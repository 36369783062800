<template>
  <div :key="theme.id" v-if="theme.attributes.ordre === 3">
    <div v-for="folder in getFoldersByTheme(theme.id)" :key="folder.id">
      <div
        v-if="
          folder &&
          (folder.name.includes('cardiaque') ||
            folder.name.includes('hypnotique') ||
            folder.name.includes('compassion')) || folder.name.includes('Indice')
        "
      >
        <div class="folder-container">
          <div
            class="folder-wrapper"
            :style="{ 'background-color': folder.color }"
            style="border-radius: 15px; margin: 4% 2%"
          >
            <p class="is-size-5 centered-text">
              <!-- <button @click="test(folder)">test</button> -->
              {{ folder.name }}
            </p>
          </div>
          <div
            class="module-section"
            :class="{
              'active-protocol': isProtocolIdInFolderIds(
                folder.protocoles[2].id
              ),
              'inactive-protocol': !isProtocolIdInFolderIds(
                folder.protocoles[2].id
              ),
              'inactive-protocol-therapeute': !isProtocolNotActiveByTherapeute(
                folder.protocoles[2].id
              )  && isDisplayCurrentRouteUsers,
            }"
          ><div
              class="inactive-protocol-therapeute"
              v-if="!isProtocolNotActiveByTherapeute(folder.protocoles[2].id)  && isDisplayCurrentRouteUsers"
            >
              <span class="overlay-message">
                Cette brique a été désactivée. Si vous souhaitez la réactiver,
                veuillez aller dans la section "Personnalisation"
                <!--Utiliser cette icone fa-cog-->
                <button class="buttonGoToPersonnalisation" @click="goToPersonnalisation()">
                  <i class="fas fa-cog"></i>
                  Personnalisation
                </button>
              </span>
            </div>
            <div class="module-header">
              <div class="toggle-switch">
                <b-switch
                  :value="isProtocolIdInFolderIds(folder.protocoles[2].id)"
                  @input="toggleProtocol(folder, 2)"
                  type="is-success"
                  size="is-small"
                ></b-switch>
              </div>
              <h4 class="module-title">Ressources libres</h4>
              <span
                :class="[
                  'validation-icon',
                  isProtocolIdInFolderIds(folder.protocoles[2].id)
                    ? 'valid'
                    : 'invalid',
                ]"
              ></span>
              <!-- <button @click="test(folder)">test</button> -->
            </div>
            <div class="module-list">
              <ul>
                <li
                  v-for="(module, index) in folder.protocoles[2].modulesDecouverte"
                  :key="module.id"
                  v-if="!module.name.includes('Pratique libre')"
                >
                  <div>
                     <span
                      :class="[
                        isProtocolIdInFolderIds(folder.protocoles[2].id)
                          ? 'module-number'
                          : 'inactive-number',
                      ]"
                      >{{ index + 1 }}</span
                    >  
                     <!-- <button @click="test(folder)">Test</button>  -->
                    {{ module.name }}
                    <!-- <div class="module-text" v-if="folder.name==='Biofeedback de cohérence cardiaque'">
                       {{ module.name }}
                      <button @click="test(module,folder)">Test</button> 
                      <ul v-if="module.linkDecouverte.length>0 && module.linkDecouverte[0].composite_file.moduleExpert">
                        <li  
                          class="module-item"
                          v-for="(link, linkIndex) in module.linkDecouverte"
                          :key="linkIndex"
                          @click="
                            ActiveModule(
                              link.composite_file.moduleExpert.objectId
                            )
                          "
                        >
                          <span
                            class="iconStatut"
                            :class="[
                              'validation-icon',
                              isModuleIdInFolderIds(
                                folder.protocoles[2].id,
                                link.composite_file.moduleExpert.objectId,
                                link.composite_file.moduleExpert.sub_name
                              )
                                ? 'valid'
                                : 'invalid',
                            ]"
                          ></span> 
                          {{ link.composite_file.moduleExpert.sub_name }}
                           <i class="fas fa-volume-up"></i> 
                        </li>
                      </ul>
                      <ul v-else-if="module.linkDecouverte.length>0 && module.linkDecouverte[0].composite_file.jeux">
                        <li  
                          class="module-item"
                          v-for="(link, linkIndex) in module.linkDecouverte"
                          :key="linkIndex"
                          @click="
                            ActiveModule(
                              link.composite_file.jeux.objectId
                            )
                          "
                        >
                           <span
                            class="iconStatut"
                            :class="[
                              'validation-icon',
                              isModuleIdInFolderIds(
                                folder.protocoles[2].id,
                                link.composite_file.jeux.objectId,
                                link.composite_file.jeux.sub_name
                              )
                                ? 'valid'
                                : 'invalid',
                            ]"
                          ></span> 

                          {{ link.composite_file.jeux.sub_name }}
                           <i class="fas fa-volume-up"></i> 
                        </li>
                      </ul>
                      <div>
                      </div> 
                    </div>
                    <div class="module-text" v-else>
                       <button @click="test(module,folder)">Test</button>  

                      <ul>
                        <li 
                          class="module-item"
                          v-for="(link, linkIndex) in module.linkDecouverte"
                          :key="linkIndex"
                          @click="
                            ActiveModule(
                              link.composite_file.short_file.objectId
                            )
                          "
                        >
                          <span
                            class="iconStatut"
                            :class="[
                              'validation-icon',
                              isModuleIdInFolderIds(
                                folder.protocoles[2].id,
                                link.composite_file.short_file.objectId,
                                link.composite_file.short_file.sub_name
                              )
                                ? 'valid'
                                : 'invalid',
                            ]"
                          ></span> 
                          

                          {{ link.composite_file.short_file.sub_name }}
                          <i class="fas fa-volume-up"></i> 
                        </li>

                      </ul>
                      </div> -->
                  </div>
                </li>
              </ul>
            </div>
            <!-- <div class='divButtonSelection'>
                  <button class="buttonSelection" @click="selectAllModules(folder)">Tout sélectionner</button>
              </div> -->
          </div>
        </div>
      </div>
      <div
        v-if="
          folder &&
          !folder.name.includes('cardiaque') &&
          !folder.name.includes('hypnotique') &&
          !folder.name.includes('compassion') &&
          !folder.name.includes('Tests') &&
          !folder.name.includes('Soft') &&
          !folder.name.includes('Vie') && !folder.name.includes('Indice')
        "
      >
        <div class="folder-container">
          <div
            class="folder-wrapper"
            :style="{ 'background-color': folder.color }"
            style="border-radius: 15px; margin: 4% 2%"
          >
            <p class="is-size-5 centered-text">
              {{ folder.name }}
            </p>
          </div>
          <div
            class="module-section"
            :class="{
              'active-protocol': isProtocolIdInFolderIds(
                folder.protocoles[3].id
              ),
              'inactive-protocol': !isProtocolIdInFolderIds(
                folder.protocoles[3].id
              ),
              'inactive-protocol-therapeute': !isProtocolNotActiveByTherapeute(
                folder.protocoles[3].id
              )  && isDisplayCurrentRouteUsers,
            }"
          >
          <div
              class="inactive-protocol-therapeute"
              v-if="!isProtocolNotActiveByTherapeute(folder.protocoles[3].id)  && isDisplayCurrentRouteUsers"
            >
              <span class="overlay-message">
                Cette brique a été désactivée. Si vous souhaitez la réactiver,
                veuillez aller dans la section "Personnalisation"
                <!--Utiliser cette icone fa-cog-->
                <button class="buttonGoToPersonnalisation" @click="goToPersonnalisation()">
                  <i class="fas fa-cog"></i>
                  Personnalisation
                </button>
              </span>
            </div>
            <div class="module-header">
              <div class="toggle-switch">
                <b-switch
                  :value="isProtocolIdInFolderIds(folder.protocoles[3].id)"
                  @input="toggleProtocol(folder, 3)"
                  type="is-success"
                  size="is-small"
                ></b-switch>
              </div>
              <h4 class="module-title">Ressources courtes</h4>
              <span
                :class="[
                  'validation-icon',
                  isProtocolIdInFolderIds(folder.protocoles[3].id)
                    ? 'valid'
                    : 'invalid',
                ]"
              ></span>
            </div>
            <div class="module-list">
              <ul>
                <li
                  v-for="(module, index) in folder.protocoles[3].modules"
                  :key="module.id"
                  v-if="!module.name.includes('Pratique libre')"
                >
                  <div class="ModuleNameDiv">
                     <span
                      :class="[
                        isProtocolIdInFolderIds(folder.protocoles[3].id)
                          ? 'module-number'
                          : 'inactive-number',
                      ]"
                      >{{ index + 1 }}</span
                    > 
                    <!-- <button @click="test(module)">Test</button> -->
                      {{ module.name }} 
                      <!-- <ul>
                        <li
                          class="module-item"
                          @click="
                            ActiveModule(
                              link.composite_file.short_file.objectId
                            )
                          "
                          v-for="(link, linkIndex) in module.linkDecouverte"
                          :key="linkIndex"
                        >
                          <span
                            class="iconStatut"
                            :class="[
                              'validation-icon',
                              isModuleIdInFolderIds(
                                folder.protocoles[3].id,
                                link.composite_file.short_file.objectId,
                                link.composite_file.short_file.sub_name
                              )
                                ? 'valid'
                                : 'invalid',
                            ]"
                          ></span>
                          {{ link.composite_file.short_file.sub_name }}
                        </li>
                      </ul> -->
                  </div>
                </li>
              </ul>
            </div>
            <!-- <div class='divButtonSelection'>
                  <button class="buttonSelection" @click="selectAllModules(folder)">Tout sélectionner</button>
                </div> -->
          </div>
          <div
            class="module-section"
            :class="{
              'active-protocol': isProtocolIdInFolderIds(
                folder.protocoles[3].id
              ),
              'inactive-protocol': !isProtocolIdInFolderIds(
                folder.protocoles[3].id
              ),
              'inactive-protocol-therapeute': !isProtocolNotActiveByTherapeute(
                folder.protocoles[3].id
              )  && isDisplayCurrentRouteUsers,
            }"
          >
          <div
              class="inactive-protocol-therapeute"
              v-if="!isProtocolNotActiveByTherapeute(folder.protocoles[3].id)  && isDisplayCurrentRouteUsers"
            >
              <span class="overlay-message">
                Cette brique a été désactivée. Si vous souhaitez la réactiver,
                veuillez aller dans la section "Personnalisation"
                <!--Utiliser cette icone fa-cog-->
                <button class="buttonGoToPersonnalisation" @click="goToPersonnalisation()">
                  <i class="fas fa-cog"></i>
                  Personnalisation
                </button>
              </span>
            </div>
            <div class="module-header">
              <div class="toggle-switch">
                <b-switch
                  :value="isProtocolIdInFolderIds(folder.protocoles[3].id)"
                  @input="toggleProtocol(folder, 3)"
                  type="is-success"
                  size="is-small"
                ></b-switch>
              </div>
              <h4 class="module-title">Ressources longues</h4>
              <span
                :class="[
                  'validation-icon',
                  isProtocolIdInFolderIds(folder.protocoles[3].id)
                    ? 'valid'
                    : 'invalid',
                ]"
              ></span>
            </div>
            <div class="module-list">
              <ul>
                <li
                  v-for="(module, index) in folder.protocoles[3]
                    .modulesDecouverte"
                  :key="module.id"
                  v-if="!module.name.includes('Pratique libre')"
                >
                  <div class="ModuleNameDiv"> 
                    <span
                      :class="[
                        isProtocolIdInFolderIds(folder.protocoles[3].id)
                          ? 'module-number'
                          : 'inactive-number',
                      ]"
                      >{{ index + 1 }}</span
                    > 
                    <!-- <button @click="test(module)">Test</button>  -->

                       {{ module.name }}
                      <!-- <ul>
                        <li
                          class="module-item"
                          v-if="
                            module.linkDecouverte &&
                            link.composite_file &&
                            link.composite_file.long_file
                          "
                          @click="
                            ActiveModule(
                              link.composite_file.short_file.objectId
                            )
                          "
                          v-for="(link, linkIndex) in module.linkDecouverte"
                          :key="linkIndex"
                        >
                           <span
                            class="iconStatut"
                            :class="[
                              'validation-icon',
                              isModuleIdInFolderIds(
                                folder.protocoles[3].id,
                                link.composite_file.long_file.objectId,
                                link.composite_file.short_file.sub_name
                              )
                                ? 'valid'
                                : 'invalid',
                            ]"
                          ></span> 
                          {{ link.composite_file.long_file.sub_name }}
                        </li>
                      </ul> -->
                  </div>
                </li>
              </ul>
            </div>
            <!-- <div class='divButtonSelection'>
                  <button class="buttonSelection" @click="selectAllModules(folder)">Tout sélectionner</button>
                </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="save-button" @click="saveChanges">
      Sauvegarder les changements
    </button> -->
  </div>
</template>
<script>
import Parse from "parse";
export default {
  data() {
    return {
      folderIds: [],
      isLoading: false,
      isDisplay: false,
      addedFolderIds: [],
      removedFolderIds: [],
      foldersIdTherapeute: [],
      isDisplayCurrentRouteUsers: false,

    };
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    foldersBrique: {
      type: Array,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    addedFolderIds: function (newVal) {
      this.$emit("update-addedFolderIds", newVal);
    },
    removedFolderIds: function (newVal) {
      this.$emit("update-removedFolderIds", newVal);
    },
  },
  methods: {
    getFoldersByTheme(monTheme) {
      return this.foldersBrique
        .filter((el) => {
          if (el.themes.length < 2) {
            return el.themes[0].objectId === monTheme;
          }
          return (
            el.themes[0].objectId === monTheme ||
            el.themes[1].objectId === monTheme
          );
        })
        .sort((a, b) => a.link.order - b.link.order);
    },
    goToPersonnalisation() {
      console.log("goToPersonnalisation");
      this.$router.push("/personnalisation");
    },
    test(folder) {
      console.log("folder", folder);
      console.log("folder.protocoles[2]", folder.protocoles[2])
    },
    ActiveModule(id) {
      console.log("id", id);
      if(this.fileIds.includes(id)){
        // le retirer
        this.fileIds = this.fileIds.filter((el) => el !== id);
        this.addedFolderIds = this.addedFolderIds.filter((el) => el !== id);
        this.removedFolderIds.push(id);
        return;
      }
      if (!this.addedFolderIds.includes(id)) {
        console.log("This.addedFolderIds ", this.addedFolderIds);
        this.addedFolderIds.push(id);

      } else {
        //retirer l'id du tableau
        console.log("This.addedFolderIds ", this.addedFolderIds);
        this.addedFolderIds = this.addedFolderIds.filter((el) => el !== id);
        // this.fileIds = this.fileIds.filter((el) => el !== id);
      }
    },
    getExercice(module) {
      console.log("module", module);
    },
    toggleProtocol(folder, protocolIndex) {
      //console.log("Modification du toggle protocol");
      //console.log("toggleProtocol: ", folder, protocolIndex);
      const protocolId = folder.protocoles[protocolIndex].id;
      //console.log("protocolId: ", protocolId);
      if (this.folderIds.includes(protocolId)) {
        this.folderIds = this.folderIds.filter((id) => id !== protocolId);
        this.removedFolderIds.push(protocolId);
        this.addedFolderIds = this.addedFolderIds.filter(
          (id) => id !== protocolId
        );
      } else {
        this.folderIds.push(protocolId);
        this.addedFolderIds.push(protocolId);
        this.removedFolderIds = this.removedFolderIds.filter(
          (id) => id !== protocolId
        );
      }
    },
    // async test(module,folder) {
    //   var moduleId = module.id;
    //   console.log("module", module);
    //   console.log("moduleId", moduleId);
    //   console.log("folder", folder);
    //   var fileQuery = new Parse.Query("folder");
    //   fileQuery.equalTo("objectId", moduleId);
    //   var fileParseObj = await fileQuery.first();
    //   console.log("fileParseObj", fileParseObj);
    // },
    async fetchUser(objectId) {
      const userParse = Parse.Object.extend("User");
      const userQuery = new Parse.Query(userParse);
      userQuery.equalTo("objectId", objectId);

      try {
        return await userQuery.first();
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur:",
          error
        );
        throw error;
      }
    },
    getExercises(module) {
      // Faites quelque chose avec le module, par exemple, affichez les exercices
      console.log(`Récupération des exercices pour le module ${module.name}`);
    },
    isModuleIdInFolderIds(protocolId, moduleId,nameModule) {
      console.log("moduleId", moduleId);
      console.log("this.fileIds", this.fileIds);
      console.log("this.addedFolderIds", this.addedFolderIds);
      console.log("this.removedFolderIds", this.removedFolderIds);
      console.log('Name:', nameModule)
      console.log('------------------------------------')
      var BooleanFileIds = !this.fileIds.includes(moduleId);
      var BooleanAddedFolderIds = !this.addedFolderIds.includes(moduleId);
      console.log("BooleanFileIds", BooleanFileIds , "|| BooleanAddedFolderIds", BooleanAddedFolderIds);
      return (
        !this.fileIds.includes(moduleId) &&
        !this.addedFolderIds.includes(moduleId)
        && this.folderIds.includes(protocolId)

      );
    },
    isProtocolIdInFolderIds(protocolId) {
      return this.folderIds.includes(protocolId);
    },
    isProtocolNotActiveByTherapeute(protocolId) {
      return this.foldersIdTherapeute.includes(protocolId);
    },
    async isProtocolActive(folder, protocolIndex) {
      try {
        const user = await this.fetchUser(this.selectedUser.objectId);
        const links = await this.fetchLinks(user);

        links.forEach((link) => {
          const folderId = link.get("folder") ? link.get("folder").id : null;

          // Si le folderId correspond à l'ID du protocole recherché, l'ajouter au tableau
          if (folderId === folder.protocoles[protocolIndex].id) {
            this.folderIds.push(folderId);
          }
        });

        if (this.folderIds.length > 0) {
          //console.log("Les folder ids trouvés sont:", this.folderIds);
        } else {
          //console.log(
          //   "Aucun folder avec le même id n'a été trouvé.",
          //   folder.protocoles[protocolIndex].id
          // );
        }
      } catch (error) {
        // Gestion d'erreur globale
        console.error("Une erreur est survenue:", error);
      }
      //console.log("Voici le tableau des ids:", this.folderIds);
    },
    async saveChanges() {
      try {
        this.isLoading = true;

        const user = await this.fetchUser(this.selectedUser.objectId);
        const linkRelation = user.relation("link");

        let linksToRemove = [];
        let linksToAdd = [];

        // Utilisation de Promise.all pour attendre que toutes les opérations soient terminées
        await Promise.all(
          this.removedFolderIds.map(async (folderId) => {
            // Rechercher le folder qui a cet id
            var folderQuery = new Parse.Query("folder");
            folderQuery.equalTo("objectId", folderId);
            const folderParseObj = await folderQuery.first();

            if (folderParseObj) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              var linkQuery = linkRelation.query();
              linkQuery.equalTo("folder", folderParseObj);
              const link = await linkQuery.first();

              //console.log("link", link);

              if (link) {
                // Vérifier que l'objet link existe
                linksToRemove.push(link.id);
              }
            }
          })
        );
        await Promise.all(
          this.addedFolderIds.map(async (folderId) => {
            // Rechercher le folder qui a cet id
            var folderQuery = new Parse.Query("folder");
            folderQuery.equalTo("objectId", folderId);
            //console.log("FolderId :", folderId);
            const folderParseObj = await folderQuery.first();
            //console.log("FolderParseObj :", folderParseObj);
            if (folderParseObj) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              var linkQuery = new Parse.Query("link");
              linkQuery.equalTo("folder", folderParseObj);
              const link = await linkQuery.first();

              //console.log("link to add", link);

              if (link) {
                // Vérifier que l'objet link existe
                linksToAdd.push(link.id);
              }
            }
          })
        );

        //console.log("Voici le tableau des links à supprimer:", linksToRemove);
        //console.log("Voici le tableau des links à ajouter:", linksToAdd);
        // Appeler la fonction Parse Cloud pour modifier les relations de lien
        await Parse.Cloud.run("modifyUserLinkRelation", {
          userObjectId: this.selectedUser.objectId,
          linksToRemove: linksToRemove,
          linksToAdd: linksToAdd,
        });

        this.isLoading = false;
        //console.log("Modifications enregistrées avec succès.");
        this.$buefy.notification.open({
          message: "Les changements ont été sauvegardés avec succès !",
          type: "is-success",
          position: "is-top",
          duration: 5000,
        });
      } catch (error) {
        this.$buefy.notification.open({
          message: `Erreur : ${error.message}`,
          type: "is-danger",
          position: "is-top",
          duration: 5000,
        });
        this.isLoading = false;
        console.error("Une erreur est survenue:", error);
      }
    },
    async fetchLinks(user) {
      const linkRelation = user.relation("link");
      const query = linkRelation.query();
      query.limit(1000);

      try {
        return await query.find();
      } catch (error) {
        console.error("Erreur lors de la récupération des links:", error);
        throw error;
      }
    },
  },
  async created() {
    try {
      if (this.selectedUser.objectId === undefined) return;
      const user = await this.fetchUser(this.selectedUser.objectId);
      const links = await this.fetchLinks(user);
      this.folderIds = links.map((link) =>
        link.get("folder") ? link.get("folder").id : null
      );
      this.fileIds = links.map((link) =>
        link.get("file") ? link.get("file").id : null
      );
      if (this.$router.currentRoute.path === "/utilisateurs") {
        this.isDisplayCurrentRouteUsers = true;
        const userTherapeute = await this.fetchUser(Parse.User.current().id);
        const linksTherapeute = await this.fetchLinks(userTherapeute);
        this.foldersIdTherapeute = linksTherapeute.map((link) =>
          link.get("folder") ? link.get("folder").id : null
        );
      }
    } catch (error) {
      console.error("Une erreur est survenue:", error);
    }
    this.$emit("loading", false);
    this.isDisplay = true;
  },
};
</script>
<style scoped lang="scss">
.folder-container {
  display: flex;
  min-height: 400px;
  // align-items: flex-start;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05); // Couleur plus douce
  padding-bottom: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease; // Transition douce
  align-items: stretch;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02); // Effet hover
  }
}
.folder-container::after {
  content: "";
  display: block;
  height: 2px;
  background: linear-gradient(to right, transparent, #0099cc, transparent);
  margin-top: 20px;
}
.folder-container > *:not(:last-child) {
  margin-right: 30px; /* Ou toute autre valeur de marge que vous préférez */
}
.folder-wrapper {
  flex: 1;
  position: relative;
  max-width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.brique-image {
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  height: 300px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s ease; // Transition douce

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); // Effet hover
  }
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-weight: bold;
  font-family: "Arial", sans-serif; // Police plus lisible
}

.iconStatut
{
  border: 1px solid black;
    border-radius: 50%;
    padding: 0px 5px 0px 5px;
    background:#fbf2f2;
}

.module-section {
  margin-bottom: 30px;
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce */
  overflow: hidden; /* Pour contenir les enfants avec un border-radius */
  background: #fff; /* Couleur de fond de la carte */
  &.active-protocol {
    border: 2px solid #4caf50; /* Bordure verte lorsque le protocole est actif */
  }

  &.inactive-protocol {
    border: 2px solid #ccc; /* Bordure grise lorsque le protocole est inactif */
  }
  &.inactive-protocol-therapeute {
    border: 2px solid #949494;
    backdrop-filter: blur(5px);
    background-color: rgba(148, 148, 148, 0.5);
    /* désactiver le clic */
    pointer-events: none;
  }
  .module-title {
    font-size: 1.2em;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
  }

  .module-list {
    flex: 1;
    font-size: 0.55em;
    padding: 10px 20px ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 10px 0;
      // border-bottom: 1px solid #e6e6e6;
      transition: background-color 0.3s ease;
      line-height: 1.6; // Espacement entre les lignes

      // &:hover {
      //   background-color: #f7f7f7;
      //   cursor: pointer;
      // }
    }

    .module-number {
      padding: 8px 16px;
      background-color: #4caf50;
      padding: 5px 10px;
      border-radius: 50%;
      margin-right: 10px;
      color: #ffffff;
      font-size: 1em;
      font-weight: bold;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      display: inline-block;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    .inactive-number {
      padding: 8px 16px;
      background-color: #ccc; /* Couleur de fond plus claire pour indiquer qu'il est désactivé */
      color: white; /* Couleur de texte gris clair pour être moins proéminent */
      padding: 5px 10px; /* Même padding que le numéro actif */
      border-radius: 50%; /* Conserve le style arrondi */
      margin-right: 10px; /* Même marge à droite */
      font-size: 1em; /* Même taille de police */
      font-weight: bold; /* Même poids de police */
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Ombre plus légère pour indiquer qu'il est inactif */
      display: inline-block; /* Conserve le style inline-block */
      transition: transform 0.3s ease; /* Même transition */
    }

    /* Texte de la liste */
    span + span {
      white-space: normal;
      display: inline-block;
      max-width: 80%;
      font-size: 0.9em; /* Réduire un peu la taille du texte pour mettre en valeur le numéro */
    }
  }
}
.toggle-switch {
  position: relative;
  width: 40px; // Réduire la taille pour moins d'encombrement
  height: 20px;
  margin-left: 10px; // Ajout d'une marge à gauche pour l'espacement
  border-radius: 20px;

  input {
    &:checked + .toggle-label {
      background-color: #4caf50; // Changer la couleur quand c'est actif
    }
  }
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; // Ajout d'une marge en bas pour l'espacement
  gap: 10px;

  .module-title {
    font-size: 1.2em;
    color: #333;
    margin: 0; // Retirer la marge en bas pour un alignement cohérent
  }

  .toggle-switch {
    display: flex;
    align-items: center; // Centrer verticalement le switch
  }
}

.toggle-label:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  /* left: 1px; */
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.validation-icon {
  font-size: 1.2em; /* Taille du caractère */
  margin-right: 10px; /* Marge à droite pour espacer l'icône du texte */
  transition: color 0.3s ease; /* Transition douce pour les changements de couleur */
}

.valid {
  color: #4caf50; /* Couleur verte pour indiquer la validité */
  &:before {
    content: "\2713"; /* Caractère de coche */
  }
}

.validDiv {
  color: #4caf50; /* Couleur verte pour indiquer la validité */
}

.invalid {
  color: #f44336; /* Couleur rouge pour indiquer l'invalidité */
  &:before {
    content: "\2717"; /* Caractère de croix */
  }
}

.invalidDiv {
  color: #f44336; /* Couleur rouge pour indiquer l'invalidité */
  opacity: 0.5;
}
input:checked + .toggle-label {
  background-color: #2196f3;
}

input:checked + .toggle-label:before {
  transform: translateX(22px);
}

.active-protocol {
  background-color: #c8e6c9; // Vert doux pour indiquer que c'est actif
  border: 1px solid #4caf50; // Bordure verte
  width: 30%;
  padding: 20px;

  transition: all 0.3s ease; // Transition douce pour les changements
}

.inactive-protocol {
  background-color: #ffcdd2; // Rouge doux pour indiquer que c'est inactif
  border: 1px solid #f44336; // Bordure rouge
  width: 30%;
  padding: 20px;

  transition: all 0.3s ease; // Transition douce pour les changements
}
.module-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid #e6e6e6;
  font-size:1rem;
}
.module-item:hover {
  background-color: #f7f7f7;
  cursor: pointer;
}
.ModuleNameDiv
{
  display: flex;
  align-items: center;
}
.module-text {
  margin-left: 8px;
}

.audio-icon {
  margin-left: 12px;
  cursor: pointer;
}

.audio-icon i {
  font-size: 16px;
}

.save-button {
  background-color: #4caf50; /* Vert */
  border: none;
  color: white; /* Texte blanc */
  padding: 15px 32px; /* Espace autour du texte */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px; /* Coins arrondis */
  transition: all 0.3s ease; /* Transition douce pour tous les changements de style */
}

.save-button:hover {
  background-color: #45a049; /* Vert foncé au survol */
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19); /* Ombre au survol */
}

.buttonSelection {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: 2px solid #239380; /* Couleur de la bordure */
  color: #239380; /* Couleur du texte */
  background-color: #ffffff; /* Couleur de fond */
  border-radius: 5px; /* Coins arrondis */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.buttonSelection:hover {
  background-color: #239380; /* Changement de couleur de fond au survol */
  color: #ffffff; /* Changement de couleur du texte au survol */
  border-color: #ffffff; /* Changement de couleur de la bordure au survol */
}
.divButtonSelection
{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.save-button:active {
  background-color: #3e8e41; /* Vert encore plus foncé lors du clic */
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.24), 0 7px 20px 0 rgba(0, 0, 0, 0.19); /* Ombre plus petite lors du clic */
  transform: translateY(
    4px
  ); /* Déplace légèrement le bouton vers le bas lors du clic */
}

.overlay-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  z-index: 10;
  font-weight: bold;
  text-align: center;
  font-size: 0.8em !important;
}

/* Désactiver le clic pour tout le conteneur */
.inactive-protocol-therapeute * {
  pointer-events: none;
}


.buttonGoToPersonnalisation {
  margin-top: 10px;
  background-color: #239380;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  pointer-events: auto; 
}
</style>
