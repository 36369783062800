export const CONFIG_PAIEMENT = {
  CMCIC_TPE: "6791364",
  CMCIC_CODESOCIETE: "abonnementtestIFRAME",
  CMCIC_CLE: "34976D955B732525717D5472797E089A66E3E696",
  CMCIC_VERSION: "3.0",
  CMCIC_BANK: "CIC",
  CMCIC_LNG: "FR",
  CMCIC_CURRENCY: "EUR",
  CMCIC_SERVEUR: "https://ssl.paiement.cic-banques.fr",
  CMCIC_URL: "https://www.symbiocenter.com/gestion/%23",
  CMCIC_URL_RETOUR: "https://www.symbiocenter.com/gestion/%23/abonnement",
  CMCIC_URLOK: "https://www.symbiocenter.com/gestion/%23/abonnement",
  CMCIC_URLKO: "https://www.symbiocenter.com/gestion/%23/abonnement",
  TEST: false,
  CMCIC_URL_MONETICO: "https://p.monetico-services.com/paiement.cgi?",
  CMCIC_URL_MONETICO_RESILIATION:
    "https://ssl.paiement.cic-banques.fr/capture_paiement.cgi?",
  CODE_SOCIETE_ANNUEL: "abonnementanueIFRAME",
  CODE_SOCIETE_MENSUEL: "abonnementtestIFRAME",
};
