var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.theme.attributes.ordre === 3)?_c('div',{key:_vm.theme.id},_vm._l((_vm.getFoldersByTheme(_vm.theme.id)),function(folder){return _c('div',{key:folder.id},[(
        folder &&
        (folder.name.includes('cardiaque') ||
          folder.name.includes('hypnotique') ||
          folder.name.includes('compassion')) || folder.name.includes('Indice')
      )?_c('div',[_c('div',{staticClass:"folder-container"},[_c('div',{staticClass:"folder-wrapper",staticStyle:{"border-radius":"15px","margin":"4% 2%"},style:({ 'background-color': folder.color })},[_c('p',{staticClass:"is-size-5 centered-text"},[_vm._v(" "+_vm._s(folder.name)+" ")])]),_c('div',{staticClass:"module-section",class:{
            'active-protocol': _vm.isProtocolIdInFolderIds(
              folder.protocoles[2].id
            ),
            'inactive-protocol': !_vm.isProtocolIdInFolderIds(
              folder.protocoles[2].id
            ),
            'inactive-protocol-therapeute': !_vm.isProtocolNotActiveByTherapeute(
              folder.protocoles[2].id
            )  && _vm.isDisplayCurrentRouteUsers,
          }},[(!_vm.isProtocolNotActiveByTherapeute(folder.protocoles[2].id)  && _vm.isDisplayCurrentRouteUsers)?_c('div',{staticClass:"inactive-protocol-therapeute"},[_c('span',{staticClass:"overlay-message"},[_vm._v(" Cette brique a été désactivée. Si vous souhaitez la réactiver, veuillez aller dans la section \"Personnalisation\" "),_c('button',{staticClass:"buttonGoToPersonnalisation",on:{"click":function($event){return _vm.goToPersonnalisation()}}},[_c('i',{staticClass:"fas fa-cog"}),_vm._v(" Personnalisation ")])])]):_vm._e(),_c('div',{staticClass:"module-header"},[_c('div',{staticClass:"toggle-switch"},[_c('b-switch',{attrs:{"value":_vm.isProtocolIdInFolderIds(folder.protocoles[2].id),"type":"is-success","size":"is-small"},on:{"input":function($event){return _vm.toggleProtocol(folder, 2)}}})],1),_c('h4',{staticClass:"module-title"},[_vm._v("Ressources libres")]),_c('span',{class:[
                'validation-icon',
                _vm.isProtocolIdInFolderIds(folder.protocoles[2].id)
                  ? 'valid'
                  : 'invalid',
              ]})]),_c('div',{staticClass:"module-list"},[_c('ul',_vm._l((folder.protocoles[2].modulesDecouverte),function(module,index){return (!module.name.includes('Pratique libre'))?_c('li',{key:module.id},[_c('div',[_c('span',{class:[
                      _vm.isProtocolIdInFolderIds(folder.protocoles[2].id)
                        ? 'module-number'
                        : 'inactive-number',
                    ]},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(module.name)+" ")])]):_vm._e()}),0)])])])]):_vm._e(),(
        folder &&
        !folder.name.includes('cardiaque') &&
        !folder.name.includes('hypnotique') &&
        !folder.name.includes('compassion') &&
        !folder.name.includes('Tests') &&
        !folder.name.includes('Soft') &&
        !folder.name.includes('Vie') && !folder.name.includes('Indice')
      )?_c('div',[_c('div',{staticClass:"folder-container"},[_c('div',{staticClass:"folder-wrapper",staticStyle:{"border-radius":"15px","margin":"4% 2%"},style:({ 'background-color': folder.color })},[_c('p',{staticClass:"is-size-5 centered-text"},[_vm._v(" "+_vm._s(folder.name)+" ")])]),_c('div',{staticClass:"module-section",class:{
            'active-protocol': _vm.isProtocolIdInFolderIds(
              folder.protocoles[3].id
            ),
            'inactive-protocol': !_vm.isProtocolIdInFolderIds(
              folder.protocoles[3].id
            ),
            'inactive-protocol-therapeute': !_vm.isProtocolNotActiveByTherapeute(
              folder.protocoles[3].id
            )  && _vm.isDisplayCurrentRouteUsers,
          }},[(!_vm.isProtocolNotActiveByTherapeute(folder.protocoles[3].id)  && _vm.isDisplayCurrentRouteUsers)?_c('div',{staticClass:"inactive-protocol-therapeute"},[_c('span',{staticClass:"overlay-message"},[_vm._v(" Cette brique a été désactivée. Si vous souhaitez la réactiver, veuillez aller dans la section \"Personnalisation\" "),_c('button',{staticClass:"buttonGoToPersonnalisation",on:{"click":function($event){return _vm.goToPersonnalisation()}}},[_c('i',{staticClass:"fas fa-cog"}),_vm._v(" Personnalisation ")])])]):_vm._e(),_c('div',{staticClass:"module-header"},[_c('div',{staticClass:"toggle-switch"},[_c('b-switch',{attrs:{"value":_vm.isProtocolIdInFolderIds(folder.protocoles[3].id),"type":"is-success","size":"is-small"},on:{"input":function($event){return _vm.toggleProtocol(folder, 3)}}})],1),_c('h4',{staticClass:"module-title"},[_vm._v("Ressources courtes")]),_c('span',{class:[
                'validation-icon',
                _vm.isProtocolIdInFolderIds(folder.protocoles[3].id)
                  ? 'valid'
                  : 'invalid',
              ]})]),_c('div',{staticClass:"module-list"},[_c('ul',_vm._l((folder.protocoles[3].modules),function(module,index){return (!module.name.includes('Pratique libre'))?_c('li',{key:module.id},[_c('div',{staticClass:"ModuleNameDiv"},[_c('span',{class:[
                      _vm.isProtocolIdInFolderIds(folder.protocoles[3].id)
                        ? 'module-number'
                        : 'inactive-number',
                    ]},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(module.name)+" ")])]):_vm._e()}),0)])]),_c('div',{staticClass:"module-section",class:{
            'active-protocol': _vm.isProtocolIdInFolderIds(
              folder.protocoles[3].id
            ),
            'inactive-protocol': !_vm.isProtocolIdInFolderIds(
              folder.protocoles[3].id
            ),
            'inactive-protocol-therapeute': !_vm.isProtocolNotActiveByTherapeute(
              folder.protocoles[3].id
            )  && _vm.isDisplayCurrentRouteUsers,
          }},[(!_vm.isProtocolNotActiveByTherapeute(folder.protocoles[3].id)  && _vm.isDisplayCurrentRouteUsers)?_c('div',{staticClass:"inactive-protocol-therapeute"},[_c('span',{staticClass:"overlay-message"},[_vm._v(" Cette brique a été désactivée. Si vous souhaitez la réactiver, veuillez aller dans la section \"Personnalisation\" "),_c('button',{staticClass:"buttonGoToPersonnalisation",on:{"click":function($event){return _vm.goToPersonnalisation()}}},[_c('i',{staticClass:"fas fa-cog"}),_vm._v(" Personnalisation ")])])]):_vm._e(),_c('div',{staticClass:"module-header"},[_c('div',{staticClass:"toggle-switch"},[_c('b-switch',{attrs:{"value":_vm.isProtocolIdInFolderIds(folder.protocoles[3].id),"type":"is-success","size":"is-small"},on:{"input":function($event){return _vm.toggleProtocol(folder, 3)}}})],1),_c('h4',{staticClass:"module-title"},[_vm._v("Ressources longues")]),_c('span',{class:[
                'validation-icon',
                _vm.isProtocolIdInFolderIds(folder.protocoles[3].id)
                  ? 'valid'
                  : 'invalid',
              ]})]),_c('div',{staticClass:"module-list"},[_c('ul',_vm._l((folder.protocoles[3]
                  .modulesDecouverte),function(module,index){return (!module.name.includes('Pratique libre'))?_c('li',{key:module.id},[_c('div',{staticClass:"ModuleNameDiv"},[_c('span',{class:[
                      _vm.isProtocolIdInFolderIds(folder.protocoles[3].id)
                        ? 'module-number'
                        : 'inactive-number',
                    ]},[_vm._v(_vm._s(index + 1))]),_vm._v(" "+_vm._s(module.name)+" ")])]):_vm._e()}),0)])])])]):_vm._e()])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }