<template>
  <div>
    <canvas ref="radarChart" width="700" height="700"></canvas>
  </div>
</template>
<script>
import Chart from "chart.js";
export default {
  data() {
    return {
      radarChart: null,
    };
  },
  props: {
    firstValue: {
      type: Number,
      required: true,
    },
    secondValue: {
      type: Number,
      required: true,
    },
    thirdValue: {
      type: Number,
      required: true,
    },
    valueChart: {
      type: Array,
      required: true,
    },
    nameBFP: {
      type: String,
      // required: true,
      default: "BFP",
    },
  },
  created() {
    console.log("NameBFP:", this.nameBFP);
  },
  watch: {
    data: function () {
      this.renderChart();
    },
    anxietyValue() {
      this.updateRadarChart();
    },
    depressionValue() {
      this.updateRadarChart();
    },
    stressValue() {
      this.updateRadarChart();
    },
  },
  methods: {
    updateRadarChart() {
      const ctx = this.$refs.radarChart.getContext("2d");

      const maxValue =
        this.nameBFP && this.nameBFP === "BFP : Bilan Flash Psychologique"
          ? 10
          : 48;
      console.log("Maximum value:", maxValue, this.nameBFP);
      // Remove any existing chart to avoid duplicates
      if (this.radarChart) {
        this.radarChart.destroy();
      }
      const backgroundColor = this.valueChart.map((value) =>
        value === 1 || value === 2
          ? "rgba(0, 255, 0, 0.2)"
          : "rgba(75, 192, 192, 0.2)"
      );
      const borderColor = this.valueChart.map((value) =>
        value === 1 || value === 2
          ? "rgba(0, 255, 0, 1)"
          : "rgba(75, 192, 192, 1)"
      );
      this.radarChart = new Chart(ctx, {
        type: "radar",
        data: {
          labels: this.valueChart,

          datasets: [
            {
              // label: "Résultats",
              data: [this.firstValue, this.secondValue, this.thirdValue],
              backgroundColor: backgroundColor,
              borderColor: borderColor,
              borderWidth: 3,
              fontColor: "red",
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scale: {
            ticks: {
              beginAtZero: true,
              max: maxValue,
              lineWidth: 3,
              stepSize: 10,
              color: "black",
              // callback: function (value, index, values) {
              //   return value; // Ceci affiche le numéro de chaque ligne
              // },
            },
            pointLabels: {
              fontSize: 16, // Adjust the font size as needed
              callback: function (value) {
                // Diviser le texte en plusieurs lignes
                return value.split("\n");
              },
              // fontColor: "red",
              //rajouter une fonction pour changer la couleur en fonction de la valeur
            },
            gridLines: {
              lineWidth: 3, // Adjust the line width for the baseline grid
            },
          },
          animations: {
            radius: {
              duration: 1000,
              easing: "linear",
              loop: (context) => context.active,
            },
          },
        },
      });
    },
  },
  mounted() {
    this.updateRadarChart();
  },
};
</script>
