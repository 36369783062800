import Parse from "parse";

export async function getFilesDonesBFP(userId) {
  const FilesDonesBFP = Parse.Object.extend("file_done");
  var User = Parse.Object.extend("User");
  const queryFilesDones = new Parse.Query(FilesDonesBFP);
  queryFilesDones.equalTo("bfp", true);
  //inclure le user
  var UserQuery = new Parse.Query(User);
  UserQuery.equalTo("objectId", userId);
  var user = await UserQuery.first();

  queryFilesDones.include("user");
  queryFilesDones.include("module");
  queryFilesDones.equalTo("user", user);
  const results = await queryFilesDones.find();
  return results;
}
