import Parse from "parse";
import ParseConfig from "@/api/parse.config";

export async function getSuiviForUser(userId) {
  ParseConfig();
  var User = Parse.Object.extend("User");
  var queryUser = new Parse.Query(User);
  queryUser.equalTo("objectId", userId);
  var result = await queryUser.find();
  var fileType = Parse.Object.extend("file_type");
  var queryFileType = new Parse.Query(fileType);
  queryFileType.find();

  var FileDone = Parse.Object.extend("file_done");
  var queryFileDone = new Parse.Query(FileDone);
  queryFileDone.limit(500);
  queryFileDone.equalTo("user", result[0]);
  queryFileDone.include("file");
  queryFileDone.include("protocole");
  queryFileDone.include("module");
  var resultFileDone = await queryFileDone.find();
  console.log("Résultats file done :", resultFileDone, userId);
  var Link = Parse.Object.extend("link");
  var queryLink = new Parse.Query(Link);
  var Folder = Parse.Object.extend("folder");
  var queryFolder = new Parse.Query(Folder);
  // Créer un objet pour stocker les informations des fichiers
  var fileObjects = {};
  for (const element of resultFileDone) {
    var LastDate = element.updatedAt;
    var ParentFolder = [];
    var timeElapsed = element.attributes.time_elapsed;
    var Ani_Boolean = element.attributes.ANI;
    // if (timeElapsed < 30) {
    //   continue;
    // }
    if (element.get("file") === undefined) {
      continue;
    }
    var fileId = element.get("file").id;
    var protocole = element.get("protocole");
    if (protocole == undefined) {
      continue;
    }
    queryFolder.equalTo("objectId", protocole.id);
    queryLink.matchesQuery("folder", queryFolder);
    queryLink.include("parent_folder");
    var resultLink = await queryLink.find();
    for (const linkElement of resultLink) {
      ParentFolder.push(linkElement);
    }
    var module = element.get("module");
    var file = element.get("file");
    //inclure les attributes
    console.log("FileTest", file);
    if (file && file.attributes.type && file.attributes.type.attributes.name) {
      var typeExercice = file.attributes.type.attributes.name;
      console.log("TYPE EXERCICE :", typeExercice);
    }
    if (fileObjects[fileId]) {
      // Le fileId existe déjà, augmenter le compteur
      fileObjects[fileId].count++;
      // Ajouter le temps passé
      fileObjects[fileId].timeElapsed += timeElapsed;
      // Vérifier si le temps passé est plus grand que le temps passé précédent
      if (LastDate > fileObjects[fileId].LastDate) {
        fileObjects[fileId].LastDate = LastDate;
      }
    } else {
      // Créer un nouvel objet pour le fileId avec un compteur initial de 1
      fileObjects[fileId] = {
        count: 1,
        file: file,
        protocole: ParentFolder[0]
          ? ParentFolder[0].attributes.parent_folder
          : file.objectId,
        module: module,
        timeElapsed: timeElapsed,
        LastDate: LastDate,
        typeExercice: typeExercice,
        Ani_Boolean: Ani_Boolean,
        resultatQuestionnaire: element.attributes.resultatQuestionnaire ? element.attributes.resultatQuestionnaire : null,
        resultatJeux: element.attributes.resultatJeux ? element.attributes.resultatJeux : null,
      };
    }
  }
  return fileObjects;
}
