<template>
  <div>
    <Header @openSidebar="openSidebar()" />
    <Sidebar :isOpen="isOpen" />
    <div>
      <div>
        <div v-bind:class="{ isOpenSidebar: isOpen }">
          <div class="columns is-fullheight">
            <div class="column">
              <transition name="slide" mode="out-in">
                <router-view v-if="!showModal"></router-view>

                <b-modal
                  :width="1200"
                  v-else
                  :active.sync="showModal"
                  @close="showModal = false"
                >
                  <div class="card">
                    <div class="card-content">
                      <div class="content">
                        <p>
                          {{ $t("confortUtilisation") }}
                        </p>
                        <a class="button is-grey" @click="showModal = false">
                          {{ $t("continuer") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </b-modal>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";
import Sidebar from "./Sidebar";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");

export default {
  name: "Root",
  components: {
    Header,
    Sidebar,
  },
  data() {
    return {
      isOpen: true,
      width: 0,
      height: 0,
      showModal: false,
    };
  },
  computed: {
    ...User.mapGetters(["modalTablette"]),
  },
  methods: {
    ...User.mapActions({
      actionsEditModalTablette: "editModalTablette",
    }),
    openSidebar() {
      this.isOpen = !this.isOpen;
    },
    handleResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
      if (this.width < 512 && !this.modalTablette) {
        this.isOpen = false;
        this.showModal = true;
        this.actionsEditModalTablette();
      }
    },
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  async mounted() {
    // try {
    //   var query = new Parse.Query("site_admin");
    //   await query.first();
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }
  },
};
</script>

<style></style>
