<template>
  <div>
    <div class="is-card-folder-resultat">
      <div class="padding-10">
        <chart-protocole-libre-vue
          v-if="protocolesLibreSelected && chartProtocoleLibre"
          :tabRow="tabRowProtocoleLibre"
        />
        <chart-protocole-guide-vue
          v-if="
            (!protocolesLibreSelected &&
              chartProtocoleGuide &&
              !ResultatsRessources &&
              taxExo1 &&
              tabExo1.color &&
              tabExo1.data &&
              tabExo1.color.length > 0 &&
              tabExo1.data.length > 0 &&
              tabExo1.data.label > 0) ||
            (taxExo2 &&
              tabExo2.color &&
              tabExo2.data &&
              tabExo2.color.length > 0 &&
              tabExo2.data.length > 0 &&
              tabExo2.data.label > 0) ||
            (taxExo3 &&
              tabExo3.color &&
              tabExo3.data &&
              tabExo3.color.length > 0 &&
              tabExo3.data.length > 0 &&
              tabExo3.data.label > 0)
          "
          :tabRowInstalle="tabExo1"
          :tabRowMaitrise="tabExo2"
          :tabRowAncre="tabExo3"
        />

        <div
          v-if="
            resultatCoherenceCardiaque && resultatCoherenceCardiaque.length > 0
          "
        >
          <b-table
            :data="resultatCoherenceCardiaque"
            :bordered="true"
            :narrowed="true"
            :striped="true"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="id"
            :show-detail-icon="false"
          >
            <template slot-scope="props" slot="header">
              <div>{{ props.column.label }}</div>
            </template>

            <template slot-scope="props">
              <b-table-column label="Date" class="has-text-centered">
                {{ new Date(props.row.updatedAt).toLocaleDateString() }}
              </b-table-column>
              <b-table-column label="Séance" class="has-text-centered">
                <p v-if="props.row.attributes.link">
                  {{
                    props.row.attributes.link.attributes.parent_folder
                      .attributes.name
                  }}
                </p>
                <p v-else-if="props.row.attributes.file">
                  {{ $t("entraintementLibre") }}
                </p>
              </b-table-column>
              <b-table-column label="Exercice" class="has-text-centered">
                <p v-if="props.row.attributes.link">
                  {{
                    props.row.attributes.link.attributes.file.attributes.name
                  }}
                </p>
                <p v-else-if="props.row.attributes.file">
                  {{ props.row.attributes.file.attributes.name }}
                </p>
              </b-table-column>
              <b-table-column
                v-if="protocolesLibreSelected"
                label="Difficulté"
                class="has-text-centered"
              >
                <p>{{ valeurDifficulte(props.row) }}</p>
              </b-table-column>
              <b-table-column
                label="Fréquence cardiaque moyenne"
                class="has-text-centered"
              >
                {{ valeurFCMoyenne(props.row) }}
              </b-table-column>
              <b-table-column
                label="Moyenne de cohérence cardiaque"
                class="has-text-centered"
              >
                {{ valeurCCMoyenne(props.row) }}%
              </b-table-column>
              <b-table-column label="Relecture" class="has-text-centered">
                <button
                  :class="{
                    expert_bouton_relecture_deploye: defaultOpenedDetails.find(
                      (e) => {
                        return e === props.row.id;
                      }
                    ),
                  }"
                  class="expert_bouton_relecture"
                  aria-label="resultat"
                  @click="showRelecture(props.row)"
                >
                  <img
                    src="./../../../../assets/img/play-button.png"
                    width="24px"
                    style="margin-left: 2px"
                  />
                </button>
              </b-table-column>
              <b-table-column label="Supprimer">
                <img
                  src="./../../../../assets/img/trash.png"
                  width="27px"
                  style="margin-left: 27px; margin-top: 2px"
                  v-on:click="deleteRelecture(props.row.id)"
                />
              </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <div id="relecture-component">
                <div
                  v-if="
                    props.row.attributes.comment &&
                    props.row.attributes.comment !== ''
                  "
                >
                  <p style="color: white; font-size: medium">Commentaire :</p>
                  <p>
                    {{ props.row.attributes.comment }}
                  </p>
                </div>
                <div class="chart-result">
                  <chart-fc-vue
                    v-if="chart && resultatObjet"
                    :valeurX="valeurX"
                    :tabRowFc="tabRowFc"
                    :defaultOpenedDetails="defaultOpenedDetails[0]"
                    @sendChartFc="convertImgFc"
                  />
                  <chart-cc-vue
                    v-if="chart && resultatObjet"
                    :valeurX="valeurCcX"
                    :tabRowCc="tabRowCc"
                    :defaultOpenedDetails="defaultOpenedDetails[0]"
                    @sendChartCc="convertImgCc"
                  />
                </div>
                <div class="columns is-multiline">
                  <div class="column is-2">
                    <div class="circleFCMoyenne1">
                      <div class="circleFCMoyenne2">
                        <p class="circleFCMoyenne3 has-text-weight-bold">
                          <span class="is-size-4">
                            {{
                              props.row.fcMoyenne ? props.row.fcMoyenne : "0"
                            }}
                          </span>
                          bpm
                        </p>
                      </div>
                    </div>
                    <p
                      class="has-text-weight-semibold has-text-white has-text-centered is-margin-t-20"
                    >
                      {{ $t("moyenneFrequenceCardiaque") }}
                    </p>
                  </div>
                  <div class="column is-2">
                    <div class="circleFCMoyenne1">
                      <div
                        class="circleFCMoyenne4"
                        :style="{
                          'background-color': props.row.couleurCCMoyenne,
                        }"
                      >
                        <p class="circleFCMoyenne3 has-text-weight-bold">
                          <span class="is-size-4">
                            {{
                              props.row.ccMoyenne ? props.row.ccMoyenne : "0"
                            }}
                          </span>
                          %
                        </p>
                      </div>
                    </div>
                    <p
                      class="has-text-weight-semibold has-text-white has-text-centered is-margin-t-20"
                    >
                      {{ $t("moyenneCc") }}
                    </p>
                  </div>
                  <div
                    class="column is-5"
                    style="
                      display: flex;
                      text-align: center;
                      flex-direction: column;
                      justify-content: space-around;
                      padding-bottom: 0%;
                    "
                  >
                    <div class="columns">
                      <div class="column is-size-4 has-text-centered">
                        {{
                          props.row.valeurRouge ? props.row.valeurRouge : "0"
                        }}%
                        <div class="respi_progress_bar_result">
                          <div
                            class="cc_red_result"
                            :style="{
                              width: props.row.valeurRouge
                                ? props.row.valeurRouge + '%'
                                : 0 + '%',
                            }"
                          ></div>
                        </div>
                      </div>
                      <div class="column is-size-4 has-text-centered">
                        {{
                          props.row.valeurOrange ? props.row.valeurOrange : "0"
                        }}
                        %
                        <div class="respi_progress_bar_result">
                          <div
                            class="cc_orange_result"
                            :style="{
                              width: props.row.valeurOrange
                                ? props.row.valeurOrange + '%'
                                : 0 + '%',
                            }"
                          ></div>
                        </div>
                      </div>
                      <div class="column is-size-4 has-text-centered">
                        {{
                          props.row.valeurVerte ? props.row.valeurVerte : "0"
                        }}%
                        <div class="respi_progress_bar_result">
                          <div
                            class="cc_green_result"
                            :style="{
                              width: props.row.valeurVerte
                                ? props.row.valeurVerte + '%'
                                : 0 + '%',
                            }"
                          ></div>
                        </div>
                      </div>
                    </div>
                    <p
                      class="has-text-white has-text-centered has-text-weight-semibold"
                    >
                      % {{ $t("pourcentTempsPasseZoneCoherence") }}
                    </p>
                  </div>
                  <div
                    class="column is-3"
                    style="
                      display: flex;
                      text-align: center;
                      flex-direction: column;
                      justify-content: space-around;
                      padding-bottom: 8%;
                    "
                  >
                    <div>
                      <span
                        class="has-text-weight-bold has-text-white has-text-centered is-margin-t-50 is-size-4"
                      >
                        {{ $t("duree") }}
                      </span>
                      <span
                        style="
                          border: 2px solid rgba(255, 255, 255, 0.5);
                          background-color: rgba(0, 0, 0, 0.5);
                          opacity: 0.8;
                        "
                        class="has-text-weight-bold has-text-cyan has-text-centered is-margin-t-20 is-size-4 labelChrono"
                      >
                        {{ props.row.labelChrono }}
                      </span>
                      <div
                        style="
                          text-align: center;
                          padding: 10px;
                          display: flex;
                          flex-direction: column;
                        "
                      >
                        <button
                          style="
                            background: #46bfcb;
                            border-radius: 5px;
                            color: white;
                            font-size: medium;
                          "
                          @click="showRelecture(props.row, true)"
                        >
                          Imprimer
                        </button>
                        <button
                          style="
                            background: #46bfcb;
                            border-radius: 5px;
                            color: white;
                            font-size: medium;
                          "
                          @click="downloadRelecture(props.row, true)"
                        >
                          Télécharger
                        </button>
                        <!-- <button style="
                          background: #46bfcb;
                          border-radius: 5px;
                          color: white;
                          font-size: medium;
                        " @click="fullscreenRelecture()">
                        Mode Plein ecran
                      </button> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </b-table>
        </div>
        <div v-else>
          <div v-if="isLoading">Chargement en cours</div>
          <div v-else>Aucun résultat.</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { getAllFolders } from "@/services/folders/getAllFolders.js";
import asyncForEach from "@/services/general/asyncForEach.js";
import { getObjectByTypeAndValue } from "@/services/parse/getObject.js";
import chartFcVue from "./chartFc.vue";
import chartCcVue from "./chartCc.vue";
// import chartBrique from "./chartBrique.vue";
// import chartBriqueUser from "./chartBriqueUser.vue";
// import presentationProtocole from "./presentationProtocole.vue";
// import chartQuestionnaire from "./chartQuestionnaire.vue";
// import chartQuestionnaireV2 from "./chartQuestionnairev2.vue";
import chartProtocoleLibreVue from "./chartProtocoleLibre.vue";
import chartProtocoleGuideVue from "./chartProtocoleGuide.vue";
// import exportPdfVue from "./exportPdf.vue";
// import exportPdfVueHautDroit from "./exportPdfHautDroit.vue";
import {
  sortInvArrayByParam,
  sortArrayByParam,
} from "@/services/general/sortArrayByParam.js";

export default {
  name: "suivieCc",
  props: {
    utilisateurs: null,
    userSelected: null,
    folders: null,
    userParticulier: null,
    IUserSelected: null,
    fileCCId: null,
    idprotocole: null,
    ResultatsRessources: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    chartFcVue: chartFcVue,
    chartCcVue: chartCcVue,
    chartProtocoleLibreVue: chartProtocoleLibreVue,
    chartProtocoleGuideVue: chartProtocoleGuideVue,
    // exportPdfVue: exportPdfVue,
    // exportPdfVueHautDroit: exportPdfVueHautDroit,
    // chartBrique: chartBrique,
    // chartQuestionnaire: chartQuestionnaire,
    // chartQuestionnaireV2: chartQuestionnaireV2,
    // presentationProtocole: presentationProtocole,
    // chartBriqueUser: chartBriqueUser,
  },
  data() {
    return {
      imageChartFc: null,
      imageChartCc: null,
      props: null,
      isFullScreen: false,
      isLoading: false,
      isCardResultat: false,
      FoldersBrique: null,
      nbUtilisateur: {},
      showFront: true,
      foldersUtilisateursSelected: null,
      resultatCoherenceCardiaque: [],
      defaultOpenedDetails: [],
      tempsZone1: 0,
      tempsZone2: 0,
      tempsZone3: 0,
      valeurCCCourante: 0,
      valeurTempsPrecedent: 0,
      labelProfil: null,
      profil: null,
      cpm: null,
      valeurX: [],
      tabRowCc: [],
      tabRowFc: [],
      chart: false,
      valeurCcX: [],
      resultatObjet: null,
      protocolesLibreSelected: false,
      chartProtocoleLibre: false,
      tabRowProtocoleLibre: {},
      tabExo1: [],
      tabExo2: [],
      tabExo3: [],
      chartProtocoleGuide: false,
      userSelected2: "tous",
      cloneFoldersBrique: null,
      exercices: [],
      disabledStats: false,
      foldersTherapie: null,
      foldersCardiaque: null,
      foldersVie: null,
      foldersQcm: null,
      openEvaluationsPsychotechniques: true,
      openQcm: true,
      openTherapiesNonMedicamenteuses: true,
      openAccompagnementPsychophysiologique: true,
      dataUrl: "",
      nomTherapeute: "",
      utilisateurSelectionne: null,
      indefinteToast: null,
      titreFolderQcm: "",
      idExercice: "",
      protocole: "",
      fileID: "",
    };
  },
  methods: {
    toggleFullscreen(element) {
      if (
        !document.fullscreenElement &&
        !document.mozFullScreenElement &&
        !document.webkitFullscreenElement &&
        !document.msFullscreenElement
      ) {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.msRequestFullscreen) {
          element.msRequestFullscreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.webkitRequestFullscreen) {
          element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
        }
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        }
      }
    },
    fullscreenRelecture() {
      const myDiv = document.getElementById("relecture-component");
      this.toggleFullscreen(myDiv);
    },
    deleteRelecture(idExerciceSelected) {
      this.idExercice = idExerciceSelected;
      const ObjectFileDone = Parse.Object.extend("file_done");
      const QueryFileDone = new Parse.Query(ObjectFileDone);
      let ResponseFileDone = QueryFileDone.first();
      Parse.masterKey = "myMasterKey";
      QueryFileDone.equalTo("objectId", this.idExercice);
      ResponseFileDone.then(function (results) {
        return Parse.Object.destroyAll(results);
      });
      this.$buefy.toast.open({
        duration: 3000,
        message: "Avancement exercice supprimé avec succès.",
        type: "is-success",
      });
      this.$emit("closeModal", ResponseFileDone.id);
    },
    async traitement(utilisateurs) {
      var tmpFolders = cloneDeep(this.cloneFoldersBrique);
      this.FoldersBrique = null;
      this.foldersTherapie = null;
      this.foldersCardiaque = null;
      this.foldersVie = null;
      this.foldersQcm = null;

      await asyncForEach(tmpFolders, async (folder) => {
        if (
          this.folders.find((e) => {
            if (folder.objectId === e.id && e.deverrouille) return e;
          })
        ) {
          folder.deverrouille = true;
        }
        await asyncForEach(folder.foldersProtocole, async (protocole) => {
          protocole.somme = 0;
          protocole.sommeCabinet = 0;
          protocole.sommeLicence = 0;
          protocole.sommeAffiliation = 0;
        });
      });
      var listeUtilisateurJson = JSON.stringify(
        utilisateurs.filter((e) => {
          return (
            e.attributes.acces_donnees_therapeute !== undefined &&
            e.attributes.acces_donnees_therapeute
          );
        })
      );
      var resultat = await Parse.Cloud.run("analyseStat", {
        utilisateurs: listeUtilisateurJson,
        foldersBrique: tmpFolders,
      });
      var monObject = JSON.parse(resultat);
      this.FoldersBrique = cloneDeep(monObject);
      this.foldersTherapie = cloneDeep(
        this.FoldersBrique.filter((e) => {
          if (e.deverrouille && e.theme && e.theme.ordre === 1) {
            return e;
          }
        })
      );

      this.foldersCardiaque = this.FoldersBrique.filter((e) => {
        if (e.deverrouille && e.name && e.theme.ordre === 4) {
          return e;
        }
      });
      this.foldersVie = this.FoldersBrique.filter((e) => {
        if (e.deverrouille && e.theme && e.theme.ordre === 5) {
          return e;
        }
      });
      this.foldersQcm = this.FoldersBrique.filter((e) => {
        if (e.deverrouille && e.theme && e.theme.ordre === 7) {
          return e;
        }
      });
      var queryDomaine = new Parse.Query("domaine");
      queryDomaine.equalTo("nom", "Santé");
      var domaine = await queryDomaine.first();
      var queryTheme = new Parse.Query("theme");
      queryTheme.equalTo("domaine", domaine);
      queryTheme.equalTo("ordre", 7);
      var theme = await queryTheme.first();
      this.titreFolderQcm = theme.attributes.name;
    },
    async getFileLinkCompleted(userSelected) {
      userSelected = this.userSelected2;
      this.isLoading = true;
      this.resultatCoherenceCardiaque = [];
      this.tabRowProtocoleLibre = {
        label: [],
        data: [],
        color: [],
      };
      this.chartProtocoleLibre = false;
      this.chartProtocoleGuide = false;
      const ObjectFileType = Parse.Object.extend("file_type");
      const ObjectFile = Parse.Object.extend("file");
      const ObjectLink = Parse.Object.extend("link");
      const ObjectLinkCompleted = Parse.Object.extend("link_completed");
      const ObjectFolder = Parse.Object.extend("folder");
      const ObjectFolderType = Parse.Object.extend("folder_type");
      const ObjectFileDone = Parse.Object.extend("file_done");

      const QueryFileType = new Parse.Query(ObjectFileType);
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryLink = new Parse.Query(ObjectLink);
      const QueryLinkCompleted = new Parse.Query(ObjectLinkCompleted);
      const QueryFolder = new Parse.Query(ObjectFolder);
      const QueryFolderType = new Parse.Query(ObjectFolderType);
      const QueryFileDone = new Parse.Query(ObjectFileDone);
      if (this.protocolesLibreSelected) {
        QueryFileType.containedIn("name", ["Module Expert", "Jeux"]);
        QueryFile.matchesQuery("type", QueryFileType);
        QueryFile.containedIn("objectId", [this.fileID]);
        QueryFolderType.equalTo("name", "Exercices libres");
        QueryFolder.matchesQuery("type", QueryFolderType);
        QueryFileDone.matchesQuery("file", QueryFile);
        QueryFileDone.matchesQuery("protocole", QueryFolder);
        // Faire monter toutes les recherches de table et filtrer par utilisateurs avant d'effectuer les filtres necessaires a l'affichage - UH
        if (userSelected !== "tous") {
          // retirer le If car unique condition - UH
          QueryFileDone.equalTo("user", userSelected);
          QueryFileDone.include("file");
          QueryFileDone.include("protocole");
          let ResponseFileDone = await QueryFileDone.find();
          ResponseFileDone = sortArrayByParam(ResponseFileDone, "updatedAt");

          await ResponseFileDone.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);
              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
              return true;
            } catch (error) {
              return false;
            }
          }).forEach((valeur) => {
            this.resultatCoherenceCardiaque.push(valeur);
            const ccMoyenne = this.valeurCCMoyenne(valeur);
            const jourExo = moment(valeur.updatedAt);
            let maCouleur = "";
            if (ccMoyenne < 60) {
              maCouleur = "#FE0000";
            } else {
              if (ccMoyenne < 90) {
                maCouleur = "#FFC801";
              } else {
                maCouleur = "#51C901";
              }
            }
            if (ccMoyenne !== 0) {
              this.tabRowProtocoleLibre.label.push(
                jourExo.format("DD/MM/YYYY") +
                  "- cc:" +
                  ccMoyenne +
                  " - " +
                  valeur.attributes.file.attributes.name
              );
              this.tabRowProtocoleLibre.data.push(ccMoyenne);
              this.tabRowProtocoleLibre.color.push(maCouleur);
            }
          });
          this.chartProtocoleLibre = true;
        }
      } else {
        QueryFileType.containedIn("name", ["Module Expert", "Jeux"]);
        QueryFile.matchesQuery("type", QueryFileType);
        QueryFile.containedIn("objectId", [this.fileID]);
        QueryLink.matchesQuery("file", QueryFile);
        QueryLinkCompleted.equalTo("user", userSelected);
        QueryLinkCompleted.matchesQuery("link", QueryLink);
        QueryLinkCompleted.include("link");
        QueryLinkCompleted.include("link.file");
        QueryLinkCompleted.include("link.parent_folder");
        let ResponseLinkCompleted = await QueryLinkCompleted.find();
        ResponseLinkCompleted = sortArrayByParam(
          ResponseLinkCompleted,
          "updatedAt"
        );
        this.tabExo1 = {
          label: [],
          data: [],
          color: [],
        };
        this.tabExo2 = {
          label: [],
          data: [],
          color: [],
        };
        this.tabExo3 = {
          label: [],
          data: [],
          color: [],
        };

        await asyncForEach(
          ResponseLinkCompleted.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);

              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
              return true;
            } catch (error) {
              return false;
            }
          }),
          async (e) => {
            this.resultatCoherenceCardiaque.push(e);
            const ccMoyenne = this.valeurCCMoyenne(e);
            const jourExo = moment(e.updatedAt);
            let maCouleur = "";
            if (ccMoyenne < 60) {
              maCouleur = "#FE0000";
            } else {
              if (ccMoyenne < 90) {
                maCouleur = "#FFC801";
              } else {
                maCouleur = "#51C901";
              }
            }
            const parent = await getObjectByTypeAndValue(
              "link",
              "folder",
              e.attributes.link.attributes.parent_folder
            );
            if (
              parent.attributes.folder.attributes.name.includes("Installer") &&
              ccMoyenne
            ) {
              this.tabExo1.label.push(
                jourExo.format("DD/MM/YYYY") +
                  "- cc:" +
                  ccMoyenne +
                  " - " +
                  e.attributes.link.attributes.file.attributes.name
              );
              this.tabExo1.data.push(ccMoyenne);
              this.tabExo1.color.push(maCouleur);
            }
            if (
              parent.attributes.folder.attributes.name.includes("Maîtriser") &&
              ccMoyenne
            ) {
              this.tabExo2.label.push(
                jourExo.format("DD/MM/YYYY") +
                  "- cc:" +
                  ccMoyenne +
                  " - " +
                  e.attributes.link.attributes.file.attributes.name
              );
              this.tabExo2.data.push(ccMoyenne);
              this.tabExo3.color.push(maCouleur);
            }
            if (
              parent.attributes.folder.attributes.name.includes("Ancrer") &&
              ccMoyenne
            ) {
              this.tabExo1.label.push(
                jourExo.format("DD/MM/YYYY") +
                  "- cc:" +
                  ccMoyenne +
                  " - " +
                  e.attributes.link.attributes.file.attributes.name
              );
              this.tabExo3.data.push(ccMoyenne);
              this.tabExo3.color.push(maCouleur);
            }
          }
        );
        this.chartProtocoleGuide = true;
      }
      this.resultatCoherenceCardiaque = sortInvArrayByParam(
        this.resultatCoherenceCardiaque,
        "updatedAt"
      );
      this.isLoading = false;
    },
    valeurDifficulte(link) {
      if (!link) return "";
      const resultatJeux = link.attributes.resultatJeux;
      if (!resultatJeux) return 0;
      try {
        const resultatJeuxFormat = JSON.parse(resultatJeux);
        const difficulte = resultatJeuxFormat.Difficulty;
        switch (difficulte) {
          case 0:
            return "facile";
          case 1:
            return "moyen";
          case 2:
            return "difficile";
        }
      } catch (e) {
        //console.log(e);
        return "facile";
      }
    },
    valeurFCMoyenne(link) {
      if (!link) return "";
      const resultatJeux = link.attributes.resultatJeux;
      if (!resultatJeux) return "0bpm";
      try {
        const resultatJeuxFormat = JSON.parse(resultatJeux);
        if (!resultatJeuxFormat.BpmCC) return "0bpm";
        return Math.round(resultatJeuxFormat.BpmCC) + "bpm";
      } catch (e) {
        //console.log(e);
        return "0bpm";
      }
    },
    valeurCCMoyenne(link) {
      if (!link) return "";
      const resultatJeux = link.attributes.resultatJeux;
      if (!resultatJeux) return 0;
      try {
        const resultatJeuxFormat = JSON.parse(resultatJeux);
        return resultatJeuxFormat.AverageCC;
      } catch (error){
        //console.log(error)
        return 0;
      }
    },
    toDataUrl(url, contexte, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          contexte.dataUrl = reader.result;
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    downloadRelecture(link) {
      const resultatChaine = link.attributes.resultatJeux;
      var download = document.createElement("a");
      download.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(resultatChaine)
      );
      download.setAttribute("download", "Donnes.json");
      download.click();
    },
    showRelecture(link, isPrint) {
      this.props = link;
      if (
        !isPrint &&
        this.defaultOpenedDetails.find((e) => {
          return e === link.id;
        })
      ) {
        this.defaultOpenedDetails = [];
        return;
      }
      if (
        !this.defaultOpenedDetails.find((e) => {
          return e === link.id;
        })
      ) {
        this.imageChartFc = null;
        this.imageChartCc = null;
      }
      this.valeurX = [];
      this.tabRowCc = [];
      this.tabRowFc = [];
      this.valeurCcX = [];
      this.resultatObjet = null;
      this.defaultOpenedDetails = [link.id];
      const resultatChaine = link.attributes.resultatJeux;
      if (!resultatChaine) return;
      if (resultatChaine.indexOf("undefined") !== -1) return;
      if (resultatChaine.indexOf("NaN") !== -1) return;
      this.resultatObjet = JSON.parse(resultatChaine);
      this.resultatObjet.ArrayTacho.forEach((valTacho) => {
        if (valTacho.x / 1000 >= 30) {
          if (Number.isInteger(valTacho.x / 1000)) {
            this.valeurX.push(valTacho.x / 1000);
            this.tabRowFc.push(valTacho.y);
          }
        }
      });
      this.resultatObjet.TabCC.forEach((valCc) => {
        if (valCc.x / 1000 >= 30) {
          if (Number.isInteger(valCc.x / 1000)) {
            this.tabRowCc.push(valCc.y);
            this.valeurCcX.push(valCc.x / 1000);
          }
        }
      });

      if (this.resultatObjet.AverageCC < 60) {
        link.couleurCCMoyenne = "#FE0000";
      } else {
        if (this.resultatObjet.AverageCC < 85) {
          link.couleurCCMoyenne = "#FFC801";
        } else {
          link.couleurCCMoyenne = "#51C901";
        }
      }
      this.analyseCC(this.resultatObjet, link);
      this.cpm = this.resultatObjet.Cpm;
      this.profil = this.resultatObjet.Profil;
      switch (this.profil) {
        case "0":
          this.labelProfil = "Profonde";
          break;
        case "1":
          this.labelProfil = "Basique";
          break;
        case "2":
          this.labelProfil = "Tonique";
          break;
      }
      if (
        link.attributes.file !== undefined &&
        link.attributes.file.attributes.game_type !== undefined
      )
        this.resultatObjet.TimeCompletion =
          this.resultatObjet.TimeCompletion * 60;
      link.valeurChrono = this.resultatObjet.TimeCompletion;
      var minutes = Math.floor(link.valeurChrono / 60);
      var seconds = link.valeurChrono % 60;
      link.ImageUri = this.imageChartFc;
      link.ImageUriCc = this.imageChartCc;
      link.labelChrono =
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);
      link.realiseLe = moment(link.updatedAt).format("DD/MM/YYYY, HH:mm");
      this.valeurInspi = this.resultatObjet.Inspi;
      this.valeurPauseInspi = this.resultatObjet.PauseInspi;
      this.valeurExpi = this.resultatObjet.Expi;
      this.valeurPauseExpi = this.resultatObjet.PauseExpi;
      link.ccMoyenne = Math.round(this.resultatObjet.AverageCC);
      link.fcMoyenne = Math.round(this.resultatObjet.BpmCC);
      link.ccMoyenneInitiale = this.resultatObjet.AverageCC;
      this.chart = true;

      if (isPrint) {
        setTimeout(() => {
          this.exportPdfV2(link);
        }, 2000);
      }
    },
    analyseCC(tableauCC, link) {
      link.valeurRouge = tableauCC.Pct1;
      link.valeurOrange = tableauCC.Pct2;
      link.valeurVerte = tableauCC.Pct3;
    },
    convertImgCc(e) {
      this.imageChartCc = e;
    },
    convertImgFc(e) {
      this.imageChartFc = e;
    },
    exportPdfV2(link) {
      const resultatJeuxFormat = JSON.parse(link.attributes.resultatJeux);
      var element =
        "<!DOCTYPE html><html><head>" + document.head.innerHTML + "</head>";
      element = element + "<body>";
      element = element + '<div class="columns">'; // Haut de page
      element = element + '<div class="column is-half">'; //Colonne 1
      element =
        element +
        '<div><label>Utilisateur : </label><label style="color:grey;padding-left : 2px;"> ' +
        link.attributes.user.attributes.username +
        "</label></div>";
      element =
        element +
        '<div><label>Suivi par : </label><label style="color:grey;padding-left : 2px;"> ' +
        this.nomTherapeute +
        "</label></div>";
      element =
        element +
        '<div><label>Séance : </label><label style="color:grey;padding-left : 2px;"> ' +
        (link.attributes.link === undefined
          ? link.attributes.brique.attributes.name
          : link.attributes.link.attributes.parent_folder.attributes.name) +
        "</label></div>";
      element =
        element +
        '<div><label>Exercice : </label><label style="color:grey;padding-left : 2px;"> ' +
        (link.attributes.link === undefined
          ? link.attributes.file.attributes.name
          : link.attributes.link.attributes.file.attributes.name) +
        "</label></div>";
      element =
        element +
        '<div><label>Durée : </label><label style="color:grey;padding-left : 2px;"> ' +
        link.labelChrono +
        "</label></div>";
      element =
        element +
        '<div><label>Réalisé le : </label><label style="color:grey;padding-left : 2px;"> ' +
        moment(link.updatedAt).format("DD/MM/YYYY, HH:mm") +
        "</label></div>";
      element = element + "</div>"; // Fin colonne 1
      element = element + '<div class="column is-half">'; // Colonne2
      element =
        element +
        '<div style="text-align: center;color: grey;font-size: medium;"><label>Paramètres respiratoires de l\'exercice</label></div>';
      var profil =
        resultatJeuxFormat.Profil == "2"
          ? "TONIQUE"
          : resultatJeuxFormat.Profil == "0"
          ? "PROFONDE"
          : "BASIQUE";
      element =
        element +
        '<div layout = "row" style=" width: 33%;float: left;text-align: center;border-right: black;border-right-style: solid;border-right-width: 1px;"><div style="font-size: smaller;"><label>CPM</label></div><div  style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.Cpm +
        '</label></div><div><label style="font-size: smaller;">Profil</label></div><div><label  style="font-size: large;background: darkgrey;color: turquoise;">' +
        profil +
        "</label></div></div>"; // colonne 1
      element =
        element +
        '<div layout = "row" style=" width: 33%;float: left;text-align: center;border-right: black;border-right-style: solid;border-right-width: 1px;"><div style="font-size: smaller;"><label>INSPIRATION</label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.Inspi +
        '</label></div><div  style="font-size: smaller;"><label>PAUSE D\'INSPIRATION</label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.PauseInspi +
        "</label></div></div>"; // colonne 2
      element =
        element +
        '<div layout = "row" style = "text-align: center;" ><div style="font-size: smaller;"><label>EXPIRATION</label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.Expi +
        '</label></div><div style="font-size: smaller;"><label>PAUSE D\'EXPIRATION </label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.PauseExpi +
        "</label></div></div>"; // colonne 3

      element = element + "</div>"; // Fin colonne2

      element = element + "</div>"; // Fin haut de page

      element = element + '<div class="columns">'; //Milieu de page

      element =
        element +
        '<div class="column" style="text-align: center;min-height:350px;">';
      element =
        element +
        '<img style="height: 200px" src="' +
        this.imageChartFc +
        '" />';
      element =
        element +
        '<img style="height: 150px" src="' +
        this.imageChartCc +
        '" />';
      element = element + "</div>";

      element = element + "</div>"; // Fin Mileu de page

      element = element + '<div class="columns">'; // Début bas de page
      element = element + '<div class="column is-one-fifth">';
      element = element + '<div class="RondBpmCcEdition" >';
      element = element + '<div class="RondBpm2Result">';
      element =
        element +
        '<div style="position: relative;top: 50%; transform: translateY(-50%);"><label class="labelBpmResult">' +
        link.fcMoyenne +
        '</label><label class="labelBpmDetail">bpm</label></div>';
      element = element + "</div>";
      element = element + "</div>";
      element =
        element +
        '<div class="expert_label_battement_result_edition"><label>Moyenne fréquence cardiaque</label></div>';
      element = element + "</div>";
      element = element + '<div class="column" >';

      element = element + '<div class="columns">'; // Zone barre
      element = element + '<div  class="column">';
      element =
        element +
        '<div class="respi_text_bar_resultEdition"><label> ' +
        link.valeurRouge +
        "%</label> </div>";
      element =
        element +
        '<div class="respi_progress_bar_resultEdition"><div class="cc_red_result"  style="width: ' +
        link.valeurRouge +
        '%;"></div>';
      element = element + "</div></div>";
      element = element + '<div class="column">';
      element =
        element +
        ' <div class="respi_text_bar_resultEdition"><label> ' +
        link.valeurOrange +
        "%</label></div>";
      element =
        element +
        '<div class="respi_progress_bar_resultEdition"> <div class="cc_orange_result"  style="width: ' +
        link.valeurOrange +
        '%;"></div>';
      element = element + "</div></div>";
      element = element + '<div class="column">';
      element =
        element +
        '<div class="respi_text_bar_resultEdition"><label> ' +
        link.valeurVerte +
        "%</label></div>";
      element =
        element +
        '<div class="respi_progress_bar_resultEdition"><div class="cc_green_result"  style="width: ' +
        link.valeurVerte +
        '%;"></div>';
      element = element + "</div> </div>";
      element = element + "</div>";

      element = element + '<div class="columns">'; // Zone en dessous des barres
      element =
        element +
        '<div class="expert_result_zone_coherence_1Edition column is-one-fifth"></div><div class="expert_result_zone_coherence_2 column"></div><div class="expert_result_zone_coherence_3Edition column is-one-fifth"></div>';
      element = element + "</div>";
      element =
        element +
        '<div class="expert_texte_zone_coherenceEdition"><label>% DE TEMPS PASSE DANS CHAQUE ZONE DE COHERENCE</label></div>';
      element =
        element +
        '<div style="margin: auto; background-color: transparent;" class="columns"><div class ="column" style="border: black;border-style: solid;border-width: 1px;padding: 5px;margin: 5px;"><p><label>Commentaire : </label></p><p>' +
        (link.comment === undefined ? "" : link.comment) +
        "</p></div></div>";
      element = element + "</div>";
      element =
        element + '<div style="height: 200px;"  class="column is-one-fifth">';
      element =
        element +
        '<div class="RondBpmCcEdition" style="background-color: ' +
        link.couleurCCMoyenne +
        ';">';
      element = element + '<div class="RondBpm2Result">';
      element =
        element +
        '<div style="position: relative;top: 50%; transform: translateY(-50%);"><label class="labelBpmResult ng-binding">' +
        link.ccMoyenne +
        '</label><label class="labelBpmDetail"> %</label></div>';
      element = element + "</div>";
      element = element + "</div>";
      element =
        element +
        '<div class="expert_label_battement_result_edition"><label>Moyenne cohérence cardiaque</label></div>';

      element = element + "</div>";
      element = element + "</div>"; // Fin bas de page
      element = element + "</div>";
      var imageSymbioCenter =
        "<img style='height:25px;' src='" + this.dataUrl + "'/>";
      element =
        element +
        "</body><footer style='background-color:#1E2C39;'><div style='width:50%;display:inline-block;'>" +
        imageSymbioCenter +
        "</div><div style='text-align:right;color:white;height:100%;width:50%;display:inline-block;'>" +
        moment().format("[Le] DD/MM/YYYY [à] hh:mm") +
        "</div></footer></html>";
      var opt = {
        margin: [0.5, 0.5, 0, 0.5],
        filename: "resultat.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { dpi: 300 },
        jsPDF: { unit: "mm", format: "a4", orientation: "l" },
      };
      html2pdf(element, opt);
    },
    exportPdf() {
      var doc = new jsPDF("l");
      var source = window.document.getElementById("export-pdf");
      var sourceHautDroit = window.document.getElementById(
        "export-pdf-hautdroit"
      );
      doc.fromHTML(source, 10, 10);
      doc.fromHTML(sourceHautDroit, 200, 10);
      doc.addImage(this.imageChartFc, "PNG", 50, 65, 200, 60);
      doc.addImage(this.imageChartCc, "PNG", 50, 125, 200, 60);
      doc.save("resultat.pdf");
    },
  },
  async mounted() {
    this.userSelected2 = this.utilisateurs;
    this.selection = this.idprotocole;
    this.fileID = this.fileCCId;
    if (this.selection == "Entraînement libre") {
      this.protocolesLibreSelected = true;
    } else {
      this.protocolesLibreSelected = false;
    }
    if (this.userParticulier !== "") {
      this.indefinteToast = this.$buefy.toast.open({
        duration: 10000000,
        message: `Veuillez patienter pendant l'ouverture des résultats.`,
        type: "is-warning",
      });
    }
    ParseConfig();
    const loadingComponent = this.$buefy.loading.open();
    this.toDataUrl(
      "https://www.symbiocenter.com/assets/symbiocenter.png",
      this,
      function () {
        return;
      }
    );

    var therapeute = await Parse.User.current();

    this.nomTherapeute =
      (therapeute.attributes.firstname !== undefined
        ? therapeute.attributes.firstname
        : "") +
      " " +
      (therapeute.attributes.lastname !== undefined
        ? therapeute.attributes.lastname
        : "");

    try {
      this.FoldersBrique = await getAllFolders();
      this.cloneFoldersBrique = cloneDeep(this.FoldersBrique);
      this.nbUtilisateur.cabinet = this.utilisateurs.filter((utilisateur) => {
        return (
          utilisateur.attributes.type_pratique === "cabinet" &&
          !utilisateur.attributes.affiliation
        );
      }).length;
      this.nbUtilisateur.licence = this.utilisateurs.filter((utilisateur) => {
        return (
          utilisateur.attributes.type_pratique === "licence" &&
          !utilisateur.attributes.affiliation
        );
      }).length;
      this.nbUtilisateur.affiliation = this.utilisateurs.filter(
        (utilisateur) => {
          return utilisateur.attributes.affiliation;
        }
      ).length;
      this.nbUtilisateur.total =
        this.nbUtilisateur.cabinet +
        this.nbUtilisateur.licence +
        this.nbUtilisateur.affiliation;

      await this.traitement(this.utilisateurs);

      loadingComponent.close();
    } catch (error) {
      loadingComponent.close();
    }
    await this.getFileLinkCompleted(this.userSelected);
    if (this.userParticulier !== "") {
      this.utilisateurSelectionne = this.utilisateurs.filter((utilisateur) => {
        return utilisateur.attributes.username === this.userParticulier;
      });
      this.isCardResultat = true;
      if (this.indefinteToast) {
        this.indefinteToast.close();
        this.indefinteToast = null;
      }
    }
  },
  watch: {
    async userSelected() {
      const loadingComponent = this.$buefy.loading.open();
      this.foldersUtilisateursSelected = null;
      if (this.userSelected !== "tous") {
        this.foldersUtilisateursSelected = this.utilisateurs.find((e) => {
          return e.id === this.userSelected.id;
        });
        var listeUser = this.utilisateurs.filter((e) => {
          return e.id === this.userSelected.id;
        });
        await this.traitement(listeUser);
        await this.getFileLinkCompleted(this.foldersUtilisateursSelected);
        loadingComponent.close();
      } else {
        await this.traitement(this.utilisateurs);
        await this.getFileLinkCompleted("tous");
        loadingComponent.close();
      }
      loadingComponent.close();
    },

    async userSelected2() {
      await this.getFileLinkCompleted(this.userSelected2);
    },

    async protocolesLibreSelected() {
      await this.getFileLinkCompleted(this.userSelected2);
    },
    isCardResultat() {
      this.userSelected2 = cloneDeep(this.userSelected);

      if (this.utilisateurSelectionne !== null)
        this.userSelected2 = this.utilisateurSelectionne[0];
    },
    exerciceSelected() {
      if (
        this.exerciceSelected &&
        this.exerciceSelected !== "tous" &&
        this.resultatCoherenceCardiaque
      ) {
        const resultatsFilter = this.resultatCoherenceCardiaque.filter((e) => {
          return e.attributes.link.id === this.exerciceSelected.id;
        });
        if (resultatsFilter && resultatsFilter.length) {
          //console.log("resultatsFilter", resultatsFilter);
        }
      }
    },
  },
};
</script>
<style>
.colonneUnEdition {
  width: 170px;
  float: left;
  margin-left: 20px;
}

.colonneDeuxEdition {
  width: 700px;
  float: left;
}

.colonneTroisEdition {
  width: 170px;
  float: left;
  margin-left: 20px;
}

.RondBpm2Result {
  border-style: none;
  border-width: 0px;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  text-align: center;
  margin: auto;
  background-color: white;
  -moz-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -o-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=rgba(0, 0, 0, 0.25), Direction=239, Strength=5);
}

.labelCCMoyenne {
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-size: x-large;
  font-weight: bold;
  /* margin-top: 10px; */
}

.expert_label_battement {
  width: 50%;
  text-align: center;
  padding-top: 20px;
}

.expert_label_battement_result {
  width: 50%;
  text-align: center;
  padding-top: 5px;
  color: white;
  margin: auto;
}

.expert_label_battement_result_edition {
  width: 50%;
  text-align: center;
  padding-top: 5px;
  color: black;
  margin: auto;
  font-size: smaller;
}

.BlocBpm {
  padding-right: 40px;
  padding-left: 20px;
}

.respi_text_bar_result {
  text-align: center;
  width: 100px;
  padding-top: 5px;
  color: white;
}

.respi_text_bar_resultEdition {
  text-align: center;
  padding-top: 5px;
  color: black;
}

.labelBpm {
  font-size: xx-large;
  color: black;
  top: -12px;
}

.labelBpmResult {
  font-size: x-large;
  color: black;
  top: -12px;
}

.labelBpmDetail {
  color: black;
  top: -12px;
}

.blocZoneResult {
  width: 450px;

  @media screen and (max-width: 1600px) {
    width: 330px;
  }
}

.RondBpmCcEdition {
  border-style: solid;
  border-width: 3px;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  text-align: center;
  position: relative;
  padding-top: 12px;
  border-color: black;
  background-color: #46bfcb;
  margin: auto;
}

.respi_progress_bar_resultEdition {
  -moz-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -o-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=rgba(0, 0, 0, 0.25), Direction=239, Strength=5);
  width: 100%;
  height: 35px;
  float: left;
  margin: auto;
  border-radius: 14px;
  border-top-color: black;
  border-top-style: solid;
  border-top-width: 3px;
  border-right-color: black;
  border-right-style: solid;
  border-right-width: 10px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-left-color: black;
  border-left-style: solid;
  border-left-width: 10px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;

  .progress_bar {
    //background:cover;
    background: #3ec2cf;
    border-radius: 6px;
    width: 44px;
    position: relative;
  }

  @media screen and (max-width: 1600px) {
    width: 300px;
  }
}

.expert_result_zone_coherence_1 {
  text-align: center;
  height: 25px;
  width: 37%;
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-color: white;
  border-left-width: 1px;

  @media screen and (max-width: 1600px) {
    width: 32%;
  }
}

.expert_result_zone_coherence_1Edition {
  text-align: center;
  height: 25px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-left-width: 1px;
}

.expert_result_zone_coherence_2 {
  text-align: center;
  height: 25px;
  width: 26%;

  @media screen and (max-width: 1600px) {
    width: 36%;
  }
}

.expert_texte_zone_coherence {
  text-align: center;
  font-size: smaller;
  margin-top: -7px;
  color: white;
}

.expert_texte_zone_coherenceEdition {
  text-align: center;
  font-size: smaller;
  margin-top: -15px;
  color: black;
}

.expert_result_zone_coherence_3Edition {
  text-align: center;
  height: 25px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-right-width: 1px;
}
</style>
