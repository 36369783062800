export function sortArrayByParam(array, param) {
  array.sort((a, b) => {
    if (a[param] < b[param]) return -1;
    return 1;
  });
  return array;
}

export function sortInvArrayByParam(array, param) {
  array.sort((a, b) => {
    if (a[param] > b[param]) return -1;
    return 1;
  });
  return array;
}
