import Parse from "parse";
import store from "@/store/store";

class WebSocketService {
  constructor() {
    this.socket = null;
    this.onlineUser = null;
    this.onlineUserPromiseResolver = null;
  }

  connectToWebSocket() {
    console.log("Connect to WebSocket");
    const IdUser = Parse.User.current().id;
    this.socket = new WebSocket("wss://www.symbiocenter.com:8084");

    this.socket.addEventListener("open", (event) => {
      console.log("WebSocket connected", event);
      this.sendUserId(IdUser);

      // Stockez la socket dans le store Vuex
      store.commit("setSocket", this.socket);
    });

    this.socket.addEventListener("message", (event) => {
      console.log("Received message:", event.data);
      this.onlineUser = JSON.parse(event.data);

      // Résolvez la promesse lorsque l'onlineUser est mis à jour
      this.resolveOnlineUserPromise();
    });

    this.socket.addEventListener("close", (event) => {
      console.log("WebSocket closed", event);

      // Supprimez la socket du store Vuex lors de la fermeture
      store.commit("disconnectSocket");
    });
  }

  sendUserId(userId) {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(JSON.stringify({ userId }));
    }
  }

  closeWebSocket() {
    if (this.socket && this.socket.readyState === WebSocket.OPEN) {
      this.socket.close();
    }
  }

  // Ajoutez une méthode pour résoudre la promesse
  resolveOnlineUserPromise() {
    if (this.onlineUserPromiseResolver) {
      this.onlineUserPromiseResolver(this.onlineUser);
      this.onlineUserPromiseResolver = null; // Réinitialisez le résolveur
    }
  }

  // Ajoutez une méthode pour attendre la mise à jour de l'onlineUser
  awaitOnlineUserUpdate() {
    return new Promise((resolve) => {
      if (this.onlineUser !== null) {
        resolve(this.onlineUser);
      } else {
        this.onlineUserPromiseResolver = resolve;
      }
    });
  }
}

export default WebSocketService;
