export default function defineSteps(etapes, num) {
  etapes[0].active = false;
  etapes[1].active = false;
  etapes[2].active = false;
  etapes[3].active = false;
  etapes[4].active = false;
  etapes[0].completed = false;
  etapes[1].completed = false;
  etapes[2].completed = false;
  etapes[3].completed = false;
  etapes[4].completed = false;
  etapes[num].active = true;
  etapes[num].completed = false;
  if (etapes[4].active) {
    etapes[0].completed = true;
    etapes[1].completed = true;
    etapes[2].completed = true;
    etapes[3].completed = true;
  } else if (etapes[3].active) {
    etapes[0].completed = true;
    etapes[1].completed = true;
    etapes[2].completed = true;
  } else if (etapes[2].active) {
    etapes[0].completed = true;
    etapes[1].completed = true;
  } else if (etapes[1].active) {
    etapes[0].completed = true;
  }
}
