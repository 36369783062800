<template>
  <div>
    <div class="card editUser">
      <div class="card-content">
        <div class="content">
          <div class="overflow">
            <h3 class="title has-text-black is-size-5">
              {{ $t("relanceUtilisateur") }}
            </h3>
            <div class="columns">
              <div class="column is-6">
                <b-field custom-class="is-small" label="Nom">
                  <b-input
                    disabled
                    size="is-small"
                    v-model="UserSelected.nom"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field custom-class="is-small" label="Prénom">
                  <b-input
                    disabled
                    size="is-small"
                    v-model="UserSelected.prenom"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div>
              <p>{{ $t("RelanceUtilisateurConnexion") }}</p>
              <p>{{ $t("RelanceUtilisateurConnexion2") }}</p>
              <br />
              <p>{{ $t("PersonnaliserLeMail") }}</p>
              <b-field>
                <b-input
                  v-model="mail"
                  maxlength="2000"
                  type="textarea"
                ></b-input>
              </b-field>
            </div>
            <div>
              <div class="is-pulled-left">
                <a class="button is-cyan" @click="sendMail()">
                  {{ $t("envoyerRelanceUtilisateur") }}
                </a>
              </div>
              <div class="is-pulled-right">
                <a class="button is-grey" @click="$emit('closeModal')">
                  {{ $t("fermer") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Parse from "parse";

export default {
  name: "relanceUtilisateur",
  methods: {
    async sendMail() {
      var userCourant = await Parse.User.current();
      await Parse.Cloud.run("demandeRelanceConnexion", {
        destinataire: this.UserSelected.mail,
        pro:
          userCourant.attributes.firstname && userCourant.attributes.lastname
            ? userCourant.attributes.firstname +
              " " +
              userCourant.attributes.lastname
            : userCourant.attributes.username,
        mail: this.mail,
        utilisateur: this.UserSelected.prenom + " " + this.UserSelected.nom,
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: "Demande de relance envoyée avec succès.",
        type: "is-success",
      });
      this.$emit("closeModal");
    },
  },
  props: ["UserSelected"],
  data() {
    return {
      mail: "",
    };
  },
};
</script>
