<template>
  <div class="style">
    <iframe class="style" :src="url"></iframe>
  </div>
</template>

<script>
export default {
  props: ["url"],
};
</script>

<style>
iframe.style {
  height: 500px !important;
  border-radius: 5px;
  width: 2000px;
}
</style>
