import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

import user from "./user.js";

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    storeChartData: [],
    count: 1,
    unityInstance: null,
    socket: null,
    isConnected: false,
    onlineUsers: [],
  },
  getters: {
    getStoreChartData(state) {
      return state.storeChartData;
    },
    getCount(state) {
      return state.count;
    },
    onlineUser: state => state.onlineUser, 
  },

  mutations: {
    setStoreChartData(state, payload) {
      state.storeChartData = payload;
    },
    setCount(state) {
      state.count++;
    },
    setUnityInstance(state, instance) {
      state.unityInstance = instance;
    },
    setSocket(state, socket) {
      console.log("setSocket", socket);
      state.socket = socket;
      state.isConnected = true;
    },
    disconnectSocket(state) {
      state.socket.close();
      state.isConnected = false;
    },
    setOnlineUser(state, users) {
      state.onlineUsers = users;
    },
  },
  actions: {
    setStoreChartData({ commit }, payload) {
      commit("setStoreChartData", payload);
    },
    setCount({ commit }) {
      commit("setCount");
    },
  },
  modules: {
    user,
  },
  plugins: [
    createPersistedState({
      paths: ["user"],
    }),
  ],
});

export default store;
