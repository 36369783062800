import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
import asyncForEach from "@/services/general/asyncForEach.js";

export async function getFilesDoneByUserId(userId) {
  ParseConfig();
  const linkFileCompleted = [];
  const ObjectFileDone = Parse.Object.extend("file_done");
  const ObjectUser = Parse.Object.extend("User");

  const QueryUser = new Parse.Query(ObjectUser);
  QueryUser.equalTo("objectId", userId);
  const ResponseUser = await QueryUser.first();

  const QueryFileDone = new Parse.Query(ObjectFileDone);
  QueryFileDone.equalTo("user", ResponseUser);
  QueryFileDone.limit(10000);
  QueryFileDone.notEqualTo("ANI", true);
  QueryFileDone.include("file");
  QueryFileDone.include(["file.type"]);
  QueryFileDone.descending("updatedAt");
  const ResponseFileDone = await QueryFileDone.find();
  const QueryLinkCompleted = new Parse.Query(
    Parse.Object.extend("link_completed")
  );
  QueryLinkCompleted.equalTo("user", ResponseUser);
  QueryLinkCompleted.limit(10000);
  QueryLinkCompleted.include("link");
  QueryLinkCompleted.include(["link.file"]);
  QueryLinkCompleted.include(["link.file.type"]);
  QueryLinkCompleted.descending("createdAt");
  const ResponseLinkCompleted = await QueryLinkCompleted.find();

  var ObjectLink = Parse.Object.extend("link");
  var ObjectFile = Parse.Object.extend("file");
  var ObjectFolder = Parse.Object.extend("folder");
  var QueryLink = new Parse.Query(ObjectLink);
  QueryLink.limit(10000);
  let TabLinks = await QueryLink.find();
  var QueryFile = new Parse.Query(ObjectFile);
  QueryFile.limit(1000);
  QueryFile.select("id");
  QueryFile.select("questionnaire_");
  QueryFile.include("questionnaire_");
  let TabFiles = await QueryFile.find();
  var QueryModuleFolder = new Parse.Query(ObjectFolder);
  QueryModuleFolder.limit(1000);
  QueryModuleFolder.select("id", "name", "sub_name", "type");
  QueryModuleFolder.include("type");
  let TabFolders = await QueryModuleFolder.find();
  await asyncForEach(ResponseLinkCompleted, async (e) => {
    if (
      e.attributes.link === undefined ||
      e.attributes.link.attributes.file === undefined
    )
      return;
    var time_elapsed = 0;
    if (e.attributes.time_elapsed) {
      time_elapsed =
        Math.round(e.attributes.time_elapsed) *
        (e.attributes.link.attributes.file.attributes.type.attributes.name ===
        "Jeux"
          ? 60
          : 1);
    }
    if (time_elapsed <= 10 && e.attributes.note === undefined) {
      return;
    }
    if (time_elapsed < 60) {
      time_elapsed = 60;
    }
    var heure = null;
    if (new Date(e.updatedAt).getMinutes() <= 9) {
      heure =
        new Date(e.updatedAt).getHours() +
        ":0" +
        new Date(e.updatedAt).getMinutes();
    } else {
      heure =
        new Date(e.updatedAt).getHours() +
        ":" +
        new Date(e.updatedAt).getMinutes();
    }

    var Link = {
      time_elapsed: Math.round(time_elapsed / 60),
      createdAt: e.updatedAt,
      date: new Date(e.updatedAt).toLocaleDateString(),
      heure: heure,
      dateComplete: e.updatedAt,
    };
    let resultTabLink = TabLinks.filter(function (elt) {
      return e.attributes.link.id === elt.id;
    });
    let ResponseLink = resultTabLink[0];
    if (
      ResponseLink !== undefined &&
      ResponseLink.attributes.file &&
      ResponseLink.attributes.parent_folder
    ) {
      Link.noteQuestionnaire = e.attributes.note;
      if (e.attributes.note_detail) {
        Link.noteDetail = JSON.parse(e.attributes.note_detail);
      }
      if (e.attributes.resultatQuestionnaire) {
        Link.resultatQuestionnaire = JSON.parse(
          e.attributes.resultatQuestionnaire
        );
        // Parcourir le tableau resultatQuestionnaire
        Link.resultatQuestionnaire.forEach((result) => {
          // Récupérer la note et l'ID de la question pour chaque résultat
          var noteQuestionnaire = result.note;
          var questionId = result.question_id;

          // Initialiser un nouvel attribut 'text' pour chaque résultat
          result.text = "";

          // Rechercher la question et la réponse correspondant à la note
          var queryQuestion = new Parse.Query("question");
          queryQuestion.equalTo("objectId", questionId);
          var queryQuestionAnswer = new Parse.Query("question_answer");
          queryQuestionAnswer.matchesQuery("question", queryQuestion);
          queryQuestionAnswer.equalTo("points", noteQuestionnaire);

          // Récupérer l'attribut 'text' de la réponse et l'affecter à l'attribut 'text' du résultat
          queryQuestionAnswer.first().then((questionAnswer) => {
            if (questionAnswer) {
              result.text = questionAnswer.attributes.text;
            }
          });
        });
      }
      Link.id = e.attributes.link.id;
      if (!e.attributes.nbAcces) Link.nb_acces = 1;
      else Link.nb_acces = e.attributes.nbAcces + 1;
      let resultTabFiles = TabFiles.filter(function (elt) {
        return elt.id === ResponseLink.attributes.file.id;
      });
      let ResponseFile = resultTabFiles[0];
      let resultTabFolder = TabFolders.filter(function (elt) {
        return elt.id === ResponseLink.attributes.parent_folder.id;
      });
      let ResponseModuleFolder = resultTabFolder[0];
      let TabLinkProtocole = TabLinks.filter(function (elt) {
        return (
          elt.attributes.folder !== undefined &&
          ResponseModuleFolder.id === elt.attributes.folder.id
        );
      });
      let ResponseLinkProtocole = TabLinkProtocole[0];
      let resultTabProtocoleFolder = TabFolders.filter(function (elt) {
        return elt.id === ResponseLinkProtocole.attributes.parent_folder.id;
      });
      let ResponseProtocoleFolder = resultTabProtocoleFolder[0];
      let resultLinkBrique = TabLinks.filter(function (elt) {
        return (
          elt.attributes.folder !== undefined &&
          ResponseProtocoleFolder.id === elt.attributes.folder.id
        );
      });
      let ResponseLinkBrique = resultLinkBrique[0];
      let resultTabBriqueFolder = TabFolders.filter(function (elt) {
        return elt.id === ResponseLinkBrique.attributes.parent_folder.id;
      });
      let ResponseBriqueFolder = resultTabBriqueFolder[0];
      Link.brique = ResponseBriqueFolder.attributes.name;
      Link.protocole = ResponseProtocoleFolder.attributes.name;
      Link.moduleP = ResponseModuleFolder.attributes.name;
      Link.file = ResponseFile.attributes.sub_name;
      Link.fileId = ResponseFile.id;
      if (Link.moduleP === "S'auto-évaluer") {
        Link.nb_acces = Link.nb_acces - 1;
      }
      if (
        ResponseFile !== undefined &&
        ResponseFile.attributes.questionnaire_ !== undefined
      ) {
        Link.estQuestionnaire = true;
        Link.estModuleExpert = false;
        Link.Questionnaire = ResponseFile.attributes.questionnaire_;
        var resultQuery = new Parse.Query("question_result");
        resultQuery.equalTo("questionnaire", Link.Questionnaire);
        resultQuery.lessThanOrEqualTo(
          "required_points",
          parseInt(Link.noteQuestionnaire)
        );
        resultQuery.descending("required_points");
        var resultatAnalyse = await resultQuery.first();
        Link.analyseQuestionnaire = resultatAnalyse.attributes.text;
      } else {
        Link.estQuestionnaire = false;
        if (ResponseFile !== undefined && ResponseFile.attributes.avecCapteur)
          Link.estModuleExpert = true;
        else Link.estModuleExpert = false;
      }
      var avecResultat = false;
      avecResultat = !e.attributes.resultatJeux ? false : true;
      Link.estModuleExpert = Link.estModuleExpert || avecResultat;
      Link.estDetaillable =
        Link.estModuleExpert || Link.estQuestionnaire || avecResultat;
      linkFileCompleted.push(Link);
      linkFileCompleted.sort((a, b) => {
        if (a.dateComplete > b.dateComplete) return -1;
        return 1;
      });
    }
  });

  await asyncForEach(ResponseFileDone, async (e) => {
    let time_elapsed = 0;
    let file = null;
    var heure = null;
    if (new Date(e.updatedAt).getMinutes() <= 9) {
      heure =
        new Date(e.updatedAt).getHours() +
        ":0" +
        new Date(e.updatedAt).getMinutes();
    } else {
      heure =
        new Date(e.updatedAt).getHours() +
        ":" +
        new Date(e.updatedAt).getMinutes();
    }
    if (
      e.attributes.file === undefined ||
      e.attributes.file.attributes.type === undefined
    )
      return;
    if (e.attributes.time_elapsed) {
      time_elapsed =
        Math.round(e.attributes.time_elapsed) *
        (e.attributes.file.attributes.type.attributes.name === "Jeux" ? 60 : 1);
    }
    if (
      time_elapsed <= 10 &&
      e.attributes.file.attributes.type.attributes.name !== "Questionnaire"
    ) {
      return;
    }
    if (time_elapsed < 60) {
      time_elapsed = 60;
    }

    file = {
      time_elapsed: Math.round(time_elapsed / 60),
      createdAt: e.updatedAt,
      date: new Date(e.updatedAt).toLocaleDateString(),
      heure: heure,
      nb_acces: 1,
      dateComplete: e.updatedAt,
      id: e.id,
    };

    if (e.attributes.brique) {
      let resultTabFolderQuery = TabFolders.filter(function (elt) {
        return e.attributes.brique.id === elt.id;
      });
      let brique = resultTabFolderQuery[0];
      if (brique) {
        file.brique = brique.attributes.name;
      }
      let resultTabFolderProtocole = TabFolders.filter(function (elt) {
        return e.attributes.protocole.id === elt.id;
      });
      let protocole = resultTabFolderProtocole[0];
      if (protocole) {
        file.protocole = protocole.attributes.name;
        file.protocoleComplet = protocole;
      }
      let resultTabFolderModule = TabFolders.filter(function (elt) {
        return e.attributes.module.id === elt.id;
      });
      let moduleP = resultTabFolderModule[0];
      if (moduleP) {
        file.moduleP = moduleP.attributes.name;
      }
      let resultTabFileQuery = TabFiles.filter(function (elt) {
        return elt.id === e.attributes.file.id;
      });
      let fileResponse = resultTabFileQuery[0];
      if (fileResponse) {
        file.fileId = fileResponse.id;
        file.file =
          fileResponse.attributes.sub_name !== undefined
            ? fileResponse.attributes.sub_name
            : fileResponse.attributes.name;
        file.estQuestionnaire = !!fileResponse.attributes.questionnaire_;
        file.estDetaillable = file.estQuestionnaire;
        fileResponse.attributes.questionnaire_ !== undefined
          ? (file.Questionnaire = fileResponse.attributes.questionnaire_)
          : (file.Questionnaire = undefined);
        file.noteQuestionnaire = e.attributes.note;
        if (e.attributes.note_detail) {
          file.note_detail = JSON.parse(e.attributes.note_detail);
        }
        if (fileResponse.attributes.questionnaire_) {
          var resultQuery = new Parse.Query("question_result");
          resultQuery.equalTo(
            "questionnaire",
            fileResponse.attributes.questionnaire_
          );
          resultQuery.lessThanOrEqualTo(
            "required_points",
            parseInt(file.noteQuestionnaire)
          );
          resultQuery.descending("required_points");
          var resultatAnalyse = await resultQuery.first();
          file.analyseQuestionnaire = resultatAnalyse.attributes.text;
        }
      }

      if (
        file.protocoleComplet.attributes.type.attributes.name ===
        "Exercices libres"
      ) {
        var fileFind = linkFileCompleted.find((f) => {
          if (
            f.file === file.file &&
            f.protocole === file.protocole &&
            f.moduleP === file.moduleP &&
            f.brique === file.brique
          )
            return f;
        });
        if (fileFind) {
          fileFind.nb_acces += 1;
          fileFind.time_elapsed += file.time_elapsed;
          if (file.dateComplete > fileFind.dateComplete) {
            fileFind.date = file.date;
            fileFind.heure = file.heure;
          }
        } else {
          if (e.attributes.file.attributes.avecCapteur) {
            file.estDetaillable = true;
            file.estQuestionnaire = false;
            file.estModuleExpert = true;
          } else {
            file.estQuestionnaire = !!fileResponse.attributes.questionnaire_;
            file.estDetaillable = file.estQuestionnaire;
            file.estModuleExpert = false;
          }
          var avecResultat = false;
          avecResultat = e.attributes.resultatJeux === undefined ? false : true;
          file.estDetaillable = file.estDetaillable || avecResultat;
          file.estModuleExpert = file.estModuleExpert || avecResultat;
          linkFileCompleted.push(file);
        }
        linkFileCompleted.sort((a, b) => {
          if (a.dateComplete > b.dateComplete) return -1;
          return 1;
        });
      }
    }
  });
  return linkFileCompleted;
}
