import Parse from 'parse';

export async function countUnseenMessages(idTherapeute) {
    let unseenMessagesArray = []; // Tableau pour stocker les données sur les messages non lus

    try {
        var userQuery = new Parse.Query(Parse.User);
        userQuery.equalTo('objectId', idTherapeute);
        const user = await userQuery.first(); // Récupérer l'utilisateur correspondant
        
        const conversationQuery = new Parse.Query('conversation');
        conversationQuery.equalTo('user_pro', user);
        const conversations = await conversationQuery.find();

        console.log("Conversations trouvées :", conversations);

        for (const conversation of conversations) {
            const messageQuery = new Parse.Query('message');
            messageQuery.equalTo('conversation', conversation);
            messageQuery.notEqualTo('sender', user); // Utiliser directement l'utilisateur
            messageQuery.equalTo('seen', false); // Ajouter cette clause pour récupérer les messages non lus
            messageQuery.include('conversation'); // Inclure l'objet conversation
            const messages = await messageQuery.find();

            const numUnseenMessages = messages.length;
            if( messages.length > 0 ) {
            // Ajouter les données à l'array unseenMessagesArray
            unseenMessagesArray.push({
                senderId: messages[0].get('sender').id,
                numUnseenMessages: numUnseenMessages
            });
            }
            // console.log("Nombre de messages non lus pour la conversation", conversation.id, ":", numUnseenMessages);
            // console.log("Messages pour la conversation", conversation.id, ":", messages);
        }
        return unseenMessagesArray; // Retourner le tableau avec les données sur les messages non lus

    } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération des messages :", error);
        return unseenMessagesArray; // Retourner le tableau vide en cas d'erreur

    }
}
