<template>
  <div>
    <div>
      <!-- <p>Texte d'introduction</p> -->
      <div :key="theme.id" v-if="theme.attributes.ordre === 7">
        <div v-for="folder in getFoldersByTheme(theme.id)" :key="folder.id">
          <div>
            <div class="folder-container">
              <div
                class="folder-wrapper"
                style="border-radius: 15px;"
              >
              <!-- <button @click="test(folder)">test</button> -->
                <img
                  v-if="folder.resourceBg && folder.resourceBg._url"
                  :src="folder.resourceBg._url"
                  alt=""
                  class="brique-image"
                />
                <img
                  v-else-if="folder.resourceBg && folder.resourceBg.url"
                  class="brique-image"
                  :src="folder.resourceBg.url"
                />
                <p class="is-size-5 centered-text">
                  {{ folder.name }}
                </p>
              </div>
              <div
                class="module-section"
                :class="{
                  'active-protocol': isProtocolIdInFolderIds(
                    folder.protocoles[0].id
                  ),
                  'inactive-protocol': !isProtocolIdInFolderIds(
                    folder.protocoles[0].id
                  ),
                }"
              >
                <div class="module-header">
                    <div class="toggle-switch">
                    <b-switch
                      :value="isProtocolIdInFolderIds(folder.protocoles[0].id)"
                      @input="toggleProtocol(folder, 0)"
                      type="is-success"
                      size="is-small"
                    ></b-switch>
                  </div>  
                  <p class="module-title">AUTO-ÉVALUATION patient/client</p>
                  <span
                    :class="[
                      'validation-icon',
                      isProtocolIdInFolderIds(folder.protocoles[0].id)
                        ? 'valid'
                        : 'invalid',
                    ]"
                  ></span> 
                </div>
                <div class="module-list">
                  <ul>
                    <li
                      :class="[
                        isModuleIdInFolderIds(
                          folder.protocoles[0].id,
                          module.id
                        )
                          ? ''
                          : 'inactive-div',
                      ]"
                      @click="getExercice(module.id)"
                      v-for="(module, index) in folder.protocoles[0].modules"
                      :key="module.id"
                    >
                      <div class="module-item">
                        <span class="iconStatut"
                          :class="[
                            'validation-icon',
                            isModuleIdInFolderIds(
                              folder.protocoles[0].id,
                              module.id
                            )
                              ? 'valid'
                              : 'invalid',
                          ]"
                          ></span
                        >
                        <div class="module-text">
                          {{ module.name }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- <div class='divButtonSelection'>
                  <button v-if="isProtocolIdInFolderIds(
                    folder.protocoles[0].id
                  )" class="buttonSelection" @click="toggleProtocol(folder, 0)">Tout désélectionner</button>
                  <button v-else class="buttonSelection"  @click="toggleProtocol(folder, 0)">Tout sélectionner</button>
                </div> -->
              </div>
              <div
                v-if="folder.protocoles[1] && folder.protocoles[1].id"
                class="module-section"
                :class="{
                  'active-protocol': isProtocolIdInFolderIds(
                    folder.protocoles[1].id
                  ),
                  'inactive-protocol': !isProtocolIdInFolderIds(
                    folder.protocoles[1].id
                  ),
                }"
              >
                <div class="module-header">
                  <div class="toggle-switch">
                    <b-switch
                      :value="isProtocolIdInFolderIds(folder.protocoles[1].id)"
                      @input="toggleProtocol(folder, 1)"
                      type="is-success"
                      size="is-small"
                    ></b-switch>
                  </div> 
                  <p class="module-title">
                    HÉTÉRO-ÉVALUATION soignant/thérapeute
                  </p>
                  
                  <span
                    :class="[
                      'validation-icon',
                      isProtocolIdInFolderIds(folder.protocoles[1].id)
                        ? 'valid'
                        : 'invalid',
                    ]"
                  ></span> 
                </div>
                <div class="module-list">
                  <ul>
                    <li
                      :class="[
                        isModuleIdInFolderIds(
                          folder.protocoles[1].id,
                          module.id
                        )
                          ? ''
                          : 'inactive-div',
                      ]"
                     @click="getExercice(module.id)"
                      v-for="(module, index) in folder.protocoles[1].modules"
                      :key="module.id"
                    >
                      <div class="module-item">
                        <span class="iconStatut"
                          :class="[
                            'validation-icon',
                            isModuleIdInFolderIds(
                              folder.protocoles[1].id,
                              module.id
                            )
                              ? 'valid'
                              : 'invalid',
                          ]"
                          ></span
                        >
                        <div class="module-text">
                          {{ module.name }}
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- <div class='divButtonSelection'>
                  <button class="buttonSelection"  @click="toggleProtocol(folder, 1)">Tout sélectionner</button>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <button class="save-button" @click="saveChanges">
      Sauvegarder les changements
    </button> -->
  </div>
</template>
<script>
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
export default {
  data() {
    return {
      folderIds: [],
      isLoading: false,
      isDisplay: false,
      addedFolderIds: [],
      removedFolderIds: [],
    };
  },
  props: {
    theme: {
      type: Object,
      required: true,
    },
    foldersBrique: {
      type: Array,
      required: true,
    },
    selectedUser: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  watch: {
    addedFolderIds: function (newVal) {
      this.$emit("update-addedFolderIds", newVal);
    },
    removedFolderIds: function (newVal) {
      this.$emit("update-removedFolderIds", newVal);
    },
  },
  methods: {
    getExercice(id) {
    console.log("id", id);
      if (!this.addedFolderIds.includes(id)) {
        console.log("This.addedFolderIds ", this.addedFolderIds);
        if(this.folderIds.includes(id))
        {
          this.folderIds = this.folderIds.filter((el) => el !== id);
        }
        else
        {
        this.addedFolderIds.push(id);
        }
      } else {
        //retirer l'id du tableau
        console.log("This.addedFolderIds ", this.addedFolderIds);
        this.addedFolderIds = this.addedFolderIds.filter((el) => el !== id);
      }
    },
    selectAllModules(folder) {
      console.log("folder all modules", folder);
      folder.protocoles[0].modules.forEach((module) => {
      if (!this.isModuleIdInFolderIds(folder.protocoles[1].id, module.id)) {
        this.addedFolderIds.push(module.id);
      }
    });
  },
    getFoldersByTheme(monTheme) {
      return this.foldersBrique
        .filter((el) => {
          if (el.themes.length < 2) {
            return el.themes[0].objectId === monTheme;
          }
          return (
            el.themes[0].objectId === monTheme ||
            el.themes[1].objectId === monTheme
          );
        })
        .sort((a, b) => a.link.order - b.link.order);
    },
    toggleProtocol(folder, protocolIndex) {
      const protocolId = folder.protocoles[protocolIndex].id;

      if (this.folderIds.includes(protocolId)) {
        this.folderIds = this.folderIds.filter((id) => id !== protocolId);
        this.removedFolderIds.push(protocolId);
        this.addedFolderIds = this.addedFolderIds.filter(
          (id) => id !== protocolId
        );
      } else {
        this.folderIds.push(protocolId);
        this.addedFolderIds.push(protocolId);
        this.removedFolderIds = this.removedFolderIds.filter(
          (id) => id !== protocolId
        );
      }
    },
    test(e) {
      console.log(e);
    },
    async fetchUser(objectId) {
      const userParse = Parse.Object.extend("User");
      const userQuery = new Parse.Query(userParse);
      userQuery.equalTo("objectId", objectId);

      try {
        return await userQuery.first();
      } catch (error) {
        console.error(
          "Erreur lors de la récupération de l'utilisateur:",
          error
        );
        throw error;
      }
    },
    isProtocolIdInFolderIds(protocolId) {
      console.log("this.folderIdsssssssss", this.folderIds);
      return this.folderIds.includes(protocolId);
    },
    isModuleIdInFolderIds(protocolId, moduleId) {
      return (
        this.folderIds.includes(protocolId) &&
        !this.folderIds.includes(moduleId) &&
        !this.addedFolderIds.includes(moduleId)
      );
    },
    async isProtocolActive(folder, protocolIndex) {
      try {
        const user = await this.fetchUser(this.selectedUser.objectId);
        const links = await this.fetchLinks(user);

        links.forEach((link) => {
          const folderId = link.get("folder") ? link.get("folder").id : null;

          // Si le folderId correspond à l'ID du protocole recherché, l'ajouter au tableau
          if (folderId === folder.protocoles[protocolIndex].id) {
            this.folderIds.push(folderId);
          }
        });

        if (this.folderIds.length > 0) {
          //console.log("Les folder ids trouvés sont:", this.folderIds);
        } else {
          //console.log(
          //   "Aucun folder avec le même id n'a été trouvé.",
          //   folder.protocoles[protocolIndex].id
          // );
        }
      } catch (error) {
        // Gestion d'erreur globale
        console.error("Une erreur est survenue:", error);
      }
      //console.log("Voici le tableau des ids:", this.folderIds);
    },
    async saveChanges() {
      try {
        this.isLoading = true;

        const user = await this.fetchUser(this.selectedUser.objectId);
        const linkRelation = user.relation("link");

        let linksToRemove = [];
        let linksToAdd = [];

        // Utilisation de Promise.all pour attendre que toutes les opérations soient terminées
        await Promise.all(
          this.removedFolderIds.map(async (folderId) => {
            // Rechercher le folder qui a cet id
            var folderQuery = new Parse.Query("folder");
            folderQuery.equalTo("objectId", folderId);
            const folderParseObj = await folderQuery.first();

            if (folderParseObj) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              var linkQuery = linkRelation.query();
              linkQuery.equalTo("folder", folderParseObj);
              const link = await linkQuery.first();

              //console.log("link", link);

              if (link) {
                // Vérifier que l'objet link existe
                linksToRemove.push(link.id);
              }
            }
          })
        );
        await Promise.all(
          this.addedFolderIds.map(async (folderId) => {
            // Rechercher le folder qui a cet id
            var folderQuery = new Parse.Query("folder");
            folderQuery.equalTo("objectId", folderId);
            //console.log("FolderId :", folderId);
            const folderParseObj = await folderQuery.first();
            //console.log("FolderParseObj :", folderParseObj);
            if (folderParseObj) {
              // Vérifier que folderParseObj existe
              // Rechercher le link associé à ce folder
              var linkQuery = new Parse.Query("link");
              linkQuery.equalTo("folder", folderParseObj);
              const link = await linkQuery.first();

              //console.log("link to add", link);

              if (link) {
                // Vérifier que l'objet link existe
                linksToAdd.push(link.id);
              }
            }
          })
        );

        //console.log("Voici le tableau des links à supprimer:", linksToRemove);
        //console.log("Voici le tableau des links à ajouter:", linksToAdd);
        // Appeler la fonction Parse Cloud pour modifier les relations de lien
        await Parse.Cloud.run("modifyUserLinkRelation", {
          userObjectId: this.selectedUser.objectId,
          linksToRemove: linksToRemove,
          linksToAdd: linksToAdd,
        });

        this.isLoading = false;
        //console.log("Modifications enregistrées avec succès.");
        this.$buefy.notification.open({
          message: "Les changements ont été sauvegardés avec succès !",
          type: "is-success",
          position: "is-top",
          duration: 5000,
        });
      } catch (error) {
        this.$buefy.notification.open({
          message: `Erreur : ${error.message}`,
          type: "is-danger",
          position: "is-top",
          duration: 5000,
        });
        this.isLoading = false;
        console.error("Une erreur est survenue:", error);
      }
    },
    async fetchLinks(user) {
      const linkRelation = user.relation("link");
      const query = linkRelation.query();
      query.limit(1000);

      try {
        return await query.find();
      } catch (error) {
        console.error("Erreur lors de la récupération des links:", error);
        throw error;
      }
    },
  },
  async created() {
    try {
      if (!this.selectedUser.objectId) return;
      const user = await this.fetchUser(this.selectedUser.objectId);
      const links = await this.fetchLinks(user);
      this.folderIds = links.map((link) =>
        link.get("folder") ? link.get("folder").id : null
      );
    } catch (error) {
      console.error("Une erreur est survenue:", error);
    }
    this.$emit("loading", false);
    this.isDisplay = true;
  },
};
</script>
<style scoped lang="scss">
.folder-container {
  display: flex;
  // align-items: flex-start;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05); // Couleur plus douce
  padding-bottom: 20px;
  margin-bottom: 20px;
  transition: all 0.3s ease; // Transition douce
  align-items: stretch;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02); // Effet hover
  }
}
.folder-container::after {
  content: "";
  display: block;
  height: 2px;
  background: linear-gradient(to right, transparent, #0099cc, transparent);
  margin-top: 20px;
}
.folder-container > *:not(:last-child) {
  margin-right: 30px; /* Ou toute autre valeur de marge que vous préférez */
}
.folder-wrapper {
  flex: 1;
  position: relative;
  max-width: 400px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.brique-image {
  border-radius: 15px;
  width: 100%;
  max-width: 400px;
  height: 300px;
  object-fit: cover;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  transition: box-shadow 0.3s ease; // Transition douce

  &:hover {
    box-shadow: 0px 6px 15px rgba(0, 0, 0, 0.2); // Effet hover
  }
}

.centered-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  font-weight: bold;
  font-family: "Arial", sans-serif; // Police plus lisible
}

.module-section {
  margin-bottom: 30px;
  border-radius: 8px; /* Coins arrondis */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce */
  overflow: hidden; /* Pour contenir les enfants avec un border-radius */
  background: #fff; /* Couleur de fond de la carte */
  &.active-protocol {
    border: 2px solid #4caf50; /* Bordure verte lorsque le protocole est actif */
  }

  &.inactive-protocol {
    border: 2px solid #ccc; /* Bordure grise lorsque le protocole est inactif */
  }
  .module-title {
    font-size: 0.8em;
    color: #333;
    margin-bottom: 10px;
    font-weight: 600;
  }


  .module-list {
    flex: 1;
    font-size: 0.55em;
    padding: 10px 20px ul {
      list-style: none;
      padding: 0;
      margin: 0;
    }

    li {
      padding: 10px;
      border-bottom: 1px solid #e6e6e6;
      transition: background-color 0.3s ease;
      line-height: 1.6; // Espacement entre les lignes

      &:hover {
        background-color: #f7f7f7;
        cursor: pointer;
      }
    }

    .module-number {
      padding: 8px 16px;
      background-color: #4caf50;
      padding: 5px 10px;
      border-radius: 50%;
      margin-right: 10px;
      color: #ffffff;
      font-size: 1em;
      font-weight: bold;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
      display: inline-block;
      transition: transform 0.3s ease;

      &:hover {
        transform: scale(1.1);
      }
    }

    .inactive-number {
      padding: 8px 16px;
      background-color: #ccc; /* Couleur de fond plus claire pour indiquer qu'il est désactivé */
      color: white; /* Couleur de texte gris clair pour être moins proéminent */
      padding: 5px 10px; /* Même padding que le numéro actif */
      border-radius: 50%; /* Conserve le style arrondi */
      margin-right: 10px; /* Même marge à droite */
      font-size: 1em; /* Même taille de police */
      font-weight: bold; /* Même poids de police */
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1); /* Ombre plus légère pour indiquer qu'il est inactif */
      display: inline-block; /* Conserve le style inline-block */
      transition: transform 0.3s ease; /* Même transition */
    }

    /* Texte de la liste */
    span + span {
      white-space: normal;
      display: inline-block;
      max-width: 80%;
      font-size: 0.9em; /* Réduire un peu la taille du texte pour mettre en valeur le numéro */
    }
  }
}
.toggle-switch {
  position: relative;
  width: 40px; // Réduire la taille pour moins d'encombrement
  height: 20px;
  margin-left: 10px; // Ajout d'une marge à gauche pour l'espacement
  border-radius: 20px;

  input {
    &:checked + .toggle-label {
      background-color: #4caf50; // Changer la couleur quand c'est actif
    }
  }
}

.divButtonSelection
{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}
.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-label {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.module-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px; // Ajout d'une marge en bas pour l'espacement
  gap: 10px;

  .module-title {
    font-size: 0.8em;
    color: #333;
    margin: 0; // Retirer la marge en bas pour un alignement cohérent
    margin-left: auto;
    margin-right: auto;
  }

  .toggle-switch {
    display: flex;
    align-items: center; // Centrer verticalement le switch
  }
}

.iconStatut
{
  border: 1px solid black;
    border-radius: 50%;
    padding: 0px 5px 0px 5px;
    background:#fbf2f2;
}


.toggle-label:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  /* left: 1px; */
  bottom: 2px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.validation-icon {
  font-size: 1.2em; /* Taille du caractère */
  margin-right: 10px; /* Marge à droite pour espacer l'icône du texte */
  transition: color 0.3s ease; /* Transition douce pour les changements de couleur */
}

.valid {
  color: #4caf50; /* Couleur verte pour indiquer la validité */
  &:before {
    content: "\2713"; /* Caractère de coche */
  }
}

.invalid {
  color: #f44336; /* Couleur rouge pour indiquer l'invalidité */
  &:before {
    content: "\2717"; /* Caractère de croix */
  }
}
input:checked + .toggle-label {
  background-color: #2196f3;
}

input:checked + .toggle-label:before {
  transform: translateX(22px);
}

.active-protocol {
  background-color: #c8e6c9; // Vert doux pour indiquer que c'est actif
  border: 1px solid #4caf50; // Bordure verte
  width: 30%;
  padding: 20px;

  transition: all 0.3s ease; // Transition douce pour les changements
}

.inactive-protocol {
  background-color: #ffcdd2; // Rouge doux pour indiquer que c'est inactif
  border: 1px solid #f44336; // Bordure rouge
  width: 30%;
  padding: 20px;

  transition: all 0.3s ease; // Transition douce pour les changements
}
.module-item {
  display: flex;
  align-items: center;
}

.module-text {
  margin-left: 8px;
}

.audio-icon {
  margin-left: 12px;
  cursor: pointer;
}

.audio-icon i {
  font-size: 16px;
}

.save-button {
  background-color: #4caf50; /* Vert */
  border: none;
  color: white; /* Texte blanc */
  padding: 15px 32px; /* Espace autour du texte */
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px; /* Coins arrondis */
  transition: all 0.3s ease; /* Transition douce pour tous les changements de style */
}

.save-button:hover {
  background-color: #45a049; /* Vert foncé au survol */
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19); /* Ombre au survol */
}

.save-button:active {
  background-color: #3e8e41; /* Vert encore plus foncé lors du clic */
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.24), 0 7px 20px 0 rgba(0, 0, 0, 0.19); /* Ombre plus petite lors du clic */
  transform: translateY(
    4px
  ); /* Déplace légèrement le bouton vers le bas lors du clic */
}
.inactive-div {
  opacity: 0.6;
}

.buttonSelection {
  display: inline-block;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  border: 2px solid #239380; /* Couleur de la bordure */
  color: #239380; /* Couleur du texte */
  background-color: #ffffff; /* Couleur de fond */
  border-radius: 5px; /* Coins arrondis */
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}

.buttonSelection:hover {
  background-color: #239380; /* Changement de couleur de fond au survol */
  color: #ffffff; /* Changement de couleur du texte au survol */
  border-color: #ffffff; /* Changement de couleur de la bordure au survol */
}
</style>