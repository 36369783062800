<template>
    <div class="forum-container">
  
        <!-- Section des posts -->
        <section class="posts-section">
            <div v-for="post in posts" :key="post.id" class="post">
                <header class="post-header">
                    <i class="fas fa-user avatar-icon"></i>
                    <span class="username">{{ post.attributes.author.attributes.firstname + " " +  post.attributes.author.attributes.lastname}}</span> <!-- L'ajout ici -->
                </header>
                <h3>{{ post.attributes.title }}</h3>
                <p>{{ post.attributes.content }}</p>
                <footer class="post-footer">
                    <span class="comment-count">{{ post.comments.length }} commentaires</span>
                </footer>
                <!-- Section des commentaires pour chaque post -->
                <div v-for="comment in post.comments" :key="comment.id" class="comment">
                    <i class="fas fa-user avatar-icon-small"></i>
                    <p>{{ comment.attributes.text }}</p>
                </div>
                <!-- Input pour ajouter un commentaire -->
                <b-field label="Ajouter un commentaire">
                    <b-input v-model="commentText[post.id]" @keyup.enter.exact.prevent="addComment(post)"></b-input>
                </b-field>
                <b-button @click="addComment(post)">Commenter</b-button>
            </div>
        </section>
  
        <!-- Section pour ajouter un nouveau post (visible uniquement pour les thérapeutes) -->
        <section v-if="currentUser.attributes.therapeute" class="new-post-section">
            <b-field label="Titre">
                <b-input v-model="newPostTitle"></b-input>
            </b-field>
            <b-field label="Contenu">
                <b-input type="textarea" v-model="newPostContent"></b-input>
            </b-field>
            <b-button @click="addPost">Poster</b-button>
        </section>
    </div>
</template>


<script>
import Parse from 'parse';
export default {
  data() {
    return {
      posts: [],  // Liste des posts
      newPostTitle: '', // Titre du nouveau post
      newPostContent: '', // Contenu du nouveau post
      commentText: {}, // Texte des commentaires par post ID
      currentUser: Parse.User.current(),
      type: ''
    };
  },

  methods: {
    async loadPosts() {
      const query = new Parse.Query('forum_post');
      query.descending('createdAt');
      const results = await query.find();
      
      // Pour chaque post, chargez les commentaires associés
      for (let post of results) {
        const commentQuery = new Parse.Query('forum_comments');
        commentQuery.equalTo('post', post);
        commentQuery.ascending('createdAt');
        post.comments = await commentQuery.find();
      }

      this.posts = results;
    },

    async addPost() {
      const post = new Parse.Object('forum_post');
      post.set('title', this.newPostTitle);
      post.set('content', this.newPostContent);
      post.set('author', this.currentUser);
      post.set('type', this.type);

      await post.save();
      this.loadPosts(); // Rechargez les posts après en avoir ajouté un
    },

    async addComment(post) {
      const comment = new Parse.Object('forum_comments');
      comment.set('text', this.commentText[post.id]);
      comment.set('post', post);
      comment.set('author', this.currentUser);

      await comment.save();
      this.loadPosts(); // Rechargez les posts après avoir ajouté un commentaire
    }
  },

  created() {
    this.loadPosts();
  }
};
</script>  
<style lang="scss" scoped>
.forum-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    padding-top: 100px;
    background-color: #f3f3f3;
}


.avatar-icon-small {
    font-size: 16px;
    color: #aaa;
    margin-right: 1rem;
}

.posts-section {
    margin-bottom: 2rem;
}

.post {
    background-color: #fff;
    border: 1px solid #e1e1e1;
    border-radius: 5px;
    padding: 1.5rem;
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.post-header {
    display: flex;
    align-items: center;
}

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 1rem;
}

.post h3 {
    margin-top: 0;
    font-size: 1.6rem;
    color: #2a2a2a;
}

.post-footer {
    margin-top: 1rem;
}

.comment-count {
    color: #999;
    font-size: 0.9rem;
}

.post p, .comment p {
    margin-bottom: 1.5rem;
    color: #555;
    line-height: 1.5;
}

.comment {
    display: flex;
    align-items: flex-start;
    background-color: #fafafa;
    padding: 1rem;
    border: 1px solid #eee;
    border-radius: 5px;
    margin-bottom: 1rem;
    position: relative;
}

.comment:before {
    content: "";
    width: 2px;
    background-color: #ddd;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 25px;
}

.avatar-small {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 1rem;
}

.comment p {
    margin: 0;
    flex-grow: 1;
    color: #666;
}

.new-post-section {
    background-color: #eef2f7;
    border: 1px solid #d4dbe6;
    border-radius: 5px;
    padding: 1.5rem;
}

b-button {
    background-color: #239380;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

b-button:hover {
    background-color: #1a726a;
}

b-field label {
    margin-bottom: 0.5rem;
    font-weight: bold;
    color: #333;
}

b-input {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

b-input:focus {
    border-color: #239380;
}
/* Ajouté pour une meilleure séparation entre les posts */
.posts-section .post:not(:last-child) {
    margin-bottom: 3rem;
}

/* Améliorer l'affichage des en-têtes des posts */
.post-header h3 {
    font-weight: 500;
    margin-left: 1rem; /* espace entre l'icône et le titre */
}

/* Plus d'ombre pour donner une sensation de profondeur */
.post {
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Meilleure séparation des commentaires */
.comment + .comment {
    margin-top: 1.5rem;
}

/* Un effet hover pour les boutons pour indiquer qu'ils sont interactifs */
b-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Un peu d'espace entre le bouton de commentaire et le champ de saisie */
b-button {
    margin-top: 1rem;
}

/* Changement de style pour le compteur de commentaires */
.comment-count {
    background-color: #eef2f7;
    padding: 0.3rem 0.8rem;
    border-radius: 12px;
}

/* Ajustement de la position de la ligne de séparation des commentaires */
.comment:before {
    left: 30px;
}

/* Amélioration de la lisibilité des commentaires */
.comment p {
    margin-left: 1rem; /* espace entre l'icône et le texte */
}

/* Changement de style pour les titres de champs */
b-field label {
    font-size: 0.9rem;
    margin-bottom: 1rem;
    text-transform: uppercase;
    letter-spacing: 1px;
}
/* Augmenter l'espace entre les sections */
.posts-section, .new-post-section {
    margin-bottom: 3rem;
}

/* Animation légère pour les icônes des utilisateurs lors du survol des commentaires et posts */
.comment:hover .avatar-icon-small, .post:hover .avatar-icon {
    transform: scale(1.1);
    transition: transform 0.2s ease;
}

/* Styling pour les inputs lorsqu'ils sont en focus */
b-input:focus {
    box-shadow: 0 0 5px rgba(36, 147, 128, 0.4); /* une ombre douce qui correspond à la couleur de focus */
}

/* Ajouter un espace entre le contenu du post et le footer */
.post p {
    margin-bottom: 1.5rem;
}

/* Effet hover pour les posts (pour indiquer qu'ils peuvent être cliqués ou interagis avec) */
.post:hover {
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

/* Espacement entre le champ du commentaire et le bouton */
b-field {
    margin-bottom: 1rem;
}

/* Effet hover sur les commentaires pour indiquer qu'ils peuvent être interagis avec (par exemple, répondre, aimer, etc.) */
.comment:hover {
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

/* Pour améliorer la hiérarchie visuelle, on peut augmenter la taille de la police pour le titre d'un nouveau post */
.new-post-section b-field label {
    font-size: 1rem;
    color: #444;
}

/* Effet "active" pour les boutons pour montrer un feedback lorsqu'ils sont pressés */
b-button:active {
    transform: translateY(1px);
    box-shadow: none;
}

/* Améliorer le style du compteur de commentaires */
.comment-count {
    font-weight: bold;
}

/* Augmenter l'espace entre les commentaires pour une meilleure lisibilité */
.comment + .comment {
    margin-top: 2rem;
}

/* Ajout d'un hover effect pour le label du champ pour montrer qu'il est interactif */
b-field label:hover {
    color: #239380;
    cursor: pointer;
}
.avatar-icon {
    font-size: 18px;         /* Réduction de la taille de l'icône pour la rendre plus subtile */
    color: #777;
    vertical-align: middle; /* Pour aligner l'icône avec le texte */
    margin-left: auto;
}

.username {
    font-size: 0.9rem;       /* Taille de police plus petite pour le nom d'utilisateur */
    color: #555;            /* Couleur légèrement plus douce pour le nom d'utilisateur */
    font-weight: 500;       /* Nom d'utilisateur en semi-gras */
    margin-right: 0.5rem;   /* Espace entre le nom d'utilisateur et le titre */
}

.post-header h3 {
    margin: 0.5rem 0;       /* Ajout d'une marge en haut et en bas pour espacer le titre du nom d'utilisateur et du contenu */
    font-size: 1.6rem;      /* La taille originale du titre */
    color: #2a2a2a;
}
/* Style supplémentaire pour le header du post pour bien gérer les éléments */
.post-header {
    display: flex;
    align-items: center;
    gap: 0.5rem;            /* Espace entre les éléments, vous pouvez ajuster en fonction de vos besoins */
}
</style>
