<template>
  <div class="popup-container" v-if="selectedNotification">
    <div class="popup-content">
      <span @click="closePopup" class="close-icon">
        <i class="fas fa-times"></i>
      </span>
      <div class="header-section">
        <h3><i class="fas fa-bell"></i> Détails de la notification</h3>
        <span class="date-text">{{
          " " + formatDate(selectedNotification.attributes.date_event)
        }}</span>
      </div>
      <div class="details-grid">
        <div class="grid-item full-width">
          <i class="fas fa-info-circle"></i>
          <strong>Description :</strong>
          <span
            v-if="selectedNotification.attributes.type === 'Questionnaire'"
            >{{
              " " +
              formatDescription(selectedNotification.attributes.description)
            }}</span
          >
          <span v-else-if="selectedNotification.attributes.type === 'Questionnaire BFP'">{{' ' + selectedNotification.attributes.description}}</span>
          <span
            v-else-if="
              selectedNotification.attributes.type === 'Visio-conférence'
            "
            >{{
              " Demande de " +
              selectedNotification.attributes.type +
              " crée par " +
              formatUser(selectedNotification.attributes.user) +
              " avec vous le " +
              formatDatePlus(
                selectedNotification.attributes.meeting_event.attributes
                  .date_meeting
              )
            }}</span
          >
        </div>
        <div class="grid-item">
          <i class="fas fa-tag"></i>
          <strong>Type :</strong>
          <span>{{ selectedNotification.attributes.type || "Inconnu" }}</span>
        </div>
        <div class="grid-item">
          <i class="fas fa-user"></i>
          <strong>Utilisateur :</strong>
          <span>{{
            " " + formatUser(selectedNotification.attributes.user)
          }}</span>
        </div>
      </div>
      <div v-if="selectedNotification.attributes.type === 'Questionnaire BFP'" class="Placement-Redirect-Button">
        <button class="redirect-button" @click="redirigerVersRelecture(selectedNotification.attributes.user)">Voir le Résultat</button>
      </div>
      <div v-if="selectedNotification.attributes.type === 'Visio-conférence'">
        <div
          class="action-buttons"
          v-if="
            selectedNotification.attributes.type === 'Visio-conférence' &&
            selectedNotification.attributes.meeting_event.attributes
              .is_accepted === undefined &&
            isTherapeute
          "
        >
          <button
            @click="acceptMeeting(selectedNotification)"
            class="action-button accept"
          >
            <i class="fas fa-check-circle"></i> Valider le rendez-vous
          </button>
          <button
            @click="declineMeeting(selectedNotification)"
            class="action-button decline"
          >
            <i class="fas fa-times-circle"></i> Refuser le rendez-vous
          </button>
        </div>
        <div
          class="meeting-status"
          v-if="
            selectedNotification.attributes.type === 'Visio-conférence' &&
            selectedNotification.attributes.meeting_event.attributes
              .is_accepted !== undefined &&
            isTherapeute
          "
        >
          <i
            :class="[
              'fas',
              selectedNotification.attributes.meeting_event.attributes
                .is_accepted
                ? 'fa-check-circle'
                : 'fa-times-circle',
            ]"
          ></i>
          {{
            selectedNotification.attributes.meeting_event.attributes.is_accepted
              ? "Rendez-vous accepté"
              : "Rendez-vous refusé"
          }}
        </div>
        <div
          class="meeting-status"
          v-if="
            selectedNotification.attributes.meeting_event.attributes
              .is_accepted === undefined && !isTherapeute
          "
        >
          <i class="fas fa-hourglass-half"></i> Rendez-vous en attente
        </div>
        <div
          class="meeting-status"
          v-if="
            !isTherapeute &&
            selectedNotification.attributes.meeting_event.attributes
              .is_accepted !== undefined
          "
        >
          <i
            :class="[
              'fas',
              selectedNotification.attributes.meeting_event.attributes
                .is_accepted
                ? 'fa-check-circle'
                : 'fa-times-circle',
            ]"
          ></i>
          {{
            selectedNotification.attributes.meeting_event.attributes.is_accepted
              ? "Rendez-vous accepté"
              : "Rendez-vous refusé"
          }}
        </div>
      </div>
    </div>
  </div>
  <div class="popup-container" v-else>
    <div class="popup-content">
      <h3>Erreur : La notification n'est pas disponible.</h3>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export default {
  data() {
    return {
      isTherapeute: false,
    };
  },
  props: {
    selectedNotification: {
      type: Object,
      required: true,
    },
  },
  created() {
    var user = Parse.User.current();
    if (user) {
      if (user.attributes.therapeute) {
        this.isTherapeute = true;
      } else {
        this.isTherapeute = false;
      }
    }
    //console.log('SelectedNotification:', this.selectedNotification)
  },
  methods: {
    closePopup() {
      this.$emit("close-popup");
    },
    formatDate(date) {
      if (!date) return "Date inconnue";
      return new Date(date).toLocaleString();
    },
    declineMeeting(notification) {
      ParseConfig();
      var ObjectNotification = Parse.Object.extend("notification");
      var query = new Parse.Query(ObjectNotification);
      query.get(notification.id).then((object) => {
        console.log('Object', object)
        var queryMeeting = new Parse.Query("meeting");
        queryMeeting
          .get(notification.attributes.meeting_event.id)
          .then((objectMeeting) => {
            objectMeeting.set("is_accepted", false);
            objectMeeting.save().then((response) => {
              this.$emit("meeting-accepted", response);
              this.$emit("close-popup");

            });
          });
      });
    },
    redirigerVersRelecture(user)
    {
      var UserId = user.id;
      document.cookie =
          "IdUserBfp=" + UserId + ";path=/;max-age=60";
      window.location.href = `/#/utilisateurs`;
      location.reload();
    },
    acceptMeeting(notification) {
      ParseConfig();
      var ObjectNotification = Parse.Object.extend("notification");
      var query = new Parse.Query(ObjectNotification);
      query.get(notification.id).then((object) => {
        console.log('Object', object)
        var queryMeeting = new Parse.Query("meeting");
        queryMeeting
          .get(notification.attributes.meeting_event.id)
          .then((objectMeeting) => {
            objectMeeting.set("is_accepted", true);
            objectMeeting.save().then((response) => {
              this.$emit("meeting-accepted", response);
              this.$emit("close-popup");

            });
          });
      });
    },
    formatDatePlus(dateString) {
      const date = new Date(dateString);
      const options = {
        weekday: "long", // jeudi
        year: "numeric", // 2023
        month: "long", // août
        day: "numeric", // 28
        hour: "2-digit", // 14
        minute: "2-digit", // 00
      };
      return date.toLocaleDateString("fr-FR", options).replace(",", " à");
    },
    formatDescription(description) {
      return description || "Description non disponible";
    },
    formatUser(user) {
      if (!user || !user.attributes) return "Utilisateur inconnu";
      return `${user.attributes.lastname} ${user.attributes.firstname}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(104 101 101 / 70%);;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background-color: #f9f9f9;
  padding: 40px;
  width: 80%;
  max-width: 800px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;

  @media (min-width: 768px) {
    width: 50%;
  }
}

.header-section {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h3 {
    margin: 0;
    font-size: 24px;
    color: #239380;
  }

  .date-text {
    font-size: 14px;
    color: #333;
  }
}

.details-grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  .grid-item {
    font-size: 16px;

    i {
      margin-right: 8px;
      color: #239380;
    }

    strong {
      color: #333;
    }
  }

  .full-width {
    grid-column: span 1;
    margin-bottom: 16px;

    @media (min-width: 768px) {
      grid-column: span 2;
    }
  }
}

.close-icon {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease;

  &:hover {
    color: #239380;
    transform: scale(1.1);
  }

  &:active,
  &:focus {
    color: #239380;
    outline: none;
  }

  i {
    font-size: 24px;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.action-buttons {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}

.action-button {
  margin: 0.5rem;
  padding: 1rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  width: auto; /* Largeur auto pour l'adaptabilité */
  text-align: center;
}

/* Pour les petits écrans */
@media (max-width: 768px) {
  .action-button {
    font-size: 0.8rem;
    padding: 0.8rem 1rem;
  }
}

/* Pour les très petits écrans */
@media (max-width: 480px) {
  .action-button {
    font-size: 0.7rem;
    padding: 0.7rem 1rem;
    margin: 0.3rem;
  }
}

.action-button i {
  margin-right: 8px; /* Espace entre l'icône et le texte */
}

.accept {
  background-color: #4caf50;
  color: white;
}

.accept:hover {
  background-color: #45a049;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

.decline {
  background-color: #f44336;
  color: white;
}

.decline:hover {
  background-color: #da190b;
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}
.meeting-status {
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.meeting-status i.fa-check-circle {
  color: green;
  margin-right: 8px;
}

.meeting-status i.fa-times-circle {
  color: red;
  margin-right: 8px;
}
.meeting-status i.fa-hourglass-half {
  color: #8c8ceb;
  margin-right: 8px;
}

.redirect-button {
    background-color: rgb(35, 147, 128);
    color: #fff;
    padding: 10px 20px;
    font-size: 1.2em;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: rgb(28, 126, 109);
    }
  }

.Placement-Redirect-Button
{
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  flex-wrap: wrap;
}
</style>
