import { render, staticRenderFns } from "./contactIcon.vue?vue&type=template&id=a05168cc"
import script from "./contactIcon.vue?vue&type=script&lang=js"
export * from "./contactIcon.vue?vue&type=script&lang=js"
import style0 from "./contactIcon.vue?vue&type=style&index=0&id=a05168cc&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports