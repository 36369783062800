<template>
  <div>
    <canvas
      class="is-margin-b-20"
      ref="chartcc"
      height="150px"
      width="1000px"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: ["valeurX", "tabRowCc", "defaultOpenedDetails"],
  name: "chart-cc",
  data() {
    return {
      chart: null,
    };
  },
  methods: {
    loadChart() {
      this.chart = null;
      if (!this.tabRowCc.length || !this.valeurX.length) {
        return;
      }
      const mesDonnees = {
        labels: this.valeurX,
        datasets: [
          {
            label: "Cohérence Cardiaque",
            data: this.tabRowCc,
            borderColor: "rgba(51, 102, 204, 1)",
            backgroundColor: "rgba(51, 102, 204, 0.5)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
        ],
      };
      var chartcc = this.$refs.chartcc;
      var ctx = chartcc.getContext("2d");
      this.chart = new Chart(ctx, {
        type: "line",
        data: mesDonnees,
        options: {
          legend: {
            display: true,
          },
          bezierCurve: false,
          animation: {
            onComplete: this.done,
          },
        },
      });
    },
    done() {
      var url = this.chart.toBase64Image();
      this.$emit("sendChartCc", url);
    },
  },
  mounted() {
    this.loadChart();
  },
  watch: {
    defaultOpenedDetails() {
      this.loadChart();
    },
  },
};
</script>
