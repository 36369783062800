<template>
  <div
    class="is-max-desktop is-fullhd full-h full-w flex-center_gap-5 color-grey"
  >
    <img
      src="../../../assets/img/Logos_Symbiofi_SymbioCenter_PRO_vectoriser.png"
    />
    <div class="box flex-center h-200">
      <h3 class="title has-text-primary">
        Félicitations! Votre inscription est validée.
      </h3>

      <h4 class="subtitle has-text-primary">
        Veuillez à présent vous connecter en cliquant sur le bouton "connexion".
      </h4>
      <div class="buttons">
        <b-button
          @click="setCompteActif"
          type="is-primary"
          class="button is-primary"
          >Connexion</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { URL_FRONT } from "@/configuration/urlConf.js";

export default {
  name: "AccountConfirm",
  methods: {
    async getMyUserGroup() {
      ParseConfig();
      const userObject = Parse.Object.extend("_User");
      const userQuery = new Parse.Query(userObject);
      userQuery.equalTo("objectId", this.$route.query.id);
      //console.log('Id:', this.$route.query.id)
      const userFind = await userQuery.first();
      //console.log('UserFind:', userFind)
      const userGroupObject = Parse.Object.extend("user_group");
      const userGroupQuery = new Parse.Query(userGroupObject);
      userGroupQuery.equalTo("objectId", userFind.attributes.group.id);
      const userGroupFind = await userGroupQuery.first();
      return userGroupFind;
    },
    async setCompteActif() {
      const myUserGroup = await this.getMyUserGroup();
      myUserGroup.set("compte_valide", true);
      await myUserGroup.save();
      window.location.href = URL_FRONT;
    },
  },
  mounted() {
    this.setCompteActif();
  },
};
</script>
<style scoped lang="scss">
.h-200 {
  min-height: 200px;
}

.full-h {
  height: 100vh;
}

.full-w {
  width: 100vw;
}

.title {
  text-align: center;
}

.flex-center {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_gap-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5em;
  }
}

.color-grey {
  background-color: #e8e8e8;
}

.btn-connexion {
  width: 200px;
}
</style>
