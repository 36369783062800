<template lang="html">
  <div class="is-size-7">
    <br />
    <div><label>Votre adresse de facturation</label></div>
    <div  v-if="nom && prenom">
      <label>{{ nom }} {{ prenom }}</label>
    </div>
    <div  v-if="adresse">
      <label>{{ adresse }}</label>
    </div>
    <div v-if="complementAdresse">
      <label>{{ complementAdresse }}</label>
    </div>
    <div v-if="codePostal && Ville">
      <label>{{ codePostal }} {{ Ville }}</label>
    </div>
    <div v-if="codeAcces">
      <label>Code accès résidence : {{ codeAcces }}</label>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  name: "adresse-lecture",
  props: ["propsInformationAdresse", "error"],
  data() {
    return {
      informationAdresse: {},
      adresse: null,
      complementAdresse: null,
      nom: null,
      prenom: null,
      ville: null,
      codePostal: null,
      codeAcces: null,
    };
  },
  mounted() {
    this.adresse = this.propsInformationAdresse.adresse;
    this.complementAdresse = this.propsInformationAdresse.complementAdresse;
    this.nom = this.propsInformationAdresse.nom;
    this.prenom = this.propsInformationAdresse.prenom;
    this.ville = this.propsInformationAdresse.ville;
    this.codePostal = this.propsInformationAdresse.codePostal;
    this.codeAcces = this.propsInformationAdresse.codeAcces;
  },
};
</script>
