export default function setServer(tpe) {
  var server = tpe.CMCIC_SERVEUR;
  var postOptions = {};
  postOptions.host = server.replace("https://", "");
  if (tpe.test) {
    server += "/test";
    postOptions.path = "/test/paiement.cgi";
  }
  if (!tpe.test) {
    postOptions.path = "/paiement.cgi";
  }
  server += "/paiement.cgi";
  var response = { server: server, postOptions: postOptions };
  return response;
}
