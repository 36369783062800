<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <div class="overflow">
          <h3 class="title has-text-black is-size-6 has-text-centered">
            {{ $t("invitationParAffiliation") }}
          </h3>
          <div>
            <div class="columns is-multiline">
              <div class="column is-6">
                <b-field
                  custom-class="is-small"
                  label="Prénom"
                  :type="{ 'is-danger': !firstname && error }"
                  :message="{
                    'Veuillez saisir un prénom.': !firstname && error,
                  }"
                >
                  <b-input size="is-small" v-model="firstname"></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field
                  custom-class="is-small"
                  label="Nom"
                  :type="{ 'is-danger': !lastname && error }"
                  :message="{
                    'Veuillez saisir un nom.': !lastname && error,
                  }"
                >
                  <b-input size="is-small" v-model="lastname"></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field
                  custom-class="is-small"
                  label="Email"
                  :type="{
                    'is-danger':
                      (!email && error) || errorEmailFormat || errorMajuscule,
                  }"
                  :message="{
                    'Veuillez saisir une adresse mail.': !email && error,
                    'Format de l\'email incorrect.': errorEmailFormat,
                    'L\'email ne doit pas contenir de majuscule':
                      errorMajuscule,
                  }"
                >
                  <b-input
                    size="is-small"
                    v-model="mailUser"
                    id="copy"
                    @focus="doNotCopyPast('copy')"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field
                  custom-class="is-small"
                  label="Recopier email"
                  :type="{
                    'is-danger':
                      (!mailCopy && error) ||
                      errorSamePassword ||
                      errorMajuscule,
                  }"
                  :message="{
                    'Veuillez recopier votre mail professionel':
                      !mailCopy && error,
                    'Il faut que votre adresse email soit identique à la précédente':
                      errorSamePassword && mailUser && mailCopy && error,
                    'L\'email ne doit pas contenir de majuscule':
                      errorMajuscule,
                  }"
                >
                  <b-input
                    name="recopieremail"
                    expanded
                    size="is-small"
                    id="past"
                    v-model="mailCopy"
                    @focus="doNotCopyPast('past')"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="is-margin-b-10">
              <p class="is-size-7">
                {{ $t("texteInformationAffiliation1") }}
              </p>
              <p class="is-size-7">
                {{ $t("texteInformationAffiliation2") }}
              </p>
              <p class="is-size-7">
                {{ $t("texteInformationAffiliation3") }}
              </p>
              <hr />
              <p class="is-size-4">{{ $t("RetrocessionEtRoyalties") }}</p>
              <p class="is-size-7">
                {{ $t("texteInformationAffiliation4") }}
              </p>
              <br />
              <div class="steps">
                <div class="step-item is-grey">
                  <div class="step-marker">1</div>
                  <div class="step-details">
                    <p class="step-title is-size-7">
                      {{ $t("etapeAffiliation1") }}
                    </p>
                  </div>
                </div>
                <div class="step-item">
                  <div class="step-marker">2</div>
                  <div class="step-details">
                    <p class="step-title is-size-7">
                      {{ $t("etapeAffiliation2") }}
                    </p>
                  </div>
                </div>
                <div class="step-item">
                  <div class="step-marker">3</div>
                  <div class="step-details">
                    <p class="step-title is-size-7">
                      {{ $t("etapeAffiliation3") }}
                    </p>
                  </div>
                </div>
                <div class="step-item">
                  <div class="step-marker">4</div>
                  <div class="step-details">
                    <p class="step-title is-size-7">
                      {{ $t("etapeAffiliation4") }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <a
          class="button is-cyan is-pulled-right is-margin-l-20"
          @click="sendAffiliation()"
        >
          {{ $t("envoyer") }}
        </a>
        <a class="button is-gris is-pulled-right" @click="back()">
          {{ $t("annuler") }}
        </a>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import checkEmailFormat from "@/services/general/checkEmailFormat";
import { getObjectByTypeAndValue } from "@/services/parse/getObject.js";
import { getFoldersByType } from "@/services/folders/getFoldersByType.js";
import { linkToAddToUser } from "@/services/folders/linkToAddToUser.js";
import { getLinksFileByLink } from "@/services/folders/getLinksFileByLink";
import asyncForEach from "@/services/general/asyncForEach.js";
import codeGenerator from "@/services/general/codeGenerator.js";

export default {
  name: "modal-affiliation-gratuit",
  props: ["code_affiliation", "group", "user"],
  data() {
    return {
      foldersBrique: [],
      linksToAdd: [],
      firstname: null,
      lastname: null,
      email: null,
      error: false,
      errorFormatEmail: false,
      mailUser: null,
      mailCopy: null,
      errorSamePassword: false,
      errorEmailFormat: false,
      errorMajuscule: false,
    };
  },
  methods: {
    doNotCopyPast(inputId) {
      const myInput = document.getElementById(inputId);
      myInput.onpaste = function (e) {
        e.preventDefault();
      };
      myInput.oncopy = function (e) {
        e.preventDefault();
      };
    },
    async sendAffiliation() {
      const loadingComponent = this.$buefy.loading.open();
      this.linksToAdd = linkToAddToUser(this.foldersBrique);
      this.error = false;
      this.errorFormatEmail = false;
      if (this.errorSamePassword) {
        loadingComponent.close();
        return;
      }
      if (!this.firstname || !this.lastname || !this.email) {
        this.error = true;
        loadingComponent.close();
        return;
      }
      if (!checkEmailFormat(this.email)) {
        this.errorFormatEmail = true;
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Format de l'email incorrect.",
          type: "is-danger",
        });
        return;
      }
      ParseConfig();
      try {
        const UserExist = await getObjectByTypeAndValue(
          "User",
          "email",
          this.email
        );
        if (UserExist) {
          loadingComponent.close();
          this.$buefy.toast.open({
            duration: 3000,
            message: "L'adresse email est déjà utilisée.",
            type: "is-danger",
          });
          return;
        }
        const UserObject = Parse.Object.extend("User");
        const UserGroupObject = Parse.Object.extend("user_group");
        const QueryUserGroup = new Parse.Query(UserGroupObject);
        QueryUserGroup.equalTo("objectId", this.group.objectId);
        const currentUserGroup = await QueryUserGroup.first();
        const User = new UserObject();
        User.set("lastname", this.lastname);
        User.set("firstname", this.firstname);
        User.set("email", this.email.trim());
        User.set("username", this.email.trim());
        User.set("type_pratique", "cabinet");
        User.set("proposition_affiliation", true);
        User.set("date_invitation_affiliation", new Date());
        User.set("affiliation", await Parse.User.current());
        User.set("cgu", true);
        if (!currentUserGroup.attributes.password_cabinet) {
          let password = codeGenerator("xxxxxxxx");
          currentUserGroup.set("password_cabinet", password);
          await currentUserGroup.save();
        }
        User.set("password", currentUserGroup.attributes.password_cabinet);
        User.set("therapeute", false);
        User.set("group", currentUserGroup);

        const RelationLinkFromUser = User.relation("link");
        await asyncForEach(this.linksToAdd, async (link) => {
          const links = await getLinksFileByLink(link);
          if (links) {
            links.forEach((e) => {
              RelationLinkFromUser.add(e);
            });
          }
          RelationLinkFromUser.add(link);
        });
        await User.save();
        await Parse.Cloud.run("propositionAffiliationGratuit", {
          destinataire: this.email,
          utilisateur:
            User.attributes.firstname + " " + User.attributes.lastname,
          codeAffiliation: this.code_affiliation,
          therapeute:
            Parse.User.current().attributes.firstname +
            " " +
            Parse.User.current().attributes.lastname,
        });
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Invitation envoyée avec succès.",
          type: "is-success",
        });
        this.back();
      } catch (e) {
        //console.log(e);
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec d'envoi du mail.",
          type: "is-danger",
        });
      }
    },
    back() {
      this.$emit("back");
    },
  },
  async mounted() {
    this.foldersBrique = await getFoldersByType(
      "Brique",
      this.user,
      this.group.type_abonnement
    );
  },
  watch: {
    mailCopy() {
      this.errorSamePassword = false;
      if (this.mailCopy !== this.email) {
        this.errorSamePassword = true;
      }
    },
    mailUser() {
      this.email = this.mailUser;
      this.errorSamePassword = false;
      if (this.mailCopy !== this.email) {
        this.errorSamePassword = true;
      }
      this.errorMajuscule = false;
      const regex = /[A-Z]/gm;
      const str = this.email;
      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        // eslint-disable-next-line no-unused-vars
        m.forEach((match, groupIndex) => {
          this.errorMajuscule = true;
        });
      }
    },
  },
};
</script>

<style></style>
