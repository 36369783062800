<template>
  <div class="section-visio" :class="{ 'no-padding': !isTherapeute }">
    <div v-if="showNotification" class="notification is-success">
      Vos disponibilités ont été sauvegardées avec succès.
    </div>

    <div class="container">
      <h2 class="title is-3">
        <i class="fas fa-calendar-alt"></i> Planning de la semaine
      </h2>
      <div class="info-box">
        <i class="fas fa-info-circle"></i>
        <p v-if="isTherapeute">
          Personnalisez votre semaine en cliquant sur les créneaux horaires pour
          vous rendre disponible à vos patients selon votre convenance.
        </p>
        <p v-else>
          Sélectionnez les plages horaires disponibles pour réserver une
          visio-conférence avec le thérapeute.
        </p>
      </div>
      <!-- <button @click="test()">test</button> -->
      <!-- <b-field label="Type de créneaux">
        <b-select
          v-model="selectedCreneau"
          placeholder="Sélectionner votre type de créneaux"
        >
          <option
            v-for="creneau in creneaux"
            :value="creneau.id"
            :key="creneau.id"
          >
            {{ creneau.type }}
          </option>
        </b-select>
      </b-field> -->
      <!-- <button v-on:click="test()">TEST</button> -->
      <div class="table-container" v-if="!isTherapeute && !hasScheduledVisio()">
        <div class="week-navigation">
          <button
            class="week-button"
            @click="currentWeek--"
            :disabled="currentWeek === 0"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
          <span class="week-number"
            >Semaine du {{ getWeekStartDate() }} au {{ getWeekEndDate() }}</span
          >
          <button class="week-button" @click="currentWeek++">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
        <table class="table is-bordered is-fullwidth schedule-table">
          <thead>
            <tr>
              <th></th>
              <th
                v-for="day in daysOfWeek"
                :key="day"
                class="has-text-centered"
              >
                {{ day + " " + getSlotDateDay(day) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hour in hours" :key="hour">
              <th>{{ hour }}</th>
              <td
                v-for="day in daysOfWeek"
                :key="day + '-' + hour"
                :class="{
                  'has-background-link': isSlotAvailableForUser(day, hour),
                  'is-slot-available': isSlotAvailableForUser(day, hour),
                }"
              >
                <button
                  v-if="isSlotAvailableForUser(day, hour)"
                  @click="createMeeting(getSlotDate(day, hour))"
                  class="button is-small is-fullwidth"
                  :class="{
                    'is-white': isSlotTaken(day, hour),
                    'is-grey': !isSlotTaken(day, hour),
                  }"
                  :disabled="
                    !isSlotTaken(day, hour) ||
                    isDayInFuture(getSlotDate(day, hour))
                  "
                >
                  Demander ce créneau
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        class="table-container"
        v-else-if="!isTherapeute && hasScheduledVisio()"
      >
        <div class="week-navigation">
          <button
            class="week-button"
            @click="currentWeek--"
            :disabled="currentWeek === 0"
          >
            <i class="fas fa-arrow-left"></i>
          </button>
          <span class="week-number"
            >Semaine du {{ getWeekStartDate() }} au {{ getWeekEndDate() }}</span
          >
          <button class="week-button" @click="currentWeek++">
            <i class="fas fa-arrow-right"></i>
          </button>
        </div>
        <div class="info-card">
          <p>
            <i class="fas fa-ban"></i>
            Vous avez déjà une visioconférence planifiée. Pour l'instant, vous
            ne pouvez pas en planifier une autre.
          </p>
        </div>
        <table
          class="table is-bordered is-fullwidth schedule-table disabled-table"
        >
          <thead>
            <tr>
              <th></th>
              <th
                v-for="day in daysOfWeek"
                :key="day"
                class="has-text-centered"
              >
                {{ day + " " + getSlotDateDay(day) }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hour in hours" :key="hour">
              <th>{{ hour }}</th>
              <td
                v-for="day in daysOfWeek"
                :key="day + '-' + hour"
                :class="{
                  'has-background-link': isSlotAvailableForUser(day, hour),
                  'is-slot-available': isSlotAvailableForUser(day, hour),
                }"
              >
                <button
                  v-if="isSlotAvailableForUser(day, hour)"
                  class="button is-small is-fullwidth"
                  :class="{
                    'is-white': isSlotTaken(day, hour),
                    'is-grey': !isSlotTaken(day, hour),
                  }"
                  :disabled="
                    !isSlotTaken(day, hour) ||
                    isDayInFuture(getSlotDate(day, hour))
                  "
                >
                  Prendre ce créneau
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="table-container" v-if="isTherapeute">
        <table
          class="table is-bordered is-fullwidth is-hoverable schedule-table"
        >
          <thead>
            <tr>
              <th></th>
              <th
                v-for="day in daysOfWeek"
                :key="day"
                class="has-text-centered"
              >
                {{ day }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="hour in hours" :key="hour">
              <th class="has-text-right">{{ hour }}</th>
              <td
                v-for="day in daysOfWeek"
                :key="day + '-' + hour"
                class="has-text-centered slot"
                :class="{ selected: isSlotSelected(day, hour) }"
                @click="toggleAvailability(day, hour), addTimeSlot(day, hour)"
              >
                <i
                  style="color: #3273dc"
                  v-if="isSlotSelected(day, hour)"
                  class="fas fa-check-circle"
                ></i>
              </td>
            </tr>
          </tbody>
        </table>
        <button @click="saveTherapistAvailability" class="button is-primary">
          Enregistrer les disponibilités
        </button>
      </div>
    </div>
    <div v-if="!showJitsiMeet && upcomingMeetings && upcomingMeetings.length>0" class="container" style="margin-top: 50px">
      <div class="columns is-multiline">
        <div
          v-for="meeting in upcomingMeetings"
          :key="meeting.id"
          class="column is-one-third meeting-card"
        >
          <div class="box">
            <article class="media">
              <figure class="media-left icon-container">
                <span class="icon is-large has-text-primary">
                  <i class="fas fa-video fa-2x" style="color: black"></i>
                </span>
              </figure>
              <div class="media-content has-text-centered">
                <div class="content">
                  <h3 class="title is-4">Visio-conférence</h3>
                  <p class="meeting-detail" v-if="isTherapeute">
                    <strong>Utilisateur : </strong>
                    {{
                      meeting.attributes.utilisateur_1.attributes.firstname +
                      " " +
                      meeting.attributes.utilisateur_1.attributes.lastname
                    }}
                  </p>

                  <p
                    class="meeting-detail"
                    v-if="meeting.attributes.is_accepted === undefined"
                  >
                    <strong>Statut : </strong>
                    <span class="icon has-text-warning">
                      <i class="fas fa-clock"></i>
                    </span>
                    <span>En attente de votre validation.</span>
                  </p>
                  <p
                    class="meeting-detail"
                    v-else-if="meeting.attributes.is_accepted === true"
                  >
                    <strong>Statut : </strong>
                    <span class="icon has-text-success">
                      <i class="fas fa-check-circle"></i>
                    </span>
                    <span>Accepté</span>
                  </p>
                  <p class="meeting-detail" v-else>
                    <strong>Statut : </strong>
                    <span class="icon has-text-danger">
                      <i class="fas fa-times-circle"></i>
                    </span>
                    <span>Refusé</span>
                  </p>
                  <p class="meeting-detail">
                    <strong>Date :</strong>
                    {{
                      new Date(meeting.get("date_meeting")).toLocaleDateString(
                        undefined,
                        {
                          day: "numeric",
                          month: "short",
                          year: "numeric",
                        }
                      )
                    }}
                  </p>
                  <p class="meeting-detail">
                    <strong>Heure :</strong>
                    {{
                      new Date(meeting.get("date_meeting")).toLocaleTimeString(
                        undefined,
                        {
                          hour: "2-digit",
                          minute: "2-digit",
                        }
                      )
                    }}
                  </p>
                </div>
              </div>
            </article>
            <div
              class="notification-action-div"
              v-if="
                meeting.attributes.is_accepted === undefined && isTherapeute
              "
            >
              <b-button
                @click="acceptMeeting(meeting)"
                type="is-success"
                icon-left="check"
                class="notification-action-button"
              >
                Accepter
              </b-button>
              <b-button
                @click="declineMeeting(meeting)"
                type="is-danger"
                class="notification-action-button"
              >
                X Refuser
              </b-button>
            </div>
            <button
              v-if="meeting.attributes.is_accepted === true"
              @click="joinMeeting(meeting.get('room_name'))"
              :disabled="!isWithinMeetingTime(meeting.get('date_meeting'))"
              class="button is-primary meeting-button"
              :class="{
                'is-disabled': !isWithinMeetingTime(
                  meeting.get('date_meeting')
                ),
              }"
            >
              Rejoindre la réunion
            </button>
            <button
              v-if="isTherapeute && meeting.attributes.is_accepted === true"
              @click="deleteMeeting(meeting)"
              class="button is-danger delete-meeting-button"
            >
              <i class="fas fa-times"></i>
              <span>Annuler</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <jitsi-meet
      v-if="showJitsiMeet"
      :currentRoomName="currentRoomName"
      :user="userCourant"
    ></jitsi-meet>
  </div>
</template>

<script>
import Parse from "parse";
import JitsiMeet from "@/components/visio/jitsiMeet.vue";
import ParseConfig from "@/api/parse.config";
export default {
  data() {
    return {
      meetings: [],
      showJitsiMeet: false,
      timeSlots: [],
      meeting_availabilities: [],
      therapist_availabilities: [],
      daysOfWeek: ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"],
      showNotification: false,
      therapeuteGroup: null,
      userCourant: "",
      hours: [
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
      ],
      creneaux: [
        { id: 1, type: "30 minutes", value: 30 },
        { id: 2, type: "1 heure", value: 60 },
      ],
      selectedCreneau: 1, // Définir l'ID du créneau avec une durée de 30 minutes
      currentWeek: 0,
    };
  },
  components: {
    JitsiMeet,
  },
  created() {
    ParseConfig();
    this.userCourant = Parse.User.current();
    this.loadMeetingAvailabilities();
  },
  computed: {
    isTherapeute() {
      const currentUser = Parse.User.current();
      return currentUser && currentUser.attributes.therapeute;
    },
    futureMeetings() {
      return this.sortedMeetings.filter(
        (meeting) =>
          this.isCurrentUserAllowedToJoin(meeting) &&
          this.isMeetingDateTodayOrFuture(meeting.get("date_meeting"))
      );
    },
    upcomingMeetings() {
      const currentTime = new Date();

      return this.futureMeetings.filter((meeting) => {
        const meetingTime = new Date(meeting.get("date_meeting"));
        meetingTime.setMinutes(meetingTime.getMinutes() + 30); // 30 mins après
        return currentTime <= meetingTime;
      });
    },
    sortedMeetings() {
      return this.meetings.slice().sort((a, b) => {
        return (
          new Date(a.get("date_meeting")) - new Date(b.get("date_meeting"))
        );
      });
    },
  },
  methods: {
    test() {
      var queryUser = new Parse.Query(Parse.User);
      var queryGroup = new Parse.Query("user_group");
      queryGroup.equalTo("objectId", Parse.User.current().attributes.group.id);
      queryUser.matchesQuery("group", queryGroup);
      queryUser.equalTo("therapeute", true);
      queryUser.first().then((therapeute) => {
        console.log("Thérapeute", therapeute);
      });

    },
    isDayInFuture(day) {
      const today = new Date();
      const dayDate = new Date(day);
      return dayDate <= today;
    },
    acceptMeeting(meeting) {
      var queryMeeting = new Parse.Query("meeting");
      queryMeeting.get(meeting.id).then((objectMeeting) => {
        objectMeeting.set("is_accepted", true);
        objectMeeting.save().then((response) => {
          console.log(response);
          this.getMeetings();
        });
      });
    },
    declineMeeting(meeting) {
      var queryMeeting = new Parse.Query("meeting");
      queryMeeting.get(meeting.id).then((objectMeeting) => {
        objectMeeting.set("is_accepted", false);
        objectMeeting.save().then((response) => {
          console.log(response);
          this.getMeetings();
        });
      });
    },
    deleteMeeting(meeting) {
      this.$buefy.dialog.confirm({
        title: "Confirmation",
        message: "Êtes-vous sûr de vouloir annuler cette visio-conférence ?",
        confirmText: "Confirmer",
        cancelText: "Annuler",
        type: "is-info",
        hasIcon: true,
        onConfirm: async () => {
          const queryMeeting = new Parse.Query("meeting");
          queryMeeting.get(meeting.id).then((objectMeeting) => {
            objectMeeting.set("is_accepted", false);
            objectMeeting.save().then((response) => {
              console.log(response)
              this.getMeetings();
            });
          });
        },
      });
    },
    isWithinMeetingTime(meetingDate) {
      const meetingTime = new Date(meetingDate);
      const currentTime = new Date();

      meetingTime.setMinutes(meetingTime.getMinutes() - 30); // 30 mins avant
      const afterMeetingTime = new Date(meetingDate);
      afterMeetingTime.setMinutes(afterMeetingTime.getMinutes() + 30); // 30 mins après

      return currentTime >= meetingTime && currentTime <= afterMeetingTime;
    },
    hasScheduledVisio() {
      if (this.upcomingMeetings.length > 0) {
        for (let i = 0; i < this.upcomingMeetings.length; i++) {
          if (
            this.upcomingMeetings[i].attributes.is_accepted === true ||
            this.upcomingMeetings[i].attributes.is_accepted === false
          ) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    },
    isWeekend(day) {
      return day === 5 || day === 6; // Samedi (index 5) ou dimanche (index 6)
    },
    getDayWithDate(index) {
      const currentDate = new Date();
      const dayOfWeek = currentDate.getDay();
      const dayDiff = index - dayOfWeek;
      currentDate.setDate(currentDate.getDate() + dayDiff);

      const options = { weekday: "long", day: "numeric", month: "long" };
      const formattedDate = currentDate.toLocaleDateString("fr-FR", options);

      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },

    async saveTherapistAvailability() {
      const currentUser = Parse.User.current();

      // récupérer les anciennes disponibilités
      var oldAvailabilities = [];
      if (currentUser.get("meeting_availabilities")) {
        oldAvailabilities = JSON.parse(
          currentUser.get("meeting_availabilities")
        );
      }

      // fusionner les anciennes disponibilités avec les nouvelles
      var allAvailabilities = [
        ...oldAvailabilities,
        ...this.therapist_availabilities,
      ];

      // sauvegarder toutes les disponibilités
      currentUser.set(
        "meeting_availabilities",
        JSON.stringify(allAvailabilities)
      );
      try {
        await currentUser.save();
        this.$buefy.notification.open({
          duration: 5000,
          message: "Vos disponibilités ont été sauvegardées avec succès.",
          position: "is-top",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.notification.open({
          duration: 5000,
          message:
            "Une erreur s'est produite lors de la sauvegarde de vos disponibilités.",
          position: "is-top",
          type: "is-danger",
        });
      }
    },

    hideNotificationAfterDelay() {
      setTimeout(() => {
        this.showNotification = false;
      }, 6000);
    },
    async isSlotTaken(day, hour) {
      var slotTaken = this.getSlotDate(day, hour);
      const Meeting = Parse.Object.extend("meeting");
      const query = new Parse.Query(Meeting);
      query.equalTo("date_meeting", slotTaken);
      query.equalTo("utilisateur_2", this.therapeuteGroup);
      const meetings = await query.find();
      if (meetings && meetings.attributes && meetings.length > 0 && meetings.attributes.is_accepted != false) {
        return true;
      } else {
        return false;
      }
    },
    addTimeSlot(day, hour) {
      const existingSlotIndex = this.therapist_availabilities.findIndex(
        (slot) => slot.day === day && slot.hour === hour
      );

      if (existingSlotIndex !== -1) {
        // Le slot est déjà sélectionné, donc nous le supprimons
        this.therapist_availabilities.splice(existingSlotIndex, 1);
      } else {
        // Le slot n'est pas encore sélectionné, nous l'ajoutons
        const timeSlot = {
          day: day,
          hour: hour,
        };

        this.therapist_availabilities.push(timeSlot);
      }
    },
    formatDate(date) {
      const options = { year: "numeric", month: "short", day: "numeric" };
      return new Date(date).toLocaleDateString("fr-FR", options);
    },
    isMeetingDateTodayOrFuture(date) {
      const meetingDate = new Date(date);
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      return meetingDate >= today;
    },
    async getMeetings() {
      const Meeting = Parse.Object.extend("meeting");
      const query = new Parse.Query(Meeting);
      try {
        this.meetings = await query.find();
      } catch (error) {
        return null;
      }
    },
    async loadMeetingAvailabilities() {
      if (Parse.User.current().attributes.therapeute) {
        if (Parse.User.current().attributes.meeting_availabilities) {
          this.meeting_availabilities = JSON.parse(
            Parse.User.current().attributes.meeting_availabilities
          );
        }
      } else {
        const currentUser = Parse.User.current();
        const queryUser = new Parse.Query(Parse.User);
        const queryGroup = new Parse.Query("user_group");
        queryGroup.equalTo("objectId", currentUser.attributes.group.id);
        queryUser.matchesQuery("group", queryGroup);
        queryUser.equalTo("therapeute", true);
        const therapist = await queryUser.first();
        this.therapeuteGroup = therapist;
        if (therapist && therapist.get("meeting_availabilities")) {
          this.therapist_availabilities = JSON.parse(
            therapist.get("meeting_availabilities")
          );
          //créer une boucle pour vérifier si le slot est pris
          for (let i = 0; i < this.therapist_availabilities.length; i++) {
            const slot = this.therapist_availabilities[i];
            const slotTaken = await this.isSlotTaken(slot.day, slot.hour);
            if (slotTaken) {
              this.therapist_availabilities.splice(i, 1);
            }
          }
        }
      }
    },
    generateTimeSlots() {
      const slots = [];
      const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi"];

      for (let i = 0; i < 7; i++) {
        for (let j = 9; j < 17; j++) {
          slots.push(`${days[i]} : ${j}h`);
        }
      }
      return slots;
    },
    getWeekStartDate() {
      let startDate = new Date();
      startDate.setDate(startDate.getDate() + this.currentWeek * 7);
      return this.formatDateForWeek(startDate);
    },
    getWeekEndDate() {
      let endDate = new Date();
      endDate.setDate(endDate.getDate() + this.currentWeek * 7 + 6);
      return this.formatDateForWeek(endDate);
    },
    async createMeeting(date) {
      this.$buefy.dialog.confirm({
        title: "Confirmation",
        message:
          "Êtes-vous sûr de vouloir planifier une visio-conférence pour ce créneau ?",
        confirmText: "Confirmer",
        cancelText: "Annuler",
        type: "is-info",
        hasIcon: true,
        onConfirm: async () => {
          console.log("Création de la visio...1")
          const currentUser = Parse.User.current();
          const queryUser = new Parse.Query(Parse.User);
          const queryGroup = new Parse.Query("user_group");
          queryGroup.equalTo("objectId", currentUser.attributes.group.id);
          queryUser.matchesQuery("group", queryGroup);
          queryUser.equalTo("therapeute", true);
          const therapist = await queryUser.first();
          console.log("Création de la visio...2")
          const Meeting = Parse.Object.extend("meeting");
          const newMeeting = new Meeting();
          newMeeting.set("date_meeting", date);
          newMeeting.set("utilisateur_1", currentUser);
          newMeeting.set("utilisateur_2", therapist);
          newMeeting.set(
            "room_name",
            `your-app-name-${this.generateUniqueId()}`
          );
          console.log("Création de la visio...3")
          try {
            await newMeeting.save();

            // Création de l'événement meeting
            const MeetingEvent = Parse.Object.extend("notification");
            const newMeetingEvent = new MeetingEvent();
            newMeetingEvent.set("meeting_event", newMeeting); // Liaison de l'événement avec le meeting
            newMeetingEvent.set("type", "Visio-conférence"); // Définition du type d'événement
            newMeetingEvent.set(
              "description",
              "Création d'une visio-conférence "
            );
            newMeetingEvent.set("user", currentUser); // Définition de l'utilisateur lié à l'événement
            newMeetingEvent.set("date_event", new Date()); // Définition de la date de l'événement
            newMeetingEvent.set("user_pro", therapist); // Définition du thérapeute lié à l'événement
            newMeetingEvent.set("isReadUser", false); // Définition de la lecture de l'événement
            newMeetingEvent.set("isReadUserPro", false); // Définition de la lecture de l'événement
            try {
              await newMeetingEvent.save();
            } catch (eventError) {
              console.error("Error while creating meeting event: ", eventError);
            }
            Parse.Cloud.run("confirmationRendezVous", {
              destinataire: therapist.attributes.username,
              idRendezVous: newMeeting.id,
            });
            this.getMeetings();
            this.loadMeetingAvailabilities();
          } catch (meetingError) {
            return meetingError;
          }
        },
        onCancel: () => false,
      });
    },

    generateUniqueId() {
      const timestamp = Date.now().toString(36);
      const randomString = Math.random().toString(36).substr(2, 5);
      return timestamp + randomString;
    },
    formatTime(date) {
      return date.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    isCurrentUserAllowedToJoin(meeting) {
      const currentUser = Parse.User.current();
      const user1 = meeting.get("utilisateur_1");
      const user2 = meeting.get("utilisateur_2");

      return (
        currentUser &&
        ((user1 && currentUser.id === user1.id) ||
          (user2 && currentUser.id === user2.id))
      );
    },
    joinMeeting(roomName) {
      this.currentRoomName = roomName;
      this.showJitsiMeet = true;
    },
    isMeetingDateToday(meetingDate) {
      const today = new Date();
      const meetingDateObject = new Date(meetingDate);
      return (
        today.getFullYear() === meetingDateObject.getFullYear() &&
        today.getMonth() === meetingDateObject.getMonth() &&
        today.getDate() === meetingDateObject.getDate()
      );
    },
    // formatDate(date) {
    //   const options = { year: "numeric", month: "long", day: "numeric" };
    //   return new Date(date).toLocaleDateString("fr-FR", options);
    // },
    formatDateForWeek(date) {
      let day = date.getDate();
      let month = date.getMonth() + 1; // Les mois sont indexés à partir de 0 en JS
      let year = date.getFullYear();

      // Formatte le jour et le mois pour toujours avoir deux chiffres
      day = day < 10 ? "0" + day : day;
      month = month < 10 ? "0" + month : month;

      return day + "/" + month + "/" + year;
    },
    isSlotAvailableForUser(slotDay, slotHour) {
      return this.therapist_availabilities.some((availability) =>
        this.isSameDayAndHour(availability, slotDay, slotHour)
      );
    },
    isSlotSelected(slotDay, slotHour) {
      return this.meeting_availabilities.some(
        (slot) => slot.day === slotDay && slot.hour === slotHour
      );
    },
    isSameDayAndHour(availability, slotDay, slotHour) {
      return availability.day === slotDay && availability.hour === slotHour;
    },
    getSlotDateDay(day) {
      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);
      // déplacez la date de départ en fonction de la semaine courante
      startDate.setDate(startDate.getDate() + this.currentWeek * 7);

      const days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      const dayIndex = days.indexOf(day);

      let slotDate = new Date(startDate);
      let dayDifference = dayIndex - startDate.getDay();

      slotDate.setDate(startDate.getDate() + dayDifference);

      slotDate = this.formatDate(slotDate);
      return slotDate;
    },
    isTodayOrFutureDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0); // Reset time part to compare only dates
      return date >= today;
    },
    getSlotDate(day, hour) {
      const startDate = new Date();
      startDate.setHours(0, 0, 0, 0);

      // déplacez la date de départ en fonction de la semaine courante
      startDate.setDate(startDate.getDate() + this.currentWeek * 7);

      const days = [
        "Dimanche",
        "Lundi",
        "Mardi",
        "Mercredi",
        "Jeudi",
        "Vendredi",
        "Samedi",
      ];

      const dayIndex = days.indexOf(day);

      let slotDate = new Date(startDate);
      let dayDifference = dayIndex - startDate.getDay();

      slotDate.setDate(startDate.getDate() + dayDifference);
      slotDate.setHours(parseInt(hour), 0, 0, 0);
      return slotDate;
    },

    isSameDateTime(date1, date2) {
      return (
        date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours()
      );
    },
    toggleAvailability(day, hour) {
      const slot = { day, hour };
      const index = this.meeting_availabilities.findIndex(
        (availability) =>
          availability.day === slot.day && availability.hour === slot.hour
      );

      if (index === -1) {
        this.meeting_availabilities.push(slot);
      } else {
        this.meeting_availabilities.splice(index, 1);
      }
    },

    isSameSlot(slotDate, meetingDate) {
      return (
        slotDate.getFullYear() === meetingDate.getFullYear() &&
        slotDate.getMonth() === meetingDate.getMonth() &&
        slotDate.getDate() === meetingDate.getDate() &&
        slotDate.getHours() === meetingDate.getHours()
      );
    },
  },
  mounted() {
    this.getMeetings();
    this.timeSlots = this.generateTimeSlots();
  },
};
</script>

<style scoped>
.box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
}

section {
  margin-top: 0px !important;
}
.box h3 {
  margin-bottom: 1rem;
  text-align: center;
}

.button.is-primary {
  width: 100%;
}

.button.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
schedule-table {
  border-collapse: separate;
  border-spacing: 0 5px;
}
.slot {
  cursor: pointer;
  position: relative;
  transition: background-color 0.3s ease;
}

.slot::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  background-color: #7957d5;
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.box {
  margin-bottom: 1.5rem;
}

.media-content .title {
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.week-navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;
  background-color: #f9f9f9;
  border: 1px solid #eaeaea;
  border-radius: 4px;
  margin-bottom: 1em;
}

.week-button {
  border: none;
  background: #7957d5;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
  transition: background 0.3s ease;
}

.week-button:hover {
  background: #7957d5;
}

.week-number {
  flex-grow: 1;
  text-align: center;
  font-weight: bold;
}
.media-content p {
  margin-bottom: 0.5rem;
}

.button.is-primary {
  margin-top: 1rem;
  transition: background-color 0.3s, border-color 0.3s;
}

.button.is-primary:hover,
.button.is-primary:focus {
  /* background-color: #3273dc; */
  border-color: #3273dc;
}

.button.is-primary.is-disabled:hover,
.button.is-primary.is-disabled:focus {
  background-color: #7957d5;
  border-color: #7957d5;
}
.slot.selected {
  /* background-color: #7957d5; */
  color: white;
}

.slot.selected::before {
  /* opacity: 1; */
}
.schedule-table th,
.schedule-table td {
  padding: 10px;
}

.schedule-table th {
  background-color: #f5f5f5;
  font-weight: bold;
}
.is-grey {
  background-color: grey;
  color: white;
}
.schedule-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}
.custom-checkbox {
  width: 20px;
  height: 20px;
  border: 2px solid #3273dc;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.no-padding {
  padding: 1.5rem !important;
}
.custom-checkbox.is-checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: #3273dc;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
}
.disabled-table {
  opacity: 0.5;
  pointer-events: none;
  position: relative;
}
.week-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}
.info-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  z-index: 1;
  width: 50%; /* Make the info card take up 50% of the table's width. */
  max-width: 600px; /* Set a maximum width. */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); /* Add a subtle shadow. */
}

.info-card p {
  font-size: 1.2em;
  line-height: 1.5;
  color: #333;
}
.schedule-table td {
  position: relative;
}

.slot-date {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.section-visio {
  /* padding: 8rem 1.5rem; */
}
/* Tooltip container */
.info-box {
  display: flex;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  color: #333;
}

.info-box i {
  margin-right: 10px;
  font-size: 1.5em; /* ajuster la taille de l'icône ici */
}

.meeting-card {
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1); /* ajout d'une ombre douce */
  border-radius: 10px; /* coins arrondis */
  transition: transform 0.3s ease; /* animation de levée */
}

.meeting-card:hover {
  transform: translateY(-10px); /* lève la carte lors du survol */
}

.box {
  padding: 20px; /* espace intérieur supplémentaire */
  background-color: #f5f5f5; /* fond plus clair */
}

.icon-container {
  background-color: rgb(
    72,
    145,
    129
  ) !important; /* couleur d'arrière-plan pour l'icône */
  padding: 10px;
  border-radius: 50%;
}

.meeting-detail {
  font-size: 0.9em; /* réduit la taille de la police pour les détails */
  color: #666; /* couleur de police plus claire pour les détails */
}

.meeting-button {
  width: 100%; /* fait en sorte que le bouton remplisse l'espace disponible */
  border-radius: 5px; /* coins légèrement arrondis sur le bouton */
  color: #fff; /* texte blanc pour le bouton */
  background-color: #7957d5; /* couleur d'arrière-plan pour le bouton */
}

.meeting-button.is-disabled {
  background-color: #ccc; /* couleur d'arrière-plan pour le bouton désactivé */
}

.delete-meeting-button {
  border-radius: 50px; /* Rend les coins du bouton arrondis */
  padding: 10px 20px; /* Ajuste le padding autour du texte et de l'icône */
  display: inline-flex; /* Met l'icône et le texte côte à côte */
  justify-content: center; /* Centre le contenu horizontalement */
  align-items: center; /* Centre le contenu verticalement */
  font-size: 16px; /* Taille de la police du texte */
  transition: background-color 0.3s ease; /* Ajoute une transition au changement de couleur d'arrière-plan */
  margin-top: 10px; /* Ajoute un espace au-dessus du bouton */
}

.delete-meeting-button:hover {
  background-color: red; /* Change la couleur de fond lors du survol */
}

.delete-meeting-button:active {
  transform: scale(
    0.95
  ); /* Réduit légèrement la taille du bouton lors du clic */
}

.delete-meeting-button i {
  margin-right: 10px; /* Ajoute un espace entre l'icône et le texte */
}
</style>
