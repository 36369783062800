import Parse from "parse";

export async function fetchRelectureData(
  fileDoneId,
  selectedUser,
  typeExercice
) {
  try {
    const fileQuery = new Parse.Query("file");
    fileQuery.equalTo("objectId", fileDoneId);

    const userQuery = new Parse.Query("User");
    if (selectedUser && selectedUser.objectId) {
      userQuery.equalTo("objectId", selectedUser.objectId);
    } else {
      userQuery.equalTo("objectId", selectedUser.id);
    }

    const fileDoneQuery = new Parse.Query("file_done");
    fileDoneQuery.limit(500);
    fileDoneQuery.equalTo("file", await fileQuery.first());
    fileDoneQuery.equalTo("user", await userQuery.first());

    const [fileDoneResults] = await Promise.all([fileDoneQuery.find()]);
    // const filteredFileDoneResults = fileDoneResults.filter(result => result.attributes.time_elapsed >= 30);
    const filteredFileDoneResults = fileDoneResults;

    if (typeExercice === "Questionnaire") {
      var folderQuery = new Parse.Query("folder");
      folderQuery.equalTo("objectId", fileDoneResults[0].get("module").id);
      const [folderResults] = await Promise.all([folderQuery.find()]);

      var LinkQuery = new Parse.Query("link");
      LinkQuery.equalTo("parent_folder", folderResults[0]);
      LinkQuery.exists("file"); // Filtrer les résultats ayant la propriété composite_file non nulle
      LinkQuery.include("file");
      const [LinkResults] = await Promise.all([LinkQuery.find()]);

      var QuestionnaireResult = new Parse.Query("questionnaire");
      QuestionnaireResult.equalTo(
        "objectId",
        LinkResults[0].attributes.file.attributes.questionnaire_.id
      );
      const [QuestionnaireResults] = await Promise.all([
        QuestionnaireResult.find(),
      ]);

      for (var i = 0; i < filteredFileDoneResults.length; i++) {
        var QuestionResult = new Parse.Query("question_result");
        QuestionResult.equalTo("questionnaire", QuestionnaireResults[0]);
        QuestionResult.lessThanOrEqualTo(
          "required_points",
          filteredFileDoneResults[i].get("note")
        );
        QuestionResult.descending("required_points");
        QuestionResult.limit(1);
        const [QuestionResults] = await Promise.all([QuestionResult.find()]);

        filteredFileDoneResults[i].set("questionResults", QuestionResults);

        var resultatQuestionnaire = filteredFileDoneResults[i].get(
          "resultatQuestionnaire"
        );
        if (typeof resultatQuestionnaire === "string") {
          try {
            const resultatQuestionnaireObj = JSON.parse(resultatQuestionnaire);
            filteredFileDoneResults[i].set(
              "resultatQuestionnaire",
              resultatQuestionnaireObj
            );
          } catch (error) {
            console.error(
              "Erreur lors de l'analyse de resultatQuestionnaire :",
              error
            );
          }
        }
        if (filteredFileDoneResults[i].get("resultatQuestionnaire")) {
          filteredFileDoneResults[i].attributes.resultatQuestionnaire.forEach(
            async (element) => {
              // Récupérer la note et l'ID de la question pour chaque résultat
              var noteQuestionnaire = element.note;
              var questionId = element.question_id;

              // Initialiser un nouvel attribut 'text' pour chaque résultat
              element.text = "";

              // Rechercher la question et la réponse correspondant à la note
              var queryQuestion = new Parse.Query("question");
              queryQuestion.equalTo("objectId", questionId);
              var queryQuestionAnswer = new Parse.Query("question_answer");
              queryQuestionAnswer.matchesQuery("question", queryQuestion);
              queryQuestionAnswer.equalTo("points", noteQuestionnaire);

              // Récupérer l'attribut 'text' de la réponse et l'affecter à l'attribut 'text' du résultat
              const questionAnswer = await queryQuestionAnswer.first();
              if (questionAnswer) {
                element.text = questionAnswer.attributes.text;
              }
            }
          );
        }
      }
    }

    return {
      fileDone: filteredFileDoneResults,
    };
  } catch (error) {
    console.error(error);
    throw error;
  }
}
