<template>
  <div>
    <div v-if="props">
      <div style="color: black; width: 50%; float: left">
        <p>
          {{ $t("utilisateur") }} :
          <label style="color: grey; padding-left: 2px">{{
            props.attributes.user.attributes.email
          }}</label>
        </p>
        <p style="color: black" v-if="props.attributes.link">
          {{ $t("seance") }} :
          <label style="color: grey; padding-left: 2px">
            {{ props.attributes.link.attributes.parent_folder.attributes.name }}
          </label>
        </p>
        <p v-else-if="props.attributes.file">
          {{ $t("seance") }} :
          <label style="color: grey; padding-left: 2px">{{
            $t("entraintementLibre")
          }}</label>
        </p>
        <p v-if="props.attributes.link">
          {{ $t("exercice") }} :
          <label style="color: grey; padding-left: 2px">
            {{ props.attributes.link.attributes.file.attributes.name }}
          </label>
        </p>
        <p v-else-if="props.attributes.file">
          {{ $t("exercice") }} :
          <label style="color: grey; padding-left: 2px">{{
            props.attributes.file.attributes.name
          }}</label>
        </p>
        <p>
          {{ $t("duree") }} :
          <label style="color: grey; padding-left: 2px">{{
            props.labelChrono
          }}</label>
        </p>
        <p>
          Réalisé le :
          <label style="color: grey; padding-left: 2px">{{
            props.realiseLe
          }}</label>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "export-pdf",
  props: ["props"],
};
</script>
