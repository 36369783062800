var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"menu menuResponsiveAni"},[(_vm.needReplace)?_c('b-collapse',{attrs:{"open":_vm.isOpen}},[_c('div',{staticClass:"is-sidebar-menu"},[_c('ul',{staticClass:"menu-list"},_vm._l((_vm.myHeadings),function(heading,index){return _c('div',{key:index,staticClass:"rub"},[_c('li',[_c('router-link',{class:{
                disabledMenuSidebar:
                  !_vm.payingSubscription && !heading.displayedForFree,
              },attrs:{"to":heading.to}},[_c('li',[_c('span',{class:{
                    disabledText:
                      !_vm.payingSubscription && !heading.displayedForFree,
                  }},[_vm._v(" "+_vm._s(heading.name)+" "+_vm._s(_vm.$t(heading.name)))])])]),_c('hr'),_c('hr')],1)])}),0),_c('div',{staticClass:"mentions"},[_c('a',{attrs:{"href":"https://www.symbiocenter.com/assets/Mentions_legales.pdf","target":"_blank"}},[_c('u',[_vm._v("Mentions légales")])])]),_c('div',{staticClass:"LienFormations"},[_c('a',{attrs:{"href":"https://www.symbiofi.com","target":"_blank"}},[_c('u',[_vm._v("Formations")]),_c('img',{attrs:{"src":require("../../../assets/img/Symbiofi2015 RBV PNG.png"),"alt":"log symbiofi"}})])])])]):_c('b-collapse',{attrs:{"open":_vm.isOpen}},[_c('div',{staticClass:"is-sidebar-menu"},[_c('ul',{staticClass:"menu-list"},_vm._l((_vm.myHeadings),function(heading,index){return _c('div',{key:index,staticClass:"rub"},[(
              heading.isActive &&
              heading.heading.name !== 'monEspace' &&
              heading.heading.name !== 'actualites' &&
              heading.heading.name !== 'Mes options' &&
              heading.heading.name !== 'maConfiguration'
            )?_c('li',[_c('router-link',{attrs:{"to":heading.heading.to}},[_c('span',{on:{"click":function($event){return _vm.resetStorage()}}},[_c('i',{staticClass:"fas",class:_vm.getFontAwesomeIcon(heading.heading.name)}),_vm._v(" "+_vm._s(" " + _vm.$t(heading.heading.name)))])]),_c('hr')],1):_vm._e()])}),0),_c('div',{staticClass:"has-text-centered box-information"},[_c('a',{staticClass:"button is-primary is-margin-10",on:{"click":function($event){return _vm.deconnexion()}}},[_vm._v(" Déconnexion")])]),_c('div',{staticClass:"mentions"},[_c('a',{attrs:{"href":"https://www.symbiocenter.com/assets/Mentions_legales.pdf","target":"_blank"}},[_c('u',[_vm._v("Mentions légales")])])]),_c('div',{staticClass:"LienFormations"},[_c('a',{attrs:{"href":"https://www.symbiofi.com","target":"_blank"}},[_c('u',[_vm._v("Formations")]),_c('img',{attrs:{"src":require("../../../assets/img/Symbiofi2015 RBV PNG.png"),"alt":"log symbiofi"}})])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }