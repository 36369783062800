import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export async function getUserById(userId) {
  ParseConfig();
  const ObjectUser = Parse.Object.extend("User");
  const QueryUser = new Parse.Query(ObjectUser);
  QueryUser.equalTo("objectId", userId);
  try {
    const ResponseUser = await QueryUser.find();
    return {
      objectId: ResponseUser[0].id,
      nom: ResponseUser[0].attributes.lastname,
      prenom: ResponseUser[0].attributes.firstname,
      mail: ResponseUser[0].attributes.username,
      link: ResponseUser[0].attributes.link,
      updatedAt: ResponseUser[0].attributes.updatedAt,
      QuestionnaireBegin: ResponseUser[0].attributes.QuestionnaireBegin,
      cgu: ResponseUser[0].attributes.cgu,
      therapeute: ResponseUser[0].attributes.therapeute,
      acces_donnees_therapeute: ResponseUser[0].attributes.acces_donnees_therapeute,
      withdrawal_credit_date: ResponseUser[0].attributes.withdrawal_credit_date,
      derniere_connexion: ResponseUser[0].attributes.derniere_connexion,
      duree_acces: ResponseUser[0].attributes.duree_acces,
      createdAt: ResponseUser[0].attributes.createdAt,
    };
  } catch (e) {
    return null;
  }
}
