<template>
  <div
    class="contact-block"
    @click="selectContactMethod(method)"
    :class="{ expanded: selected, disabled: method === 'ai' }"
  >
    <i :class="iconClass"></i>
    <h3>{{ title }}</h3>
    <p>{{ description }}</p>
    <div class="badge" v-if="numberNotif">{{ numberNotif }}</div>
    <div
      v-if="method === 'video' && !haveMeetingAvailability"
      class="availability-icon"
    >
      <i class="fas fa-calendar-times"></i>
    </div>
    <p
      v-if="method === 'video' && !haveMeetingAvailability && isTherapeute"
      class="availability-text"
    >
      Vous devez remplir vos disponibilités.
    </p>
    <p
      v-else-if="
        method === 'video' && !haveMeetingAvailability && !isTherapeute
      "
      class="availability-text"
    >
      Votre thérapeute n'a pas encore rempli ses disponibilités.
    </p>
  </div>
</template>

<script>
import Parse from "parse";

import { getTherapeuteFromUser } from "@/services/utilisateurs/getTherapeuteFromUser.js";

export default {
  data() {
    return {
      TherapeuteInfo: null,
    };
  },
  props: {
    method: String,
    selected: Boolean,
    numberNotif: Number,
  },
  async created() {
    console.log('Récupération des informations du thérapeute');
    try{
      this.TherapeuteInfo = await getTherapeuteFromUser(Parse.User.current());
      console.log(Parse.User.current());
      console.log(this.TherapeuteInfo);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des informations du thérapeute',error);
    }
    finally {
      console.log('Informations du thérapeute',this.TherapeuteInfo);
    }
    console.log('Démarrage de la vérification de la disponibilité du thérapeute');
    this.therapistNotAvailable();
  },
  computed: {
    iconClass() {
      switch (this.method) {
        case "chat":
          return "fa fa-comments icon-block";
        case "video":
          return "fas fa-video icon-block";
        case "ai":
          return "fa fa-comment-dots icon-block";
        default:
          return "";
      }
    },
    haveMeetingAvailability() {
      return this.isTherapeute
        ? !!Parse.User.current().attributes.meeting_availabilities
        : !!this.TherapeuteInfo.meeting_availabilities;
    },
    isTherapeute() {
      return Parse.User.current().attributes.therapeute;
    },
    TherapeuteHasMeetingAvaibility() {
      return !!this.TherapeuteInfo.meeting_availabilities;
    },
    title() {
      switch (this.method) {
        case "chat":
          return "Chat";
        case "video":
          return "Visio-Conférence";
        case "ai":
          return "Symbio-Bot";
        default:
          return "";
      }
    },
    description() {
      if (this.method === "chat") {
        return this.isTherapeute
          ? "Contacter votre patient via un système de messagerie."
          : "Contacter votre thérapeute via un système de messagerie.";
      } else if (this.method === "video") {
        return this.isTherapeute
          ? "Planifier une visio-conférence avec votre patient."
          : "Prendre un rendez-vous de visio-conférence avec votre thérapeute.";
      } else if (this.method === "ai") {
        return "Bientôt disponible";
      }
      return "";
    },
  },
  methods: {
    selectContactMethod() {
      this.$emit("select", this.method);
    },
    therapistNotAvailable() {
      console.log(this.method, this.haveMeetingAvailability, this.isTherapeute);
      if (this.method === 'video' && !this.haveMeetingAvailability && !this.isTherapeute) {
        // Émettre un événement avec une valeur spécifique pour informer le parent
        console.log('Therapist availability not filled');
        this.$emit('therapistAvailabilityNotFilled', true);
      }
    },
  },
};
</script>
<style scoped>
.contact-block {
  flex: 1;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: #e3e3e3;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease; /* Transition pour toutes les propriétés */
  margin: 5px;
  position: relative; /* Assurez-vous que la position est définie */
}

.badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  padding: 0.2rem 0.5rem;
  position: absolute;
  top: 6px;
  right: 6px;
}

.contact-block.expanded {
  flex: 1 1 100%;
  max-width: 100%;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.4);
  background: #f0f0f0;
  padding: 30px;
}
.contact-block:hover {
  transform: translateY(-3px); /* Légère élévation au survol */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  border: 2px solid #489181;
  transition: transform 0.6s ease, box-shadow 0.6s ease, border 0.6s ease;
}

.contact-block.selected {
  border: 2px solid #489181;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.icon-block {
  font-size: 24px;
  margin-bottom: 10px;
  display: block;
  animation: bounce 0.5s ease infinite alternate;
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

.availability-text {
  color: red !important; /* Texte en rouge */
  font-size: 14px; /* Taille de police ajustable selon le besoin */
  padding-top: 5px; /* Ajuster l'espacement selon le besoin */
}

.contact-block h3 {
  color: #333;
  margin-top: 0;
  margin-bottom: 5px;
  font-size: 1rem;
}

.contact-block p {
  color: #555;
  font-size: 12px;
}

.disabled {
  opacity: 0.5;
  pointer-events: none; /* Empêche toute interaction */
  cursor: not-allowed; /* Change le curseur */
  position: relative; /* Nécessaire pour positionner les éléments pseudo */
}

.disabled::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: repeating-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0) 10px,
    rgba(0, 0, 0, 0.1) 10px,
    rgba(0, 0, 0, 0.1) 20px
  );
}
.availability-icon {
  position: absolute;
  top: 0;
  right: 0;
  color: red; /* Couleur de l'icône */
  font-size: 20px; /* Taille de l'icône */
  padding: 10px; /* Espacement autour de l'icône */
}
</style>
