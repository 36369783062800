const abonnements = {
  1: "gratuit",
  2: "play",
  3: "pulse",
};

export default function getAccesWithAbonnement(type_abonnement) {
  if (type_abonnement === abonnements[1]) {
    return 1;
  }
  if (type_abonnement === abonnements[2]) {
    return 2;
  }
  if (type_abonnement === abonnements[3]) {
    return 3;
  }
}
