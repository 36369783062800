<template>
  <div></div>
</template>

<script>
import router from "@/router";

export default {
  name: "validation-paiement",
  mounted() {
    if (this.$route.query.validationpaiementabonnement) {
      router.push("/abonnement?validationpaiement=true");
    } else if (this.$route.query.retourabonnement) {
      router.push("/abonnement?retour=true");
    } else if (this.$route.query.validationpaiementinscriptionplay) {
      router.push("/inscription-offre-play?validationpaiement=true");
    } else if (this.$route.query.retourinscriptionplay) {
      router.push("/inscription-offre-play?retour=true");
    } else if (this.$route.query.validationpaiementinscriptionpulse) {
      router.push("/inscription-offre-pulse?validationpaiement=true");
    } else if (this.$route.query.retourinscriptionpulse) {
      router.push("/inscription-offre-pulse?retour=true");
    }
  },
};
</script>
