import initialisationTransactionData from "./initialisationTransactionData";
import setServer from "./setServer";
import { dataToSendResiliation } from "./dataToSend";
import calculateMAC from "./calculateMAC";

export default function initialisationResiliation(data, tpe) {
  let transactionTpe = tpe;
  let transactionData = {};
  let postOptions = {};
  let mac = null;
  transactionData = initialisationTransactionData(data);
  transactionData.date_commande = data.date_commande;
  const responseSetServer = setServer(transactionTpe);
  transactionTpe.server = responseSetServer.server;
  postOptions = responseSetServer.postOptions;
  const responseDataToSend = dataToSendResiliation(
    transactionData,
    transactionTpe
  );
  // mac = calculateMAC(responseDataToSend, transactionTpe.CMCIC_CLE);
  mac = calculateMAC(responseDataToSend);
  return {
    mac: mac,
    transactionData: transactionData,
    transactionTpe: transactionTpe,
    postOptions: postOptions,
  };
}
