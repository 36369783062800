import Vue from "vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
export async function setGlobalVariables() {
  try {
    ParseConfig();
    var user = Parse.User.current();
    console.log("%cUtilisateur récupéré ", "color: blue");
    console.log(user);

    if (user == null) {
      return;
    }
    if (user.attributes.new_version_enabled) {
      var NewVersionEnabled = user.attributes.new_version_enabled;
    } else {
      var NewVersionEnabled = false;
    }

    Vue.prototype.$NewVersionEnabled = NewVersionEnabled;
    if (user.attributes.therapeute) {
      var Therapeute = user.attributes.therapeute;
    } else {
      var Therapeute = false;
    }
    Vue.prototype.$Therapeute = Therapeute;
    if (user.attributes.group.attributes.essai_gratuit) {
      var EssaiGratuit = user.attributes.group.attributes.essai_gratuit;
    } else {
      var EssaiGratuit = false;
    }
    Vue.prototype.$EssaiGratuit = EssaiGratuit;
  } catch (error) {
    console.log(error);
  }
}
export async function setIdPatientGlobal(id) {
  try {
    Vue.prototype.$IdPatient = id;
  } catch (error) {
    console.log(error);
  }
}
export async function resetIdPatientGlobal() {
  try {
    Vue.prototype.$IdPatient = null;
  } catch (error) {
    console.log(error);
  }
}
