<template>
  <div>
    <canvas ref="chartAutoEval" height="150px" width="500px"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  props: ["avant", "apres", "max"],
  name: "chart-auto-evaluation",
  methods: {
    loadChart() {
      var mesLabels = ["avant", "après"];
      var mesDatas = [this.avant, this.apres];
      var noteMax = this.max;
      var couleur = ["#88c1c9", "#537b81"];

      const mesDonnees = {
        labels: mesLabels,
        datasets: [
          {
            label: "Note",
            data: mesDatas,
            backgroundColor: couleur,
            order: 1,
          },
        ],
      };
      var chartBrique = this.$refs.chartAutoEval;
      var ctx = chartBrique.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: mesDonnees,
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: noteMax,
                },
              },
            ],
          },
          cutoutPercentage: 75,
        },
      });
    },
  },
  async mounted() {
    this.loadChart();
  },
};
</script>
