<template>
  <div>
    <form>
      <b-field
        label="Nom de la séance de groupe :"
        :type="{ 'is-danger': !sessionGroupValue.name && error }"
        :message="{
          'Veuillez saisir le nom de la séance.':
            !sessionGroupValue.name && error,
        }"
      >
        <b-input v-model="sessionGroupValue.name"></b-input>
      </b-field>
      <b-field label="Description :">
        <quill-editor
          :options="editorOption"
          v-model="sessionGroupValue.description"
        >
        </quill-editor>
      </b-field>
      <br />
      <div class="columns">
        <div class="column is-4">
          <p>{{ $t("definirDateDebut") }}</p>
          <b-datepicker
            v-model="sessionGroupValue.dateStart"
            icon-pack="fa"
            inline
            :first-day-of-week="1"
            :day-names="dayName"
            :month-names="monthName"
            class="is-margin-b-50"
          >
          </b-datepicker>
        </div>
        <div class="column is-4"></div>
        <div class="column is-4">
          <p>{{ $t("definirDateFin") }}</p>
          <b-datepicker
            v-model="sessionGroupValue.dateEnd"
            icon-pack="fa"
            inline
            :first-day-of-week="1"
            :day-names="dayName"
            :month-names="monthName"
          >
          </b-datepicker>
        </div>
      </div>
    </form>
    <br />
    <br />
    <div
      class="navbar is-fixed-bottom"
      style="
        position: float;
        padding-top: 20px;
        border-top: 5px;
        margin-left: 225px;
      "
    >
      <div class="navbar-end is-margin-r-80">
        <a class="button is-cyan" @click="saveAndNext()">
          {{ $t("suivant") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "selection-informations",
  props: ["sessionGroupValue"],
  data() {
    return {
      editorOption: {
        placeholder: "",
        theme: "snow",
      },
      error: null,
      dayName: ["D", "L", "M", "M", "J", "V", "S"],
      monthName: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Aout",
        "Septembre",
        "Octobre",
        "Novembre",
        "Decembre",
      ],
    };
  },
  methods: {
    saveAndNext() {
      this.error = false;
      if (!this.sessionGroupValue.name) {
        this.error = true;
        this.$buefy.toast.open({
          duration: 3000,
          message: "Vous devez renseigner le nom de la séance.",
          type: "is-danger",
        });
        return;
      }
      if (
        !this.sessionGroupValue.dateEnd ||
        !this.sessionGroupValue.dateStart
      ) {
        this.$buefy.toast.open({
          duration: 3000,
          message:
            "Vous devez renseigner une date de début et une date de fin.",
          type: "is-danger",
        });
        return;
      }
      if (this.sessionGroupValue.dateEnd <= this.sessionGroupValue.dateStart) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "La date de fin ne peut pas être après la date de début.",
          type: "is-danger",
        });
        return;
      }
      var deb = new moment(this.sessionGroupValue.dateStart);
      var fin = new moment(this.sessionGroupValue.dateEnd);
      var duration = fin.diff(deb, "days", true);
      if (duration > 5) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "La durée de la session ne peut pas excéder 5 jours.",
          type: "is-danger",
        });
        return;
      }
      this.$emit("next", 2);
    },
  },
};
</script>
