<template>
  <div>
    <div>
      <div class="columns is-multiline">
        <div class="column is-16">
          <div class="chart-result">
            <!-- <chartFcVue
              :valeurX="valeurX"
              :tabRowFc="TabRowFC"
              :defaultOpenedDetails="DefaultOpenedDetails"
            /> -->
            <chartAni
              :valeurX="valeurX"
              :tabRowFc="TabRowCC"
              :tabRowCC="TabRowFC"
              :defaultOpenedDetails="DefaultOpenedDetails"
            />
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-4 placementCenter">
          <div class="placementInfoBpm">
            <div class="divplacementInfoBpm">
              <span class="is-size-4">
                {{ fcMoyenne ? fcMoyenne : "0" + " bpm" }}
              </span>
              <p
                class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
              >
                {{ $t("moyenneFrequenceCardiaque") }}
              </p>
            </div>
          </div>
        </div>
        <div class="column is-4">
          <!-- <div class="column is-size-4 has-text-centered">
            <div class="card-relecture">
              <p>Temps de calibrage</p>
              <div>
                <p>
                  <span class="duration">{{ labelChrono / 60 }}</span>
                  <span class="unit">min</span>
                  <i class="fas fa-stopwatch logoRelecture"></i>
                </p>
              </div>
            </div>
          </div> -->
          <div class="column is-size-4 has-text-centered">
            <div class="card-relecture">
              <p>Durée de l'exercice</p>
              <div>
                <p>
                  <span class="duration">{{
                    formatDuration(timeElapsed)
                  }}</span>
                  <i class="fas fa-clock logoRelecture"></i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="column is-4 placementCenter">
          <div
            class="placementInfoBpm"
            :style="{
                 'border-color': couleurMoyenne,
                 'border-width': '8px', /* spécifiez la largeur de la bordure selon vos besoins */
                 'border-style': 'solid', /* spécifiez le style de la bordure selon vos besoins */
            }"
          >
            <div class="divplacementInfoBpm">
              <span class="is-size-4">
                {{ ccMoyenne ? ccMoyenne : "0" }}
              </span>
              <p
                class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
              >
                Moyenne générale de l'indice de lâcher-prise
              </p>
            </div>
          </div>
        </div>
        <div class="column is-16">
          <b-field label="commentaire :">
            <b-input
              @input="emitCommentaire"
              maxlength="200"
              type="textarea"
              v-model="commentaireMoniteur"
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import suivieCc from "./tableauDeBord/SuivieCcExercice.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
// import chartFcVue from "./tableauDeBord/chartFcANI.vue";
import chartAni from "./tableauDeBord/chartAni.vue";
export default {
  data() {
    return {
      firstObject: {},
      ouvertureSuiviCcUtilisateur: "",
      fileId: "7rOgxcWOD4",
      protocole: "Entraînement libre",
      userSelected: "tous",
      DisplayRelecture: false,
      commentaireMoniteur: "",
    };
  },
  props: [
    "valeurX",
    "fcMoyenne",
    "ccMoyenne",
    "couleurCCMoyenne",
    "TabRowFC",
    "DefaultOpenedDetails",
    "ValeurCCX",
    "TabRowCC",
    "labelChrono",
    "timeElapsed",
  ],
  components: {
    // suivieCc,
    // chartFcVue,
    chartAni,
  },
  computed: {
    ...User.mapState(["user"]),
  },
  methods: {
    test() {
      ////console.log all props
      //console.log(this.$props);
    },
    emitCommentaire() {
      this.$emit("commentaire-change", this.commentaireMoniteur);
    },
    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}min${remainingSeconds}s`;
    },
  },
  mounted() {
    ParseConfig();
    const objectFolder = Parse.Object.extend("folder");
    const QueryFolders = new Parse.Query(objectFolder);
    QueryFolders.equalTo("objectId", "ALoaWJgV7l");
    QueryFolders.find().then((results) => {
      this.folders = results;
      this.firstObject = this.folders.shift();
      return this.folders, this.firstObject;
    });
  },
  created() {
    if (this.ccMoyenne > 0 && this.ccMoyenne < 30) {
      this.couleurMoyenne = "#ff0000";
    } else if (this.ccMoyenne > 30 && this.ccMoyenne < 60) {
      this.couleurMoyenne = "#ff7f00";
    } else if (this.ccMoyenne > 60 && this.ccMoyenne < 90) {
      this.couleurMoyenne = "#e9e935";
    } else if (this.ccMoyenne > 90 && this.ccMoyenne < 120) {
      this.couleurMoyenne = "#78e20d";
    } else if (this.ccMoyenne > 120 && this.ccMoyenne < 150) {
      this.couleurMoyenne = "#29c029";
    }
  },
};
</script>
<style>
.encadrement {
  margin: 0 auto;
  padding: 8px;
  /* background-color: rgba(0, 0, 0, 0.5); */
}
.unit {
  font-size: 0.8em;
  color: #888;
}
.logoRelecture {
  margin-left: 10px;
}
.animated-timer {
  font-size: 3em;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.card-relecture {
  background-color: #f5f5f5;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.placementInfoBpm {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 10px;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.divplacementInfoBpm {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.placementCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
