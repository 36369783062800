<!-- ULRICHHAPPI rien n'a ete modifie -->
<template>
  <div v-if="folders">
    <div
      class="column is-2 is-multiline folders-dashboard"
      v-for="folderBrique in folders.filter(
        (e) => e.theme.ordre == numTheme && e.aAfficher
      )"
      :key="folderBrique.id"
    >
      <div>
        <p
          style="min-height: 60px"
          class="has-text-centered is-size-5 has-text-weight-semibold"
        >
          {{ folderBrique.name.toUpperCase() }}
        </p>
        <br />
        <div
          v-for="folderProtocole in folderBrique.foldersProtocole.filter(
            (e) => {
              return e.aAfficher;
            }
          )"
          :key="folderProtocole.id"
          class="is-margin-b-20"
        >
          <div
            v-if="
              folderProtocole.name !== 'Entraînement libre' &&
              folderProtocole.name !== 'Découverte vidéo de la technique'
            "
          >
            <div>
              <div>
                <p class="brique-text-avancement">
                  {{ folderProtocole.name }}
                </p>
              </div>
              <chart-brique
                v-if="nbUtilisateur"
                :data="[
                  Math.round(
                    folderProtocole.sommeCabinet / nbUtilisateur.cabinet
                  ),
                  Math.round(
                    folderProtocole.sommeLicence / nbUtilisateur.licence
                  ),
                  Math.round(
                    folderProtocole.sommeAffiliation / nbUtilisateur.affiliation
                  ),
                ]"
                :moyenne="
                  Math.round(folderProtocole.somme / nbUtilisateur.total)
                "
              ></chart-brique>
              <chart-brique-user
                v-else
                :data="[Math.round(folderProtocole.somme)]"
              ></chart-brique-user>
            </div>
            <div>
              <p
                class="has-text-centered is-size-5 has-text-weight-semibold"
                v-if="
                  nbUtilisateur && nbUtilisateur.total && folderProtocole.somme
                "
              >
                Moyenne :
                {{ Math.round(folderProtocole.somme / nbUtilisateur.total) }}
                %
              </p>
              <p
                class="has-text-centered is-size-5 has-text-weight-semibold"
                v-else-if="nbUtilisateur"
              >
                Moyenne : 0 %
              </p>
              <hr />
            </div>
            <div
              style="text-align: center"
              v-if="folderProtocole.type === 'Protocole long'"
            >
              <p>
                Résultats de l'auto-évaluation sur
                <b>{{ folderProtocole.foldersModule[0].maxPoint }} pts</b>
              </p>
              <chart-auto-evaluation
                :max="folderProtocole.foldersModule[0].maxPoint"
                :avant="folderProtocole.foldersModule[0].noteMoyenne"
                :apres="folderProtocole.foldersModule[5].noteMoyenne"
              ></chart-auto-evaluation>
            </div>
          </div>
        </div>
        <div
          v-if="folderBrique.name.includes('cardiaque')"
          class="has-text-centered is-margin-t-20"
        >
          <a class="button is-cyan" @click="isCardResultat = true">
            {{ $t("resultat") }}
          </a>
        </div>
        <div
          v-if="folderBrique.name.includes('cardiaque')"
          class="has-text-centered is-margin-t-20"
        >
          <a
            v-if="statistiques"
            class="button is-cyan"
            @click="isCardStatistiques = true"
          >
            {{ $t("statistiques") }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartBrique from "./chartBrique.vue";
import chartBriqueUser from "./chartBriqueUser.vue";
import chartAutoEvaluation from "./chartAutoEvaluation.vue";
export default {
  props: ["folders", "nbUtilisateur", "numTheme", "statistiques"],
  name: "presentation-protocole",
  components: {
    chartBrique: chartBrique,
    chartBriqueUser: chartBriqueUser,
    chartAutoEvaluation: chartAutoEvaluation,
  },
};
</script>
