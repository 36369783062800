<template>
  <div class="is-main-content">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <div layout="row">
          <label class="title is-size-4">{{
            $t("bienvenueEspaceManager")
          }}</label>
          <label class="title is-size-4" style="font-style: italic">
            {{ $t("bienvenueEspaceManager1") }}
          </label>
        </div>
        <div class="columns is-multiline">
          <div
            class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image is-2by1">
                  <img
                    src="../../../assets/img/image-symbiofi-front.png"
                    alt="Image mon espace"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h2 class="subtitle is-size-5">
                    {{ $t("personnaliserAccesPageAccueil") }}
                  </h2>
                  <ul>
                    <li>{{ $t("pageAccueilInformation1") }}</li>
                    <li>{{ $t("pageAccueilInformation2") }}</li>
                    <li>{{ $t("pageAccueilInformation3") }}</li>
                    <li>{{ $t("pageAccueilInformation4") }}</li>
                  </ul>
                  <div class="has-text-centered">
                    <a v-on:click="verifToken()" class="button is-cyan is-margin-b-10" :href="urlFront">
                      {{ $t("monEspaceActivite") }} 
                    </a>
                    <br />
                    <router-link :to="'/mon-espace'">
                      <a class="button-param">
                        {{ $t("modifierEspaceActivite") }}
                        <span class="img-param"></span>
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen"
          >
            <div class="has-text-centered" v-if="!payingSubscription">
              <router-link :to="'/abonnement'">
                <a
                  class="button is-primary is-large disabled-button-home buttonRadius"
                >
                  {{ $t("souscrire") }}
                </a>
              </router-link>
            </div>
            <div class="card" :class="{ disabled: !payingSubscription }">
              <div :class="{ disabledText: !payingSubscription }">
                <div class="card-image">
                  <figure class="image is-2by1">
                    <img
                      src="../../../assets/img/image-accueil-tbb.png"
                      alt="Image tableau de bord"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <h2 class="subtitle is-size-5">
                      {{ $t("texteTitreTableauDeBord") }}
                    </h2>
                    <ul>
                      <li>{{ $t("pageTDBInformation1") }}</li>
                      <li>{{ $t("pageTDBInformation2") }}</li>
                      <li>{{ $t("pageTDBInformation3") }}</li>
                    </ul>
                    <div class="has-text-centered">
                      <router-link :to="'/tableau-de-bord'">
                        <a class="button is-cyan is-centered">
                          {{ $t("tableauDeBord") }}
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen"
          >
            <div class="has-text-centered" v-if="!payingSubscription">
              <router-link :to="'/abonnement'">
                <a
                  class="button is-primary is-large disabled-button-home buttonRadius"
                >
                  {{ $t("souscrire") }}
                </a>
              </router-link>
            </div>
            <div class="card" :class="{ disabled: !payingSubscription }">
              <div :class="{ disabledText: !payingSubscription }">
                <div class="card-image">
                  <figure class="image is-2by1">
                    <img
                      src="../../../assets/img/image-symbiofi-utilisateur.png"
                      alt="Image utilisateurs"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <h2 class="subtitle is-size-5">
                      {{ $t("texteTitreUtilisateur") }}
                    </h2>
                    <ul>
                      <li>{{ $t("pageUtilisateurInformation1") }}</li>
                      <li>{{ $t("pageUtilisateurInformation2") }}</li>
                      <li>{{ $t("pageUtilisateurInformation3") }}</li>
                      <li>{{ $t("pageUtilisateurInformation4") }}</li>
                      <li>{{ $t("pageUtilisateurInformation5") }}</li>
                    </ul>
                    <div class="has-text-centered">
                      <router-link :to="'/utilisateurs'">
                        <a class="button is-cyan is-centered">
                          {{ $t("utilisateurs") }}
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen"
          >
            <div class="card">
              <div class="card-image">
                <figure class="image is-2by1">
                  <img
                    src="../../../assets/img/image-symbiofi-retrocession.png"
                    alt="Image retrocession"
                  />
                </figure>
              </div>
              <div class="card-content">
                <div class="content">
                  <h2 class="subtitle is-size-5">
                    {{ $t("texteTitreRetrocession") }}
                  </h2>
                  <ul>
                    <li>{{ $t("pageRetrocessionInformation1") }}</li>
                    <li>{{ $t("pageRetrocessionInformation2") }}</li>
                    <li>{{ $t("pageRetrocessionInformation3") }}</li>
                  </ul>
                  <div class="has-text-centered">
                    <router-link :to="'/retrocession-et-royalties'">
                      <a class="button is-cyan is-centered">
                        {{ $t("RetrocessionEtRoyalties") }}
                      </a>
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-6-tablet is-6-desktop is-4-widescreen"
          >
            <div class="card">
              <div>
                <div class="card-image">
                  <figure class="image is-2by1">
                    <img
                      src="../../../assets/img/img_accueil_abonnement.png"
                      alt="Image abonnement"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <div class="content">
                    <h2 class="subtitle is-size-5">
                      {{ $t("gestionDesAbonnements") }}
                    </h2>
                    <ul>
                      <li>{{ $t("pageAbonnementInformation1") }}</li>
                      <li>{{ $t("pageAbonnementInformation2") }}</li>
                      <li>{{ $t("pageAbonnementInformation3") }}</li>
                    </ul>
                    <div class="has-text-centered">
                      <router-link :to="'/abonnement'">
                        <a class="button is-cyan is-centered">
                          {{ $t("abonnement") }}
                        </a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import hasPayingSubscription from "@/services/userGroup/subscriptionType.js";
import { URL_FRONT } from "@/configuration/urlConf.js";
import router from "@/router";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
const User = createNamespacedHelpers("user");
import { verificationToken } from "@/services/token/verificationToken.js";

export default {
  name: "home",
  data() {
    return {
      payingSubscription: false,
      isCardWelcome: true,
      isCardWelcomeOpen: false,
      urlFront: URL_FRONT,
      etape1Check: false,
      animated: "fade",
      interval: 6000,
      items: [
        {
          title: "Personnaliser mes informations professionnelles",
          image:
            "https://symbiocenter.fr/wp-content/uploads/2021/07/slider_connexion_gestion_PRO_001_c_070721.jpg",
          rating: 4.4,
        },
        {
          title: "Personnaliser votre environnement",
          image:
            "https://symbiocenter.fr/wp-content/uploads/2021/07/slider_connexion_gestion_PRO_002_b_070721.jpg",
          rating: 3.5,
        },
        {
          title: "Afffecter les briques et protocoles",
          image:
            "https://symbiocenter.fr/wp-content/uploads/2021/07/slider_connexion_gestion_PRO_003_a_070721.jpg",
          rating: 5,
        },
        {
          title: "Pratique de vos utilisateurs en séance",
          image:
            "https://symbiocenter.fr/wp-content/uploads/2021/07/slider_connexion_gestion_PRO_004_a_070721.jpg",
        },
        {
          title: "Pratique de vos utilisateurs à distance",
          image:
            "https://symbiocenter.fr/wp-content/uploads/2021/07/slider_connexion_gestion_PRO_005_a_070721.jpg",
          rating: 5,
        },
        {
          title: "Suivi et prise en charge",
          image:
            "https://symbiocenter.fr/wp-content/uploads/2021/07/slider_connexion_gestion_PRO_006_a_070721.jpg",
          rating: 4,
        },
      ],
    };
  },
  computed: {
    ...User.mapGetters([
      "user",
      "abonnement",
      "group",
      "firstConnection",
      "sauvegardeUtilisateur",
    ]),
  },
  methods: {
    ...User.mapActions({
      actionsIsNotFirstConnection: "isNotFirstConnection",
      actionsLogOut: "logOut",
      actionsUpdateSauvegardeUtilisateur: "updateSauvegardeUtilisateur",
    }),
    async goToActivites() {
      await this.actionsLogOut();
      window.location.href = this.urlFront;
    },
    async verifToken()
    {
      document.cookie = "idUserForSave=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";

      var token = localStorage.getItem("token");
      var loginTimestamp = parseInt(localStorage.getItem("dateConnexion"), 10);
      var result = await verificationToken(token);
      
      if(Date.now() - loginTimestamp > 12 * 60 * 60 * 1000)
      {
        // si le token est trop vieux, on le supprime
        localStorage.removeItem("token");
        localStorage.removeItem("idUserForSave");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
      if(result == false){
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");

      }
    },
  },
  async mounted() {
    // try {
    //   var query = new Parse.Query("site_admin");
    //   var resultat = await query.first();
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }
    if (this.user && !this.user.username) {
      location.reload();
    }
    this.payingSubscription = hasPayingSubscription(this.abonnement);
    if (this.sauvegardeUtilisateur) {
      this.$buefy.toast.open({
        duration: 5000,
        message: "Mise à jour de votre profil effectuée avec succès",
        type: "is-success",
      });
      this.actionsUpdateSauvegardeUtilisateur();
    }
    if (!this.firstConnection) {
      return;
    }
    if (this.group.parametrage_fait) {
      return;
    }
    this.isCardWelcomeOpen = true;
  },
  watch: {
    abonnement() {
      this.payingSubscription = hasPayingSubscription(this.abonnement);
    },
  },
};
</script>

<style></style>
