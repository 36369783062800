var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-fullheight"},[_c('div',{staticClass:"is-margin-10"},[_c('div',{staticClass:"columns has-text-centered"},[_c('div',{staticClass:"column is-12"},[_c('h3',{staticClass:"title has-text-grey is-size-4"},[_vm._v(" "+_vm._s(_vm.$t("souscrivezOffrePlay"))+" ")]),(!_vm.goToPaiement)?_c('div',{staticClass:"box"},[_c('p',{staticClass:"is-size-3 is-margin-b-20"},[_vm._v("1 – "+_vm._s(_vm.$t("choixForfait")))]),_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen has-text-centered"},[_c('a',{staticClass:"button is-blueregister is-large button-inscription-offre-abonnement",class:{ isGrey: !_vm.abonnement.engagementAnnuel },on:{"click":function($event){_vm.abonnement.engagementAnnuel = true;
                  _vm.contratAccepte = false;}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 button-text-offre-abonnement"},[_c('p',{staticClass:"is-size-6"},[_vm._v(_vm._s(_vm.$t("avecEngagementAnnuel")))])]),_c('div',{staticClass:"column is-12 button-text-offre-abonnement"},[_c('p',{staticClass:"is-size-4"},[_vm._v(" "+_vm._s((this.playAnnuel / 12).toFixed(2))+"€ / "+_vm._s(_vm.$t("mois"))+" ttc ")])]),_c('div',{staticClass:"column is-12 button-text-offre-abonnement"},[_c('p',{staticClass:"is-size-7"},[_c('span',{staticClass:"has-text-success"},[_vm._v(" "+_vm._s(_vm.$t("economieDe"))+" ("+_vm._s(this.playEconomie)+") ")]),_c('br'),_vm._v(" "+_vm._s(_vm.$t("prelevementAnnuelDe"))+" "+_vm._s(this.playAnnuel.toFixed(2))+" € ttc. "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("debiteEn"))+" 1 "+_vm._s(_vm.$t("fois"))+". ")])])])])]),_c('div',{staticClass:"column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen has-text-centered"},[_c('a',{staticClass:"button is-blueregister is-large button-inscription-offre-abonnement",class:{ isGrey: _vm.abonnement.engagementAnnuel },on:{"click":function($event){_vm.abonnement.engagementAnnuel = false;
                  _vm.contratAccepte = false;}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12 button-text-offre-abonnement"},[_c('p',{staticClass:"is-size-6"},[_vm._v(_vm._s(_vm.$t("sansEngagement")))])]),_c('div',{staticClass:"column is-12 button-text-offre-abonnement"},[_c('p',{staticClass:"is-size-4"},[_vm._v(" "+_vm._s(this.playMensuel.toFixed(2))+"€ / "+_vm._s(_vm.$t("mois"))+" ttc ")])])])])])]),_c('p',{staticClass:"is-size-3 is-margin-b-20"},[_vm._v(" 2 – "+_vm._s(_vm.$t("vosInformationsProfessionnelles"))+" ")]),_c('form',[_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.civilite && _vm.error },"message":{
                'Veuillez sélectionner votre civilite':
                  !_vm.user.civilite && _vm.error,
              }}},[_c('b-select',{attrs:{"placeholder":"Civilité","expanded":""},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.civilite),callback:function ($$v) {_vm.$set(_vm.user, "civilite", $$v)},expression:"user.civilite"}},_vm._l((_vm.civilites),function(civilite){return _c('option',{key:civilite,domProps:{"value":civilite}},[_vm._v(" "+_vm._s(civilite)+" ")])}),0)],1),_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.nom && _vm.error },"message":{
                'Veuillez saisir votre nom de famille': !_vm.user.nom && _vm.error,
              }}},[_c('b-input',{attrs:{"placeholder":"Nom de famille"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.nom),callback:function ($$v) {_vm.$set(_vm.user, "nom", $$v)},expression:"user.nom"}})],1),_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.prenom && _vm.error },"message":{
                'Veuillez saisir votre prénom': !_vm.user.prenom && _vm.error,
              }}},[_c('b-input',{attrs:{"placeholder":"Prénom"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.prenom),callback:function ($$v) {_vm.$set(_vm.user, "prenom", $$v)},expression:"user.prenom"}})],1),_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.profession && _vm.error },"message":{
                'Veuillez saisir votre profession': !_vm.user.profession && _vm.error,
              }}},[_c('b-input',{attrs:{"placeholder":"Profession"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.profession),callback:function ($$v) {_vm.$set(_vm.user, "profession", $$v)},expression:"user.profession"}})],1),_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.adresse && _vm.error },"message":{
                'Veuillez saisir votre adresse professionnelle':
                  !_vm.user.adresse && _vm.error,
              }}},[_c('b-input',{attrs:{"placeholder":"Adresse professionelle"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.adresse),callback:function ($$v) {_vm.$set(_vm.user, "adresse", $$v)},expression:"user.adresse"}})],1),_c('b-field',[_c('b-input',{attrs:{"placeholder":"Complément d'adresse"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.complementAdresse),callback:function ($$v) {_vm.$set(_vm.user, "complementAdresse", $$v)},expression:"user.complementAdresse"}})],1),_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.codePostal && _vm.error },"message":{
                    'Veuillez saisir votre code postal':
                      !_vm.user.codePostal && _vm.error,
                  }}},[_c('b-input',{attrs:{"placeholder":"Code postal"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.codePostal),callback:function ($$v) {_vm.$set(_vm.user, "codePostal", $$v)},expression:"user.codePostal"}})],1)],1),_c('div',{staticClass:"column is-6"},[_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.ville && _vm.error },"message":{
                    'Veuillez saisir votre code postal': !_vm.user.ville && _vm.error,
                  }}},[_c('b-input',{attrs:{"placeholder":"Ville"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.ville),callback:function ($$v) {_vm.$set(_vm.user, "ville", $$v)},expression:"user.ville"}})],1)],1)]),_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.user.telephone_pro && _vm.error },"message":{
                'Veuillez saisir votre téléphone professionel':
                  !_vm.user.telephone_pro && _vm.error,
              }}},[_c('b-input',{attrs:{"placeholder":"Téléphone professionel"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.telephone_pro),callback:function ($$v) {_vm.$set(_vm.user, "telephone_pro", $$v)},expression:"user.telephone_pro"}})],1),_c('b-field',{attrs:{"type":{
                'is-danger':
                  ((!_vm.user.mail || _vm.errorMessage) && _vm.error) ||
                  _vm.errorEmailFormat ||
                  _vm.errorEmailUse ||
                  _vm.errorMajuscule,
              },"message":{
                'Veuillez saisir votre mail professionel':
                  !_vm.user.mail && _vm.error,
                'Format de l\'email incorrect': _vm.errorEmailFormat,
                'Email déjà utilisé': _vm.errorEmailUse,
                'L\'email ne doit pas contenir de majuscule': _vm.errorMajuscule,
              }}},[_c('b-input',{attrs:{"placeholder":"Mail professionel","id":"copy"},on:{"focus":function($event){return _vm.doNotCopyPast('copy')},"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.mailUser),callback:function ($$v) {_vm.mailUser=$$v},expression:"mailUser"}})],1),_c('b-field',{attrs:{"type":{
                'is-danger':
                  (!_vm.mailCopy && _vm.error) || _vm.errorSamePassword || _vm.errorMajuscule,
              },"message":{
                'Veuillez recopier votre mail professionel':
                  !_vm.mailCopy && _vm.error,
                'Il faut que votre adresse email soit identique à la précédente':
                  _vm.errorSamePassword && _vm.error,
                'L\'email ne doit pas contenir de majuscule': _vm.errorMajuscule,
              }}},[_c('b-input',{attrs:{"placeholder":"Recopier email","id":"past"},on:{"focus":function($event){return _vm.doNotCopyPast('past')},"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.mailCopy),callback:function ($$v) {_vm.mailCopy=$$v},expression:"mailCopy"}})],1),_c('b-field',[_c('b-radio-button',{attrs:{"native-value":"Siret","type":"is-primary"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.siretOrAdeli),callback:function ($$v) {_vm.siretOrAdeli=$$v},expression:"siretOrAdeli"}},[_c('span',[_vm._v(_vm._s(_vm.$t("siret")))])]),_c('b-radio-button',{attrs:{"native-value":"Adeli","type":"is-primary"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.siretOrAdeli),callback:function ($$v) {_vm.siretOrAdeli=$$v},expression:"siretOrAdeli"}},[_c('span',[_vm._v(_vm._s(_vm.$t("adeli")))])])],1),(_vm.siretOrAdeli === 'Siret')?_c('b-field',{attrs:{"type":{
                'is-danger':
                  (!_vm.user.siret && _vm.error && _vm.siretOrAdeli === 'Siret') ||
                  _vm.errorSiret,
              },"message":{
                'Veuillez saisir votre n° SIRET':
                  !_vm.user.siret && _vm.error && _vm.siretOrAdeli === 'Siret',
                'Numero de siret incorrect': _vm.errorSiret,
              }}},[_c('b-input',{staticClass:"is-margin-b-20",attrs:{"placeholder":"n° SIRET"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.siret),callback:function ($$v) {_vm.$set(_vm.user, "siret", $$v)},expression:"user.siret"}})],1):_vm._e(),(_vm.siretOrAdeli === 'Adeli')?_c('b-field',{attrs:{"type":{
                'is-danger':
                  (!_vm.user.adeli && _vm.error && _vm.siretOrAdeli === 'Adeli') ||
                  _vm.errorAdeli,
              },"message":{
                'Veuillez saisir votre n° ADELI':
                  !_vm.user.adeli && _vm.error && _vm.siretOrAdeli === 'Adeli',
                'Numero de siret incorrect': _vm.errorAdeli,
              }}},[_c('b-input',{staticClass:"is-margin-b-20",attrs:{"placeholder":"n° ADELI"},on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.user.adeli),callback:function ($$v) {_vm.$set(_vm.user, "adeli", $$v)},expression:"user.adeli"}})],1):_vm._e(),(_vm.abonnement.engagementAnnuel)?_c('p',{staticClass:"is-size-3 is-margin-b-20"},[_vm._v(" 3 – "+_vm._s(_vm.$t("cadeauBienvenue"))+" ")]):_vm._e(),(_vm.abonnement.engagementAnnuel)?_c('div',{staticClass:"field"},[_c('p',{staticClass:"has-text-justified is-margin-b-10"},[_vm._v(" "+_vm._s(_vm.$t("recevezCartesPostales"))+" ")]),(_vm.abonnement.engagementAnnuel)?_c('b-checkbox',{staticClass:"is-margin-b-20",on:{"input":function($event){_vm.contratAccepte = false}},model:{value:(_vm.abonnement.cartesPostales),callback:function ($$v) {_vm.$set(_vm.abonnement, "cartesPostales", $$v)},expression:"abonnement.cartesPostales"}},[_vm._v(" "+_vm._s(_vm.$t("souhaiteRecevoirCartesPostales"))+" ")]):_vm._e(),(_vm.abonnement.cartesPostales)?_c('b-checkbox',{model:{value:(_vm.adresseLivraisonDifferente),callback:function ($$v) {_vm.adresseLivraisonDifferente=$$v},expression:"adresseLivraisonDifferente"}},[_vm._v(" "+_vm._s(_vm.$t("adresseLivraisonDifferente"))+" ")]):_vm._e(),(_vm.adresseLivraisonDifferente)?_c('adresseLivraisonComponent',{attrs:{"propsInformationAdresse":_vm.abonnement.adresse,"error":_vm.error},on:{"updateAdresse":_vm.updateAdresse}}):_vm._e()],1):_vm._e(),_c('b-field',{attrs:{"type":{ 'is-danger': !_vm.contratAccepte && _vm.errorContratAccepte },"message":{
                'Veuillez accepter le contrat avant de confirmer votre abonnement.':
                  !_vm.contratAccepte && _vm.errorContratAccepte,
              }}},[_c('b-checkbox',{model:{value:(_vm.contratAccepte),callback:function ($$v) {_vm.contratAccepte=$$v},expression:"contratAccepte"}},[_vm._v(" "+_vm._s(_vm.$t("cgu"))+" ("),_c('a',{attrs:{"href":"https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("cgu2_cgv")))]),_vm._v(","),_c('a',{attrs:{"href":"https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf","target":"_blank"}},[_vm._v(_vm._s(_vm.$t("cgu2_cgu")))]),_vm._v(","),_c('a',{attrs:{"href":"https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf","target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("cgu2_rgpd")))]),_vm._v(") ")])],1),_c('div',[(_vm.error && _vm.errorMessage)?_c('p',{staticClass:"has-text-weight-light has-text-danger is-size-6"},[_vm._v(" "+_vm._s(_vm.errorMessage)+" ")]):_vm._e()]),(_vm.contratAccepte && !_vm.error && _vm.tpeIsReady)?_c('a',{staticClass:"button is-block is-primary is-fullwidth",on:{"click":function($event){_vm.goToPaiement = true}}},[_vm._v(" "+_vm._s(_vm.$t("procederAuPaiement"))+" ")]):_vm._e()],1)]):(_vm.contratAccepte && !_vm.error && _vm.tpeIsReady && _vm.goToPaiement)?_c('div',[_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("abonnement"))+" "+_vm._s(_vm.abonnement.engagementAnnuel ? "annuel" : "mensuel")+" ")]),_c('p',{staticClass:"has-text-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("montantARegler"))+" : "+_vm._s(_vm.abonnement.engagementAnnuel ? this.playAnnuel.toFixed(2) : this.playMensuel.toFixed(2))+" € ")]),_c('formulaire-paiement-component',{attrs:{"transactionData2":_vm.transactionData,"code_societe":_vm.code_societe,"amount":_vm.transactionData.amount,"page":'inscriptionplay',"user":_vm.user}}),_c('a',{staticClass:"button is-grey",on:{"click":function($event){return _vm.retourPaiement()}}},[_vm._v(" "+_vm._s(_vm.$t("retour"))+" ")])],1):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }