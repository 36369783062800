<template>
  <div class="modal-loader-section">
    <div class="modal-loader">
      <LoaderSmb />
      <slot name="text">Chargement en cours</slot>
    </div>
  </div>
</template>

<script>
import LoaderSmb from "./Loader.vue";

export default {
  name: "ModalLoaderI",
  components: { LoaderSmb },
};
</script>

<style scoped>
.modal-loaderI {
  background-color: #239380;
  z-index: 1000;
  width: 400px;
  height: 200px;
  border: 1px solid black;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
