<template>
  <div>
    <canvas ref="chartQuestionDetail" height="150px" width="500px"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import Parse from "parse";
import moment from "moment";
export default {
  props: ["data", "questionnaire", "userSelected"],
  name: "chart-questionnaire-detail-v2",
  methods: {
    loadChart(listeFileDone) {
      var mesLabels = [];
      var mesDatas = [];
      var noteMax = 0;
      var couleur = [];
      for (var i = 0; i < listeFileDone.length; i = i + 1) {
        mesLabels.push(moment(listeFileDone[i].createdAt).format("DD/MM/YYYY"));
        mesDatas.push(listeFileDone[i].attributes.note);
        couleur.push("rgb(77, 160, 255)");
        if (noteMax < listeFileDone[i].attributes.note)
          noteMax = listeFileDone[i].attributes.note;
      }

      const mesDonnees = {
        labels: mesLabels,
        datasets: [
          {
            label: "Note",
            data: mesDatas,
            backgroundColor: couleur,
            order: 1,
          },
        ],
      };
      var chartBrique = this.$refs.chartQuestionDetail;
      var ctx = chartBrique.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: mesDonnees,
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: noteMax,
                },
              },
            ],
          },
          cutoutPercentage: 75,
        },
      });
    },
  },
  async mounted() {
    var quest = this.questionnaire;
    var queryProtocole = new Parse.Query("folder");
    var queryUser = new Parse.Query("User");
    var monUser = await queryUser.get(this.userSelected.id);
    var monProtocole = await queryProtocole.get(quest.id);
    var queryFileDone = new Parse.Query("file_done");
    queryFileDone.limit(10000);
    queryFileDone.equalTo("module", monProtocole);
    queryFileDone.equalTo("user", monUser);
    queryFileDone.ascending("createdAt");
    var listeFileDone = await queryFileDone.find();
    if (listeFileDone.length > 0) this.loadChart(listeFileDone);
  },
};
</script>
