import Vue from "vue";
import Buefy from "buefy";
import "buefy/dist/buefy.css";
import Parse from "parse";
import VueQuillEditor from "vue-quill-editor";
import App from "./App.vue";
import router from "./router";
import VueI18n from "vue-i18n";
import store from "./store/store";
import ImageUploader from "vue-image-upload-resize";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
// ES6 Modules or TypeScript
import Swal from 'sweetalert2'
// eslint-disable-next-line
import 'quill/dist/quill.core.css';
// eslint-disable-next-line
import 'quill/dist/quill.snow.css';
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import { languages } from "@/i18n/index.js";
import { defaultLocale } from "@/i18n/index.js";
const messages = Object.assign(languages);

const crypto = require('crypto');

Vue.prototype.$crypto = crypto;

import { Buffer } from 'buffer';

import {setIdPatientGlobal} from "@/services/utilisateurs/globalSettingsUser.js";
setIdPatientGlobal(null);

// Assurez-vous que Buffer est défini globalement
if (typeof window !== 'undefined') {
  window.Buffer = Buffer;
}
// Vue.use(VueRouter);
Vue.use(Buefy);
Vue.use(Parse);
Vue.use(VueQuillEditor);
Vue.use(ImageUploader);
Vue.use(VueI18n);
Vue.use(VueSweetalert2);
library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(Buefy, { defaultIconPack: "fas" });
Vue.component("v-select", vSelect);
// import swal
Vue.prototype.$swal = Swal;

export const EventBus = new Vue();

var i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: "en",
  messages,
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

// permet de ne pas afficher les messages de debug dans la console

//console.log = function() {};