<template>
  <div>
    <div class="custom-notification">
      <div class="custom-notification-header">
        <span>Notifications ({{ unreadCount }} non lues)</span>
        <!-- <button @click="markAllAsRead">Marquer comme lu</button> -->
      </div>
      <div class="custom-notification-content">
        <div
          class="custom-notification-item"
          v-for="notif in sortedNotifications"
          :key="notif.id"
          :class="{ unread: !notif.attributes.isReadUserPro && isTherapeute }"
        >
          <!-- <div class="custom-notification-user">
            <b-icon class="custom-icon fas fa-user"></b-icon>
            <span class="custom-user-name" v-if="notif.attributes.user">
              {{ notif.attributes.user.attributes.lastname.charAt(0) + notif.attributes.user.attributes.firstname.charAt(0) }}
            </span>
          </div>  -->
          <div
            class="new-indicator"
            v-if="
              (!notif.attributes.isReadUserPro && isTherapeute) ||
              (!notif.attributes.isReadUser && !isTherapeute)
            "
          ></div>
          <div
            class="read-indicator"
            v-else-if="
              (notif.attributes.isReadUserPro && isTherapeute) ||
              (notif.attributes.isReadUser && !isTherapeute)
            "
          >
            <i class="fas fa-check"></i>
            <!-- Vous pouvez utiliser une autre icône si vous préférez -->
          </div>
          <div class="custom-icon-container">
            <b-icon
              v-if="notif.attributes.type === 'Visio-conférence'"
              class="custom-icon fas fa-video"
            ></b-icon>
            <b-icon
              v-else-if="notif.attributes.type === 'Questionnaire'"
              class="custom-icon fas fa-clipboard"
            ></b-icon>
            <b-icon
              v-else-if="notif.attributes.type === 'Questionnaire BFP'"
              class="custom-icon fas fa-clipboard"
            ></b-icon>
          </div>
          <div class="custom-notification-body">
            <div
              class="custom-description"
              v-if="notif.attributes.type === 'Visio-conférence'"
            >
              {{ notif.attributes.description }}
            </div>
            <div
              class="custom-description"
              v-else-if="notif.attributes.type === 'Questionnaire'"
            >
              Score de questionnaire critique
            </div>
            <div
              class="custom-description"
              v-else-if="notif.attributes.type === 'Questionnaire BFP'"
            >
            Évaluation Initiale
            </div>
          </div>
          <span class="custom-notification-time">{{
            timeSince(notif.attributes.createdAt)
          }}</span>

          <!-- <button class="custom-close-notif" @click="removeNotification(notif.id)">x</button> -->
          <button class="detail-button" @click="openPopup(notif)">
            <i class="fas fa-info-circle"></i> Détails
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="popup" v-if="showPopup">
      <div class="popup-content">
        <h3>Détails de la notification</h3>
        <p>{{ currentNotifDetails }}</p>
        <button @click="showPopup = false">Fermer</button>
      </div>
    </div> -->
  </div>
</template>

<script>
import Parse from "parse";
export default {
  data() {
    return {
      showPopup: false,
      currentNotifDetails: "",
      isTherapeute: false,
    };
  },
  props: {
    notifications: Array,
  },
  computed: {
  sortedNotifications() {
    // trier le tableau senon la date de création
    return this.notifications.sort((a, b) => {
      return new Date(b.attributes.createdAt) - new Date(a.attributes.createdAt);
    });
  },
  unreadCount() {
    if (this.isTherapeute) {
      return this.notifications.filter(
        (notif) => !notif.attributes.isReadUserPro
      ).length;
    } else {
      return this.notifications.filter(
        (notif) => !notif.attributes.isReadUser
      ).length;
    }
  },
},
  created() {
    var user = Parse.User.current();
    if (user.attributes.therapeute) {
      this.isTherapeute = true;
    } else {
      this.isTherapeute = false;
    }
  },
  methods: {
    markAllAsRead() {
      this.$emit("markAllAsRead");
    },
    removeNotification(id) {
      // Implementez votre logique ici
      console.log(id);
    },
    openPopup(notif) {
      //   this.showPopup = true;
      //   this.currentNotifDetails = notif.attributes.description;
      this.maskOneAsRead(notif.id);
      this.$emit("notifDetails", notif);
    },
    async maskOneAsRead(objectIdOfNotification) {
      try {
        const ObjetNotif = Parse.Object.extend("notification");
        const QueryNotif = new Parse.Query(ObjetNotif);

        // Rechercher la notification spécifique par son objectId
        QueryNotif.equalTo("objectId", objectIdOfNotification);

        const notificationToMarkAsRead = await QueryNotif.first();

        // Vérifier si la notification existe
        if (notificationToMarkAsRead) {
          // Marquer la notification comme lue en fonction du type d'utilisateur
          if (this.isTherapeute) {
            if (!notificationToMarkAsRead.get("isReadUserPro")) {
              notificationToMarkAsRead.set("isReadUserPro", true);
              await notificationToMarkAsRead.save(); // Sauvegarder les changements
            }
          } else {
            if (!notificationToMarkAsRead.get("isReadUser")) {
              notificationToMarkAsRead.set("isReadUser", true);
              await notificationToMarkAsRead.save(); // Sauvegarder les changements
            }
          }

          // Mettre à jour votre liste locale de notifications, si nécessaire
          this.$emit("updateLastNews");
        }
      } catch (error) {
        console.error(
          `Error in marking the notification as read: ${error.message}`
        );
      }
    },
    timeSince(dateString) {
      const now = new Date();
      const date = new Date(dateString);
      const seconds = Math.floor((now - date) / 1000);

      let interval = seconds / 31536000;
      if (interval > 1) {
        return Math.floor(interval) + " années";
      }
      interval = seconds / 2592000;
      if (interval > 1) {
        return Math.floor(interval) + " mois";
      }
      interval = seconds / 86400;
      if (interval > 1) {
        return Math.floor(interval) + " jours";
      }
      interval = seconds / 3600;
      if (interval > 1) {
        return Math.floor(interval) + " heures";
      }
      interval = seconds / 60;
      if (interval > 1) {
        return Math.floor(interval) + " minutes";
      }
      return Math.floor(seconds) + " secondes";
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-notification {
  position: absolute;
  top: 100%;
  right: 90%;
  width: 320px;
  border: 1px solid #d1d1d1;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  z-index: 1000;
  animation: slideDown 0.3s ease-out;

  .custom-notification-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid #d1d1d1;
    background-color: #f2f4f8;
    font-weight: 600;
    font-size: 18px;

    button {
      background-color: transparent;
      border: none;
      color: #007bff;
      cursor: pointer;
      font-size: 14px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .custom-notification-content {
    max-height: 350px;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background: #b3b3b3;
    }

    .custom-notification-item {
      display: flex;
      position: relative;
      align-items: center;
      justify-content: space-between;
      padding: 12px 16px;
      transition: background 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      &:hover {
        background-color: #f5f5f5;
      }

      .custom-icon {
        flex: 0;
        font-size: 18px;
        color:black;
      }

      .custom-notification-body {
        flex: 1;

        span {
          display: block;
          font-size: 14px;
          color: #333;
        }
      }

      .custom-notification-time {
        font-size: 12px;
        color: #666;
      }

      .custom-close-notif {
        flex: 0;
        background: none;
        border: none;
        color: #999;
        cursor: pointer;
        font-size: 16px;

        &:hover {
          color: #555;
        }
      }
    }
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.custom-notification-user {
  display: flex;
  align-items: center;

  .custom-icon {
    font-size: 18px;
  }

  .custom-user-name {
    font-size: 12px;
    margin-left: 6px;
  }
}

.custom-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;

  .custom-icon {
    font-size: 18px;
  }
}

.custom-description {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.custom-notification-time {
  font-size: 12px;
  color: #666;
  margin-left: auto; /* This will push it to the right */
}

@media (max-width: 850px) {
  .custom-notification {
    right: 0;
  }
}
.custom-notification-item.unread {
  background-color: #f5f5f5;
}
.view-details {
  background-color: #007bff;
  color: white;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}

.detail-button {
  background-color: transparent;
  border: 1px solid gray; /* Couleur de bordure grise */
  color: gray; /* Texte grise */
  padding: 5px 10px; /* Espacement interne */
  border-radius: 3px; /* Coins arrondis */
  cursor: pointer; /* Curseur en forme de main */
  font-size: 12px; /* Taille du texte */
  display: flex; /* Layout flexible pour aligner icône et texte */
  align-items: center; /* Alignement vertical des éléments */
  margin-left: 10px;
}

/* Style pour le bouton "Détails" au survol */
.detail-button:hover {
  background-color: #489181; /* Couleur d'arrière-plan au survol */
  border-color: #489181; /* Couleur de bordure au survol */
  color: white; /* Texte en blanc au survol */
}
.custom-notification-item.unread {
  background-color: #f0f0f0;
  color: #000; /* Changez la couleur du texte */
}
.new-indicator {
  width: 10px;
  height: 10px;
  background-color: #d9534f;
  border-radius: 50%;
  position: absolute;
  top: 5px;
  left: 5px;
}

.read-indicator {
  position: absolute;
  top: 5px;
  left: 5px;
  font-size: 14px;
  color: #b0b0b0;
}
</style>
