import Parse from "parse";

export async function getMessages(idTherapeute, User) {
  const therapeuteQuery = new Parse.Query("User");
  therapeuteQuery.equalTo("objectId", idTherapeute);

  const ConversationQuery = new Parse.Query("conversation");
  ConversationQuery.matchesQuery("user_pro", therapeuteQuery);
  ConversationQuery.equalTo("user_patient", User);
  const conversation = await ConversationQuery.first();

  const MessageQuery = new Parse.Query("message");
  MessageQuery.equalTo("conversation", conversation);
  // par ordre du plus vieux au plus récent
  MessageQuery.ascending("createdAt");
  const messages = await MessageQuery.find();

  const MessagesList = messages.map((message) => {
    let isUserIdEqual;
    if (Parse.User.current().attributes.therapeute) {
      isUserIdEqual = message.attributes.sender.id === idTherapeute;
    } else {
      isUserIdEqual = message.attributes.sender.id === User.id;
    }
    if (!isUserIdEqual && !message.attributes.seen) {
      message.set("seen", true); // Marque le message comme vu
      message.save(); // Enregistre la mise à jour dans Parse
    }
    return {
      id: message.id,
      text: message.attributes.text,
      createdAt: message.attributes.createdAt,
      seen: message.attributes.seen,
      userId: message.attributes.sender.id,
      isUserMessage: isUserIdEqual, // true si égal, false sinon
    };
  });
  return MessagesList;
}
