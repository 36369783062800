<template>
  <div class="columns is-multiline is-margin-t-10">
    <div class="column">
      <div class="box has-text-centered box-information box-offre" v-if="User">
        <avatar
          class="center-block"
          v-if="User.attributes.photo"
          :src="User.attributes.photo._url"
        ></avatar>
        <avatar
          class="center-block"
          v-else-if="!User.attributes.photo"
          :username="User.attributes.firstname + ' ' + User.attributes.lastname"
        >
        </avatar>
        <br />
        <p class="is-size-7">{{ User.attributes.email }}</p>
        <p>
          {{ $t("inscritDepuisLe") }} :
          <span class="has-text-weight-semibold">
            {{ new Date(User.createdAt).toLocaleDateString() }}
          </span>
        </p>
        <router-link to="/mon-profil" class="button is-cyan buttonRadius">
          {{ $t("monProfil") }}
        </router-link>
      </div>
    </div>
    <div class="column">
      <div
        class="box has-text-centered box-information box-information-utilisateurs"
        :class="{ boxInformationDanger: group.resilie }"
      >
        <p class="has-text-weight-normal is-size-2 box-titre">
          {{ $t("offre") }}
          <span v-if="group.type_abonnement === 'play'">{{ $t("play") }}</span>
          <span v-else-if="group.type_abonnement === 'pulse'">
            {{ $t("pulse") }}
          </span>
          <span v-else>{{ $t("gratuite") }}</span>
        </p>
        <p
          v-if="
            group.type_abonnement === 'play' ||
            group.type_abonnement === 'pulse'
          "
        >
          <span v-if="!group.resilie">{{ $t("dateRenouvellement") }} :</span>
          <span v-else>{{ $t("termineLe") }} :</span>
        </p>
        <p v-else>{{ $t("creditsDisponibles") }} :</p>
        <router-link
          to="/abonnement"
          class="button is-warning buttonRadius is-margin-b-10 is-margin-t-10"
          v-if="
            (group.type_abonnement === 'play' ||
              group.type_abonnement === 'pulse') &&
            dateRenouvellement &&
            !group.resilie
          "
        >
          {{ new Date(dateRenouvellement).toLocaleDateString() }}
        </router-link>
        <router-link
          to="/abonnement"
          class="button is-danger buttonRadius is-margin-b-10 is-margin-t-10"
          v-else-if="
            (group.type_abonnement === 'play' ||
              group.type_abonnement === 'pulse') &&
            dateRenouvellement &&
            group.resilie
          "
        >
          {{ new Date(dateRenouvellement).toLocaleDateString() }}
        </router-link>
      </div>
    </div>
    <div class="column">
      <div
        class="box has-text-centered box-information box-information-utilisateurs"
      >
        <div class="is-margin-b-10">
          <div>
            <div v-if="nbUsersInMyGroupWithActiveAccess < group.nb_users">
              <br />
            </div>
            <p>{{ $t("licenceAttribuees") }}</p>
            <p class="has-text-weight-semibold is-size-2 box-titre">
              {{ nbUsersInMyGroupWithActiveAccess }} /
              <span v-if="group.nb_users">{{ group.nb_users }}</span>
              <span v-else>0</span>
            </p>
            <a
              v-if="nbUsersInMyGroupWithActiveAccess >= group.nb_users"
              class="button is-primary buttonRadius is-margin-t-10"
            >
              {{ $t("acheter") }}&nbsp;&nbsp;
              <b-icon pack="fas" icon="plus" size="is-small"></b-icon>
            </a>
            <div v-else>
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="column">
      <div
        class="box has-text-centered box-information box-information-utilisateurs"
      >
        <p>{{ $t("affiliations") }}</p>
        <p class="is-size-5">
          {{
            utilisateursAffilie.filter((e) => {
              return e.etat === "valide";
            }).length
          }}
          <b-icon
            pack="fas"
            icon="check-circle"
            size="is-small"
            type="is-success"
          ></b-icon>
        </p>
        <p class="is-size-5">
          {{ affiliation_en_attente }}
          <b-icon
            pack="fa"
            icon="hourglass-half"
            size="is-small"
            type="is-warning"
          ></b-icon>
        </p>
        <p class="is-size-5">
          {{
            utilisateursAffilie.filter((e) => {
              return e.etat === "inactif";
            }).length
          }}
          <b-icon
            pack="fa"
            icon="times"
            size="is-small"
            type="is-danger"
          ></b-icon>
        </p>
        <router-link
          to="/retrocession-et-royalties"
          class="button is-primary buttonRadius"
        >
          <span v-if="group.credit && Math.round(group.credit) >= 2">
            {{ Math.round(group.credit) }} {{ $t("credits") }}
          </span>
          <span v-else-if="Math.round(group.credit) === 1">
            1 {{ $t("credit") }}
          </span>
          <span v-else>0 {{ $t("credit") }}</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";
import getDateRenouvellement from "@/services/abonnement/getDateRenouvellement.js";

export default {
  name: "informations-principales",
  props: [
    "User",
    "group",
    "utilisateursAffilie",
    "nbUsersInMyGroupWithActiveAccess",
    "affiliation_en_attente",
  ],
  components: {
    avatar: Avatar,
  },
  data() {
    return {
      dateRenouvellement: null,
    };
  },
  methods: {
    getDateRenouvellement() {
      this.dateRenouvellement = getDateRenouvellement(this.group);
    },
  },
  mounted() {
    this.getDateRenouvellement();
  },
};
</script>
