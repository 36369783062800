<template>
  <div ref="mainDiv" class="columnFullScreen" id="chartResultId">
    <div class="columns is-multiline">
      <button class="chart-button-fullscreen" v-on:click="toggleFullScreen()">Afficher en plein écran le graphique</button>

      <div class="column is-11 is-offset-custom">
        <div class="card-relecture chart-result">
          <!-- <chartFcVue
            :valeurX="valeurX"
            :tabRowFc="TabRowFC"
            :defaultOpenedDetails="DefaultOpenedDetails"
          /> -->
          <chartBiofeedback
              :valeurX="valeurX"
              :tabRowFc="TabRowFC"
              :tabRowCC="TabRowCC"
              :defaultOpenedDetails="DefaultOpenedDetails"
            />
        </div>
      </div>
      <div class="column is-11 is-offset-custom">
        <!-- <div class="chart-result card-relecture">
          <chartAni
            :valeurX="ValeurCCX"
            :tabRowFc="TabRowCC"
            :defaultOpenedDetails="DefaultOpenedDetails"
          />
        </div> -->
      </div>
      <div class="column is-4">
        <div class="circleFCMoyenne1">
          <div class="circleFCMoyenne2">
            <p class="circleFCMoyenne3 has-text-weight-bold">
              <span class="is-size-4">
                {{ Math.round(fcMoyenne) }}
              </span>
              bpm
            </p>
          </div>
        </div>
        <p
          class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
        >
          {{ $t("moyenneFrequenceCardiaque") }}
        </p>
      </div>
      <div class="column is-4">
        <div class="card-relecture card-relecture-info">
          <p>Durée de l'exercice</p>
          <div>
            <p>
              <span class="duration">{{ formatDuration(timeElapsed) }}</span>
              <i class="fas fa-clock logoRelecture"></i>
            </p>
          </div>
        </div>
        <div class="card-relecture card-relecture-info" style="margin-top: 10px">
          <p>% de temps passé dans chaque zone de cohérences</p>
          <div class="progress-relecture">
            <div
              class="progress-bar-relecture progress-bar-danger-relecture"
              role="progressbar"
              :style="{ width: Pct1 + '%' }"
              :class="{ 'black-percentage': Pct1 === 0 }"
              aria-valuenow="redPercentage"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ Pct1 }}%
            </div>
          </div>
          <div class="progress-relecture">
            <div
              class="progress-bar-relecture progress-bar-warning-relecture"
              role="progressbar"
              :style="{ width: Pct2 + '%' }"
              :class="{ 'black-percentage': Pct2 === 0 }"
              aria-valuenow="yellowPercentage"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ Pct2 }}%
            </div>
          </div>
          <div class="progress-relecture">
            <div
              class="progress-bar-relecture progress-bar-success-relecture"
              role="progressbar"
              :style="{ width: Pct3 + '%' }"
              :class="{ 'black-percentage': Pct3 === 0 }"
              aria-valuenow="greenPercentage"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              {{ Pct3 }}%
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="circleFCMoyenne1">
          <div
            class="circleFCMoyenne4"
            :style="{
              'background-color': couleurCCMoyenne,
            }"
          >
            <p class="circleFCMoyenne3 has-text-weight-bold">
              <span class="is-size-4">
                {{ Math.round(ccMoyenne) + "%" }}
              </span>
            </p>
          </div>
        </div>
        <p
          class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
        >
          Moyenne cohérence
        </p>
      </div>
      <div v-if="comment" class="column is-12">
        <b-field label="commentaire :">
          <b-input
            @input="emitCommentaire"
            maxlength="200"
            type="textarea"
            v-model="commentaireMoniteur"
          ></b-input>
        </b-field>
      </div>
      <div v-if="comment" class="column is-12 containerButtonSave">
        <button class="buttonChart contentButtonSave" v-on:click="emitSave()">
          Sauvegarder
        </button>
      </div>
    </div>
    <!-- <div class="PlacementBoutonFullScreen">
      <button class="BoutonFullScreen" v-on:click="toggleFullscreen()">
        Mettre en plein écran la relecture
      </button>
    </div> -->
  </div>
</template>

<script>
// import suivieCc from "../tableauDeBord/SuivieCcExercice.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
// import chartFcVue from "../tableauDeBord/chartFc.vue";
// import chartAni from "../tableauDeBord/chartMoniteur.vue";
import chartBiofeedback from "../tableauDeBord/chartBiofeedback.vue";

export default {
  data() {
    return {
      firstObject: {},
      ouvertureSuiviCcUtilisateur: "",
      fileId: "7rOgxcWOD4",
      protocole: "Entraînement libre",
      userSelected: "tous",
      DisplayRelecture: false,
      commentaireMoniteur: "",
      greenPercentage: 10,
      redPercentage: 20,
      yellowPercentage: 50,
      isFullscreen: false,
    };
  },
  props: [
    "valeurX",
    "fcMoyenne",
    "ccMoyenne",
    "couleurCCMoyenne",
    "TabRowFC",
    "DefaultOpenedDetails",
    "ValeurCCX",
    "TabRowCC",
    "labelChrono",
    "timeElapsed",
    "comment",
    "Pct1",
    "Pct2",
    "Pct3",
  ],
  components: {
    // suivieCc,
    // chartFcVue,
    // chartAni,
    chartBiofeedback,
  },
  computed: {
    ...User.mapState(["user"]),
  },
  created() {
    this.commentaireMoniteur = this.comment;
    console.log('ALL PROPS', this.$props);
  },
  methods: {
    toggleFullScreen() {
      let element = document.getElementById("chartResultId");
      if (!document.fullscreenElement) {
        element.requestFullscreen().catch((err) => {
          alert(
            `Erreur lors de la tentative de passage en plein écran: ${err.message} (${err.name})`
          );
        });
        this.isFullScreen = true; // Mettre à jour l'état en plein écran
      } else {
        document.exitFullscreen();
      }
    },
   
    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      if (remainingSeconds === 1 && minutes === 0 || remainingSeconds === 5 && minutes === 0) {
        return `${remainingSeconds}min${minutes}s`;
      }
      else
      {
      return `${minutes}min${remainingSeconds}s`;
    }
    },
    toggleFullscreen() {
      if (!document.fullscreenElement) {
        this.$refs.mainDiv
          .requestFullscreen()
          .then(() => {
            this.isFullscreen = true;
          })
          .catch((err) => {
            alert(
              `Erreur en tentant de passer en mode plein écran: ${err.message} (${err.name})`
            );
          });
      } else {
        if (document.exitFullscreen) {
          document.exitFullscreen().then(() => {
            this.isFullscreen = false;
          });
        }
      }
    },
    emitSave() {
      this.$emit("save-change", this.commentaireMoniteur);
    },
    emitCommentaire() {
      this.$emit("commentaire-change", this.commentaireMoniteur);
    },
  },

  mounted() {
    ParseConfig();
    const objectFolder = Parse.Object.extend("folder");
    const QueryFolders = new Parse.Query(objectFolder);
    QueryFolders.equalTo("objectId", "ALoaWJgV7l");
    QueryFolders.find().then((results) => {
      this.folders = results;
      this.firstObject = this.folders.shift();
      return this.folders, this.firstObject;
    });
  },
};
</script>
<style>
.encadrement {
  margin: 0 auto;
  padding: 8px;
  /* background-color: rgba(0, 0, 0, 0.5); */
}
.containerButtonSave {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentButtonSave {
  text-align: center;
}
.unit {
  font-size: 0.8em;
  color: #888;
}
.logoRelecture {
  margin-left: 10px;
}
.animated-timer {
  font-size: 3em;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.card-relecture {
  background-color: #f5f5f5;
  border-radius: 5px;
  /* padding: 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

}
.black-percentage {
  color: black !important;
}

.buttonChart {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: rgb(35, 147, 128) !important;
  color: white;
  margin: 7px;
}
.PlacementBoutonFullScreen {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
.BoutonFullScreen {
  background-color: rgb(35, 147, 128) !important;
  color: white;
  border-radius: 5px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  padding: 5px;
  margin-left: auto;
}
.is-offset-custom {
  margin-left: 4.1666%;
}
.card-relecture {
  background-color: #f5f5f5;
  border-radius: 5px;
  /* padding: 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.card-relecture-info
{
  display: flex;
  flex-direction: column;
    align-items: center;
}

.progress-relecture {
  height: 20px;
  margin-bottom: 10px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  border: 1px solid #ccc;
  width: 100%;
}

.progress-bar-relecture {
  height: 100%;
  /* color: #fff; */
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
}

.progress-bar-success-relecture {
  background-color: #28a745;
}

.progress-bar-danger-relecture {
  background-color: #dc3545;
}

.progress-bar-warning-relecture {
  background-color: #ffc107;
}
.columnFullScreen {
  /* overflow-y:auto; */
}

.chart-button-fullscreen {
  background-color: #239380; /* Green background */
  color: white; /* White text */
  padding: 6px 12px; /* Padding around the text */
  font-size: 12px; /* Button text size */
  border: none; /* No borders */
  border-radius: 5px; /* Rounded corners */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
  display: inline-flex; /* Flexbox for inline alignment */
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center text and icon horizontally */
  margin-bottom: 1rem; /* Add some space below the button */
  margin-left: auto;
  display: flex;
  margin-right:1rem;
  margin-top: 1rem;
}

#chartResultId
{
  background-color: white;
}
</style>
