<template>
  <div class="item">
    <!--   wave -->
    <svg width="500" 
      id="gsap riseAndShine"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="100 0 428.4 80"
      style="enable-background: new 0 0 428.4 129.1"
      xml:space="preserve"
    >
      <title>Element 18</title>
      <defs>
        <linearGradient
          id="myGradient"
          gradientUnits="userSpaceOnUse"
          x1="0%"
          y1="0%"
          x2="0%"
          y2="100%"
        >
          <stop offset="0%" stop-color="#ff0000" />
          <stop offset="100%" stop-color="#239380" />
        </linearGradient>
      </defs> 

      <g id="Ebene_2_1_">
        <g id="Ebene_1-2">
          <path
            fill="url(#MyGradient)"
            class="st0 leaf-2"
            d="M295.4,31.8c-0.1-0.8-0.1-1.5-0.1-2.3c-0.1-3.4,0.4-6.7,1.5-9.9c-5.1-4.4-10.2-10.9-12.8-14.6
                                          c-1.3,5-3.3,15.2-0.7,22.9C287.5,28.6,291.6,29.9,295.4,31.8z"
          />
          <path
            fill="url(#MyGradient)"
            class="st0 leaf-3"
            d="M298.4,29.5v0.1c-0.1,1.6,0.2,3.2,0.7,4.7c2.6,2,4.6,4.7,5.8,7.8l0,0c4.6-5.3,5.9-6.7,8-11l1.4-2.9l1.4,2.9
                                          c2.1,4.3,3.3,5.8,8,11l0,0c1.2-3.1,3.2-5.8,5.8-7.8c0.6-1.5,0.8-3.1,0.7-4.7v-0.1v-0.1c0.2-6.1-1.9-12-5.9-16.7
                                          c-3.7-4-7-8.2-10-12.7c-3,4.5-6.4,8.8-10,12.7C300.3,17.4,298.2,23.3,298.4,29.5L298.4,29.5z"
          />
          <path
            fill="url(#MyGradient)"
            class="st0 leaf-4"
            d="M333.1,29.6c0,0.8,0,1.5-0.1,2.3c3.8-1.9,7.9-3.2,12.1-3.8c2.6-7.7,0.6-18-0.7-22.9
                                          c-2.6,3.6-7.7,10.2-12.8,14.6C332.8,22.8,333.3,26.2,333.1,29.6z"
          />
          <path
            fill="url(#MyGradient)"
            class="st0 leaf-1"
            d="M302.5,44.6c-3.5-13.4-23.9-14.1-24.1-14.1h-0.2c-5.3-0.7-9.7-4.8-12.1-7.7c-0.3,3.1-0.3,8.5,2.3,16.1
                                          c0.1,0.2,6.4,18.3,26.8,23.5c0.1-2.6,0.5-5.1,1.3-7.6c1.1-3.5,2.9-6.7,5.3-9.5C302.1,45.1,302.3,44.9,302.5,44.6z"
          />
          <path
            fill="url(#MyGradient)"
            class="st0 leaf-6"
            d="M324.2,47.4c-1.1-1.3-2.1-2.4-3-3.4c-3.6-4.1-5.4-6.1-7-9c-1.7,3-3.4,4.9-7.1,9l-3,3.4
                                          c-2.1,2.4-3.7,5.3-4.7,8.4c-0.8,2.4-1.1,4.9-1.2,7.4c5.2,1.7,10.6,2.6,16,2.6c5.4,0,10.7-0.9,15.8-2.6c0-2.5-0.4-5-1.1-7.4
                                          C328,52.7,326.4,49.8,324.2,47.4z"
          />
          <path
            fill="url(#MyGradient)"
            class="st0 leaf-5"
            d="M350.2,30.6H350c-0.2,0-20.6,0.6-24.1,14.1c0.2,0.2,0.4,0.5,0.6,0.7c2.4,2.7,4.2,6,5.3,9.5
                                          c0.8,2.5,1.2,5,1.3,7.6c20.4-5.1,26.8-23.2,26.9-23.4c2.6-7.6,2.6-13,2.3-16.1C359.9,25.7,355.5,29.9,350.2,30.6z"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
<script>
import { gsap } from "gsap";
export default {
  mounted() {
    this.initializeLoader();
    //console.log("loaderLotus mounted");
  },
  methods: {
    initializeLoader() {
      // gsap loader + copypasta for inverted style
      let leaf1 = document.querySelector("#gsap\\ riseAndShine .leaf-1");
      let leaf2 = document.querySelector("#gsap\\ riseAndShine .leaf-2");
      let leaf3 = document.querySelector("#gsap\\ riseAndShine .leaf-3");
      let leaf4 = document.querySelector("#gsap\\ riseAndShine .leaf-4");
      let leaf5 = document.querySelector("#gsap\\ riseAndShine .leaf-5");

      let tl = gsap.timeline({
        repeat: -1,
        repeatDelay: 0.4,
        defaults: {
          duration: 0.15,
          ease: "",
        },
        smoothChildTiming: true,
      });

      let distance = 4;

      tl.to(leaf1, { x: -3, y: -3 })
        .to(leaf1, { x: 0, y: 0 })
        .to(leaf2, { x: distance * -1, y: distance * -1 }, "-=.1")
        .to(leaf2, { x: 0, y: 0 }, "-=.1")
        .to(leaf3, { y: distance * -1 }, "-=.1")
        .to(leaf3, { y: 0 }, "-=.1")
        .to(leaf4, { x: distance, y: distance * -1 }, "-=.1")
        .to(leaf4, { x: 0, y: 0 }, "-=.1")
        .to(leaf5, { x: distance, y: distance * -1 }, "-=.1")
        .to(leaf5, { x: 0, y: 0 }, "-=.1");
    },
  },
};
</script>
<style lang="scss">
// @import url("http://fonts.googleapis.com/css?family=Lato");
 .st0 {
  fill: #239380;
 }
@keyframes bounce {
  0% {
    transform: translateX(3px);
  }
  25% {
    transform: translateX(-3px);
  }
  50% {
    transform: translateX(3px);
  }
  100% {
    transform: translateX(0);
  }
}
.wrapper {
  font-family: "Lato", sans-serif;
  color: #4f5160;
  margin: 30px;
  h1 {
    margin-left: 5px;
  }
  p {
    margin-left: 5px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
  }
  .bar {
    display: flex;
    @media screen and (max-width: 776px) {
      display: block;
    }
    .btn {
      padding: 8px 12px;
      border-radius: 4px;
      display: inline-block;
      border: 1px solid #3fc3bf;
      background: white;
      color: #3fc3bf;
      margin: 0 5px;
      cursor: pointer;
      transition: all 0.2s ease-in;
      &:hover {
        // animation: bounce .1s 3 alternate;
        transform: scale(1.05);
      }
      &.inverted {
        background: #3fc3bf;
        color: white;
      }
    }
    .demo {
      display: flex;
      @media screen and (max-width: 776px) {
        display: none;
      }
      .text {
        padding-top: 8px;
        margin-left: 30px;
        margin-right: 10px;
      }
      .demo-btn {
        padding: 8px 12px;
        background: white;
        color: #3fc3bf;
        border-radius: 4px;
        cursor: pointer;
        min-width: 90px;
        position: relative;
        border: 1px solid #3fc3bf;
        min-height: 18px;
        overflow: hidden;
        margin-right: 5px;
        svg {
          opacity: 1;
          transform: translateY(5px) scale(1.7);
          path {
            fill: #3fc3bf;
          }
        }
        &.inverted {
          background: #3fc3bf;
          color: white;
          svg {
            path {
              fill: white;
            }
          }
        }
        &.inactive {
          span {
            opacity: 1;
          }
          svg {
            opacity: 0;
          }
        }
      }
      span {
        opacity: 0;
        display: block;
        height: 0;
        text-align: center;
        transform: translateY(2px);
      }
    }
  }
  .loaders {
    opacity: 0;
    border-radius: 5px;
    transition: opacity 0.2s ease-in;
    position: absolute;
    top: 162px;
    left: 40px;
    right: 40px;
    margin-bottom: 70px;
    @media screen and (max-width: 776px) {
      top: 220px;
    }
    &.active {
      // display: block;
      opacity: 1;
    }
    &.inverted {
      .container {
        .item {
          background: #3fc3bf;
          border: 1px solid #3fc3bf;
          h3 {
            color: white;
          }
          svg path {
            fill: #ffffff;
          }
        }
      }
    }
    .container {
      display: flex;
      margin-top: 30px;
      flex-wrap: wrap;
      .item {
        width: calc(33% - 80px);
        @media screen and (max-width: 776px) {
          width: 100%;
        }
        text-align: center;
        margin-bottom: 40px;
        border: 1px solid #4f5160;
        border-radius: 5px;
        margin-right: 40px;
        padding: 20px;
        position: relative;
        overflow: hidden;
        .tag {
          position: absolute;
          top: 6px;
          left: -29px;
          transform: rotate(-45deg);
          background: orange;
          padding: 4px;
          width: 88px;
          font-size: 12px;
          font-weight: bold;
          color: white;
        }
      }
    }
  }

  @keyframes shine {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }
  @keyframes rise {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(3px);
    }
    100% {
      transform: translateY(0);
    }
  }
  @keyframes classicCircle {
    0% {
      opacity: 0.2;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.2;
    }
  }

  #shine {
    .st0 {
      opacity: 0.2;
      animation: shine 2.4s ease-out infinite;
      animation-delay: 2.4s;
    }

    .leaf-1 {
      animation-delay: 0s;
    }
    .leaf-2 {
      animation-delay: 0.2s;
    }
    .leaf-3 {
      animation-delay: 0.4s;
    }
    .leaf-6 {
      animation-delay: 0.4s;
    }
    .leaf-4 {
      animation-delay: 0.6s;
    }
    .leaf-5 {
      animation-delay: 0.8s;
    }
  }
  #rise {
    .st0 {
      animation: rise 2s ease-out infinite;
      animation-delay: 2s;
    }

    .leaf-1 {
      animation-delay: 0s;
    }
    .leaf-2 {
      animation-delay: 0.2s;
    }
    .leaf-3 {
      animation-delay: 0.4s;
    }
    // .leaf-6 {  animation-delay: .4s; }
    .leaf-4 {
      animation-delay: 0.6s;
    }
    .leaf-5 {
      animation-delay: 0.8s;
    }
  }
  #riseAndShine {
    .st0 {
      opacity: 0.2;
      animation: shine 2.4s ease-out infinite, rise 2s ease-out infinite;
      animation-delay: 2.2s;
    }

    .leaf-1 {
      animation-delay: 0s;
    }
    .leaf-2 {
      animation-delay: 0.2s;
    }
    .leaf-3 {
      animation-delay: 0.4s;
    }
    .leaf-6 {
      animation-delay: 0.4s;
    }
    .leaf-4 {
      animation-delay: 0.6s;
    }
    .leaf-5 {
      animation-delay: 0.8s;
    }
  }
  #classicCircle {
    .st0 {
      opacity: 0.2;
      animation: classicCircle 1s infinite;
      animation-delay: 1s;
    }

    .leaf-1 {
      animation-delay: 0s;
    }
    .leaf-2 {
      animation-delay: 0.1s;
    }
    .leaf-3 {
      animation-delay: 0.2s;
    }
    .leaf-4 {
      animation-delay: 0.3s;
    }
    .leaf-5 {
      animation-delay: 0.4s;
    }
    .leaf-6 {
      animation-delay: 0.5s;
    }
  }
}
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

#riseAndShine {
  .st0 {
    opacity: 0.2;
    animation: shine 2.4s ease-out infinite, rise 2s ease-out infinite;
    animation-delay: 2.2s;
  }

  .leaf-1 {
    animation-delay: 0s;
  }
  .leaf-2 {
    animation-delay: 0.2s;
  }
  .leaf-3 {
    animation-delay: 0.4s;
  }
  .leaf-6 {
    animation-delay: 0.4s;
  }
  .leaf-4 {
    animation-delay: 0.6s;
  }
  .leaf-5 {
    animation-delay: 0.8s;
  }
}
</style>
