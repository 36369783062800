import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";

export async function getLastConnectionByUserId(userId) {
  ParseConfig();
  Parse.masterKey = "myMasterKey";

  const QueryUser = new Parse.Query(Parse.User);
  const QuerySession = new Parse.Query("session_detail");
  QueryUser.equalTo("objectId", userId);
  const ResponseUser = await QueryUser.first();

  QuerySession.descending("createdAt");
  QuerySession.equalTo("user", ResponseUser);

  const ResponseSession = await QuerySession.first();

  if (ResponseSession) {
    return ResponseSession.get("createdAt");
  }
  return;
}

export async function getLastConnectionByArrayUser(listeUser) {
  ParseConfig();

  const QuerySession = new Parse.Query("session_detail");
  QuerySession.descending("createdAt");
  QuerySession.containedIn("user", listeUser);
  QuerySession.limit(100000);

  const ResponseSession = await QuerySession.find();
  return ResponseSession;
}
