<template>
  <div>
    <div class="columns is-multiline">
      <div class="column is-4 is-margin-b-20">
        <canvas
          ref="chartProtocoleInstalle"
          height="400px"
          width="1000px"
        ></canvas>
        <p class="has-text-weight-semibold has-text-centered">
          {{ $t("installer") }}
        </p>
      </div>
      <div class="column is-4 is-margin-b-20">
        <canvas
          ref="chartProtocoleMaitrise"
          height="400px"
          width="1000px"
        ></canvas>
        <p class="has-text-weight-semibold has-text-centered">
          {{ $t("maitriser") }}
        </p>
      </div>
      <div class="column is-4 is-margin-b-20">
        <canvas
          ref="chartProtocoleAncre"
          height="400px"
          width="1000px"
        ></canvas>
        <p class="has-text-weight-semibold has-text-centered">
          {{ $t("ancrer") }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: ["tabRowInstalle", "tabRowMaitrise", "tabRowAncre"],
  name: "chart-cc",
  methods: {
    loadChart() {
      const mesDonneesInstalle = {
        labels: this.tabRowInstalle.label,
        datasets: [
          {
            label: "Moyenne cohérence cardiaque",
            data: this.tabRowInstalle.data,
            borderColor: this.tabRowInstalle.color,
            backgroundColor: this.tabRowInstalle.color,
          },
        ],
      };
      const chartProtocoleInstalle = this.$refs.chartProtocoleInstalle;
      const ctxInstalle = chartProtocoleInstalle.getContext("2d");
      new Chart(ctxInstalle, {
        type: "bar",
        data: mesDonneesInstalle,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      const mesDonneesMaitrise = {
        labels: this.tabRowMaitrise.label,
        datasets: [
          {
            label: "Moyenne cohérence cardiaque",
            data: this.tabRowMaitrise.data,
            borderColor: this.tabRowMaitrise.color,
            backgroundColor: this.tabRowMaitrise.color,
          },
        ],
      };
      const chartProtocoleMaitrise = this.$refs.chartProtocoleMaitrise;
      const ctxMaitrise = chartProtocoleMaitrise.getContext("2d");
      new Chart(ctxMaitrise, {
        type: "bar",
        data: mesDonneesMaitrise,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
      const mesDonneesAncre = {
        labels: this.tabRowAncre.label,
        datasets: [
          {
            label: "Moyenne cohérence cardiaque",
            data: this.tabRowAncre.data,
            borderColor: this.tabRowAncre.color,
            backgroundColor: this.tabRowAncre.color,
          },
        ],
      };
      const chartProtocoleAncre = this.$refs.chartProtocoleAncre;
      const ctxAncre = chartProtocoleAncre.getContext("2d");
      new Chart(ctxAncre, {
        type: "bar",
        data: mesDonneesAncre,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  mounted() {
    this.loadChart();
  },
  watch: {
    defaultOpenedDetails() {
      this.loadChart();
    },
  },
};
</script>
