import initialisationTransactionData from "./initialisationTransactionData";
import setServer from "./setServer";
import { dataToSend } from "./dataToSend";
// import calculateMAC from "./newCalculateMac.js";
import calculateMAC from "./calculateMAC.js";


export default function initialisationPaiement(data, tpe) {
  let transactionTpe = tpe;
  let transactionData = {};
  let postOptions = {};
  let mac = null;
  transactionData = initialisationTransactionData(data);
  const responseSetServer = setServer(transactionTpe);
  transactionTpe.server = responseSetServer.server;
  postOptions = responseSetServer.postOptions;
  const responseDataToSend = dataToSend(transactionData, transactionTpe);
  mac = calculateMAC(responseDataToSend, transactionTpe.CMCIC_CLE);
  return {
    mac: mac,
    transactionData: transactionData,
    transactionTpe: transactionTpe,
    postOptions: postOptions,
  };
}
