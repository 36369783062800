<template>
  <div class="card">
    <div class="card-content">
      <div class="content">
        <h3 class="title has-text-black is-size-5">
          {{ $t("creationUtilisateurSeanceGroupe") }}
        </h3>
        <hr />
        <form>
          <div class="columns">
            <div class="column is-6">
              <b-field
                custom-class="is-small"
                label="Nom"
                :type="{ 'is-danger': !nouvelUtilisateur.nom && error }"
                :message="{
                  'Veuillez saisir un nom.': !nouvelUtilisateur.nom && error,
                }"
              >
                <b-input
                  size="is-small"
                  v-model="nouvelUtilisateur.nom"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field
                custom-class="is-small"
                label="Prénom"
                :type="{ 'is-danger': !nouvelUtilisateur.prenom && error }"
                :message="{
                  'Veuillez saisir un prenom.':
                    !nouvelUtilisateur.prenom && error,
                }"
              >
                <b-input
                  size="is-small"
                  v-model="nouvelUtilisateur.prenom"
                ></b-input>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <b-field
                custom-class="is-small"
                label="Email"
                :type="{
                  'is-danger':
                    (!email && error) ||
                    errorEmailFormat ||
                    errorEmailUse ||
                    errorMajuscule,
                }"
                :message="{
                  'Veuillez saisir une adresse mail.': !email && error,
                  'Format de l\'email incorrect.': errorEmailFormat,
                  'Email déjà utilisé.': errorEmailUse,
                  'L\'email ne doit pas contenir de majuscule': errorMajuscule,
                }"
              >
                <b-input
                  size="is-small"
                  v-model="mailUser"
                  id="copy"
                  @focus="doNotCopyPast('copy')"
                ></b-input>
              </b-field>
            </div>
            <div class="column is-6">
              <b-field
                custom-class="is-small"
                label="Recopier email"
                :type="{
                  'is-danger':
                    (!mailCopy && error) || errorSamePassword || errorMajuscule,
                }"
                :message="{
                  'Veuillez recopier votre mail professionel':
                    !mailCopy && error,
                  'Il faut que votre adresse email soit identique à la précédente':
                    errorSamePassword && mailUser && mailCopy && error,
                  'L\'email ne doit pas contenir de majuscule': errorMajuscule,
                }"
              >
                <b-input
                  name="recopieremail"
                  expanded
                  size="is-small"
                  id="past"
                  v-model="mailCopy"
                  @focus="doNotCopyPast('past')"
                ></b-input>
              </b-field>
            </div>
          </div>
        </form>
        <br />
        <hr />
        <a class="is-pulled-right button is-cyan" @click="createUser()">
          {{ $t("valider") }}
        </a>
        <a
          class="is-pulled-right button is-grey is-margin-r-10"
          @click="$emit('closeModal')"
        >
          {{ $t("annuler") }}
        </a>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import { getObjectById } from "@/services/parse/getObject.js";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export default {
  name: "creation-utilisateur",
  props: ["group"],
  data() {
    return {
      nouvelUtilisateur: {},
      errorEmailUse: false,
      error: false,
      errorEmailFormat: false,
      errorSamePassword: false,
      mailUser: null,
      mailCopy: null,
      errorMajuscule: false,
    };
  },
  methods: {
    doNotCopyPast(inputId) {
      const myInput = document.getElementById(inputId);
      myInput.onpaste = function (e) {
        e.preventDefault();
      };
      myInput.oncopy = function (e) {
        e.preventDefault();
      };
    },
    async createUser() {
      this.error = false;
      this.errorEmailFormat = false;
      this.errorEmailUse = false;
      if (this.errorSamePassword) {
        return;
      }
      if (
        !this.nouvelUtilisateur.mail ||
        !this.nouvelUtilisateur.nom ||
        !this.nouvelUtilisateur.prenom
      ) {
        this.error = true;
        return;
      }
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("username", this.nouvelUtilisateur.mail.trim());
      const ResponseUser = await QueryUser.find();
      if (ResponseUser.length) {
        this.errorEmailUse = true;
        return;
      }
      const loadingComponent = this.$buefy.loading.open();
      try {
        ParseConfig();
        Parse.masterKey = "myMasterKey";
        const UserObject = Parse.Object.extend("User");
        const UserGroup = await getObjectById(
          "user_group",
          this.group.objectId
        );
        const User = new UserObject();
        User.set("lastname", this.nouvelUtilisateur.nom);
        User.set("firstname", this.nouvelUtilisateur.prenom);
        User.set("email", this.nouvelUtilisateur.mail.trim());
        User.set("username", this.nouvelUtilisateur.mail.trim());
        User.set("type_pratique", "cabinet");
        User.set("password", UserGroup.attributes.password_cabinet);
        User.set("group", UserGroup);
        User.set("cgu", true);
        await User.save();
        this.$emit("createUser");
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Utilisateur créé avec succès.",
          type: "is-success",
        });
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Erreur de création de l'utilisateur.",
          type: "is-danger",
        });
        loadingComponent.close();
        return;
      }
    },
  },
  watch: {
    mailCopy() {
      this.errorSamePassword = false;
      if (this.mailCopy !== this.nouvelUtilisateur.mail) {
        this.errorSamePassword = true;
      }
    },
    mailUser() {
      this.nouvelUtilisateur.mail = this.mailUser;
      this.errorSamePassword = false;
      if (this.mailCopy !== this.nouvelUtilisateur.mail) {
        this.errorSamePassword = true;
      }
      this.errorMajuscule = false;
      const regex = /[A-Z]/gm;
      const str = this.nouvelUtilisateur.mail;
      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        // eslint-disable-next-line no-unused-vars
        m.forEach((match, groupIndex) => {
          this.errorMajuscule = true;
        });
      }
    },
  },
};
</script>
