import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export async function linkToAddToUser(foldersBrique) {
  ParseConfig();
  const linksToAdd = [];
  const queryTheme = new Parse.Query("theme");
  queryTheme.limit(10000);
  const listeTheme = await queryTheme.find();

  for (const brique of foldersBrique) {
    if (!brique.deverrouille) continue;

    linksToAdd.push(brique.link);
    const theme = listeTheme.find((e) => e.id === brique.themeId);

    const protocoles = brique.protocoles.filter(
      (protocole) => protocole.deverrouille
    );
    for (const protocole of protocoles) {
      linksToAdd.push(protocole.link);
      const modulesExpert =
        protocole.buttonExpert &&
        protocole.modulesExpert.filter(
          (moduleExpert) => moduleExpert.deverrouille
        );
      if (modulesExpert) {
        linksToAdd.push(
          ...modulesExpert.map((moduleExpert) => moduleExpert.linkExpert)
        );
      }

      const modulesDecouverte =
        protocole.buttonDecouverte &&
        protocole.modulesDecouverte.filter(
          (moduleDecouverte) => moduleDecouverte.deverrouille
        );
      if (modulesDecouverte) {
        linksToAdd.push(
          ...modulesDecouverte.map(
            (moduleDecouverte) => moduleDecouverte.linkDecouverte
          )
        );
      }

      const modulesP = protocole.modules.filter(
        (moduleP) => moduleP.deverrouille
      );
      for (const moduleP of modulesP) {
        linksToAdd.push(moduleP.link);
        if (theme.attributes.ordre !== 7) {
          const ObjectFolder = Parse.Object.extend("folder");
          const QueryFolder = new Parse.Query(ObjectFolder);
          QueryFolder.equalTo("objectId", moduleP.id);
          QueryFolder.limit(1000);
          const parentModule = await QueryFolder.first();

          const Object = Parse.Object.extend("link");
          const Query = new Parse.Query(Object);
          Query.equalTo("parent_folder", parentModule);
          Query.limit(1000);
          const result = await Query.find();

          linksToAdd.push(...result);
        }
      }
    }
  }

  return linksToAdd;
}
