<template>
  <div class="card editUser" v-if="UserSelected">
    <b-loading
      :is-full-page="true"
      :active.sync="loaderFolderActive"
      :can-cancel="false"
    >
      <p>
        <b-icon
          pack="fas"
          icon="sync-alt"
          size="is-large"
          custom-class="fa-spin"
          type="is-blue"
        ></b-icon>
      </p>
      <p
        style="
          z-index: 10;
          padding-top: 100px;
          margin-left: -150px;
          color: #4579b2;
        "
      >
        <strong class="has-text-blue">
          {{ $t("enregistrementModificationEnCours") }}
        </strong>
      </p>
    </b-loading>
    <div class="card-content">
      <div class="content" v-if="deleteUser">
        <h3 class="title has-text-black is-size-5">
          {{ $t("suppressionUtilisateur") }}
        </h3>
        <p>{{ $t("confirmationSuppressionUtilisateur") }}</p>
        <a class="button is-cyan" @click="supprimerUtilisateur()">
          {{ $t("confirmer") }}
        </a>
        <a class="button is-grey is-pulled-right" @click="deleteUser = false">
          {{ $t("annuler") }}
        </a>
      </div>
      <div class="content" v-else-if="affiliationUser">
        <h3 class="title has-text-black is-size-5">
          {{ $t("affiliationUtilisateur") }}
          <a
            class="linkPlusInfo"
            target="_blank"
            href="https://symbiocenter.fr/2020/01/23/espace-de-gestion-creer-des-utilisateurs/#ghnlnvyvuikvjqyfemwzskgaksnjyadyxqhj"
          >
            + d'infos</a
          >
        </h3>

        <p>
          {{ $t("confirmationAffiliationUtilisateur") }}
        </p>
        <a class="button is-cyan" @click="affilierUtilisateur()">
          {{ $t("confirmer") }}
        </a>
        <a
          class="button is-grey is-pulled-right"
          @click="affiliationUser = false"
        >
          {{ $t("annuler") }}
        </a>
      </div>
      <div class="content" v-else-if="retourCabinet">
        <h3 class="title has-text-black is-size-5">
          {{ $t("retourCabinetApresLicence") }}
        </h3>
        <p>
          {{ $t("annulationLicence") }}
        </p>
        <a class="button is-light" @click="retourCabinet = false">
          {{ $t("annuler") }}
        </a>
        <a class="button is-cyan is-pulled-right" @click="annulerLicence()">
          {{ $t("confirmer") }}
        </a>
      </div>
      <div class="content" v-else>
        <div class="overflow">
          <h3 class="title has-text-black is-size-5">
            {{ $t("modificationDunUtilisateurQuiPratique") }}
            <span v-if="UserSelected.typePratique !== 'licence'">
              {{ $t("auCabinet") }}
              <a
                class="linkPlusInfo"
                target="_blank"
                href="https://www.symbiocenter.fr/2020/01/23/espace-de-gestion-creer-des-utilisateurs/"
              >
                + d'infos</a
              >
            </span>
            <span v-else>
              {{ $t("sousLicence") }}
              <a
                class="linkPlusInfo"
                target="_blank"
                href="https://www.symbiocenter.fr/2020/01/23/espace-de-gestion-creer-des-utilisateurs/"
              >
                + d'infos</a
              >
            </span>
            <div class="is-pulled-right">
              <a
                v-if="UserSelected.typePratique !== 'licence'"
                class="button background-color-adistance color-white is-small is-margin-r-10 is-rounded"
                @click="onSwitchToLicence()"
              >
                {{ $t("versLicence") }}
              </a>
              <a
                v-if="UserSelected.typePratique === 'licence'"
                class="button background-color-enseance color-white is-small is-margin-r-10 is-rounded"
                @click="retourCabinet = true"
              >
                {{ $t("annulerLaLicence") }}
              </a>
              <a
                v-if="
                  !UserSelected.propositionAffiliation &&
                  isThisHeadingIsActive('RetrocessionEtRoyaltiesTitre')
                "
                class="button is-success is-small is-rounded"
                @click="affiliationUser = true"
              >
                {{ $t("affilierUtilisateur") }}
              </a>
            </div>
          </h3>
          <hr />
          <form>
            <div class="columns">
              <div class="column is-6">
                <b-field
                  custom-class="is-small"
                  label="Nom"
                  :type="{ 'is-danger': !UserSelected.nom && error }"
                  :message="{
                    'Veuillez saisir un nom.': !UserSelected.nom && error,
                  }"
                >
                  <b-input
                    disabled
                    size="is-small"
                    v-model="UserSelected.nom"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field
                  custom-class="is-small"
                  label="Prénom"
                  :type="{ 'is-danger': !UserSelected.prenom && error }"
                  :message="{
                    'Veuillez saisir un prenom.': !UserSelected.prenom && error,
                  }"
                >
                  <b-input
                    disabled
                    size="is-small"
                    v-model="UserSelected.prenom"
                  ></b-input>
                </b-field>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-3">
                <b-field label="Date de création">
                  <p>
                    {{ new Date(UserSelected.createdAt).toLocaleDateString() }}
                  </p>
                </b-field>
              </div>
              <div
                class="column is-3"
                v-if="
                  UserSelected.typePratique === 'licence' &&
                  !UserSelected.seance_group
                "
              >
                <b-field label="Durée d'accès distanciel à la plateforme">
                  <b-select v-model="UserSelected.dureeAcces" size="is-small">
                    <option :value="1">1 {{ $t("moisLicence") }}</option>
                    <option :value="2">2 {{ $t("moisLicence") }}</option>
                    <option :value="3">3 {{ $t("moisLicence") }}</option>
                    <option :value="4">4 {{ $t("moisLicence") }}</option>
                    <option v-if="group.credits" :value="1000">Illimité</option>
                  </b-select>
                </b-field>
              </div>
              <div
                class="column is-3 is-size-7"
                v-if="UserSelected.typePratique === 'cabinet'"
              >
                <b-field>
                  <template slot="label">Personnaliser les accès</template>
                  <div class="field">
                    <b-switch
                      v-model="personnaliserAcces"
                      :true-value="true"
                      :false-value="false"
                    ></b-switch>
                  </div>
                </b-field>
              </div>
              <div
                class="column is-3"
                v-if="UserSelected.typePratique === 'licence'"
              >
                <b-field label="Date de début de licence">
                  <div v-if="UserSelected.typePratique !== 'licence'">
                    <p v-if="UserSelected.date_debut_licence">
                      {{
                        new Date(
                          UserSelected.date_debut_licence
                        ).toLocaleDateString()
                      }}
                    </p>
                  </div>
                  <div v-else>
                    {{
                      new Date(
                        UserSelected.withdrawal_credit_date
                      ).toLocaleDateString()
                    }}
                  </div>
                </b-field>
              </div>

              <div
                class="column is-3 is-size-7"
                v-if="UserSelected.typePratique !== 'licence'"
              >
                <b-field>
                  <template slot="label">{{
                    $t("switchPassagePratiqueADistanceModifUtilisateur")
                  }}</template>
                  <div class="field">
                    <b-switch
                      v-model="UserSelected.typePratique"
                      true-value="licenceEdit"
                      false-value="cabinet"
                    >
                    </b-switch>
                  </div>
                </b-field>
              </div>
              <!-- <div class="column is-3 is-size-7">
                <b-field>
                  <template slot="label">Licence active</template>
                  <div class="field">
                    <b-checkbox v-model="licenceActive" true-value="true" false-value="false" disabled>{{
                      UserSelected.dureeAcces }} -- {{ initialSelectValue }}</b-checkbox>
                  </div>
                </b-field>
              </div> -->
              <div
                class="column is-3"
                v-if="UserSelected.typePratique !== 'licence'"
              >
                <b-field label="Durée d'accès distanciel à la plateforme">
                  <b-select
                    size="is-small"
                    v-model="UserSelected.dureeAcces"
                    :disabled="UserSelected.typePratique === 'cabinet'"
                  >
                    <option :value="1">1 {{ $t("moisLicence") }}</option>
                    <option :value="2">2 {{ $t("moisLicence") }}</option>
                    <option :value="3">3 {{ $t("moisLicence") }}</option>
                    <option :value="4">4 {{ $t("moisLicence") }}</option>
                    <option v-if="group.credits" :value="1000">Illimité</option>
                  </b-select>
                </b-field>
              </div>
              <div class="column is-3" v-else>
                <b-field label="Date de fin">
                  <p>
                    {{
                      new Date(
                        UserSelected.fin_acces_plateforme
                      ).toLocaleDateString()
                    }}
                  </p>
                </b-field>
              </div>
              <div class="column is-12">
                <div class="is-pulled-right">
                  <a
                    v-if="UserSelected.propositionAffiliation"
                    class="button is-primary buttonRadius is-rounded is-margin-l-20"
                    @click="affilierUtilisateur()"
                  >
                    {{ $t("relancerLaffiliation") }}
                  </a>
                </div>
              </div>
              <div
                class="column is-6"
                v-if="UserSelected.typePratique !== 'cabinet'"
              >
                <b-field
                  custom-class="is-small"
                  label="Email"
                  :type="{
                    'is-danger':
                      (!UserSelected.mail && error) ||
                      errorEmailFormat ||
                      errorEmailUse ||
                      errorMajuscule,
                  }"
                  :message="{
                    'Veuillez saisir une adresse mail.':
                      !UserSelected.mail && error,
                    'Email déjà utilisé.': errorEmailFormat,
                    'Email déjà utilisé.': errorEmailUse,
                    'L\'email ne doit pas contenir de majuscule':
                      errorMajuscule,
                  }"
                >
                  <b-input size="is-small" v-model="mailUser" id="copy">
                  </b-input>
                </b-field>
              </div>
            </div>
            <div class="columns"></div>
            <a
              class="has-text-weight-semibold has-text-black"
              @click="openAccesExercices = !openAccesExercices"
              v-if="UserSelected.typePratique !== 'cabinet'"
            >
              {{ $t("gestionAccesExercice") }}
              <b-icon v-if="openAccesExercices" pack="fas" icon="sort-down">
              </b-icon>
              <b-icon v-else pack="fas" icon="caret-right"></b-icon>
            </a>

            <folders-component
              v-if="
                UserSelected.typePratique !== 'cabinet' || personnaliserAcces
              "
              v-show="openAccesExercices"
              :foldersBrique="foldersBrique"
              :group="group"
              :themes="themes"
              :isPageUser="true"
              @unlockProtocol="unlockProtocol"
            />
          </form>
        </div>
        <div class="div-BoutonAction-PopupModification">
          <a
            v-if="UserSelected.typePratique !== 'licence'"
            class="button is-danger is-small is-rounded is-margin-r-10"
            @click="deleteUser = true"
          >
            {{ $t("supprimerLutilisateur") }}
          </a>
          <a class="button is-cyan button-float-right" @click="editUser()">{{
            $t("valider")
          }}</a>
          <a
            class="button is-grey is-margin-r-10 button-float-right"
            @click="(UserSelected.mail = mailInitial), $emit('closeModal')"
          >
            {{ $t("annuler") }}
          </a>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import { unlockFolder } from "@/services/folders/unlockFolder.js";
import { linkToAddToUser } from "@/services/folders/linkToAddToUser.js";
import { createSubscriber } from "@/services/utilisateurs/createSubscriber.js";
import foldersComponent from "@/components/folders.vue";
import { TYPE_PRATIQUE } from "@/configuration/userGroupConf.js";
// import codeGenerator from "@/services/general/codeGenerator.js";
import cloneDeep from "lodash/cloneDeep";
import { createNamespacedHelpers } from "vuex";
const User = createNamespacedHelpers("user");
import asyncForEach from "@/services/general/asyncForEach.js";
import checkEmailFormat from "@/services/general/checkEmailFormat";
import isThisHeading from "@/services/headings/isThisHeading.js";

export default {
  name: "modification-utilisateur",
  components: {
    FoldersComponent: foldersComponent,
  },
  props: ["UserSelected", "group", "user"],
  data() {
    return {
      error: false,
      errorEmailFormat: false,
      errorEmailUse: false,
      errorMajuscule: false,
      openAccesExercices: true,
      themes: [],
      foldersBrique: [],
      openSuiviPratique: true,
      linksToAdd: [],
      foldersBriqueEdit: [],
      deleteUser: false,
      affiliationUser: false,
      textLicence: TYPE_PRATIQUE.licence,
      emailDisable: true,
      mailUser: null,
      errorSamePassword: false,
      mailInitial: null,
      pratiqueInitial: null,
      loaderFolderActive: false,
      retourCabinet: false,
      versLicence: false,
      personnaliserAcces: false,
      licenceActive: null,
      initialSelectValue: null,
      newDureeAcces: null,
    };
  },
  computed: {
    ...User.mapGetters(["folders", "headings"]),
  },
  created() {
    if (this.UserSelected.exos_personnaliser) {
      this.personnaliserAcces = true;
    } else {
      this.personnaliserAcces = false;
    }
  },
  methods: {
    onSwitchToLicence() {
      this.versLicence = true;
      this.openAccesExercices = !this.openAccesExercices;
    },
    isThisHeadingIsActive(thisHeading) {
      return isThisHeading(thisHeading);
    },
    unlockProtocol(props) {
      unlockFolder(props, this.group.type_abonnement);
    },
    doNotCopyPast(inputId) {
      const myInput = document.getElementById(inputId);
      myInput.onpaste = function (e) {
        e.preventDefault();
      };
      myInput.oncopy = function (e) {
        e.preventDefault();
      };
    },
    async editUser() {
      //   if (
      //   !this.UserSelected.mail && !this.UserSelected.exos_personnaliser && !this.personnaliserAcces ||
      //   this.errorSamePassword
      // ) {
      //   this.error = true;
      //   return;

      // }
      if (this.personnaliserAcces) {
        this.UserSelected.exos_personnaliser = true;
      } else {
        this.UserSelected.exos_personnaliser = false;
      }
      var listeExoDeverouille = this.foldersBrique.filter(
        (e) => e.deverrouille
      );
      if (
        listeExoDeverouille.length === 0 &&
        this.typePratique !== TYPE_PRATIQUE.affiliation
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message:
            "Vous devez sélectionner au moins une de nos thématiques pour valider la création de l'utilisateur.",
          type: "is-danger",
        });
        return;
      }

      this.errorMajuscule = false;
      const regex = /[A-Z]/gm;
      const str = this.UserSelected.mail;
      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        // eslint-disable-next-line no-unused-vars
        m.forEach((match, groupIndex) => {
          this.errorMajuscule = true;
        });
      }
      if (this.errorMajuscule) return;
      try {
        this.linksToAdd = await linkToAddToUser(this.foldersBrique);
      } catch (e) {
        this.$buefy.toast.open({
          duration: 5000,
          message:
            "Une erreur est survenue lors de la modification de l'utilisateur.",
          type: "is-danger",
        });
        return;
      }

      this.error = false;
      this.errorEmailFormat = false;
      this.errorEmailUse = false;
      const queryUser = new Parse.Query(Parse.User);
      queryUser.equalTo("objectId", this.UserSelected.objectId);
      const userResponse = await queryUser.first();
      if (!userResponse) {
        this.error = true;
        return;
      }

      const query = new Parse.Query(Parse.User);
      query.equalTo("username", this.UserSelected.mail);
      const userResponseTemp = await query.first();
      if (
        this.UserSelected.typePratique === "licenceEdit" &&
        this.group.nb_users_licence >= this.group.nb_users &&
        this.group.nb_users !== null &&
        this.group.nb_users !== undefined
      ) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Vous n'avez plus de crédit.",
          type: "is-danger",
        });
        return;
      }
      if (
        userResponseTemp &&
        userResponse &&
        userResponse.attributes.email !== userResponseTemp.attributes.email
      ) {
        this.errorEmailUse = true;
        return;
      }
      //const loadingComponent = this.$buefy.loading.open();
      this.loaderFolderActive = true;
      if (this.pratiqueInitial !== this.UserSelected.typePratique) {
        // eslint-disable-next-line no-unused-vars
        let pratique = "cabinet";
        if (
          this.UserSelected.typePratique === "licence" ||
          this.UserSelected.typePratique === "licenceEdit"
        ) {
          pratique = "sous licence";
          this.UserSelected.cgu = false;
        }
        console.log('Pratique', pratique)
      }
      if (this.personnaliserAcces) {
        this.UserSelected.exos_personnaliser = true;
      }
      try {
        await createSubscriber(
          this.UserSelected,
          this.group,
          this.linksToAdd,
          this.foldersBriqueEdit,
          userResponse,
          this.pratiqueInitial
        );
        this.loaderFolderActive = false;
        if (this.mailInitial !== this.UserSelected.mail) {
          var userCourant = await Parse.User.current();
          // await Parse.Cloud.run("modificationEmailUtilisateur", {
          //   destinataire: this.mailInitial,
          //   utilisateur: this.UserSelected.nom + " " + this.UserSelected.prenom,
          //   therapeute:
          //     userCourant.attributes.firstname +
          //     " " +
          //     userCourant.attributes.lastname,
          //   nouveau: this.UserSelected.mail,
          // });

          //Envoie de mail après modification d'un utilisateur
        }

        if (this.pratiqueInitial !== this.UserSelected.typePratique) {
          let pratique = "en séance";
          if (
            this.UserSelected.typePratique === "licence" ||
            this.UserSelected.typePratique === "licenceEdit"
          ) {
            pratique = "à distance";
            this.UserSelected.cgu = false;
          }
          userCourant = await Parse.User.current();
          await Parse.Cloud.run("modificationPratiqueUtilisateurTherapeute", {
            destinataire: userCourant.attributes.username,
            anciennePratique: this.pratiqueInitial,
            utilisateur: this.mailInitial,
            therapeute:
              userCourant.attributes.firstname +
              " " +
              userCourant.attributes.lastname,
            pratique: pratique,
          });
        }
        if (this.newDureeAcces === true) {
          const UserObject = Parse.Object.extend("User");
          const UserQuery = new Parse.Query(UserObject);
          UserQuery.equalTo("objectId", this.UserSelected.objectId);
          const userEdit = await UserQuery.first();
          userEdit.set("LicenceActive", false);
          await userEdit.save({}, { useMasterKey: true });
        }
      } catch (e) {
        //console.log(e);
        this.errorEmailFormat = true;
        //loadingComponent.close();
        this.loaderFolderActive = false;
        return;
      }
      this.loaderFolderActive = false;
      //loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Utilisateur modifié avec succès.",
        type: "is-success",
      });
      this.$emit("editUser");
    },
    async sendMail() {
      var userCourant = await Parse.User.current();
      await Parse.Cloud.run("demandeAutorisationAcces", {
        destinataire: this.UserSelected.mail,
        pro:
          userCourant.attributes.firstname && userCourant.attributes.lastname
            ? userCourant.attributes.firstname +
              " " +
              userCourant.attributes.lastname
            : userCourant.attributes.username,
        mail: this.mail,
        utilisateur: this.UserSelected.prenom + " " + this.UserSelected.nom,
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: "Demande d'autorisation envoyée avec succès.",
        type: "is-success",
      });
      this.$emit("closeModal");
    },
    async supprimerUtilisateur() {
      Parse.masterKey = "myMasterKey";
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("objectId", this.UserSelected.objectId);
      const ResponseUser = await QueryUser.first();
      ResponseUser.destroy({ useMasterKey: true });
      this.$buefy.toast.open({
        duration: 3000,
        message: "Utilisateur supprimé avec succès.",
        type: "is-success",
      });
      this.$emit("closeModal", ResponseUser.id);
    },
    async annulerLicence() {
      Parse.masterKey = "myMasterKey";
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("objectId", this.UserSelected.objectId);
      const ResponseUser = await QueryUser.first();
      ResponseUser.set("fin_acces_plateforme", null);
      ResponseUser.set("date_debut_licence", null);
      ResponseUser.set("cgu", true);
      ResponseUser.set("duree_acces", null);
      ResponseUser.set("password", this.group.password_cabinet);
      ResponseUser.set("type_pratique", "cabinet");
      await ResponseUser.save({}, { useMasterKey: true });
      let name = null;
      if (
        ResponseUser.attributes.firstname &&
        ResponseUser.attributes.lastname
      ) {
        name =
          ResponseUser.attributes.firstname +
          " " +
          ResponseUser.attributes.lastname;
      }
      var userCourant = await Parse.User.current();
      await Parse.Cloud.run("modificationPratiqueUtilisateur", {
        destinataire: this.mailInitial,
        anciennePratique: this.pratiqueInitial,
        pratique: "en séance",
        therapeute: userCourant.attributes.username,
        utilisateur: name ? name : this.mailInitial,
      });
      await Parse.Cloud.run("modificationPratiqueUtilisateurTherapeute", {
        destinataire: userCourant.attributes.username,
        utilisateur: this.mailInitial,
        anciennePratique: this.pratiqueInitial,
        therapeute:
          userCourant.attributes.firstname +
          " " +
          userCourant.attributes.lastname,
        pratique: "en séance",
      });
      this.$emit("closeModalLicence", ResponseUser.id);
    },
    async affilierUtilisateur() {
      var userCourant = await Parse.User.current();
      let therapeute = userCourant.attributes.email;
      if (userCourant.attributes.firstname || userCourant.attributes.lastname) {
        therapeute =
          userCourant.attributes.firstname +
          " " +
          userCourant.attributes.lastname;
      }
      Parse.masterKey = "myMasterKey";
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("objectId", this.UserSelected.objectId);
      const ResponseUser = await QueryUser.first();
      ResponseUser.set("date_invitation_affiliation", new Date());
      ResponseUser.set("affiliation", userCourant);
      await ResponseUser.save({}, { useMasterKey: true });
      await Parse.Cloud.run("propositionAffiliation", {
        destinataire: this.UserSelected.mail,
        codeAffiliation: this.group.code_affiliation,
        therapeute: therapeute,
      });
      this.$buefy.toast.open({
        duration: 5000,
        message: "Proposition d'affiliation envoyée avec succès.",
        type: "is-success",
      });
      this.affiliationUser = false;
      // this.$emit("closeModalEdit", "affiliation");
      this.$emit("closeModalEdit");
    },
  },
  async mounted() {
    const loadingComponent = this.$buefy.loading.open();
    if (!this.UserSelected.dureeAcces) {
      this.UserSelected.dureeAcces = 3;
    }
    if (checkEmailFormat(this.UserSelected.mail)) {
      this.mailUser = this.UserSelected.mail;
    } else {
      this.mailUser = "";
    }
    this.mailInitial = this.UserSelected.mail;
    this.pratiqueInitial = this.UserSelected.typePratique;
    loadingComponent.close();
    this.foldersBrique = [];
    this.folders.forEach((e) => {
      if (e.domaine == "Santé") this.foldersBrique.push(cloneDeep(e));
    });
    const oDomaine = Parse.Object.extend("domaine");
    const queryDomaine = new Parse.Query(oDomaine);
    queryDomaine.equalTo("nom", "Santé");
    const domaineSante = await queryDomaine.first();
    const oTheme = Parse.Object.extend("theme");
    const queryTheme = new Parse.Query(oTheme);
    queryTheme.equalTo("domaine", domaineSante);
    queryTheme.ascending("ordre");
    this.themes = await queryTheme.find();
    const queryUser = new Parse.Query(Parse.User);
    queryUser.equalTo("objectId", this.UserSelected.objectId);
    const ResponseUser = await queryUser.first();
    const RelationLinkFromUser = ResponseUser.relation("link");
    let QueryLinkFromRelation = RelationLinkFromUser.query();
    QueryLinkFromRelation.limit(1000);
    const ResponseLinkFromRelation = await QueryLinkFromRelation.find();
    await asyncForEach(this.foldersBrique, async (folderBrique) => {
      var buttonExpert = true;
      if (
        ResponseLinkFromRelation.find((e) => {
          var id = folderBrique.link.objectId
            ? folderBrique.link.objectId
            : folderBrique.link.id;
          return id === e.id;
        })
      ) {
        folderBrique.deverrouille = true;
        await asyncForEach(folderBrique.protocoles, async (folderProtocole) => {
          if (
            ResponseLinkFromRelation.find((e) => {
              var id = folderProtocole.link.objectId
                ? folderProtocole.link.objectId
                : folderProtocole.link.id;
              return id === e.id;
            })
          ) {
            folderProtocole.deverrouille = true;
            if (folderProtocole.name === "Entraînement libre") {
              folderProtocole.buttonExpert = buttonExpert;
            }
          } else {
            if (folderProtocole.name.includes("expert")) {
              buttonExpert = false;
            }
            folderProtocole.deverrouille = false;
            if (folderProtocole.name === "Entraînement libre") {
              folderProtocole.buttonDecouverte = false;
              folderProtocole.buttonExpert = false;
            }
          }
        });
      } else {
        folderBrique.deverrouille = false;
        await asyncForEach(folderBrique.protocoles, async (folderProtocole) => {
          if (
            ResponseLinkFromRelation.find((e) => {
              var id = folderBrique.link.objectId
                ? folderBrique.link.objectId
                : folderBrique.link.id;
              return id === e.id;
            })
          ) {
            folderProtocole.deverrouille = true;
            if (folderProtocole.name === "Entraînement libre") {
              folderProtocole.buttonExpert = buttonExpert;
            }
          } else {
            if (folderProtocole.name.includes("expert")) {
              buttonExpert = false;
            }

            folderProtocole.deverrouille = false;
            if (folderProtocole.name === "Entraînement libre") {
              folderProtocole.buttonDecouverte = false;
              folderProtocole.buttonExpert = false;
            }
          }
        });
      }
    });
  },
  watch: {
    "UserSelected.dureeAcces": function (newVal, oldVal, initialSelectValue) {
      initialSelectValue = this.initialSelectValue;
      if (newVal !== initialSelectValue) {
        this.newDureeAcces = true;
      } else {
        this.newDureeAcces = false;
      }
    },

    versLicence() {
      this.UserSelected.typePratique = "licenceEdit";
      this.versLicence = false;
    },
    mailUser() {
      this.UserSelected.mail = this.mailUser;
      this.errorSamePassword = false;
      this.errorMajuscule = false;
      const regex = /[A-Z]/gm;
      const str = this.UserSelected.mail;
      let m;

      while ((m = regex.exec(str)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        // eslint-disable-next-line no-unused-vars
        m.forEach((match, groupIndex) => {
          this.errorMajuscule = true;
        });
      }
    },
  },
};
</script>

<style></style>
