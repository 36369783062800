var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-content"},[(_vm.isLoading)?_c('div',{staticClass:"message-container"},[_vm._v("Chargement en cours...")]):_c('div',{staticClass:"message-container"},[_c('div',{staticClass:"close-button",on:{"click":_vm.closePopup}},[_vm._v("✖")]),_c('div',{staticClass:"user-name"},[(_vm.isLoading)?[_vm._v("Chargement en cours...")]:(!_vm.isTherapeute)?[_vm._v(_vm._s(_vm.TherapeuteInfo.name))]:[_vm._v(_vm._s(_vm.selectedUser.attributes.lastname + " " + _vm.selectedUser.attributes.firstname))]],2),(
        _vm.TherapeuteInfo.isConnectedRecently && !_vm.selectedUser ||
        (_vm.selectedUser && _vm.selectedUser.isConnected)
      )?_c('div',{staticClass:"online"}):_vm._e(),_c('div',{staticClass:"messages",attrs:{"id":"messageContainer"}},[(_vm.selectedUser && !_vm.selectedUser.isConnected)?_c('span',{staticClass:"offline-message"},[_vm._v(" (Cet utilisateur n'est pas en ligne pour le moment, mais votre message lui sera bien envoyé et il recevra également un e-mail le notifiant de votre message.) ")]):_vm._e(),_vm._l((_vm.MessagesList),function(message,index){return _c('div',{key:index,class:{
          'user-message': message.isUserMessage,
          'other-message': !message.isUserMessage,
        }},[_vm._v(" "+_vm._s(message.text)+" "),(index === _vm.MessagesList.length - 1 && message.seen)?_c('span',{staticClass:"seen-status"},[_c('i',{staticClass:"fas fa-eye"}),_vm._v(" Vu ")]):_vm._e()])}),_c('div',{attrs:{"id":"lastMsg"}})],2),_c('div',{staticClass:"input-bar"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.newMessage),expression:"newMessage"}],attrs:{"placeholder":"Tapez votre message..."},domProps:{"value":(_vm.newMessage)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessageMethod.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.newMessage=$event.target.value}}}),_c('button',{on:{"click":_vm.sendMessageMethod}},[_vm._v("Envoyer")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }