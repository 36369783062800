import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
export async function unlockFolder(props, type_abonnement, isNotUser) {
  // Si c'est une autre brique que Cohérence Cardiaque (car cas particulier)
  ParseConfig();

  var queryTheme = new Parse.Query("theme");
  queryTheme.equalTo("objectId", props.brique.themeId);
  var monTheme = await queryTheme.first();

  const abonnements = {
    1: "gratuit",
    2: "play",
    3: "pulse",
  };

  function getAccesWithAbonnement(type_abonnement) {
    if (type_abonnement === abonnements[1]) {
      return 1;
    }
    if (type_abonnement === abonnements[2]) {
      return 2;
    }
    if (type_abonnement === abonnements[3]) {
      return 3;
    }
  }

  let casSimple =
    monTheme.attributes.ordre !== 4 && monTheme.attributes.ordre !== 7;

  if (casSimple) {
    if (props && !props.num && props.brique && props.brique.deverrouille) {
      props.brique.deverrouille = false;
      props.brique.protocoles[0].deverrouille = false;
      props.brique.protocoles[1].deverrouille = false;
      props.brique.protocoles[2].deverrouille = false;
      if (!props.brique.protocoles[3]) return;
      props.brique.protocoles[3].buttonDecouverte = false;
      props.brique.protocoles[3].buttonExpert = false;
      props.brique.protocoles[3].deverrouille = false;
      return;
    } else if (!props.brique.deverrouille) {
      props.brique.deverrouille = true;
      props.brique.protocoles[0].deverrouille = true;
      if (
        props.num &&
        (props.brique.protocoles[props.num].deverrouille_user || isNotUser)
      ) {
        props.brique.protocoles[props.num].deverrouille = true;
      } else {
        if (props.brique.protocoles[1].deverrouille_user || isNotUser)
          props.brique.protocoles[1].deverrouille = true;
        if (props.brique.protocoles[2].deverrouille_user || isNotUser)
          props.brique.protocoles[2].deverrouille =
            type_abonnement === "gratuit" ? false : true;
        if (
          (props.brique.protocoles[3] &&
            props.brique.protocoles[3].deverrouille_user) ||
          isNotUser
        )
          props.brique.protocoles[3].buttonDecouverte =
            type_abonnement === "gratuit" ? false : true;
        if (
          (props.brique.protocoles[3] &&
            props.brique.protocoles[3].deverrouille_user) ||
          isNotUser
        )
          props.brique.protocoles[3].buttonExpert =
            type_abonnement === "gratuit" ? false : true;
        if (
          (props.brique.protocoles[3] &&
            props.brique.protocoles[3].deverrouille_user) ||
          isNotUser
        )
          props.brique.protocoles[3].deverrouille = true;
      }

      return;
    }

    if (type_abonnement === "gratuit" && props.num === 1) {
      props.brique.protocoles[props.num].deverrouille = false;
    } else {
      if (
        props.brique.protocoles[props.num].name === "Résultats" ||
        props.brique.protocoles[props.num].name === "protocole 3 vide"
      ) {
        props.brique.protocoles[3].deverrouille =
          !props.brique.protocoles[3].deverrouille;
      } else {
        props.brique.protocoles[props.num].deverrouille =
          !props.brique.protocoles[props.num].deverrouille;
      }
    }

    if (props.num === 4) {
      props.brique.protocoles[3].buttonExpert = false;
      props.brique.protocoles[3].buttonDecouverte = false;
    }
    //console.log(props.brique.protocoles[3]);
    if (
      !props.brique.protocoles[1].deverrouille &&
      !props.brique.protocoles[2].deverrouille &&
      ((props.brique.protocoles[3] &&
        !props.brique.protocoles[3].deverrouille) ||
        (props.brique.protocoles[3] &&
          props.brique.protocoles[3].name == "Résultats"))
    ) {
      props.brique.deverrouille = false;
      props.brique.protocoles[1].deverrouille = false;
      props.brique.protocoles[2].deverrouille = false;
      props.brique.protocoles[3].buttonDecouverte = false;
      props.brique.protocoles[3].buttonExpert = false;
      props.brique.protocoles[3].deverrouille = false;
      return;
    }

    if (
      !props.brique.protocoles[1].deverrouille &&
      !props.brique.protocoles[2].deverrouille &&
      !props.brique.protocoles[3]
    ) {
      props.brique.deverrouille = false;
      props.brique.protocoles[1].deverrouille = false;
      props.brique.protocoles[2].deverrouille = false;
      return;
    }

    if (props.brique.protocoles[3] && props.brique.protocoles[3].deverrouille) {
      props.brique.protocoles[3].buttonDecouverte = true;
      props.brique.protocoles[3].buttonExpert = true;
    } else if (
      props.brique.protocoles[3] &&
      !props.brique.protocoles[3].deverrouille
    ) {
      props.brique.protocoles[3].buttonDecouverte = false;
      props.brique.protocoles[3].buttonExpert = false;
    }
  }
  // Si c'est une brique Cohérence Cardiaque (car cas particulier)
  else if (monTheme.attributes.ordre === 4) {
    if (props && !props.num && props.brique && props.brique.deverrouille) {
      props.brique.deverrouille = false;
      props.brique.protocoles[0].deverrouille = false;
      props.brique.protocoles[1].deverrouille = false;
      props.brique.protocoles[2].buttonDecouverte = false;
      props.brique.protocoles[2].buttonExpert = false;
      props.brique.protocoles[2].deverrouille = false;
      return;
    } else if (!props.brique.deverrouille) {
      props.brique.deverrouille = true;
      props.brique.protocoles[0].deverrouille = true;
      if (props.brique.protocoles[1].deverrouille_user || isNotUser)
        props.brique.protocoles[1].deverrouille = true;
      if (props.brique.protocoles[2].deverrouille_user || isNotUser)
        props.brique.protocoles[2].buttonDecouverte =
          type_abonnement === "pulse" ? true : false;
      if (props.brique.protocoles[2].deverrouille_user || isNotUser)
        props.brique.protocoles[2].buttonExpert =
          type_abonnement === "pulse" ? true : false;
      if (props.brique.protocoles[2].deverrouille_user || isNotUser)
        props.brique.protocoles[2].deverrouille =
          type_abonnement === "pulse" ? true : false;
      return;
    }

    props.brique.protocoles[props.num].deverrouille =
      !props.brique.protocoles[props.num].deverrouille;

    if (props.num === 3) {
      props.brique.protocoles[2].buttonExpert = false;
      props.brique.protocoles[2].buttonDecouverte = false;
    }
    if (!props.brique.protocoles[1].deverrouille) {
      props.brique.deverrouille = false;
      props.brique.protocoles[1].deverrouille = false;
      props.brique.protocoles[2].buttonDecouverte = false;
      props.brique.protocoles[2].buttonExpert = false;
      props.brique.protocoles[2].deverrouille = false;
      return;
    }

    if (
      props.brique.protocoles[2].deverrouille &&
      props.brique.protocoles[1].deverrouille
    ) {
      props.brique.protocoles[2].buttonDecouverte = true;
      props.brique.protocoles[2].buttonExpert = true;
    } else {
      props.brique.protocoles[2].buttonDecouverte = false;
      props.brique.protocoles[2].buttonExpert = false;
    }

    if (type_abonnement !== "pulse") {
      props.brique.protocoles[2].deverrouille = false;
      props.brique.protocoles[2].buttonDecouverte = false;
      props.brique.protocoles[2].buttonExpert = false;
    }
  } else if (monTheme.attributes.ordre === 7) {
    if (props.num == undefined) {
      props.brique.deverrouille = !props.brique.deverrouille;
      for (var i = 0; i < props.brique.protocoles.length; i = i + 1) {
        if (
          getAccesWithAbonnement(
            props.brique.protocoles[i].link.type_abonnement
          ) > getAccesWithAbonnement(type_abonnement)
        )
          return;
        props.brique.protocoles[i].deverrouille = props.brique.deverrouille;
        for (
          var j = 0;
          j < props.brique.protocoles[i].modules.length;
          j = j + 1
        ) {
          if (
            getAccesWithAbonnement(
              props.brique.protocoles[i].modules[j].link.type_abonnement
            ) > getAccesWithAbonnement(type_abonnement)
          )
            break;
          props.brique.protocoles[i].modules[j].deverrouille =
            props.brique.deverrouille;
        }
      }
      return;
    }

    for (
      i = 0;
      i < props.brique.protocoles[props.num].modules.length;
      i = i + 1
    ) {
      if (
        props.brique.protocoles[props.num].modules[i].id === props.module.id
      ) {
        if (!props.brique.protocoles[props.num].modules[i].deverrouille) {
          props.brique.deverrouille =
            !props.brique.protocoles[props.num].modules[i].deverrouille;
        }
        props.brique.protocoles[props.num].modules[i].deverrouille =
          !props.brique.protocoles[props.num].modules[i].deverrouille;
      }
      if (
        props.brique.protocoles[props.num].modules[i].deverrouille === undefined
      )
        props.brique.protocoles[props.num].modules[i].deverrouille = false;
    }
    var listExoDeverouille = props.brique.protocoles[props.num].modules.filter(
      (e) => e.deverrouille !== undefined && e.deverrouille === true
    );

    if (listExoDeverouille.length > 0)
      props.brique.protocoles[props.num].deverrouille = true;
    else {
      props.brique.protocoles[props.num].deverrouille = false;
    }
  }
  // Si c'est un questionnaire
  else {
    if (
      props &&
      !props.num &&
      props.num !== 0 &&
      props.brique &&
      props.brique.deverrouille
    ) {
      props.brique.deverrouille = false;
      props.brique.protocoles[1].deverrouille =
        type_abonnement === "gratuit" ? false : true;
      for (var g = 0; g < props.brique.protocoles.length; g++) {
        props.brique.protocoles[g].deverrouille = false;
      }
      return;
    } else if (!props.brique.deverrouille) {
      props.brique.deverrouille = true;
      for (var k = 0; k < props.brique.protocoles.length; k++) {
        if (props.brique.protocoles[k].deverrouille_user || isNotUser) {
          props.brique.protocoles[k].deverrouille = true;
          if (props.brique.protocoles[1]) {
            props.brique.protocoles[1].deverrouille =
              type_abonnement === "gratuit" ? false : true;
          }
        }
      }
      return;
    }

    props.brique.protocoles[props.num].deverrouille =
      !props.brique.protocoles[props.num].deverrouille;

    var count = 0;
    for (j = 0; j < props.brique.protocoles.length; j++) {
      count = count + props.brique.protocoles[j].deverrouille;
    }
    if (!count && props && !props.num && props.num !== 0) {
      props.brique.deverrouille = false;
    }
  }
  return;
}
