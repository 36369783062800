<template lang="html">
  <div>
    <div class="card" style="background-color: white">
      <header class="card-header card-header-cyan">
        <p class="has-text-white is-size-4">
          {{ $t("offrePlay") }}
        </p>
      </header>
      <div class="columns is-multiline">
        <div
          class="card-image column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen is-text-centered"
          style="width: 100%"
        >
          <figure class="image is-2by1">
            <!--img
              src="https://bulma.io/images/placeholders/720x240.png"
              alt="PImage abonnement play"
            /-->
            <img
              src="../../../../assets/img/img_abonnement_play.png"
              alt="Image abonnement play"
            />
          </figure>
        </div>
        <div class="column is-12 columns is-multiline" v-if="misAJour">
          <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen has-text-centered"
            v-if="group.type_abonnement !== 'play'"
          >
            <a style="padding: 4rem;"
              class="button is-cyan is-large button-offre-abonnement"
              :class="{ isGrey: !abonnement.engagementAnnuel }"
              @click="setEngagement(true)"
            >
              <div class="columns is-multiline">
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-6">{{ $t("avecEngagementAnnuel") }}</p>
                </div>
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-4">
                    {{ (this.playAnnuel / 12).toFixed(2) }}€ /
                    {{ $t("mois") }} ttc
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen has-text-centered"
            v-if="group.type_abonnement !== 'play'"
          >
            <a style="padding: 4rem;"
              class="button is-cyan is-large button-offre-abonnement"
              :class="{ isGrey: abonnement.engagementAnnuel }"
              @click="
                setEngagement(false);
                abonnement.cartesPostales = false;
              "
            >
              <div class="columns is-multiline">
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-6">{{ $t("sansEngagement") }}</p>
                </div>
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-4">
                    {{ this.playMensuel.toFixed(2) }}€ / {{ $t("mois") }} ttc
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-7-desktop is-8-widescreen"
          >
            <div class="informations is-size-6">
              <!--<div v-if="
                group.type_abonnement === 'play' &&
                !modificationAbonnement &&
                !group.engagement_annuel
              " class="has-text-centered">
                <p>
                  {{ $t("evolutionAbonnement") }}
                </p>
                <br />
                <div class="columns">
                  <div class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen column-mon-abonnement">
                    <p class="is-size-6">{{ $t("votreAbonnement") }}</p>
                    <br />
                    <p class="is-size-6">{{ $t("tarifMensuelDe") }}</p>
                    <p class="is-size-6">
                      {{ this.playMensuel.toFixed(2) }}€ ttc
                    </p>
                  </div>
                  <div class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen">
                    <p class="is-size-6">
                      {{ $t("abonnementAvecEngagement") }}
                    </p>
                    <br />
                    <p class="is-size-6">{{ $t("tarifMensuelDe") }}</p>
                    <p class="is-size-6">
                      {{ (this.playAnnuel / 12).toFixed(2) }}€ ttc
                    </p>
                    <p>
                      {{ $t("prelevementAnnuelDe") }}
                      {{ this.playAnnuel.toFixed(2) }}€ ttc.
                    </p>
                    <p>{{ $t("debiteEn") }} 1 {{ $t("fois") }}.</p>
                    <p class="is-size-6 has-text-success">
                      {{ $t("economieAnnuelle") }} : {{ this.playEconomie }}
                    </p>
                  </div>
                </div>
                <a class="button is-primary" @click="editInformationAbonnement()">
                  {{ $t("passageAbonnementAnnuel") }}
                </a>
              </div>-->
              <p
                v-if="
                  group.type_abonnement === 'play' && modificationAbonnement
                "
              >
                {{ $t("modificationAbonnementConditionGeneral") }}
              </p>
              <div
                class="field"
                v-if="
                  group.type_abonnement !== 'play' || modificationAbonnement
                "
              >
                <b-checkbox
                  v-model="abonnement.cartesPostales"
                  v-if="abonnement.engagementAnnuel"
                  @input="
                    checkValue();
                    contratAccepte = false;
                  "
                >
                  {{ $t("recevoirCartesPostales") }}
                </b-checkbox>
              </div>
              <div
                class="field"
                v-if="
                  abonnement.cartesPostales &&
                  (group.type_abonnement !== 'play' || modificationAbonnement)
                "
              >
                <!-- <b-checkbox
                  v-model="adresseLivraisonDifferente"
                  @input="
                    checkValue();
                    contratAccepte = false;
                  "
                >
                  {{ $t("adresseLivraisonDifferente") }}
                </b-checkbox> -->
              </div>
              <span
                class="has-text-danger"
                v-if="
                  errorAdresseUtilisateurUndefined &&
                  abonnement.cartesPostales &&
                  (group.type_abonnement !== 'play' || modificationAbonnement)
                "
              >
                {{ $t("adresseFacturationNonRenseigne") }}
              </span>
              <div
                v-if="
                  ((abonnement.cartesPostales ||
                    ((group.type_abonnement === 'play' ||
                      modificationAbonnement) &&
                      group.cartes_postales)) &&
                    adresseLivraisonDifferente) ||
                  !userHasBillingAdress
                "
              ></div>
              <formulaire-paiement-component
                v-if="check && abonnement.adresse.codePostal"
                :transactionData2="transactionData"
                :code_societe="code_societe"
                :amount="this.transactionData.amount"
                :page="'abonnement'"
                @confirmerAbonnement="confirmerAbonnement"
              />
              <span
                class="has-text-danger"
                v-if="check && !abonnement.adresse.codePostal"
              >
                Pour passer au paiement, veuillez remplir votre adresse.
              </span>
              <b-field
                v-if="
                  group.type_abonnement !== 'play' || modificationAbonnement
                "
                :type="{ 'is-danger': !contratAccepte && errorContratAccepte }"
                :message="{
                  'Veuillez accepter le contrat afin de procéder au paiement.':
                    !contratAccepte && errorContratAccepte,
                }"
              >
                <b-checkbox v-model="contratAccepte" @input="checkValue()">
                  {{ $t("cgu") }} (<a
                    href="https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgv") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgu") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                    target="_blank"
                  >
                    {{ $t("cgu2_rgpd") }}</a
                  >)
                </b-checkbox>
              </b-field>
            </div>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen"
          >
            <div class="box has-text-centered box-information box-offre">
              <p class="has-text-weight-normal is-size-3">
                {{ $t("offrePlay") }}
              </p>
              <p class="is-size-6 is-margin-b-50">{{ $t("avantagePlay1") }}</p>
              <a
                v-if="
                  group.type_abonnement === 'play' && !modificationAbonnement
                "
                class="button is-danger buttonRadius"
                @click="isCardResiliationOpen = true"
              >
                {{ $t("resilier") }}
              </a>
            </div>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-margin-b-20"
          >
            <a
              class="button is-light is-margin-l-20"
              @click="
                modificationAbonnement = false;
                $emit('retour');
              "
            >
              {{ $t("retour") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardResiliationOpen"
      @close="
        (isCardResiliationOpen = false),
          (raisonResiliation = raisonResiliationDefault)
      "
    >
      <div class="card card-resiliation" v-if="raisonResiliation">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              {{ $t("demandeResiliation") }}
            </h3>
            <p>
              {{ $t("motivationResiliation") }}
            </p>
            <b-checkbox
              v-model="raisonResiliation.probleme_technique"
              native-value="Problèmes techniques"
            >
              {{ $t("resiliationRaison1") }}
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="raisonResiliation.inutilise"
              native-value="Inutilisé"
            >
              {{ $t("resiliationRaison2") }}
            </b-checkbox>
            <br />
            <b-checkbox v-model="raisonResiliation.cout" native-value="Coût">
              {{ $t("resiliationRaison3") }}
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="raisonResiliation.complexe"
              native-value="Complexe"
            >
              {{ $t("resiliationRaison4") }}
            </b-checkbox>
            <br />
            <b-checkbox v-model="raisonResiliation.autre" native-value="Autres">
              {{ $t("resiliationRaison5") }}
            </b-checkbox>
            <textarea
              v-if="raisonResiliation.autre"
              v-model="raisonResiliation.texte"
              class="textarea"
              rows="2"
            ></textarea>
            <br />
            <br />
            <p>{{ $t("confirmationAnnualationAbonnement") }}</p>
            <div class="is-margin-b-50">
              <a
                class="button is-light is-pulled-left"
                @click="
                  (isCardResiliationOpen = false),
                    (raisonResiliation = raisonResiliationDefault)
                "
              >
                {{ $t("conserverOffre") }}
              </a>
              <a
                v-if="!validerResiliation"
                class="button is-cyan is-pulled-right"
                @click="resilier()"
              >
                {{ $t("confirmer") }}
              </a>
              <formulaire-resiliation-component
                v-else
                :resiliationData="resiliationData"
                @resilier="resilier()"
              />
            </div>
            <b-checkbox class="is-margin-t-20" v-model="validerResiliation">
              {{ $t("informationResiliation") }}
            </b-checkbox>
            <p class="has-text-justified">
              {{ $t("informationResiliation2") }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
//import jsPDF from "jspdf";
import { createNamespacedHelpers } from "vuex";
import AdresseLivraison from "@/components/general/AdresseLivraison.vue";
import { checkAddressInformation } from "@/services/general/checkAddressInformation.js";
import { editUserGroupAbonnement } from "@/services/userGroup/editUserGroupAbonnement.js";
import moment from "moment";
import { responseQuestionnaryResiliation } from "@/services/userGroup/responseQuestionnaryResiliation.js";
import FormulairePaiement from "../general/FormulairePaiement.vue";
import FormulaireResiliation from "../general/FormulaireResiliation.vue";
import codeGenerator from "@/services/general/codeGenerator.js";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import getDateRenouvellement from "@/services/abonnement/getDateRenouvellement.js";
import { CONFIG_PAIEMENT } from "@/configuration/paiementConf.js";
import asyncForEach from "@/services/general/asyncForEach.js";

const User = createNamespacedHelpers("user");

export default {
  name: "offre-play",
  components: {
    AdresseLivraisonComponent: AdresseLivraison,
    FormulairePaiementComponent: FormulairePaiement,
    FormulaireResiliationComponent: FormulaireResiliation,
  },
  data() {
    return {
      userHasBillingAdress: false,
      code_societe: CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL,
      amount: null,
      showTransactionData: false,
      transactionData: null,
      abonnement: {},
      misAJour: true,
      error: false,
      contratAccepte: false,
      errorContratAccepte: false,
      isCardResiliationOpen: false,
      raisonResiliation: null,
      raisonResiliationDefault: {
        complexe: false,
        autre: false,
        inutilise: false,
        problemes_techniques: false,
        cout: false,
        texte: null,
      },
      validerResiliation: false,
      errorValiderResiliation: false,
      adresseLivraisonDifferente: false,
      errorAdresseUtilisateurUndefined: false,
      check: false,
      datePaiement: null,
      resiliationData: null,
      modificationAbonnement: false,
      playMensuel: 0,
      playAnnuel: 0,
      playEconomie: "",
    };
  },
  computed: {
    ...User.mapGetters(["group", "user", "abonnementTemp"]),
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsEditAbonnementTemp: "editAbonnementTemp",
    }),
    updateAdresse(e) {
      this.misAJour = false;
      this.abonnement.adresse = e;
      this.misAJour = true;
      this.contratAccepte = false;
      this.checkValue();
    },
    async confirmerAbonnement() {
      if (!this.contratAccepte) {
        this.errorContratAccepte = true;
        return;
      }
      if (
        this.abonnement.cartesPostales &&
        !this.user.adresse &&
        !this.adresseLivraisonDifferente
      ) {
        this.errorAdresseUtilisateurUndefined = true;
        return;
      }
      const loadingComponent = this.$buefy.loading.open();
      this.error = false;
      this.errorAdresseUtilisateurUndefined = false;
      if (
        this.abonnement.cartesPostales &&
        this.abonnement.engagementAnnuel &&
        !checkAddressInformation(this.abonnement.adresse)
      ) {
        this.error = true;
        loadingComponent.close();
        return;
      }
      this.actionsEditAbonnementTemp({
        abonnement: this.abonnement,
        transactionData: this.transactionData,
      });
      loadingComponent.close();
    },
    async modifierAbonnement() {
      const loadingComponent = this.$buefy.loading.open();
      this.error = false;
      if (!checkAddressInformation(this.abonnement.adresse)) {
        this.error = true;
        loadingComponent.close();
        return;
      }
      await editUserGroupAbonnement(this.abonnement, this.group.objectId);
      this.actionsUpdateUserGroup();
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Modification de l'abonnement effectué avec succès.",
        type: "is-success",
      });
      this.$emit("retour");
    },
    async resilier() {
      ParseConfig();
      const loadingComponent = this.$buefy.loading.open();
      this.errorValiderResiliation = false;
      if (!this.validerResiliation) {
        this.errorValiderResiliation = true;
        loadingComponent.close();
        return;
      }
      try {
        await responseQuestionnaryResiliation(
          this.raisonResiliation,
          this.group
        );
        this.actionsUpdateUserGroup();
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 5000,
          message: "Résiliation de l'abonnement effectué avec succès.",
          type: "is-success",
        });
        const dateResiliation = getDateRenouvellement(this.group);
        this.isCardResiliationOpen = false;
        Parse.Cloud.run("confirmationResiliationAbonnementPro", {
          destinataire: this.user.username,
          offre: this.group.type_abonnement,
          duree: this.group.engagement_annuel ? "annuel" : "trimestriel",
          date_fin_acces: moment(new Date(dateResiliation)).format(
            "DD/MM/YYYY"
          ),
        });
      } catch {
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Erreur rencontrée.",
          type: "is-danger",
        });
      }
      this.$emit("retour");
    },
    editTransactionData(value) {
      this.showTransactionData = false;
      this.transactionData.engagementAnnuel = value;
      if (this.transactionData.engagementAnnuel) {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-engagement";
        this.transactionData.amount = this.playAnnuel.toFixed(2);
      } else {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-sansengagement";
        this.transactionData.amount = this.playMensuel.toFixed(2);
      }
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL;
      this.showTransactionData = true;
      this.generationFacture();
    },
    setEngagement(value) {
      this.check = false;
      this.abonnement.engagementAnnuel = value;
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL;
      this.editTransactionData(value);
      if (this.contratAccepte) {
        this.check = true;
      }
    },
    async editInformationAbonnement() {
      this.modificationAbonnement = true;
      this.setEngagement(true);
    },
    checkValue() {
      this.check = false;
      this.error = false;
      this.errorAdresseUtilisateurUndefined = false;
      if (!this.contratAccepte) {
        this.errorContratAccepte = true;
        return;
      }
      if (
        this.abonnement.cartesPostales &&
        !this.user.adresse &&
        !this.adresseLivraisonDifferente
      ) {
        this.errorAdresseUtilisateurUndefined = true;
        return;
      }
      if (
        this.abonnement.cartesPostales &&
        this.abonnement.engagementAnnuel &&
        !checkAddressInformation(this.abonnement.adresse)
      ) {
        this.error = true;
        return;
      }
      this.editTransactionData(this.abonnement.engagementAnnuel);
      this.check = true;
      this.datePaiement = new Date();
    },
    async generationFacture() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;
      ParseConfig();
      var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryGroup.equalTo("objectId", this.group.objectId);
      var ResponseGroup = await QueryGroup.first();
      var QueryFacture = new Parse.Query(Parse.Object.extend("facture"));
      QueryFacture.equalTo("type_abonnement", "modele"); // Permet de récupérer l'image de fond de la facture
      //var ResponseFacture = await QueryFacture.first();
      var transactionData = this.transactionData;
      //var group = this.group;
      var QueryFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryFactures.equalTo("user", await Parse.User.current());
      var ResponseFactures = await QueryFactures.find();

      await asyncForEach(ResponseFactures, async (facture) => {
        if (!facture.attributes.valide) {
          await facture.destroy();
        }
      });

      var init_ref_facture =
        "SC" +
        yyyy.toString().charAt(2) +
        yyyy.toString().charAt(3) +
        "" +
        mm +
        "";
      var QueryAllFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryAllFactures.startsWith("reference_facture", init_ref_facture);
      var ResponseAllFactures = await QueryAllFactures.find();

      var initReFactureOk = false;
      var number = ResponseAllFactures.length + 1;
      var finalRef = null;
      while (!initReFactureOk) {
        if (number <= 9) {
          finalRef = init_ref_facture + "000" + number.toString();
        } else if (number <= 99) {
          finalRef = init_ref_facture + "00" + number.toString();
        } else if (number <= 999) {
          finalRef = init_ref_facture + "0" + number.toString();
        } else {
          finalRef = init_ref_facture + number.toString();
        }
        var QueryFactureByRefFacture = new Parse.Query(
          Parse.Object.extend("facture")
        );
        QueryFactureByRefFacture.equalTo("reference_facture", finalRef);
        var ResponseFactureByRefFacture = await QueryFactureByRefFacture.find();
        if (!ResponseFactureByRefFacture.length) {
          initReFactureOk = true;
        } else {
          number++;
        }
      }
      let duration = null;
      let dateRenouvellement = null;
      if (transactionData.engagementAnnuel) duration = "years";
      else duration = "trimesters";
      dateRenouvellement = new Date(moment().add(1, duration));
      while (dateRenouvellement < new Date()) {
        dateRenouvellement = new Date(
          moment(dateRenouvellement).add(1, duration)
        );
      }
      var ObjectFacture = Parse.Object.extend("facture");
      var Facture = new ObjectFacture();
      var monUser = await Parse.User.current();
      Facture.set("type_abonnement", "play");
      Facture.set("avec_engagement", transactionData.engagementAnnuel);
      Facture.set("user", monUser);

      Facture.set("montant", transactionData.amount);
      Facture.set("credit", 0);
      Facture.set("reference_paiement", transactionData.reference);
      Facture.set("reference_facture", finalRef);
      Facture.set("valide", false);
      Facture.set("group", ResponseGroup);
      await Facture.save();
    },
    toDataURL(url, currentUser, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result, currentUser);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async chargeParametre() {
      var queryAppParam = new Parse.Query(Parse.Object.extend("app_param"));
      var appParam = await queryAppParam.first();
      this.playMensuel = appParam.attributes.playMensuel;
      this.playAnnuel = appParam.attributes.playAnnuel;
      this.playEconomie = appParam.attributes.playEconomie;
    },
  },
  async mounted() {
    await this.chargeParametre();
    this.raisonResiliation = this.raisonResiliationDefault;
    let adresse = {};
    let engagementAnnuel = false;
    let cartesPostales = false;
    this.showTransactionData = false;
    if (this.user.adresse) {
      this.userHasBillingAdress = true;
    }
    if (this.group) {
      if (!this.group.adresse_livraison) {
        adresse.nom = this.user.lastname;
        adresse.prenom = this.user.firstname;
        adresse.adresse = this.user.adresse;
        adresse.complementAdresse = this.user.complement_adresse;
        adresse.ville = this.user.ville;
        adresse.codePostal = this.user.code_postal;
        adresse.codeAcces = null;
      } else {
        adresse.nom = this.group.adresse_livraison_nom;
        adresse.prenom = this.group.adresse_livraison_prenom;
        adresse.adresse = this.group.adresse_livraison;
        adresse.complementAdresse = this.group.adresse_livraison_complement;
        adresse.ville = this.group.adresse_livraison_ville;
        adresse.codePostal = this.group.adresse_livraison_code_postal;
        adresse.codeAcces = this.group.adresse_livraison_code_acces_residence;
      }

      if (this.group.cartes_postales) {
        cartesPostales = this.group.cartes_postales;
      }

      if (
        !this.group.engagement_annuel &&
        this.group.engagement_annuel !== false
      ) {
        engagementAnnuel = true;
      } else {
        engagementAnnuel = this.group.engagement_annuel;
      }

      if (this.group.type_abonnement !== "play") {
        engagementAnnuel = true;
      }
    }
    this.abonnement = {
      typeAbonnement: "play",
      engagementAnnuel: engagementAnnuel,
      cartesPostales: cartesPostales,
      adresse: adresse,
    };
    this.transactionData = {
      email: this.user.email,
      referenceCode:
        this.abonnement.typeAbonnement +
        "_" +
        this.group.objectId +
        "_" +
        codeGenerator("xxxx") +
        "-therapeute",
    };
    //this.editTransactionData(engagementAnnuel);
  },
  watch: {
    async contratAccepte() {
      if (this.contratAccepte) {
        this.confirmerAbonnement();
        this.check = true;
        var queryUser = new Parse.Query("User");
        queryUser.equalTo("objectId", this.user.objectId);
        var monUser = await queryUser.first();

        var linkRelationUser = monUser.relation("link");
        var i = 0;

        for (i = 0; i < linkRelationUser.length; i = i + 1) {
          linkRelationUser[i].destroy();
        }

        await monUser.save({}, { useMasterKey: true });

        const QueryLinks = new Parse.Query(Parse.Object.extend("link"));
        QueryLinks.equalTo("type_abonnement", "gratuit");
        QueryLinks.limit(1000);
        const ResponseLinks = await QueryLinks.find();

        const QueryLinksPlay = new Parse.Query(Parse.Object.extend("link"));
        QueryLinksPlay.equalTo("type_abonnement", "play");
        QueryLinksPlay.limit(1000);
        const ResponseLinksPlay = await QueryLinksPlay.find();

        const RelationLinkFromUser = this.user.relation("link");
        for (i = 0; i < ResponseLinks.length; i = i + 1) {
          RelationLinkFromUser.add(ResponseLinks[i]);
        }

        for (i = 0; i < ResponseLinksPlay.length; i = i + 1) {
          RelationLinkFromUser.add(ResponseLinksPlay[i]);
        }

        await monUser.save({}, { useMasterKey: true });

        const ObjectGroup = Parse.Object.extend("user_group");
        const QueryGroup = new Parse.Query(ObjectGroup);
        QueryGroup.equalTo("objectId", this.group.objectId);
        const ResponseGroup = await QueryGroup.first();
        if (
          this.adresseLivraisonDifferente &&
          this.abonnement.engagementAnnuel
        ) {
          ResponseGroup.set(
            "adresse_livraison_nom",
            this.abonnement.adresse.nom
          );
          ResponseGroup.set(
            "adresse_livraison_prenom",
            this.abonnement.adresse.prenom
          );
          ResponseGroup.set(
            "adresse_livraison",
            this.abonnement.adresse.adresse
          );
          ResponseGroup.set(
            "adresse_livraison_complement",
            this.abonnement.adresse.complementAdresse
          );
          ResponseGroup.set(
            "adresse_livraison_code_postal",
            this.abonnement.adresse.codePostal
          );
          ResponseGroup.set(
            "adresse_livraison_ville",
            this.abonnement.adresse.ville
          );
          ResponseGroup.set(
            "adresse_livraison_code_acces_residence",
            this.abonnement.adresse.codeAcces
          );
        } else {
          ResponseGroup.set("adresse_livraison_nom", this.user.lastname);
          ResponseGroup.set("adresse_livraison_prenom", this.user.firstname);
          ResponseGroup.set("adresse_livraison", this.user.adresse);
          ResponseGroup.set(
            "adresse_livraison_complement",
            this.user.complementAdresse
          );
          ResponseGroup.set(
            "adresse_livraison_code_postal",
            this.user.codePostal
          );
          ResponseGroup.set("adresse_livraison_ville", this.user.ville);
        }
        ResponseGroup.set("cartes_postales", this.abonnement.cartesPostales);
        ResponseGroup.set("subscription_end_date", new Date(2100, 12, 1));
        ResponseGroup.set("essai_gratuit", false);
        await ResponseGroup.save();
      }
    },
    async isCardResiliationOpen() {
      this.resiliationData = {
        reference: this.group.reference_paiement,
        date_commande: moment(new Date(this.group.date_paiement)).format(
          "DD/MM/YYYY"
        ),
        montant_deja_capture: this.group.montant_deja_preleve_therapeute,
        amount: this.group.montant_initial_therapeute,
      };
    },
  },
};
</script>

<style></style>
