<template>
  <div>
    <div v-if="props">
      <div>
        <div style="text-align: center; color: grey; font-size: medium">
          <label>Paramètres respiratoires de l'exercice</label>
        </div>
        <table>
          <tr>
            <td><label>CPM</label></td>
            <td><label>INSPIRATION</label></td>
            <td><label>EXPIRATION</label></td>
          </tr>
        </table>

        <div layout="column">
          <div
            layout="row"
            style="
              width: 33%;
              float: left;
              text-align: center;
              border-right: black;
              border-right-style: solid;
              border-right-width: 1px;
            "
          >
            <div style="font-size: smaller"><label>CPM</label></div>
            <div
              style="font-size: large; background: darkgrey; color: turquoise"
            >
              <label>6</label>
            </div>
            <div><label style="font-size: smaller">Profil</label></div>
            <div>
              <label
                style="font-size: large; background: darkgrey; color: turquoise"
                >TONIQUE</label
              >
            </div>
          </div>
          <div
            layout="row"
            style="
              width: 33%;
              float: left;
              text-align: center;
              border-right: black;
              border-right-style: solid;
              border-right-width: 1px;
            "
          >
            <div style="font-size: smaller"><label>INSPIRATION</label></div>
            <div
              style="font-size: large; background: darkgrey; color: turquoise"
            >
              <label>2.5</label>
            </div>
            <div style="font-size: smaller">
              <label>PAUSE D\'INSPIRATION</label>
            </div>
            <div
              style="font-size: large; background: darkgrey; color: turquoise"
            >
              <label>0</label>
            </div>
          </div>
          <div layout="row" style="text-align: center">
            <div style="font-size: smaller"><label>EXPIRATION</label></div>
            <div
              style="font-size: large; background: darkgrey; color: turquoise"
            >
              <label>2.5</label>
            </div>
            <div style="font-size: smaller">
              <label>PAUSE D\'EXPIRATION </label>
            </div>
            <div
              style="font-size: large; background: darkgrey; color: turquoise"
            >
              <label>0</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "export-pdf-hautdroit",
  props: ["props"],
};
</script>
