<template>
  <div>
    <canvas ref="chartProtocoleLibre" height="200px" width="1000px"></canvas>
    <p class="is-margin-b-20 has-text-centered has-text-weight-semibold">
      {{ $t("datesDepuisPremierExoLibreRealise") }}
    </p>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: ["tabRow"],
  name: "chart-cc",
  methods: {
    loadChart() {
      const mesDonnees = {
        labels: this.tabRow.label,
        datasets: [
          {
            label: "Moyenne cohérence cardiaque",
            data: this.tabRow.data,
            borderColor: this.tabRow.color,
            backgroundColor: this.tabRow.color,
          },
        ],
      };
      var chartProtocoleLibre = this.$refs.chartProtocoleLibre;
      var ctx = chartProtocoleLibre.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: mesDonnees,
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  mounted() {
    this.loadChart();
  },
  watch: {
    defaultOpenedDetails() {
      this.loadChart();
    },
  },
};
</script>
