<template>
  <div class="container">
    <div class="card">
      <div class="logo-container">
        <img
          src="../../../../assets/img/logo_symbiofi.png"
          alt="Symbiocenter"
        />
      </div>
      <div v-if="!unsubscribed">
        <h2>Désactiver la Newsletter</h2>
        <p>
          Voulez-vous vraiment vous désabonner de notre newsletter ? Vous ne
          recevrez plus nos mises à jour et offres exclusives.
        </p>
        <div class="button-group">
          <button class="btn cancel-btn" @click="cancel">Annuler</button>
          <button class="btn unsubscribe-btn" @click="unsubscribe">
            Se désabonner
          </button>
        </div>
      </div>
      <div v-else>
        <h2>Désabonnement effectué</h2>
        <p>
          Vous avez été désabonné de notre newsletter. Vous ne recevrez plus
          nos mises à jour et offres exclusives.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export default {
  data() {
    return {
      user: null,
      userId: null,
      unsubscribed: false,
    };
  },
  methods: {
    cancel() {
      alert("Désabonnement annulé");
    },
    unsubscribe() {
      // this.user.set("newsletter", false);
      this.fetchUserData();

      this.unsubscribed = true;
      console.log(this.user);
    },
    async fetchUserData() {
      try {
        ParseConfig();

        const userId = this.$route.query.idUser;
        this.userId = userId;
        console.log("User ID:", userId);
        // Appeler la fonction Cloud pour désactiver la newsletter
        const result = await Parse.Cloud.run("disableNewsletter", {
          userId: userId,
        });
        console.log(result);
        console.log(result.message);
      } catch (error) {
        console.error("An error occurred while fetching user data:", error);
      }
    },
  },
  created() {},
};
</script>

<style scoped>
/* Container centralisé */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f4;
  padding: 20px;
}

/* Carte avec design modernisé */
.card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
  max-width: 450px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

/* Logo bien intégré */
.logo-container {
  margin-bottom: 20px;
}

.logo-container img {
  max-width: 200px;
}

/* Titre principal */
h2 {
  margin-bottom: 18px;
  font-size: 1.8rem;
  color: #2c3e50;
  font-weight: bold;
}

/* Paragraphe informatif */
p {
  margin-bottom: 28px;
  font-size: 1rem;
  color: #7f8c8d;
  line-height: 1.6;
}

/* Groupement des boutons */
.button-group {
  display: flex;
  justify-content: center;
  gap: 15px;
}

/* Boutons stylisés */
.btn {
  padding: 12px 25px;
  font-size: 1rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.cancel-btn {
  background-color: #bdc3c7;
  color: #2c3e50;
}

.cancel-btn:hover {
  background-color: #95a5a6;
  transform: translateY(-2px);
}

.unsubscribe-btn {
  background-color: #e74c3c;
  color: #ffffff;
}

.unsubscribe-btn:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}
</style>
