<template>
  <div>
    <div  class="relectureDetailsDiv">
      <!-- <span>Relecture de votre exercice effectué le {{ dateCreated }}</span> -->
      <div class="columns is-multiline">
        <div class="column is-16">
          <div class="chart-result">
            <chartAni id="relectureDetailsId"
              :widthSize="widthSize"
              class="chartAni"
              :valeurX="valeurX"
              :tabRowFc="TabRowCC"
              :tabRowCC="TabRowFC"
              :defaultOpenedDetails="DefaultOpenedDetails"
            />
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-4">
          <div class="circleFCMoyenne1">
            <div
              class="circleFCMoyenne4"
              :style="{
                'background-color': couleurCCMoyenne,
              }"
            >
              <p class="circleFCMoyenne3 has-text-weight-bold">
                <span class="is-size-4">
                  {{ ccMoyenne ? ccMoyenne : "0" }}
                </span>
              </p>
            </div>
          </div>
          <p
            class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
          >
            Moyenne de l'indice de lâcher-prise
          </p>
        </div>
        <div class="column is-4">
          <div class="circleFCMoyenne1">
            <div class="circleFCMoyenne2">
              <p class="circleFCMoyenne3 has-text-weight-bold">
                <span class="is-size-4">
                  {{ fcMoyenne ? fcMoyenne : "0" }}
                </span>
                bpm
              </p>
            </div>
          </div>
          <p
            class="has-text-weight-semibold has-text-black has-text-centered is-margin-t-20"
          >
            {{ $t("moyenneFrequenceCardiaque") }}
          </p>
        </div>
        <div class="column is-4">
          <div class="column is-size-4 has-text-centered">
            <div class="card-relecture">
              <p>Durée de l'exercice</p>
              <div>
                <p>
                  <span class="duration">{{
                    formatDuration(timeElapsed)
                  }}</span>
                  <i class="fas fa-clock logoRelecture"></i>
                </p>
              </div>
            </div>
            <!-- <div id="buttonPrint" class="column is-size-4 has-text-centered">
              <button class="buttonImprimer" @click="printPDF">Imprimer</button>
            </div> -->
            
          </div>
        </div>
      </div>
    </div>
    <div v-if="comment" class="column is-16">
      <b-field label="commentaire :">
        <b-input
          @input="emitCommentaire"
          maxlength="200"
          type="textarea"
          v-model="commentaireMoniteur"
        ></b-input>
      </b-field>
    </div>
    <div v-if="comment" class="containerButtonSave">
      <button class="buttonChart contentButtonSave" v-on:click="emitSave()">
        Sauvegarder
      </button>
    </div>
  </div>
</template>

<script>
// import suivieCc from "../tableauDeBord/SuivieCcExercice.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

import Parse from "parse";
import ParseConfig from "@/api/parse.config";
const User = createNamespacedHelpers("user");
import { createNamespacedHelpers } from "vuex";
// import chartFcVue from "../tableauDeBord/chartFcANI.vue";
import chartAni from "../tableauDeBord/chartAni.vue";
export default {
  data() {
    return {
      firstObject: {},
      ouvertureSuiviCcUtilisateur: "",
      fileId: "7rOgxcWOD4",
      protocole: "Entraînement libre",
      userSelected: "tous",
      DisplayRelecture: false,
      commentaireMoniteur: "",
      widthSize: 500,
    };
  },
  props: [
    "valeurX",
    "fcMoyenne",
    "ccMoyenne",
    "couleurCCMoyenne",
    "TabRowFC",
    "DefaultOpenedDetails",
    "ValeurCCX",
    "TabRowCC",
    "labelChrono",
    "timeElapsed",
    "comment",
    "dateCreated",
  ],
  components: {
    // suivieCc,
    // chartFcVue,
    chartAni,
  },
  computed: {
    ...User.mapState(["user"]),
  },
  created() {
    this.commentaireMoniteur = this.comment;
    console.log("Date de création:", this.dateCreated);
  },
  methods: {
    async generatePDF() {
      const element = document.getElementById('relectureDetailsId');
    
    // Options for html2canvas
    const options = {
      scale: 2, // Increase the scale for better quality
      logging: true, // Enable logging
      useCORS: true, // Use CORS for loading images
      width: 2500,
      height: 2500,
    };

    const canvas = await html2canvas(element, options);
    const imgData = canvas.toDataURL('image/png');

    const pdf = new jsPDF({
      orientation: 'portrait', // or 'landscape'
      unit: 'mm',
      format: 'a4'
    });

    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

    pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('download.pdf');
    },
    changeSize() {
      this.widthSize = 300;
    },
    formatDuration(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes}min${remainingSeconds}s`;
    },
    emitSave() {
      this.$emit("save-change", this.commentaireMoniteur);
    },
    emitCommentaire() {
      this.$emit("commentaire-change", this.commentaireMoniteur);
    },
    async printPDF() {
      //récupérer le bouton avec l'id : buttonPrint
      var buttonPrint = document.getElementById("buttonPrint");
      //cacher le bouton
      buttonPrint.style.display = "none";

      const element = document.getElementById("relectureDetailsId");
      const canvas = await html2canvas(element);
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 0, 0);
      pdf.save("download.pdf");

      //afficher le bouton
      buttonPrint.style.display = "block";
    },
  },

  mounted() {
    ParseConfig();
    const objectFolder = Parse.Object.extend("folder");
    const QueryFolders = new Parse.Query(objectFolder);
    QueryFolders.equalTo("objectId", "ALoaWJgV7l");
    QueryFolders.find().then((results) => {
      this.folders = results;
      this.firstObject = this.folders.shift();
      return this.folders, this.firstObject;
    });
  },
};
</script>
<style>
.chartAni {
  max-width: 1400px;
}

.buttonImprimer {
  margin-left: auto;
  margin-right: auto;
  color: white;
  background-color: #e0ab4a !important;
  margin-top: 1%;
  margin-bottom: 1%;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
}

.encadrement {
  margin: 0 auto;
  padding: 8px;
  /* background-color: rgba(0, 0, 0, 0.5); */
}
.containerButtonSave {
  display: flex;
  align-items: center;
  justify-content: center;
}
.contentButtonSave {
  text-align: center;
}
.unit {
  font-size: 0.8em;
  color: #888;
}
.logoRelecture {
  margin-left: 10px;
}
.animated-timer {
  font-size: 3em;
  animation: pulse 1s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
.card-relecture {
  background-color: #f5f5f5;
  border-radius: 5px;
  /* padding: 10px; */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.buttonChart {
  text-decoration: none;
  text-transform: none;
  padding: 10px;
  min-height: 40px;
  border-radius: 20px;
  border: 0 none;
  outline: 0 none;
  -webkit-appearance: button;
  cursor: pointer;
  background-color: rgb(35, 147, 128) !important;
  color: white;
  margin: 7px;
}

.printing .chart-result,
.printing .chart-result svg {
  transform: scale(0.8);
  transform-origin: top left;
}
</style>
