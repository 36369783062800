export default function getHashKey(key) {
  var hexStrkey = key.slice(0, 38),
    hexFinal = "" + key.slice(38) + "00",
    cca0 = hexFinal.charCodeAt(0);
  if (cca0 > 70 && cca0 < 97) {
    hexStrkey += String.fromCharCode(cca0 - 23) + hexFinal.charAt(1);
  } else {
    if (hexFinal.charAt(1) == "M") {
      hexStrkey += hexFinal.charAt(0) + "0";
    } else {
      hexStrkey += hexFinal.slice(0, 2);
    }
  }
  var r = "";
  for (var i = 0; i < hexStrkey.length / 2; i++) {
    var hex = hexStrkey[i * 2] + hexStrkey[i * 2 + 1];
    var hexPack = parseInt(hex, 16);
    r += String.fromCharCode(hexPack);
  }
  return r;
}
