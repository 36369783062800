<template>
  <div>
    <div class="card editUser">
      <div class="card-content">
        <div class="content">
          <div class="overflow">
            <h3 class="title has-text-black is-size-5">
              {{ $t("Introduction de l'activité") }}
            </h3>
            <div v-html="descriptionSelected"></div>
            <div>
              <div class="is-pulled-right">
                <a class="button is-grey" @click="$emit('closeModal')">
                  {{ $t("fermer") }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  name: "informationActivite",
  methods: {},
  props: ["descriptionSelected", "videoSelected"],
  data() {
    return {};
  },
};
</script>
