import Parse from "parse";

export async function getFilesDonesANI(userId) {
  var filesDones = [];
  const queryFileDone = new Parse.Query("file_done");
  const queryUser = new Parse.Query("User");

  queryUser.equalTo("objectId", userId);
  queryFileDone.equalTo("ANI", true);
  queryFileDone.matchesQuery("user", queryUser);
  //inclure les attributes des files
  queryFileDone.include("file");
  queryFileDone.include("module");

  await queryFileDone.find().then((results) => {
    results.forEach((result) => {
      const resultatJeux = JSON.parse(result.attributes.resultatJeux);
      const id = result.id;
      const newResult = { ...result.attributes, resultatJeux, id };
      filesDones.push(newResult);
    });
  });

  return filesDones;
}
