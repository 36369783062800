export default function generateUserGroup(group) {
  const UserGroup = {
    code_affiliation: group.attributes.code_affiliation,
    couleur: group.attributes.couleur,
    createdAt: group.attributes.createdAt,
    description: group.attributes.description,
    logo: group.attributes.logo,
    mode_retrocession: group.attributes.mode_retrocession,
    name: group.attributes.name,
    objectId: group.id,
    parametrage_fait: group.attributes.parametrage_fait,
    texture: group.attributes.texture,
    updatedAt: group.attributes.updatedAt,
    type_abonnement: group.attributes.type_abonnement,
    subscription_end_date: group.attributes.subscription_end_date,
    domains: group.attributes.domains,
    nb_users: group.attributes.nb_users,
    credits: group.attributes.credits,
    compte_valide: group.attributes.compte_valide,
    type_paiement: group.attributes.type_paiement,
    numero_carte: group.attributes.numero_carte,
    cvv: group.attributes.cvv,
    code_postal_cb: group.attributes.code_postal_cb,
    pays: group.attributes.pays,
    numero_tva: group.attributes.numero_tva,
    cartes_postales: group.attributes.cartes_postales,
    engagement_annuel: group.attributes.engagement_annuel,
    nom_prenom: group.attributes.nom_prenom,
    adresse: group.attributes.adresse,
    complement_adresse: group.attributes.complement_adresse,
    ville: group.attributes.ville,
    code_postal: group.attributes.code_postal,
    code_acces_residence: group.attributes.code_acces_residence,
    date_expiration_cb: group.attributes.date_expiration_cb,
    resilie: group.attributes.resilie,
    password_cabinet: group.attributes.password_cabinet,
    reference_paiement: group.attributes.reference_paiement,
    date_paiement: group.attributes.date_paiement,
    montant_initial_therapeute: group.attributes.montant_initial_therapeute,
    montant_deja_preleve_therapeute:
      group.attributes.montant_deja_preleve_therapeute,
    adresse_livraison_prenom: group.attributes.adresse_livraison_prenom,
    adresse_livraison_nom: group.attributes.adresse_livraison_nom,
    adresse_livraison_statut: group.attributes.adresse_livraison_statut,
    adresse_livraison: group.attributes.adresse_livraison,
    adresse_livraison_complement: group.attributes.adresse_livraison_complement,
    adresse_livraison_ville: group.attributes.adresse_livraison_ville,
    adresse_livraison_code_postal:
      group.attributes.adresse_livraison_code_postal,
    adresse_livraison_pays: group.attributes.adresse_livraison_pays,
    adresse_livraison_departement:
      group.attributes.adresse_livraison_departement,
    adresse_livraison_telephone: group.attributes.adresse_livraison_telephone,
    credit: group.attributes.credit,
    essai_gratuit: group.attributes.essai_gratuit,
    type_abonnement_perso: group.attributes.type_abonnement_perso,
    engagement_annuel_perso: group.attributes.engagement_annuel_perso,
    date_paiement_perso: group.attributes.date_paiement_perso,
  };
  return UserGroup;
}
