import Parse from "parse";

export async function verificationToken(token) {
    const query = new Parse.Query("session_detail");
    query.equalTo("token", token);
    const session = await query.first();

    if (session) {
        const user = await session.get("user").fetch();
        if (user) {
            return true;
        }
    }
    
    return false;
}
