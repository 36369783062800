export function checkAddressInformation(adresse) {
  if (
    !adresse.nom ||
    !adresse.prenom ||
    !adresse.adresse ||
    !adresse.ville ||
    !adresse.codePostal
  ) {
    return false;
  }
  return true;
}
