import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export async function addUserToRole(user, roleName) {
  ParseConfig();

  const RoleQuery = new Parse.Query(Parse.Role);
  RoleQuery.equalTo("name", roleName);
  const RoleSubscriber = await RoleQuery.first();
  RoleSubscriber.relation("users").add(user);
  RoleSubscriber.save();
}
