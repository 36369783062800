<template>
  <div class="video-pop-up">
    <div class="navigation">
      <i class="fas fa-arrow-left back-arrow" @click="goBack"></i>
      <div class="close-button" @click="closePopup">&#10006;</div>
    </div>
    <div class="divMeeting">
      <meetingComponent />
    </div>
  </div>
</template>
<script>
import meetingComponent from "../visio/meetingComponent.vue";
import Parse from "parse";
export default {
  components: {
    meetingComponent,
  },
  data() {
    return {
      TherapeuteInfo: null,
    };
  },
  methods: {
    closePopup() {
      this.$emit("close");
    },
    goBack() {
      this.$emit("goBack");
    },
  },
  async created() {
    console.log("Meeting PopUp");
    console.log(this.haveMeetingAvaibility);


  },
  computed: {
    isTherapeute() {
      return Parse.User.current().attributes.therapeute;
    },
    haveMeetingAvaibility() {
      return !!Parse.User.current().attributes.meeting_availabilities;
    },
  },
};
</script>
<style scoped>
.divMeeting::-webkit-scrollbar {
  width: 8px; /* Largeur de la barre de défilement */
  height: 8px; /* Hauteur de la barre de défilement */
}

/* Styliser le fond de la barre de défilement */
.divMeeting::-webkit-scrollbar-track {
  background: #f0f0f0; /* Couleur de fond légèrement différente pour la piste */
  border-radius: 10px; /* Bords arrondis pour la piste */
}

/* Styliser le curseur de la barre de défilement */
.divMeeting::-webkit-scrollbar-thumb {
  background: #489181; /* Couleur principale du thème pour le curseur */
  border-radius: 10px; /* Bords arrondis pour le curseur */
}

/* Ajouter un effet au survol du curseur de la barre de défilement */
.divMeeting::-webkit-scrollbar-thumb:hover {
  background: #366c5a; /* Une teinte plus foncée pour le survol */
}

.divMeeting {
  overflow-y: auto;
  max-height: 600px;
}

.divMeeting {
  margin-top: 40px;
}
.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez la taille au besoin */
  margin-right: 20px; /* Espacement avant le titre ou la bordure de la pop-up */
}

.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez selon vos besoins */
  color: #489181; /* Couleur de l'icône */
  margin-right: 20px; /* Ajustez l'espacement si nécessaire */
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
