<template>
  <div class="is-main-content">
    <div class="is-fullheight accueil container is-fluid">
      <div class="container">
        <h1 class="title is-size-4">
          {{ $t("tableauDeBord.TitrePage") }}
        </h1>
        <p class="has-text-justified is-size-6">
          {{ $t("tableauDeBordInformation1") }}
        </p>
        <app-informations-principales
          :User="User"
          :group="group"
          :utilisateursAffilie="utilisateursAffilie"
          :nbUsersInMyGroupWithActiveAccess="nbUsersInMyGroupWithActiveAccess"
          :affiliation_en_attente="affiliation_en_attente"
        />
        <div>
          <h1 class="title is-size-5">
            {{ $t("connexionDesUtilisateurs") }}
          </h1>
          <div v-if="loadChartConnection">
            <app-connexion-utilisateurs :group="group" :users="utilisateurs" />
          </div>
        </div>
        <!-- <div class="is-margin-b-50">
          <div class="columns is-multline">
            <div class="column is-3">
              <h1 class="title is-size-5">
                {{ $t("dernieresActualitesPubliees") }}
              </h1>
              <app-dernieres-actualites :group="group" />
            </div>
            <div class="column is-3">
              <h1 class="title is-size-5">
                {{ $t("derniersExercicesRealises") }}
              </h1>
              <div v-if="loadFilesDone">
                <ul
                  v-for="fileDone in filesDone.slice(0, 5)"
                  :key="fileDone.id"
                >
                  <li>
                    -
                    {{
                      fileDone.brique == undefined ? "" : fileDone.brique.name
                    }}/{{
                      fileDone.module == undefined ? "" : fileDone.module.name
                    }}/{{
                      fileDone.file == undefined
                        ? ""
                        : fileDone.file.sub_name || fileDone.file.name
                    }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="column is-3">
              <h1 class="title is-size-5">
                {{ $t("dernieresAffiliations") }}
              </h1>
              <app-dernieres-affiliations
                :utilisateursAffilie="utilisateursAffilie"
              />
            </div>
            <div class="column is-3">
              <h1 class="title is-size-5">
                {{ $t("mesSeancesDeGroupes") }}
              </h1>
              <app-seances-groupes
                v-if="sessionsGroup"
                :sessionsGroup="sessionsGroup"
              />
            </div>
          </div>
        </div> -->
        <div class="is-margin-b-50">
          <div class="level" style="width: 1250px">
            <span class="title is-size-5">
              {{ $t("avancementProtocolesParThematiques") }}
            </span>
            <b-dropdown
              v-model="userSelected"
              aria-role="list"
              v-if="utilisateurs && !utilisateursSessionGroup"
              class="is-pulled-right"
            >
              <button class="button" type="button" slot="trigger">
                <template v-if="userSelected === 'tous'">
                  <span>{{ $t("rechercherParUtilisateur") }}</span>
                  <b-icon pack="fas" icon="caret-down"></b-icon>
                </template>
                <template v-else>
                  <span>{{ userSelected.attributes.username }}</span>
                  <b-icon pack="fas" icon="caret-down"></b-icon>
                </template>
              </button>
              <b-dropdown-item value="tous" aria-role="listitem">
                {{ $t("aucunFiltre") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="utilisateur in utilisateurs"
                :key="utilisateur.id"
                :value="utilisateur"
                aria-role="listitem"
              >
                {{ utilisateur.attributes.username }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-model="userSelected"
              aria-role="list"
              v-if="utilisateursSessionGroup"
              class="is-pulled-right"
            >
              <button class="button" type="button" slot="trigger">
                <template v-if="userSelected === 'tous'">
                  <span>{{ $t("rechercherParUtilisateur") }}</span>
                  <b-icon pack="fas" icon="caret-down"></b-icon>
                </template>
                <template v-else>
                  <span>{{ userSelected.attributes.username }}</span>
                  <b-icon pack="fas" icon="caret-down"></b-icon>
                </template>
              </button>
              <b-dropdown-item value="tous" aria-role="listitem">
                {{ $t("aucunFiltre") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="utilisateur in utilisateursSessionGroup"
                :key="utilisateur.id"
                :value="utilisateur"
                aria-role="listitem"
              >
                {{ utilisateur.attributes.username }}
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              v-model="groupSelected"
              aria-role="list"
              v-if="sessionsGroup"
            >
              <button class="button" type="button" slot="trigger">
                <template v-if="groupSelected === 'tous'">
                  <span>{{ $t("rechercherParSeanceGroupe") }}</span>
                  <b-icon pack="fas" icon="caret-down"></b-icon>
                </template>
                <template v-else>
                  <span>{{ groupSelected.attributes.name }}</span>
                  <b-icon pack="fas" icon="caret-down"></b-icon>
                </template>
              </button>
              <b-dropdown-item value="tous" aria-role="listitem">
                {{ $t("aucunFiltre") }}
              </b-dropdown-item>
              <b-dropdown-item
                v-for="sessionGroup in sessionsGroup"
                :key="sessionGroup.id"
                :value="sessionGroup"
                aria-role="listitem"
              >
                {{ sessionGroup.attributes.name }}
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <app-avancement-protocoles
            v-if="loadProtocoles"
            :utilisateurs="utilisateurs"
            :userSelected="userSelected"
            :sessionsGroup="sessionsGroup"
            :groupSelected="groupSelected"
            :utilisateursSessionGroup="utilisateursSessionGroup"
            :folders="folders"
            :userParticulier="ouvertureSuiviCcUtilisateur"
          />
        </div>
        <div>
          <h1 class="title is-size-5">
            {{ $t("exercicesPlusRealises") }}
          </h1>
          <app-exercices-realises
            v-if="filesDone.length > 0"
            :filesDone="filesDone"
          />
          <p v-else>{{ $t("aucunExerciceRealise") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import asyncForEach from "@/services/general/asyncForEach.js";
import { sortInvArrayByParam } from "@/services/general/sortArrayByParam.js";
import { getObjectById } from "@/services/parse/getObject.js";
import ConnexionUtilisateurs from "./tableauDeBord/connexionUtilisateurs.vue";
import ExercicesRealises from "./tableauDeBord/exercicesRealises.vue";
import AvancementProtocoles from "./tableauDeBord/avancementProtocoles.vue";
// import SeancesGroupes from "./tableauDeBord/seancesGroupes.vue";
// import DernieresAffiliations from "./tableauDeBord/dernieresAffiliations.vue";
// import DernieresActualites from "./tableauDeBord/dernieresActualites.vue";
import InformationsPrincipales from "./tableauDeBord/informationsPrincipales.vue";
import router from "@/router";
const User = createNamespacedHelpers("user");

export default {
  name: "tableau-de-bord",
  components: {
    appConnexionUtilisateurs: ConnexionUtilisateurs,
    appExercicesRealises: ExercicesRealises,
    appAvancementProtocoles: AvancementProtocoles,
    // appSeancesGroupes: SeancesGroupes,
    // appDernieresAffiliations: DernieresAffiliations,
    // appDernieresActualites: DernieresActualites,
    appInformationsPrincipales: InformationsPrincipales,
  },
  data() {
    return {
      User: null,
      utilisateurs: [],
      utilisateursAffilie: [],
      nbUsersInMyGroupWithActiveAccess: 0,
      filesDone: [],
      loadProtocoles: false,
      userSelected: "tous",
      sessionsGroup: null,
      utilisateursSessionGroup: null,
      groupSelected: "tous",
      affiliation_en_attente: 0,
      loadChartConnection: false,
      loadFilesDone: false,
      ouvertureSuiviCcUtilisateur: "",
      indefinteToast: null,
    };
  },
  computed: {
    ...User.mapGetters(["group", "folders"]),
  },
  methods: {
    ...User.mapActions({
      actionUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
    }),
    async getMyUsers() {
      ParseConfig();
      var tmpUtilisateur = [];
      this.loadChartConnection = false;
      const UserGroupObject = Parse.Object.extend("user_group");
      const UserGroupTemporary = new UserGroupObject();
      UserGroupTemporary.id = this.group.objectId;

      const UsersFromGroupQuery = new Parse.Query(Parse.User);
      UsersFromGroupQuery.equalTo("group", UserGroupTemporary);
      UsersFromGroupQuery.limit(10000);
      const UsersInMyGroup = await UsersFromGroupQuery.find();

      const UsersAffiliesQuery = new Parse.Query(Parse.User);
      UsersAffiliesQuery.equalTo("affiliation", Parse.User.current());
      UsersAffiliesQuery.limit(10000);
      const UserAffiliated = await UsersAffiliesQuery.find();
      await UsersInMyGroup.forEach((user) => {
        if (
          moment().toDate() <
            moment(user.attributes.fin_acces_plateforme).toDate() &&
          user.attributes.fin_acces_plateforme
        ) {
          this.nbUsersInMyGroupWithActiveAccess++;
        }
        tmpUtilisateur.push(user);
      });
      await UserAffiliated.forEach((user) => {
        var tmpUser = tmpUtilisateur.filter((usr) => {
          return usr.id == user.id;
        });
        if (tmpUser.length == 0) tmpUtilisateur.push(user);
      });
      this.utilisateurs = tmpUtilisateur;
      this.loadChartConnection = true;
    },
    async getUserAffiliateByGroupId() {
      this.affiliation_en_attente = 0;
      ParseConfig();
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("affiliation", Parse.User.current());
      QueryUser.limit(100000);
      const ResponseUser = await QueryUser.find();
      const QueryUserGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryUserGroup.equalTo("objectId", this.group.objectId);
      const ResponseUserGroup = await QueryUserGroup.first();
      const QueryUser2 = new Parse.Query(Parse.User);
      QueryUser2.equalTo("group", ResponseUserGroup);
      QueryUser2.limit(10000);
      const ResponseUserFromGroup = await QueryUser2.find();
      ResponseUserFromGroup.forEach((e) => {
        if (
          e.attributes.date_invitation_affiliation &&
          !e.attributes.date_affiliation
        ) {
          this.affiliation_en_attente += 1;
        }
      });
      const ArrayUtilisateurs = [];
      await asyncForEach(ResponseUser, async (utilisateur) => {
        const newUtilisateur = {
          firstname: utilisateur.attributes.firstname,
          lastname: utilisateur.attributes.lastname,
          id: utilisateur.id,
          date_affiliation: utilisateur.attributes.date_affiliation,
          date_invitation_affiliation:
            utilisateur.attributes.date_invitation_affiliation,
          email: utilisateur.attributes.username,
        };
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        if (utilisateur.attributes.group) {
          QueryUserGroup.equalTo("objectId", utilisateur.attributes.group.id);
          const userGroup = await QueryUserGroup.first();
          if (newUtilisateur.date_affiliation) {
            if (
              (!userGroup.attributes.type_abonnement_perso ||
                userGroup.attributes.type_abonnement_perso === "gratuit") &&
              moment(new Date()).toDate() <=
                moment(newUtilisateur.date_affiliation).add(10, "days").toDate()
            ) {
              newUtilisateur.etat = "essai gratuit";
              this.affiliation_en_attente += 1;
            } else if (userGroup.attributes.type_abonnement_perso === "cplay") {
              newUtilisateur.etat = "valide";
            } else {
              newUtilisateur.etat = "inactif";
            }
          }
        }
        ArrayUtilisateurs.push(newUtilisateur);
      });
      this.utilisateursAffilie = sortInvArrayByParam(
        ArrayUtilisateurs,
        "dateInscription"
      );
    },
    async getFilesDones() {
      this.loadFilesDone = false;
      try {
        var resultat = await Parse.Cloud.run("getFilesDones", {
          groupId: this.group.objectId,
        });
        var jsonResultat = JSON.parse(resultat);
        this.filesDone = jsonResultat;
        this.loadFilesDone = true;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des exercices terminés.",
          type: "is-danger",
        });
      }
    },
    async getAllSessionGroup() {
      try {
        const ResponseUserGroup = await getObjectById(
          "user_group",
          this.group.objectId
        );
        const ObjectSessionGroup = Parse.Object.extend("session_group");
        const QuerySessionGroup = new Parse.Query(ObjectSessionGroup);
        QuerySessionGroup.equalTo("user_group", ResponseUserGroup);
        var tmpSessionGroup = await QuerySessionGroup.find();
        this.sessionsGroup = tmpSessionGroup;
      } catch(error) {
        //console.log(error);
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des séances de groupe.",
          type: "is-danger",
        });
      }
    },
  },
  async mounted() {
    if (this.$route.params.username !== undefined) {
      this.ouvertureSuiviCcUtilisateur = this.$route.params.username;
    }
    // try {
    //   var query = new Parse.Query("site_admin");
    //   await query.first();
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }
    const loadingComponent = this.$buefy.loading.open();
    try {
      this.User = await Parse.User.current();
      await this.actionUpdateUserGroup();
      await this.getMyUsers();
      await this.getUserAffiliateByGroupId();
      await this.getAllSessionGroup();
      await this.getFilesDones();
      loadingComponent.close();
      this.loadProtocoles = true;
    } catch (error){
        //console.log(error)
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Echec de récupérations des informations.",
        type: "is-danger",
      });
    }
  },
  watch: {
    async groupSelected() {
      if (this.groupSelected === "tous") {
        this.utilisateursSessionGroup = null;
        return;
      }
      const RelationUserFromSessionGroup = this.groupSelected.relation("users");
      let QueryRelationUserFromSessionGroup =
        RelationUserFromSessionGroup.query();
      QueryRelationUserFromSessionGroup.limit(1000);
      this.utilisateursSessionGroup =
        await QueryRelationUserFromSessionGroup.find();
    },
  },
};
</script>
