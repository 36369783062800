import moment from "moment";

export default function getDateRenouvellement(group) {
  // Vérifie si le groupe a une période d'essai gratuite
  if (group.essai_gratuit !== undefined && group.essai_gratuit) {
    return new Date(moment(group.subscription_end_date));
  }
  
  // Vérifie si la date de paiement est absente ou nulle
  if (group.date_paiement === undefined || group.date_paiement === null) {
    return "période d'essai";
  }

  let duration = null;
  let dateRenouvellement = null;
  console.log("Date de paiement: ", group.date_paiement);
  // Détermine si la durée est en années ou en mois
  if (group.engagement_annuel) {
    duration = "years";
    dateRenouvellement = new Date(moment(group.date_paiement).add(1, duration));
  } else {
    duration = "months";
    dateRenouvellement = new Date(moment(group.date_paiement).add(3, duration)); // Ajoute 3 mois pour les abonnements mensuels
  }

  // Ajuste la date de renouvellement pour s'assurer qu'elle est dans le futur
  while (dateRenouvellement < new Date()) {
    dateRenouvellement = new Date(moment(dateRenouvellement).add(3, duration));
  }
  console.log("dateRenouvellement: ", dateRenouvellement);
  return dateRenouvellement;
}
