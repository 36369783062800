<template>
  <div>
    <folders-component
      :foldersBrique="foldersBrique"
      :group="group"
      :themes="themes"
      @unlockProtocol="unlockProtocol"
    />
    <br />
    <div
      class="navbar is-fixed-bottom"
      style="
        position: float;
        padding-top: 20px;
        border-top: 5px;
        margin-left: 225px;
      "
    >
      <a class="button is-grey is-margin-l-20" @click="$emit('next', 1)">
        {{ $t("precedent") }}
      </a>
      <div class="navbar-end is-margin-r-80">
        <a class="button is-cyan" @click="linkToAddToUser()">
          {{ $t("suivant") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import { unlockFolder } from "@/services/folders/unlockFolder.js";
import foldersComponent from "../folders.vue";
import cloneDeep from "lodash/cloneDeep";
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";

const User = createNamespacedHelpers("user");

export default {
  name: "selection-exercices",
  props: ["sessionGroupValue"],
  components: {
    FoldersComponent: foldersComponent,
  },
  data() {
    return {
      foldersBrique: [],
      themes: [],
    };
  },
  computed: {
    ...User.mapGetters(["group", "user", "folders"]),
  },
  methods: {
    unlockProtocol(props) {
      unlockFolder(props, this.group.type_abonnement, true);
    },
    goAbonnement() {
      this.$router.push("abonnement");
    },
    linkToAddToUser() {
      this.sessionGroupValue.linksToAdd = [];
      this.foldersBrique.forEach((brique) => {
        if (brique.deverrouille) {
          this.sessionGroupValue.linksToAdd.push(brique.link);
          brique.protocoles.forEach((protocole) => {
            if (protocole.buttonExpert && protocole.modulesExpert.length) {
              protocole.modulesExpert.forEach((moduleExpert) => {
                moduleExpert.linkExpert.forEach((link) => {
                  this.sessionGroupValue.linksToAdd.push(link);
                });
              });
            }
            if (
              protocole.buttonDecouverte &&
              protocole.modulesDecouverte.length
            ) {
              protocole.modulesDecouverte.forEach((moduleDecouverte) => {
                moduleDecouverte.linkDecouverte.forEach((link) => {
                  this.sessionGroupValue.linksToAdd.push(link);
                });
              });
            }
            if (protocole.deverrouille) {
              this.sessionGroupValue.linksToAdd.push(protocole.link);
              protocole.modules.forEach((moduleP) => {
                this.sessionGroupValue.linksToAdd.push(moduleP.link);
              });
            }
          });
        }
      });
      this.$emit("next", 3);
    },
  },
  async mounted() {
    this.foldersBrique = [];
    this.folders.forEach(async (e) => {
      var clone = cloneDeep(e);
      clone.deverrouille = false;
      await clone.protocoles.forEach(async (p) => (p.deverrouille = false));
      this.foldersBrique.push(clone);
    });
    this.foldersBrique = this.foldersBrique.filter((e) => e.domaine == "Santé");
    ParseConfig();
    const oDomaine = Parse.Object.extend("domaine");
    const queryDomaine = new Parse.Query(oDomaine);
    queryDomaine.equalTo("nom", "Santé");
    const domaineSante = await queryDomaine.first();
    const oTheme = Parse.Object.extend("theme");
    const queryTheme = new Parse.Query(oTheme);
    queryTheme.equalTo("domaine", domaineSante);
    queryTheme.ascending("ordre");
    this.themes = await queryTheme.find();
  },
};
</script>
