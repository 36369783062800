<template class="placement_div">
  <aside class="menu menuResponsiveAni">
    <b-collapse :open="isOpen" v-if="needReplace">
      <div class="is-sidebar-menu">
        <ul class="menu-list">
          <div class="rub" v-for="(heading, index) in myHeadings" :key="index">
            <li>
              <router-link
                v-bind:to="heading.to"
                :class="{
                  disabledMenuSidebar:
                    !payingSubscription && !heading.displayedForFree,
                }"
                
              >
                <li>
                  <span
                    :class="{
                      disabledText:
                        !payingSubscription && !heading.displayedForFree,
                    }"
                  >
                    {{ heading.name }} {{ $t(heading.name) }}</span
                  >
                </li>
              </router-link>
              <hr />
              <hr />
            </li>
          </div>
        </ul>
        <div class="mentions">
          <a
            href="https://www.symbiocenter.com/assets/Mentions_legales.pdf"
            target="_blank"
            ><u>Mentions légales</u></a
          >
        </div>
        <div class="LienFormations">
          <a href="https://www.symbiofi.com" target="_blank"
            ><u>Formations</u>
            <img
              src="../../../assets/img/Symbiofi2015 RBV PNG.png"
              alt="log symbiofi"
            />
          </a>
        </div>
      </div>
    </b-collapse>
    <b-collapse :open="isOpen" v-else>
      <div class="is-sidebar-menu">
        <ul class="menu-list">
          <div class="rub" v-for="(heading, index) in myHeadings" :key="index">
            <li
              v-if="
                heading.isActive &&
                heading.heading.name !== 'monEspace' &&
                heading.heading.name !== 'actualites' &&
                heading.heading.name !== 'Mes options' &&
                heading.heading.name !== 'maConfiguration'
              "
            >
              <router-link  :to="heading.heading.to">
                <span @click="resetStorage()"
                  ><i
                    class="fas"
                    :class="getFontAwesomeIcon(heading.heading.name)"
                  ></i>
                  {{ " " + $t(heading.heading.name) }}</span
                >
              </router-link>
              <hr />
            </li>
          </div>
        </ul>
        <div class="has-text-centered box-information">
          <a class="button is-primary is-margin-10" @click="deconnexion()">
            Déconnexion</a
          >
        </div>
        <div class="mentions">
          <a
            href="https://www.symbiocenter.com/assets/Mentions_legales.pdf"
            target="_blank"
            ><u>Mentions légales</u></a
          >
        </div>
        <div class="LienFormations">
          <a href="https://www.symbiofi.com" target="_blank"
            ><u>Formations</u>
            <img
              src="../../../assets/img/Symbiofi2015 RBV PNG.png"
              alt="log symbiofi"
            />
          </a>
        </div>
      </div>
    </b-collapse>
  </aside>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import hasPayingSubscription from "@/services/userGroup/subscriptionType.js";

const User = createNamespacedHelpers("user");

export default {
  name: "sidebar",
  props: ["isOpen"],
  data() {
    return {
      myHeadings: [],
      needReplace: false,
      headingsReplace: [
        // { name: "monEspace", to: "/mon-espace", displayedForFree: true },
        { name: "monProfil", to: "/mon-profil", displayedForFree: true },
        // { name: "monAni", to: "/mon-ani", displayedForFree: true },
        { name: "mesActivites", to: "/activites", displayedForFree: true },
        { name: "utilisateurs", to: "/utilisateurs", displayedForFree: true },
        {
          name: "RetrocessionEtRoyaltiesTitre",
          to: "/retrocession-et-royalties",
          displayedForFree: false,
        },
        { name: "Messagerie", to: "/ma-messagerie", displayedForFree: true },
        { name: "Forum", to: "/forum", displayedForFree: true },
        { name: "actualites", to: "/actualites", displayedForFree: false },
        // {
        //   name: "sessionGroupe",
        //   to: "/seance-groupe",
        //   displayedForFree: false,
        // },
        //---------------- ULRICHHAPPI
        {
          name: "tableauDeBord",
          to: "/tableau-de-bord",
          displayedForFree: false,
        },
        //---------------- ULRICHHAPPI
        { name: "abonnement", to: "/abonnement", displayedForFree: true },
        { name: "Support", to: "/support", displayedForFree: true },
      ],
      payingSubscription: false,
    };
  },
  computed: {
    ...User.mapGetters(["user", "abonnement", "headings"]),
  },
  methods: {
    ...User.mapActions({
      actionsLogOut: "logOut",
    }),

    functionTri(a, b) {
      let diff = a.heading.order - b.heading.order;
      if (diff < 0) {
        return -1; // On permute
      } else {
        return 1; // On ne permute pas
      }
    },
    async resetStorage() {
      console.log("resetStorage");
      document.cookie =
        "idUserForSave=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT";

      //reset le localStorage idUserForSave
      localStorage.removeItem("idUserForSave");
      //vérifier que le token est le bon
      var token = localStorage.getItem("token");
      var result = await verificationToken(token);
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
    },
    deconnexion() {
      this.actionsLogOut();
      this.router.go("/connexion");
    },
    getFontAwesomeIcon(headingName) {
      // Mapper les noms d'en-tête aux classes Font Awesome
      const iconMap = {
        monEspace: "fa-user",
        monProfil: "fa-id-card",
        monAni: "fa-heartbeat",
        mesActivites: "fa-tasks",
        utilisateurs: "fa-users",
        mesVisios: "fa-video",
        Messagerie: "fa-envelope",
        Support: "fa-question-circle",
        maConfiguration: "fa-cog",
        abonnement: "fa-credit-card",
        Personnalisation: "fa-cog",
      };
      return iconMap[headingName] || "fa-question"; // Icône par défaut
    },
  },

  mounted() {
    this.payingSubscription = hasPayingSubscription(this.abonnement);
    if (this.headings.length > 0) {
      this.myHeadings = this.headings.filter((el) => {
        return el.heading;
      });
      this.myHeadings = this.myHeadings.sort(this.functionTri);

      // Ajouter le heading "Personnalisation" ici
      this.myHeadings.push({
        heading: {
          name: "Personnalisation",
          to: "/personnalisation", // Assurez-vous que le chemin est correct
        },
        isActive: true,
      });
    } else {
      this.needReplace = true;
      this.myHeadings = this.headingsReplace;
    }
  },

  watch: {
    abonnement() {
      this.payingSubscription = hasPayingSubscription(this.abonnement);
    },
    headings() {
      this.myHeadings = this.headings.filter((el) => {
        return el.heading;
      });
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 768px) {
  .menuResponsiveAni {
    position: absolute;
    z-index: 998;
  }
}
</style>
