export default function getDate() {
  var d = new Date();
  var day = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
  var month =
    d.getMonth() + 1 < 10 ? "0" + (d.getMonth() + 1) : d.getMonth() + 1;
  var year = d.getFullYear();
  var hour = d.getHours() < 10 ? "0" + d.getHours() : d.getHours();
  var minute = d.getMinutes() < 10 ? "0" + d.getMinutes() : d.getMinutes();
  var second = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();
  var date =
    day + "/" + month + "/" + year + ":" + hour + ":" + minute + ":" + second;
  return date;
}
