<template>
  <div class="jitsi-wrapper">
    <div class="jitsi-container" ref="jitsiContainer">
      <div class="logo-overlay">
        <img src="../../../../assets/img/logo_symbiofi.png" alt="Votre logo" />
      </div>
    </div>
    <button @click="activatePiP">Activer PiP</button> <!-- Bouton ajouté -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      api: null,
    };
  },
  props: {
    currentRoomName: {
      type: String,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  methods: {
    activatePiP() {
      let videoElement = this.$refs.jitsiContainer.querySelector('video');
      if (videoElement && 'pictureInPictureEnabled' in document && !videoElement.disablePictureInPicture) {
        videoElement.requestPictureInPicture()
        .catch(error => {
          console.error(`PiP Error: ${error}`);
        });
      }
    }
  },
  mounted() {
    const domain = "8x8.vc"; // Remplacez par votre domaine Jaas Jitsi
    const options = {
      roomName: this.currentRoomName, // Remplacez par le nom de la salle souhaité
      parentNode: this.$refs.jitsiContainer,
      userInfo: {
        displayName: this.user.attributes.firstname, // Remplacez par le nom de l'utilisateur
      },
      lang: "fr",
      configOverwrite: {
        // prejoinPageEnabled: false,
        disableRemoteMute: true,
        disableRemoteControl: true,
        disableRemoteShortcuts: true,
        disableP2P: true,
        enableWelcomePage: false,
        enableClosePage: false,
        enableCalendarIntegration: false,
        enableLiveStreaming: false,
        enableRecording: false,
        enableTileView: false,
        enableNoAudioDetection: false,
        enableNoisyMicDetection: false,
        enableInsecureRoomNameWarning: false,
        enableAutomaticUrlCopy: false,
        disableDeepLinking: true,
        disableLocalVideoFlip: true,
        disableInviteFunctions: true,
        disableProfile: true,
        disableChat: true,
        disablePrivateChat: true,
        disablePolls: true,
        disableShortcuts: true,
        disableVideoBackground: true,
        disableVideoBlur: true,
        disableVideoShare: true,
        disableVirtualBackgrounds: true,
        disableKick: true,
        disableE2EE: true,
        disableThirdPartyRequests: true,
        disableLocalRecording: true,
        disableLocalRecordingDownload: true,
        disableTranscriptionSubtitles: true,
        disableLocalParticipantNameDisplay: true,
        disableRemoteParticipantNameDisplay: true,
      },
    };
    /* global JitsiMeetExternalAPI */
    this.api = new JitsiMeetExternalAPI(domain, options);
  },
  beforeDestroy() {
    if (this.api) {
      this.api.dispose();
    }
  },
};
</script>

<style scoped>
.jitsi-wrapper {
  position: relative;
  border: 5px solid #000;
  border-radius: 10px;
  margin: 0 auto;
  width: 80%;
  height: 600px;
  margin-top: 100px;
}

.jitsi-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.logo-overlay {
  background-color: white;
  position: absolute;
  top: 10%;
  left: 90%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 10;
  padding: 10px;
  border-radius: 10px 10px 10px 10px;
  opacity: 0.8;
}

.logo-overlay img {
  max-width: 100%;
  max-height: 100%;
}

#jaas-container {
  width: 100%;
  height: 100%;
}
</style>
