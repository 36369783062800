<template>
  <div>
    <b-table
      v-if="files.length"
      :data="files"
      :bordered="true"
      :narrowed="true"
      :striped="true"
      :paginated="true"
      perPage="10"
      :pagination-simple="false"
      icon-pack="fas"
    >
      <template slot-scope="props" slot="header">
        <div>{{ props.column.label }}</div>
      </template>

      <template slot-scope="props">
        <b-table-column field="title" label="Brique">
          {{ props.row.brique === undefined ? "" : props.row.brique }}
        </b-table-column>
        <b-table-column field="title" label="Protocole">
          {{ props.row.protocole === undefined ? "" : props.row.protocole }}
        </b-table-column>
        <b-table-column field="title" label="Module">
          {{ props.row.module === undefined ? "" : props.row.module }}
        </b-table-column>
        <b-table-column field="name" label="Exercice">
          <div v-if="props.row.attributes.sub_name">
            {{ props.row.attributes.sub_name }}
          </div>
          <div v-else>
            {{ props.row.attributes.name }}
          </div>
        </b-table-column>
        <b-table-column field="title" label="Nb accés">
          {{ props.row.nb }}
        </b-table-column>
        <b-table-column field="title" label="Durée initiale">
          <p v-if="props.row.attributes.duration">
            {{ Math.round(props.row.attributes.duration / 60) }}
            <span v-if="Math.round(props.row.attributes.duration / 60) === 1">
              {{ $t("minute") }}
            </span>
            <span v-else>{{ $t("minutes") }}</span>
          </p>
        </b-table-column>
        <b-table-column field="title" label="Total temps passé">
          <div v-if="props.row.total_time_elapsed">
            <div v-if="props.row.total_time_elapsed / 60 >= 1">
              {{ Math.round(props.row.total_time_elapsed / 60) }}
              <span v-if="Math.round(props.row.total_time_elapsed / 60) === 1">
                {{ $t("minute") }}
              </span>
              <span v-else>{{ $t("minutes") }}</span>
            </div>
            <div v-else>
              {{ Math.round(props.row.total_time_elapsed) }}
              <span v-if="Math.round(props.row.total_time_elapsed) === 1">
                {{ $t("seconde") }}
              </span>
              <span v-else>{{ $t("secondes") }}</span>
            </div>
          </div>
        </b-table-column>
      </template>
    </b-table>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { sortInvArrayByParam } from "@/services/general/sortArrayByParam.js";

export default {
  name: "exercice-les-plus-realises",
  props: ["filesDone"],
  data() {
    return {
      files: [],
    };
  },
  methods: {
    async getFilesMostlyDone() {
      const ObjectFolder = Parse.Object.extend("folder");
      const QueryFolder = new Parse.Query(ObjectFolder);
      QueryFolder.limit(10000);
      await QueryFolder.find();
      const ObjectFile = Parse.Object.extend("file");
      const QueryFile = new Parse.Query(ObjectFile);
      QueryFile.limit(10000);
      const files = await QueryFile.find();
      const filesAlreadyDone = [];
      for (var i = 0; i < files.length; i = i + 1) {
        var file = files[i];
        var filesDone = [];
        if (this.filesDone) {
          filesDone = this.filesDone.filter((e) => {
            return e.file !== undefined && e.file.id === file.id;
          });

          file.nb = 0;
          if (filesDone.length > 0) {
            if (filesDone[0].module) file.module = filesDone[0].module.name;
            if (filesDone[0].protocole)
              file.protocole = filesDone[0].protocole.name;
            if (filesDone[0].brique) file.brique = filesDone[0].brique.name;
            else if (filesDone[0].protocole) {
              const ObjectLink = Parse.Object.extend("link");
              const QueryLink = new Parse.Query(ObjectLink);
              QueryLink.equalTo("folder", filesDone[0].protocole);
              const ResponseLink = await QueryLink.first();
              file.brique =
                ResponseLink.attributes.parent_folder.attributes.name;
            }
            file.nb = filesDone.length;
            file.total_time_elapsed = 0;
            filesDone.forEach((e) => {
              if (e.time_elapsed) {
                file.total_time_elapsed += e.time_elapsed;
              }
            });
            filesAlreadyDone.push(file);
          }
        }
      }
      this.files = sortInvArrayByParam(filesAlreadyDone, "nb");
    },
  },
  async mounted() {
    ParseConfig();
    await this.getFilesMostlyDone();
  },
};
</script>
