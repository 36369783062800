<template>
  <div class="configPage is-main-content" style="padding: 5% 0 0 0 !important">
    <div class="is-fullheight">
      <div class="container is-fluid">
        <div class="listes">
          <Card
            :infos="index"
            :isPremiumSubUser="isPremiumSubUser"
            v-for="index in headingWithoutConfig"
            :key="index.name"
            @update-cart="updateCart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Card from "../components/UI/Card.vue";
import hasPayingSubscription from "@/services/userGroup/subscriptionType.js";
import Parse from "parse";

import { createNamespacedHelpers } from "vuex";

const User = createNamespacedHelpers("user");

export default {
  components: { Card },
  data() {
    return {
      isPremiumSubUser: "",
      headingWithoutConfig: null,
      myHeadings: null,
    };
  },
  computed: {
    ...User.mapGetters(["group", "abonnement", "abonnementTemp", "headings"]),
  },
  methods: {
    ...User.mapActions({
      actionsEditHeadings: "editHeadings",
    }),
    async updateCart(e) {
      const userHeadingsObject = Parse.Object.extend("user_headings");
      const userHeadingsQuery = new Parse.Query(userHeadingsObject);
      userHeadingsQuery.equalTo("objectId", e.infos.objectId);
      const userHeadingsFind = await userHeadingsQuery.first();
      userHeadingsFind.set("isActive", e.newActiveHeadings);
      await userHeadingsFind.save();
      e.infos.isActive = e.newActiveHeadings;
      this.pushMyHeadings();
    },
    async pushMyHeadings() {
      this.headingWithoutConfig = [];
      this.myHeadings = this.headings;
      this.myHeadings.forEach(async (el) => {
        this.headingWithoutConfig.push(el);
      });

      this.actionsEditHeadings({
        payload: this.myHeadings,
      });
    },
  },
  mounted() {
    this.pushMyHeadings();
    this.isPremiumSubUser = hasPayingSubscription(this.abonnement);
  },
  watch: {
    abonnement() {
      this.isPremiumSubUser = hasPayingSubscription(this.abonnement);
      if (this.group.essai_gratuit !== undefined && this.group.essai_gratuit)
        this.isPremiumSubUser = true;
    },
  },
};
</script>

<style>
.listes {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 1em;
}
@media screen and (min-width: 600px) {
  .listes {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (min-width: 900px) {
  .listes {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 1240px) {
  .listes {
    grid-template-columns: repeat(4, 1fr);
  }
}
</style>
