<template>
  <div class="chat-container">
    <aside class="menu">
      <div class="menu-header">
    <p class="menu-label">Conversations</p>
    <b-button @click="refreshMessages" class="refresh-button">
      <i class="fas fa-sync-alt"></i>
    </b-button>
  </div>

      <ul class="menu-list">
        <li v-if="users.length === 0">
          <span>Aucun utilisateur disponible</span>
        </li>
        <li v-else v-for="user in users" :key="user.id" class="user-list-item">
          <a
            @click="selectUser(user)"
            :class="{ 'is-active': selectedUser === user }"
          >
            <i class="fas fa-user"></i>
            <span :title="isUserOnline(user) ? 'En ligne' : 'Hors ligne'">
              {{ user.attributes.firstname + " " + user.attributes.lastname }}
            </span>
            <span v-if="isUserOnline(user)" class="user-online-indicator">
              <i class="fas fa-circle"></i>
            </span>
          </a>
        </li>
      </ul>
    </aside>

    <div class="chat">
      <div
        v-for="message in messages"
        :key="message.id"
        class="message-row"
        :class="
          message.attributes.sender.id === currentUser.id ? 'own' : 'other'
        "
      >
        <div class="message">
          {{ message.attributes.text }}
        </div>
      </div>

      <section class="message-input-section">
        <b-field class="input-field">
          <b-input
            v-model="newMessage"
            placeholder="Écrire un message"
            type="textarea"
            class="message-input"
            @keyup.enter.exact.prevent="sendMessage"
          ></b-input>
        </b-field>
        <b-button
          class="send-button"
          @click="sendMessage"
          :disabled="!newMessage.length"
        >
          Envoyer
        </b-button>
      </section>
    </div>
  </div>
</template>
<script>
import Parse from "parse";
import { getUserFromTherapeute } from "@/services/chat/getUserFromTherapeute.js";

export default {
  data() {
    return {
      users: [],
      messages: [],
      newMessage: "",
      currentUser: Parse.User.current(),
      selectedUser: null,
    };
  },

  methods: {
    async selectUser(user) {
      this.selectedUser = user;

      // Load the existing messages for the conversation with this user.
      if (this.currentUser.attributes.therapeute) {
        const query = new Parse.Query("conversation");
        query.equalTo("user_pro", this.currentUser);
        query.equalTo("user_patient", this.selectedUser);
        let conversation = await query.first();
        if (!conversation) {
          const conversation = new Parse.Object("conversation");
          conversation.set("user_pro", this.currentUser);
          conversation.set("user_patient", this.selectedUser);
          await conversation.save();
        }

        const messageQuery = new Parse.Query("message");
        messageQuery.equalTo("conversation", conversation);
        messageQuery.ascending("createdAt");
        this.messages = await messageQuery.find();
      } else {
        const query = new Parse.Query("conversation");
        query.equalTo("user_pro", this.selectedUser);
        query.equalTo("user_patient", this.currentUser);
        let conversation = await query.first();
        if (!conversation) {
          const conversation = new Parse.Object("conversation");
          conversation.set("user_pro", this.selectedUser);
          conversation.set("user_patient", this.currentUser);
          await conversation.save();
        }

        const messageQuery = new Parse.Query("message");
        messageQuery.equalTo("conversation", conversation);
        messageQuery.ascending("createdAt");
        this.messages = await messageQuery.find();
      }
    },
    isUserOnline(user) {
      const lastConnectionDate = new Date(user.attributes.derniere_connexion);
      const currentDate = new Date();

      const differenceInMinutes =
        (currentDate - lastConnectionDate) / 1000 / 60;

      return differenceInMinutes <= 15;
    },
    async refreshMessages() {
      if (this.users.length > 0) {
      this.selectUser(this.users[0]);
    }
    },
    async sendMessage() {
      if (this.newMessage.length) {
        const message = new Parse.Object("message");
        message.set("text", this.newMessage);
        message.set("sender", this.currentUser);
        // Set the 'conversation' field to the conversation with the selected user.

        const conversationQuery = new Parse.Query("conversation");
        if (this.currentUser.attributes.therapeute) {
          conversationQuery.equalTo("user_pro", this.currentUser);
          conversationQuery.equalTo("user_patient", this.selectedUser);
        } else {
          conversationQuery.equalTo("user_pro", this.selectedUser);
          conversationQuery.equalTo("user_patient", this.currentUser);
        }
        let conversation = await conversationQuery.first();

        message.set("conversation", conversation);
        await message.save();

        this.newMessage = "";
        this.messages.push(message);
      }
    },
  },

  async created() {
    if (this.currentUser.attributes.therapeute) {
      var result = await getUserFromTherapeute(
        this.currentUser.attributes.group,
        true
      );
    } else {
      var result = await getUserFromTherapeute(
        this.currentUser.attributes.group,
        false
      );
    }
    this.users = result;
    if (this.users.length > 0) {
      this.selectUser(this.users[0]);
    }
  },
};
</script>

<style scoped>
.chat-container {
  display: flex;
  margin-top: 5rem;
  margin-left: 2rem;
  height: calc(100vh - 5rem);
}

.menu {
  width: 25%;
  max-width: 240px;
  height: 100%;
  border-right: 1px solid #e0e0e0;
}

.menu-label {
  font-size: 1.2rem;
  color: #363636;
  margin-bottom: 1rem;
}

.chat {
  flex-grow: 1;
  margin-right: 2rem;
}
.chat-container {
  display: flex;
}

.menu {
  width: 240px;
}

.chat {
  flex-grow: 1;
  margin-top: 2rem;
  margin-left: 0.5rem;
}

.user-list-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;

  margin-top: 1.5rem;
}

.user-list-item a {
  display: flex;
  align-items: center;
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 100px;
}

.user-list-item a.is-active {
  background-color: #239380;
}

.user-list-item a span {
  margin-left: 10px;
}

.message-input-section {
  position: relative; /* Removed 'fixed' */
  bottom: 0;
  /* width: 65%; */
  padding: 10px;
  display: flex;
  align-items: center;
  margin-top: 20px; /* Added this line */
}

.input-field {
  flex-grow: 1;
  margin-right: 10px;
}

.message-input {
  border-radius: 15px;
}

.send-button {
  border-radius: 15px;
  background: #239380;
  color: white;
}
.message-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.message-row.other {
  justify-content: flex-start;
}

.message {
  background: #239380;
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  max-width: 75%;
  word-wrap: break-word;
}

.message-row.other .message {
  background: #d4d4d4;
  color: #333;
}

.user-online-indicator i {
  color: green;
}


.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.refresh-button {
  background-color: #239380;
  color: white;
  border-radius: 50%;
}
</style>
