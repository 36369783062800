export function dataToSend(data, tpe) {
  const chaine =
    "TPE=" +
    tpe.CMCIC_TPE +
    "*contexte_commande=" +
    data.contexte_commandeBase64 +
    "*date=" +
    data.date +
    "*lgue=" +
    tpe.CMCIC_LNG +
    "*mail=" +
    data.email +
    "*mode_affichage=iframe" +
    "*montant=" +
    data.amount +
    "" +
    tpe.CMCIC_CURRENCY +
    "*reference=" +
    data.reference +
    "*societe=" +
    tpe.CMCIC_CODESOCIETE +
    "*url_retour=" +
    tpe.CMCIC_URLKO.replace("%23", "#") +
    "*url_retour_err=" +
    tpe.CMCIC_URLKO.replace("%23", "#") +
    "*url_retour_ok=" +
    tpe.CMCIC_URLOK.replace("%23", "#") +
    "*version=" +
    tpe.CMCIC_VERSION;
  return chaine;
}

export function dataToSendResiliation(data, tpe) {
  const chaine =
    "TPE=" +
    tpe.CMCIC_TPE +
    "*date=" +
    data.date +
    "*date_commande=" +
    data.date_commande +
    "*lgue=" +
    tpe.CMCIC_LNG +
    "*montant=" +
    data.amount +
    "" +
    tpe.CMCIC_CURRENCY +
    "*montant_a_capturer=0EUR" +
    "*montant_deja_capture=0EUR" +
    "*montant_restant=0EUR" +
    "*reference=" +
    data.reference +
    "*societe=" +
    tpe.CMCIC_CODESOCIETE +
    "*stoprecurrence=OUI" +
    "*version=" +
    tpe.CMCIC_VERSION;
  return chaine;
}
