<template>
  <div>
    <div>
      <div
        class=""
        v-for="folder in listeFolder.filter((e) => {
          return e.aAfficher;
        })"
        :key="folder.id"
      >
        <div>
          <p class="has-text-left has-text-weight-semibold">
            {{ folder.name.toUpperCase() }}
          </p>
          <br />
          <div
            v-for="folderProtocole in folder.foldersProtocole.filter((e) => {
              return e.aAfficher;
            })"
            :key="folderProtocole.id"
            class="is-margin-b-20"
          >
            <div>
              <p class="has-text-left brique-text-avancement">
                {{ folderProtocole.name }}
              </p>
              <div style="width: 1000px; height: 150px; display: inline-flex">
                <div style="height: 150px; display: grid; place-items: center">
                  <div
                    style="
                      background-color: #537b81;
                      width: 400px;
                      height: 30px;
                    "
                  >
                    <div
                      style="
                        background-color: #3ec2cf;
                        height: 30px;
                        text-align: right;
                      "
                      v-bind:style="{
                        width:
                          folderProtocole.nbNote === 0
                            ? 0
                            : ((folderProtocole.noteMoyenne /
                                folderProtocole.nbNote) *
                                100) /
                                folderProtocole.foldersModule.reduce(function (
                                  acc,
                                  valeur
                                ) {
                                  return acc + valeur.maxPoint;
                                },
                                0) +
                              '%',
                      }"
                    >
                      <label style="color: white; padding-right: 10px">
                        {{
                          folderProtocole.nbNote === 0
                            ? ""
                            : folderProtocole.noteMoyenne /
                                folderProtocole.nbNote +
                              " pts"
                        }}
                      </label>
                    </div>
                  </div>
                </div>

                <div
                  style="
                    padding-left: 10px;
                    padding-right: 10px;
                    height: 150px;
                    display: grid;
                    place-items: center;
                  "
                >
                  <label
                    >{{
                      folderProtocole.foldersModule.reduce(function (
                        acc,
                        valeur
                      ) {
                        return acc + valeur.maxPoint;
                      },
                      0)
                    }}
                    pts</label
                  >
                </div>
                <div
                  style="
                    padding-left: 20px;
                    height: 150px;
                    display: grid;
                    place-items: center;
                    border-left-color: black;
                    border-left-width: 1px;
                    border-left-style: solid;
                  "
                >
                  <div style="display: inline-flex" v-if="folderUserSelected">
                    <div
                      style="
                        display: grid;
                        place-items: center;
                        padding-right: 20px;
                      "
                    >
                      <label>Résultats détaillés</label>
                    </div>
                    <chart-questionnaire-detail
                      :data="[Math.round(folderProtocole.somme)]"
                      :questionnaire="folderProtocole"
                      :userSelected="folderUserSelected"
                    ></chart-questionnaire-detail>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartQuestionnaireDetail from "./chartQuestionnaireDetail.vue";
export default {
  props: ["listeUser", "listeFolder", "folderUserSelected"],
  name: "chart-questionnaire",
  components: {
    chartQuestionnaireDetail: chartQuestionnaireDetail,
  },
  data() {
    return {
      name: "",
      userSelected: null,
    };
  },
  computed: {
    filteredDataArray() {
      return this.listeUser.filter((option) => {
        return (
          option.attributes.username
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>
