import Parse from "parse";
import asyncForEach from "@/services/general/asyncForEach";

export async function saveVerrouillageEtapeByProtocoleAndUser(
  foldersBrique,
  User
) {
  const ObjectFolder = Parse.Object.extend("folder");
  const ObjectVerrouillageEtape = Parse.Object.extend("verrouillage_etape");

  asyncForEach(foldersBrique, async (brique) => {
    asyncForEach(brique.protocoles, async (protocole) => {
      const QueryVerrouillageEtape = new Parse.Query(ObjectVerrouillageEtape);

      const folder = new ObjectFolder();
      folder.id = protocole.id;
      QueryVerrouillageEtape.equalTo("User", User);
      QueryVerrouillageEtape.equalTo("folder", folder);

      const ResponseVerrouillageEtape = await QueryVerrouillageEtape.first();
      if (ResponseVerrouillageEtape) {
        ResponseVerrouillageEtape.set(
          "verrouille",
          protocole.verrouillage_etape
        );
        await ResponseVerrouillageEtape.save();
      }
    });
  });
}
