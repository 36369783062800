var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-12-mobile is-12-tablet is-6-desktop is-4-widescreen"},[_c('a',{staticClass:"button is-cyan",on:{"click":function($event){_vm.isCardCreationUtilisateur = true}}},[_vm._v(" "+_vm._s(_vm.$t("ajoutUtilisateur"))+" ")]),_c('br'),_c('p',{staticClass:"has-text-weight-semibold is-size-5"},[_vm._v(" "+_vm._s(_vm.$t("listeUtilisateurInvite"))+" ")])])]),(
      _vm.sessionGroupValue &&
      _vm.sessionGroupValue.utilisateurs.length &&
      _vm.displayUser
    )?_c('b-table',{attrs:{"data":_vm.sessionGroupValue.utilisateurs,"bordered":true,"narrowed":true,"striped":true},scopedSlots:_vm._u([{key:"header",fn:function(props){return [_c('div',[_vm._v(_vm._s(props.column.label))])]}},{key:"default",fn:function(props){return (props.row.typePratique !== _vm.textLicence)?[_c('b-table-column',{attrs:{"field":"lastname","label":"Nom"}},[_vm._v(" "+_vm._s(props.row.nom)+" ")]),_c('b-table-column',{attrs:{"field":"firstname","label":"Prénom"}},[_vm._v(" "+_vm._s(props.row.prenom)+" ")]),_c('b-table-column',{attrs:{"field":"email","label":"Mail"}},[_vm._v(" "+_vm._s(props.row.mail)+" ")]),_c('b-table-column',{staticClass:"has-text-centered",attrs:{"label":"Dernière activité"}},[(props.row.derniere_connexion)?_c('div',[_vm._v(" "+_vm._s(new Date(props.row.derniere_connexion).toLocaleDateString())+" ")]):_vm._e()]),_c('b-table-column',{staticClass:"has-text-centered",attrs:{"label":"Durée d'accès"}},[(props.row.dureeAcces)?_c('span',[_vm._v(" "+_vm._s(props.row.dureeAcces)+" mois ")]):_vm._e()]),_c('b-table-column',{attrs:{"label":"Licence active"}},[_c('div',{staticClass:"has-text-centered"},[(
              props.row.typePratique === _vm.textLicence && props.row.accesActif
            )?_c('b-icon',{attrs:{"pack":"fas","icon":"circle","type":"is-success"}}):(
              props.row.typePratique === _vm.textLicence && !props.row.accesActif
            )?_c('b-icon',{attrs:{"pack":"fas","icon":"circle","type":"is-warning"}}):_c('b-icon',{attrs:{"pack":"fas","icon":"circle","type":"is-danger"}})],1)]),_c('b-table-column',{staticClass:"has-text-centered",attrs:{"label":"Accès"}},[_c('b-checkbox',{attrs:{"type":"is-cyan"},model:{value:(props.row.selected),callback:function ($$v) {_vm.$set(props.row, "selected", $$v)},expression:"props.row.selected"}})],1)]:undefined}}],null,true)}):_c('div',[_vm._v(" "+_vm._s(_vm.$t("aucunUtilisateur"))+" ")]),_c('br'),_c('br'),_c('br'),_c('br'),_c('div',{staticClass:"navbar is-fixed-bottom",staticStyle:{"position":"float","padding-top":"20px","border-top":"5px","margin-left":"225px"}},[_c('a',{staticClass:"button is-grey is-margin-l-20",on:{"click":function($event){return _vm.$emit('next', 2)}}},[_vm._v(" "+_vm._s(_vm.$t("precedent"))+" ")]),_c('div',{staticClass:"navbar-end is-margin-r-80"},[_c('a',{staticClass:"button is-cyan",on:{"click":function($event){return _vm.saveAndNext()}}},[_vm._v(" "+_vm._s(_vm.$t("suivant"))+" ")])])]),_c('b-modal',{attrs:{"width":1200,"active":_vm.isCardCreationUtilisateur},on:{"update:active":function($event){_vm.isCardCreationUtilisateur=$event},"close":function($event){_vm.isCardCreationUtilisateur = false}}},[_c('creation-utilisateur-component',{attrs:{"group":_vm.group},on:{"createUser":_vm.createUser,"closeModal":function($event){_vm.isCardCreationUtilisateur = false}}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }