<template>
  <div>
    <div class="contact-icon-container">
      <div class="contact-icon" @click="showPopUpContact = true">
        <i class="fas fa-comments"></i>
        <span v-if="NumberUnseenMessages > 0" class="unread-count">{{
        NumberUnseenMessages
      }}</span>
      </div>
    </div>
    <div>
      <contact-pop-up
        v-if="showPopUpContact"
        @close="handleClosePopUpContact"
        :NumberUnseenMessages="NumberUnseenMessages"
        :idUserMsg="idUserMsg"
      ></contact-pop-up>
    </div>
  </div>
</template>
<script> 
import contactPopUp from "@/components/contact/contactPopUp.vue";
import { getTotalUnseenMessages } from "@/services/chat/getTotalUnseenMessages.js"; // Importez la méthode sendMessage
import Parse from "parse";

export default {
  data() {
    return {
      showPopUpContact: false,
      NumberUnseenMessages: 0,
    };
  },
  props:
  {
    idUserMsg: String,
  },
  components: {
    contactPopUp,
  },
  async created() {
    this.NumberUnseenMessages = await getTotalUnseenMessages(Parse.User.current().id);
    console.log('Nombre total de messages non lus :', this.NumberUnseenMessages)
    if(this.idUserMsg)
    {
      this.showPopUpContact = true;
    }
  },
  methods: {
    handleClosePopUpContact() {
      this.showPopUpContact = false;
    },
    childMethod() {
      // Logique de la méthode enfant
      this.showPopUpContact = !this.showPopUpContact;
    },
  },
  mounted() {
  },
};
</script>
<style>
.contact-icon-container {
  position: fixed;
  bottom: 40px;
  right: 20px;
  z-index: 1000;
}

.contact-icon {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #239380;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.unread-count {
  position: absolute;
  top: -5px; /* Ajustez cette valeur pour régler la position verticale du badge */
  right: -10px; /* Ajustez cette valeur pour régler la position horizontale du badge */
  background-color: red;
  color: white;
  border-radius: 50%; /* Pour obtenir un cercle */
  padding: 5px 10px; /* Ajustez ces valeurs pour la taille du badge */
  font-size: 14px;
  font-weight: bold;
}
</style>
