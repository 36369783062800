<!-- ULRICHHAPPI rien n'a ete modifie -->
<template>
  <div class="columns is-multiline" v-if="statistiques && !disabledStats">
    <div class="column is-12 is-size-7">
      <b-icon
        pack="fa"
        icon="square"
        size="is-small"
        style="color: rgb(58, 255, 15)"
      ></b-icon>
      {{ $t("utilisateurAuCabinet") }}
      <b-icon
        pack="fa"
        icon="square"
        size="is-small"
        style="color: rgb(23, 128, 255)"
      ></b-icon>
      {{ $t("utilisateurSousLicence") }}
      <b-icon
        pack="fa"
        icon="square"
        size="is-small"
        style="color: rgb(254, 221, 1)"
      ></b-icon>
      {{ $t("utilisateurAffilie") }}
      <b-icon
        pack="fa"
        icon="square"
        size="is-small"
        style="color: black"
      ></b-icon>
      {{ $t("moyenneUtilisateurs") }}
      <b-icon
        pack="fa"
        icon="square"
        size="is-small"
        style="color: rgb(0, 255, 255)"
      ></b-icon>
      {{ $t("moyenneGlobale") }}
    </div>
    <div class="column is-6">
      <p
        class="has-text-centered has-text-weight-bold is-size-5 is-margin-b-20"
      >
        {{ $t("frequenceCardiaque") }}
      </p>
      <div class="columns is-multine">
        <div class="column is-4">
          <svg width="150" height="160" class="center-block">
            <rect
              width="70"
              height="160"
              style="
                fill: rgb(255, 255, 255);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <line
              v-if="statistiques.Utilisateur.BpmCC.moyenne"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.Utilisateur.BpmCC.moyenne"
              x2="70"
              :y2="160 - statistiques.Utilisateur.BpmCC.moyenne"
              stroke="rgb(0,255,255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.General.BpmCC.moyenne"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.General.BpmCC.moyenne"
              x2="70"
              :y2="160 - statistiques.General.BpmCC.moyenne"
              stroke="black"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurCabinet.BpmCC.moyenne"
              x1="0"
              :y1="160 - statistiques.UtilisateurCabinet.BpmCC.moyenne"
              x2="70"
              :y2="160 - statistiques.UtilisateurCabinet.BpmCC.moyenne"
              stroke="rgb(58, 255, 159)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurLicence.BpmCC.moyenne"
              x1="0"
              :y1="160 - statistiques.UtilisateurLicence.BpmCC.moyenne"
              x2="70"
              :y2="160 - statistiques.UtilisateurLicence.BpmCC.moyenne"
              stroke="rgb(23, 128, 255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurAffiliation.BpmCC.moyenne"
              x1="0"
              :y1="160 - statistiques.UtilisateurAffiliation.BpmCC.moyenne"
              x2="70"
              :y2="160 - statistiques.UtilisateurAffiliation.BpmCC.moyenne"
              stroke="rgb(254, 221, 1)"
              stroke-width="2"
            />
            <circle
              v-if="statistiques.UtilisateurCabinet.BpmCC.moyenne"
              cx="35"
              :cy="160 - statistiques.UtilisateurCabinet.BpmCC.moyenne"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(58, 255, 159)"
            />
            <circle
              v-if="statistiques.UtilisateurLicence.BpmCC.moyenne"
              cx="35"
              :cy="160 - statistiques.UtilisateurLicence.BpmCC.moyenne"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(23, 128, 255)"
            />
            <circle
              v-if="statistiques.UtilisateurAffiliation.BpmCC.moyenne"
              cx="35"
              :cy="160 - statistiques.UtilisateurAffiliation.BpmCC.moyenne"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(254, 221, 1)"
            />
            <text
              v-if="
                statistiques.UtilisateurCabinet.BpmCC.moyenne &&
                statistiques.Utilisateur.BpmCC.moyenne !==
                  statistiques.UtilisateurCabinet.BpmCC.moyenne &&
                !statistiques.Utilisateur.BpmCC.moyenne -
                  statistiques.UtilisateurCabinet.BpmCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.moyenne -
                    statistiques.UtilisateurCabinet.BpmCC.moyenne
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.BpmCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurCabinet.BpmCC.moyenne }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurLicence.BpmCC.moyenne &&
                statistiques.Utilisateur.BpmCC.moyenne !==
                  statistiques.UtilisateurLicence.BpmCC.moyenne &&
                !statistiques.Utilisateur.BpmCC.moyenne -
                  statistiques.UtilisateurLicence.BpmCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.moyenne -
                    statistiques.UtilisateurLicence.BpmCC.moyenne
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.BpmCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurLicence.BpmCC.moyenne }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurAffiliation.BpmCC.moyenne &&
                statistiques.Utilisateur.BpmCC.moyenne !==
                  statistiques.UtilisateurAffiliation.BpmCC.moyenne &&
                !statistiques.Utilisateur.BpmCC.moyenne -
                  statistiques.UtilisateurAffiliation.BpmCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.moyenne -
                    statistiques.UtilisateurAffiliation.BpmCC.moyenne
              "
              x="75"
              :y="160 - statistiques.UtilisateurAffiliation.BpmCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurAffiliation.BpmCC.moyenne }} bpm
            </text>
            <text
              v-if="statistiques.Utilisateur.BpmCC.moyenne"
              x="75"
              :y="160 - statistiques.Utilisateur.BpmCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.Utilisateur.BpmCC.moyenne }} bpm
            </text>
            <text
              v-if="
                statistiques.General.BpmCC.moyenne &&
                statistiques.Utilisateur.BpmCC.moyenne !==
                  statistiques.General.BpmCC.moyenne &&
                !statistiques.Utilisateur.BpmCC.moyenne -
                  statistiques.General.BpmCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.moyenne -
                    statistiques.General.BpmCC.moyenne
              "
              x="75"
              :y="160 - statistiques.General.BpmCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.General.BpmCC.moyenne }} bpm
            </text>
          </svg>
          <p class="has-text-centered is-margin-t-10 is-size-5">
            {{ $t("moyenneMaj") }}
          </p>
        </div>
        <div class="column is-4">
          <svg width="150" height="160" class="center-block">
            <rect
              width="70"
              height="160"
              style="
                fill: rgb(255, 255, 255);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <line
              v-if="statistiques.Utilisateur.BpmCC.max"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.Utilisateur.BpmCC.max"
              x2="70"
              :y2="160 - statistiques.Utilisateur.BpmCC.max"
              stroke="rgb(0,255,255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.General.BpmCC.max"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.General.BpmCC.max"
              x2="70"
              :y2="160 - statistiques.General.BpmCC.max"
              stroke="black"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurCabinet.BpmCC.max"
              x1="0"
              :y1="160 - statistiques.UtilisateurCabinet.BpmCC.max"
              x2="70"
              :y2="160 - statistiques.UtilisateurCabinet.BpmCC.max"
              stroke="rgb(58, 255, 159)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurLicence.BpmCC.max"
              x1="0"
              :y1="160 - statistiques.UtilisateurLicence.BpmCC.max"
              x2="70"
              :y2="160 - statistiques.UtilisateurLicence.BpmCC.max"
              stroke="rgb(23, 128, 255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurAffiliation.BpmCC.max"
              x1="0"
              :y1="160 - statistiques.UtilisateurAffiliation.BpmCC.max"
              x2="70"
              :y2="160 - statistiques.UtilisateurAffiliation.BpmCC.max"
              stroke="rgb(254, 221, 1)"
              stroke-width="2"
            />
            <circle
              v-if="statistiques.UtilisateurCabinet.BpmCC.max"
              cx="35"
              :cy="160 - statistiques.UtilisateurCabinet.BpmCC.max"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(58, 255, 159)"
            />
            <circle
              v-if="statistiques.UtilisateurLicence.BpmCC.max"
              cx="35"
              :cy="160 - statistiques.UtilisateurLicence.BpmCC.max"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(23, 128, 255)"
            />
            <circle
              v-if="statistiques.UtilisateurAffiliation.BpmCC.max"
              cx="35"
              :cy="160 - statistiques.UtilisateurAffiliation.BpmCC.max"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(254, 221, 1)"
            />
            <text
              v-if="
                statistiques.UtilisateurCabinet.BpmCC.max &&
                statistiques.Utilisateur.BpmCC.max !==
                  statistiques.UtilisateurCabinet.BpmCC.max &&
                !statistiques.Utilisateur.BpmCC.max -
                  statistiques.UtilisateurCabinet.BpmCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.max -
                    statistiques.UtilisateurCabinet.BpmCC.max
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.BpmCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurCabinet.BpmCC.max }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurLicence.BpmCC.max &&
                statistiques.Utilisateur.BpmCC.max !==
                  statistiques.UtilisateurLicence.BpmCC.max &&
                !statistiques.Utilisateur.BpmCC.max -
                  statistiques.UtilisateurLicence.BpmCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.max -
                    statistiques.UtilisateurLicence.BpmCC.max
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.BpmCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurLicence.BpmCC.max }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurAffiliation.BpmCC.max &&
                statistiques.Utilisateur.BpmCC.max !==
                  statistiques.UtilisateurAffiliation.BpmCC.max &&
                !statistiques.Utilisateur.BpmCC.max -
                  statistiques.UtilisateurAffiliation.BpmCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.max -
                    statistiques.UtilisateurAffiliation.BpmCC.max
              "
              x="75"
              :y="160 - statistiques.UtilisateurAffiliation.BpmCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurAffiliation.BpmCC.max }} bpm
            </text>
            <text
              v-if="statistiques.Utilisateur.BpmCC.max"
              x="75"
              :y="160 - statistiques.Utilisateur.BpmCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.Utilisateur.BpmCC.max }} bpm
            </text>
            <text
              v-if="
                statistiques.General.BpmCC.max &&
                statistiques.Utilisateur.BpmCC.max !==
                  statistiques.General.BpmCC.max &&
                !statistiques.Utilisateur.BpmCC.max -
                  statistiques.General.BpmCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.max -
                    statistiques.General.BpmCC.max
              "
              x="75"
              :y="160 - statistiques.General.BpmCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.General.BpmCC.max }} bpm
            </text>
          </svg>
          <p class="has-text-centered is-margin-t-10 is-size-5">
            {{ $t("MAXIMUM") }}
          </p>
        </div>
        <div class="column is-4">
          <svg width="150" height="160" class="center-block">
            <rect
              width="70"
              height="160"
              style="
                fill: rgb(255, 255, 255);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <line
              v-if="statistiques.Utilisateur.BpmCC.min"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.Utilisateur.BpmCC.min"
              x2="70"
              :y2="160 - statistiques.Utilisateur.BpmCC.min"
              stroke="rgb(0,255,255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.General.BpmCC.min"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.General.BpmCC.min"
              x2="70"
              :y2="160 - statistiques.General.BpmCC.min"
              stroke="black"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurCabinet.BpmCC.min"
              x1="0"
              :y1="160 - statistiques.UtilisateurCabinet.BpmCC.min"
              x2="70"
              :y2="160 - statistiques.UtilisateurCabinet.BpmCC.min"
              stroke="rgb(58, 255, 159)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurLicence.BpmCC.min"
              x1="0"
              :y1="160 - statistiques.UtilisateurLicence.BpmCC.min"
              x2="70"
              :y2="160 - statistiques.UtilisateurLicence.BpmCC.min"
              stroke="rgb(23, 128, 255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurAffiliation.BpmCC.min"
              x1="0"
              :y1="160 - statistiques.UtilisateurAffiliation.BpmCC.min"
              x2="70"
              :y2="160 - statistiques.UtilisateurAffiliation.BpmCC.min"
              stroke="rgb(254, 221, 1)"
              stroke-width="2"
            />
            <circle
              v-if="statistiques.UtilisateurCabinet.BpmCC.min"
              cx="35"
              :cy="160 - statistiques.UtilisateurCabinet.BpmCC.min"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(58, 255, 159)"
            />
            <circle
              v-if="statistiques.UtilisateurLicence.BpmCC.min"
              cx="35"
              :cy="160 - statistiques.UtilisateurLicence.BpmCC.min"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(23, 128, 255)"
            />
            <circle
              v-if="statistiques.UtilisateurAffiliation.BpmCC.min"
              cx="35"
              :cy="160 - statistiques.UtilisateurAffiliation.BpmCC.min"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(254, 221, 1)"
            />
            <text
              v-if="
                statistiques.UtilisateurCabinet.BpmCC.min &&
                statistiques.Utilisateur.BpmCC.min !==
                  statistiques.UtilisateurCabinet.BpmCC.min &&
                !statistiques.Utilisateur.BpmCC.min -
                  statistiques.UtilisateurCabinet.BpmCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.min -
                    statistiques.UtilisateurCabinet.BpmCC.min
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.BpmCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurCabinet.BpmCC.min }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurLicence.BpmCC.min &&
                statistiques.Utilisateur.BpmCC.min !==
                  statistiques.UtilisateurLicence.BpmCC.min &&
                !statistiques.Utilisateur.BpmCC.min -
                  statistiques.UtilisateurLicence.BpmCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.min -
                    statistiques.UtilisateurLicence.BpmCC.min
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.BpmCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurLicence.BpmCC.min }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurAffiliation.BpmCC.min &&
                statistiques.Utilisateur.BpmCC.min !==
                  statistiques.UtilisateurAffiliation.BpmCC.min &&
                !statistiques.Utilisateur.BpmCC.min -
                  statistiques.UtilisateurAffiliation.BpmCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.min -
                    statistiques.UtilisateurAffiliation.BpmCC.min
              "
              x="75"
              :y="160 - statistiques.UtilisateurAffiliation.BpmCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurAffiliation.BpmCC.min }} bpm
            </text>
            <text
              v-if="statistiques.Utilisateur.BpmCC.min"
              x="75"
              :y="160 - statistiques.Utilisateur.BpmCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.Utilisateur.BpmCC.min }} bpm
            </text>
            <text
              v-if="
                statistiques.General.BpmCC.min &&
                statistiques.Utilisateur.BpmCC.min !==
                  statistiques.General.BpmCC.min &&
                !statistiques.Utilisateur.BpmCC.min -
                  statistiques.General.BpmCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.BpmCC.min -
                    statistiques.General.BpmCC.min
              "
              x="75"
              :y="160 - statistiques.General.BpmCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.General.BpmCC.min }} bpm
            </text>
          </svg>
          <p class="has-text-centered is-margin-t-10 is-size-5">
            {{ $t("MINIMUM") }}
          </p>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <p
        class="has-text-centered has-text-weight-bold is-size-5 is-margin-b-20"
      >
        % {{ $t("sessionAvecCc") }}
      </p>
      <div class="columns" v-if="statistiques">
        <div class="column is-4 has-text-centered">
          <p>{{ $t("superieureA") }} 90%</p>
          <canvas
            ref="chartSessionHigh"
            height="200"
            width="400px"
            class="is-margin-b-10"
          ></canvas>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(58, 255, 15)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurCabinet.BpmCC.high.length">
              {{
                Math.round(
                  (statistiques.UtilisateurCabinet.BpmCC.high.length /
                    statistiques.UtilisateurCabinet.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(23, 128, 255)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurLicence.BpmCC.high.length">
              {{
                Math.round(
                  (statistiques.UtilisateurLicence.BpmCC.high.length /
                    statistiques.UtilisateurLicence.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(254, 221, 1)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurAffiliation.BpmCC.high.length">
              {{
                Math.round(
                  (statistiques.UtilisateurAffiliation.BpmCC.high.length /
                    statistiques.UtilisateurAffiliation.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(0, 255, 255)"
            >
            </b-icon>
            <span v-if="statistiques.General.BpmCC.high.length">
              {{
                Math.round(
                  (statistiques.General.BpmCC.high.length /
                    statistiques.General.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: black"
            >
            </b-icon>
            <span v-if="statistiques.Utilisateur.BpmCC.high.length">
              {{
                Math.round(
                  (statistiques.Utilisateur.BpmCC.high.length /
                    statistiques.Utilisateur.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
        </div>
        <div class="column is-4 has-text-centered">
          <p>{{ $t("de") }} 70% {{ $t("a") }} 90%</p>
          <canvas
            ref="chartSessionMedium"
            height="200"
            width="400"
            class="is-margin-b-10"
          ></canvas>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(58, 255, 15)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurCabinet.BpmCC.medium.length">
              {{
                Math.round(
                  (statistiques.UtilisateurCabinet.BpmCC.medium.length /
                    statistiques.UtilisateurCabinet.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(23, 128, 255)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurLicence.BpmCC.medium.length">
              {{
                Math.round(
                  (statistiques.UtilisateurLicence.BpmCC.medium.length /
                    statistiques.UtilisateurLicence.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(254, 221, 1)"
            >
            </b-icon>
            <span
              v-if="statistiques.UtilisateurAffiliation.BpmCC.medium.length"
            >
              {{
                Math.round(
                  (statistiques.UtilisateurAffiliation.BpmCC.medium.length /
                    statistiques.UtilisateurAffiliation.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(0, 255, 255)"
            >
            </b-icon>
            <span v-if="statistiques.General.BpmCC.medium.length">
              {{
                Math.round(
                  (statistiques.General.BpmCC.medium.length /
                    statistiques.General.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: black"
            >
            </b-icon>
            <span v-if="statistiques.Utilisateur.BpmCC.medium.length">
              {{
                Math.round(
                  (statistiques.Utilisateur.BpmCC.medium.length /
                    statistiques.Utilisateur.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
        </div>
        <div class="column is-4 has-text-centered">
          <p>{{ $t("inferieureA") }} 70%</p>
          <canvas
            ref="chartSessionLow"
            height="200"
            width="400"
            class="is-margin-b-10"
          ></canvas>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(58, 255, 15)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurCabinet.BpmCC.low.length">
              {{
                Math.round(
                  (statistiques.UtilisateurCabinet.BpmCC.low.length /
                    statistiques.UtilisateurCabinet.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(23, 128, 255)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurLicence.BpmCC.low.length">
              {{
                Math.round(
                  (statistiques.UtilisateurLicence.BpmCC.low.length /
                    statistiques.UtilisateurLicence.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(254, 221, 1)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurAffiliation.BpmCC.low.length">
              {{
                Math.round(
                  (statistiques.UtilisateurAffiliation.BpmCC.low.length /
                    statistiques.UtilisateurAffiliation.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(0, 255, 255)"
            >
            </b-icon>
            <span v-if="statistiques.General.BpmCC.low.length">
              {{
                Math.round(
                  (statistiques.General.BpmCC.low.length /
                    statistiques.General.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: black"
            >
            </b-icon>
            <span v-if="statistiques.Utilisateur.BpmCC.low.length">
              {{
                Math.round(
                  (statistiques.Utilisateur.BpmCC.low.length /
                    statistiques.Utilisateur.BpmCC.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <p
        class="has-text-centered has-text-weight-bold is-size-5 is-margin-b-20"
      >
        {{ $t("coherenceCardiaque") }}
      </p>
      <div class="columns is-multine">
        <div class="column is-4">
          <svg width="150" height="160" class="center-block">
            <rect
              width="70"
              height="160"
              style="
                fill: rgb(243, 194, 2);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <line
              v-if="statistiques.Utilisateur.AverageCC.moyenne"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.Utilisateur.AverageCC.moyenne"
              x2="70"
              :y2="160 - statistiques.Utilisateur.AverageCC.moyenne"
              stroke="rgb(0,255,255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.General.AverageCC.moyenne"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.General.AverageCC.moyenne"
              x2="70"
              :y2="160 - statistiques.General.AverageCC.moyenne"
              stroke="black"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurCabinet.AverageCC.moyenne"
              x1="0"
              :y1="160 - statistiques.UtilisateurCabinet.AverageCC.moyenne"
              x2="70"
              :y2="160 - statistiques.UtilisateurCabinet.AverageCC.moyenne"
              stroke="rgb(58, 255, 159)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurLicence.AverageCC.moyenne"
              x1="0"
              :y1="160 - statistiques.UtilisateurLicence.AverageCC.moyenne"
              x2="70"
              :y2="160 - statistiques.UtilisateurLicence.AverageCC.moyenne"
              stroke="rgb(23, 128, 255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurAffiliation.AverageCC.moyenne"
              x1="0"
              :y1="160 - statistiques.UtilisateurAffiliation.AverageCC.moyenne"
              x2="70"
              :y2="160 - statistiques.UtilisateurAffiliation.AverageCC.moyenne"
              stroke="rgb(254, 221, 1)"
              stroke-width="2"
            />
            <circle
              v-if="statistiques.UtilisateurCabinet.AverageCC.moyenne"
              cx="35"
              :cy="160 - statistiques.UtilisateurCabinet.AverageCC.moyenne"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(58, 255, 159)"
            />
            <circle
              v-if="statistiques.UtilisateurLicence.AverageCC.moyenne"
              cx="35"
              :cy="160 - statistiques.UtilisateurLicence.AverageCC.moyenne"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(23, 128, 255)"
            />
            <circle
              v-if="statistiques.UtilisateurAffiliation.AverageCC.moyenne"
              cx="35"
              :cy="160 - statistiques.UtilisateurAffiliation.AverageCC.moyenne"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(254, 221, 1)"
            />
            <text
              v-if="
                statistiques.UtilisateurCabinet.AverageCC.moyenne &&
                statistiques.Utilisateur.AverageCC.moyenne !==
                  statistiques.UtilisateurCabinet.AverageCC.moyenne &&
                !statistiques.Utilisateur.AverageCC.moyenne -
                  statistiques.UtilisateurCabinet.AverageCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.moyenne -
                    statistiques.UtilisateurCabinet.AverageCC.moyenne
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.AverageCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurCabinet.AverageCC.moyenne }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurLicence.AverageCC.moyenne &&
                statistiques.Utilisateur.AverageCC.moyenne !==
                  statistiques.UtilisateurLicence.AverageCC.moyenne &&
                !statistiques.Utilisateur.AverageCC.moyenne -
                  statistiques.UtilisateurLicence.AverageCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.moyenne -
                    statistiques.UtilisateurLicence.AverageCC.moyenne
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.AverageCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurLicence.AverageCC.moyenne }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurAffiliation.AverageCC.moyenne &&
                statistiques.Utilisateur.AverageCC.moyenne !==
                  statistiques.UtilisateurAffiliation.AverageCC.moyenne &&
                !statistiques.Utilisateur.AverageCC.moyenne -
                  statistiques.UtilisateurAffiliation.AverageCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.moyenne -
                    statistiques.UtilisateurAffiliation.AverageCC.moyenne
              "
              x="75"
              :y="160 - statistiques.UtilisateurAffiliation.AverageCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurAffiliation.AverageCC.moyenne }} bpm
            </text>
            <text
              v-if="statistiques.Utilisateur.AverageCC.moyenne"
              x="75"
              :y="160 - statistiques.Utilisateur.AverageCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.Utilisateur.AverageCC.moyenne }} bpm
            </text>
            <text
              v-if="
                statistiques.General.AverageCC.moyenne &&
                statistiques.Utilisateur.AverageCC.moyenne !==
                  statistiques.General.AverageCC.moyenne &&
                !statistiques.Utilisateur.AverageCC.moyenne -
                  statistiques.General.AverageCC.moyenne <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.moyenne -
                    statistiques.General.AverageCC.moyenne
              "
              x="75"
              :y="160 - statistiques.General.AverageCC.moyenne"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.General.AverageCC.moyenne }} bpm
            </text>
          </svg>
          <p class="has-text-centered is-margin-t-10 is-size-5">
            {{ $t("moyenneMaj") }}
          </p>
        </div>
        <div class="column is-4">
          <svg width="150" height="160" class="center-block">
            <rect
              width="70"
              height="160"
              style="
                fill: rgb(56, 213, 18);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <line
              v-if="statistiques.Utilisateur.AverageCC.max"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.Utilisateur.AverageCC.max"
              x2="70"
              :y2="160 - statistiques.Utilisateur.AverageCC.max"
              stroke="rgb(0,255,255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.General.AverageCC.max"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.General.AverageCC.max"
              x2="70"
              :y2="160 - statistiques.General.AverageCC.max"
              stroke="black"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurCabinet.AverageCC.max"
              x1="0"
              :y1="160 - statistiques.UtilisateurCabinet.AverageCC.max"
              x2="70"
              :y2="160 - statistiques.UtilisateurCabinet.AverageCC.max"
              stroke="rgb(58, 255, 159)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurLicence.AverageCC.max"
              x1="0"
              :y1="160 - statistiques.UtilisateurLicence.AverageCC.max"
              x2="70"
              :y2="160 - statistiques.UtilisateurLicence.AverageCC.max"
              stroke="rgb(23, 128, 255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurAffiliation.AverageCC.max"
              x1="0"
              :y1="160 - statistiques.UtilisateurAffiliation.AverageCC.max"
              x2="70"
              :y2="160 - statistiques.UtilisateurAffiliation.AverageCC.max"
              stroke="rgb(254, 221, 1)"
              stroke-width="2"
            />
            <circle
              v-if="statistiques.UtilisateurCabinet.AverageCC.max"
              cx="35"
              :cy="160 - statistiques.UtilisateurCabinet.AverageCC.max"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(58, 255, 159)"
            />
            <circle
              v-if="statistiques.UtilisateurLicence.AverageCC.max"
              cx="35"
              :cy="160 - statistiques.UtilisateurLicence.AverageCC.max"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(23, 128, 255)"
            />
            <circle
              v-if="statistiques.UtilisateurAffiliation.AverageCC.max"
              cx="35"
              :cy="160 - statistiques.UtilisateurAffiliation.AverageCC.max"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(254, 221, 1)"
            />
            <text
              v-if="
                statistiques.UtilisateurCabinet.AverageCC.max &&
                statistiques.Utilisateur.AverageCC.max !==
                  statistiques.UtilisateurCabinet.AverageCC.max &&
                !statistiques.Utilisateur.AverageCC.max -
                  statistiques.UtilisateurCabinet.AverageCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.max -
                    statistiques.UtilisateurCabinet.AverageCC.max
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.AverageCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurCabinet.AverageCC.max }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurLicence.AverageCC.max &&
                statistiques.Utilisateur.AverageCC.max !==
                  statistiques.UtilisateurLicence.AverageCC.max &&
                !statistiques.Utilisateur.AverageCC.max -
                  statistiques.UtilisateurLicence.AverageCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.max -
                    statistiques.UtilisateurLicence.AverageCC.max
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.AverageCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurLicence.AverageCC.max }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurAffiliation.AverageCC.max &&
                statistiques.Utilisateur.AverageCC.max !==
                  statistiques.UtilisateurAffiliation.AverageCC.max &&
                !statistiques.Utilisateur.AverageCC.max -
                  statistiques.UtilisateurAffiliation.AverageCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.max -
                    statistiques.UtilisateurAffiliation.AverageCC.max
              "
              x="75"
              :y="160 - statistiques.UtilisateurAffiliation.AverageCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurAffiliation.AverageCC.max }} bpm
            </text>
            <text
              v-if="statistiques.Utilisateur.AverageCC.max"
              x="75"
              :y="160 - statistiques.Utilisateur.AverageCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.Utilisateur.AverageCC.max }} bpm
            </text>
            <text
              v-if="
                statistiques.General.AverageCC.max &&
                statistiques.Utilisateur.AverageCC.max !==
                  statistiques.General.AverageCC.max &&
                !statistiques.Utilisateur.AverageCC.max -
                  statistiques.General.AverageCC.max <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.max -
                    statistiques.General.AverageCC.max
              "
              x="75"
              :y="160 - statistiques.General.AverageCC.max"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.General.AverageCC.max }} bpm
            </text>
          </svg>
          <p class="has-text-centered is-margin-t-10 is-size-5">
            {{ $t("maximumMaj") }}
          </p>
        </div>
        <div class="column is-4">
          <svg width="150" height="160" class="center-block">
            <rect
              width="70"
              height="160"
              style="
                fill: rgb(246, 52, 52);
                stroke-width: 1;
                stroke: rgb(0, 0, 0);
              "
            />
            <line
              v-if="statistiques.Utilisateur.AverageCC.min"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.Utilisateur.AverageCC.min"
              x2="70"
              :y2="160 - statistiques.Utilisateur.AverageCC.min"
              stroke="rgb(0,255,255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.General.AverageCC.min"
              stroke-dasharray="4"
              x1="0"
              :y1="160 - statistiques.General.AverageCC.min"
              x2="70"
              :y2="160 - statistiques.General.AverageCC.min"
              stroke="black"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurCabinet.AverageCC.min"
              x1="0"
              :y1="160 - statistiques.UtilisateurCabinet.AverageCC.min"
              x2="70"
              :y2="160 - statistiques.UtilisateurCabinet.AverageCC.min"
              stroke="rgb(58, 255, 159)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurLicence.AverageCC.min"
              x1="0"
              :y1="160 - statistiques.UtilisateurLicence.AverageCC.min"
              x2="70"
              :y2="160 - statistiques.UtilisateurLicence.AverageCC.min"
              stroke="rgb(23, 128, 255)"
              stroke-width="2"
            />
            <line
              v-if="statistiques.UtilisateurAffiliation.AverageCC.min"
              x1="0"
              :y1="160 - statistiques.UtilisateurAffiliation.AverageCC.min"
              x2="70"
              :y2="160 - statistiques.UtilisateurAffiliation.AverageCC.min"
              stroke="rgb(254, 221, 1)"
              stroke-width="2"
            />
            <circle
              v-if="statistiques.UtilisateurCabinet.AverageCC.min"
              cx="35"
              :cy="160 - statistiques.UtilisateurCabinet.AverageCC.min"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(58, 255, 159)"
            />
            <circle
              v-if="statistiques.UtilisateurLicence.AverageCC.min"
              cx="35"
              :cy="160 - statistiques.UtilisateurLicence.AverageCC.min"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(23, 128, 255)"
            />
            <circle
              v-if="statistiques.UtilisateurAffiliation.AverageCC.min"
              cx="35"
              :cy="160 - statistiques.UtilisateurAffiliation.AverageCC.min"
              r="6"
              fill="white"
              stroke-width="2"
              stroke="rgb(254, 221, 1)"
            />
            <text
              v-if="
                statistiques.UtilisateurCabinet.AverageCC.min &&
                statistiques.Utilisateur.AverageCC.min !==
                  statistiques.UtilisateurCabinet.AverageCC.min &&
                !statistiques.Utilisateur.AverageCC.min -
                  statistiques.UtilisateurCabinet.AverageCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.min -
                    statistiques.UtilisateurCabinet.AverageCC.min
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.AverageCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurCabinet.AverageCC.min }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurLicence.AverageCC.min &&
                statistiques.Utilisateur.AverageCC.min !==
                  statistiques.UtilisateurLicence.AverageCC.min &&
                !statistiques.Utilisateur.AverageCC.min -
                  statistiques.UtilisateurLicence.AverageCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.min -
                    statistiques.UtilisateurLicence.AverageCC.min
              "
              x="75"
              :y="160 - statistiques.UtilisateurCabinet.AverageCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurLicence.AverageCC.min }} bpm
            </text>
            <text
              v-if="
                statistiques.UtilisateurAffiliation.AverageCC.min &&
                statistiques.Utilisateur.AverageCC.min !==
                  statistiques.UtilisateurAffiliation.AverageCC.min &&
                !statistiques.Utilisateur.AverageCC.min -
                  statistiques.UtilisateurAffiliation.AverageCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.min -
                    statistiques.UtilisateurAffiliation.AverageCC.min
              "
              x="75"
              :y="160 - statistiques.UtilisateurAffiliation.AverageCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.UtilisateurAffiliation.AverageCC.min }} bpm
            </text>
            <text
              v-if="statistiques.Utilisateur.AverageCC.min"
              x="75"
              :y="160 - statistiques.Utilisateur.AverageCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.Utilisateur.AverageCC.min }} bpm
            </text>
            <text
              v-if="
                statistiques.General.AverageCC.min &&
                statistiques.Utilisateur.AverageCC.min !==
                  statistiques.General.AverageCC.min &&
                !statistiques.Utilisateur.AverageCC.min -
                  statistiques.General.AverageCC.min <
                  10 &&
                !-10 <
                  statistiques.Utilisateur.AverageCC.min -
                    statistiques.General.AverageCC.min
              "
              x="75"
              :y="160 - statistiques.General.AverageCC.min"
              font-family="Verdana"
              font-size="12"
            >
              {{ statistiques.General.AverageCC.min }} bpm
            </text>
          </svg>
          <p class="has-text-centered is-margin-t-10 is-size-5">
            {{ $t("minimumMaj") }}
          </p>
        </div>
      </div>
    </div>
    <div class="column is-6">
      <p
        class="has-text-centered has-text-weight-bold is-size-5 is-margin-b-20"
      >
        % {{ $t("tempsZoneCc") }}
      </p>
      <div class="columns" v-if="statistiques">
        <div class="column is-4 has-text-centered">
          <p>{{ $t("superieureA") }} 90%</p>
          <canvas
            ref="chartTimeHigh"
            height="200"
            width="400px"
            class="is-margin-b-10"
          ></canvas>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(58, 255, 15)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurCabinet.Time.high.length">
              {{
                Math.round(
                  (statistiques.UtilisateurCabinet.Time.high.length /
                    statistiques.UtilisateurCabinet.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(23, 128, 255)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurLicence.Time.high.length">
              {{
                Math.round(
                  (statistiques.UtilisateurLicence.Time.high.length /
                    statistiques.UtilisateurLicence.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(254, 221, 1)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurAffiliation.Time.high.length">
              {{
                Math.round(
                  (statistiques.UtilisateurAffiliation.Time.high.length /
                    statistiques.UtilisateurAffiliation.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(0, 255, 255)"
            >
            </b-icon>
            <span v-if="statistiques.General.Time.high.length">
              {{
                Math.round(
                  (statistiques.General.Time.high.length /
                    statistiques.General.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: black"
            >
            </b-icon>
            <span v-if="statistiques.Utilisateur.Time.high.length">
              {{
                Math.round(
                  (statistiques.Utilisateur.Time.high.length /
                    statistiques.Utilisateur.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
        </div>
        <div class="column is-4 has-text-centered">
          <p>{{ $t("de") }} 60% {{ $t("a") }} 90%</p>
          <canvas
            ref="chartTimeMedium"
            height="200"
            width="400"
            class="is-margin-b-10"
          ></canvas>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(58, 255, 15)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurCabinet.Time.medium.length">
              {{
                Math.round(
                  (statistiques.UtilisateurCabinet.Time.medium.length /
                    statistiques.UtilisateurCabinet.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(23, 128, 255)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurLicence.Time.medium.length">
              {{
                Math.round(
                  (statistiques.UtilisateurLicence.Time.medium.length /
                    statistiques.UtilisateurLicence.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(254, 221, 1)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurAffiliation.Time.medium.length">
              {{
                Math.round(
                  (statistiques.UtilisateurAffiliation.Time.medium.length /
                    statistiques.UtilisateurAffiliation.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(0, 255, 255)"
            >
            </b-icon>
            <span v-if="statistiques.General.Time.medium.length">
              {{
                Math.round(
                  (statistiques.General.Time.medium.length /
                    statistiques.General.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: black"
            >
            </b-icon>
            <span v-if="statistiques.Utilisateur.Time.medium.length">
              {{
                Math.round(
                  (statistiques.Utilisateur.Time.medium.length /
                    statistiques.Utilisateur.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
        </div>
        <div class="column is-4 has-text-centered">
          <p>{{ $t("inferieureA") }} 60%</p>
          <canvas
            ref="chartTimeLow"
            height="200"
            width="400"
            class="is-margin-b-10"
          ></canvas>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(58, 255, 15)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurCabinet.Time.low.length">
              {{
                Math.round(
                  (statistiques.UtilisateurCabinet.Time.low.length /
                    statistiques.UtilisateurCabinet.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(23, 128, 255)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurLicence.Time.low.length">
              {{
                Math.round(
                  (statistiques.UtilisateurLicence.Time.low.length /
                    statistiques.UtilisateurLicence.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(254, 221, 1)"
            >
            </b-icon>
            <span v-if="statistiques.UtilisateurAffiliation.Time.low.length">
              {{
                Math.round(
                  (statistiques.UtilisateurAffiliation.Time.low.length /
                    statistiques.UtilisateurAffiliation.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
          <p class="is-size-7">
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: rgb(0, 255, 255)"
            >
            </b-icon>
            <span v-if="statistiques.General.Time.low.length">
              {{
                Math.round(
                  (statistiques.General.Time.low.length /
                    statistiques.General.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
            <b-icon
              pack="fa"
              icon="circle"
              size="is-small"
              style="color: black"
            >
            </b-icon>
            <span v-if="statistiques.Utilisateur.Time.low.length">
              {{
                Math.round(
                  (statistiques.Utilisateur.Time.low.length /
                    statistiques.Utilisateur.Time.donnees.length) *
                    100
                )
              }}
              %
            </span>
            <span v-else> 0 % </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "modal-statistiques",
  props: ["statistiques", "disabledStats"],
  methods: {
    loadChartTime() {
      const dataHigh = [];
      dataHigh.push(
        this.statistiques.UtilisateurCabinet.Time.high.length
          ? (this.statistiques.UtilisateurCabinet.Time.high.length /
              this.statistiques.UtilisateurCabinet.Time.donnees.length) *
              100
          : 0
      );
      dataHigh.push(
        this.statistiques.UtilisateurLicence.Time.high.length
          ? (this.statistiques.UtilisateurLicence.Time.high.length /
              this.statistiques.UtilisateurLicence.Time.donnees.length) *
              100
          : 0
      );
      dataHigh.push(
        this.statistiques.UtilisateurAffiliation.Time.high.length
          ? (this.statistiques.UtilisateurAffiliation.Time.high.length /
              this.statistiques.UtilisateurAffiliation.Time.donnees.length) *
              100
          : 0
      );

      const utilisateurHigh = this.statistiques.Utilisateur.Time.high.length
        ? (this.statistiques.Utilisateur.Time.high.length /
            this.statistiques.Utilisateur.Time.donnees.length) *
          100
        : 0;
      const generalHigh = this.statistiques.General.Time.high.length
        ? (this.statistiques.General.Time.high.length /
            this.statistiques.General.Time.donnees.length) *
          100
        : 0;
      const mesDonneesTimeHigh = {
        labels: ["Cabinet", "Licence", "Affiliation"],
        datasets: [
          {
            label: "Vos utilisateurs",
            type: "line",
            data: [utilisateurHigh, utilisateurHigh, utilisateurHigh],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            label: "Global",
            type: "line",
            data: [generalHigh, generalHigh, generalHigh],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "rgb(0, 255, 255, 255)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            type: "bar",
            data: dataHigh,
            backgroundColor: ["#3aff9f", "#1780ff", "#fedd01"],
          },
        ],
      };
      const chartTimeHigh = this.$refs.chartTimeHigh;
      const ctxTimeHigh = chartTimeHigh.getContext("2d");
      new Chart(ctxTimeHigh, {
        type: "bar",
        data: mesDonneesTimeHigh,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });

      const dataMedium = [];
      dataMedium.push(
        this.statistiques.UtilisateurCabinet.Time.medium.length
          ? (this.statistiques.UtilisateurCabinet.Time.medium.length /
              this.statistiques.UtilisateurCabinet.Time.donnees.length) *
              100
          : 0
      );
      dataMedium.push(
        this.statistiques.UtilisateurLicence.Time.medium.length
          ? (this.statistiques.UtilisateurLicence.Time.medium.length /
              this.statistiques.UtilisateurLicence.Time.donnees.length) *
              100
          : 0
      );
      dataMedium.push(
        this.statistiques.UtilisateurAffiliation.Time.medium.length
          ? (this.statistiques.UtilisateurAffiliation.Time.medium.length /
              this.statistiques.UtilisateurAffiliation.Time.donnees.length) *
              100
          : 0
      );

      const utilisateurMedium = this.statistiques.Utilisateur.Time.medium.length
        ? (this.statistiques.Utilisateur.Time.medium.length /
            this.statistiques.Utilisateur.Time.donnees.length) *
          100
        : 0;
      const generalMedium = this.statistiques.General.Time.medium.length
        ? (this.statistiques.General.Time.medium.length /
            this.statistiques.General.Time.donnees.length) *
          100
        : 0;
      const mesDonneesTimeMedium = {
        labels: ["Cabinet", "Licence", "Affiliation"],
        datasets: [
          {
            label: "Vos utilisateurs",
            type: "line",
            data: [utilisateurMedium, utilisateurMedium, utilisateurMedium],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            label: "Global",
            type: "line",
            data: [generalMedium, generalMedium, generalMedium],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "rgb(0, 255, 255, 255)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            type: "bar",
            data: dataMedium,
            backgroundColor: ["#3aff9f", "#1780ff", "#fedd01"],
          },
        ],
      };
      const chartTimeMedium = this.$refs.chartTimeMedium;
      const ctxTimeMedium = chartTimeMedium.getContext("2d");
      new Chart(ctxTimeMedium, {
        type: "bar",
        data: mesDonneesTimeMedium,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });

      const dataLow = [];
      dataLow.push(
        this.statistiques.UtilisateurCabinet.Time.low.length
          ? (this.statistiques.UtilisateurCabinet.Time.low.length /
              this.statistiques.UtilisateurCabinet.Time.donnees.length) *
              100
          : 0
      );
      dataLow.push(
        this.statistiques.UtilisateurLicence.Time.low.length
          ? (this.statistiques.UtilisateurLicence.Time.low.length /
              this.statistiques.UtilisateurLicence.Time.donnees.length) *
              100
          : 0
      );
      dataLow.push(
        this.statistiques.UtilisateurAffiliation.Time.low.length
          ? (this.statistiques.UtilisateurAffiliation.Time.low.length /
              this.statistiques.UtilisateurAffiliation.Time.donnees.length) *
              100
          : 0
      );

      const utilisateurLow = this.statistiques.Utilisateur.Time.low.length
        ? (this.statistiques.Utilisateur.Time.low.length /
            this.statistiques.Utilisateur.Time.donnees.length) *
          100
        : 0;
      const generalLow = this.statistiques.General.Time.low.length
        ? (this.statistiques.General.Time.low.length /
            this.statistiques.General.Time.donnees.length) *
          100
        : 0;
      const mesDonneesTimeLow = {
        labels: ["Cabinet", "Licence", "Affiliation"],
        datasets: [
          {
            label: "Vos utilisateurs",
            type: "line",
            data: [utilisateurLow, utilisateurLow, utilisateurLow],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            label: "Global",
            type: "line",
            data: [generalLow, generalLow, generalLow],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "rgb(0, 255, 255, 255)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            type: "bar",
            data: dataLow,
            backgroundColor: ["#3aff9f", "#1780ff", "#fedd01"],
          },
        ],
      };
      const chartTimeLow = this.$refs.chartTimeLow;
      const ctxTimeLow = chartTimeLow.getContext("2d");
      new Chart(ctxTimeLow, {
        type: "bar",
        data: mesDonneesTimeLow,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
    loadChartSession() {
      const dataHigh = [];
      dataHigh.push(
        this.statistiques.UtilisateurCabinet.BpmCC.high.length
          ? (this.statistiques.UtilisateurCabinet.BpmCC.high.length /
              this.statistiques.UtilisateurCabinet.BpmCC.donnees.length) *
              100
          : 0
      );
      dataHigh.push(
        this.statistiques.UtilisateurLicence.BpmCC.high.length
          ? (this.statistiques.UtilisateurLicence.BpmCC.high.length /
              this.statistiques.UtilisateurLicence.BpmCC.donnees.length) *
              100
          : 0
      );
      dataHigh.push(
        this.statistiques.UtilisateurAffiliation.BpmCC.high.length
          ? (this.statistiques.UtilisateurAffiliation.BpmCC.high.length /
              this.statistiques.UtilisateurAffiliation.BpmCC.donnees.length) *
              100
          : 0
      );

      const utilisateurHigh = this.statistiques.Utilisateur.BpmCC.high.length
        ? (this.statistiques.Utilisateur.BpmCC.high.length /
            this.statistiques.Utilisateur.BpmCC.donnees.length) *
          100
        : 0;
      const generalHigh = this.statistiques.General.BpmCC.high.length
        ? (this.statistiques.General.BpmCC.high.length /
            this.statistiques.General.BpmCC.donnees.length) *
          100
        : 0;
      const mesDonneesSessionHigh = {
        labels: ["Cabinet", "Licence", "Affiliation"],
        datasets: [
          {
            label: "Vos utilisateurs",
            type: "line",
            data: [utilisateurHigh, utilisateurHigh, utilisateurHigh],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            label: "Global",
            type: "line",
            data: [generalHigh, generalHigh, generalHigh],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "rgb(0, 255, 255, 255)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            type: "bar",
            data: dataHigh,
            backgroundColor: ["#3aff9f", "#1780ff", "#fedd01"],
          },
        ],
      };
      const chartSessionHigh = this.$refs.chartSessionHigh;
      const ctxSessionHigh = chartSessionHigh.getContext("2d");
      new Chart(ctxSessionHigh, {
        type: "bar",
        data: mesDonneesSessionHigh,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });

      const dataMedium = [];
      dataMedium.push(
        this.statistiques.UtilisateurCabinet.BpmCC.medium.length
          ? (this.statistiques.UtilisateurCabinet.BpmCC.medium.length /
              this.statistiques.UtilisateurCabinet.BpmCC.donnees.length) *
              100
          : 0
      );
      dataMedium.push(
        this.statistiques.UtilisateurLicence.BpmCC.medium.length
          ? (this.statistiques.UtilisateurLicence.BpmCC.medium.length /
              this.statistiques.UtilisateurLicence.BpmCC.donnees.length) *
              100
          : 0
      );
      dataMedium.push(
        this.statistiques.UtilisateurAffiliation.BpmCC.medium.length
          ? (this.statistiques.UtilisateurAffiliation.BpmCC.medium.length /
              this.statistiques.UtilisateurAffiliation.BpmCC.donnees.length) *
              100
          : 0
      );

      const utilisateurMedium = this.statistiques.Utilisateur.BpmCC.medium
        .length
        ? (this.statistiques.Utilisateur.BpmCC.medium.length /
            this.statistiques.Utilisateur.BpmCC.donnees.length) *
          100
        : 0;
      const generalMedium = this.statistiques.General.BpmCC.medium.length
        ? (this.statistiques.General.BpmCC.medium.length /
            this.statistiques.General.BpmCC.donnees.length) *
          100
        : 0;
      const mesDonneesSessionMedium = {
        labels: ["Cabinet", "Licence", "Affiliation"],
        datasets: [
          {
            label: "Vos utilisateurs",
            type: "line",
            data: [utilisateurMedium, utilisateurMedium, utilisateurMedium],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            label: "Global",
            type: "line",
            data: [generalMedium, generalMedium, generalMedium],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "rgb(0, 255, 255, 255)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            type: "bar",
            data: dataMedium,
            backgroundColor: ["#3aff9f", "#1780ff", "#fedd01"],
          },
        ],
      };
      const chartSessionMedium = this.$refs.chartSessionMedium;
      const ctxSessionMedium = chartSessionMedium.getContext("2d");
      new Chart(ctxSessionMedium, {
        type: "bar",
        data: mesDonneesSessionMedium,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });

      const dataLow = [];
      dataLow.push(
        this.statistiques.UtilisateurCabinet.BpmCC.low.length
          ? (this.statistiques.UtilisateurCabinet.BpmCC.low.length /
              this.statistiques.UtilisateurCabinet.BpmCC.donnees.length) *
              100
          : 0
      );
      dataLow.push(
        this.statistiques.UtilisateurLicence.BpmCC.low.length
          ? (this.statistiques.UtilisateurLicence.BpmCC.low.length /
              this.statistiques.UtilisateurLicence.BpmCC.donnees.length) *
              100
          : 0
      );
      dataLow.push(
        this.statistiques.UtilisateurAffiliation.BpmCC.low.length
          ? (this.statistiques.UtilisateurAffiliation.BpmCC.low.length /
              this.statistiques.UtilisateurAffiliation.BpmCC.donnees.length) *
              100
          : 0
      );

      const utilisateurLow = this.statistiques.Utilisateur.BpmCC.low.length
        ? (this.statistiques.Utilisateur.BpmCC.low.length /
            this.statistiques.Utilisateur.BpmCC.donnees.length) *
          100
        : 0;
      const generalLow = this.statistiques.General.BpmCC.low.length
        ? (this.statistiques.General.BpmCC.low.length /
            this.statistiques.General.BpmCC.donnees.length) *
          100
        : 0;
      const mesDonneesSessionLow = {
        labels: ["Cabinet", "Licence", "Affiliation"],
        datasets: [
          {
            label: "Vos utilisateurs",
            type: "line",
            data: [utilisateurLow, utilisateurLow, utilisateurLow],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "black",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            label: "Global",
            type: "line",
            data: [generalLow, generalLow, generalLow],
            borderDash: [10, 5],
            pointStyle: "line",
            borderColor: "rgb(0, 255, 255, 255)",
            backgroundColor: "rgba(0, 0, 0, 0)",
            pointBackgroundColor: "rgba(51, 102, 204, 0)",
            pointBorderColor: "rgba(51, 102, 204, 0)",
          },
          {
            type: "bar",
            data: dataLow,
            backgroundColor: ["#3aff9f", "#1780ff", "#fedd01"],
          },
        ],
      };
      const chartSessionLow = this.$refs.chartSessionLow;
      const ctxSessionLow = chartSessionLow.getContext("2d");
      new Chart(ctxSessionLow, {
        type: "bar",
        data: mesDonneesSessionLow,
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
        },
      });
    },
  },
  mounted() {
    if (this.statistiques) {
      this.loadChartTime();
      this.loadChartSession();
    }
  },
  watch: {
    statistiques() {
      this.loadChartTime();
      this.loadChartSession();
    },
    disabledStats() {
      if (this.statistiques && !this.disabledStats) {
        setTimeout(() => {
          this.loadChartTime();
          this.loadChartSession();
        }, 200);
      }
    },
  },
};
</script>
