// authService.js
import Parse from 'parse';
import ParseConfig from "@/api/parse.config";

const AuthService = {
  async generateToken(userId) {
    ParseConfig();
    try {
      console.log("Test connexion..");
      // Appel de la fonction Cloud pour générer le lien de connexion
      const response = await Parse.Cloud.run("generateLoginToken", {
        userId,
      });

      // Récupération du lien généré
      const loginLink = response.link;

      // Afficher le lien ou l'envoyer par email via un service backend
      console.log("Lien de connexion généré:", loginLink);

      return loginLink;
      // Vous pouvez ici envoyer le lien par email à l'utilisateur via votre backend ou API mail
    } catch (error) {
      console.error(
        "Erreur lors de la génération du lien de connexion:",
        error.message
      );
    }
  },

  recupToken() {
    // Récupérer l'URL complète incluant les fragments (après le #)
    const hashParams = window.location.hash.split("?")[1];

    // Si des paramètres existent après le #
    if (hashParams) {
      const urlParams = new URLSearchParams(hashParams);
      const token = urlParams.get("token");
      console.log("Token récupéré:", token);
      if (token) {
        var response = this.getSessionToken(token);
        return response;
      } else {
        console.error("Token introuvable dans l'URL.");
      }
    } else {
      console.error("Aucun paramètre trouvé après le #.");
    }
  },

//   async authenticateUser(token) {
//     ParseConfig();

//     try {
//       // Appel de la fonction Cloud pour authentifier l'utilisateur avec le token
//       const response = await Parse.Cloud.run("authenticateWithToken", {
//         token,
//       });
//       console.log("Réponse reçue:", response);
//       const tokenResponse = response.sessionToken;

//       // Chaînage des fonctions asynchrones
//       await Parse.User.logOut();
//       console.log("Utilisateur déconnecté.");
//       console.log("Authentification de l'utilisateur avec le token...");
//       console.log("Token:", tokenResponse);
//       await Parse.User.become(tokenResponse);
//       console.log("Utilisateur authentifié avec succès.");
//       console.log("Utilisateur actuel:", Parse.User.current());
//     } catch (error) {
//       console.error(
//         "Erreur lors de l'authentification de l'utilisateur:",
//         error.message
//       );
//     }
//   },

  async getSessionToken(token) {
    ParseConfig();
    try {
        console.log("GetSESSIONTOKEN..", token);
        // Appel de la fonction Cloud pour authentifier l'utilisateur avec le token
        const response = await Parse.Cloud.run("authenticateWithToken", {
          token,
        });
        console.log("Réponse reçue:", response);
        const tokenResponse = response.sessionToken;
        const MailResponse = response.mail;
        return { tokenResponse, MailResponse };

    }
    catch (error) {
      console.error(
        "Erreur lors de l'authentification de l'utilisateur:",
        error.message
      );
    }
  }
};

export default AuthService;
