export const EDIT_USER = "EDIT_USER";
export const EDIT_ERROR = "EDIT_ERROR";
export const EDIT_ABONNEMENT = "EDIT_ABONNEMENT";
export const EDIT_USER_GROUP = "EDIT_USER_GROUP";
export const EDIT_FIRST_CONNECTION = "EDIT_FIRST_CONNECTION";
export const EDIT_ABONNEMENT_TEMP = "EDIT_ABONNEMENT_TEMP";
export const EDIT_HEADINGS = "EDIT_HEADINGS";
export const EDIT_SAUVEGARDE_UTILISATEUR = "EDIT_SAUVEGARDE_UTILISATEUR";
export const EDIT_FOLDERS = "EDIT_FOLDERS";
export const EDIT_MODAL_TABLETTE = "EDIT_MODAL_TABLETTE";
export const EDIT_CGU = "EDIT_CGU";
export const EDIT_PAYANT = "EDIT_PAYANT";
