import CryptoJS from "crypto-js";
import getHashKey from "./getHasKey.js";

export default function calculateMAC(data, cle) {
  var key = getHashKey(cle);
  // Utilisez enc.Latin1 pour encoder la clé
  key = CryptoJS.enc.Latin1.parse(key);
  // Créez le HMAC avec sha1 et la clé
  return CryptoJS.HmacSHA1(data, key).toString(CryptoJS.enc.Hex);
}
