import Parse from 'parse';

export async function getUserFromTherapeute(groupTherapeute,therapeute) {
    const query = new Parse.Query("User");
    const queryGroup = new Parse.Query("user_group");
    queryGroup.equalTo('objectId', groupTherapeute.id);
    // récupérer le groupe
    var group = await queryGroup.first();
    console.log('Groupe trouvé:', group)
    // //console.log('Groupe trouvé:', group)
    query.matchesQuery('group', queryGroup);
    //le champ thérapeute doit être false
    if(therapeute)
    {
    query.equalTo('therapeute', false);
    }
    else
    {
    query.equalTo('therapeute', true);
    }
    //cela ne doit pas être "cabinet" comme type de pratique
    query.notEqualTo('type_pratique', 'cabinet');
    const user = await query.find();
    return user;
}