import Parse from "parse";
import moment from "moment";
import ParseConfig from "@/api/parse.config.js";
import codeGenerator from "@/services/general/codeGenerator.js";

export async function createUser(user, UserGroup, linksToAdd, User) {
  ParseConfig();
  Parse.masterKey = "myMasterKey";

  const UserObject = Parse.Object.extend("User");
  //  const UserGroupObject = Parse.Object.extend("user_group");

  let password = codeGenerator("xxxxxxxx");
  let pratique = user.typePratique;
  let proposition_affiliation = false;
  let dureeAcces = user.dureeAcces;
  let affiliation = null;
  let date_invitation_affiliation = null;

  let isNewUser = false;
  if (!User) {
    isNewUser = true;
    User = new UserObject();
  }

  if (pratique === "affiliation") {
    pratique = "cabinet";
    User.set("cgu", false);
    proposition_affiliation = true;
    date_invitation_affiliation = new Date();
    affiliation = await Parse.User.current();
  } else if (pratique === "licenceEdit") {
    pratique = "licence";
  } else if (pratique === "cabinet" || pratique === "cabinet_licence") {
    User.set("cgu", false);
  }
  if (pratique === "cabinet" || pratique === "cabinet_licence") {
    if (!UserGroup.attributes.password_cabinet) {
      UserGroup.set("password_cabinet", password);
      await UserGroup.save();
    } else {
      password = UserGroup.attributes.password_cabinet;
    }
    User.set("fin_acces_plateforme", null);
    User.set("date_debut_licence", null);
    dureeAcces = null;
  } else if (pratique === "licence") {
    if (isNewUser) {
      User.set("cgu", false);
    } else {
      User.set("cgu", user.cgu);
    }
    if (!user.date_debut_licence) {
      User.set("fin_acces_plateforme", moment().add(dureeAcces, "M").toDate());
      User.set("date_debut_licence", moment().toDate());
    } else {
      User.set(
        "fin_acces_plateforme",
        moment(user.date_debut_licence).add(dureeAcces, "M").toDate()
      );
    }
  }

  var username = null;
  var email = null;

  if (user.mail) {
    username = user.mail.trim();
    email = user.mail.trim();
  } else {
    username = user.nom + user.prenom;
    email = user.nom + "@" + user.prenom;
  }
  var dt = new Date();
  User.set("lastname", user.nom);
  User.set("firstname", user.prenom);
  User.set("email", email);
  User.set("username", username);
  if (user.exos_personnaliser === true) {
    User.set("exos_personnaliser", user.exos_personnaliser);
  } else {
    User.set("exos_personnaliser", false);
  }
  User.set("duree_acces", dureeAcces);
  User.set("proposition_affiliation", proposition_affiliation);
  User.set("affiliation", affiliation);
  User.set("date_invitation_affiliation", date_invitation_affiliation);
  User.set("withdrawal_credit_date", dt);
  if (user.acces_donnees_therapeute === undefined) {
    User.set("acces_donnees_therapeute", false);
  }

  if (!(User.attributes.type_pratique === "licence")) {
    User.set("password", password);
  }

  if (pratique === "cabinet_licence") {
    User.set("type_pratique", "cabinet");
  } else {
    User.set("type_pratique", pratique);
  }

  if (isNewUser) {
    User.set("civilite", user.civilite);
    User.set("therapeute", user.therapeute);
    User.set("profession", user.profession);
    User.set("adresse", user.adresse);
    User.set("complement_adresse", user.complementAdresse);
    User.set("code_postal", user.codePostal);
    User.set("ville", user.ville);
    User.set("telephone_pro", user.telephone_pro);
    User.set("email", email);
    User.set("username", username);
    User.set("adeli", user.adeli);
    User.set("siret", user.siret);
    User.set("group", UserGroup);
    if (user.exos_personnaliser === true) {
      User.set("exos_personnaliser", user.exos_personnaliser);
    } else {
      User.set("exos_personnaliser", undefined);
    }
  }

  const RelationLinkFromUser = User.relation("link");

  let QueryLinkFromRelation = RelationLinkFromUser.query();
  QueryLinkFromRelation.limit(1000);
  const ResponseLinkFromRelation = await QueryLinkFromRelation.find();
  ResponseLinkFromRelation.forEach((link) => {
    RelationLinkFromUser.remove(link);
  });

  var listeLinkId = [];
  for (var i = 0; i < linksToAdd.length; i = i + 1) {
    listeLinkId.push(linksToAdd[i].id || linksToAdd[i].objectId);
  }

  var queryLink = new Parse.Query("link");
  queryLink.containedIn("objectId", listeLinkId);
  queryLink.limit(100000);
  var listeLinkAAjouter = await queryLink.find();

  for (i = 0; i < listeLinkAAjouter.length; i = i + 1) {
    RelationLinkFromUser.add(listeLinkAAjouter[i]);
  }

  return await User.save({}, { useMasterKey: true });
}
