var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-container"},[_c('b-field',{staticClass:"field-paiement",attrs:{"grouped":""}},[_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small","expanded":"","type":{ 'is-danger': !_vm.nom && _vm.error },"message":{
        'Veuillez renseigner un nom.': !_vm.nom && _vm.error,
      }}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Nom"},model:{value:(_vm.nom),callback:function ($$v) {_vm.nom=$$v},expression:"nom"}})],1),_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small","expanded":"","type":{ 'is-danger': !_vm.prenom && _vm.error },"message":{
        'Veuillez renseigner un prénom': !_vm.prenom && _vm.error,
      }}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Prénom"},model:{value:(_vm.prenom),callback:function ($$v) {_vm.prenom=$$v},expression:"prenom"}})],1)],1),_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small","type":{ 'is-danger': !_vm.adresse && _vm.error },"message":{
      'Veuillez renseigner une adresse.': !_vm.adresse && _vm.error,
    }}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Adresse de livraison"},model:{value:(_vm.adresse),callback:function ($$v) {_vm.adresse=$$v},expression:"adresse"}})],1),_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small"}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Complément d'adresse"},model:{value:(_vm.complementAdresse),callback:function ($$v) {_vm.complementAdresse=$$v},expression:"complementAdresse"}})],1),_c('b-field',{staticClass:"field-paiement",attrs:{"grouped":""}},[_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small","expanded":"","type":{ 'is-danger': !_vm.ville && _vm.error },"message":{
        'Veuillez renseigner une ville.': !_vm.ville && _vm.error,
      }}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Ville"},model:{value:(_vm.ville),callback:function ($$v) {_vm.ville=$$v},expression:"ville"}})],1),_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small","expanded":"","type":{ 'is-danger': !_vm.codePostal && _vm.error },"message":{
        'Veuillez renseigner un code postal.': !_vm.codePostal && _vm.error,
      }}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Code postal"},model:{value:(_vm.codePostal),callback:function ($$v) {_vm.codePostal=$$v},expression:"codePostal"}})],1)],1),_c('b-field',{staticClass:"field-paiement",attrs:{"custom-class":"is-small"}},[_c('b-input',{attrs:{"custom-class":"is-small","placeholder":"Code d'accès à la résidence"},model:{value:(_vm.codeAcces),callback:function ($$v) {_vm.codeAcces=$$v},expression:"codeAcces"}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }