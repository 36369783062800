<template>
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <h1 class="title is-size-4">
          {{ $t("Gestion des actualites") }}
        </h1>
        <p class="has-text-black is-margin-b-10">
          {{ $t("texteInformationPageActualite") }}
        </p>
        <a class="button is-cyan is-margin-b-10" @click="openModal()">
          {{ $t("ajouterActualite") }}&nbsp;&nbsp;
          <b-icon pack="fas" icon="plus" size=""></b-icon>
        </a>
        <b-table
          v-if="newsInMyGroup.length"
          :data="newsInMyGroup"
          :bordered="true"
          :narrowed="true"
          :striped="true"
        >
          <template slot-scope="props" slot="header">
            <div>{{ props.column.label }}</div>
          </template>

          <template slot-scope="props">
            <b-table-column field="title" label="Titre">
              {{ props.row.title }}
            </b-table-column>
            <b-table-column label="Date de création">
              {{ new Date(props.row.createdAt).toLocaleDateString() }}
            </b-table-column>
            <b-table-column label="Publié">
              <div class="has-text-centered">
                <b-icon
                  v-if="props.row.publie"
                  pack="fas"
                  icon="circle"
                  type="is-success"
                >
                </b-icon>
                <b-icon v-else pack="fas" icon="circle" type="is-danger">
                </b-icon>
              </div>
            </b-table-column>
            <b-table-column label="Importance">
              <div class="has-text-centered">
                <b-icon
                  pack="fa"
                  icon="circle"
                  size="is-small"
                  :style="getCategoryColor(props.row.Category)"
                ></b-icon>
              </div>
            </b-table-column>
            <b-table-column
              field="modifier"
              class="has-text-centered table-button"
            >
              <a @click="openModal(props.row)">
                <b-icon
                  pack="fas"
                  icon="edit"
                  class="is-margin-l-10 is-margin-r-10 has-text-black"
                ></b-icon>
              </a>
              <a
                @click="
                  (isModalDeleteNewsOpen = true),
                    (newsTemporarySave = props.row)
                "
              >
                <b-icon
                  pack="fas"
                  icon="trash-alt"
                  class="is-margin-l-10 is-margin-r-10 has-text-black"
                ></b-icon>
              </a>
            </b-table-column>
          </template>
        </b-table>
        <p v-else>{{ $t("aucuneActualite") }}</p>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardNewsOpen"
      @close="isCardNewsOpen = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div class="overflow">
              <h3 class="title has-text-black is-size-5">
                {{ $t("actualite") }}
              </h3>
              <hr />
              <form class="scroll-modal">
                <b-field
                  label="Titre de l'actualité :"
                  :type="{ 'is-danger': !actualite.title && error }"
                  :message="{
                    'Veuillez saisir un titre.': !actualite.title && error,
                  }"
                >
                  <b-input v-model="actualite.title"></b-input>
                </b-field>
                <div class="field">
                  <b-switch v-model="actualite.publie">
                    <span v-if="actualite.publie">{{ $t("publier") }}</span>
                    <span v-else>{{ $t("nePasPublier") }}</span>
                  </b-switch>
                </div>
                <div class="columns">
                  <div class="column">
                    <p>Article pour les utilisateurs :</p>
                    <div class="field">
                      <b-checkbox
                        v-model="type_pratique"
                        native-value="cabinet"
                      >
                        {{ $t("utilisateursCabinet") }}
                      </b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox
                        v-model="type_pratique"
                        native-value="licence"
                      >
                        {{ $t("utilisateursLicence") }}
                      </b-checkbox>
                    </div>
                    <div class="field">
                      <b-checkbox
                        v-model="type_pratique"
                        native-value="affilié"
                      >
                        {{ $t("utilisateursAffilie") }}
                      </b-checkbox>
                    </div>
                    <p
                      v-if="!type_pratique.length"
                      class="is-size-7 has-text-danger"
                    >
                      Veuillez sélectionner au moins un type d'utilisateur sinon
                      l'actualité ne sera pas visible.
                    </p>
                  </div>
                  <div class="column">
                    <p>Dégré d'importance :</p>
                    <section>
                      <b-field>
                        <b-radio
                          v-model="degreImportance"
                          native-value=""
                          checked
                        >
                          Annonce Primaire
                        </b-radio>
                      </b-field>
                      <b-field>
                        <b-radio
                          v-model="degreImportance"
                          native-value="is-warning"
                          type="is-warning"
                        >
                          Annonce Importante
                        </b-radio>
                      </b-field>
                      <b-field>
                        <b-radio
                          v-model="degreImportance"
                          native-value="is-danger"
                          type="is-danger"
                        >
                          Annonce Urgente
                        </b-radio>
                      </b-field>
                    </section>
                  </div>
                </div>

                <b-field label="Description :">
                  <quill-editor
                    :options="editorOption"
                    v-model="actualite.text"
                  >
                  </quill-editor>
                </b-field>
                <b-field label="Ajouter une image :">
                  <input type="file" @change="onFileResourceChange" />
                </b-field>
                <div v-if="actualite.resource" class="img-logo">
                  <img
                    v-if="actualite.resource._url"
                    :src="actualite.resource._url"
                  />
                  <img v-else :src="actualite.resource.url" />
                </div>
                <br />
                <b-field label="Ajouter une fiche :">
                  <input type="file" @change="onFilePieceJointeChange" />
                </b-field>
                <div v-if="actualite.piecejointe && actualite.piecejointe._url">
                  {{ actualite.piecejointe._url }}
                </div>
                <div
                  v-else-if="
                    actualite.piecejointe && actualite.piecejointe.name
                  "
                >
                  {{ actualite.piecejointe.name }}
                </div>
              </form>
            </div>
            <hr />
            <a
              class="button is-pulled-right is-cyan"
              @click="editOrCreateNews()"
            >
              {{ $t("valider") }}
            </a>
            <a
              class="button is-pulled-right is-grey is-margin-r-10"
              @click="isCardNewsOpen = false"
            >
              {{ $t("annuler") }}
            </a>
            <br />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="isModalDeleteNewsOpen"
      @close="isModalDeleteNewsOpen = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-5">
              {{ $t("actualite") }}
            </h3>
            <hr />
            <p>{{ $t("confirmationSuppresionActualite") }}</p>
            <hr />
            <div class="is-margin-b-50">
              <a class="button is-pulled-right is-cyan" @click="deleteNews()">
                {{ $t("supprimerActualite") }}
              </a>
              <a
                class="button is-pulled-right is-grey is-margin-r-10"
                @click="isModalDeleteNewsOpen = false"
              >
                {{ $t("annuler") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Parse from "parse";
import { createNamespacedHelpers } from "vuex";
import ParseConfig from "@/api/parse.config.js";
import { getObjectById } from "@/services/parse/getObject.js";

const User = createNamespacedHelpers("user");

export default {
  name: "actualites",
  data() {
    return {
      newsInMyGroup: [],
      editorOption: {
        placeholder: "",
        theme: "snow",
      },
      actualite: {},
      isCardNewsOpen: false,
      userGroup: null,
      error: false,
      isModalDeleteNewsOpen: false,
      newsTemporarySave: {},
      type_pratique: [],
      degreImportance: "",
    };
  },
  computed: {
    ...User.mapGetters(["group"]),
  },
  methods: {
    getCategoryColor(Category) {
      if (Category === "is-warning") {
        return { color: "#FFDD57" };
      } else if (Category === "is-danger") {
        return { color: "#FF3860" };
      } else {
        return { color: "#DBDBDB" };
      }
    },
    async loadNews() {
      this.newsInMyGroup = [];
      const loadingComponent = this.$buefy.loading.open();
      ParseConfig();

      this.userGroup = await getObjectById("user_group", this.group.objectId);

      const NewsObject = Parse.Object.extend("news");
      const NewsQuery = new Parse.Query(NewsObject);
      NewsQuery.equalTo("group", this.userGroup);
      const NewsResults = await NewsQuery.find();
      NewsResults.forEach((news) => {
        this.newsInMyGroup.push({
          id: news.id,
          title: news.attributes.title,
          text: news.attributes.text,
          publie: news.attributes.publie,
          resource: news.attributes.resource,
          type_pratique: news.attributes.type_pratique,
          piecejointe: news.attributes.piecejointe,
          createdAt: news.createdAt,
          Category: news.attributes.Category,
        });
      });
      this.newsInMyGroup.sort((a, b) => {
        if (a.createdAt > b.createdAt) return -1;
        return 1;
      });
      loadingComponent.close();
    },
    openModal(actualite) {
      this.actualite = {};
      if (actualite && actualite.id) {
        this.actualite = actualite;
        if (!this.actualite.publie) {
          this.actualite.publie = false;
        }
        this.type_pratique = this.actualite.type_pratique;
        this.degreImportance = this.actualite.Category;
      } else {
        this.actualite.publie = true;
        this.type_pratique = ["cabinet", "licence", "affilié"];
        this.degreImportance = "";
      }
      this.isCardNewsOpen = true;
    },
    async editOrCreateNews() {
      this.error = false;
      if (this.actualite && !this.actualite.title) {
        this.error = true;
        return;
      }
      const loadingComponent = this.$buefy.loading.open();
      let News = null;
      if (this.actualite.id) {
        News = await this.findNewsById(this.actualite.id);
      } else {
        const NewsObject = Parse.Object.extend("news");
        News = new NewsObject();
        News.set("group", this.userGroup);
      }
      News.set("title", this.actualite.title);
      News.set("text", this.actualite.text);
      News.set("publie", this.actualite.publie);
      News.set("type_pratique", this.type_pratique);
      News.set("Category", this.degreImportance);
      if (
        this.actualite.resource &&
        this.actualite.resource.file &&
        this.actualite.resource.name &&
        this.actualite.resource.file.type
      ) {
        const fileResource = new Parse.File(
          "ressouce",
          this.actualite.resource.file,
          this.actualite.resource.file.type
        );
        const fileResourceSave = await fileResource.save();
        News.set("resource", fileResourceSave);
      }
      if (
        this.actualite.piecejointe &&
        this.actualite.piecejointe.name &&
        this.actualite.piecejointe.file &&
        this.actualite.piecejointe.file.type
      ) {
        const filePieceJointe = new Parse.File(
          "piecejointe",
          this.actualite.piecejointe.file,
          this.actualite.piecejointe.file.type
        );
        const filePieceJointeSave = await filePieceJointe.save();
        News.set("piecejointe", filePieceJointeSave);
      }
      await News.save();
      loadingComponent.close();
      this.isCardNewsOpen = false;
      location.reload();
      //await this.loadNews();
    },
    async deleteNews() {
      const loadingComponent = this.$buefy.loading.open();
      const News = await this.findNewsById(this.newsTemporarySave.id);
      News.destroy();
      loadingComponent.close();
      this.isModalDeleteNewsOpen = false;
      await this.loadNews();
    },
    async findNewsById(id) {
      const NewsObject = Parse.Object.extend("news");
      const NewsQuery = new Parse.Query(NewsObject);
      NewsQuery.equalTo("objectId", id);
      return await NewsQuery.first();
    },
    onFileResourceChange(e) {
      const file = e.target.files[0];
      this.actualite.resource = {};
      this.actualite.resource.url = URL.createObjectURL(file);
      this.actualite.resource.name = file.name;
      this.actualite.resource.file = file;
    },
    onFilePieceJointeChange(e) {
      const file = e.target.files[0];
      this.actualite.piecejointe = {};
      this.actualite.piecejointe.url = URL.createObjectURL(file);
      this.actualite.piecejointe.name = file.name;
      this.actualite.piecejointe.file = file;
    },
  },
  async mounted() {
    await this.loadNews();
    ParseConfig();

    this.userGroup = await getObjectById("user_group", this.group.objectId);

    const NewsObject = Parse.Object.extend("news");
    const NewsQuery = new Parse.Query(NewsObject);
    NewsQuery.equalTo("group", this.userGroup);
    const NewsResults = await NewsQuery.find();
    for (let i = 0; i < NewsResults.length; i++) {
      this.degreImportance = NewsResults[i].attributes.Category;
    }
  },
};
</script>

<style></style>
