<template>
  <div
    class="is-max-desktop is-fullhd full-h full-w flex-center_gap-5 color-grey"
  >
    <img
      src="../../../assets/img/Logos_Symbiofi_SymbioCenter_PRO_vectoriser.png"
    />
    <div class="box flex-center h-200">
      <h3 class="title has-text-primary">
        Vous venez d'accepter le suivi de vos activités par le therapeute !
      </h3>
      <div class="buttons">
        <b-button
          @click="goToActivity()"
          type="is-primary"
          class="button is-primary"
          >Connectez-vous</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export default {
  name: "AcceptSuivi",
  data() {
    return {};
  },
  computed: {},
  methods: {
    goToActivity() {
      window.location = "https://www.symbiocenter.com/";
    },
    async getUserToSetChu() {
      ParseConfig();
      Parse.masterKey = "myMasterKey";
      const userObject = Parse.Object.extend("_User");
      const userQuery = new Parse.Query(userObject);
      userQuery.equalTo("email", this.$route.query.email);
      const userFind = await userQuery.first();
      userFind.set("acces_donnees_therapeute", true);
      await userFind.save({}, { useMasterKey: true });
    },
  },
  mounted() {
    this.getUserToSetChu();
  },
};
</script>

<style scoped lang="scss">
.h-200 {
  height: 200px;
}

.full-h {
  height: 100vh;
}

.full-w {
  width: 100vw;
}

.title {
  text-align: center;
}

.flex-center {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_gap-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5em;
  }
}

.color-grey {
  background-color: #e8e8e8;
}

.btn-connexion {
  width: 200px;
}
</style>
