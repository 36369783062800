<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  created() {
    localStorage.removeItem("idUserForSave");
  },
};
</script>
<style lang="scss">
@import "~bulma/sass/utilities/_all";

@import "~bulma-extensions/bulma-steps/dist/css/bulma-steps.min.css";
@import "assets/style/color";
@import "assets/style/navbar";
@import "assets/style/sidebar";
@import "assets/style/table";

@import "~bulma";

@import "assets/style/box";
@import "assets/style/general";
@import "assets/style/modal";
@import "assets/style/button";
@import "assets/style/folders";
@import "assets/style/utilisateur";
@import "assets/style/dashboard";

@import "~buefy/src/scss/buefy";
@import "~buefy/dist/buefy.css";
</style>
