<template>
  <div>
    <canvas ref="chartBrique" height="225px" width="360px"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: ["data"],
  name: "chart-brique-user",
  methods: {
    loadChart() {
      const mesDonnees = {
        labels: ["Utilisateur"],
        datasets: [
          {
            label: "Avancement",
            data: this.data,
            backgroundColor: ["rgb(77, 160, 255)"],
            order: 1,
          },
        ],
      };
      var chartBrique = this.$refs.chartBrique;
      var ctx = chartBrique.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: mesDonnees,
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 100,
                },
              },
            ],
          },
          cutoutPercentage: 75,
        },
      });
    },
  },
  mounted() {
    this.loadChart();
  },
};
</script>
