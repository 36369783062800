import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { addUserToRole } from "@/services//utilisateurs/addUserToRole.js";
import { getObjectById } from "@/services/parse/getObject.js";
import { createUser } from "@/services/utilisateurs/createUser.js";

export async function createSubscriber(
  newTemporaryUser,
  group,
  linksToAdd,
  foldersBrique,
  User
) {
  ParseConfig();
  var userCourant = await Parse.User.current();
  let EstSousLicence = false;
  // const ObjectVerrouillageEtape = Parse.Object.extend("verrouillage_etape");
  if (User) {
    if (User.attributes.type_pratique === "licence") {
      EstSousLicence = true;
    }
  }
  const UserGroup = await getObjectById("user_group", group.objectId);

  const subscriberCreate = await createUser(
    newTemporaryUser,
    UserGroup,
    linksToAdd,
    User
  );
  // asyncForEach(foldersBrique, async (brique) => {
  //   asyncForEach(brique.protocoles, async (protocole) => {
  //     const NewVerrouillageEtape = new ObjectVerrouillageEtape();
  //     const QueryFolder = new Parse.Query(ObjectFolder);
  //     QueryFolder.equalTo("objectId", protocole.id);
  //     const ResponseFolder = await QueryFolder.first();

  //     NewVerrouillageEtape.set("User", subscriberCreate);
  //     NewVerrouillageEtape.set("verrouille", protocole.verrouillage_etape);
  //     NewVerrouillageEtape.set("folder", ResponseFolder);
  //     await NewVerrouillageEtape.save();
  //   });
  // });
  if (!User) {
    await addUserToRole(subscriberCreate, "Subscribers");

    if (newTemporaryUser.typePratique === "licence") {
      await Parse.Cloud.run("pratiqueSousLicence", {
        destinataire: subscriberCreate.attributes.username,
        utilisateur:
          subscriberCreate.attributes.firstname +
          " " +
          subscriberCreate.attributes.lastname,
        password: subscriberCreate.attributes.password,
        therapeute:
          userCourant.attributes.firstname +
          " " +
          userCourant.attributes.lastname,
        dureeAcces: subscriberCreate.attributes.duree_acces,
      });
    } else if (newTemporaryUser.typePratique === "affiliation") {
      await Parse.Cloud.run("propositionAffiliation", {
        destinataire: subscriberCreate.attributes.username,
        utilisateur:
          subscriberCreate.attributes.firstname +
          " " +
          subscriberCreate.attributes.lastname,
        codeAffiliation: UserGroup.attributes.code_affiliation,
        therapeute:
          userCourant.attributes.firstname +
          " " +
          userCourant.attributes.lastname,
      });
    }
  } else if (User) {
    if (User.attributes.type_pratique === "licence") {
      if (!EstSousLicence) {
        await Parse.Cloud.run("pratiqueSousLicence", {
          destinataire: subscriberCreate.attributes.username,
          utilisateur:
            subscriberCreate.attributes.firstname +
            " " +
            subscriberCreate.attributes.lastname,
          password: subscriberCreate.attributes.password,
          therapeute:
            userCourant.attributes.firstname +
            " " +
            userCourant.attributes.lastname,
          dureeAcces: subscriberCreate.attributes.duree_acces,
        });
      } else {
        await Parse.Cloud.run("modificationInformationUtilisateurLicence", {
          destinataire: User.attributes.username,
          therapeute:
            userCourant.attributes.firstname +
            " " +
            userCourant.attributes.lastname,
          utilisateur:
            User.attributes.firstname + " " + User.attributes.lastname,
          dureeAcces: User.attributes.duree_acces,
        });
      }
    }
  }
}
