import Parse from "parse";

export async function getTherapeuteFromUser(user) {
  console.log("user", user);
  if (!user.attributes.therapeute) {
    let groupId = user.attributes.group.id;
    const UserGroupQuery = new Parse.Query("user_group");
    UserGroupQuery.equalTo("objectId", groupId);
    const userQuery = new Parse.Query("User");
    userQuery.matchesQuery("group", UserGroupQuery);
    userQuery.equalTo("therapeute", true);
    const therapeute = await userQuery.first();

    // Vérifiez si l'objet therapeute existe
    if (therapeute) {
      const lastLogin = therapeute.attributes.derniere_connexion;
      const now = new Date();
      const timeDifferenceMinutes = Math.floor((now - lastLogin) / (1000 * 60));
      const isConnectedRecently = timeDifferenceMinutes <= 15;

      const therapeuteInfo = {
        id: therapeute.id,
        name: therapeute.attributes.lastname + " " + therapeute.attributes.firstname,
        isConnectedRecently: isConnectedRecently,
        mail: therapeute.attributes.username,
        meeting_availabilites: therapeute.attributes.meeting_availabilites,
      };

      console.log(therapeuteInfo);
      return therapeuteInfo;
    }
  } else {
    console.log("User récupéré:", user);
    const lastLogin = user.attributes.derniere_connexion;
    const now = new Date();
    const timeDifferenceMinutes = Math.floor((now - lastLogin) / (1000 * 60));
    const isConnectedRecently = timeDifferenceMinutes <= 15;

    const therapeuteInfo = {
      id: user.id,
      name: user.attributes.lastname + " " + user.attributes.firstname,
      isConnectedRecently: isConnectedRecently,
      mail: user.attributes.username,
    };

    return therapeuteInfo;
  }
}
