import Parse from 'parse';

export async function sendMessage(newMessage, conversationId) {
    try {
        const message = new Parse.Object("message");
        message.set("text", newMessage);
        message.set("sender", Parse.User.current());
        message.set("seen", false);

        const conversationQuery = new Parse.Query("conversation");
        conversationQuery.equalTo("objectId", conversationId);
        console.log("conversationId", conversationId);
        let conversation = await conversationQuery.first();

        if (!conversation) {
            throw new Error("Conversation not found"); // Gérez le cas où la conversation n'est pas trouvée
        }

        message.set("conversation", conversation);

        await message.save();
    } catch (error) {
        console.error("Error while sending message: ", error);
        throw error; // Propagez l'erreur pour une gestion plus avancée au niveau supérieur si nécessaire
    }
}
