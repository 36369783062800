var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.selectedNotification)?_c('div',{staticClass:"popup-container"},[_c('div',{staticClass:"popup-content"},[_c('span',{staticClass:"close-icon",on:{"click":_vm.closePopup}},[_c('i',{staticClass:"fas fa-times"})]),_c('div',{staticClass:"header-section"},[_vm._m(0),_c('span',{staticClass:"date-text"},[_vm._v(_vm._s(" " + _vm.formatDate(_vm.selectedNotification.attributes.date_event)))])]),_c('div',{staticClass:"details-grid"},[_c('div',{staticClass:"grid-item full-width"},[_c('i',{staticClass:"fas fa-info-circle"}),_c('strong',[_vm._v("Description :")]),(_vm.selectedNotification.attributes.type === 'Questionnaire')?_c('span',[_vm._v(_vm._s(" " + _vm.formatDescription(_vm.selectedNotification.attributes.description)))]):(_vm.selectedNotification.attributes.type === 'Questionnaire BFP')?_c('span',[_vm._v(_vm._s(' ' + _vm.selectedNotification.attributes.description))]):(
            _vm.selectedNotification.attributes.type === 'Visio-conférence'
          )?_c('span',[_vm._v(_vm._s(" Demande de " + _vm.selectedNotification.attributes.type + " crée par " + _vm.formatUser(_vm.selectedNotification.attributes.user) + " avec vous le " + _vm.formatDatePlus( _vm.selectedNotification.attributes.meeting_event.attributes .date_meeting )))]):_vm._e()]),_c('div',{staticClass:"grid-item"},[_c('i',{staticClass:"fas fa-tag"}),_c('strong',[_vm._v("Type :")]),_c('span',[_vm._v(_vm._s(_vm.selectedNotification.attributes.type || "Inconnu"))])]),_c('div',{staticClass:"grid-item"},[_c('i',{staticClass:"fas fa-user"}),_c('strong',[_vm._v("Utilisateur :")]),_c('span',[_vm._v(_vm._s(" " + _vm.formatUser(_vm.selectedNotification.attributes.user)))])])]),(_vm.selectedNotification.attributes.type === 'Questionnaire BFP')?_c('div',{staticClass:"Placement-Redirect-Button"},[_c('button',{staticClass:"redirect-button",on:{"click":function($event){return _vm.redirigerVersRelecture(_vm.selectedNotification.attributes.user)}}},[_vm._v("Voir le Résultat")])]):_vm._e(),(_vm.selectedNotification.attributes.type === 'Visio-conférence')?_c('div',[(
          _vm.selectedNotification.attributes.type === 'Visio-conférence' &&
          _vm.selectedNotification.attributes.meeting_event.attributes
            .is_accepted === undefined &&
          _vm.isTherapeute
        )?_c('div',{staticClass:"action-buttons"},[_c('button',{staticClass:"action-button accept",on:{"click":function($event){return _vm.acceptMeeting(_vm.selectedNotification)}}},[_c('i',{staticClass:"fas fa-check-circle"}),_vm._v(" Valider le rendez-vous ")]),_c('button',{staticClass:"action-button decline",on:{"click":function($event){return _vm.declineMeeting(_vm.selectedNotification)}}},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" Refuser le rendez-vous ")])]):_vm._e(),(
          _vm.selectedNotification.attributes.type === 'Visio-conférence' &&
          _vm.selectedNotification.attributes.meeting_event.attributes
            .is_accepted !== undefined &&
          _vm.isTherapeute
        )?_c('div',{staticClass:"meeting-status"},[_c('i',{class:[
            'fas',
            _vm.selectedNotification.attributes.meeting_event.attributes
              .is_accepted
              ? 'fa-check-circle'
              : 'fa-times-circle',
          ]}),_vm._v(" "+_vm._s(_vm.selectedNotification.attributes.meeting_event.attributes.is_accepted ? "Rendez-vous accepté" : "Rendez-vous refusé")+" ")]):_vm._e(),(
          _vm.selectedNotification.attributes.meeting_event.attributes
            .is_accepted === undefined && !_vm.isTherapeute
        )?_c('div',{staticClass:"meeting-status"},[_c('i',{staticClass:"fas fa-hourglass-half"}),_vm._v(" Rendez-vous en attente ")]):_vm._e(),(
          !_vm.isTherapeute &&
          _vm.selectedNotification.attributes.meeting_event.attributes
            .is_accepted !== undefined
        )?_c('div',{staticClass:"meeting-status"},[_c('i',{class:[
            'fas',
            _vm.selectedNotification.attributes.meeting_event.attributes
              .is_accepted
              ? 'fa-check-circle'
              : 'fa-times-circle',
          ]}),_vm._v(" "+_vm._s(_vm.selectedNotification.attributes.meeting_event.attributes.is_accepted ? "Rendez-vous accepté" : "Rendez-vous refusé")+" ")]):_vm._e()]):_vm._e()])]):_c('div',{staticClass:"popup-container"},[_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_c('i',{staticClass:"fas fa-bell"}),_vm._v(" Détails de la notification")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-content"},[_c('h3',[_vm._v("Erreur : La notification n'est pas disponible.")])])
}]

export { render, staticRenderFns }