import Parse from "parse";

export async function getLinksFileByLink(link) {
  const ObjectFolder = Parse.Object.extend("folder");
  const ObjectFolderType = Parse.Object.extend("folder_type");
  const ObjectLink = Parse.Object.extend("link");

  const QueryFolderType = new Parse.Query(ObjectFolderType);
  QueryFolderType.equalTo("name", "Module");
  const ResponseFolderType = await QueryFolderType.first();

  if (link.folder) {
    const QueryFolder = new Parse.Query(ObjectFolder);
    QueryFolder.equalTo("objectId", link.folder.objectId);
    //QueryFolder.equalTo("type", ResponseFolderType);
    const ResponseFolder = await QueryFolder.first();
    if (
      ResponseFolder &&
      ResponseFolder.attributes.type.id === ResponseFolderType.id
    ) {
      const QueryLink = new Parse.Query(ObjectLink);
      QueryLink.equalTo("parent_folder", ResponseFolder);
      QueryLink.limit(1000);
      return await QueryLink.find();
    }
  }
  return;
}
