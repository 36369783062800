<template lang="html">
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <b-loading
          :is-full-page="true"
          :active.sync="loaderFolderActive"
          :can-cancel="false"
        >
          <p>
            <b-icon
              pack="fas"
              icon="sync-alt"
              size="is-large"
              custom-class="fa-spin"
              type="is-blue"
            ></b-icon>
          </p>
          <p
            style="
              z-index: 10;
              padding-top: 100px;
              margin-left: -160px;
              color: #4579b2;
            "
          >
            <strong
              v-if="isStepFolder && foldersBrique.length !== 4 && num == 3"
              class="has-text-blue"
            >
              {{ $t("enregsitrementExercicesEnCours") }}
            </strong>
            <strong v-else class="has-text-blue">
              {{ $t("chargementDesInformationsEnCours") }}
            </strong>
          </p>
        </b-loading>
        <h1 class="title is-size-4">
          {{ $t("personnalisationDeMonEspacePro") }}
          <a class="linkPlusInfo" @click="isCardModalIframe = true">
            + d'infos</a
          >
        </h1>
        <div class="columns hidecolumn">
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
          >
            <div class="columns">
              <div
                class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen"
              >
                <div class="box box-information">
                  <div class="columns">
                    <div class="column is-padding-0">
                      <p class="is-size-5 has-text-weight-semibold">
                        {{
                          $t(
                            "Bienvenue sur votre espace professionnel de gestion."
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <p class="is-margin-b-20 has-text-weight-semibold">
                    {{ $t("") }}
                  </p>
                  <div class="columns">
                    <div class="column is-1 is-padding-1">
                      <b-icon pack="fas" icon="edit" type="is-black"></b-icon>
                    </div>
                    <div class="column is-padding-1">
                      <p>
                        Dans un premier temps, vous allez personnaliser votre
                        espace professionnel d'activités.
                      </p>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-1 is-padding-1">
                      <img
                        class="icon-tableau"
                        src="../../../assets/img/access.png"
                      />
                    </div>
                    <div class="column is-padding-l">
                      <p>
                        Vous allez commencer par définir et personnaliser votre
                        propre espace avec : un nom, une description et un logo.
                      </p>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-1 is-padding-l">
                      <img
                        class="icon-tableaubis"
                        src="../../../assets/img/acces_front_cabinet_001_160519.png"
                      />
                    </div>
                    <div class="column is-padding-l">
                      <p>
                        C'est depuis cet espace que vos utilisateurs et
                        vous-même pourrez pratiquer les diverses activités.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen"
              >
                <div class="box box-information">
                  <div class="columns">
                    <div class="column is-padding-0">
                      <p class="is-size-5 has-text-weight-semibold">
                        {{
                          $t(
                            "La plateforme SymbioCenter se décompose en 2 parties :"
                          )
                        }}
                      </p>
                    </div>
                  </div>
                  <p class="is-margin-b-20 has-text-weight-semibold">
                    {{ $t("") }}
                  </p>
                  <div class="columns">
                    <div class="column is-1 is-padding-l">
                      <img
                        class="icon-tableaubis"
                        src="../../../assets/img/acces_front_cabinet_001_160519.png"
                      />
                    </div>
                    <div class="column is-padding-l">
                      <p>
                        le côté utilisateur : l'espace d’activités visible par
                        vos utilisateurs.
                      </p>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-1 is-padding-l">
                      <img
                        class="icon-tableau"
                        src="../../../assets/img/access.png"
                      />
                    </div>
                    <div class="column is-padding-l">
                      <p>
                        le côté manager : espace professionnel de gestion
                        uniquement visible par le professionnel. Il vous permet
                        de gérer vos activités, vos utilisateurs, les
                        fonctionnalités, vos actualités selon le menu visible
                        sur la gauche.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div class="steps is-large">
          <a
            @click="saveAndNext(0)"
            class="step-item"
            :class="{
              'is-active': etapes[0].active,
              'is-completed': etapes[0].completed,
            }"
          >
            <div class="step-marker">1</div>
            <div
              class="step-details step-detailsDesc"
              :class="{
                cacherDetails: !etapes[0].active,
              }"
            >
              <p>
                {{ $t("paramEspaceAideBloc1") }}
              </p>
            </div>
          </a>
          <a
            @click="saveAndNext(1)"
            class="step-item"
            :class="{
              'is-active': etapes[1].active,
              'is-completed': etapes[1].completed,
            }"
          >
            <div class="step-marker">2</div>
            <div
              class="step-details step-detailsDesc"
              :class="{
                cacherDetails: !etapes[1].active,
              }"
            >
              <p>
                {{ $t("paramEspaceAideBloc2") }}
              </p>
            </div>
          </a>
          <a
            @click="saveAndNext(3)"
            class="step-item"
            :class="{
              'is-active': etapes[3].active,
              'is-completed': etapes[3].completed,
            }"
          >
            <div class="step-marker">3</div>
            <div
              class="step-details step-detailsDesc"
              :class="{
                cacherDetails: !etapes[3].active,
              }"
            >
              <p>
                {{ $t("paramEspaceAideBloc4") }}
              </p>
            </div>
          </a>
        </div>
        <div v-if="etapes[0].active">
          <div class="overflow2">
            <div>
              <label class="label">
                {{ $t("monEspace.Nom") }}
                <b-tooltip
                  size="is-small"
                  multilined
                  type="is-dark"
                  :label="$t('explicationNom')"
                  position="is-right"
                >
                  <img
                    class="img-icon"
                    src="../../../assets/img/info.png"
                  /> </b-tooltip
              ></label>
            </div>

            <b-input v-model="userGroup.name"></b-input>

            <div class="is-margin-t-20 is-margin-b-20">
              <div>
                <label class="label">
                  {{ $t("monEspace.Description") }}
                  <b-tooltip
                    size="is-small"
                    multilined
                    type="is-dark"
                    :label="$t('explicationDescription')"
                    position="is-right"
                  >
                    <img
                      class="img-icon"
                      src="../../../assets/img/info.png"
                    /> </b-tooltip
                ></label>
              </div>
              <quill-editor
                :options="editorOption"
                v-model="userGroup.description"
              >
              </quill-editor>
            </div>
            <label class="label"
              >{{ $t("monEspace.AjoutLogo") }}
              <b-tooltip
                size="is-small"
                multilined
                type="is-dark"
                :label="$t('explicationLogo')"
                position="is-right"
              >
                <img
                  class="img-icon"
                  src="../../../assets/img/info.png"
                /> </b-tooltip
            ></label>
            <div style="min-height: 100px">
              <image-uploader
                :preview="false"
                :maxHeight="100"
                :quality="0.9"
                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                :debug="1"
                :autoRotate="true"
                outputFormat="verbose"
                @input="setImage"
              >
              </image-uploader>
              <div v-if="image2 && image2.dataUrl">
                <br />
                <img :src="image2.dataUrl" />
              </div>
              <div
                v-else-if="userGroup.logo && userGroup.logo.url"
                class="img-logo"
                style="height: 100px"
              >
                <img :src="logoM" />
              </div>
            </div>
            <hr />
          </div>
          <div
            class="navbar is-fixed-bottom is-fullwidth ombrage"
            style="
              position: float;
              padding-top: 20px;
              border-top: 5px;
              background-color: #6753be;
            "
          >
            <div class="navbar-end">
              <a
                class="button is-blue button-sauvegarde"
                @click="saveAndNext(1)"
              >
                {{ $t("sauvegarderEtContinuer") }}
              </a>
            </div>
          </div>
        </div>
        <div v-if="etapes[1].active">
          <div class="overflow2">
            <h1 class="title is-size-5">{{ $t("motifEtCouleur") }}</h1>
            <div class="columns is-multiline">
              <div
                class="column is-12-mobile is-12-tablet is-12-desktop is-8-widescreen"
              >
                <p class="has-text-black">
                  1. {{ $t("choisissezMotifOuImage") }}
                </p>
                <p v-if="textureSelectionnee.id">
                  {{ $t("choix") }} :<strong>{{
                    textureSelectionnee.name
                  }}</strong>
                </p>
                <br />
                <p class="has-text-black">{{ $t("textures") }} :</p>
                <p class="has-text-black is-size-7" v-if="false">
                  {{ $t("ajouterUneTexture") }} :
                  <image-uploader
                    :preview="false"
                    :maxHeight="100"
                    :quality="0.9"
                    :className="[
                      'fileinput',
                      { 'fileinput--loaded': hasImage },
                    ]"
                    :debug="1"
                    :autoRotate="true"
                    outputFormat="verbose"
                    @input="setImageTexture"
                  >
                  </image-uploader>
                </p>
                <br />
                <div class="columns is-multiline zone-texture">
                  <div
                    v-for="texture in textures.type_texture"
                    :key="texture.name"
                    class="column is-6-mobile is-2-tablet is-2-desktop is-2-widescreen"
                  >
                    <a
                      @click="selectTexture(texture)"
                      class="box box-texture"
                      :style="{
                        backgroundImage: texture.urlTransform,
                        backgroundColor: color.hex,
                      }"
                      :class="{
                        checkTextureBackground:
                          texture.id === textureSelectionnee.id,
                      }"
                    >
                      <div
                        class="checkTexture"
                        v-if="texture.id === textureSelectionnee.id"
                      >
                        <b-icon
                          pack="fas"
                          icon="check-circle"
                          type="is-success"
                        ></b-icon>
                      </div>
                      <p v-else class="has-text-white has-text-centered">
                        {{ texture.name }}
                      </p>
                    </a>
                  </div>
                </div>
                <br />
                <p class="has-text-black">{{ $t("images") }} :</p>
                <p class="has-text-black is-size-7" v-if="false">
                  {{ $t("ajouterUneImage") }} :
                  <image-uploader
                    :preview="false"
                    :maxHeight="100"
                    :quality="0.9"
                    :className="[
                      'fileinput',
                      { 'fileinput--loaded': hasImage },
                    ]"
                    :debug="1"
                    :autoRotate="true"
                    outputFormat="verbose"
                    @input="setImageBackground"
                  >
                  </image-uploader>
                </p>
                <br />
                <div class="columns is-multiline zone-texture">
                  <div
                    v-for="texture in textures.type_background"
                    :key="texture.name"
                    class="column is-6-mobile is-6-tablet is-4-desktop is-3-widescreen"
                  >
                    <a
                      class="box box-texture"
                      @click="selectTexture(texture)"
                      :class="{
                        checkTextureBackground:
                          texture.id === textureSelectionnee.id,
                      }"
                      :style="{
                        backgroundImage: texture.urlTransform,
                      }"
                      style="background-size: 100%"
                    >
                      <div
                        class="checkTexture"
                        v-if="texture.id === textureSelectionnee.id"
                      >
                        <b-icon
                          pack="fas"
                          icon="check-circle"
                          type="is-success"
                        ></b-icon>
                      </div>
                    </a>
                  </div>
                </div>
                <br />
              </div>
              <div
                class="column is-12-mobile is-12-tablet is-12-desktop is-4-widescreen"
              >
                <p class="has-text-black">2. {{ $t("definirVotreCouleur") }}</p>
                <div class="section">
                  <chrome-picker v-model="color" />
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div
            class="navbar is-fixed-bottom is-fullwidth ombrage"
            style="
              position: float;
              padding-top: 20px;
              border-top: 5px;
              background-color: #6753be;
            "
          >
            <div class="navbar-end">
              <a class="button is-warning" @click="backStep(0)">
                {{ $t("retour") }}
              </a>
              <a
                class="button is-blue button-sauvegarde"
                @click="saveAndNext(3)"
              >
                {{ $t("sauvegarderEtContinuer") }}
              </a>
            </div>
          </div>
        </div>

        <div class="box-valide" v-if="etapes[3].active">
          <h1 class="title is-size-4">
            {{ $t("monEspace.EspaceActiviteValide") }}
          </h1>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
          >
            <div class="box columns">
              <div
                class="column is-1-mobile is-1-tablet is-1-desktop is-1-widescreen"
              >
                <img
                  class="icon-valide"
                  src="../../../assets/img/check.svg"
                  style="fill: #000"
                />
              </div>
              <div
                class="column is-12-mobile is-11-tablet is-11-desktop is-11-widescreen"
                v-if="payingSubscription"
              >
                <p>
                  {{ $t("messageFinalMonEspace") }}
                  <router-link
                    to="/utilisateurs"
                    class="button is-cyan is-margin-b-20"
                  >
                    {{ $t("ajoutUtilisateur") }}&nbsp;&nbsp;
                    <b-icon pack="fas" icon="plus" size=""></b-icon>
                  </router-link>
                </p>
              </div>
              <div
                class="column is-12-mobile is-11-tablet is-11-desktop is-11-widescreen align-self-center"
                v-else
              >
                {{ $t("messageFinalMonEspaceGratuit") }}
              </div>
            </div>
            <div
              class="navbar is-fixed-bottom is-fullwidth ombrage column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
              style="
                position: float;
                padding: 20px;
                border-top: 5px;
                background-color: #6753be;
              "
            >
              <div class="navbar-end">
                <a class="button retourEspaceGestion" @click="saveAndNext(4)">
                  <span class="versactivites">
                    {{ $t("continuerDecouverteEspaceGestion") }}
                  </span>
                  <i class="fas fa-home fa-2x"></i>
                </a>
                <a
                  class="button is-warning retourEspacePersonnalisation"
                  @click="saveAndNext(0)"
                >
                  <span class="versactivites">
                    {{ $t("revenirEtapePersonnalisation") }}
                  </span>
                  <img
                    class="icon"
                    src="../../../assets/img/parameters-white.svg"
                  />
                </a>
                <router-link
                  class="button is-blue button-sauvegarde decouvrirEspaceActivite"
                  v-bind:to="'/activites'"
                >
                  <span class="versactivites">
                    {{ $t("decouvrirEspaceActivite") }}
                  </span>
                  <img
                    src="../../../assets/img/acces_front_cabinet_blanc_001_160519@2x.png"
                    class="is-margin-l-10"
                  />
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardAjoutTexture"
      @close="isCardAjoutTexture = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div v-if="imageTexture && imageTexture.dataUrl">
              <h3 class="title has-text-black is-size-6">
                {{ $t("ajouterUneTexture") }}
              </h3>
              <div class="has-text-centered">
                <img class="border-image" :src="imageTexture.dataUrl" />
                <p>{{ imageTexture.name }}</p>
              </div>
              <br />
              <a class="button is-cyan is-pulled-right" @click="addTexture()">
                {{ $t("ajouter") }}
              </a>
              <a
                class="button is-grey"
                @click="(isCardAjoutTexture = false), (imageTexture = null)"
              >
                {{ $t("annuler") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="isCardAjoutBackground"
      @close="isCardAjoutBackground = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <div v-if="imageBackground && imageBackground.dataUrl">
              <h3 class="title has-text-black is-size-6">
                {{ $t("ajouterUneImage") }}
              </h3>
              <div class="has-text-centered">
                <img class="border-image" :src="imageBackground.dataUrl" />
                <p>{{ imageBackground.name }}</p>
              </div>
              <br />
              <a
                class="button is-cyan is-pulled-right"
                @click="addBackground()"
              >
                {{ $t("ajouter") }}
              </a>
              <a
                class="button is-grey"
                @click="
                  (isCardAjoutBackground = false), (imageBackground = null)
                "
              >
                {{ $t("annuler") }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="isCardModalIframe"
      @close="isCardModalIframe = false"
    >
      <modal-iframe-component
        url="https://www.symbiocenter.fr/2019/09/12/decouvrir-mon-espace-manager/"
      />
    </b-modal>
  </div>
</template>

<script>
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
import { Chrome } from "vue-color/";
import { createNamespacedHelpers } from "vuex";
import codeGenerator from "@/services/general/codeGenerator.js";
import defineSteps from "@/services/espace/defineSteps.js";
import cloneDeep from "lodash/cloneDeep";
import foldersComponent from "./folders.vue";
import { unlockFolder } from "@/services/folders/unlockFolder.js";
import { saveVerrouillageEtapeByProtocoleAndUser } from "@/services/folders/saveVerrouillageEtapeByProtocoleAndUser";
import { pushLinkToUser } from "@/services/folders/pushLinkToUser";
import router from "@/router";
import ModalIframe from "./modalInformation.vue";
import { URL_FRONT } from "@/configuration/urlConf.js";
import hasPayingSubscription from "@/services/userGroup/subscriptionType.js";
const User = createNamespacedHelpers("user");

export default {
  name: "mon-espace",
  components: {
    "chrome-picker": Chrome,
    FoldersComponent: foldersComponent,
    ModalIframeComponent: ModalIframe,
  },
  data() {
    return {
      payingSubscription: false,
      image: {},
      color: { hex: "#4579B2", hexBG: "#ffffff" },
      editorOption: {
        placeholder: "",
        theme: "snow",
      },
      file: null,
      isCardModalIframe: false,
      urlFront: URL_FRONT,
      etapes: [
        {
          etape: 1,
          active: true,
          complete: false,
        },
        {
          etape: 2,
          active: false,
          complete: false,
        },
        {
          etape: 3,
          active: false,
          complete: false,
        },
        {
          etape: 4,
          active: false,
          complete: false,
        },
        {
          etape: 5,
          active: false,
          complete: false,
        },
      ],
      textures: {
        type_texture: [],
        type_background: [],
      },
      textureSelectionnee: {},
      userGroup: {},
      error: false,
      foldersBrique: [],
      themes: [],
      foldersProtocolLoad: false,
      linksToAdd: [],
      hasImage: false,
      image2: null,
      imageTexture: null,
      logoM: null,
      imageBackground: null,
      isCardAjoutTexture: false,
      isCardAjoutBackground: false,
      loaderFolderActive: false,
      isStepFolder: false,
      num: 0,
      textureBG: null,
    };
  },
  computed: {
    ...User.mapGetters(["group", "user", "folders", "abonnement"]),
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
      actionsLoadFolder: "loadFolder",
    }),
    async goAbonnement() {
      await this.saveAndNext();
      this.$router.push("abonnement");
    },
    async goToActivites() {
      await this.actionsLogOut();
      window.location.href = this.urlFront;
    },
    selectTexture(texture) {
      if (this.textureSelectionnee.id === texture.id) {
        this.textureSelectionnee = {};
        return;
      }
      this.textureSelectionnee = {
        id: texture.id,
        name: texture.name,
      };
    },
    async backStep(num) {
      this.num = num;
      this.error = false;
      let prepareLoadLink = false;
      if (!this.userGroup.name) {
        this.error = true;
        return;
      }
      await this.actionsUpdateUserGroup();
      this.initialisationUserGroup(this.group);
      this.foldersBrique = cloneDeep(
        this.folders.filter((e) => e.domaine == "Santé")
      );
      this.loaderFolderActive = true;
      try {
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const ObjectUser = Parse.Object.extend("User");
        const ObjectTexture = Parse.Object.extend("texture");

        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        const QueryUser = new Parse.Query(ObjectUser);
        const QueryTexture = new Parse.Query(ObjectTexture);

        QueryUserGroup.equalTo("objectId", this.userGroup.objectId);
        //const ResponseUserGroup = await QueryUserGroup.first();

        QueryUser.equalTo("objectId", this.user.objectId);
        const ResponseUser = await QueryUser.first();
        if (this.textureSelectionnee.id) {
          QueryTexture.equalTo("objectId", this.textureSelectionnee.id);
          //const ResponseTexture = await QueryTexture.first();
          //ResponseUserGroup.set("texture", ResponseTexture);
        }
        if (this.isStepFolder) {
          this.linksToAdd = await this.linkToAddToUser();
          saveVerrouillageEtapeByProtocoleAndUser(
            this.foldersBrique,
            await Parse.User.current()
          );
          await pushLinkToUser(ResponseUser, this.linksToAdd);
          prepareLoadLink = true;
          this.isStepFolder = false;
        }
        if (num === 2) {
          this.isStepFolder = true;
        }

        if (num || num === 0) {
          if (num === 4) {
            router.push("/accueil");
          } else if (num === 3) {
            defineSteps(this.etapes, num);
          } else {
            defineSteps(this.etapes, num);
          }
        }
        if (this.isStepFolder && this.foldersBrique.length !== 4) {
          this.loaderFolderActive = false;
          return;
        }
        if (prepareLoadLink) {
          await this.actionsLoadFolder();
        }
        this.loaderFolderActive = false;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Une erreur est survenue.",
          position: "is-top",
          type: "is-danger",
        });
        this.loaderFolderActive = false;
      }
    },
    async saveAndNext(num) {
      this.num = num;
      this.error = false;
      let prepareLoadLink = false;
      if (!this.userGroup.name) {
        this.error = true;
        return;
      }
      this.loaderFolderActive = true;
      try {
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const ObjectUser = Parse.Object.extend("User");
        const ObjectTexture = Parse.Object.extend("texture");

        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        const QueryUser = new Parse.Query(ObjectUser);
        const QueryTexture = new Parse.Query(ObjectTexture);

        QueryUserGroup.equalTo("objectId", this.userGroup.objectId);
        const ResponseUserGroup = await QueryUserGroup.first();

        QueryUser.equalTo("objectId", this.user.objectId);
        const ResponseUser = await QueryUser.first();
        if (this.textureSelectionnee.id) {
          QueryTexture.equalTo("objectId", this.textureSelectionnee.id);
          const ResponseTexture = await QueryTexture.first();
          ResponseUserGroup.set("texture", ResponseTexture);
          this.textureBG = ResponseTexture.attributes.type_texture;
        }
        if (this.isStepFolder) {
          this.linksToAdd = await this.linkToAddToUser();
          /*saveVerrouillageEtapeByProtocoleAndUser(
            this.foldersBrique,
            await Parse.User.current()
          );*/

          await pushLinkToUser(ResponseUser, this.linksToAdd);

          prepareLoadLink = true;
          this.isStepFolder = false;
        }
        if (num === 2) {
          this.isStepFolder = true;
        }

        ResponseUserGroup.set("name", this.userGroup.name);
        ResponseUserGroup.set("description", this.userGroup.description);
        if (
          this.userGroup.logo &&
          this.userGroup.logo.logo &&
          this.userGroup.logo.name &&
          this.userGroup.logo.name !== this.image.name
        ) {
          this.image.name = this.userGroup.logo.name;
          var base64 = this.userGroup.logo.url.split("base64,")[1];
          var parseFile = new Parse.File("logo", {
            base64: base64,
          });
          const fileSave = await parseFile.save();
          ResponseUserGroup.set("logo", fileSave);
        }
        if (this.textureBG === "background") {
          ResponseUserGroup.set("couleur", this.color.hexBG);
        } else {
          ResponseUserGroup.set("couleur", this.color.hex);
        }
        ResponseUserGroup.set(
          "mode_retrocession",
          this.userGroup.mode_retrocession
        );
        ResponseUserGroup.set(
          "code_affiliation",
          this.userGroup.code_affiliation
        );
        if (num || num === 0) {
          if (num === 4) {
            ResponseUserGroup.set("parametrage_fait", true);
            await ResponseUserGroup.save();
            await ResponseUser.save();
            await this.actionsUpdateUserGroup();
            router.push("/accueil");
          } else if (num === 3) {
            defineSteps(this.etapes, num);
            ResponseUserGroup.set("parametrage_fait", true);
            await ResponseUserGroup.save();
            await ResponseUser.save();
            await this.actionsUpdateUserGroup();
          } else {
            defineSteps(this.etapes, num);
            await ResponseUserGroup.save();
            await ResponseUser.save();
            await this.actionsUpdateUserGroup();
          }
        } else {
          await ResponseUserGroup.save();
          await ResponseUser.save();
        }
        if (this.isStepFolder && this.foldersBrique.length !== 4) {
          this.loaderFolderActive = false;
          return;
        }
        if (prepareLoadLink) {
          await this.actionsLoadFolder();
        }
        this.loaderFolderActive = false;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 5000,
          message: "Une erreur est survenue.",
          position: "is-top",
          type: "is-danger",
        });
        this.loaderFolderActive = false;
      }
    },
    async getTexture() {
      this.textures.type_background = [];
      this.textures.type_texture = [];
      ParseConfig();
      const Texture = Parse.Object.extend("texture");
      const query = new Parse.Query(Texture);
      const textures = await query.find();
      textures.forEach((texture) => {
        if (texture.attributes.texture) {
          const newTexture = {
            id: texture.id,
            name: texture.attributes.name,
            url: texture.attributes.texture._url,
          };
          if (texture.attributes.type_texture === "background") {
            newTexture.urlTransform =
              "url(" + texture.attributes.texture._url + ")";
            this.textures.type_background.push(newTexture);
          } else if (texture.attributes.type_texture === "texture") {
            newTexture.urlTransform =
              "url(" + texture.attributes.texture._url + ")";
            this.textures.type_texture.push(newTexture);
          }
        }
      });
    },
    setImage: function (output) {
      this.hasImage = true;
      this.image2 = output;
      this.userGroup.logo = {};
      this.userGroup.logo.url = this.image2.dataUrl;
      this.userGroup.logo.name = this.image2.name;
      this.userGroup.logo.logo = this.image2;
    },
    setImageTexture: function (output) {
      this.imageTexture = null;
      this.imageTexture = output;
    },
    setImageBackground: function (output) {
      this.imageBackground = null;
      this.imageBackground = output;
    },
    async unlockProtocol(props) {
      await unlockFolder(props, this.group.type_abonnement, true);
    },
    async linkToAddToUser() {
      const linksToAdd = [];
      var queryTheme = new Parse.Query("theme");
      queryTheme.limit(10000);
      var listeTheme = await queryTheme.find();
      for (var i = 0; i < this.foldersBrique.length; i = i + 1) {
        var brique = this.foldersBrique[i];
        var mesThemes = listeTheme.filter((e) => {
          return e.id === brique.themeId;
        });
        var theme = mesThemes[0];
        if (brique.deverrouille) {
          linksToAdd.push(brique.link);
          for (var j = 0; j < brique.protocoles.length; j = j + 1) {
            var protocole = brique.protocoles[j];
            if (protocole.buttonExpert && protocole.modulesExpert.length) {
              protocole.modulesExpert.forEach((moduleExpert) => {
                moduleExpert.linkExpert.forEach((link) => {
                  linksToAdd.push(link);
                });
              });
            }
            if (
              protocole.buttonDecouverte &&
              protocole.modulesDecouverte.length
            ) {
              protocole.modulesDecouverte.forEach((moduleDecouverte) => {
                moduleDecouverte.linkDecouverte.forEach((link) => {
                  linksToAdd.push(link);
                });
              });
            }
            if (protocole.deverrouille) {
              linksToAdd.push(protocole.link);
              for (var k = 0; k < protocole.modules.length; k = k + 1) {
                var moduleP = protocole.modules[k];
                if (theme.attributes.ordre === 7 && moduleP.deverrouille) {
                  linksToAdd.push(moduleP.link);
                } else if (theme.attributes.ordre !== 7) {
                  linksToAdd.push(moduleP.link);
                }
              }
            }
          }
        }
      }
      return linksToAdd;
    },
    async addTexture() {
      const ObjectTexture = Parse.Object.extend("texture");
      const NewTexture = new ObjectTexture();
      NewTexture.set("type_texture", "texture");
      NewTexture.set("name", this.imageTexture.name);
      var base64 = this.imageTexture.dataUrl.split("base64,")[1];
      var parseFile = new Parse.File(this.imageTexture.name, {
        base64: base64,
      });
      const fileSave = await parseFile.save();
      NewTexture.set("texture", fileSave);
      await NewTexture.save();
      this.isCardAjoutTexture = false;
      this.imageTexture = false;
      await this.getTexture();
    },
    async addBackground() {
      const ObjectTexture = Parse.Object.extend("texture");
      const NewTexture = new ObjectTexture();
      NewTexture.set("type_texture", "background");
      NewTexture.set("name", this.imageBackground.name);
      var base64 = this.imageBackground.dataUrl.split("base64,")[1];
      var parseFile = new Parse.File(this.imageBackground.name, {
        base64: base64,
      });
      const fileSave = await parseFile.save();
      NewTexture.set("texture", fileSave);
      await NewTexture.save();
      this.isCardAjoutBackground = false;
      this.imageBackground = false;
      await this.getTexture();
    },
    initialisationUserGroup(monGroup) {
      this.userGroup = Object.assign(monGroup);
      if (this.userGroup.logo && this.userGroup.logo._name) {
        this.userGroup.logo.name = this.userGroup.logo._name;
        this.image = cloneDeep(this.userGroup.logo);
      } else if (this.userGroup.logo && this.userGroup.logo.name) {
        this.image = cloneDeep(this.userGroup.logo);
      }
      if (this.userGroup.texture && this.userGroup.texture.objectId) {
        this.textureSelectionnee.id = this.userGroup.texture.objectId;
      } else if (this.userGroup.texture && this.userGroup.texture.id) {
        this.textureSelectionnee.id = this.userGroup.texture.id;
      }
      if (!this.userGroup.mode_retrocession) {
        this.userGroup.mode_retrocession = "Credit";
      }
      const texture =
        this.textures.type_background.find((texture) => {
          return texture.id === this.textureSelectionnee.id;
        }) ||
        this.textures.type_texture.find((texture) => {
          return texture.id === this.textureSelectionnee.id;
        });
      if (texture) {
        this.textureSelectionnee.name = texture.name;
      }
      if (this.userGroup.couleur) {
        this.color.hex = this.userGroup.couleur;
      }
      if (!this.userGroup.code_affiliation) {
        this.userGroup.code_affiliation = codeGenerator("xxxxxxxx");
      }
    },
  },
  async mounted() {
    if (typeof this.group.logo.url === "function") {
      this.logoM = this.group.logo.url();
    } else {
      this.logoM = this.group.logo.url;
    }
    // try {
    //   var query = new Parse.Query("site_admin");
    //   var resultat = await query.first();
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }

    this.payingSubscription = hasPayingSubscription(this.abonnement);
    this.loaderFolderActive = true;
    await this.getTexture();
    if (this.folders === null) await this.actionsLoadFolder();

    this.foldersBrique = cloneDeep(
      this.folders.filter((e) => e.domaine == "Santé")
    );

    const oDomaine = Parse.Object.extend("domaine");
    const queryDomaine = new Parse.Query(oDomaine);
    queryDomaine.equalTo("nom", "Santé");
    const domaineSante = await queryDomaine.first();
    const oTheme = Parse.Object.extend("theme");
    const queryTheme = new Parse.Query(oTheme);
    queryTheme.equalTo("domaine", domaineSante);
    queryTheme.ascending("ordre");
    this.themes = await queryTheme.find();
    this.foldersProtocolLoad = true;
    this.initialisationUserGroup(this.group);
    this.loaderFolderActive = false;
  },
  watch: {
    abonnement() {
      this.payingSubscription = hasPayingSubscription(this.abonnement);
    },
    imageTexture() {
      if (this.imageTexture) {
        this.isCardAjoutTexture = true;
      }
    },
    imageBackground() {
      if (this.imageBackground) {
        this.isCardAjoutBackground = true;
      }
    },
  },
};
</script>

<style>
.steps .step-item.is-active .step-marker {
  color: #239380 !important;
  border-color: #239380 !important;
}

.steps .step-item.is-completed .step-marker {
  background-color: #239380 !important;
}

.steps.is-large .is-active:not(:first-child)::before {
  background: #239380 !important;
}

.steps.is-large .is-completed:not(:first-child)::before {
  background: #239380 !important;
}

.step-detailsDesc {
  font-size: medium;
  background-color: #239380;
  color: white;
  padding: 5px;
  border-radius: 9px;
}

.steps.is-large .step-item .step-details {
  margin-top: 4rem;
}

.cacherDetails {
  display: none;
}

.retourEspaceGestion {
  background-color: #b2b2b2;
  color: white;
  margin: 15px;
  padding: 30px;
  white-space: normal;
}

.retourEspacePersonnalisation {
  margin: 15px;
  padding: 30px;
  white-space: normal;
}

.decouvrirEspaceActivite {
  white-space: normal;
  padding: 30px;
  margin: 15px;
}

.decouvrirEspaceActivite img {
  width: 36px;
  margin-left: 20px;
}

.retourEspacePersonnalisation img {
  width: 306px;
  margin-left: 20px;
}

.retourEspaceGestion label {
  width: 250px;
}

.align-self-center {
  align-self: center;
}
</style>
