import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export async function editUserGroupAbonnement(abonnement, groupId) {
  ParseConfig();

  let avecCartesPostale = false;

  const ObjectUserGroup = Parse.Object.extend("user_group");

  const QueryUserGroup = new Parse.Query(ObjectUserGroup);

  QueryUserGroup.equalTo("objectId", groupId);
  const ResponseUserGroup = await QueryUserGroup.first();

  if (abonnement.informationsBancaires) {
    ResponseUserGroup.set(
      "code_postal_cb",
      abonnement.informationsBancaires.codePostal
    );
    ResponseUserGroup.set("pays", abonnement.informationsBancaires.pays);
    ResponseUserGroup.set(
      "numero_tva",
      abonnement.informationsBancaires.numeroTva
    );
  }

  if (abonnement.adresse && abonnement.engagementAnnuel) {
    ResponseUserGroup.set(
      "adresse_livraison_nom",
      abonnement.adresse.adresse_livraison_nom
    );
    ResponseUserGroup.set(
      "adresse_livraison_prenom",
      abonnement.adresse.adresse_livraison_prenom
    );
    ResponseUserGroup.set(
      "adresse_livraison",
      abonnement.adresse.adresse_livraison
    );
    ResponseUserGroup.set(
      "adresse_livraison_complement",
      abonnement.adresse.adresse_livraison_complement
    );
    ResponseUserGroup.set(
      "adresse_livraison_ville",
      abonnement.adresse.adresse_livraison_ville
    );
    ResponseUserGroup.set(
      "adresse_livraison_code_postal",
      abonnement.adresse.adresse_livraison_code_postal
    );
    ResponseUserGroup.set(
      "adresse_livraison_code_acces_residence",
      abonnement.adresse.adresse_livraison_code_acces_residence
    );
  }

  if (abonnement.cartesPostales && abonnement.engagementAnnuel) {
    avecCartesPostale = true;
  }

  ResponseUserGroup.set("cartes_postales", avecCartesPostale);
  ResponseUserGroup.set("resilie", false);

  return await ResponseUserGroup.save();
}
