export default function createUser(user, newUser) {
  newUser.set("username", user.mail);
  newUser.set("therapeute", true);
  newUser.set("civilite", user.civilite);
  newUser.set("lastname", user.nom);
  newUser.set("firstname", user.prenom);
  newUser.set("profession", user.profession);
  newUser.set("adresse", user.adresse);
  newUser.set("complement_adresse", user.complementAdresse);
  newUser.set("code_postal", user.codePostal);
  newUser.set("ville", user.ville);
  newUser.set("telephone_pro", user.telephone_pro);
  newUser.set("email", user.mail);
  newUser.set("adeli", user.adeli);
  newUser.set("siret", user.siret);
  newUser.set("cgu", true);
  newUser.set("password", user.password);
  newUser.set("compte_actif", true);
}
