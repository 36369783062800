<template>
  <div
    class="is-max-desktop is-fullhd full-h full-w flex-center_gap-5 color-grey"
  >
    <img
      src="../../../assets/img/Logos_Symbiofi_SymbioCenter_PRO_vectoriser.png"
    />
    <div class="box flex-center h-200">
      <h3 class="title has-text-primary">
        Vous venez de refuser votre rendez-vous de visio-conférence !
      </h3>

      <h4 class="subtitle has-text-primary">
        Veuillez à présent vous connecter en cliquant sur le bouton "connexion".
      </h4>
      <div class="buttons">
        <b-button
          @click="setCompteActif"
          type="is-primary"
          class="button is-primary"
          >Connexion</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export default {
  name: "AccountConfirm",
  methods: {
    async getMyMeeting() {
      ParseConfig();
      const meetingObject = Parse.Object.extend("meeting");
      const meetingQuery = new Parse.Query(meetingObject);
      meetingQuery.equalTo("objectId", this.$route.query.id);
      const meetingFind = await meetingQuery.first();
      return meetingFind;
    },
    async setCompteActif() {
      const meeting = await this.getMyMeeting();
      meeting.set("is_accepted", false);
      await meeting.save();
      // myUserGroup.set("compte_valide", true);
      // await myUserGroup.save();
      // window.location.href = URL_FRONT;
    },
  },
  mounted() {
    this.setCompteActif();
  },
};
</script>
<style scoped lang="scss">
.h-200 {
  min-height: 200px;
}

.full-h {
  height: 100vh;
}

.full-w {
  width: 100vw;
}

.title {
  text-align: center;
}

.flex-center {
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &_gap-5 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5em;
  }
}

.color-grey {
  background-color: #e8e8e8;
}

.btn-connexion {
  width: 200px;
}
</style>
