<template>
  <div class="box is-margin-b-10 bg-color" v-if="misAJour" ref="element">
    <b-tabs type="is-boxed" expanded size="is-medium">
      <template v-for="theme in themes">
        <b-tab-item
          :key="theme.id"
          :label="theme.attributes.name"
          v-if="theme.attributes.name != 'Mes échelles d\'évaluation'"
        >
          <!-- Pour avoir la CC dans mes programme sante : Modifier son themeId et changer la ligne suivante par "theme.attributes.ordre === 1" sinon ""=== 4" -->

          <!-- DEBUT ONGLET PROGRAMME PARTIE 1 - EXCEPTION RESSOUCES LONGS OU COURTS -->
          <div v-if="theme.attributes.ordre === 1">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >            
              <div
                class="box is-margin-b-20"
                :class="{
                  boxFolder:
                    folderBrique.deverrouille &&
                    folderBrique.protocoles[1].deverrouille,
                }"
                v-if="
                  folderBrique &&
                  (folderBrique.name.includes('cardiaque') ||
                    folderBrique.name.includes('hypnotique') ||
                    folderBrique.name.includes('compassion'))
                "
              >
                <div class="columns">
                  <div
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? ''
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="img-brique-folder">
                        <figure
                          class="image is-128x128 brique is-margin-t-50 is-margin-l-0"
                        >
                          <router-link
                            v-if="
                              !isPageUser &&
                              !isPageSessionGroup &&
                              group.type_abonnement == 'gratuit'
                            "
                            class=""
                            to="/abonnement"
                          >
                            <div class="">
                              <div
                                class="column is-12 is-size-7 is-centered text-abonnezvous-expert"
                                style="
                                  border-radius: 15px 15px 0px 0px;
                                  background-color: #4a439e;
                                  color: white;
                                  z-index: 1;
                                  display: block;
                                "
                              >
                                {{ $t("abonnezVousPour") }} <br />
                                {{ $t("debloquerProtocole") }}
                              </div>
                            </div>
                          </router-link>
                          <img
                            v-if="
                              folderBrique.resourceBg &&
                              folderBrique.resourceBg._url
                            "
                            class="brique-image"
                            style="
                              border-radius: 0px 0px 15px 15px;
                              width: 100%;
                              max-width: 400px;
                            "
                            :src="folderBrique.resourceBg._url"
                          />
                          <img
                            v-else-if="
                              folderBrique.resourceBg &&
                              folderBrique.resourceBg.url
                            "
                            class="brique-image"
                            style="
                              border-radius: 0px 0px 15px 15px;
                              width: 100%;
                              max-width: 400px;
                            "
                            :src="folderBrique.resourceBg.url"
                          />
                          <div class="brique-text">
                            <p class="text-brique">
                              {{ folderBrique.protocoles[1].name }}
                            </p>
                          </div>
                          <a
                            class="linkPlusInfo2"
                            style="display: flex"
                            @click="
                              openInformation(
                                folderBrique.protocoles[1].text,
                                folderBrique.protocoles[1].videoIntro
                              )
                            "
                          >
                            + d'infos</a
                          >
                        </figure>
                      </div>
                    </b-tooltip>
                  </div>
                  <a
                    class="tooltip column is-12-mobile is-5-tablet is-5-desktop is-4-widescreen"
                    v-if="folderBrique.protocoles[1]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[1].deverrouille &&
                        (folderBrique.protocoles[1].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.protocoles[1].deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique, 1)"
                  >
                    <span class="tooltiptext"
                      >Activer/Désactiver le protocole</span
                    >
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <span class="has-text-black is-size-12 text-brique">
                          {{ folderBrique.protocoles[1].name }}
                        </span>
                      </div>

                      <div class="BSwitchBrique">
                        <b-switch
                          size="is-small"
                          @click="unlock(folderBrique, 1)"
                          type="is-cyan"
                          v-model="folderBrique.protocoles[1].deverrouille"
                        >
                          <span v-if="folderBrique.protocoles[1].deverrouille"
                            >Activé</span
                          >
                          <span v-else>Désactivé</span>
                        </b-switch>
                      </div>
                    </div>

                    <div>
                      <div
                        :class="{}"
                        v-for="(moduleP, index) in folderBrique.protocoles[1]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div class="columns is-vcentered">
                          <div
                            class="column is-12-mobile is-2-tablet is-3-desktop is-2-widescreen p-5"
                            :class="{
                              'is-3': isPageSessionGroup,
                            }"
                            v-if="!moduleP.name.includes('Pratique libre')"
                          >
                            <figure>
                              <div
                                class="cercle"
                                :class="{
                                  cerclegrey:
                                    !folderBrique.protocoles[1].deverrouille,
                                }"
                              >
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ index + 1 }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div
                            class="column isPageUser has-text-black is-12-mobile is-10-tablet is-8-desktop is-10-widescreen"
                            v-if="!moduleP.name.includes('Pratique libre')"
                          >
                            <div>
                              <span
                                class="has-text-black"
                                style="font-size: 12px; margin-left: 20px"
                              >
                                {{ moduleP.name }}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="is-margin-t-10">
                        <div
                          class="columns is-vcentered folders-cc-disabled"
                          v-if="folderBrique.protocoles[1].modules.length === 1"
                        >
                          <div
                            class="column is-12-mobile is-4-tablet is-3-desktop is-3-widescreen p-5"
                            :class="{
                              'is-3': isPageSessionGroup,
                            }"
                          >
                            <figure style="margin: 0">
                              <div class="cerclegrey">
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ 2 }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div
                            class="column has-text-black"
                            :class="{ 'is-margin-l-40': isPageUser }"
                          >
                            {{ $t("installerLaCC") }}
                          </div>
                        </div>
                      </div>
                      <div class="is-margin-t-12">
                        <div
                          class="columns is-vcentered folders-cc-disabled"
                          v-if="folderBrique.protocoles[1].modules.length === 1"
                        >
                          <div
                            class="column is-2"
                            :class="{
                              'is-2': isPageUser,
                              'is-3': isPageSessionGroup,
                            }"
                          >
                            <figure style="margin: 0">
                              <div class="cerclegrey">
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ 3 }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div
                            class="column has-text-black"
                            :class="{ 'is-margin-l-40': isPageUser }"
                          >
                            {{ $t("maitriserLaCC") }}
                          </div>
                        </div>
                      </div>
                      <div class="is-margin-t-12">
                        <div
                          class="columns is-vcentered folders-cc-disabled"
                          v-if="folderBrique.protocoles[1].modules.length === 1"
                        >
                          <div
                            class="column is-12-mobile is-2-tablet is-2-desktop is-2-widescreen p-5"
                            :class="{
                              'is-3': isPageSessionGroup,
                            }"
                          >
                            <figure style="margin: 0">
                              <div class="cerclegrey">
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ 4 }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div
                            class="column has-text-black"
                            :class="{ 'is-margin-l-40': isPageUser }"
                          >
                            {{ $t("ancrerLaCC") }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[2] &&
                        folderBrique.protocoles[2].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <!-- DEBUT ONGLET PROGRAMME PARTIE 2 - EXCEPTION RESSOUCES LONGS ET COURTS -->
          <div class="bg-color" v-if="theme.attributes.ordre === 1">
            <div
              class="bg-color"
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
            >
              <!-- {{ "Verouillage brique :" + folderBrique.deverrouille }} -->
              <div
                class="box is-margin-b-10"
                :class="{
                  boxFolder:
                    (folderBrique.deverrouille &&
                      folderBrique.protocoles[1].deverrouille) ||
                    (folderBrique.deverrouille &&
                      folderBrique.protocoles[2].deverrouille),
                }"
                v-if="
                  folderBrique &&
                  !folderBrique.name.includes('cardiaque') &&
                  !folderBrique.name.includes('hypnotique') &&
                  !folderBrique.name.includes('compassion') &&
                  !folderBrique.name.includes('Tests') &&
                  !folderBrique.name.includes('Soft') &&
                  !folderBrique.name.includes('Vie')
                "
              >
                <div class="columns">
                  <div
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? ''
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="img-brique-folder">
                        <figure
                          class="image is-128x128 brique is-margin-t-50 is-margin-l-0"
                        >
                          <router-link
                            v-if="
                              !isPageUser &&
                              !isPageSessionGroup &&
                              group.type_abonnement == 'gratuit'
                            "
                            class=""
                            to="/abonnement"
                          >
                            <div class="">
                              <div
                                class="column is-12 is-size-7 is-centered text-abonnezvous-expert"
                                style="
                                  border-radius: 15px 15px 0px 0px;
                                  background-color: #4a439e;
                                  color: white;
                                  z-index: 1;
                                  display: block;
                                "
                              >
                                {{ $t("abonnezVousPour") }} <br />
                                {{ $t("debloquerProtocole") }}
                              </div>
                            </div>
                          </router-link>
                          <img
                            v-if="
                              folderBrique.resourceBg &&
                              folderBrique.resourceBg._url
                            "
                            class="brique-image"
                            style="
                              border-radius: 0px 0px 15px 15px;
                              width: 100%;
                              max-width: 400px;
                            "
                            :src="folderBrique.resourceBg._url"
                          />
                          <img
                            v-else-if="
                              folderBrique.resourceBg &&
                              folderBrique.resourceBg.url
                            "
                            class="brique-image"
                            style="
                              border-radius: 0px 0px 15px 15px;
                              width: 100%;
                              max-width: 400px;
                            "
                            :src="folderBrique.resourceBg.url"
                          />
                          <div class="brique-text">
                            <p class="text-brique">
                              {{ folderBrique.protocoles[1].name }}
                            </p>
                          </div>
                          <a
                            class="linkPlusInfo2"
                            style="display: flex"
                            @click="
                              openInformation(
                                folderBrique.protocoles[1].text,
                                folderBrique.protocoles[1].videoIntro
                              )
                            "
                          >
                            + d'infos</a
                          >
                        </figure>
                      </div>
                    </b-tooltip>
                  </div>
                  <a
                    class="tooltip column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen column-border"
                    v-if="folderBrique.protocoles[1]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[1].deverrouille &&
                        (folderBrique.protocoles[1].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.protocoles[1].deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique, 1)"
                    ><div style="display: flex; justify-content: space-between">
                      <span class="tooltiptext"
                        >Activer/Désactiver le protocole</span
                      >
                      <div>
                        <span class="has-text-black is-size-12">
                          Programme Court
                        </span>
                      </div>
                      <div class="BSwitchBrique">
                        <b-switch
                          size="is-small"
                          @click="unlock(folderBrique, 1)"
                          type="is-cyan"
                          v-model="folderBrique.protocoles[1].deverrouille"
                        >
                          <span v-if="folderBrique.protocoles[1].deverrouille"
                            >Activé</span
                          >
                          <span v-else>Désactivé</span>
                        </b-switch>
                      </div>
                    </div>
                    <div>
                      <div
                        :class="{}"
                        v-for="(moduleP, index) in folderBrique.protocoles[1]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div class="columns is-vcentered">
                          <div
                            class="column is-12-mobile is-2-tablet is-2-desktop is-3-widescreen p-5"
                            :class="{
                              'is-4': isPageSessionGroup,
                            }"
                          >
                            <figure>
                              <div
                                class="cercle"
                                :class="{
                                  cerclegrey:
                                    !folderBrique.protocoles[1].deverrouille,
                                }"
                              >
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ index + 1 }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div>
                            <span
                              style="font-size: 12px"
                              class="column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                            >
                              {{ moduleP.name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[2] &&
                        folderBrique.protocoles[2].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </a>

                  <a
                    class="tooltip column is-12-mobile is-5-tablet is-5-desktop is-4-widescreen column-border"
                    v-if="folderBrique.protocoles[2]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[2].deverrouille &&
                        (folderBrique.protocoles[2].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                    }"
                    @click="unlock(folderBrique, 2)"
                    ><span class="tooltiptext"
                      >Activer/Désactiver le protocole</span
                    >
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <span class="has-text-black is-size-12">
                          Programme Long
                        </span>
                      </div>
                      <div class="BSwitchBrique">
                        <b-switch
                          size="is-small"
                          @click="unlock(folderBrique, 2)"
                          type="is-cyan"
                          v-model="folderBrique.protocoles[2].deverrouille"
                        >
                          <span v-if="folderBrique.protocoles[2].deverrouille"
                            >Activé</span
                          >
                          <span v-else>Désactivé</span>
                        </b-switch>
                      </div>
                    </div>
                    <div>
                      <div
                        :class="{ isPageUser: isPageUser }"
                        v-for="(moduleP, index) in folderBrique.protocoles[2]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div
                          class="columns is-vcentered"
                          v-if="moduleP.fileIsNotEval"
                        >
                          <div
                            class="column is-12-mobile is-2-tablet is-2-desktop is-3-widescreen p-5"
                            :class="{
                              'is-6': isPageUser,
                              'is-4': isPageSessionGroup,
                            }"
                          >
                            <figure>
                              <div
                                class="cercle"
                                :class="{
                                  cerclegrey:
                                    !folderBrique.protocoles[2].deverrouille,
                                }"
                              >
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ index }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div>
                            <span
                              style="font-size: 12px"
                              class="column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                            >
                              {{ moduleP.name }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[2] &&
                        folderBrique.protocoles[2].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </a>
                </div>
              </div>
              <div
                v-else-if="
                  !folderBrique.name.includes('cardiaque') &&
                  !folderBrique.name.includes('hypnotique') &&
                  !folderBrique.name.includes('compassion') &&
                  !folderBrique.name.includes('Tests') &&
                  !folderBrique.name.includes('Soft') &&
                  !folderBrique.name.includes('Vie')
                "
              >
                <div
                  class="box is-margin-b-20 degrade"
                  v-if="!isPageUser && !isPageSessionGroup"
                >
                  <div class="columns">
                    <div
                      class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                    >
                      <figure
                        class="image is-128x128 brique"
                        v-if="folderBrique.resource"
                      >
                        <img
                          v-if="folderBrique.resource.url"
                          class="is-rounded brique-image"
                          :src="folderBrique.resource.url"
                        />
                        <img
                          v-else-if="folderBrique.resource._url"
                          class="is-rounded brique-image"
                          :src="folderBrique.resource._url"
                        />
                        <div class="brique-text">
                          <p class="is-size-7">{{ folderBrique.name }}</p>
                        </div>
                      </figure>
                    </div>
                    <div
                      class="column is-3-mobile is-3-tablet is-3-desktop is-3-widescreen column-border"
                      v-if="folderBrique.protocoles[1]"
                    >
                      <span class="has-text-black">
                        {{ folderBrique.protocoles[1].name }}
                      </span>
                      <div class="columns is-multiline">
                        <div
                          class="column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen"
                          :class="{ isPageUser: isPageUser }"
                          v-for="(moduleP, index) in folderBrique.protocoles[1]
                            .modules"
                          :key="moduleP.id"
                        >
                          <div class="cercle">
                            <strong :class="{ cercletext: !isPageUser }">
                              {{ index + 1 }}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen"
                      v-if="folderBrique.protocoles[2]"
                    >
                      <span class="has-text-black" style="font-size: 12px">
                        {{ folderBrique.protocoles[2].name }}
                      </span>
                      <div class="columns is-multiline">
                        <div
                          class="column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen"
                          :class="{ isPageUser: isPageUser }"
                          v-for="moduleP in folderBrique.protocoles[2].modules"
                          :key="moduleP.id"
                        >
                          <figure
                            v-if="moduleP.resource"
                            class="image is-48x48"
                          >
                            <img
                              v-if="moduleP.resource.url"
                              :src="moduleP.resource.url"
                            />
                            <img
                              v-else-if="moduleP.resource._url"
                              :src="moduleP.resource._url"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div
                      class="column is-3-mobile is-3-tablet is-3-desktop is-3-widescreen has-text-centered"
                    >
                      <a
                        class="button button-rounded is-primary"
                        @click="goAbonnement()"
                      >
                        {{ $t("souscrireA") }} <br />
                        {{ $t("laBrique") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- DEBUT ONGLET RESSOURCES PARTIE 1 - EXCEPTION RESSOUCES LONGS OU COURTS - ATTENTION INVERSION ORDRE PROTOCOLESE RESULTATS ET LIBRES -->

          <!--AFFICHAGE DEV-->

          <!-- <div v-if="theme.attributes.ordre === 3">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-20"
                :class="{
                  boxFolder:
                    (folderBrique.deverrouille &&
                      folderBrique.protocoles[3] &&
                      folderBrique.protocoles[3].deverrouille) ||
                    (folderBrique.deverrouille && !folderBrique.protocoles[3]),
                }"
                v-if="
                  folderBrique &&
                  (folderBrique.name.includes('cardiaque') ||
                    folderBrique.name.includes('hypnotique') ||
                    folderBrique.name.includes('compassion'))
                "
              >
                <div class="columns">
                  <div
                    class="column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen column-border img-brique-folderR"
                    style="border-radius: 15px; margin: 10% 2% 10% 2%"
                    :style="{ 'background-color': folderBrique.color }"
                  >
                    <router-link
                      v-if="
                        !isPageUser &&
                        !isPageSessionGroup &&
                        group.type_abonnement == 'gratuit'
                      "
                      class=""
                      to="/abonnement"
                    >
                      <div class="columns is-multiligne">
                        <div
                          class="column is-12 is-size-7 text-abonnezvous-expert"
                          style="
                            border-radius: 15px 15px 0px 0px;
                            background-color: #4a439e;
                            color: white;
                            margin-bottom: 15px;
                          "
                        >
                          {{ $t("abonnezVousPour") }} <br />
                          {{ $t("debloquerRessources") }}
                        </div>
                      </div>
                    </router-link>
                    <div
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? ''
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="brique-textR" style="color: white">
                        <p class="is-size-12">{{ folderBrique.name }}</p>
                      </div>
                    </div>
                    <div class="BSwitchBrique" v-if="folderBrique.protocoles[3]">
                      <b-switch
                        size="is-medium"
                        @click="unlock(folderBrique, 2)"
                        type="is-grey"
                        v-model="folderBrique.protocoles[3].deverrouille"
                      >
                        <span
                          style="color: white"
                          v-if="folderBrique.protocoles[3].deverrouille"
                          >Activé</span
                        >
                        <span style="color: white" v-else>Désactivé</span>
                      </b-switch>
                    </div>
                  </div>
                  <a
                    class="tooltip column is-12-mobile is-6-tablet is-5-desktop is-5-widescreen"
                    v-if="folderBrique.protocoles[3]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[3].deverrouille &&
                        (folderBrique.protocoles[3].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        (!folderBrique.protocoles[3].deverrouille_user &&
                          (isPageUser || isPageSessionGroup)) ||
                        (group.type_abonnement !== 'pulse' &&
                          (folderBrique.name.includes('cardiaque') ||
                            folderBrique.name.includes('hypnotique') ||
                            folderBrique.name.includes('compassion'))),
                    }"
                    @click="unlock(folderBrique, 3)"
                  >
                    <span class="tooltiptext"
                      >Activer/Désactiver le protocole</span
                    >
                    <div v-if="folderBrique.protocoles[3]" style="display: flex; justify-content: space-between">
                      <div
                        :label="
                          folderBrique.protocoles[3].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)
                            ? 'Cliquer pour activer/désactiver ce protocole'
                            : 'Exercices non accessibles'
                        "
                        position="is-top"
                      >
                        <span
                          v-if="
                            folderBrique.protocoles[3] &&
                            folderBrique.protocoles[3].name
                          "
                          class="has-text-black is-size-12"
                        >
                          {{ folderBrique.protocoles[3].name }}
                        </span>
                      </div>
                    </div>
                    <div v-if="folderBrique.protocoles[3]">
                      <div
                        :class="{}"
                        v-if="folderBrique.protocoles[3].modules"
                        v-for="(moduleP, index) in folderBrique.protocoles[3]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div class="columns is-vcentered">
                          <div
                            class="column is-12-mobile is-2-tablet is-2-desktop is-2-widescreen p-5"
                            :class="{
                              'is-3': isPageSessionGroup,
                            }"
                            v-if="!moduleP.name.includes('Pratique libre')"
                          >
                            <figure>
                              <div v-if="folderBrique.protocoles[3]"
                                class="cercleRessources"
                                :class="{
                                  cerclegreyRessources:
                                    !folderBrique.protocoles[3].deverrouille,
                                }"
                              >
                                <strong :class="{ cercletext: !isPageUser }">
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div>
                            <span
                              style="font-size: 12px"
                              class="column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                            >
                              {{ moduleP.name }}
                            </span>
                          </div>
                          <div
                            class="column has-text-black"
                            v-if="!moduleP.name.includes('Pratique libre')"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div> -->

          <!-- AFFICHAGE PROD-->
          <div v-if="theme.attributes.ordre === 3">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-20"
                :class="{
                  boxFolder:
                    (folderBrique.deverrouille &&
                      folderBrique.protocoles[2] &&
                      folderBrique.protocoles[2].deverrouille) ||
                    (folderBrique.deverrouille && !folderBrique.protocoles[2]),
                }"
                v-if="
                  folderBrique &&
                  (folderBrique.name.includes('cardiaque') ||
                    folderBrique.name.includes('hypnotique') ||
                    folderBrique.name.includes('compassion'))
                "
              >
                <div class="columns">
                  <div
                    class="column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen column-border img-brique-folderR"
                    style="border-radius: 15px; margin: 10% 2% 10% 2%"
                    :style="{ 'background-color': folderBrique.color }"
                  >
                    <router-link
                      v-if="
                        !isPageUser &&
                        !isPageSessionGroup &&
                        group.type_abonnement == 'gratuit'
                      "
                      class=""
                      to="/abonnement"
                    >
                      <div class="columns is-multiligne">
                        <div
                          class="column is-12 is-size-7 text-abonnezvous-expert"
                          style="
                            border-radius: 15px 15px 0px 0px;
                            background-color: #4a439e;
                            color: white;
                            margin-bottom: 15px;
                          "
                        >
                          {{ $t("abonnezVousPour") }} <br />
                          {{ $t("debloquerRessources") }}
                        </div>
                      </div>
                    </router-link>
                    <div
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? ''
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="brique-textR" style="color: white">
                        <p class="is-size-12">{{ folderBrique.name }}</p>
                      </div>
                    </div>
                    <div
                      class="BSwitchBrique"
                      v-if="folderBrique.protocoles[2]"
                    >
                      <b-switch
                        size="is-medium"
                        @click="unlock(folderBrique, 2)"
                        type="is-grey"
                        v-model="folderBrique.protocoles[2].deverrouille"
                      >
                        <span
                          style="color: white"
                          v-if="folderBrique.protocoles[2].deverrouille"
                          >Activé</span
                        >
                        <span style="color: white" v-else>Désactivé</span>
                      </b-switch>
                    </div>
                  </div>
                  <a
                    class="tooltip column is-12-mobile is-6-tablet is-5-desktop is-5-widescreen"
                    v-if="folderBrique.protocoles[2]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[2].deverrouille &&
                        (folderBrique.protocoles[2].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        (!folderBrique.protocoles[2].deverrouille_user &&
                          (isPageUser || isPageSessionGroup)) ||
                        (group.type_abonnement !== 'pulse' &&
                          (folderBrique.name.includes('cardiaque') ||
                            folderBrique.name.includes('hypnotique') ||
                            folderBrique.name.includes('compassion'))),
                    }"
                    @click="unlock(folderBrique, 2)"
                  >
                    <span class="tooltiptext"
                      >Activer/Désactiver le protocole</span
                    >
                    <div
                      v-if="folderBrique.protocoles[2]"
                      style="display: flex; justify-content: space-between"
                    >
                      <div
                        :label="
                          folderBrique.protocoles[2].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)
                            ? 'Cliquer pour activer/désactiver ce protocole'
                            : 'Exercices non accessibles'
                        "
                        position="is-top"
                      >
                        <span
                          v-if="
                            folderBrique.protocoles[2] &&
                            folderBrique.protocoles[2].name
                          "
                          class="has-text-black is-size-12"
                        >
                          {{ folderBrique.protocoles[2].name }}
                        </span>
                      </div>
                    </div>
                    <div v-if="folderBrique.protocoles[2]">
                      <div
                        :class="{}"
                        v-if="folderBrique.protocoles[2].modules"
                        v-for="(moduleP, index) in folderBrique.protocoles[2]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div class="columns is-vcentered" :key="index">
                          <div
                            class="column is-12-mobile is-2-tablet is-2-desktop is-2-widescreen p-5"
                            :class="{
                              'is-3': isPageSessionGroup,
                            }"
                            v-if="!moduleP.name.includes('Pratique libre')"
                          >
                            <figure>
                              <div
                                v-if="folderBrique.protocoles[2]"
                                class="cercleRessources"
                                :class="{
                                  cerclegreyRessources:
                                    !folderBrique.protocoles[2].deverrouille,
                                }"
                              >
                                <strong :class="{ cercletext: !isPageUser }">
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div>
                            <span
                              style="font-size: 12px"
                              class="column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                            >
                              {{ moduleP.name }}
                            </span>
                          </div>
                          <div
                            class="column has-text-black"
                            v-if="!moduleP.name.includes('Pratique libre')"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- DEBUT ONGLET RESSOURCES PARTIE 2 - EXCEPTION RESSOUCES LONGS ET COURTS -->
          <div v-if="theme.attributes.ordre === 3">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-10"
                :class="{ boxFolder: folderBrique.deverrouille  &&
                      folderBrique.protocoles[3] &&
                      folderBrique.protocoles[3].deverrouille}"
                v-if="
                  folderBrique &&
                  !folderBrique.name.includes('cardiaque') &&
                  !folderBrique.name.includes('hypnotique') &&
                  !folderBrique.name.includes('compassion') &&
                  !folderBrique.name.includes('Tests') &&
                  !folderBrique.name.includes('Soft') &&
                  !folderBrique.name.includes('Vie')
                "
              >
                <div class="columns">
                  <div
                    class="column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen column-border img-brique-folderR"
                    style="border-radius: 15px; margin: 10% 2% 10% 2%"
                    :style="{ 'background-color': folderBrique.color }"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? ''
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="brique-textR" style="color: white">
                        <p class="is-size-12">{{ folderBrique.name }}</p>
                      </div>
                    </b-tooltip>
                    <div
                      v-if="folderBrique.protocoles[3]"
                      class="BSwitchBrique"
                    >
                      <b-switch
                        @click="unlock(folderBrique, 3)"
                        size="is-medium"
                        type="is-grey"
                        v-model="folderBrique.protocoles[3].deverrouille"
                      >
                        <span
                          style="color: white"
                          v-if="folderBrique.protocoles[3].deverrouille"
                          >Activé</span
                        >
                        <span style="color: white" v-else>Désactivé</span>
                      </b-switch>
                    </div>
                  </div>

                  <a
                    class="column is-12-mobile is-4-tablet is4-desktop is-4-widescreen"
                    v-if="folderBrique.protocoles[3]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[3].deverrouille &&
                        (folderBrique.protocoles[3].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.protocoles[3].deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique, 3)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.protocoles[3].deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver ce protocole'
                          : 'Exercices non accessibles'
                      "
                      position="is-top"
                    >
                      <span class="has-text-black is-size-12">
                        {{ folderBrique.protocoles[3].name }} (court)
                      </span>
                    </b-tooltip>
                    <div>
                      <div
                        :class="{}"
                        v-for="exoP in folderBrique.protocoles[3]
                          .modulesDecouverte"
                        :key="exoP.id"
                        class="is-margin-t-10"
                      >
                        <div
                          :class="{}"
                          v-for="moduleP in exoP.linkDecouverte"
                          :key="moduleP.id"
                          class="is-margin-t-10"
                        >
                          <div class="columns is-vcentered">
                            <div
                              class="column is-12-mobile is-2-tablet is-3-desktop is-2-widescreen p-5"
                              :class="{
                                'is-3': isPageSessionGroup,
                              }"
                            >
                              <figure>
                                <div
                                  v-if="folderBrique.protocoles[3]"
                                  class="cercleRessources"
                                  :class="{
                                    cerclegreyRessources:
                                      !folderBrique.protocoles[3].deverrouille,
                                  }"
                                >
                                  <strong :class="{ cercletext: !isPageUser }">
                                  </strong>
                                </div>
                              </figure>
                            </div>
                            <div
                              class="isPageUser has-text-black is-12-mobile is-10-tablet is-8-desktop is-10-widescreen"
                            >
                              <b-tooltip
                                :label="
                                  folderBrique.protocoles[3]
                                    .deverrouille_user ||
                                  (!isPageUser && !isPageSessionGroup)
                                    ? 'Cliquer pour activer/désactiver ce protocole'
                                    : 'Exercices non accessibles'
                                "
                                position="is-top"
                              >
                                <span
                                  class="has-text-black"
                                  style="font-size: 12px"
                                >
                                  {{
                                    moduleP.composite_file.short_file.sub_name
                                  }}
                                </span>
                              </b-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                  <a
                    class="column is-12-mobile is-4-tablet is-4-desktop is-5-widescreen"
                    v-if="folderBrique.protocoles[3]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[3].deverrouille &&
                        (folderBrique.protocoles[3].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.protocoles[3].deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique, 3)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.protocoles[3].deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver ce protocole'
                          : 'Exercices non accessibles'
                      "
                      position="is-top"
                    >
                      <span class="has-text-black is-size-12">
                        {{ folderBrique.protocoles[3].name }} (long)
                      </span>
                    </b-tooltip>
                    <div>
                      <div
                        :class="{}"
                        v-for="exoP in folderBrique.protocoles[3]
                          .modulesDecouverte"
                        :key="exoP.id"
                        class="is-margin-t-10"
                      >
                        <div
                          :class="{}"
                          v-for="moduleE in exoP.linkDecouverte"
                          :key="moduleE.id"
                          class="is-margin-t-10"
                        >
                          <div
                            class="columns is-vcentered"
                            v-if="moduleE.composite_file.long_file"
                          >
                            <div
                              class="column is-12-mobile is-2-tablet is-3-desktop is-2-widescreen p-5"
                              :class="{
                                'is-3': isPageSessionGroup,
                              }"
                            >
                              <figure>
                                <div
                                  v-if="folderBrique.protocoles[3]"
                                  class="cercleRessources"
                                  :class="{
                                    cerclegreyRessources:
                                      !folderBrique.protocoles[3].deverrouille,
                                  }"
                                >
                                  <strong :class="{ cercletext: !isPageUser }">
                                  </strong>
                                </div>
                              </figure>
                            </div>
                            <div
                              class="isPageUser has-text-black is-12-mobile is-10-tablet is-8-desktop is-10-widescreen"
                            >
                              <b-tooltip
                                :label="
                                  folderBrique.protocoles[3]
                                    .deverrouille_user ||
                                  (!isPageUser && !isPageSessionGroup)
                                    ? 'Cliquer pour activer/désactiver ce protocole'
                                    : 'Exercices non accessibles'
                                "
                                position="is-top"
                              >
                                <span
                                  class="has-text-black"
                                  style="font-size: 12px"
                                >
                                  {{
                                    moduleE.composite_file.long_file.sub_name
                                  }}
                                </span>
                              </b-tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>

          <!-- Onglet INCONNU -->
          <!-- <div v-if="theme.attributes.ordre === 2">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-20"
                :class="{ boxFolder: folderBrique.deverrouille }"
              >
                <div class="columns">
                  <a
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                    :class="{
                      verrouille:
                        !folderBrique.deverrouille &&
                        (folderBrique.deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? ''
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="img-brique-folder">
                        <figure
                          class="image container is-128x128 brique is-margin-l-0"
                          :class="{
                            'is-margin-t-50':
                              folderBrique.protocoles[1] !== undefined &&
                              folderBrique.protocoles[1].modules.length == 4,
                            'is-margin-t-90':
                              folderBrique.protocoles[1] !== undefined &&
                              folderBrique.protocoles[1].modules.length >= 5,
                          }"
                        >
                          <img
                            v-if="
                              folderBrique.resource &&
                              folderBrique.resource._url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource._url"
                          />
                          <img
                            v-else-if="
                              folderBrique.resource && folderBrique.resource.url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource.url"
                          />
                          <div class="brique-text">
                            <p class="is-size-7 text-brique">
                              {{ folderBrique.name }}
                            </p>
                          </div>
                        </figure>
                      </div>
                    </b-tooltip>
                  </a>
                  <a
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                    v-if="folderBrique.protocoles[1]"
                    :class="{
                      verrouille:
                        !folderBrique.protocoles[1].deverrouille &&
                        (folderBrique.protocoles[1].deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.protocoles[1].deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique, 1)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.protocoles[1].deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver ce protocole'
                          : 'Exercices non accessibles'
                      "
                      position="is-top"
                    >
                      <span class="has-text-black is-size-12">
                        {{ folderBrique.protocoles[1].name }}
                      </span>
                    </b-tooltip>
                    <div>
                      <div
                        :class="{}"
                        v-for="(moduleP, index) in folderBrique.protocoles[1]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div class="columns is-vcentered">
                          <div
                            class="column is-12-mobile is-4-tablet is-3-desktop is-3-widescreen p-5"
                            :class="{
                              'is-4': isPageSessionGroup,
                            }"
                          >
                            <figure>
                              <div
                                class="cercle"
                                :class="{
                                  cerclegrey:
                                    !folderBrique.protocoles[1].deverrouille,
                                }"
                              >
                                <strong :class="{ cercletext: !isPageUser }">
                                  {{ index + 1 }}
                                </strong>
                              </div>
                            </figure>
                          </div>
                          <div  style='font-size:12px'
                            class="column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                          >
                            {{ moduleP.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[2] &&
                        folderBrique.protocoles[2].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </a>
                  <router-link
                    v-if="
                      !isPageUser &&
                      !isPageSessionGroup &&
                      group.type_abonnement == 'gratuit'
                    "
                    class="button is-primary button-abonnezvous-expert"
                    to="/abonnement"
                  >
                    <span class="is-size-7">
                      {{ $t("abonnezVousPour") }} <br />
                      {{ $t("debloquerProtocole") }}
                    </span>
                  </router-link>

                  <a
                    class="column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen"
                    v-if="folderBrique.protocoles[2]"
                    :class="{
                      verrouille:
                        (!folderBrique.protocoles[2].deverrouille &&
                          (folderBrique.protocoles[2].deverrouille_user ||
                            (!isPageUser && !isPageSessionGroup))) ||
                        group.type_abonnement === 'gratuit',
                      disabled:
                        (!folderBrique.protocoles[2].deverrouille_user &&
                          (isPageUser || isPageSessionGroup)) ||
                        group.type_abonnement === 'gratuit',
                    }"
                    @click="unlock(folderBrique, 2)"
                  >
                    <div>
                      <span class="has-text-black">
                        {{ folderBrique.protocoles[2].name }}
                      </span>
                      <div
                        class="has-text-centered row"
                        :class="{
                          'is-margin-l-0': !isPageUser,
                          'is-margin-t-50':
                            folderBrique.protocoles[2].modules.length == 4,
                          'is-margin-t-90':
                            folderBrique.protocoles[2].modules.length >= 5,
                        }"
                      >
                        <img
                          class="is-rounded has-text-centered"
                          style="width: 80px"
                          src="../../../assets/img/icone_protocole_Libre_active.png"
                        />
                      </div>
                    </div>
                  </a>
                </div>
              </div>
              <div></div>
            </div>
          </div> -->
          <!-- Onglet INCONNU -->
          <!-- <div v-if="theme.attributes.ordre === 5">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-20"
                :class="{ boxFolder: folderBrique.deverrouille }"
                v-if="folderBrique && folderBrique.name.includes('Tests')"
              >
                <div class="columns">
                  <a
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                    :class="{
                      verrouille:
                        !folderBrique.deverrouille &&
                        (folderBrique.deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver cette thématique'
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="img-brique-folder">
                        <figure
                          class="image container is-128x128 brique is-margin-l-0"
                          :class="{
                            'is-margin-t-50':
                              folderBrique.protocoles.length >= 5,
                            'is-margin-t-90':
                              folderBrique.protocoles.length >= 8,
                          }"
                        >
                          <img
                            v-if="
                              folderBrique.resource &&
                              folderBrique.resource._url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource._url"
                          />
                          <img
                            v-else-if="
                              folderBrique.resource && folderBrique.resource.url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource.url"
                          />
                          <div class="brique-text">
                            <p class="is-size-7">{{ folderBrique.name }}</p>
                          </div>
                        </figure>
                      </div>
                    </b-tooltip>
                  </a>
                  <a
                    class="column is-12-mobile is-9-tablet is-9-desktop is-9-widescreen"
                  >
                    <a
                      v-for="(protocole, index) in folderBrique.protocoles"
                      :key="index"
                      @click="unlock(folderBrique, index)"
                    >
                      <div
                        class="columns is-vcentered"
                        :class="{
                          verrouille:
                            !protocole.deverrouille &&
                            (protocole.deverrouille_user ||
                              (!isPageUser && !isPageSessionGroup)),
                          disabled:
                            !protocole.deverrouille_user &&
                            (isPageUser || isPageSessionGroup),
                        }"
                      >
                        <div
                          class="column is-1"
                          :class="{
                            'is-4': isPageUser,
                            'is-3': isPageSessionGroup,
                          }"
                        >
                          <figure>
                            <div
                              class="cercle-small"
                              :class="{
                                'cercle-small-grey': !protocole.deverrouille,
                              }"
                            ></div>
                          </figure>
                        </div>
                        <div class="column has-text-black">
                          {{ protocole.name }}
                        </div>
                      </div>
                      <hr
                        class="hr-folder"
                        v-if="folderBrique.protocoles.length - 1 !== index"
                      />
                    </a>
                  </a>
                </div>
              </div>
            </div>
          </div> -->
          <!-- Onglet QCM evalution hetero - auto -->
          <div v-if="theme.attributes.ordre === 7">
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-20"
                :class="{ boxFolder: folderBrique.deverrouille }"
              >
                <div class="columns">
                  <a
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                    :class="{
                      verrouille:
                        !folderBrique.deverrouille &&
                        (folderBrique.deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver cette thématique'
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="img-brique-folder">
                        <router-link
                          v-if="
                            !isPageUser &&
                            !isPageSessionGroup &&
                            group.type_abonnement == 'gratuit'
                          "
                          class=""
                          to="/abonnement"
                        >
                          <div class="columns is-multiligne">
                            <div
                              class="column is-12 is-size-7 text-abonnezvous-expert"
                              style="
                                border-radius: 15px 15px 0px 0px;
                                background-color: #4a439e;
                                color: white;
                              "
                            >
                              {{ $t("abonnezVousPour") }} <br />
                              {{ $t("debloquerQCM") }}
                            </div>
                          </div>
                        </router-link>
                        <figure
                          class="image container is-128x128 brique is-margin-l-0"
                          :class="{
                            'is-margin-t-0':
                              folderBrique.protocoles[0] !== undefined &&
                              folderBrique.protocoles[0].modules.length == 4,
                            'is-margin-t-90':
                              folderBrique.protocoles[0] !== undefined &&
                              folderBrique.protocoles[0].modules.length >= 5,
                          }"
                        >
                          <img
                            v-if="
                              folderBrique.resource &&
                              folderBrique.resource._url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource._url"
                          />
                          <img
                            v-else-if="
                              folderBrique.resource && folderBrique.resource.url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource.url"
                          />
                          <div class="brique-text">
                            <p class="is-size-12">{{ folderBrique.name }}</p>
                          </div>
                        </figure>
                      </div>
                    </b-tooltip>
                  </a>
                  <a
                    class="column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen column-border"
                    v-if="folderBrique.protocoles[0]"
                    :class="{
                      verrouille:
                        !folderBrique.deverrouille ||
                        (!folderBrique.protocoles[0].deverrouille &&
                          (folderBrique.protocoles[0].deverrouille_user ||
                            (!isPageUser && !isPageSessionGroup))),
                      disabled:
                        !folderBrique.protocoles[0].deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.protocoles[0].deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer sur le questionnaire pour activer/désactiver'
                          : 'Exercices non accessibles'
                      "
                      position="is-top"
                    >
                      <span class="has-text-black is-size-12">
                        {{ folderBrique.protocoles[0].name }}
                      </span>
                    </b-tooltip>
                    <div>
                      <div
                        :class="{ isPageUser: isPageUser }"
                        v-for="(moduleP, index) in folderBrique.protocoles[0]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div
                          :class="{
                            disabled:
                              getAccesWithAbonnement(group.type_abonnement) <
                              getAccesWithAbonnement(
                                moduleP.link.type_abonnement
                              ),
                          }"
                          class="columns is-vcentered"
                        >
                          <div
                            class="column is-3"
                            :class="{
                              'is-6': isPageUser,
                              'is-4': isPageSessionGroup,
                            }"
                          >
                            <!-- <b-checkbox style="color:red !important;background-color:red !important;" @click="unlock(folderBrique, 0, moduleP)"
                          v-model="moduleP.deverrouille"
                          :class="{ cercletext: !isPageUser }"
                        >
                        </b-checkbox>  -->
                            <div class="round">
                              <input
                                type="checkbox"
                                :checked="
                                  moduleP.deverrouille &&
                                  folderBrique.deverrouille
                                "
                                :id="'moduleP-checkbox-' + index"
                              />
                              <label
                                for="moduleP-checkbox"
                                @click="unlock(folderBrique, 0, moduleP)"
                              ></label>
                            </div>
                          </div>
                          <div
                            class="column has-text-black"
                            style="font-size: 12px"
                          >
                            {{ moduleP.name }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[0] &&
                        folderBrique.protocoles[0].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </a>

                  <a
                    class="column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen"
                    v-if="folderBrique.protocoles[1]"
                    :class="{
                      verrouille:
                        !folderBrique.deverrouille ||
                        (!folderBrique.protocoles[1].deverrouille &&
                          (folderBrique.protocoles[1].deverrouille_user ||
                            (!isPageUser && !isPageSessionGroup))),
                    }"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.protocoles[1].deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer sur le questionnaire pour activer/désactiver'
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-top"
                    >
                      <span class="has-text-black is-size-12">
                        {{ folderBrique.protocoles[1].name }}
                      </span>
                    </b-tooltip>
                    <div>
                      <div
                        :class="{ isPageUser: isPageUser }"
                        v-for="(moduleP, index) in folderBrique.protocoles[1]
                          .modules"
                        :key="moduleP.id"
                        class="is-margin-t-10"
                      >
                        <div
                          :class="{
                            disabled:
                              getAccesWithAbonnement(group.type_abonnement) <
                              getAccesWithAbonnement(
                                moduleP.link.type_abonnement
                              ),
                          }"
                          class="columns is-vcentered"
                          v-if="moduleP.fileIsNotEval"
                        >
                          <div
                            class="column is-3"
                            :class="{
                              'is-6': isPageUser,
                              'is-4': isPageSessionGroup,
                            }"
                          >
                            <!-- <b-checkbox
                              is-cyan
                              v-model="moduleP.deverrouille"
                              :disabled="!moduleP.deverrouille"
                              :class="{ cercletext: !isPageUser }" @click="unlock(folderBrique, 1, moduleP)
                            >
                            </b-checkbox> -->
                            <div class="round">
                              <input
                                type="checkbox"
                                :checked="
                                  moduleP.deverrouille &&
                                  folderBrique.deverrouille
                                "
                                :id="'moduleP-checkbox-' + index"
                              />
                              <label
                                for="moduleP-checkbox"
                                @click="unlock(folderBrique, 1, moduleP)"
                              ></label>
                            </div>
                          </div>
                          <div
                            class="column has-text-black"
                            style="font-size: 12px"
                          >
                            {{ moduleP.name + " " + moduleP.deverrouille }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="
                        folderBrique.protocoles[1] &&
                        folderBrique.protocoles[1].modules
                      "
                      class="is-pulled-right is-size-7"
                    ></div>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="
              theme.attributes.ordre === 9 ||
              theme.attributes.ordre === 6 ||
              theme.attributes.ordre === 8
            "
          >
            <div
              v-for="folderBrique in getFoldersByTheme(theme.id)"
              :key="folderBrique.id"
              class="is-margin-t-10"
            >
              <div
                class="box is-margin-b-20"
                :class="{ boxFolder: folderBrique.deverrouille }"
              >
                <div class="columns">
                  <a
                    class="column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"
                    :class="{
                      verrouille:
                        !folderBrique.deverrouille &&
                        (folderBrique.deverrouille_user ||
                          (!isPageUser && !isPageSessionGroup)),
                      disabled:
                        !folderBrique.deverrouille_user &&
                        (isPageUser || isPageSessionGroup),
                    }"
                    @click="unlock(folderBrique)"
                  >
                    <b-tooltip
                      :label="
                        folderBrique.deverrouille_user ||
                        (!isPageUser && !isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver cette thématique'
                          : 'Exercices non accessibles, voir la page &quot;Mes activités&quot; pour la gestion des accès aux exercices'
                      "
                      position="is-right"
                    >
                      <div class="img-brique-folder">
                        <figure
                          class="image container is-128x128 brique is-margin-l-0"
                          :class="{
                            'is-margin-t-50':
                              folderBrique.protocoles.length >= 5,
                            'is-margin-t-90':
                              folderBrique.protocoles.length >= 8,
                          }"
                        >
                          <img
                            v-if="
                              folderBrique.resource &&
                              folderBrique.resource._url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource._url"
                          />
                          <img
                            v-else-if="
                              folderBrique.resource && folderBrique.resource.url
                            "
                            class="is-rounded brique-image"
                            :src="folderBrique.resource.url"
                          />
                          <div class="brique-text">
                            <p class="is-size-7">{{ folderBrique.name }}</p>
                          </div>
                        </figure>
                      </div>
                    </b-tooltip>
                  </a>
                  <a
                    class="column is-12-mobile is-9-tablet is-9-desktop is-9-widescreen"
                  >
                    <a
                      v-for="(protocole, index) in folderBrique.protocoles"
                      :key="index"
                      @click="unlock(folderBrique, index)"
                    >
                      <div
                        class="columns is-vcentered"
                        :class="{
                          verrouille:
                            !protocole.deverrouille &&
                            (protocole.deverrouille_user ||
                              (!isPageUser && !isPageSessionGroup)),
                          disabled:
                            !protocole.deverrouille_user &&
                            (isPageUser || isPageSessionGroup),
                        }"
                      >
                        <div
                          class="column is-1"
                          :class="{
                            'is-4': isPageUser,
                            'is-3': isPageSessionGroup,
                          }"
                        >
                          <figure>
                            <div
                              class="cercle-small"
                              :class="{
                                'cercle-small-grey': !protocole.deverrouille,
                              }"
                            ></div>
                          </figure>
                        </div>
                        <div class="column has-text-black">
                          {{ protocole.name }}
                        </div>
                      </div>
                      <hr
                        class="hr-folder"
                        v-if="folderBrique.protocoles.length - 1 !== index"
                      />
                    </a>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </template>
    </b-tabs>

    <b-modal
      :width="1200"
      :active.sync="isCardInformationOpen"
      @close="isCardInformationOpen = false"
    >
      <information-activite-component
        :descriptionSelected="descriptionSelected"
        :videoSelected="videoSelected"
        @closeModal="isCardInformationOpen = false"
      />
    </b-modal>
  </div>
</template>

<script>
import { unlockFolder } from "@/services/folders/unlockFolder.js";
import informationActiviteComponent from "./activites/informationActivite.vue";

export default {
  name: "folders",
  props: [
    "foldersBrique",
    "group",
    "isPageUser",
    "isPageSessionGroup",
    "themes",
  ],
  components: {
    informationActiviteComponent: informationActiviteComponent,
  },
  data() {
    return {
      abonnements: {
        1: "gratuit",
        2: "play",
        3: "pulse",
      },
      isSelect: false,
      misAJour: true,
      isBtnExerciceLibreSelect: false,
      openTherapiesNonMedicamenteuses: true,
      openAccompagnementPsychophysiologique: true,
      openEvaluationsPsychotechniques: true,
      isCardInformationOpen: false,
      descriptionSelected: "",
      videoSelected: "",
      ActiverDésactiverBrique: true,
    };
  },
  methods: {
    RecupBriqueData(e) {
      console.log(e);
    },
    toggleCheckbox(e) {
      e.deverrouille = !e.deverrouille;
    },

    getAccesWithAbonnement(type_abonnement) {
      if (type_abonnement === this.abonnements[1]) {
        return 1;
      }
      if (type_abonnement === this.abonnements[2]) {
        return 2;
      }
      if (type_abonnement === this.abonnements[3]) {
        return 3;
      }
    },
    getFoldersByTheme(monTheme) {
      //console.log('Thême ', monTheme)
      return this.foldersBrique.filter((el) => {
        if (el.themes.length < 2) {
          return el.themes[0].objectId === monTheme;
        }
        return (
          el.themes[0].objectId === monTheme ||
          el.themes[1].objectId === monTheme
        );
      });
    },
    async unlock(folderBrique, num, module) {
      if (
        (this.isPageUser || this.isPageSessionGroup) &&
        !folderBrique.deverrouille_user
      ) {
        return;
      }
      if (num >= 0) {
        if (
          (this.isPageUser || this.isPageSessionGroup) &&
          !folderBrique.protocoles[num].deverrouille_user
        ) {
          return;
        }
      }
      if (this.isSelect || this.isBtnExerciceLibreSelect) {
        this.isSelect = false;
        this.isBtnExerciceLibreSelect = false;
        return;
      }
      const props = {
        brique: folderBrique,
        num: num,
        module: module,
      };

      await unlockFolder(props, this.group.type_abonnement, true);

      //this.$emit("unlockProtocol", props);
    },
    goAbonnement() {
      this.$emit("goAbonnement");
    },
    openInformation(description, video) {
      this.descriptionSelected = description;
      this.videoSelected = video;
      this.isCardInformationOpen = true;
    },
    unlockStep(folderBrique, num) {
      if (this.group.type_abonnement === "gratuit" && num === 1) {
        return;
      }
      this.misAJour = false;
      if (!folderBrique.protocoles[num].deverrouille) {
        this.isSelect = false;
        this.misAJour = true;
        return;
      }
      folderBrique.protocoles[num].verrouillage_etape =
        !folderBrique.protocoles[num].verrouillage_etape;
      this.isSelect = true;
      this.misAJour = true;
    },
  },
  watch: {
    misAJour() {
      this.misAJour = true;
    },
  },
};
</script>

<style>
.p-5 {
  padding: 5px;
}

.cercle {
  padding-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #239380;
  color: white;
}
.cercleRessources {
  padding-top: 6px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background: #239380;
  color: white;
}

.cercletext {
  margin-left: 15px;
}

.cerclegrey {
  padding-top: 6px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: grey;
  color: white;
}
.cerclegreyRessources {
  padding-top: 6px;
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background: grey;
  color: white;
}

.cercle-small {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: #239380;
  color: white;
}

.cercle-small-grey {
  width: 30px;
  height: 30px;
  border-radius: 20px;
  background: grey;
  color: white;
}

.boxFolder {
  border: 5px solid #239380;
}

.image.is-128x128 {
  height: 80%;
  width: 100%;
}

.hr-folder {
  margin: 0;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tabs.is-toggle li.is-active a {
  background-color: #239380;
  border-color: #239380;
  color: white;
  z-index: 1;
}

.tabs.is-toggle a span {
  font-size: medium;
}

.custom-switch .b-switch-check {
  background-color: #239380 !important;
}
.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: #7957d5;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 50%;
  left: 50%;
  margin-left: -60px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.round {
  position: relative;
}

.round label {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  left: 0;
  position: absolute;
  top: 0;
  width: 28px;
}

.round label:after {
  border: 2px solid #fff;
  border-top: none;
  border-right: none;
  content: "";
  height: 6px;
  left: 7px;
  opacity: 0;
  position: absolute;
  top: 8px;
  transform: rotate(-45deg);
  width: 12px;
}

.round input[type="checkbox"] {
  visibility: hidden;
}

.round input[type="checkbox"]:checked + label {
  background-color: #239380;
  border-color: #239380;
}

.round input[type="checkbox"]:checked + label:after {
  opacity: 1;
}
.BSwitchBrique {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
}
</style>
