<template>
  <div>
    <iframe
      style="display: none"
      v-if="resilie"
      id="idFramePaiement"
      name="nomFramePaiement"
      :src="url"
    ></iframe>
    <a @click="resilier()" class="button is-cyan is-pulled-right">Confirmer</a>
  </div>
</template>

<script>
import { CONFIG_PAIEMENT } from "@/configuration/paiementConf.js";
import initialisationResiliation from "@/services/paiement/initialisationResiliation";

export default {
  name: "formulaire-paiement",
  props: ["resiliationData"],
  data() {
    return {
      transactionData: this.resiliationData,
      tpe: {
        CMCIC_TPE: CONFIG_PAIEMENT.CMCIC_TPE,
        CMCIC_CODESOCIETE: CONFIG_PAIEMENT.CMCIC_CODESOCIETE,
        CMCIC_CLE: CONFIG_PAIEMENT.CMCIC_CLE,
        CMCIC_VERSION: CONFIG_PAIEMENT.CMCIC_VERSION,
        CMCIC_BANK: CONFIG_PAIEMENT.CMCIC_BANK,
        CMCIC_LNG: CONFIG_PAIEMENT.CMCIC_LNG,
        CMCIC_CURRENCY: CONFIG_PAIEMENT.CMCIC_CURRENCY,
        CMCIC_SERVEUR: CONFIG_PAIEMENT.CMCIC_SERVEUR,
        CMCIC_URL_RETOUR: CONFIG_PAIEMENT.CMCIC_URL_RETOUR,
        CMCIC_URLOK: CONFIG_PAIEMENT.CMCIC_URLOK,
        CMCIC_URLKO: CONFIG_PAIEMENT.CMCIC_URLKO,
        test: CONFIG_PAIEMENT.TEST,
        CMCIC_URL_MONETICO: CONFIG_PAIEMENT.CMCIC_URL_MONETICO,
      },
      TPE: {},
      DATA: {},
      postOptions: {
        host: "",
        path: "",
        method: "POST",
      },
      mac: null,
      url: null,
      resilie: false,
    };
  },
  methods: {
    initResiliation() {
      const ResponseInitialisationPaiement = initialisationResiliation(
        this.transactionData,
        this.tpe
      );
      this.DATA = ResponseInitialisationPaiement.transactionData;
      this.mac = ResponseInitialisationPaiement.mac;
      this.TPE = ResponseInitialisationPaiement.transactionTpe;
      this.postOptions = ResponseInitialisationPaiement.postOptions;
      this.url =
        this.TPE.CMCIC_URL_MONETICO_RESILIATION +
        "version=" +
        this.TPE.CMCIC_VERSION +
        "&TPE=" +
        this.TPE.CMCIC_TPE +
        "&date=" +
        this.DATA.date +
        "&date_commande=" +
        this.DATA.date_commande +
        "&montant=" +
        this.DATA.amount +
        "" +
        this.TPE.CMCIC_CURRENCY +
        "&montant_a_capturer=0EUR&montant_deja_capture=0EUR&montant_restant=0EUR&stoprecurrence=OUI&reference=" +
        this.DATA.reference +
        "&lgue=" +
        this.TPE.CMCIC_LNG +
        "&societe=" +
        this.TPE.CMCIC_CODESOCIETE +
        "&MAC=" +
        this.mac;
    },
    async resilier() {
      const loadingComponent = this.$buefy.loading.open();
      this.resilie = true;
      setTimeout(() => {
        loadingComponent.close();
        this.$emit("resilier");
      }, 1000);
    },
  },
  watch: {
    mac() {
      this.TPE.mac = this.mac;
    },
  },
  mounted() {
    this.initResiliation();
  },
};
</script>

<style lang="scss" scoped></style>
