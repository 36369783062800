<template>
  <div class="is-iframe-ani">
    <iframeAni
      :key="refreshKey"
      @refresh-component="refreshComponent"
    ></iframeAni>
  </div>
</template>

<script>
// import iframeAni from "@/components/ani.vue";
import iframeAni from "@/components/ani/aniChart.vue";

export default {
  name: "utilisateurs",
  components: {
    iframeAni: iframeAni,
  },
  data() {
    return {
      refreshKey: 0,
    };
  },
  computed: {},
  methods: {
    refreshComponent() {
      this.refreshKey++; // Incrémente la valeur de refreshKey pour déclencher le rafraîchissement du composant
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
.is-iframe-ani {
  margin: 0px;
  padding: 12px;
  padding-top: 100px;
}
@media (min-width: 900px) and (max-width: 1215px) {
  .is-iframe-ani {
    padding-left: 25px;
  }
}
@media (min-width: 769px) and (max-width: 900px) {
  .is-iframe-ani {
    padding-left: 25px;
  }
}
@media (min-width: 500px) and (max-width: 769px) {
  .is-iframe-ani {
    padding-left: 35px;
  }
}
</style>
