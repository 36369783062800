import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";

export async function responseQuestionnaryResiliation(questionnaire, group) {
  ParseConfig();

  const ObjectQuestionnaireResiliation = Parse.Object.extend(
    "questionnaire_resiliation"
  );
  const ObjectUserGroup = Parse.Object.extend("user_group");

  const questionnaireResiliation = new ObjectQuestionnaireResiliation();

  const QueryUserGroup = new Parse.Query(ObjectUserGroup);

  QueryUserGroup.equalTo("objectId", group.objectId);
  const userGroup = await QueryUserGroup.first();

  userGroup.id = group.objectId;

  if (questionnaire.autre) {
    questionnaireResiliation.set("autre", questionnaire.texte);
  }
  questionnaireResiliation.set("complexe", questionnaire.complexe);
  questionnaireResiliation.set(
    "problemes_techniques",
    questionnaire.problemes_techniques
  );
  questionnaireResiliation.set("cout", questionnaire.cout);
  questionnaireResiliation.set("inutilise", questionnaire.inutilise);
  questionnaireResiliation.set("group", userGroup);

  userGroup.set("resilie", true);

  await userGroup.save();
  await questionnaireResiliation.save();
}
