<template>
  <div>
    <div>
      <a
        class="has-text-weight-semibold has-text-black"
        @click="
          openTherapiesNonMedicamenteuses = !openTherapiesNonMedicamenteuses
        "
      >
        {{ $t("therapieNonMedicamenteuses") }}
        <b-icon
          v-if="openTherapiesNonMedicamenteuses"
          pack="fas"
          icon="sort-down"
        ></b-icon>
        <b-icon v-else pack="fas" icon="caret-right"></b-icon>
      </a>
      <b-collapse
        style="
          width: 3000px;
          overflow-y: scroll;
          scrollbar-color: rebeccapurple green;
          scrollbar-width: thin;
        "
        :open.sync="openTherapiesNonMedicamenteuses"
      >
        <br />
        <div v-if="!foldersUtilisateursSelected">
          <p class="brique-text-attente" v-if="!foldersTherapie">
            {{ $t("exerciceEnCoursDeChargement") }}
          </p>
          <p
            class="brique-text-attente"
            v-else-if="foldersTherapie && !foldersTherapie.length"
          >
            {{ $t("tableauDeBordAucuneThematique") }}
          </p>
          <presentation-protocole
            class="columns is-multiline"
            style="width: 2500px"
            :folders="foldersTherapie"
            :nbUtilisateur="nbUtilisateur"
            :numTheme="1"
          ></presentation-protocole>
        </div>
        <div v-else>
          <p
            class="brique-text-attente"
            v-if="
              foldersUtilisateursSelected.foldersTherapie &&
              !foldersUtilisateursSelected.foldersTherapie.length
            "
          >
            {{ $t("tableauDeBordAucuneThematique") }}
          </p>
          <presentation-protocole
            class="columns is-multiline"
            style="width: 2500px"
            :folders="foldersTherapie"
            :numTheme="1"
          ></presentation-protocole>
        </div>
      </b-collapse>
      <hr />
      <a
        class="has-text-weight-semibold has-text-black"
        @click="
          openAccompagnementPsychophysiologique =
            !openAccompagnementPsychophysiologique
        "
      >
        {{ $t("accompagnementPsychophysiologique") }}
        <b-icon
          v-if="openAccompagnementPsychophysiologique"
          pack="fas"
          icon="sort-down"
        >
        </b-icon>
        <b-icon v-else pack="fas" icon="caret-right"></b-icon>
      </a>
      <b-collapse
        :open.sync="openAccompagnementPsychophysiologique"
        style="
          width: 3000px;
          overflow-x: scroll;
          scrollbar-color: rebeccapurple green;
          scrollbar-width: thin;
        "
      >
        <br />

        <div
          class="columns is-multiline"
          style="width: 2500px"
          v-if="!foldersUtilisateursSelected"
        >
          <p class="brique-text-attente" v-if="!foldersCardiaque">
            {{ $t("exerciceEnCoursDeChargement") }}
          </p>
          <p
            class="brique-text-attente"
            v-else-if="foldersCardiaque && !foldersCardiaque.length"
          >
            {{ $t("tableauDeBordAucuneThematique") }}
          </p>
          <div
            class="column is-2 is-multiline folders-dashboard"
            v-for="folderBrique in foldersCardiaque"
            :key="folderBrique.id"
          >
            <div>
              <p class="has-text-centered is-size-5 has-text-weight-semibold">
                {{ folderBrique.name.toUpperCase() }}
              </p>
              <br />
              <div
                v-for="folderProtocole in folderBrique.foldersProtocole.filter(
                  (e) => {
                    return e.aAfficher;
                  }
                )"
                :key="folderProtocole.id"
                class="is-margin-b-20"
              >
                <div
                  v-if="
                    folderProtocole.name !== 'Entraînement libre' &&
                    folderProtocole.name !== 'Découverte vidéo de la technique'
                  "
                >
                  <div>
                    <div>
                      <p class="brique-text-avancement">
                        {{ folderProtocole.name }}
                      </p>
                    </div>
                    <chart-brique
                      :data="[
                        Math.round(
                          folderProtocole.sommeCabinet / nbUtilisateur.cabinet
                        ),
                        Math.round(
                          folderProtocole.sommeLicence / nbUtilisateur.licence
                        ),
                        Math.round(
                          folderProtocole.sommeAffiliation /
                            nbUtilisateur.affiliation
                        ),
                      ]"
                      :moyenne="
                        Math.round(folderProtocole.somme / nbUtilisateur.total)
                      "
                    ></chart-brique>
                  </div>
                  <p
                    class="has-text-centered is-size-5 has-text-weight-semibold"
                    v-if="nbUtilisateur.total && folderProtocole.somme"
                  >
                    Moyenne :
                    {{
                      Math.round(folderProtocole.somme / nbUtilisateur.total)
                    }}
                    %
                  </p>
                  <p
                    class="has-text-centered is-size-5 has-text-weight-semibold"
                    v-else
                  >
                    0 %
                  </p>
                  <hr />
                </div>
              </div>
              <div
                v-if="folderBrique.name.includes('cardiaque')"
                class="has-text-centered is-margin-t-20"
              >
                <a class="button is-cyan" @click="isCardResultat = true">
                  {{ $t("resultats") }}
                </a>
              </div>
              <div
                v-if="folderBrique.name.includes('cardiaque')"
                class="has-text-centered is-margin-t-20"
              >
                <a
                  v-if="statistiques"
                  class="button is-cyan"
                  @click="isCardStatistiques = true"
                >
                  {{ $t("statistiques") }}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-multiline" style="width: 2500px" v-else>
          <p
            class="brique-text-attente"
            v-if="
              foldersUtilisateursSelected.foldersCardiaque &&
              !foldersUtilisateursSelected.foldersCardiaque.length
            "
          >
            {{ $t("tableauDeBordAucuneThematique") }}
          </p>
          <div
            class="column is-2 is-multiline folders-dashboard"
            v-for="folderBrique in foldersCardiaque.filter((e) => {
              return e.aAfficher;
            })"
            :key="folderBrique.id"
          >
            <p class="has-text-centered is-size-5 has-text-weight-semibold">
              {{ folderBrique.name.toUpperCase() }}
            </p>
            <br />
            <div
              v-for="folderProtocole in folderBrique.foldersProtocole.filter(
                (e) => {
                  return e.aAfficher;
                }
              )"
              :key="folderProtocole.id"
              class="is-margin-b-20"
            >
              <div
                v-if="
                  folderProtocole.name !== 'Entraînement libre' &&
                  folderProtocole.name !== 'Découverte vidéo de la technique'
                "
              >
                <div>
                  <div>
                    <p class="brique-text-avancement">
                      {{ folderProtocole.name }}
                    </p>
                  </div>
                  <chart-brique-user
                    :data="[Math.round(folderProtocole.pourcentageComplete)]"
                  ></chart-brique-user>
                </div>
                <hr />
                <div
                  v-if="folderBrique.name.includes('cardiaque')"
                  class="has-text-centered is-margin-t-20"
                >
                  <a class="button is-cyan" @click="isCardResultat = true">
                    {{ $t("resultats") }}
                  </a>
                </div>
                <div
                  v-if="folderBrique.name.includes('cardiaque')"
                  class="has-text-centered is-margin-t-20"
                >
                  <a class="button is-cyan" @click="isCardStatistiques = true">
                    {{ $t("statistiques") }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
      <hr />
      <a
        class="has-text-weight-semibold has-text-black"
        @click="
          openEvaluationsPsychotechniques = !openEvaluationsPsychotechniques
        "
      >
        {{ $t("evaluationsPsychotechniques") }}
        <b-icon
          v-if="openEvaluationsPsychotechniques"
          pack="fas"
          icon="sort-down"
        ></b-icon>
        <b-icon v-else pack="fas" icon="caret-right"></b-icon>
      </a>

      <b-collapse
        :open.sync="openEvaluationsPsychotechniques"
        style="
          width: 3000px;
          overflow-x: scroll;
          scrollbar-color: rebeccapurple green;
          scrollbar-width: thin;
        "
      >
        <br />
        <div>
          <chart-questionnaire
            :listeUser="this.utilisateurs"
            :listeFolder="foldersVie"
            :folderUserSelected="foldersUtilisateursSelected"
          ></chart-questionnaire>
        </div>
      </b-collapse>
      <a
        class="has-text-weight-semibold has-text-black"
        @click="openQcm = !openQcm"
      >
        {{ titreFolderQcm }}
        <b-icon v-if="openQcm" pack="fas" icon="sort-down"></b-icon>
        <b-icon v-else pack="fas" icon="caret-right"></b-icon>
      </a>
      <b-collapse
        :open.sync="openQcm"
        style="
          width: 3000px;
          overflow-y: scroll;
          scrollbar-color: rebeccapurple green;
          scrollbar-width: thin;
        "
      >
        <br />
        <div>
          <chart-questionnaire-v2
            :listeUser="this.utilisateurs"
            :listeFolder="foldersQcm"
            :folderUserSelected="foldersUtilisateursSelected"
          ></chart-questionnaire-v2>
        </div>
      </b-collapse>
      <div id="export-pdf" style="display: none">
        <export-pdf-vue v-if="props" :props="props" />
      </div>
      <div id="export-pdf-hautdroit" style="display: none">
        <export-pdf-vue-haut-droit v-if="props" :props="props" />
      </div>
      <b-modal
        :width="1200"
        class="is-modal-folder-resultat"
        :active.sync="isCardResultat"
        @close="
          isCardResultat = false;
          defaultOpenedDetails = [];
        "
      >
        <div class="card is-card-folder-resultat">
          <div class="padding-10">
            <div class="level">
              <span class="is-size-5 has-text-weight-semibold is-margin-b-10">
                {{ $t("resultatDesExercicesCC") }}
              </span>
              <b-dropdown
                v-model="userSelected2"
                aria-role="list"
                v-if="utilisateurs && !utilisateursSessionGroup2"
                class="is-pulled-right"
              >
                <button class="button" type="button" slot="trigger">
                  <template v-if="userSelected2 === 'tous'">
                    <span>{{ $t("rechercherParUtilisateur") }}</span>
                    <b-icon pack="fas" icon="caret-down"></b-icon>
                  </template>
                  <template v-else>
                    <span>{{ userSelected2.attributes.username }}</span>
                    <b-icon pack="fas" icon="caret-down"></b-icon>
                  </template>
                </button>
                <b-dropdown-item value="tous" aria-role="listitem">
                  {{ $t("aucunFiltre") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="utilisateur in utilisateurs"
                  :key="utilisateur.id"
                  :value="utilisateur"
                  aria-role="listitem"
                >
                  {{ utilisateur.attributes.username }}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                v-model="userSelected2"
                aria-role="list"
                v-else-if="utilisateursSessionGroup2"
                class="is-pulled-right"
              >
                <button class="button" type="button" slot="trigger">
                  <template v-if="userSelected2 === 'tous'">
                    <span>{{ $t("rechercherParUtilisateur") }}</span>
                    <b-icon pack="fas" icon="caret-down"></b-icon>
                  </template>
                  <template v-else>
                    <span>{{ userSelected2.attributes.username }}</span>
                    <b-icon pack="fas" icon="caret-down"></b-icon>
                  </template>
                </button>
                <b-dropdown-item value="tous" aria-role="listitem">
                  {{ $t("aucunFiltre") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="utilisateur in utilisateursSessionGroup2"
                  :key="utilisateur.id"
                  :value="utilisateur"
                  aria-role="listitem"
                >
                  {{ utilisateur.attributes.username }}
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                v-model="groupSelected2"
                aria-role="list"
                v-if="sessionsGroup"
                class="is-pulled-right"
              >
                <button class="button" type="button" slot="trigger">
                  <template v-if="groupSelected2 === 'tous'">
                    <span>{{ $t("rechercherParSeanceGroupe") }}</span>
                    <b-icon pack="fas" icon="caret-down"></b-icon>
                  </template>
                  <template v-else>
                    <span>{{ groupSelected2.attributes.name }}</span>
                    <b-icon pack="fas" icon="caret-down"></b-icon>
                  </template>
                </button>
                <b-dropdown-item value="tous" aria-role="listitem">
                  {{ $t("aucunFiltre") }}
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="session in sessionsGroup"
                  :key="session.id"
                  :value="session"
                  aria-role="listitem"
                >
                  {{ session.attributes.name }}
                </b-dropdown-item>
              </b-dropdown>
              <b-field class="is-pulled-right">
                <b-radio-button
                  v-model="protocolesLibreSelected"
                  :native-value="false"
                  type="is-success"
                >
                  <span>{{ $t("protocoleGuide") }}</span>
                </b-radio-button>
                <b-radio-button
                  class="is-margin-l-10"
                  v-model="protocolesLibreSelected"
                  :native-value="true"
                  type="is-success"
                >
                  <span>{{ $t("protocoleLibre") }}</span>
                </b-radio-button>
              </b-field>
            </div>

            <chart-protocole-libre-vue
              v-if="protocolesLibreSelected && chartProtocoleLibre"
              :tabRow="tabRowProtocoleLibre"
            />

            <chart-protocole-guide-vue
              v-if="!protocolesLibreSelected && chartProtocoleGuide"
              :tabRowInstalle="tabExo1"
              :tabRowMaitrise="tabExo2"
              :tabRowAncre="tabExo3"
            />
            <div
              v-if="
                resultatCoherenceCardiaque &&
                resultatCoherenceCardiaque.length > 0
              "
            >
              <b-table
                :data="resultatCoherenceCardiaque"
                :bordered="true"
                :narrowed="true"
                :striped="true"
                :opened-detailed="defaultOpenedDetails"
                detailed
                detail-key="id"
                :show-detail-icon="false"
              >
                <template slot-scope="props" slot="header">
                  <div>{{ props.column.label }}</div>
                </template>

                <template slot-scope="props">
                  <b-table-column label="Date" class="has-text-centered">
                    {{ new Date(props.row.updatedAt).toLocaleDateString() }}
                  </b-table-column>
                  <b-table-column label="Séance" class="has-text-centered">
                    <p v-if="props.row.attributes.link">
                      {{
                        props.row.attributes.link.attributes.parent_folder
                          .attributes.name
                      }}
                    </p>
                    <p v-else-if="props.row.attributes.file">
                      {{ $t("entraintementLibre") }}
                    </p>
                  </b-table-column>
                  <b-table-column label="Exercices" class="has-text-centered">
                    <p v-if="props.row.attributes.link">
                      {{
                        props.row.attributes.link.attributes.file.attributes
                          .name
                      }}
                    </p>
                    <p v-else-if="props.row.attributes.file">
                      {{ props.row.attributes.file.attributes.name }}
                    </p>
                  </b-table-column>
                  <b-table-column label="Difficulté" class="has-text-centered">
                    <p v-if="props.row.attributes.link">
                      {{
                        valeurDifficulte(
                          props.row.attributes.link.attributes.file.attributes
                            .difficulte
                        )
                      }}
                    </p>
                    <p v-else-if="props.row.attributes.file">
                      {{
                        valeurDifficulte(
                          props.row.attributes.file.attributes.difficulte
                        )
                      }}
                    </p>
                  </b-table-column>
                  <b-table-column
                    label="Fréquence cardiaque moyenne"
                    class="has-text-centered"
                  >
                    {{ valeurFCMoyenne(props.row) }}
                  </b-table-column>
                  <b-table-column
                    label="Moyenne de cohérence cardiaque"
                    class="has-text-centered"
                  >
                    {{ valeurCCMoyenne(props.row) }}%
                  </b-table-column>
                  <b-table-column label="Relecture" class="has-text-centered">
                    <button
                      :class="{
                        expert_bouton_relecture_deploye:
                          defaultOpenedDetails.find((e) => {
                            return e === props.row.id;
                          }),
                      }"
                      class="expert_bouton_relecture"
                      aria-label="resultat"
                      @click="showRelecture(props.row)"
                    >
                      <img
                        src="./../../../../assets/img/play-button.png"
                        width="24px"
                        style="margin-left: 2px"
                      />
                    </button>
                  </b-table-column>
                </template>

                <template slot="detail" slot-scope="props">
                  <div style="text-align: center; padding: 10px">
                    <button
                      style="
                        background: #46bfcb;
                        border-radius: 5px;
                        color: white;
                        font-size: medium;
                      "
                      @click="showRelecture(props.row, true)"
                    >
                      Imprimer
                    </button>
                    <button
                      style="
                        background: #46bfcb;
                        border-radius: 5px;
                        color: white;
                        font-size: medium;
                      "
                      @click="downloadRelecture(props.row, true)"
                    >
                      Télécharger
                    </button>
                  </div>
                  <div class="columns is-multiline">
                    <div class="column is-2">
                      <div class="circleFCMoyenne1">
                        <div class="circleFCMoyenne2">
                          <p class="circleFCMoyenne3 has-text-weight-bold">
                            <span class="is-size-4">
                              {{
                                props.row.fcMoyenne ? props.row.fcMoyenne : "0"
                              }}
                            </span>
                            bpm
                          </p>
                        </div>
                      </div>
                      <p
                        class="has-text-weight-semibold has-text-white has-text-centered is-margin-t-20"
                      >
                        {{ $t("moyenneFrequenceCardiaque") }}
                      </p>
                      <p
                        class="has-text-weight-bold has-text-white has-text-centered is-margin-t-50 is-size-4"
                      >
                        {{ $t("duree") }}
                      </p>
                      <p
                        class="has-text-weight-bold has-text-cyan has-text-centered is-margin-t-20 is-size-4 labelChrono"
                      >
                        {{ props.row.labelChrono }}
                      </p>
                    </div>
                    <div class="column is-8">
                      <div class="chart-result">
                        <chart-fc-vue
                          v-if="chart && resultatObjet"
                          :valeurX="valeurX"
                          :tabRowFc="tabRowFc"
                          :defaultOpenedDetails="defaultOpenedDetails[0]"
                          @sendChartFc="convertImgFc"
                        />
                        <chart-cc-vue
                          v-if="chart && resultatObjet"
                          :valeurX="valeurCcX"
                          :tabRowCc="tabRowCc"
                          :defaultOpenedDetails="defaultOpenedDetails[0]"
                          @sendChartCc="convertImgCc"
                        />
                      </div>
                      <div class="columns">
                        <div class="column is-size-4 has-text-centered">
                          {{
                            props.row.valeurRouge ? props.row.valeurRouge : "0"
                          }}%
                          <div class="respi_progress_bar_result">
                            <div
                              class="cc_red_result"
                              :style="{
                                width: props.row.valeurRouge
                                  ? props.row.valeurRouge + '%'
                                  : 0 + '%',
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="column is-size-4 has-text-centered">
                          {{
                            props.row.valeurOrange
                              ? props.row.valeurOrange
                              : "0"
                          }}
                          %
                          <div class="respi_progress_bar_result">
                            <div
                              class="cc_orange_result"
                              :style="{
                                width: props.row.valeurOrange
                                  ? props.row.valeurOrange + '%'
                                  : 0 + '%',
                              }"
                            ></div>
                          </div>
                        </div>
                        <div class="column is-size-4 has-text-centered">
                          {{
                            props.row.valeurVerte ? props.row.valeurVerte : "0"
                          }}%
                          <div class="respi_progress_bar_result">
                            <div
                              class="cc_green_result"
                              :style="{
                                width: props.row.valeurVerte
                                  ? props.row.valeurVerte + '%'
                                  : 0 + '%',
                              }"
                            ></div>
                          </div>
                        </div>
                      </div>
                      <p
                        class="has-text-white has-text-centered has-text-weight-semibold"
                      >
                        % {{ $t("pourcentTempsPasseZoneCoherence") }}
                      </p>
                    </div>
                    <div class="column is-2">
                      <div class="circleFCMoyenne1">
                        <div
                          class="circleFCMoyenne4"
                          :style="{
                            'background-color': props.row.couleurCCMoyenne,
                          }"
                        >
                          <p class="circleFCMoyenne3 has-text-weight-bold">
                            <span class="is-size-4">
                              {{
                                props.row.ccMoyenne ? props.row.ccMoyenne : "0"
                              }}
                            </span>
                            %
                          </p>
                        </div>
                      </div>
                      <p
                        class="has-text-weight-semibold has-text-white has-text-centered is-margin-t-20"
                      >
                        {{ $t("moyenneCc") }}
                      </p>
                    </div>
                  </div>
                </template>
              </b-table>
            </div>
            <div v-else>
              <div v-if="isLoading">Chargement en cours</div>
              <div v-else>Aucun résultat.</div>
            </div>
          </div>
        </div>
      </b-modal>
      <b-modal
        :width="1200"
        class="is-modal-folder-resultat"
        :active.sync="isCardStatistiques"
        @close="isCardStatistiques = false"
      >
        <div class="card is-card-folder-resultat">
          <div class="padding-10">
            <div class="columns">
              <div class="column is-4">
                <span class="is-size-5 has-text-weight-semibold is-margin-b-10">
                  {{ $t("statistiquesCC") }}
                </span>
              </div>
              <div class="column is-4">
                <b-field>
                  <b-datepicker
                    icon-pack="fa"
                    :show-week-number="false"
                    placeholder="Date de début"
                    v-model="filter_date_start"
                    icon="calendar-alt"
                  >
                  </b-datepicker>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field>
                  <b-datepicker
                    icon-pack="fa"
                    :show-week-number="false"
                    placeholder="Date de fin"
                    v-model="filter_date_end"
                    icon="calendar-alt"
                  >
                  </b-datepicker>
                </b-field>
              </div>
            </div>
            <div class="columns">
              <div class="column is-3"></div>
              <div class="column is-3">
                <b-dropdown
                  v-model="userSelected2"
                  aria-role="list"
                  v-if="utilisateurs && !utilisateursSessionGroup2"
                  class="is-pulled-right"
                >
                  <button class="button" type="button" slot="trigger">
                    <template v-if="userSelected2 === 'tous'">
                      <span>{{ $t("rechercherParUtilisateur") }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                    <template v-else>
                      <span>{{ userSelected2.attributes.username }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                  </button>
                  <b-dropdown-item value="tous" aria-role="listitem">
                    {{ $t("aucunFiltre") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="utilisateur in utilisateurs"
                    :key="utilisateur.id"
                    :value="utilisateur"
                    aria-role="listitem"
                  >
                    {{ utilisateur.attributes.username }}
                  </b-dropdown-item>
                </b-dropdown>
                <b-dropdown
                  v-model="userSelected2"
                  aria-role="list"
                  v-else-if="utilisateursSessionGroup2"
                  class="is-pulled-right"
                >
                  <button class="button" type="button" slot="trigger">
                    <template v-if="userSelected2 === 'tous'">
                      <span>{{ $t("rechercherParUtilisateur") }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                    <template v-else>
                      <span>{{ userSelected2.attributes.username }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                  </button>
                  <b-dropdown-item value="tous" aria-role="listitem">
                    {{ $t("aucunFiltre") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="utilisateur in utilisateursSessionGroup2"
                    :key="utilisateur.id"
                    :value="utilisateur"
                    aria-role="listitem"
                  >
                    {{ utilisateur.attributes.username }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="column is-3">
                <b-dropdown
                  v-model="groupSelected2"
                  aria-role="list"
                  v-if="sessionsGroup"
                >
                  <button class="button" type="button" slot="trigger">
                    <template v-if="groupSelected2 === 'tous'">
                      <span>{{ $t("rechercherParSeanceGroupe") }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                    <template v-else>
                      <span>{{ groupSelected2.attributes.name }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                  </button>
                  <b-dropdown-item value="tous" aria-role="listitem">
                    {{ $t("aucunFiltre") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="session in sessionsGroup"
                    :key="session.id"
                    :value="session"
                    aria-role="listitem"
                  >
                    {{ session.attributes.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
              <div class="column is-3">
                <b-dropdown
                  v-model="exerciceSelected"
                  aria-role="list"
                  position="is-bottom-left"
                >
                  <button class="button" type="button" slot="trigger">
                    <template v-if="exerciceSelected === 'tous'">
                      <span>{{ $t("rechercherParExercice") }}</span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                    <template v-else>
                      <span>
                        {{ exerciceSelected.attributes.file.attributes.name }}
                      </span>
                      <b-icon pack="fas" icon="caret-down"></b-icon>
                    </template>
                  </button>
                  <b-dropdown-item value="tous" aria-role="listitem">
                    {{ $t("aucunFiltre") }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-for="exercice in exercices"
                    :key="exercice.id"
                    :value="exercice"
                    aria-role="listitem"
                  >
                    {{ exercice.attributes.file.attributes.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <modal-statistiques-vue
              :statistiques="statistiques"
              :disabledStats="disabledStats"
            />
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import html2pdf from "html2pdf.js";
import moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { getAllFolders } from "@/services/folders/getAllFolders.js";
import asyncForEach from "@/services/general/asyncForEach.js";
import { getObjectByTypeAndValue } from "@/services/parse/getObject.js";
import chartFcVue from "./chartFc.vue";
import chartCcVue from "./chartCc.vue";
import chartBrique from "./chartBrique.vue";
import chartBriqueUser from "./chartBriqueUser.vue";
import presentationProtocole from "./presentationProtocole.vue";
import chartQuestionnaire from "./chartQuestionnaire.vue";
import chartQuestionnaireV2 from "./chartQuestionnairev2.vue";
import chartProtocoleLibreVue from "./chartProtocoleLibre.vue";
import chartProtocoleGuideVue from "./chartProtocoleGuide.vue";
import exportPdfVue from "./exportPdf.vue";
import exportPdfVueHautDroit from "./exportPdfHautDroit.vue";
import modalStatistiquesVue from "./modalStatistiques.vue";
import {
  sortInvArrayByParam,
  sortArrayByParam,
} from "@/services/general/sortArrayByParam.js";

export default {
  name: "avancement-protocoles",
  props: [
    "utilisateurs",
    "userSelected",
    "utilisateursSessionGroup",
    "sessionsGroup",
    "groupSelected",
    "folders",
    "userParticulier",
  ],
  components: {
    chartFcVue: chartFcVue,
    chartCcVue: chartCcVue,
    chartProtocoleLibreVue: chartProtocoleLibreVue,
    chartProtocoleGuideVue: chartProtocoleGuideVue,
    exportPdfVue: exportPdfVue,
    exportPdfVueHautDroit: exportPdfVueHautDroit,
    modalStatistiquesVue: modalStatistiquesVue,
    chartBrique: chartBrique,
    chartQuestionnaire: chartQuestionnaire,
    chartQuestionnaireV2: chartQuestionnaireV2,
    presentationProtocole: presentationProtocole,
    chartBriqueUser: chartBriqueUser,
  },
  data() {
    return {
      statistiques: {},
      imageChartFc: null,
      imageChartCc: null,
      props: null,
      isLoading: false,
      isCardResultat: false,
      FoldersBrique: null,
      nbUtilisateur: {},
      showFront: true,
      foldersUtilisateursSelected: null,
      resultatCoherenceCardiaque: [],
      defaultOpenedDetails: [],
      tempsZone1: 0,
      tempsZone2: 0,
      tempsZone3: 0,
      valeurCCCourante: 0,
      valeurTempsPrecedent: 0,
      labelProfil: null,
      profil: null,
      cpm: null,
      valeurX: [],
      tabRowCc: [],
      tabRowFc: [],
      chart: false,
      valeurCcX: [],
      resultatObjet: null,
      protocolesLibreSelected: false,
      chartProtocoleLibre: false,
      tabRowProtocoleLibre: {},
      tabExo1: [],
      tabExo2: [],
      tabExo3: [],
      chartProtocoleGuide: false,
      userSelected2: "tous",
      groupSelected2: "tous",
      isCardStatistiques: false,
      cloneFoldersBrique: null,
      utilisateursSessionGroup2: null,
      filter_date_start: null,
      filter_date_end: null,
      exerciceSelected: "tous",
      exercices: [],
      disabledStats: false,
      foldersTherapie: null,
      foldersCardiaque: null,
      foldersVie: null,
      foldersQcm: null,
      openEvaluationsPsychotechniques: true,
      openQcm: true,
      openTherapiesNonMedicamenteuses: true,
      openAccompagnementPsychophysiologique: true,
      dataUrl: "",
      nomTherapeute: "",
      utilisateurSelectionne: null,
      indefinteToast: null,
      titreFolderQcm: "",
    };
  },
  methods: {
    async traitement(utilisateurs) {
      var tmpFolders = cloneDeep(this.cloneFoldersBrique);
      this.FoldersBrique = null;
      this.foldersTherapie = null;
      this.foldersCardiaque = null;
      this.foldersVie = null;
      this.foldersQcm = null;

      await asyncForEach(tmpFolders, async (folder) => {
        if (
          this.folders.find((e) => {
            if (folder.objectId === e.id && e.deverrouille) return e;
          })
        ) {
          folder.deverrouille = true;
        }
        await asyncForEach(folder.foldersProtocole, async (protocole) => {
          protocole.somme = 0;
          protocole.sommeCabinet = 0;
          protocole.sommeLicence = 0;
          protocole.sommeAffiliation = 0;
        });
      });
      var listeUtilisateurJson = JSON.stringify(
        utilisateurs.filter((e) => {
          return (
            e.attributes.acces_donnees_therapeute !== undefined &&
            e.attributes.acces_donnees_therapeute
          );
        })
      );
      var resultat = await Parse.Cloud.run("analyseStat", {
        utilisateurs: listeUtilisateurJson,
        foldersBrique: tmpFolders,
      });
      var monObject = JSON.parse(resultat);
      this.FoldersBrique = cloneDeep(monObject);
      this.foldersTherapie = cloneDeep(
        this.FoldersBrique.filter((e) => {
          if (e.deverrouille && e.theme && e.theme.ordre === 1) {
            return e;
          }
        })
      );

      this.foldersCardiaque = this.FoldersBrique.filter((e) => {
        if (e.deverrouille && e.name && e.theme.ordre === 4) {
          return e;
        }
      });
      this.foldersVie = this.FoldersBrique.filter((e) => {
        if (e.deverrouille && e.theme && e.theme.ordre === 5) {
          return e;
        }
      });
      this.foldersQcm = this.FoldersBrique.filter((e) => {
        if (e.deverrouille && e.theme && e.theme.ordre === 7) {
          return e;
        }
      });
      var queryDomaine = new Parse.Query("domaine");
      queryDomaine.equalTo("nom", "Santé");
      var domaine = await queryDomaine.first();
      var queryTheme = new Parse.Query("theme");
      queryTheme.equalTo("domaine", domaine);
      queryTheme.equalTo("ordre", 7);
      var theme = await queryTheme.first();
      this.titreFolderQcm = theme.attributes.name;
    },
    async getFileLinkCompleted(userSelected) {
      this.isLoading = true;
      this.resultatCoherenceCardiaque = [];
      this.tabRowProtocoleLibre = {
        label: [],
        data: [],
        color: [],
      };
      this.chartProtocoleLibre = false;
      this.chartProtocoleGuide = false;
      const ObjectFileType = Parse.Object.extend("file_type");
      const ObjectFile = Parse.Object.extend("file");
      const ObjectLink = Parse.Object.extend("link");
      const ObjectLinkCompleted = Parse.Object.extend("link_completed");
      const ObjectFolder = Parse.Object.extend("folder");
      const ObjectFolderType = Parse.Object.extend("folder_type");
      const ObjectFileDone = Parse.Object.extend("file_done");

      const QueryFileType = new Parse.Query(ObjectFileType);
      const QueryFile = new Parse.Query(ObjectFile);
      const QueryLink = new Parse.Query(ObjectLink);
      const QueryLinkCompleted = new Parse.Query(ObjectLinkCompleted);
      const QueryFolder = new Parse.Query(ObjectFolder);
      const QueryFolderType = new Parse.Query(ObjectFolderType);
      const QueryFileDone = new Parse.Query(ObjectFileDone);
      if (this.protocolesLibreSelected) {
        QueryFileType.containedIn("name", ["Module Expert", "Jeux"]);
        QueryFile.matchesQuery("type", QueryFileType);
        QueryFolderType.equalTo("name", "Exercices libres");
        QueryFolder.matchesQuery("type", QueryFolderType);
        QueryFileDone.matchesQuery("file", QueryFile);
        QueryFileDone.matchesQuery("protocole", QueryFolder);
        if (userSelected === "tous" && !this.utilisateursSessionGroup2) {
          QueryFileDone.containedIn("user", this.utilisateurs);
          QueryFileDone.include("file");
          QueryFileDone.include("protocole");
          const ResponseFileDone = await QueryFileDone.find();

          ResponseFileDone.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);
              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
              return true;
            } catch (error) {
              return false;
            }
          }).forEach((valeur) => {
            this.resultatCoherenceCardiaque.push(valeur);
          });
        } else if (userSelected !== "tous") {
          QueryFileDone.equalTo("user", userSelected);
          QueryFileDone.include("file");
          QueryFileDone.include("protocole");
          let ResponseFileDone = await QueryFileDone.find();
          ResponseFileDone = sortArrayByParam(ResponseFileDone, "updatedAt");

          await ResponseFileDone.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);

              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
              return true;
            } catch (error) {
              return false;
            }
          }).forEach((valeur) => {
            this.resultatCoherenceCardiaque.push(valeur);
            const ccMoyenne = this.valeurCCMoyenne(valeur);
            const jourExo = moment(valeur.updatedAt);
            let maCouleur = "";
            if (ccMoyenne < 60) {
              maCouleur = "#FE0000";
            } else {
              if (ccMoyenne < 90) {
                maCouleur = "#FFC801";
              } else {
                maCouleur = "#51C901";
              }
            }
            if (ccMoyenne !== 0) {
              this.tabRowProtocoleLibre.label.push(
                jourExo.format("DD/MM/YYYY") +
                  "- cc:" +
                  ccMoyenne +
                  " - " +
                  valeur.attributes.file.attributes.name
              );
              this.tabRowProtocoleLibre.data.push(ccMoyenne);
              this.tabRowProtocoleLibre.color.push(maCouleur);
            }
          });
          this.chartProtocoleLibre = true;
        } else if (this.utilisateursSessionGroup2) {
          QueryFileDone.containedIn("user", this.utilisateursSessionGroup2);
          QueryFileDone.include("file");
          QueryFileDone.include("protocole");
          const ResponseFileDone = await QueryFileDone.find();
          ResponseFileDone.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);

              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
              return true;
            } catch (error) {
              return false;
            }
          }).forEach((valeur) => {
            this.resultatCoherenceCardiaque.push(valeur);
          });
        }
      } else {
        QueryFileType.containedIn("name", ["Module Expert", "Jeux"]);
        QueryFile.matchesQuery("type", QueryFileType);
        QueryLink.matchesQuery("file", QueryFile);
        if (userSelected === "tous" && !this.utilisateursSessionGroup2) {
          QueryLinkCompleted.containedIn("user", this.utilisateurs);
          QueryLinkCompleted.matchesQuery("link", QueryLink);
          QueryLinkCompleted.include("link");
          QueryLinkCompleted.include("link.file");
          QueryLinkCompleted.include("link.parent_folder");
          if (this.filter_date_start && this.filter_date_end) {
            QueryLinkCompleted.greaterThanOrEqualTo(
              "createdAt",
              this.filter_date_start
            );
            QueryLinkCompleted.lessThan("createdAt", this.filter_date_end);
          }
          const ResponseLinkCompleted = await QueryLinkCompleted.find();
          ResponseLinkCompleted.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);

              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;

              return true;
            } catch (error) {
              return false;
            }
          }).forEach((e) => {
            this.resultatCoherenceCardiaque.push(e);
          });
        } else if (userSelected && userSelected !== "tous") {
          QueryLinkCompleted.equalTo("user", userSelected);
          QueryLinkCompleted.matchesQuery("link", QueryLink);
          QueryLinkCompleted.include("link");
          QueryLinkCompleted.include("link.file");
          QueryLinkCompleted.include("link.parent_folder");
          let ResponseLinkCompleted = await QueryLinkCompleted.find();
          ResponseLinkCompleted = sortArrayByParam(
            ResponseLinkCompleted,
            "updatedAt"
          );
          this.tabExo1 = {
            label: [],
            data: [],
            color: [],
          };
          this.tabExo2 = {
            label: [],
            data: [],
            color: [],
          };
          this.tabExo3 = {
            label: [],
            data: [],
            color: [],
          };

          await asyncForEach(
            ResponseLinkCompleted.filter(function (e) {
              if (e.attributes.resultatJeux === undefined) return false;
              try {
                const resultatJeuxFormat = JSON.parse(
                  e.attributes.resultatJeux
                );

                if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
                return true;
              } catch (error) {
                return false;
              }
            }),
            async (e) => {
              this.resultatCoherenceCardiaque.push(e);
              const ccMoyenne = this.valeurCCMoyenne(e);
              const jourExo = moment(e.updatedAt);
              let maCouleur = "";
              if (ccMoyenne < 60) {
                maCouleur = "#FE0000";
              } else {
                if (ccMoyenne < 90) {
                  maCouleur = "#FFC801";
                } else {
                  maCouleur = "#51C901";
                }
              }
              const parent = await getObjectByTypeAndValue(
                "link",
                "folder",
                e.attributes.link.attributes.parent_folder
              );
              if (
                parent.attributes.folder.attributes.name.includes(
                  "Installer"
                ) &&
                ccMoyenne
              ) {
                this.tabExo1.label.push(
                  jourExo.format("DD/MM/YYYY") +
                    "- cc:" +
                    ccMoyenne +
                    " - " +
                    e.attributes.link.attributes.file.attributes.name
                );
                this.tabExo1.data.push(ccMoyenne);
                this.tabExo1.color.push(maCouleur);
              }
              if (
                parent.attributes.folder.attributes.name.includes(
                  "Maîtriser"
                ) &&
                ccMoyenne
              ) {
                this.tabExo2.label.push(
                  jourExo.format("DD/MM/YYYY") +
                    "- cc:" +
                    ccMoyenne +
                    " - " +
                    e.attributes.link.attributes.file.attributes.name
                );
                this.tabExo2.data.push(ccMoyenne);
                this.tabExo3.color.push(maCouleur);
              }
              if (
                parent.attributes.folder.attributes.name.includes("Ancrer") &&
                ccMoyenne
              ) {
                this.tabExo1.label.push(
                  jourExo.format("DD/MM/YYYY") +
                    "- cc:" +
                    ccMoyenne +
                    " - " +
                    e.attributes.link.attributes.file.attributes.name
                );
                this.tabExo3.data.push(ccMoyenne);
                this.tabExo3.color.push(maCouleur);
              }
            }
          );
          this.chartProtocoleGuide = true;
        } else if (this.utilisateursSessionGroup2) {
          QueryLinkCompleted.containedIn(
            "user",
            this.utilisateursSessionGroup2
          );
          QueryLinkCompleted.matchesQuery("link", QueryLink);
          QueryLinkCompleted.include("link");
          QueryLinkCompleted.include("link.file");
          QueryLinkCompleted.include("link.parent_folder");
          const ResponseLinkCompleted = await QueryLinkCompleted.find();

          ResponseLinkCompleted.filter(function (e) {
            if (e.attributes.resultatJeux === undefined) return false;
            try {
              const resultatJeuxFormat = JSON.parse(e.attributes.resultatJeux);

              if (resultatJeuxFormat.ArrayTacho.length === 0) return false;
              return true;
            } catch (error) {
              return false;
            }
          }).forEach((e) => {
            this.resultatCoherenceCardiaque.push(e);
          });
        }
      }
      this.resultatCoherenceCardiaque = sortInvArrayByParam(
        this.resultatCoherenceCardiaque,
        "updatedAt"
      );
      this.isLoading = false;
    },
    valeurDifficulte(difficulte) {
      switch (difficulte) {
        case 0:
          return "facile";
        case 1:
          return "moyen";
        case 2:
          return "difficile";
      }
    },
    valeurFCMoyenne(link) {
      if (!link) return "";
      const resultatJeux = link.attributes.resultatJeux;
      if (!resultatJeux) return "0bpm";
      try {
        const resultatJeuxFormat = JSON.parse(resultatJeux);
        if (!resultatJeuxFormat.BpmCC) return "0bpm";
        return Math.round(resultatJeuxFormat.BpmCC) + "bpm";
      } catch (error) {
        //console.log(error);
        return "0bpm";
      }
    },
    valeurCCMoyenne(link) {
      if (!link) return "";
      const resultatJeux = link.attributes.resultatJeux;
      if (!resultatJeux) return 0;
      try {
        const resultatJeuxFormat = JSON.parse(resultatJeux);
        return resultatJeuxFormat.AverageCC;
      } catch (error){
        //console.log(error);
        return 0;
      }
    },
    toDataUrl(url, contexte, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          contexte.dataUrl = reader.result;
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    downloadRelecture(link) {
      const resultatChaine = link.attributes.resultatJeux;
      var download = document.createElement("a");
      download.setAttribute(
        "href",
        "data:text/plain;charset=utf-8," + encodeURIComponent(resultatChaine)
      );
      download.setAttribute("download", "Donnes.json");
      download.click();
    },
    showRelecture(link, isPrint) {
      this.props = link;
      if (
        !isPrint &&
        this.defaultOpenedDetails.find((e) => {
          return e === link.id;
        })
      ) {
        this.defaultOpenedDetails = [];
        return;
      }
      if (
        !this.defaultOpenedDetails.find((e) => {
          return e === link.id;
        })
      ) {
        this.imageChartFc = null;
        this.imageChartCc = null;
      }
      this.valeurX = [];
      this.tabRowCc = [];
      this.tabRowFc = [];
      this.valeurCcX = [];
      this.resultatObjet = null;
      this.defaultOpenedDetails = [link.id];
      const resultatChaine = link.attributes.resultatJeux;
      if (!resultatChaine) return;
      if (resultatChaine.indexOf("undefined") !== -1) return;
      if (resultatChaine.indexOf("NaN") !== -1) return;
      this.resultatObjet = JSON.parse(resultatChaine);
      this.resultatObjet.ArrayTacho.forEach((valTacho) => {
        if (valTacho.x / 1000 >= 30) {
          if (Number.isInteger(valTacho.x / 1000)) {
            this.valeurX.push(valTacho.x / 1000);
            this.tabRowFc.push(valTacho.y);
          }
        }
      });
      this.resultatObjet.TabCC.forEach((valCc) => {
        if (valCc.x / 1000 >= 30) {
          if (Number.isInteger(valCc.x / 1000)) {
            this.tabRowCc.push(valCc.y);
            this.valeurCcX.push(valCc.x / 1000);
          }
        }
      });

      if (this.resultatObjet.AverageCC < 60) {
        link.couleurCCMoyenne = "#FE0000";
      } else {
        if (this.resultatObjet.AverageCC < 90) {
          link.couleurCCMoyenne = "#FFC801";
        } else {
          link.couleurCCMoyenne = "#51C901";
        }
      }
      this.analyseCC(this.resultatObjet, link);
      this.cpm = this.resultatObjet.Cpm;
      this.profil = this.resultatObjet.Profil;
      switch (this.profil) {
        case "0":
          this.labelProfil = "Profonde";
          break;
        case "1":
          this.labelProfil = "Basique";
          break;
        case "2":
          this.labelProfil = "Tonique";
          break;
      }
      if (
        link.attributes.file !== undefined &&
        link.attributes.file.attributes.game_type !== undefined
      )
        this.resultatObjet.TimeCompletion =
          this.resultatObjet.TimeCompletion * 60;
      link.valeurChrono = this.resultatObjet.TimeCompletion;
      var minutes = Math.floor(link.valeurChrono / 60);
      var seconds = link.valeurChrono % 60;
      link.ImageUri = this.imageChartFc;
      link.ImageUriCc = this.imageChartCc;
      link.labelChrono =
        (minutes < 10 ? "0" + minutes : minutes) +
        ":" +
        (seconds < 10 ? "0" + seconds : seconds);
      link.realiseLe = moment(link.updatedAt).format("DD/MM/YYYY, HH:mm");
      this.valeurInspi = this.resultatObjet.Inspi;
      this.valeurPauseInspi = this.resultatObjet.PauseInspi;
      this.valeurExpi = this.resultatObjet.Expi;
      this.valeurPauseExpi = this.resultatObjet.PauseExpi;
      link.ccMoyenne = Math.round(this.resultatObjet.AverageCC);
      link.fcMoyenne = Math.round(this.resultatObjet.BpmCC);
      link.ccMoyenneInitiale = this.resultatObjet.AverageCC;
      this.chart = true;

      if (isPrint) {
        setTimeout(() => {
          this.exportPdfV2(link);
        }, 2000);
      }
    },
    analyseCC(tableauCC, link) {
      link.valeurRouge = tableauCC.Pct1;
      link.valeurOrange = tableauCC.Pct2;
      link.valeurVerte = tableauCC.Pct3;
    },
    convertImgCc(e) {
      this.imageChartCc = e;
    },
    convertImgFc(e) {
      this.imageChartFc = e;
    },
    exportPdfV2(link) {
      const resultatJeuxFormat = JSON.parse(link.attributes.resultatJeux);
      var element =
        "<!DOCTYPE html><html><head>" + document.head.innerHTML + "</head>";
      element = element + "<body>";
      element = element + '<div class="columns">'; // Haut de page
      element = element + '<div class="column is-half">'; //Colonne 1
      element =
        element +
        '<div><label>Utilisateur : </label><label style="color:grey;padding-left : 2px;"> ' +
        link.attributes.user.attributes.username +
        "</label></div>";
      element =
        element +
        '<div><label>Suivi par : </label><label style="color:grey;padding-left : 2px;"> ' +
        this.nomTherapeute +
        "</label></div>";
      element =
        element +
        '<div><label>Séance : </label><label style="color:grey;padding-left : 2px;"> ' +
        (link.attributes.link === undefined
          ? link.attributes.brique.attributes.name
          : link.attributes.link.attributes.parent_folder.attributes.name) +
        "</label></div>";
      element =
        element +
        '<div><label>Exercice : </label><label style="color:grey;padding-left : 2px;"> ' +
        (link.attributes.link === undefined
          ? link.attributes.file.attributes.name
          : link.attributes.link.attributes.file.attributes.name) +
        "</label></div>";
      element =
        element +
        '<div><label>Durée : </label><label style="color:grey;padding-left : 2px;"> ' +
        link.labelChrono +
        "</label></div>";
      element =
        element +
        '<div><label>Réalisé le : </label><label style="color:grey;padding-left : 2px;"> ' +
        moment(link.updatedAt).format("DD/MM/YYYY, HH:mm") +
        "</label></div>";
      element = element + "</div>"; // Fin colonne 1
      element = element + '<div class="column is-half">'; // Colonne2
      element =
        element +
        '<div style="text-align: center;color: grey;font-size: medium;"><label>Paramètres respiratoires de l\'exercice</label></div>';
      var profil =
        resultatJeuxFormat.Profil == "2"
          ? "TONIQUE"
          : resultatJeuxFormat.Profil == "0"
          ? "PROFONDE"
          : "BASIQUE";
      element =
        element +
        '<div layout = "row" style=" width: 33%;float: left;text-align: center;border-right: black;border-right-style: solid;border-right-width: 1px;"><div style="font-size: smaller;"><label>CPM</label></div><div  style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.Cpm +
        '</label></div><div><label style="font-size: smaller;">Profil</label></div><div><label  style="font-size: large;background: darkgrey;color: turquoise;">' +
        profil +
        "</label></div></div>"; // colonne 1
      element =
        element +
        '<div layout = "row" style=" width: 33%;float: left;text-align: center;border-right: black;border-right-style: solid;border-right-width: 1px;"><div style="font-size: smaller;"><label>INSPIRATION</label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.Inspi +
        '</label></div><div  style="font-size: smaller;"><label>PAUSE D\'INSPIRATION</label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.PauseInspi +
        "</label></div></div>"; // colonne 2
      element =
        element +
        '<div layout = "row" style = "text-align: center;" ><div style="font-size: smaller;"><label>EXPIRATION</label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.Expi +
        '</label></div><div style="font-size: smaller;"><label>PAUSE D\'EXPIRATION </label></div><div style="font-size: large;background: darkgrey;color: turquoise;"><label>' +
        resultatJeuxFormat.PauseExpi +
        "</label></div></div>"; // colonne 3

      element = element + "</div>"; // Fin colonne2

      element = element + "</div>"; // Fin haut de page

      element = element + '<div class="columns">'; //Milieu de page

      element =
        element +
        '<div class="column" style="text-align: center;min-height:350px;">';
      element =
        element +
        '<img style="height: 200px" src="' +
        this.imageChartFc +
        '" />';
      element =
        element +
        '<img style="height: 150px" src="' +
        this.imageChartCc +
        '" />';
      element = element + "</div>";

      element = element + "</div>"; // Fin Mileu de page

      element = element + '<div class="columns">'; // Début bas de page
      element = element + '<div class="column is-one-fifth">';
      element = element + '<div class="RondBpmCcEdition" >';
      element = element + '<div class="RondBpm2Result">';
      element =
        element +
        '<div style="position: relative;top: 50%; transform: translateY(-50%);"><label class="labelBpmResult">' +
        link.fcMoyenne +
        '</label><label class="labelBpmDetail">bpm</label></div>';
      element = element + "</div>";
      element = element + "</div>";
      element =
        element +
        '<div class="expert_label_battement_result_edition"><label>Moyenne fréquence cardiaque</label></div>';
      element = element + "</div>";
      element = element + '<div class="column" >';

      element = element + '<div class="columns">'; // Zone barre
      element = element + '<div  class="column">';
      element =
        element +
        '<div class="respi_text_bar_resultEdition"><label> ' +
        link.valeurRouge +
        "%</label> </div>";
      element =
        element +
        '<div class="respi_progress_bar_resultEdition"><div class="cc_red_result"  style="width: ' +
        link.valeurRouge +
        '%;"></div>';
      element = element + "</div></div>";
      element = element + '<div class="column">';
      element =
        element +
        ' <div class="respi_text_bar_resultEdition"><label> ' +
        link.valeurOrange +
        "%</label></div>";
      element =
        element +
        '<div class="respi_progress_bar_resultEdition"> <div class="cc_orange_result"  style="width: ' +
        link.valeurOrange +
        '%;"></div>';
      element = element + "</div></div>";
      element = element + '<div class="column">';
      element =
        element +
        '<div class="respi_text_bar_resultEdition"><label> ' +
        link.valeurVerte +
        "%</label></div>";
      element =
        element +
        '<div class="respi_progress_bar_resultEdition"><div class="cc_green_result"  style="width: ' +
        link.valeurVerte +
        '%;"></div>';
      element = element + "</div> </div>";
      element = element + "</div>";

      element = element + '<div class="columns">'; // Zone en dessous des barres
      element =
        element +
        '<div class="expert_result_zone_coherence_1Edition column is-one-fifth"></div><div class="expert_result_zone_coherence_2 column"></div><div class="expert_result_zone_coherence_3Edition column is-one-fifth"></div>';
      element = element + "</div>";
      element =
        element +
        '<div class="expert_texte_zone_coherenceEdition"><label>% DE TEMPS PASSE DANS CHAQUE ZONE DE COHERENCE</label></div>';
      element =
        element +
        '<div style="margin: auto; background-color: transparent;" class="columns"><div class ="column" style="border: black;border-style: solid;border-width: 1px;padding: 5px;margin: 5px;"><p><label>Commentaire : </label></p><p>' +
        (link.comment === undefined ? "" : link.comment) +
        "</p></div></div>";
      element = element + "</div>";
      element =
        element + '<div style="height: 200px;"  class="column is-one-fifth">';
      element =
        element +
        '<div class="RondBpmCcEdition" style="background-color: ' +
        link.couleurCCMoyenne +
        ';">';
      element = element + '<div class="RondBpm2Result">';
      element =
        element +
        '<div style="position: relative;top: 50%; transform: translateY(-50%);"><label class="labelBpmResult ng-binding">' +
        link.ccMoyenne +
        '</label><label class="labelBpmDetail"> %</label></div>';
      element = element + "</div>";
      element = element + "</div>";
      element =
        element +
        '<div class="expert_label_battement_result_edition"><label>Moyenne cohérence cardiaque</label></div>';

      element = element + "</div>";
      element = element + "</div>"; // Fin bas de page
      element = element + "</div>";
      var imageSymbioCenter =
        "<img style='height:25px;' src='" + this.dataUrl + "'/>";
      element =
        element +
        "</body><footer style='background-color:#1E2C39;'><div style='width:50%;display:inline-block;'>" +
        imageSymbioCenter +
        "</div><div style='text-align:right;color:white;height:100%;width:50%;display:inline-block;'>" +
        moment().format("[Le] DD/MM/YYYY [à] hh:mm") +
        "</div></footer></html>";
      var opt = {
        margin: [0.5, 0.5, 0, 0.5],
        filename: "resultat.pdf",
        image: { type: "png", quality: 1 },
        html2canvas: { dpi: 300 },
        jsPDF: { unit: "mm", format: "a4", orientation: "l" },
      };
      html2pdf(element, opt);
    },
    exportPdf() {
      var doc = new jsPDF("l");
      var source = window.document.getElementById("export-pdf");
      var sourceHautDroit = window.document.getElementById(
        "export-pdf-hautdroit"
      );
      doc.fromHTML(source, 10, 10);
      doc.fromHTML(sourceHautDroit, 200, 10);
      doc.addImage(this.imageChartFc, "PNG", 50, 65, 200, 60);
      doc.addImage(this.imageChartCc, "PNG", 50, 125, 200, 60);
      doc.save("resultat.pdf");
    },
    async getStatistics() {
      this.disabledStats = true;
      let filter_user = false;
      let Liste_Utilisateurs = [];

      if (this.userSelected2 !== "tous" && this.userSelected2) {
        Liste_Utilisateurs.push(this.userSelected2);
        filter_user = true;
      } else if (
        this.userSelected2 === "tous" &&
        !this.utilisateursSessionGroup2
      ) {
        Liste_Utilisateurs = this.utilisateurs.filter((e) => {
          return (
            e.attributes.acces_donnees_therapeute !== undefined &&
            e.attributes.acces_donnees_therapeute
          );
        });
      } else if (this.utilisateursSessionGroup2) {
        Liste_Utilisateurs = this.utilisateursSessionGroup2;
      }

      const General = {
        BpmCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
          high: [],
          medium: [],
          low: [],
        },
        AverageCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
        },
        Time: {
          donnees: [],
          high: [],
          medium: [],
          low: [],
        },
      };

      const Utilisateur = {
        BpmCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
          high: [],
          medium: [],
          low: [],
        },
        AverageCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
        },
        Time: {
          donnees: [],
          high: [],
          medium: [],
          low: [],
        },
      };

      const UtilisateurCabinet = {
        BpmCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
          high: [],
          medium: [],
          low: [],
        },
        AverageCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
        },
        Time: {
          donnees: [],
          high: [],
          medium: [],
          low: [],
        },
      };

      const UtilisateurLicence = {
        BpmCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
          high: [],
          medium: [],
          low: [],
        },
        AverageCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
        },
        Time: {
          donnees: [],
          high: [],
          medium: [],
          low: [],
        },
      };

      const UtilisateurAffiliation = {
        BpmCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
          high: [],
          medium: [],
          low: [],
        },
        AverageCC: {
          donnees: [],
          somme: 0,
          moyenne: 0,
          max: 0,
          min: 0,
        },
        Time: {
          donnees: [],
          high: [],
          medium: [],
          low: [],
        },
      };
      const ObjectLinkCompleted = Parse.Object.extend("link_completed");
      const QueryLinkCompleted = new Parse.Query(ObjectLinkCompleted);
      QueryLinkCompleted.limit(10000);
      QueryLinkCompleted.exists("resultatJeux");
      QueryLinkCompleted.containedIn("user", Liste_Utilisateurs);
      if (this.filter_date_start && this.filter_date_end) {
        QueryLinkCompleted.greaterThanOrEqualTo(
          "createdAt",
          this.filter_date_start
        );
        QueryLinkCompleted.lessThan("createdAt", this.filter_date_end);
      }
      if (this.exerciceSelected !== "tous" && this.exerciceSelected) {
        QueryLinkCompleted.equalTo("link", this.exerciceSelected);
      }
      const ResponseLinkCompleted = await QueryLinkCompleted.find();
      // var test = JSON.stringify(ResponseLinkCompleted);

      await asyncForEach(ResponseLinkCompleted, async (link) => {
        if (link.attributes.user.attributes.acces_donnees_therapeute) {
          if (
            link.attributes.resultatJeux &&
            link.attributes.resultatJeux.indexOf("NaN") === -1 &&
            link.attributes.resultatJeux.indexOf("undefined") === -1 &&
            link.attributes.resultatJeux.indexOf("Infinity") === -1
          ) {
            const resultatJeuxFormat = JSON.parse(link.attributes.resultatJeux);
            if (resultatJeuxFormat.ArrayTacho.length === 0) return;

            if (resultatJeuxFormat.BpmCC) {
              General.BpmCC.donnees.push(Math.round(resultatJeuxFormat.BpmCC));
              General.BpmCC.somme += Math.round(resultatJeuxFormat.BpmCC);
              if (resultatJeuxFormat.BpmCC >= 90)
                General.BpmCC.high.push(resultatJeuxFormat.BpmCC);
              else if (resultatJeuxFormat.BpmCC < 70)
                General.BpmCC.low.push(resultatJeuxFormat.BpmCC);
              else General.BpmCC.medium.push(resultatJeuxFormat.BpmCC);
            }
            if (resultatJeuxFormat.AverageCC) {
              General.AverageCC.donnees.push(
                Math.round(resultatJeuxFormat.AverageCC)
              );
              General.AverageCC.somme += Math.round(
                resultatJeuxFormat.AverageCC
              );
            }
            if (resultatJeuxFormat.TimeCompletion && resultatJeuxFormat.Timer) {
              const valeur =
                (resultatJeuxFormat.TimeCompletion * 100) /
                resultatJeuxFormat.Timer;
              General.Time.donnees.push(valeur);
              if (valeur >= 90) General.Time.high.push(valeur);
              else if (valeur < 60) General.Time.low.push(valeur);
              else General.Time.medium.push(valeur);
            }
            if (
              Liste_Utilisateurs.find((e) => {
                return e.id === link.attributes.user.id;
              })
            ) {
              if (resultatJeuxFormat.BpmCC) {
                Utilisateur.BpmCC.donnees.push(
                  Math.round(resultatJeuxFormat.BpmCC)
                );
                Utilisateur.BpmCC.somme += Math.round(resultatJeuxFormat.BpmCC);
                if (resultatJeuxFormat.BpmCC >= 90)
                  Utilisateur.BpmCC.high.push(resultatJeuxFormat.BpmCC);
                else if (resultatJeuxFormat.BpmCC < 70)
                  Utilisateur.BpmCC.low.push(resultatJeuxFormat.BpmCC);
                else Utilisateur.BpmCC.medium.push(resultatJeuxFormat.BpmCC);
              }
              if (resultatJeuxFormat.AverageCC) {
                Utilisateur.AverageCC.donnees.push(
                  Math.round(resultatJeuxFormat.AverageCC)
                );
                Utilisateur.AverageCC.somme += Math.round(
                  resultatJeuxFormat.AverageCC
                );
              }
              if (
                resultatJeuxFormat.TimeCompletion &&
                resultatJeuxFormat.Timer
              ) {
                const valeur =
                  (resultatJeuxFormat.TimeCompletion * 100) /
                  resultatJeuxFormat.Timer;
                Utilisateur.Time.donnees.push(valeur);
                if (valeur >= 90) Utilisateur.Time.high.push(valeur);
                else if (valeur < 60) Utilisateur.Time.low.push(valeur);
                else Utilisateur.Time.medium.push(valeur);
              }
              if (
                link.attributes.user.attributes.type_pratique === "cabinet" &&
                !link.attributes.user.attributes.affiliation
              ) {
                if (resultatJeuxFormat.BpmCC) {
                  UtilisateurCabinet.BpmCC.donnees.push(
                    Math.round(resultatJeuxFormat.BpmCC)
                  );
                  UtilisateurCabinet.BpmCC.somme += Math.round(
                    resultatJeuxFormat.BpmCC
                  );
                  if (resultatJeuxFormat.BpmCC >= 90)
                    UtilisateurCabinet.BpmCC.high.push(
                      resultatJeuxFormat.BpmCC
                    );
                  else if (resultatJeuxFormat.BpmCC < 70)
                    UtilisateurCabinet.BpmCC.low.push(resultatJeuxFormat.BpmCC);
                  else
                    UtilisateurCabinet.BpmCC.medium.push(
                      resultatJeuxFormat.BpmCC
                    );
                }
                if (resultatJeuxFormat.AverageCC) {
                  UtilisateurCabinet.AverageCC.donnees.push(
                    Math.round(resultatJeuxFormat.AverageCC)
                  );
                  UtilisateurCabinet.AverageCC.somme += Math.round(
                    resultatJeuxFormat.AverageCC
                  );
                }
                if (
                  resultatJeuxFormat.TimeCompletion &&
                  resultatJeuxFormat.Timer
                ) {
                  const valeur =
                    (resultatJeuxFormat.TimeCompletion * 100) /
                    resultatJeuxFormat.Timer;
                  UtilisateurCabinet.Time.donnees.push(valeur);
                  if (valeur >= 90) UtilisateurCabinet.Time.high.push(valeur);
                  else if (valeur < 60)
                    UtilisateurCabinet.Time.low.push(valeur);
                  else UtilisateurCabinet.Time.medium.push(valeur);
                }
              } else if (
                link.attributes.user.attributes.type_pratique === "licence" &&
                !link.attributes.user.attributes.affiliation
              ) {
                if (resultatJeuxFormat.BpmCC) {
                  UtilisateurLicence.BpmCC.donnees.push(
                    Math.round(resultatJeuxFormat.BpmCC)
                  );
                  UtilisateurLicence.BpmCC.somme += Math.round(
                    resultatJeuxFormat.BpmCC
                  );
                  if (resultatJeuxFormat.BpmCC >= 90)
                    UtilisateurLicence.BpmCC.high.push(
                      resultatJeuxFormat.BpmCC
                    );
                  else if (resultatJeuxFormat.BpmCC < 70)
                    UtilisateurLicence.BpmCC.low.push(resultatJeuxFormat.BpmCC);
                  else
                    UtilisateurLicence.BpmCC.medium.push(
                      resultatJeuxFormat.BpmCC
                    );
                }
                if (resultatJeuxFormat.AverageCC) {
                  UtilisateurLicence.AverageCC.donnees.push(
                    Math.round(resultatJeuxFormat.AverageCC)
                  );
                  UtilisateurLicence.AverageCC.somme += Math.round(
                    resultatJeuxFormat.AverageCC
                  );
                }
                if (
                  resultatJeuxFormat.TimeCompletion &&
                  resultatJeuxFormat.Timer
                ) {
                  const valeur =
                    (resultatJeuxFormat.TimeCompletion * 100) /
                    resultatJeuxFormat.Timer;
                  UtilisateurLicence.Time.donnees.push(valeur);
                  if (valeur >= 90) UtilisateurLicence.Time.high.push(valeur);
                  else if (valeur < 60)
                    UtilisateurLicence.Time.low.push(valeur);
                  else UtilisateurLicence.Time.medium.push(valeur);
                }
              } else if (link.attributes.user.attributes.affiliation) {
                if (resultatJeuxFormat.BpmCC) {
                  UtilisateurAffiliation.BpmCC.donnees.push(
                    Math.round(resultatJeuxFormat.BpmCC)
                  );
                  UtilisateurAffiliation.BpmCC.somme += Math.round(
                    resultatJeuxFormat.BpmCC
                  );
                  if (resultatJeuxFormat.BpmCC >= 90)
                    UtilisateurAffiliation.BpmCC.high.push(
                      resultatJeuxFormat.BpmCC
                    );
                  else if (resultatJeuxFormat.BpmCC < 70)
                    UtilisateurAffiliation.BpmCC.low.push(
                      resultatJeuxFormat.BpmCC
                    );
                  else
                    UtilisateurAffiliation.BpmCC.medium.push(
                      resultatJeuxFormat.BpmCC
                    );
                }
                if (resultatJeuxFormat.AverageCC) {
                  UtilisateurAffiliation.AverageCC.donnees.push(
                    Math.round(resultatJeuxFormat.AverageCC)
                  );
                  UtilisateurAffiliation.AverageCC.somme += Math.round(
                    resultatJeuxFormat.AverageCC
                  );
                }
                if (
                  resultatJeuxFormat.TimeCompletion &&
                  resultatJeuxFormat.Timer
                ) {
                  const valeur =
                    (resultatJeuxFormat.TimeCompletion * 100) /
                    resultatJeuxFormat.Timer;
                  UtilisateurAffiliation.Time.donnees.push(valeur);
                  if (valeur >= 90)
                    UtilisateurAffiliation.Time.high.push(valeur);
                  else if (valeur < 60)
                    UtilisateurAffiliation.Time.low.push(valeur);
                  else UtilisateurAffiliation.Time.medium.push(valeur);
                }
              }
            }
          }
        }
      });
      General.BpmCC.moyenne = General.BpmCC.donnees.length
        ? Math.round(General.BpmCC.somme / General.BpmCC.donnees.length)
        : 0;
      General.BpmCC.max = General.BpmCC.donnees.length
        ? Math.max(...General.BpmCC.donnees)
        : 0;
      General.BpmCC.min = General.BpmCC.donnees.length
        ? Math.min(...General.BpmCC.donnees)
        : 0;
      General.AverageCC.moyenne = General.AverageCC.donnees.length
        ? Math.round(General.AverageCC.somme / General.AverageCC.donnees.length)
        : 0;
      General.AverageCC.max = General.BpmCC.donnees.length
        ? Math.max(...General.AverageCC.donnees)
        : 0;
      General.AverageCC.min = General.BpmCC.donnees.length
        ? Math.min(...General.AverageCC.donnees)
        : 0;
      Utilisateur.BpmCC.moyenne = Utilisateur.BpmCC.donnees.length
        ? Math.round(Utilisateur.BpmCC.somme / Utilisateur.BpmCC.donnees.length)
        : 0;
      Utilisateur.BpmCC.max = Utilisateur.BpmCC.donnees.length
        ? Math.max(...Utilisateur.BpmCC.donnees)
        : 0;
      Utilisateur.BpmCC.min = Utilisateur.BpmCC.donnees.length
        ? Math.min(...Utilisateur.BpmCC.donnees)
        : 0;
      Utilisateur.AverageCC.moyenne = Utilisateur.AverageCC.donnees.length
        ? Math.round(
            Utilisateur.AverageCC.somme / Utilisateur.AverageCC.donnees.length
          )
        : 0;
      Utilisateur.AverageCC.max = Utilisateur.BpmCC.donnees.length
        ? Math.max(...Utilisateur.AverageCC.donnees)
        : 0;
      Utilisateur.AverageCC.min = Utilisateur.BpmCC.donnees.length
        ? Math.min(...Utilisateur.AverageCC.donnees)
        : 0;
      UtilisateurCabinet.BpmCC.moyenne = UtilisateurCabinet.BpmCC.donnees.length
        ? Math.round(
            UtilisateurCabinet.BpmCC.somme /
              UtilisateurCabinet.BpmCC.donnees.length
          )
        : 0;
      UtilisateurCabinet.BpmCC.max = UtilisateurCabinet.BpmCC.donnees.length
        ? Math.max(...UtilisateurCabinet.BpmCC.donnees)
        : 0;
      UtilisateurCabinet.BpmCC.min = UtilisateurCabinet.BpmCC.donnees.length
        ? Math.min(...UtilisateurCabinet.BpmCC.donnees)
        : 0;
      UtilisateurCabinet.AverageCC.moyenne = UtilisateurCabinet.AverageCC
        .donnees.length
        ? Math.round(
            UtilisateurCabinet.AverageCC.somme /
              UtilisateurCabinet.AverageCC.donnees.length
          )
        : 0;
      UtilisateurCabinet.AverageCC.max = UtilisateurCabinet.BpmCC.donnees.length
        ? Math.max(...UtilisateurCabinet.AverageCC.donnees)
        : 0;
      UtilisateurCabinet.AverageCC.min = UtilisateurCabinet.BpmCC.donnees.length
        ? Math.min(...UtilisateurCabinet.AverageCC.donnees)
        : 0;
      UtilisateurLicence.BpmCC.moyenne = UtilisateurLicence.BpmCC.donnees.length
        ? Math.round(
            UtilisateurLicence.BpmCC.somme /
              UtilisateurLicence.BpmCC.donnees.length
          )
        : 0;
      UtilisateurLicence.BpmCC.max = UtilisateurLicence.BpmCC.donnees.length
        ? Math.max(...UtilisateurLicence.BpmCC.donnees)
        : 0;
      UtilisateurLicence.BpmCC.min = UtilisateurLicence.BpmCC.donnees.length
        ? Math.min(...UtilisateurLicence.BpmCC.donnees)
        : 0;
      UtilisateurLicence.AverageCC.moyenne = UtilisateurLicence.AverageCC
        .donnees.length
        ? Math.round(
            UtilisateurLicence.AverageCC.somme /
              UtilisateurLicence.AverageCC.donnees.length
          )
        : 0;
      UtilisateurLicence.AverageCC.max = UtilisateurLicence.BpmCC.donnees.length
        ? Math.max(...UtilisateurLicence.AverageCC.donnees)
        : 0;
      UtilisateurLicence.AverageCC.min = UtilisateurLicence.BpmCC.donnees.length
        ? Math.min(...UtilisateurLicence.AverageCC.donnees)
        : 0;
      UtilisateurAffiliation.BpmCC.moyenne = UtilisateurAffiliation.BpmCC
        .donnees.length
        ? Math.round(
            UtilisateurAffiliation.BpmCC.somme /
              UtilisateurAffiliation.BpmCC.donnees.length
          )
        : 0;
      UtilisateurAffiliation.BpmCC.max = UtilisateurAffiliation.BpmCC.donnees
        .length
        ? Math.max(...UtilisateurAffiliation.BpmCC.donnees)
        : 0;
      UtilisateurAffiliation.BpmCC.min = UtilisateurAffiliation.BpmCC.donnees
        .length
        ? Math.min(...UtilisateurAffiliation.BpmCC.donnees)
        : 0;
      UtilisateurAffiliation.AverageCC.moyenne = UtilisateurAffiliation
        .AverageCC.donnees.length
        ? Math.round(
            UtilisateurAffiliation.AverageCC.somme /
              UtilisateurAffiliation.AverageCC.donnees.length
          )
        : 0;
      UtilisateurAffiliation.AverageCC.max = UtilisateurAffiliation.BpmCC
        .donnees.length
        ? Math.max(...UtilisateurAffiliation.AverageCC.donnees)
        : 0;
      UtilisateurAffiliation.AverageCC.min = UtilisateurAffiliation.BpmCC
        .donnees.length
        ? Math.min(...UtilisateurAffiliation.AverageCC.donnees)
        : 0;

      this.statistiques.UtilisateurCabinet = UtilisateurCabinet;
      this.statistiques.UtilisateurLicence = UtilisateurLicence;
      this.statistiques.UtilisateurAffiliation = UtilisateurAffiliation;

      if (!filter_user) {
        this.statistiques.General = General;
        this.statistiques.Utilisateur = Utilisateur;
      }
      this.disabledStats = false;
    },
  },
  async mounted() {
    if (this.userParticulier !== "") {
      this.indefinteToast = this.$buefy.toast.open({
        duration: 10000000,
        message: `Veuillez patienter pendant l'ouverture des résultats.`,
        type: "is-warning",
      });
    }
    ParseConfig();
    const loadingComponent = this.$buefy.loading.open();
    this.toDataUrl(
      "https://www.symbiocenter.com/assets/symbiocenter.png",
      this,
      function () {
        return;
      }
    );

    var therapeute = await Parse.User.current();

    this.nomTherapeute =
      (therapeute.attributes.firstname !== undefined
        ? therapeute.attributes.firstname
        : "") +
      " " +
      (therapeute.attributes.lastname !== undefined
        ? therapeute.attributes.lastname
        : "");

    try {
      this.FoldersBrique = await getAllFolders();
      this.cloneFoldersBrique = cloneDeep(this.FoldersBrique);
      this.nbUtilisateur.cabinet = this.utilisateurs.filter((utilisateur) => {
        return (
          utilisateur.attributes.type_pratique === "cabinet" &&
          !utilisateur.attributes.affiliation
        );
      }).length;
      this.nbUtilisateur.licence = this.utilisateurs.filter((utilisateur) => {
        return (
          utilisateur.attributes.type_pratique === "licence" &&
          !utilisateur.attributes.affiliation
        );
      }).length;
      this.nbUtilisateur.affiliation = this.utilisateurs.filter(
        (utilisateur) => {
          return utilisateur.attributes.affiliation;
        }
      ).length;
      this.nbUtilisateur.total =
        this.nbUtilisateur.cabinet +
        this.nbUtilisateur.licence +
        this.nbUtilisateur.affiliation;

      await this.traitement(this.utilisateurs);

      loadingComponent.close();
    } catch (error) {
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Echec de récupérations de l'avancement des protocoles.",
        type: "is-danger",
      });
    }
    await this.getFileLinkCompleted(this.userSelected);
    await this.getStatistics();
    if (this.userParticulier !== "") {
      this.utilisateurSelectionne = this.utilisateurs.filter((utilisateur) => {
        return utilisateur.attributes.username === this.userParticulier;
      });
      this.isCardResultat = true;
      if (this.indefinteToast) {
        this.indefinteToast.close();
        this.indefinteToast = null;
      }
    }
  },
  watch: {
    async userSelected() {
      const loadingComponent = this.$buefy.loading.open();
      this.foldersUtilisateursSelected = null;
      if (this.userSelected !== "tous") {
        this.foldersUtilisateursSelected = this.utilisateurs.find((e) => {
          return e.id === this.userSelected.id;
        });
        var listeUser = this.utilisateurs.filter((e) => {
          return e.id === this.userSelected.id;
        });
        await this.traitement(listeUser);
        await this.getFileLinkCompleted(this.foldersUtilisateursSelected);
        this.getStatistics();
        loadingComponent.close();
      } else {
        await this.traitement(this.utilisateurs);
        await this.getFileLinkCompleted("tous");
        this.getStatistics();
        loadingComponent.close();
      }
      loadingComponent.close();
    },
    async utilisateursSessionGroup() {
      if (this.utilisateursSessionGroup) {
        await this.traitement(this.utilisateursSessionGroup);
        await this.getFileLinkCompleted();
        this.getStatistics();
        return;
      }
      await this.traitement(this.utilisateurs);
      await this.getFileLinkCompleted();
      this.getStatistics();
    },
    async userSelected2() {
      await this.getFileLinkCompleted(this.userSelected2);
      this.getStatistics();
    },
    async groupSelected2() {
      if (this.groupSelected2 === "tous") {
        this.utilisateursSessionGroup2 = null;
      } else {
        const RelationUserFromSessionGroup =
          this.groupSelected2.relation("users");
        let QueryRelationUserFromSessionGroup =
          RelationUserFromSessionGroup.query();
        QueryRelationUserFromSessionGroup.limit(1000);
        this.utilisateursSessionGroup2 =
          await QueryRelationUserFromSessionGroup.find();
      }
      await this.getFileLinkCompleted(this.userSelected2);
      this.getStatistics();
    },
    async groupSelected() {
      await this.getFileLinkCompleted(this.userSelected2);
      this.getStatistics();
    },
    async protocolesLibreSelected() {
      await this.getFileLinkCompleted(this.userSelected2);
    },
    isCardResultat() {
      this.userSelected2 = cloneDeep(this.userSelected);

      if (this.utilisateurSelectionne !== null)
        this.userSelected2 = this.utilisateurSelectionne[0];
      this.groupSelected2 = cloneDeep(this.groupSelected);
      this.utilisateursSessionGroup2 = cloneDeep(this.utilisateursSessionGroup);
    },
    async isCardStatistiques() {
      if (!this.isCardStatistiques) {
        this.filter_date_start = null;
        this.filter_date_end = null;
        await this.getFileLinkCompleted(this.userSelected2);
        this.getStatistics();
        return;
      }
      this.userSelected2 = cloneDeep(this.userSelected);
      this.groupSelected2 = cloneDeep(this.groupSelected);
      this.utilisateursSessionGroup2 = cloneDeep(this.utilisateursSessionGroup);
    },
    async filter_date_start() {
      if (this.filter_date_start && this.filter_date_end) {
        await this.getFileLinkCompleted(this.userSelected2);
        this.getStatistics();
      }
    },
    async filter_date_end() {
      if (this.filter_date_start && this.filter_date_end) {
        await this.getFileLinkCompleted(this.userSelected2);
        this.getStatistics();
      }
    },
    exerciceSelected() {
      if (
        this.exerciceSelected &&
        this.exerciceSelected !== "tous" &&
        this.resultatCoherenceCardiaque
      ) {
        const resultatsFilter = this.resultatCoherenceCardiaque.filter((e) => {
          return e.attributes.link.id === this.exerciceSelected.id;
        });
        if (resultatsFilter && resultatsFilter.length) {
          this.getStatistics(resultatsFilter);
        } else {
          this.getStatistics();
        }
      } else {
        this.getStatistics();
      }
    },
  },
};
</script>
<style>
.colonneUnEdition {
  width: 170px;
  float: left;
  margin-left: 20px;
}
.colonneDeuxEdition {
  width: 700px;
  float: left;
}
.colonneTroisEdition {
  width: 170px;
  float: left;
  margin-left: 20px;
}
.RondBpm2Result {
  border-style: none;
  border-width: 0px;
  border-radius: 100%;
  width: 90px;
  height: 90px;
  text-align: center;
  margin: auto;
  background-color: white;
  -moz-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -o-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=rgba(0, 0, 0, 0.25), Direction=239, Strength=5);
}
.labelCCMoyenne {
  font-family: Helvetica, Verdana, Arial, sans-serif;
  font-size: x-large;
  font-weight: bold;
  /* margin-top: 10px; */
}
.expert_label_battement {
  width: 50%;
  text-align: center;
  padding-top: 20px;
}

.expert_label_battement_result {
  width: 50%;
  text-align: center;
  padding-top: 5px;
  color: white;
  margin: auto;
}
.expert_label_battement_result_edition {
  width: 50%;
  text-align: center;
  padding-top: 5px;
  color: black;
  margin: auto;
  font-size: smaller;
}
.BlocBpm {
  padding-right: 40px;
  padding-left: 20px;
}
.respi_text_bar_result {
  text-align: center;
  width: 100px;
  padding-top: 5px;
  color: white;
}
.respi_text_bar_resultEdition {
  text-align: center;
  padding-top: 5px;
  color: black;
}
.labelBpm {
  font-size: xx-large;
  color: black;
  top: -12px;
}

.labelBpmResult {
  font-size: x-large;
  color: black;
  top: -12px;
}

.labelBpmDetail {
  color: black;
  top: -12px;
}

.blocZoneResult {
  width: 450px;
  @media screen and (max-width: 1600px) {
    width: 330px;
  }
}
.RondBpmCcEdition {
  border-style: solid;
  border-width: 3px;
  border-radius: 100%;
  width: 120px;
  height: 120px;
  text-align: center;
  position: relative;
  padding-top: 12px;
  border-color: black;
  background-color: #46bfcb;
  margin: auto;
}

.respi_progress_bar_resultEdition {
  -moz-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  -o-box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  box-shadow: -3px 5px 5px 0px rgba(0, 0, 0, 0.25);
  filter: progid:DXImageTransform.Microsoft.Shadow(color=rgba(0, 0, 0, 0.25), Direction=239, Strength=5);
  width: 100%;
  height: 35px; //background: white; /* Old browsers */
  float: left; //margin-left:20px;
  margin: auto;
  border-radius: 14px;
  border-top-color: black;
  border-top-style: solid;
  border-top-width: 3px;
  border-right-color: black;
  border-right-style: solid;
  border-right-width: 10px;
  border-bottom-color: black;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  border-left-color: black;
  border-left-style: solid;
  border-left-width: 10px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  .progress_bar {
    //background:cover;
    background: #3ec2cf;
    border-radius: 6px;
    width: 44px;
    position: relative;
  }
  @media screen and (max-width: 1600px) {
    width: 300px;
  }
}
.expert_result_zone_coherence_1 {
  text-align: center;
  height: 25px;
  width: 37%;
  border-bottom-style: solid;
  border-bottom-color: white;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-color: white;
  border-left-width: 1px;
  @media screen and (max-width: 1600px) {
    width: 32%;
  }
}
.expert_result_zone_coherence_1Edition {
  text-align: center;
  height: 25px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-left-style: solid;
  border-left-color: black;
  border-left-width: 1px;
}

.expert_result_zone_coherence_2 {
  text-align: center;
  height: 25px;
  width: 26%;
  @media screen and (max-width: 1600px) {
    width: 36%;
  }
}

.expert_texte_zone_coherence {
  text-align: center;
  font-size: smaller;
  margin-top: -7px;
  color: white;
}
.expert_texte_zone_coherenceEdition {
  text-align: center;
  font-size: smaller;
  margin-top: -15px;
  color: black;
}
.expert_result_zone_coherence_3Edition {
  text-align: center;
  height: 25px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
  border-right-style: solid;
  border-right-color: black;
  border-right-width: 1px;
}
</style>
