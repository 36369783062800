<template>
  <div class="divCenter">
    <h1 class="pageTitle">Aperçu du Questionnaire</h1>
    <div class="textExplicationBFP">
      <span
        >Des événements soudains, très stressants mais aussi des expériences
        pénibles moins évidentes qui font partie de la vie quotidienne, peuvent
        entrainer des perturbations physiques et émotionnelles, allant d'un
        impact momentané à la blessure psychique qui avec le temps peut prendre
        la forme d’une pathologie. Il est donc primordial d’évaluer précocement
        ce genre de choc vécu. Le Bilan Flash Psychologique (BFP) peut être
        utilisé comme la première étape à réaliser suite à l’événement critique.
        Il vous permet d'évaluer rapidement, après un événement, les meilleures
        conduites de gestion de crise à tenir.
      </span>
    </div>
    <div class="imageContainer">
      <img
        src="../../assets/img/bfp_illustration.png"
        alt="Questionnaire Illustration"
      />
      <img
        src="../../assets/img/bfp_illustration2.png"
        alt="Questionnaire Illustration"
      />
      <img
        src="../../assets/img/bfp_illustration3.png"
        alt="Questionnaire Illustration"
      />
    </div>
    <button class="closeButton" @click="close">Fermer</button>
  </div>
</template>

<script>
export default {
  name: "PrevisualisationQuestionnaire",
  props:
  {
    bfp: {
      required: true,
      type: Boolean,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.divCenter {
  text-align: center;
  max-height: 80vh; /* Hauteur maximale de 80% de la hauteur de la vue */
  overflow-y: auto; /* Ajoute une barre de défilement uniquement lorsque nécessaire */
}

.pageTitle {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.imageContainer {
  /* max-height: 60vh;  */
  overflow-y: auto;
}

/* Personnalisation de la barre de défilement */
.imageContainer::-webkit-scrollbar {
  width: 10px; /* Largeur de la barre de défilement */
}

.imageContainer::-webkit-scrollbar-track {
  background: #f1f1f1; /* Couleur de l'arrière-plan de la piste de la barre de défilement */
}

.imageContainer::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la poignée de la barre de défilement */
}

.closeButton {
  background-color: #ff5733;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.closeButton:hover {
  background-color: #ff4022;
}
.textExplicationBFP {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
}
</style>
