<template>
  <div class="card editUser">
    <div class="card-content">
      <div class="content">
        <div class="overflow">
          <h3 class="title has-text-black is-size-5">
            {{ $t("suiviEtAvancementActivites") }}
          </h3>
          <div>
            <div class="columns">
              <div class="column is-6">
                <b-field custom-class="is-small" label="Nom">
                  <b-input
                    disabled
                    size="is-small"
                    v-model="UserSelected.nom"
                  ></b-input>
                </b-field>
              </div>
              <div class="column is-6">
                <b-field custom-class="is-small" label="Prénom">
                  <b-input
                    disabled
                    size="is-small"
                    v-model="UserSelected.prenom"
                  ></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <br />
          <div
            v-if="UserSelected.acces_donnees_therapeute"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            <planActionBfp
              :filesDonesBFP="filesDonesBFP"
              v-if="filesDonesBFP.length > 0"
            />
          </div>
          <h2 class="table-title-suivi">Suivi de l'utilisateur</h2>
          <div
            v-if="
              UserSelected.typePratique !== 'cabinet' &&
              (UserSelected.acces_donnees_therapeute === undefined ||
                !UserSelected.acces_donnees_therapeute)
            "
          >
            <p>{{ $t("AccesAvancementUtilisateurNonAutorise") }}</p>
            <p>{{ $t("AccesAvancementUtilisateurNonAutorise2") }}</p>
            <p>{{ $t("AccesAvancementUtilisateurNonAutorise3") }}</p>
            <p>{{ $t("AccesAvancementUtilisateurNonAutorise4") }}</p>
            <br />
            <p>{{ $t("PersonnaliserLeMail") }}</p>
            <b-field>
              <b-input
                v-model="mail"
                maxlength="2000"
                type="textarea"
              ></b-input>
            </b-field>
          </div>
          <b-table
            v-else-if="UserSelected.filesDone && UserSelected.filesDone.length"
            class="is-size-6"
            ref="table"
            :data="UserSelected.filesDone"
            :bordered="true"
            :narrowed="true"
            :striped="true"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="id"
            :show-detail-icon="false"
          >
            <template slot-scope="props" slot="header">
              <div>{{ props.column.label }}</div>
            </template>

            <template slot-scope="props">
              <b-table-column field="brique" label="Brique" searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters['brique']"
                    placeholder="Filtrer..."
                    size="is-small"
                  />
                </template>
                {{ props.row.brique }}
              </b-table-column>
              <b-table-column field="protocole" label="Programme" searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters['protocole']"
                    placeholder="Filtrer..."
                    size="is-small"
                  />
                </template>
                {{ props.row.protocole }}
              </b-table-column>
              <b-table-column field="moduleP" label="Seance" searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters['moduleP']"
                    placeholder="Filtrer..."
                    size="is-small"
                  />
                </template>
                {{ props.row.moduleP }}
              </b-table-column>
              <b-table-column field="file" label="Exercice" searchable>
                <template #searchable="props">
                  <b-input
                    v-model="props.filters['file']"
                    placeholder="Filtrer..."
                    size="is-small"
                  />
                </template>
                {{ props.row.file }}
              </b-table-column>
              <b-table-column label="Temps passé">
                <div v-if="props.row.time_elapsed">
                  <div>
                    {{ Math.round(props.row.time_elapsed) }}
                    <span v-if="Math.round(props.row.time_elapsed) === 1">
                      {{ $t("minute") }}
                    </span>
                    <span v-else>{{ $t("minutes") }}</span>
                  </div>
                </div>
              </b-table-column>
              <b-table-column label="Date de réalisation">
                {{ new Date(props.row.createdAt).toLocaleDateString() }}
              </b-table-column>
              <b-table-column label="Heure de réalisation">
                <span v-if="new Date(props.row.createdAt).getMinutes() <= 9">
                  {{ new Date(props.row.createdAt).getHours() }}:0{{
                    new Date(props.row.createdAt).getMinutes()
                  }}
                </span>
                <span v-else>
                  {{ new Date(props.row.createdAt).getHours() }}:{{
                    new Date(props.row.createdAt).getMinutes()
                  }}
                </span>
              </b-table-column>
              <b-table-column field="nb_acces" label="Nb accès">
                {{ props.row.nb_acces }}
              </b-table-column>
              <b-table-column field="estDetaillable" label="Détail">
                <a @click="goToResultatquestionnaire(props.row)">
                  <b-button
                    v-if="
                      props.row.estQuestionnaire !== undefined &&
                      props.row.estQuestionnaire
                    "
                    size="is-small"
                    type="is-light"
                    style="background-color: #218977; color: white"
                  >
                    {{ $t("détail") }}
                  </b-button>
                </a>
                <a @click="sendToCCResult(props.row)">
                  <b-button
                    v-if="
                      coherencevue == false &&
                      props.row.estModuleExpert !== undefined &&
                      props.row.estModuleExpert
                    "
                    size="is-small"
                    type="is-light"
                    style="background-color: #218977; color: white"
                  >
                    {{ $t("détail") }}
                  </b-button>
                </a>
                <a @click="goToResultatcoherence(props.row)">
                  <b-button
                    v-if="
                      (coherencevue == true || coherencevue == undefined) &&
                      props.row.estModuleExpert !== undefined &&
                      props.row.estModuleExpert
                    "
                    size="is-small"
                    type="is-light"
                    style="background-color: #218977; color: white"
                  >
                    {{ $t("détail") }}
                  </b-button>
                </a>
              </b-table-column>
            </template>

            <template slot="detail" slot-scope="props">
              <article class="media">
                <div class="media-content" v-if="questionnaire === true">
                  <div class="container">
                    <table
                      class="table is-striped is-hoverable is-fullwidth table-custom"
                    >
                      <thead>
                        <tr class="is-medium">
                          <th>Note</th>
                          <th>Date</th>
                          <th>Détails</th>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="result in results">
                          <tr :key="result.fileDone.id">
                            <td>{{ result.fileDone.attributes.note }}</td>
                            <td>
                              {{
                                formatDate(result.fileDone.attributes.createdAt)
                              }}
                            </td>
                            <td class="centered-button">
                              <button
                                class="button is-small button-custom"
                                v-on:click="MoreInformations(result.fileDone)"
                              >
                                <i class="icon fas fa-info-circle"></i>
                                <p>détail</p>
                              </button>
                            </td>
                          </tr>
                          <tr
                            v-if="detailsVisible === result.fileDone.id"
                            :key="result.fileDone.id + '-details'"
                            class="details-row"
                          >
                            <td colspan="3">
                              <p>
                                L'analyse de l'exercice :
                                <span
                                  v-html="
                                    result.resultatAnalyse.attributes.text
                                  "
                                ></span>
                              </p>
                              <table
                                v-if="
                                  result.fileDone.attributes
                                    .resultatQuestionnaire &&
                                  result.elements.length > 0
                                "
                                class="table is-bordered is-striped is-hoverable is-fullwidth"
                              >
                                <thead>
                                  <tr>
                                    <th>Question</th>
                                    <th>Réponse</th>
                                    <th>Note</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr
                                    :key="result.id"
                                    v-for="result in result.elements"
                                  >
                                    <td>{{ result.question }}</td>
                                    <td>{{ result.text }}</td>
                                    <td>{{ result.note }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="media-content" v-if="coherence === true">
                  <div class="content">
                    <app-suivieCc
                      v-if="loadProtocoles"
                      :utilisateurs="utilisateurs"
                      :userSelected="userSelected"
                      :folders="folders"
                      :userParticulier="ouvertureSuiviCcUtilisateur"
                      :IUserSelected="UserSelected"
                      :fileCCId="props.row.fileId"
                      :idprotocole="props.row.protocole"
                    />
                  </div>
                </div>
              </article>
            </template>
          </b-table>
          <div v-else>
            {{ $t("aucuneActivite") }}
          </div>
        </div>
        <hr />
        <div
          v-if="
            UserSelected.typePratique !== 'cabinet' &&
            (UserSelected.acces_donnees_therapeute === undefined ||
              !UserSelected.acces_donnees_therapeute)
          "
        >
          <div class="is-pulled-left">
            <a class="button is-cyan" @click="sendMail()">
              {{ $t("envoyerDemandeAutorisation") }}
            </a>
          </div>
          <div class="is-pulled-right">
            <a class="button is-grey" @click="$emit('closeModal')">
              {{ $t("fermer") }}
            </a>
          </div>
        </div>
        <div class="is-pulled-right" v-else>
          <a class="button is-cyan" @click="$emit('closeModal')">
            {{ $t("fermer") }}
          </a>
        </div>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import planActionBfp from "../bfp/planActionBfp.vue";
import Parse from "parse";
// import router from "@/router";
import ParseConfig from "@/api/parse.config.js";
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import asyncForEach from "@/services/general/asyncForEach.js";
import { sortInvArrayByParam } from "@/services/general/sortArrayByParam.js";
import { getObjectById } from "@/services/parse/getObject.js";
import suivieCc from "../tableauDeBord/SuivieCc.vue";
import { getFilesDonesBFP } from "@/services/bfp/getFilesDonesBFP.js";
const User = createNamespacedHelpers("user");
export default {
  name: "suivi-utilisateur",
  components: {
    appSuivieCc: suivieCc,
    planActionBfp,
  },
  props: ["UserSelected"],
  data() {
    return {
      mail: "",
      User: null,
      detailsVisible: null,

      utilisateurs: [],
      utilisateursAffilie: [],
      nbUsersInMyGroupWithActiveAccess: 0,
      filesDone: [],
      loadProtocoles: false,
      questionnaire: false,
      coherence: false,
      userSelected: "tous",
      sessionsGroup: null,
      utilisateursSessionGroup: null,
      groupSelected: "tous",
      affiliation_en_attente: 0,
      loadChartConnection: false,
      loadFilesDone: false,
      ouvertureSuiviCcUtilisateur: "",
      indefinteToast: null,
      IUserSelected: "",
      fileCCId: "",
      idprotocole: "",
      coherencevue: "",
      usergroup: "",
      results: [],
      resultAnswer: [],
      filesDonesBFP: [],
    };
  },
  async created() {
    this.filesDonesBFP = await getFilesDonesBFP(this.UserSelected.objectId);
    //console.log("FILES_DONES_BFP:",this.filesDonesBFP);
  },
  computed: {
    ...User.mapGetters(["group", "folders"]),
  },
  methods: {
    goToResultatquestionnaire(row) {
      this.findQuestionnaireByUser(
        this.UserSelected.objectId,
        row.fileId,
        row.Questionnaire
      );
      this.$refs.table.toggleDetails(row);
      this.questionnaire = true;
      this.coherence = false;
      //transformer le string en objet
    },
    goToResultatcoherence(row) {
      this.$refs.table.toggleDetails(row);
      this.questionnaire = false;
      this.coherence = true;
    },
    formatDate(date) {
      const options = {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      };
      return new Date(date).toLocaleString("fr-FR", options);
    },
    findQuestionnaireByUser(idUser, idFile, questionnaire) {
      //réinitialiser le résultat à chaque fois
      this.results = [];

      const QueryFileDone = new Parse.Query("file_done");
      const QueryUser = new Parse.Query("User");
      const QueryFile = new Parse.Query("file");
      QueryUser.equalTo("objectId", idUser);
      QueryFile.equalTo("objectId", idFile);
      QueryFileDone.matchesQuery("user", QueryUser);
      QueryFileDone.include("file");
      QueryFileDone.matchesQuery("file", QueryFile);
      QueryFileDone.find().then((filesDone) => {
        filesDone.forEach((fileDone) => {
          var resultQuery = new Parse.Query("question_result");
          resultQuery.equalTo("questionnaire", questionnaire);
          resultQuery.lessThanOrEqualTo(
            "required_points",
            fileDone.attributes.note
          );
          resultQuery.descending("required_points");

          // Créer un nouvel objet pour stocker les résultats correspondants à chaque fileDone
          var fileResults = { fileDone, elements: [] };

          if (fileDone.attributes.resultatQuestionnaire) {
            JSON.parse(fileDone.attributes.resultatQuestionnaire).forEach(
              (element) => {
                // Récupérer la note et l'ID de la question pour chaque résultat
                var noteQuestionnaire = element.note;
                var questionId = element.question_id;

                // Initialiser un nouvel attribut 'text' pour chaque résultat
                element.text = "";

                // Rechercher la question et la réponse correspondant à la note
                var queryQuestion = new Parse.Query("question");
                queryQuestion.equalTo("objectId", questionId);
                var queryQuestionAnswer = new Parse.Query("question_answer");
                queryQuestionAnswer.matchesQuery("question", queryQuestion);
                queryQuestionAnswer.equalTo("points", noteQuestionnaire);

                // Récupérer l'attribut 'text' de la réponse et l'affecter à l'attribut 'text' du résultat
                queryQuestionAnswer.first().then((questionAnswer) => {
                  if (questionAnswer) {
                    element.text = questionAnswer.attributes.text;
                    // Ajouter l'élément au tableau des éléments correspondants à ce fileDone
                    fileResults.elements.push(element);
                  }
                });
              }
            );
          }

          resultQuery.first().then((resultatAnalyse) => {
            // Ajouter le nouvel objet fileResults à this.results
            fileResults.resultatAnalyse = resultatAnalyse;
            this.results.push(fileResults);
          });
        });
      });
    },
    MoreInformations(result) {
      this.detailsVisible =
        this.detailsVisible === result.id ? null : result.id;
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    sendToCCResult() {
      localStorage.objectIdToUse = this.UserSelected.objectId;
      window.location.href =
        "https://www.symbiocenter.com/resultatexpert/WFKMKUWnf4?accesDirect=true";
      // router.push({
      //   name: "suiviCCUtilisateur",
      //   params: { username: this.UserSelected.mail },
      // });
    },

    close() {
      this.$emit("closeModal");
    },
    ...User.mapActions({
      actionUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
    }),
    async getMyUsers() {
      ParseConfig();
      var tmpUtilisateur = [];
      this.loadChartConnection = false;
      const UserGroupObject = Parse.Object.extend("user_group");
      const UserGroupTemporary = new UserGroupObject();
      UserGroupTemporary.id = this.group.objectId;

      const UsersFromGroupQuery = new Parse.Query(Parse.User);
      UsersFromGroupQuery.equalTo("group", UserGroupTemporary);
      UsersFromGroupQuery.limit(10000);
      const UsersInMyGroup = await UsersFromGroupQuery.find();

      const UsersAffiliesQuery = new Parse.Query(Parse.User);
      UsersAffiliesQuery.equalTo("affiliation", Parse.User.current());
      UsersAffiliesQuery.limit(10000);
      const UserAffiliated = await UsersAffiliesQuery.find();
      await UsersInMyGroup.forEach((user) => {
        if (
          moment().toDate() <
            moment(user.attributes.fin_acces_plateforme).toDate() &&
          user.attributes.fin_acces_plateforme
        ) {
          this.nbUsersInMyGroupWithActiveAccess++;
        }
        tmpUtilisateur.push(user);
      });
      await UserAffiliated.forEach((user) => {
        var tmpUser = tmpUtilisateur.filter((usr) => {
          return usr.id == user.id;
        });
        if (tmpUser.length == 0) tmpUtilisateur.push(user);
      });
      for (var i = 0; i < tmpUtilisateur.length; i++) {
        if (tmpUtilisateur[i].id == this.UserSelected.objectId) {
          return (this.utilisateurs = tmpUtilisateur[i]);
        }
      }
      this.loadChartConnection = true;
    },
    async getUserAffiliateByGroupId() {
      this.affiliation_en_attente = 0;
      ParseConfig();
      const QueryUser = new Parse.Query(Parse.User);
      QueryUser.equalTo("affiliation", Parse.User.current());
      QueryUser.limit(100000);
      const ResponseUser = await QueryUser.find();
      const QueryUserGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryUserGroup.equalTo("objectId", this.group.objectId);
      const ResponseUserGroup = await QueryUserGroup.first();
      const QueryUser2 = new Parse.Query(Parse.User);
      QueryUser2.equalTo("group", ResponseUserGroup);
      QueryUser2.limit(10000);
      const ResponseUserFromGroup = await QueryUser2.find();
      ResponseUserFromGroup.forEach((e) => {
        if (
          e.attributes.date_invitation_affiliation &&
          !e.attributes.date_affiliation
        ) {
          this.affiliation_en_attente += 1;
        }
      });
      const ArrayUtilisateurs = [];
      await asyncForEach(ResponseUser, async (utilisateur) => {
        const newUtilisateur = {
          firstname: utilisateur.attributes.firstname,
          lastname: utilisateur.attributes.lastname,
          id: utilisateur.id,
          date_affiliation: utilisateur.attributes.date_affiliation,
          date_invitation_affiliation:
            utilisateur.attributes.date_invitation_affiliation,
          email: utilisateur.attributes.username,
        };
        const ObjectUserGroup = Parse.Object.extend("user_group");
        const QueryUserGroup = new Parse.Query(ObjectUserGroup);
        if (utilisateur.attributes.group) {
          QueryUserGroup.equalTo("objectId", utilisateur.attributes.group.id);
          const userGroup = await QueryUserGroup.first();
          if (newUtilisateur.date_affiliation) {
            if (
              (!userGroup.attributes.type_abonnement_perso ||
                userGroup.attributes.type_abonnement_perso === "gratuit") &&
              moment(new Date()).toDate() <=
                moment(newUtilisateur.date_affiliation).add(10, "days").toDate()
            ) {
              newUtilisateur.etat = "essai gratuit";
              this.affiliation_en_attente += 1;
            } else if (userGroup.attributes.type_abonnement_perso === "cplay") {
              newUtilisateur.etat = "valide";
            } else {
              newUtilisateur.etat = "inactif";
            }
          }
        }
        ArrayUtilisateurs.push(newUtilisateur);
      });
      this.utilisateursAffilie = sortInvArrayByParam(
        ArrayUtilisateurs,
        "dateInscription"
      );
    },
    async getFilesDones() {
      this.loadFilesDone = false;
      try {
        var resultat = await Parse.Cloud.run("getFilesDones", {
          groupId: this.group.objectId,
        });
        var jsonResultat = JSON.parse(resultat);
        this.filesDone = jsonResultat;
        this.loadFilesDone = true;
      } catch (error) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des exercices terminés.",
          type: "is-danger",
        });
      }
    },
    async getAllSessionGroup() {
      try {
        const ResponseUserGroup = await getObjectById(
          "user_group",
          this.group.objectId
        );
        const ObjectSessionGroup = Parse.Object.extend("session_group");
        const QuerySessionGroup = new Parse.Query(ObjectSessionGroup);
        QuerySessionGroup.equalTo("user_group", ResponseUserGroup);
        var tmpSessionGroup = await QuerySessionGroup.find();
        this.sessionsGroup = tmpSessionGroup;
      } catch (error){
        //console.log(error);
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des séances de groupe.",
          type: "is-danger",
        });
      }
    },
  },
  async mounted() {
    const usergroupObject = Parse.Object.extend("user_group");
    const userObject = Parse.Object.extend("_User");

    const queryusergroup = new Parse.Query(usergroupObject);
    const queryuser = new Parse.Query(userObject);

    queryuser.equalTo("objectId", this.UserSelected.objectId);
    var recupgroup = await queryuser.find();
    this.usergroup = recupgroup[0].attributes.group.id;
    queryusergroup.equalTo("objectId", this.usergroup);
    var resultat = await queryusergroup.find();
    this.coherencevue = resultat[0].attributes.CoherenceVue;

    if (this.$route.params.username !== undefined) {
      this.ouvertureSuiviCcUtilisateur = this.$route.params.username;
    }
    // try {
    //   var query = new Parse.Query("site_admin");
    //   await query.first();
    // } catch (error) {
    //   await this.actionsLogOut();
    //   router.go("/connexion");
    //   return;
    // }
    const loadingComponent = this.$buefy.loading.open();
    try {
      this.User = await Parse.User.current();
      await this.actionUpdateUserGroup();
      await this.getMyUsers();
      await this.getUserAffiliateByGroupId();
      await this.getAllSessionGroup();
      await this.getFilesDones();
      loadingComponent.close();
      this.loadProtocoles = true;
    } catch (error) {
      //console.log(error);
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Echec de récupérations des informations.",
        type: "is-danger",
      });
    }
  },
  watch: {
    async groupSelected() {
      if (this.groupSelected === "tous") {
        this.utilisateursSessionGroup = null;
        return;
      }
      const RelationUserFromSessionGroup = this.groupSelected.relation("users");
      let QueryRelationUserFromSessionGroup =
        RelationUserFromSessionGroup.query();
      QueryRelationUserFromSessionGroup.limit(1000);
      this.utilisateursSessionGroup =
        await QueryRelationUserFromSessionGroup.find();
    },
  },
};
</script>

<style scoped>
.resultat-questionnaire {
  padding: 1rem;
}

.resultat-questionnaire ul {
  list-style: none;
  padding: 0;
}

.resultat-questionnaire li {
  margin-bottom: 1rem;
  font-size: 1rem;
}
.table-custom {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 0.5em;
  background-color: #f5f5f5;
  box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1);
  border-radius: 6px;
  font-family: Arial, sans-serif;
}

.table-custom thead {
  background-color: rgb(33, 137, 119);
  color: #fff;
}

.table-custom thead th {
  border-bottom: 2px solid #dbdbdb;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.75rem 1.25rem;
}

.table-custom tbody tr {
  border-radius: 6px;
  background-color: #fff;
}

.table-custom tbody tr:hover {
  background-color: #e8e8e8;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.button-custom {
  background-color: #f1c40f;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}
.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.button-custom:hover {
  background-color: #d4ac0d;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.icon {
  margin-right: 0.25rem;
}
.table-custom tbody td {
  padding-top: 0.75em;
  padding-bottom: 0.75em;
  vertical-align: middle;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.button.is-warning {
  background-color: #ffcc00;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
}

.button.is-warning:hover {
  background-color: #e6b800;
  color: #fff;
}

.icon {
  margin-right: 0.25rem;
}
tr.details-row {
  background-color: #f9f9f9;
  font-size: 14px;
}

tr.details-row td {
  padding: 10px;
  text-align: center;
}

tr.details-row td:first-child {
  font-weight: bold;
}

tr.details-row p {
  margin-bottom: 10px;
}

tr.details-row span {
  font-weight: bold;
}
table-title-suivi {
  font-size: 24px;
  margin-bottom: 16px;
}
</style>
