<template>
  <div>
    <div>
      <nav
        style="z-index: 999 !important"
        class="navbar is-fixed-top is-primary navbar-symbiofi navbarResponsiveAni"
        role="navigation"
        aria-label="main navigation"
      >
        <div :class="largeurEcran < 1024 ? 'navbar-menus' : 'navbar-menu'">
          <div class="navbar-start">
            <a class="navbar-item" @click="$emit('openSidebar')">
              <b-icon pack="fas" icon="bars" size="is-medium"></b-icon>
            </a>
            <!-- <router-link class="navbar-item" v-bind:to="'/activites'">
              <b-icon pack="fas" icon="home" size="is-medium"></b-icon>
            </router-link> -->
            <router-link
              class="navbar-item button is-navbar navbar-item"
              v-bind:to="'/activites'"
              v-if="largeurEcran > 1023"
            >
              <span class="versactivites" @click="resetStorage()">
                {{ $t("monEspaceActivite") }}
              </span>
              <img
                src="../../../assets/img/acces_front_cabinet_blanc_001_160519@2x.png"
                class="is-margin-l-10"
              />
            </router-link>
            <b-dropdown aria-role="list" v-if="largeurEcran < 1024">
              <button class="button is-primary" slot="trigger">
                <b-icon pack="fas" icon="angle-down"></b-icon>
              </button>

              <b-dropdown-item aria-role="listitem" @click="deconnexion()">
                {{ $t("deconnexion") }}
              </b-dropdown-item>
            </b-dropdown>
          </div>

          <div class="navbar-end is-pulled-right" v-if="largeurEcran > 1023">
            <div
              class="notification-wrapper notifPlacement"
              @click="toggleNotificationDiv"
            >
              <b-icon icon="bell" class="notification-icon"></b-icon>
              <span class="notification-count" v-if="unreadCount">
                {{ unreadCount }}
              </span>
            </div>
            <div class="notification-div" v-if="showNotificationDiv">
              <NotificationDiv
                :notifications="notifications"
                @notifDetails="handleNotifDetails"
                @markAllAsRead="handlemarkAllAsRead()"
                @updateLastNews="HandleUpdateLastNews()"
              />
            </div>
            <div class="navbar-item">
              <b-dropdown aria-role="list">
                <button class="button is-primary" slot="trigger">
                  <span class="navbar-nom-pro" v-if="user">
                    {{ user.firstname }} {{ user.lastname }}
                  </span>
                  <b-icon pack="fas" icon="angle-down"></b-icon>
                </button>

                <b-dropdown-item aria-role="listitem" @click="deconnexion()">
                  {{ $t("deconnexion") }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="navbar-item">
              <avatar v-if="user && user.photo" :src="user.photo.url"></avatar>
              <avatar
                v-else-if="user && !user.photo"
                :username="user.firstname + ' ' + user.lastname"
              >
              </avatar>
            </div>
          </div>
        </div>
      </nav>
      <div class="pop-up_ephemere" v-if="messageHeader != '' && isDisplay">
        {{ messageHeader }}
        <p class="a-droite" @click="hide()">
          <img src="../../../assets/img/closeheader.png" />
        </p>
      </div>
    </div>
    <!-- <MessagerieBottom></MessagerieBottom> -->
    <contact-icon></contact-icon>
    <div class="popup" v-if="showPopup">
      <NotificationDetails
        :selectedNotification="selectedNotification"
        @close-popup="handleClosePopup"
      ></NotificationDetails>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import Avatar from "vue-avatar";
import router from "@/router";
import { URL_FRONT } from "@/configuration/urlConf.js";
// import NotificationComponent from "@/components/NotificationComponent.vue";
import { verificationToken } from "@/services/token/verificationToken.js";
// import MessagerieBottom from "@/components/chat/MessagerieBottom.vue";
import ContactIcon from "@/components/contact/contactIcon.vue";

import NotificationDiv from "@/components/notification/Notification.vue";
import NotificationDetails from "@/components/notification/NotificationDetails.vue";
const User = createNamespacedHelpers("user");

export default {
  name: "App",
  data() {
    return {
      appParam: null,
      isDisplay: true, // execute le code de haut en bas , d'apres leo - localStorage.setItem("isDisplay") == "false" ? false :
      daysLeft: null,
      notifications: [],
      local: localStorage.getItem(
        "Parse/ilYgUXld1YqqqMvfpqRk59OqXt6MAKd8jis9oLhg/currentUser"
      ),
      //session: windows.localStorage.getItem('isDisplay' ,true), // a voir avec leo
      urlFront: URL_FRONT,
      isCardRedirectFront: false,
      largeurEcran: 0, // calcule la taille de l'ecran
      showNotificationDiv: false,
      showPopup: false,
      selectedNotification: {},
      isTherapeute: false,
    };
  },
  components: {
    avatar: Avatar,
    // NotificationComponent,
    // MessagerieBottom,
    NotificationDiv,
    NotificationDetails,
    ContactIcon,
  },
  created() {
    this.$router.push("/utilisateurs");
    try {
      // console.log(this.user);
      if (this.user.therapeute) {
        this.isTherapeute = true;
      } else {
        this.isTherapeute = false;
      }
    } catch (error) {
      console.log(error);
    }

    this.getLastNews();
  },
  computed: {
    ...User.mapGetters(["user"]),
    messageHeader() {
      if (!this.appParam) {
        return;
      }
      return this.appParam.attributes.messageHeader;
    },
    unreadCount() {
      if (this.isTherapeute) {
        return this.notifications.filter(
          (notif) => !notif.attributes.isReadUserPro
        ).length;
      } else {
        return this.notifications.filter(
          (notif) => !notif.attributes.isReadUser
        ).length;
      }
    },
  },
  methods: {
    ...User.mapActions({
      actionsLogOut: "logOut",
      resetPassword: "resetPassword",
    }),
    handleClosePopup() {
      this.showPopup = false;
    },
    toggleNotificationDiv() {
      this.showNotificationDiv = !this.showNotificationDiv;
    },
    async maskAllAsRead() {
      //console.log('Mettre lu à toutes les notifications...')
      try {
        const ObjetNotif = Parse.Object.extend("notification");
        const QueryNotif = new Parse.Query(ObjetNotif);
        const ObjetUser = Parse.Object.extend("User");
        const QueryUser = new Parse.Query(ObjetUser);

        QueryUser.equalTo("objectId", this.user.objectId);

        if (this.user.therapeute) {
          QueryNotif.matchesQuery("user_pro", QueryUser);
        } else {
          QueryNotif.matchesQuery("user", QueryUser);
          QueryNotif.notEqualTo("type", "Questionnaire BFP");
        }
        QueryNotif.equalTo("isRead", false); // Assuming the attribute to check if a notification is read or not is 'isRead'

        const notificationsToMarkAsRead = await QueryNotif.find();

        for (let notif of notificationsToMarkAsRead) {
          if (this.user.therapeute) {
            //console.log('Mettre lu à la notification de l\'utilisateur pro...')
            notif.set("isReadUserPro", true); // Assuming the attribute to set a notification as read is 'isRead'
          } else {
            //console.log('Mettre lu à la notification de l\'utilisateur...')
            notif.set("isReadUser", true); // Assuming the attribute to set a notification as read is 'isRead'
          }
        }
        await Parse.Object.saveAll(notificationsToMarkAsRead); // Save all changes in one batch

        // Optionally, refresh your local notifications list
        this.getLastNews();
      } catch (error) {
        console.error(
          `Error in marking all notifications as read: ${error.message}`
        );
      }
    },
    handleNotifDetails(notif) {
      console.log("handleNotifDetails", notif);
      this.selectedNotification = notif;
      this.showPopup = true;
    },
    handlemarkAllAsRead() {
      this.maskAllAsRead();
    },
    HandleUpdateLastNews() {
      this.getLastNews();
    },
    async getLastNews() {
      ParseConfig();
      try {
        const ObjetNotif = Parse.Object.extend("notification");
        const QueryNotif = new Parse.Query(ObjetNotif);
        const ObjetUser = Parse.Object.extend("User");
        const QueryUser = new Parse.Query(ObjetUser);
        QueryUser.equalTo("objectId", this.user.objectId);
        if (this.user.therapeute) {
          QueryNotif.matchesQuery("user_pro", QueryUser);
        } else {
          QueryNotif.matchesQuery("user", QueryUser);
        }
        QueryNotif.descending("updatedAt");
        // QueryNotif.limit(3);
        QueryNotif.include("user");
        QueryNotif.include("user_pro");
        //inclure meeting_events
        QueryNotif.include("meeting_event");
        const actualites = await QueryNotif.find();
        this.notifications = actualites;
      } catch (error) {
        console.error(
          `Erreur lors de la récupération des actualités : ${error.message}`
        );
      }
    },
    async resetStorage() {
      localStorage.removeItem("idPatientPerso");
      localStorage.removeItem("idUserForSave");
      // Réinitialiser les cookies
      document.cookie =
        "idUserForSave=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "idPatientPerso=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      //vérifier que le token est le bon
      var token = localStorage.getItem("token");
      var result = await verificationToken(token);
      if (result == false) {
        //si le token est mauvais, on le supprime
        localStorage.removeItem("token");
        //on redirige vers la page de connexion et on déconnecte l'utilisateur
        await this.actionsLogOut();
        router.push("/connexion");
      }
    },

    hide() {
      this.isDisplay = false;
      localStorage.setItem("isDisplay", this.isDisplay);
    },
    deconnexion() {
      this.actionsLogOut();
      router.go("/connexion");
    },
    majLargeurEcran() {
      this.largeurEcran = document.documentElement.clientWidth;
    },
    goToMonProfil() {
      router.push("/mon-profil");
    },
    handleAccepted(response) {
      console.log("handleAccepted", response);
      this.getLastNews();
    },
    handleDeclined(response) {
      console.log("handleDeclined", response);
      this.getLastNews();
    },
    async goToActivites() {
      await this.actionsLogOut();
      router.go("/connexion");
    },
  },
  async mounted() {
    window.addEventListener("resize", this.majLargeurEcran);
    this.majLargeurEcran();
    try {
      ParseConfig();
      await Parse.User.current();

      var queryAppParam = new Parse.Query("app_param");
      this.appParam = await queryAppParam.first();

      var dt = new Date();
      if (
        Parse.User.current().attributes.group.attributes.subscription_end_date
      ) {
        var tempsRestant =
          Parse.User.current().attributes.group.attributes.subscription_end_date.getTime() -
          dt.getTime();

        var tempsRestants = Math.trunc(tempsRestant / 86400000);
        if (tempsRestants < 0) {
          this.daysLeft = "Acces limité";
        } else if (tempsRestants > 0 && 31 > tempsRestants) {
          this.daysLeft = tempsRestants + " jours restants";
        }
      } else {
        this.daysLeft = null;
      }
    } catch (error) {
      await this.actionsLogOut();
      router.go("/connexion");
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.majLargeurEcran);
  },
};
</script>

<style>
.navbar-menus {
  padding: 0px;
}

.a-droite {
  display: flex;
  justify-content: flex-end;
  width: 97%;
  position: absolute;
  right: 30px;
  height: 20px;
  top: 3px;
}

.pop-up_ephemere {
  color: white;
  font-size: 14px;
  text-align: center;
  background-color: darksalmon;
  position: relative;
  top: 66px;
  height: 25px;
}
@media screen and (max-width: 768px) {
  .navbarResponsiveAni {
    z-index: 999;
    position: absolute;
  }
}
.custom-notification-header {
  color: black;
}
.navbar-end {
  position: relative;
}
.notification-wrapper {
  position: relative;
  display: inline-block; /* Makes the wrapper adjust to the content size */
  cursor: pointer; /* Indicates clickable area */
}

.notification-icon {
  font-size: 40px;
  transition: transform 0.3s; /* Smooth transition effect */
}

.notification-wrapper:hover .notification-icon {
  transform: scale(1.1); /* Slight scaling on hover */
}

.notifPlacement {
  margin-right: 20px;
  margin-top: 15px;
}
.notification-count {
  position: absolute;
  top: -5px; /* Adjust positioning to prevent overlap */
  right: -5px;
  background-color: #d9534f;
  color: white;
  border-radius: 50%;
  min-width: 20px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); /* Adds a subtle shadow for depth */
}
</style>
