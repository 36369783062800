<template>
    <div class="popup">
      <div class="popup-content">
        <div class="close-button" @click="closePopup">&#10006;</div>
        <div class="popup-title">QR Code</div>
        <div id="qrcode" class="qrcode-container"></div>
      </div>
    </div>
  </template>
  
  <script>
import QRCode from 'qrcode';

export default {
  data() {
    return {
      baseUrl: 'https://www.symbiocenter.com/#/connexion/', // Remplace ce lien par le lien que tu veux encoder
      qrCodeSize: 400 // Taille en pixels du QR code (ajuste selon tes besoins)
    };
  },
  props: {
    tokenGenerate: {
      type: String,
      required: true,
    }
  },
  computed:
  {
    url()
    {
      return `${this.baseUrl}?token=${encodeURIComponent(this.tokenGenerate)}`;
    }
  },
  methods: {
    closePopup() {
      this.$emit('closeModal');
    },
    async generateQRCode() {
      try {
        // Création d'un élément canvas
        const canvas = document.createElement('canvas');
        canvas.width = this.qrCodeSize; // Définir la largeur du canvas
        canvas.height = this.qrCodeSize; // Définir la hauteur du canvas

        // Ajout du canvas à la div
        const qrcodeContainer = document.getElementById('qrcode');
        qrcodeContainer.innerHTML = ''; // Nettoyer le conteneur
        qrcodeContainer.appendChild(canvas);

        // Génération du QR code
        console.log('URL du qr code:', this.tokenGenerate);
        await QRCode.toCanvas(canvas, this.tokenGenerate, {
          width: this.qrCodeSize, // Largeur du QR code
          margin: 1 // Marge autour du QR code (ajuste selon tes besoins)
        });
        console.log('QR Code généré !');
      } catch (error) {
        console.error('Erreur lors de la génération du QR code :', error);
      }
    }
  },
  mounted() {
    this.generateQRCode(); // Génère le QR code au chargement du composant
  }
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Effet de flou sur l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 10 30px rgba(0, 0, 0, 0.2);
  width: 800px; /* Largeur de base fixe */
  height: 600px; /* Hauteur de base fixe */
  max-width: 70%; /* Flexibilité maximale */
  max-height: 70%; /* Flexibilité maximale pour la hauteur */
  text-align: center;
  transition: all 0.3s ease;
  position: relative;
}

.qrcode-container {
  margin: 20px auto;
}

button {
  background: #489181;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

button:hover {
  background: #36715f; /* Couleur plus foncée au survol */
}
</style>

  