<template>
    <div class="visio-mail-container">
      <div class="navigation">
        <i class="fas fa-arrow-left back-arrow" @click="goBack"></i>
        <div class="close-button" @click="closePopup">&#10006;</div>
      </div>
      <div class="popup-container">
        <h2 class="titleVisioMail">Demande de rendez-vous</h2>
        <p class="infoText">Il semble que votre thérapeute n'ait pas encore indiqué ses disponibilités.</p>
        <p class="infoText">En envoyant un e-mail, vous pouvez l'informer de votre souhait de prendre rendez-vous. Cela l'encouragera à mettre à jour ses disponibilités.</p>
        <br/><p class="questionText">Voulez-vous envoyer cet e-mail maintenant ?</p>
        <div class="controls-mail-visio">
          <button @click="sendEmail">Envoyer l'e-mail</button>
          <button @click="closePopup">Fermer</button>
        </div>
      </div>
    </div>
  </template>
  

<script>
import { getTherapeuteFromUser } from "@/services/utilisateurs/getTherapeuteFromUser.js";
import Parse from "parse";
import Swal from "sweetalert2/dist/sweetalert2.js";

export default {
  methods: {
    goBack() {
      this.$emit("goBack");
    },
    closePopup() {
      this.$emit("close");
    },
    sendEmail() {
        const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 6000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      console.log("Envoi d'un e-mail");
      console.log("Nom de l'utilisateur",this.TherapeuteInfo);
      try{
      Parse.Cloud.run("notificationDemandeVisio", {
        mail: this.TherapeuteInfo.mail,
        name: this.TherapeuteInfo.name,
      });
        Toast.fire({
            icon: "success",
            title: "Votre e-mail a bien été envoyé.",
        });
        }
        catch (error) {
            console.error('Erreur lors de l\'envoi de l\'e-mail',error);
            Toast.fire({
                icon: "error",
                title: "Une erreur est survenue lors de l'envoi de l'e-mail.",
            });
        }
        finally {
            this.$emit("close");
        }
    },
    
  },
  async created() {
    console.log('Récupération des informations du thérapeute');
    try{
      this.TherapeuteInfo = await getTherapeuteFromUser(Parse.User.current());
      console.log(Parse.User.current());
      console.log(this.TherapeuteInfo);
    }
    catch (error) {
      console.error('Erreur lors de la récupération des informations du thérapeute',error);
    }
    finally {
      console.log('Informations du thérapeute',this.TherapeuteInfo);
    }
    console.log('Démarrage de la vérification de la disponibilité du thérapeute')
  },
};
</script>

<style> 
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez la taille au besoin */
  margin-right: 20px; /* Espacement avant le titre ou la bordure de la pop-up */
}

.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez selon vos besoins */
  color: #489181; /* Couleur de l'icône */
  margin-right: 20px; /* Ajustez l'espacement si nécessaire */
}
.close-button {
  position: absolute;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
}
.visio-mail-container {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Limite la largeur pour une meilleure lisibilité */
  margin: auto; /* Centrer dans la page */
}

.controls-mail-visio {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Espace entre les boutons */
  margin-top: 20px; /* Espacement au-dessus de la pagination */
}

.controls-mail-visio button {
  padding: 8px 16px; /* Espacement intérieur pour agrandir le bouton */
  border: none; /* Suppression de la bordure par défaut */
  background-color: #489181; /* Couleur de fond bleue */
  color: white; /* Texte blanc pour le contraste */
  border-radius: 5px; /* Bordures arrondies pour un look moderne */
  cursor: pointer; /* Change le curseur pour indiquer la cliquabilité */
  font-weight: bold; /* Rend le texte un peu plus épais */
  transition: background-color 0.3s, transform 0.2s; /* Transition pour l'effet au survol */
}

.controls-mail-visio button:disabled {
  background-color: #cccccc; /* Couleur de fond pour les boutons désactivés */
  cursor: not-allowed; /* Change le curseur pour indiquer qu'il n'est pas cliquable */
  transform: none; /* Pas de transformation pour les boutons désactivés */
}

.controls-mail-visiobutton:not(:disabled):hover {
  background-color: #397467; /* Assombrir le bouton au survol */
  transform: translateY(-2px); /* Effet de léger soulèvement au survol */
}

.controls-mail-visio button:not(:disabled):active {
  background-color: #397467; /* Couleur plus sombre lors du clic */
  transform: translateY(1px); /* Effet d'enfoncement lors du clic */
}

.titleVisioMail {
  margin-bottom: 20px; 
    text-align: center;
    color: #489181;
    font-size: 24px;
    font-weight: bold;
}
.questionText {
  color: #489181;
  font-size: 1.1rem;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}
</style>