<template>
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <iframe
      style="width: 100vw; height: 800vh"
      name="Support"
      src="https://symbiocenter.fr/centre_assistance/"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: "support",
};
</script>
