<template>
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <div id="export-pdf" style="display: none">
          <img id="imgid" src="../../../assets/img/carte-affiliation.png" />
        </div>
        <h1 class="title is-size-4">
          {{ $t("RetrocessionEtRoyaltiesPageTitre") }}
          <span class="title is-size-5 is-pulled-right">
            {{ $t("votreCodeAffiliation") }} :
            {{ group.code_affiliation }}
            <br />
            <a
              class="is-size-6"
              @click="imprimerCarteAffiliation(group.code_affiliation)"
            >
              {{ $t("imprimerMaCarteAffiliation") }}
            </a>
            <br />
            <a
              class="button is-cyan is-margin-t-20"
              @click="isCardAffiliationGratuitOpen = true"
            >
              {{ $t("invitationParAffiliation") }}
            </a>
          </span>
        </h1>
        <p class="is-size-6 is-margin-b-10">
          {{ $t("vousAvezActuellement") }}
          <a class="has-text-primary">
            <a
              @click="openHistoriqueCredit()"
              class="has-text-weight-semibold"
              v-if="group.credit && Math.round(group.credit) >= 1"
            >
              {{ Math.round(group.credit) }}
            </a>
            <a
              v-else-if="group.credit && Math.round(group.credit) === 1"
              @click="openHistoriqueCredit()"
            >
              1 {{ $t("credit") }}
            </a>
            <a
              class="has-text-weight-semibold"
              @click="openHistoriqueCredit()"
              v-else
            >
              0
            </a>
            <a @click="openHistoriqueCredit()" v-if="group.credit >= 2">
              {{ $t("credits") }}
            </a>
            <a @click="openHistoriqueCredit()" v-else>
              {{ $t("credit") }}
            </a>
          </a>
          {{ $t("deFormation") }}
        </p>
        <a
          class="button is-cyan is-margin-b-50"
          @click="modalTransformerCredit = true"
          v-if="Math.round(group.credit)"
        >
          {{ $t("transformerCreditsCodePromo") }}
        </a>
        <br v-else />
        <p class="has-text-black">
          {{ $t("texteInformationPageAffiliation") }}
        </p>
        <br />
        <br />
        <div class="steps">
          <div class="step-item">
            <div class="step-marker">1</div>
            <div class="step-details">
              <p>
                {{ $t("etapeAffiliation1") }}
              </p>
            </div>
          </div>
          <div class="step-item">
            <div class="step-marker">2</div>
            <div class="step-details">
              <p>{{ $t("etapeAffiliation2") }}</p>
            </div>
          </div>
          <div class="step-item">
            <div class="step-marker">3</div>
            <div class="step-details">
              <p>
                {{ $t("etapeAffiliation3") }}
              </p>
            </div>
          </div>
          <div class="step-item">
            <div class="step-marker">4</div>
            <div class="step-details">
              <p>
                {{ $t("etapeAffiliation4") }}
              </p>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-4">
            <div
              class="box has-text-centered box-information box-information-retrocession box-information-utilisateurs"
            >
              <div class="is-margin-b-10">
                <div>
                  <p class="has-text-weight-semibold is-size-2 box-titre">
                    {{
                      utilisateurs.filter((e) => {
                        return e.etat === "valide";
                      }).length
                    }}
                  </p>
                  <p>{{ $t("affiliationsValidees") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div
              class="box has-text-centered box-information box-information-retrocession box-information-retrocession-attente"
            >
              <div class="is-margin-b-10">
                <div>
                  <p class="has-text-weight-semibold is-size-2 box-titre">
                    {{
                      utilisateurs.filter((e) => {
                        if (
                          e.etat === "en attente" ||
                          e.etat === "essai gratuit"
                        ) {
                          return e;
                        }
                      }).length
                    }}
                  </p>
                  <p>{{ $t("nombreDemandesAffiliationsAttentes") }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-4">
            <div
              class="box has-text-centered box-information box-information-retrocession box-information-retrocession-relancer"
            >
              <div class="is-margin-b-10">
                <div>
                  <p class="has-text-weight-semibold is-size-2 box-titre">
                    {{
                      utilisateurs.filter((e) => {
                        return e.etat === "essai gratuit expiré";
                      }).length
                    }}
                  </p>
                  <p>{{ $t("affiliationsSansReponse") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p class="has-text-weight-semibold is-size-5">
          {{ $t("listeUtilisateursAffilies") }}
        </p>
        <b-table
          v-if="utilisateurs.length"
          :data="utilisateurs"
          :bordered="true"
          :narrowed="true"
          :striped="true"
          :paginated="true"
          :per-page="10"
          icon-pack="fas"
        >
          <template slot-scope="props" slot="header">
            <div>{{ props.column.label }}</div>
          </template>

          <template slot-scope="props">
            <b-table-column field="lastname" label="Nom" searchable>
              {{ props.row.lastname }}
            </b-table-column>
            <b-table-column field="firstname" label="Prénom" searchable>
              {{ props.row.firstname }}
            </b-table-column>
            <b-table-column field="email" label="mail" searchable>
              {{ props.row.email }}
            </b-table-column>
            <b-table-column field="dateInscription" label="Date d'inscription">
              <p v-if="props.row.date_affiliation">
                {{ new Date(props.row.date_affiliation).toLocaleDateString() }}
              </p>
            </b-table-column>
            <b-table-column field="dateInvitation" label="Date d'invitation">
              <p v-if="props.row.date_invitation_affiliation">
                {{
                  new Date(
                    props.row.date_invitation_affiliation
                  ).toLocaleDateString()
                }}
              </p>
            </b-table-column>
            <b-table-column field="etat" label="Etat" searchable>
              {{ props.row.etat }}
            </b-table-column>
            <b-table-column field="renew" label="Renouvellement" searchable>
              <span v-if="props.row.renouvellement">
                {{ $t("annuel") }}
              </span>
              <span v-else-if="props.row.dateRenouvellement">
                {{ $t("trimestriel") }}
              </span>
            </b-table-column>
            <b-table-column
              field="endDateSubscription"
              label="Echéance d'inscription"
            >
              <span v-if="props.row.dateRenouvellement">
                {{
                  new Date(props.row.dateRenouvellement).toLocaleDateString()
                }}
              </span>
            </b-table-column>
            <b-table-column
              label="Suivi"
              class="has-text-centered table-button"
            >
              <a @click="openSuivi(props.row)">
                <img
                  class="icon-tableau"
                  v-if="!props.row.acces_donnees_therapeute"
                  src="../../../assets/img/block.png"
                />
                <img
                  class="icon-tableau"
                  v-else
                  src="../../../assets/img/graphic-progression.png"
                />
              </a>
            </b-table-column>
            <b-table-column
              :label="$t('tableauAbonne.RetourEnSeance')"
              class="has-text-centered"
              v-if="group && group.type_abonnement !== 'gratuit'"
            >
              <a
                @click="(modalRetourCabinet = true), (userBack = props.row)"
                v-if="props.row.etat === 'essai gratuit expiré'"
              >
                <b-icon pack="fas" icon="step-backward"></b-icon>
              </a>
            </b-table-column>
            <b-table-column
              label="Relancer l'invitation"
              class="has-text-centered"
            >
              <a
                @click="relanceMail(props.row)"
                class="has-text-black"
                v-if="props.row.relance"
              >
                <b-icon pack="fas" icon="paper-plane"></b-icon>
              </a>
              <a
                @click="relanceMailAbonnement(props.row)"
                class="has-text-black"
                v-else-if="props.row.relanceAbonnement"
              >
                <b-icon pack="fas" icon="paper-plane"></b-icon>
              </a>
            </b-table-column>
          </template>
        </b-table>
        <p v-else>
          {{ $t("aucunUtilisateurAffilie") }}
        </p>
      </div>
    </div>
    <b-modal :width="1200" :active.sync="isCardAffiliationGratuitOpen">
      <modal-affiliation-gratuit
        :code_affiliation="group.code_affiliation"
        :group="group"
        :user="user"
        @back="isCardAffiliationGratuitOpen = false"
      />
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="modalRetourCabinet"
      @close="userBack = null"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              {{ $t("retourCabinet") }}
            </h3>
            <hr />
            <p>
              {{ $t("retourCabinetInformation") }}
            </p>
            <p>
              {{ $t("retourCabinetConfirmation") }}
            </p>
            <hr />
            <a class="is-pulled-right button is-cyan" @click="backToCabinet()">
              {{ $t("confirmer") }}
            </a>
            <a
              class="is-pulled-right button is-grey is-margin-r-10"
              @click="modalRetourCabinet = false"
            >
              {{ $t("annuler") }}
            </a>
            <br />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="modalTransformerCredit"
      @close="modalTransformerCredit = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              {{ $t("transformerCreditsCodePromo") }}
            </h3>
            <hr />
            <p>
              {{ $t("retourCabinetInformation2") }}
            </p>
            <hr />
            <a class="is-pulled-right button is-cyan" @click="codePromo()">
              {{ $t("confirmer") }}
            </a>
            <a
              class="is-pulled-right button is-grey is-margin-r-10"
              @click="modalRetourCabinet = false"
            >
              {{ $t("annuler") }}
            </a>
            <br />
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="isCardSuiviOpen"
      @close="isCardSuiviOpen = false"
    >
      <suivi-utilisateur-component
        :UserSelected="UserSelected"
        @closeModal="isCardSuiviOpen = false"
      />
    </b-modal>
    <b-modal
      :width="1200"
      :active.sync="modalHistoriqueCredit"
      @close="modalHistoriqueCredit = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              Historiques de mes crédits
            </h3>
            <hr />
            <b-table
              v-if="factures.length"
              :data="factures"
              :bordered="true"
              :narrowed="true"
              :striped="true"
            >
              <template slot-scope="props" slot="header">
                <div>{{ props.column.label }}</div>
              </template>

              <template slot-scope="props">
                <b-table-column label="Utilisateur">
                  {{ props.row.utilisateur }}
                </b-table-column>
                <b-table-column label="Date abonnement">
                  {{ props.row.date_abonnement }}
                </b-table-column>
                <b-table-column label="Engagement">
                  {{ props.row.engagement }}
                </b-table-column>
                <b-table-column label="Crédits">
                  {{ props.row.credit }}
                </b-table-column>
              </template>
            </b-table>
            <p v-else>Aucun historique de crédit renseigné.</p>
            <br />
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import codeGenerator from "@/services/general/codeGenerator.js";
import { sortInvArrayByParam } from "@/services/general/sortArrayByParam.js";
import ParseConfig from "@/api/parse.config.js";
import Parse from "parse";
import jsPDF from "jspdf";
import { createNamespacedHelpers } from "vuex";
import asyncForEach from "@/services/general/asyncForEach.js";
import moment from "moment";
import modalAffiliationGratuit from "./retrocession/modalAffiliationGratuit.vue";
import { getObjectById } from "@/services/parse/getObject";
import { getFilesDoneByUserId } from "@/services/folders/getFilesDoneByUserId.js";
import suiviUtilisateurComponent from "./utilisateur/suiviUtilisateur.vue";

const User = createNamespacedHelpers("user");

export default {
  name: "rétrocession",
  data() {
    return {
      utilisateurs: [],
      isCardAffiliationGratuitOpen: false,
      modalRetourCabinet: false,
      userBack: null,
      isCardSuiviOpen: false,
      UserSelected: null,
      data: [
        [
          "customer_emails",
          "amount",
          "code",
          "discount_type",
          "minimum_amount",
          "individual_use",
          "usage_limit",
          "limit_usage_to_x_items",
          "usage_limit_per_user",
        ],
      ],
      modalTransformerCredit: false,
      factures: [],
      modalHistoriqueCredit: false,
    };
  },
  components: {
    ModalAffiliationGratuit: modalAffiliationGratuit,
    SuiviUtilisateurComponent: suiviUtilisateurComponent,
  },
  computed: {
    ...User.mapGetters(["group", "user"]),
  },
  methods: {
    ...User.mapActions({
      actionUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
    }),
    async openSuivi(e) {
      //if (!e.acces_donnees_therapeute) return;
      const loadingComponent = this.$buefy.loading.open();
      this.UserSelected = Object.assign({}, e);
      this.UserSelected.filesDone = await getFilesDoneByUserId(e.id);
      this.isCardSuiviOpen = true;
      loadingComponent.close();
    },
    async calculNbCredit() {
      const objectFacture = Parse.Object.extend("facture");
      const queryFacture = new Parse.Query(objectFacture);
      const objectGroup = Parse.Object.extend("user_group");
      const queryUserGroup = new Parse.Query(objectGroup);
      queryUserGroup.equalTo("objectId", this.user.group.objectId);

      var monGroupe = await queryUserGroup.first();

      queryFacture.equalTo("group_pro", monGroupe);
      queryFacture.equalTo("valide", true);
      var listeFacture = await queryFacture.find();

      this.group.credit = listeFacture.reduce(function (a, b) {
        return a + b.attributes.credit;
      }, 0);

      const oUsageCredit = Parse.Object.extend("usage_credit");
      const queryUsageCredit = new Parse.Query(oUsageCredit);
      queryUsageCredit.equalTo("group", monGroupe);
      var listeUsageCredit = await queryUsageCredit.find();
      this.group.credit =
        this.group.credit -
        listeUsageCredit.reduce(function (a, b) {
          return a + b.attributes.credit;
        }, 0);
    },
    async backToCabinet() {
      const loadingComponent = this.$buefy.loading.open();
      try {
        const user = await getObjectById("User", this.userBack.id);
        const currentUser = await getObjectById("User", this.user.objectId);
        const group = await getObjectById(
          "user_group",
          this.user.group.objectId
        );

        user.set("type_pratique", "cabinet");
        user.set("cgu", true);
        user.set("group", group);
        user.set("affiliation", null);
        user.set("date_invitation_affiliation", null);
        user.set("date_affiliation", null);
        user.set("password", this.group.password_cabinet);

        const RelationLinkFromCurrentUser = currentUser.relation("link");
        let QueryLinkFromRelationCurrentUser =
          RelationLinkFromCurrentUser.query();
        QueryLinkFromRelationCurrentUser.limit(1000);
        const ResponseLinkFromRelationCurrentUser =
          await QueryLinkFromRelationCurrentUser.find();

        const RelationLinkFromUser = user.relation("link");
        let QueryLinkFromRelation = RelationLinkFromCurrentUser.query();
        QueryLinkFromRelation.limit(1000);
        const ResponseLinkFromRelation = await QueryLinkFromRelation.find();

        ResponseLinkFromRelation.forEach((link) => {
          RelationLinkFromUser.remove(link);
        });

        ResponseLinkFromRelationCurrentUser.forEach((e) => {
          RelationLinkFromUser.add(e);
        });

        Parse.masterKey = "myMasterKey";
        await user.save({}, { useMasterKey: true });
        await this.getUserAffiliateByGroupId();
        this.modalRetourCabinet = false;
        this.userBack = null;
        var userCourant = await Parse.User.current();
        Parse.Cloud.run("retourAffiliation", {
          destinataire: user.getUsername(),
          utilisateur:
            user.attributes.firstname + " " + user.attributes.lastname,
          therapeute:
            userCourant.attributes.firstname +
            " " +
            userCourant.attributes.lastname,
          emailPro: userCourant.attributes.username,
        });

        loadingComponent.close();
      } catch (e) {
        //console.log(e);
        loadingComponent.close();
      }
    },
    imprimerCarteAffiliation(code_affiliation) {
      this.toDataURL(document.getElementById("imgid").src, function (dataUrl) {
        var doc = new jsPDF("l");
        var img = new Image();
        img.src = dataUrl;
        doc.addImage(img, "JPEG", 0, 0, 300, 210, undefined, "FAST");
        doc.setFontSize(9);
        doc.setFontSize(20);
        doc.setTextColor(255, 255, 255);

        doc.setFontSize(25);
        doc.setFontType("bold");
        doc.text(131, 102, code_affiliation);
        doc.save("carte-affiliation.pdf");
      });
    },
    toDataURL(url, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async getUserAffiliateByGroupId() {
      this.utilisateurs = [];
      //ParseConfig();
      const QueryUser = new Parse.Query(Parse.User);
      var userCurrent = await Parse.User.current(); //this.user
      if (userCurrent == null) {
        var ObjectUser = Parse.Object.extend("User");
        var QueryUsers = new Parse.Query(ObjectUser);
        QueryUsers.equalTo("email", this.user.email);
        QueryUsers.first();
        userCurrent = await QueryUsers.first();

        // this.$buefy.toast.open({
        //   duration: 3000,
        //   message: "Une erreur est survenue, nous allons vous deconnecter.",
        //   type: "is-danger"
        // });

        // await this.actionsLogOut();
        // router.go("/connexion");
      }
      QueryUser.equalTo("affiliation", userCurrent);
      QueryUser.include("group");
      QueryUser.limit(100000);
      const ResponseUser = await QueryUser.find();
      const ArrayUtilisateurs = [];
      await asyncForEach(ResponseUser, async (utilisateur) => {
        const newUtilisateur = {
          firstname: utilisateur.attributes.firstname,
          lastname: utilisateur.attributes.lastname,
          prenom: utilisateur.attributes.firstname,
          nom: utilisateur.attributes.lastname,
          id: utilisateur.id,
          date_affiliation: utilisateur.attributes.date_affiliation,
          date_invitation_affiliation:
            utilisateur.attributes.date_invitation_affiliation,
          email: utilisateur.attributes.username,
          mail: utilisateur.attributes.username,
          typePratique: utilisateur.attributes.type_pratique,
          acces_donnees_therapeute:
            utilisateur.attributes.acces_donnees_therapeute,
        };
        try {
          if (utilisateur.attributes.group) {
            const userGroup = utilisateur.attributes.group;
            if (
              newUtilisateur.date_invitation_affiliation &&
              !newUtilisateur.date_affiliation
            ) {
              newUtilisateur.etat = "en attente";
              newUtilisateur.relance = true;
            } else if (newUtilisateur.date_affiliation) {
              if (
                (!userGroup.attributes.type_abonnement_perso ||
                  userGroup.attributes.type_abonnement_perso === "gratuit") &&
                moment(new Date()).toDate() <=
                  moment(newUtilisateur.date_affiliation)
                    .add(10, "days")
                    .toDate()
              ) {
                newUtilisateur.etat = "essai gratuit";
              } else if (
                userGroup.attributes.type_abonnement_perso === "cplay"
              ) {
                newUtilisateur.renouvellement =
                  userGroup.attributes.engagement_annuel_perso;
                newUtilisateur.etat = "valide";
                if (userGroup.attributes.resilie_perso)
                  newUtilisateur.etat = "resilié";
                let duration = null;
                let dateRenouvellement = null;
                if (userGroup.attributes.engagement_annuel_perso)
                  duration = "years";
                else duration = "months";
                dateRenouvellement = new Date(
                  moment(userGroup.attributes.date_paiement_perso).add(
                    1,
                    duration
                  )
                );

                newUtilisateur.dateRenouvellement = dateRenouvellement;
              } else {
                newUtilisateur.etat = "essai gratuit expiré";
                newUtilisateur.relanceAbonnement = true;
              }
            }
          }
          ArrayUtilisateurs.push(newUtilisateur);
        } catch (error) {
          return;
        }
      });
      this.utilisateurs = sortInvArrayByParam(
        ArrayUtilisateurs,
        "date_affiliation"
      );
    },
    async codePromo() {
      ParseConfig();
      let csvContent = this.data.map((e) => e.join(",")).join("\n");
      var base64 = btoa(csvContent);
      Parse.Cloud.run("exportCodePromo", {
        attachment: base64,
        utilisateur: this.user.username,
      });
      Parse.Cloud.run("exportCodePromoUser", {
        attachment: base64,
        utilisateur: this.user.username,
      });
      const user_group = await getObjectById("user_group", this.group.objectId);

      const oUsageCredit = Parse.Object.extend("usage_credit");
      const usageCredit = new oUsageCredit();
      usageCredit.set("group", user_group);
      usageCredit.set("credit", this.group.credit);
      await usageCredit.save();

      user_group.set("credit", 0);
      await user_group.save();
      this.group.credit = 0;
      this.actionUpdateUserGroup();
      this.modalTransformerCredit = false;
      this.$toast.open({
        duration: 3000,
        message: "Vos crédits ont été transformé en code promo.",
        type: "is-success",
      });
    },
    async relanceMail(utilisateur) {
      if (utilisateur) {
        await Parse.Cloud.run("propositionAffiliationGratuit", {
          destinataire: utilisateur.email,
          utilisateur: utilisateur.firstname + " " + utilisateur.lastname,
          codeAffiliation: this.group.code_affiliation,
          therapeute:
            Parse.User.current().attributes.firstname +
            " " +
            Parse.User.current().attributes.lastname,
        });
        this.$toast.open({
          duration: 3000,
          message:
            "Vous venez de relancer l'invitation d'affiliation par mail avec succès.",
          type: "is-success",
        });
      }
    },
    async relanceMailAbonnement(utilisateur) {
      if (utilisateur) {
        await Parse.Cloud.run("propositionAbonnementUtilisateurAffilie", {
          destinataire: utilisateur.email,
          utilisateur: utilisateur.firstname + " " + utilisateur.lastname,
          therapeute:
            Parse.User.current().attributes.firstname +
            " " +
            Parse.User.current().attributes.lastname,
          emailTherapeute: Parse.User.current().attributes.username,
          codeAffiliation: this.group.code_affiliation,
        });
        this.$toast.open({
          duration: 3000,
          message:
            "Vous venez d'inviter l'utilisateur affilié à souscrire à un abonnement.",
          type: "is-success",
        });
      }
    },
    async openHistoriqueCredit() {
      ParseConfig();
      this.factures = [];
      this.modalHistoriqueCredit = false;
      try {
        var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
        QueryGroup.equalTo("objectId", this.group.objectId);
        var ResponseGroup = await QueryGroup.first();
        var QueryFacture = new Parse.Query(Parse.Object.extend("facture"));
        QueryFacture.equalTo("group_pro", ResponseGroup);
        QueryFacture.equalTo("valide", true);
        var ResponseFacture = await QueryFacture.find();
        await asyncForEach(ResponseFacture, async (e) => {
          if (e.attributes.valide) {
            var QueryUser = new Parse.Query(Parse.User);
            QueryUser.equalTo("objectId", e.attributes.user.id);
            var ResponseUser = await QueryUser.first();
            var facture = null;
            if (ResponseUser) {
              facture = {
                utilisateur:
                  ResponseUser.attributes.lastname &&
                  ResponseUser.attributes.firstname
                    ? ResponseUser.attributes.firstname +
                      " " +
                      ResponseUser.attributes.lastname
                    : ResponseUser.attributes.username,
                date_abonnement: moment(e.createdAt).format("DD/MM/YYYY"),
                engagement: e.attributes.avec_engagement
                  ? "Annuel"
                  : "Trimestriel",
                credit: e.attributes.credit,
                date: e.createdAt,
              };
              this.factures.push(facture);
            } else {
              facture = {
                utilisateur: "Inconnu",
                date_abonnement: moment(e.createdAt).format("DD/MM/YYYY"),
                engagement: e.attributes.avec_engagement
                  ? "Annuel"
                  : "Trimestriel",
                credit: e.attributes.credit,
                date: e.createdAt,
              };
              this.factures.push(facture);
            }
          }
        });
        this.factures = sortInvArrayByParam(this.factures, "date");
        this.modalHistoriqueCredit = true;
      } catch (error) {
        return;
      }
    },
  },
  async mounted() {
    await this.actionUpdateUserGroup();
    await this.getUserAffiliateByGroupId();
    await this.calculNbCredit();
    this.data.push([
      this.user.username,
      Math.round(this.group.credit),
      codeGenerator("xxxxxxxx"),
      "fixed_cart",
      Math.round(this.group.credit),
      1,
      1,
      1,
      1,
    ]);
  },
  watch: {
    async isCardAffiliationGratuitOpen() {
      if (!this.isCardAffiliationGratuitOpen) {
        await this.getUserAffiliateByGroupId();
      }
    },
  },
};
</script>
