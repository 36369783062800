<template>
  <div class="is-main-content">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <h1 class="title is-size-4">
          {{ $t("gestionSeanceGroupe") }}
        </h1>
        <div class="columns">
          <div
            class="column is-12-mobile is-8-tablet is-9-desktop is-9-widescreen"
          >
            <p class="has-text-justified is-size-6">
              {{ $t("seanceGroupeInformation1") }}
            </p>
            <br />
            <p class="has-text-justified is-size-6">
              {{ $t("seanceGroupeInformation2") }}
            </p>
            <br />
            <p class="has-text-justified is-size-6">
              {{ $t("seanceGroupeInformation3") }}
            </p>
            <br />
            <a
              class="button is-cyan"
              @click="editionSessionGroup()"
              v-if="!addSession && !isRecap"
            >
              {{ $t("ajouterSeanceGroupe") }}
            </a>
            <a
              v-else-if="!isRecap"
              class="is-grey button"
              @click="addSession = false"
            >
              {{ $t("retourListeSeancesGroupes") }}
            </a>
          </div>
          <div
            class="column is-12-mobile is-4-tablet is-3-desktop is-3-widescreen"
          >
            <div
              class="box has-text-centered box-information box-information-utilisateurs"
            >
              <p
                v-if="
                  group.nb_users_licence_not_expire ||
                  group.nb_users_licence_not_expire === 0
                "
                class="has-text-weight-semibold is-size-2 box-titre"
              >
                {{ group.nb_users_licence_not_expire }} /
                <span v-if="group.nb_users">{{ group.nb_users }}</span>
                <span v-else>Illimité</span>
              </p>
              <p>{{ $t("accesSousLicenceActives") }}</p>
            </div>
          </div>
        </div>
        <div v-if="addSession || isRecap">
          <creation-seance
            :group="group"
            :sessionGroup="sessionGroup"
            :isRecap="isRecap"
            @back="reload()"
          />
        </div>
        <div v-else>
          <p class="is-size-5">{{ $t("listeSeanceEnCours") }} :</p>
          <liste-seance-groupe
            v-if="sessionsGroupInProgress.length"
            :seancesGroupe="sessionsGroupInProgress"
            :group="group"
            @editionSessionGroup="editionSessionGroup"
            @reload="reload"
          />
          <p v-else class="is-size-6">{{ $t("aucuneSeanceEnCours") }}</p>
          <br />
          <p class="is-size-5">{{ $t("listeSeanceAVenir") }} :</p>
          <liste-seance-groupe
            v-if="sessionsGroupForthcoming.length"
            :seancesGroupe="sessionsGroupForthcoming"
            :group="group"
            @editionSessionGroup="editionSessionGroup"
            @reload="reload"
          />
          <p v-else class="is-size-6">{{ $t("aucuneSeanceAVenir") }}</p>
          <br />
          <p class="is-size-5">{{ $t("listeSeanceTerminee") }} :</p>
          <liste-seance-groupe
            v-if="sessionsGroupEnd.length"
            :seancesGroupe="sessionsGroupEnd"
            :group="group"
            @editionSessionGroup="editionSessionGroup"
            @reload="reload"
          />
          <p v-else class="is-size-6">{{ $t("aucuneSeanceTerminee") }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import ListeSeanceGroupe from "./seanceGroupe/liste.vue";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { getObjectById } from "@/services/parse/getObject";
import asyncForEach from "@/services/general/asyncForEach.js";
import CreationSeance from "./seanceGroupe/creationSeance.vue";
import { getUsersByGroupId } from "@/services/utilisateurs/getUsersByGroupId.js";

const User = createNamespacedHelpers("user");

export default {
  name: "séance-groupe",
  components: {
    listeSeanceGroupe: ListeSeanceGroupe,
    creationSeance: CreationSeance,
  },
  data() {
    return {
      sessionsGroup: [],
      sessionsGroupInProgress: [],
      sessionsGroupEnd: [],
      sessionsGroupForthcoming: [],
      addSession: false,
      usersInMyGroup: [],
      sessionGroup: {},
      isRecap: false,
    };
  },
  computed: {
    ...User.mapGetters(["group"]),
  },
  methods: {
    async getAllSessionGroup() {
      ParseConfig();
      const loadingComponent = this.$buefy.loading.open();
      try {
        const ResponseUserGroup = await getObjectById(
          "user_group",
          this.group.objectId
        );
        const ObjectSessionGroup = Parse.Object.extend("session_group");
        const QuerySessionGroup = new Parse.Query(ObjectSessionGroup);
        QuerySessionGroup.equalTo("user_group", ResponseUserGroup);
        this.sessionsGroup = await QuerySessionGroup.find();

        const QueryUser = new Parse.Query(Parse.User);
        QueryUser.limit(100000);
        await QueryUser.find();
        loadingComponent.close();
      } catch (e) {
        //console.log(e);
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de récupération des séances de groupe.",
          type: "is-danger",
        });
      }
    },
    async dissociateSessionGroup() {
      this.sessionsGroupEnd = [];
      this.sessionsGroupInProgress = [];
      this.sessionsGroupForthcoming = [];
      await asyncForEach(this.sessionsGroup, async (session) => {
        session.users = [];
        await session
          .relation("users")
          .query()
          .each((user) => {
            session.users.push(user);
          });
        if (session.attributes.date_end <= new Date())
          this.sessionsGroupEnd.push(session);
        if (session.attributes.date_start >= new Date())
          this.sessionsGroupForthcoming.push(session);
        if (
          session.attributes.date_end >= new Date() &&
          session.attributes.date_start <= new Date()
        )
          this.sessionsGroupInProgress.push(session);
      });

      this.sessionsGroupForthcoming = this.sessionsGroupForthcoming.sort(
        function (a, b) {
          return (
            new Date(a.attributes.date_start) -
            new Date(b.attributes.date_start)
          );
        }
      );
      this.sessionsGroupInProgress = this.sessionsGroupInProgress.sort(
        function (a, b) {
          return (
            new Date(a.attributes.date_start) -
            new Date(b.attributes.date_start)
          );
        }
      );
      this.sessionsGroupEnd = this.sessionsGroupEnd.sort(function (a, b) {
        return (
          new Date(b.attributes.date_start) - new Date(a.attributes.date_start)
        );
      });
    },
    async getMyUsers() {
      this.usersInMyGroup = [];
      const props = await getUsersByGroupId(this.group);
      this.group.nb_users_licence = props.nb_users_licence;
      this.group.nb_users_licence_not_expire =
        props.nb_users_licence_not_expire;
      this.usersInMyGroup = props.usersInMyGroup;
    },
    async reload() {
      this.addSession = false;
      this.isRecap = false;
      await this.getMyUsers();
      await this.getAllSessionGroup();
    },
    async editionSessionGroup(payload) {
      this.sessionGroup = {};
      const utilisateurs = this.usersInMyGroup;
      if (payload && payload.sessionGroupSelected) {
        this.sessionGroup = payload.sessionGroupSelected;
        this.sessionGroup.id = payload.sessionGroupSelected.id;
        this.sessionGroup.name = payload.sessionGroupSelected.attributes.name;
        this.sessionGroup.description =
          payload.sessionGroupSelected.attributes.description;
        this.sessionGroup.dateStart =
          payload.sessionGroupSelected.attributes.date_start;
        this.sessionGroup.dateEnd =
          payload.sessionGroupSelected.attributes.date_end;
        await utilisateurs.forEach((utilisateur) => {
          if (
            payload.sessionGroupSelected.users.find((e) => {
              return utilisateur.objectId === e.id;
            })
          ) {
            utilisateur.selected = true;
          }
        });
      }
      this.sessionGroup.utilisateurs = utilisateurs;
      if (!payload || !payload.isRecap) this.addSession = true;
      else this.isRecap = true;
    },
  },
  watch: {
    sessionsGroup() {
      this.dissociateSessionGroup();
    },
  },
  async mounted() {
    await this.getMyUsers();
    await this.getAllSessionGroup();
  },
};
</script>
