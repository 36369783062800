import Parse from 'parse';

export async function getConversation(idTherapeute,User) {
    const therapeuteQuery = new Parse.Query('User');
    if(Parse.User.current().attributes.therapeute){
    therapeuteQuery.equalTo('objectId', Parse.User.current().id);
    }
    else
    {
    therapeuteQuery.equalTo('objectId', idTherapeute);
    }
    const ConversationQuery = new Parse.Query('conversation');
    ConversationQuery.matchesQuery('user_pro', therapeuteQuery);
    ConversationQuery.equalTo('user_patient', User);
    const conversation = await ConversationQuery.first();
    //si il n'y a pas de conversation on en crée une
    if(!conversation){
        const conversation = new Parse.Object('conversation');
        let userPro; // Déclaration de la variable pour stocker l'objet utilisateur professionnel
        if(Parse.User.current().attributes.therapeute){
            userPro = Parse.User.current(); // Utilisateur courant est le thérapeute
        }
        else {
            // Exécutez la requête pour obtenir l'objet utilisateur thérapeute
            const userProObject = await therapeuteQuery.first();
            userPro = userProObject; // Utilisez l'objet utilisateur obtenu de la requête
        }
    
        conversation.set('user_patient', User);
        conversation.set('user_pro', userPro); // Utilisez l'objet utilisateur pour définir user_pro
    
        await conversation.save();
    }
    
    const ConversationInfo = {
        id: conversation.id,
    };

    return ConversationInfo;

}