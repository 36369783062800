<template>
  <div>
    <a
      v-if="forPremiumSub && !isPremiumSubUser"
      @click="goToAbonnements()"
      class="modalSub button is-primary"
      >Abonnez-vous</a
    >
    <div
      class="smb-card"
      :class="{
        disable: !infos.isActive,
        needPremiumSub: forPremiumSub && !isPremiumSubUser,
      }"
    >
      <img :src="whichHeading.img" alt="Image" />
      <div class="content">
        <h4 class="title is-margin-t-10">{{ $t(whichHeading.name) }}</h4>
        <ul class="bullets">
          <li v-for="bullet in whichHeading.bullet" :key="bullet.id">
            {{ $t(bullet) }}
          </li>
        </ul>
      </div>

      <a
        class="button-content button is-primary is-bottom"
        @click="onToggleActif()"
        v-if="!(forPremiumSub && !isPremiumSubUser)"
      >
        {{ infos.isActive ? "Désactiver" : "Activer" }}
      </a>
    </div>
  </div>
</template>

<script>
import getAccesWithAbonnement from "@/services/abonnement/getAccesWithAbonnement.js";

export default {
  name: "Card",
  props: {
    infos: {
      type: Object,
      required: true,
    },
    isPremiumSubUser: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tab: {
        // utilisateurs: {
        //   name: `texteTitreUtilisateur`,
        //   bullet: [
        //     `pageUtilisateurInformation1`,
        //     `pageUtilisateurInformation2`,
        //     `pageUtilisateurInformation3`,
        //     `pageUtilisateurInformation4`,
        //     `pageUtilisateurInformation5`,
        //   ],
        //   img:
        //     "https://www.symbiocenter.com/assets/img/image-symbiofi-utilisateur.png",
        // },
        actualites: {
          name: `gestionDesActualites`,
          bullet: [`pageActualiteInformation1`, `pageActualiteInformation2`],
          img: "https://www.symbiocenter.com/assets/img/image-symbiofi-front.png",
        },
        // abonnement: {
        //   name: `gestionDesAbonnements`,
        //   bullet: [
        //     `pageAbonnementInformation1`,
        //     `pageAbonnementInformation2`,
        //     `pageAbonnementInformation3`,
        //   ],
        //   img:
        //     "https://www.symbiocenter.com/assets/img/img_accueil_abonnement.png",
        // },
        sessionGroupe: {
          name: `gestionDesSessions`,
          bullet: [`pageSessionInformation1`, `pageSessionInformation2`],
          img: "https://www.symbiocenter.com/assets/img/image-symbiofi-front.png",
        },
        tableauDeBord: {
          name: `texteTitreTableauDeBord`,
          bullet: [
            `pageTDBInformation1`,
            `ppageTDBInformation2`,
            `pageTDBInformation3`,
          ],
          img: "https://www.symbiocenter.com/assets/img/image-accueil-tbb.png",
        },
        RetrocessionEtRoyaltiesTitre: {
          name: `texteTitreRetrocession`,
          bullet: [
            `pageRetrocessionInformation1`,
            `pageRetrocessionInformation2`,
            `pageRetrocessionInformation3`,
          ],
          img: "https://www.symbiocenter.com/assets/img/image-symbiofi-retrocession.png",
        },
      },
    };
  },
  computed: {
    whichHeading() {
      return this.tab[this.infos.heading.name];
    },
    forPremiumSub() {
      if (
        getAccesWithAbonnement(this.infos.heading.type_abonnement) >
        getAccesWithAbonnement("gratuit")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    goToAbonnements() {
      this.$router.push("abonnement");
    },
    onToggleActif() {
      this.$emit("update-cart", {
        infos: this.infos,
        newActiveHeadings: !this.infos.isActive,
      });
    },
  },
};
</script>

<style scoped>
.smb-card {
  display: flex;
  flex-direction: column;
  border: 5px solid #239380;
  background-color: #b9f6fa;
  height: 100%;
  width: 100%;
  padding: 15px;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 3px rgb(10 10 10 / 10%),
    0 0 0 1px rgb(10 10 10 / 10%);
  box-shadow: 0 2px 3px rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 10%);
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* justify-content: space-between; */
}

.button-content {
  width: 100%;
}

.bullets {
  padding: 5px 0;
}

.is-bottom {
  position: relative;
  bottom: 0;
}

.disable {
  background-color: rgba(230, 230, 230, 0.5);
  border: none;
  transform: scale(0.98);
}

.needPremiumSub {
  border: 5px solid #239380;
  background-color: #b9f6fa;
  opacity: 0.3;
  margin-top: -40px;
  transform: scale(0.98);
}

.modalSub {
  position: relative;
  top: calc(50% - calc(40px / 2));
  left: calc(50% - calc(136.688px / 2));
  z-index: 100;
}
</style>
