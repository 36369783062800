<template>
  <div>
    <div v-if="sessionGroupValue">
      <p class="title is-size-5">
        {{ $t("recaptitulatifSeancedeGroupe") }}
      </p>
      <br />
      <div class="columns">
        <div
          class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen"
        >
          <p class="is-size-6">{{ $t("nomSeanceGroupe") }}</p>
          <div class="notification">
            <p class="title is-5">{{ sessionGroupValue.name }}</p>
          </div>
          <div class="columns">
            <div class="column is-4">
              <p class="is-size-6">{{ $t("dateDebut") }}</p>
              <p class="title is-4" v-if="sessionGroupValue.dateStart">
                {{ sessionGroupValue.dateStart.toLocaleDateString() }}
              </p>
            </div>
            <div class="column is-4"></div>
            <div class="column is-4">
              <p class="is-size-6">{{ $t("dateFin") }}</p>
              <p class="title is-4" v-if="sessionGroupValue.dateEnd">
                {{ sessionGroupValue.dateEnd.toLocaleDateString() }}
              </p>
            </div>
          </div>
          <p class="is-size-6">{{ $t("description") }}</p>
          <div class="notification">
            <p class="is-6">{{ descriptionFormate }}</p>
          </div>
        </div>
        <div
          class="column is-12-mobile is-6-tablet is-6-desktop is-6-widescreen"
        >
          <p>{{ $t("utilisateursAssocies") }}</p>
          <b-table
            v-if="usersAssociate && usersAssociate.length && userLoad"
            :data="usersAssociate"
            :bordered="true"
            :narrowed="true"
            :striped="true"
          >
            <template slot-scope="props" slot="header">
              <div>{{ props.column.label }}</div>
            </template>

            <template slot-scope="props">
              <b-table-column field="lastname" label="Nom">
                {{ props.row.nom }}
              </b-table-column>
              <b-table-column field="firstname" label="Prénom">
                {{ props.row.prenom }}
              </b-table-column>
              <b-table-column field="email" label="Mail">
                {{ props.row.mail }}
              </b-table-column>
            </template>
          </b-table>
          <div class="hero is-marginless is-cyan borderUserAssociate">
            <p
              class="is-6 level-right is-margin-r-10 is-margin-b-10 is-margin-t-10"
            >
              {{ $t("totalLicencesUtilisees") }} =
              {{
                this.valueCreationSessionGroup.nb_users_selectionnes -
                this.valueCreationSessionGroup
                  .nb_users_selectionnes_with_licence
              }}
            </p>
          </div>
          <div class="hero is-marginless borderUserAssociate">
            <p
              class="is-6 level-right is-margin-r-10 is-margin-b-10 is-margin-t-10"
            >
              {{ $t("licencesDisponibles") }} =
              {{ valueCreationSessionGroup.nb_licence_dispo }}
            </p>
          </div>
          <div class="hero is-marginless borderUserAssociate">
            <p
              class="is-6 level-right is-margin-r-10 is-margin-b-10 is-margin-t-10"
            >
              {{ $t("licencesSupplementaires") }} = 0
            </p>
          </div>
          <div class="hero is-cyan borderUserAssociate" v-if="!isRecap">
            <p
              class="is-6 level-right is-margin-r-10 is-margin-b-10 is-margin-t-10"
            >
              {{ $t("licencesRestantes") }} =
              {{ valueCreationSessionGroup.nb_licence_restant_apres_creation }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <br /><br /><br /><br />
    <div
      class="navbar is-fixed-bottom"
      style="
        position: float;
        padding-top: 20px;
        border-top: 5px;
        margin-left: 225px;
      "
    >
      <a
        v-if="!isRecap"
        class="button is-grey is-margin-l-20"
        @click="$emit('next', 3)"
      >
        {{ $t("precedent") }}
      </a>
      <a v-else class="button is-grey is-margin-l-20" @click="$emit('back')">
        {{ $t("annuler") }}
      </a>
      <div class="navbar-end is-margin-r-80">
        <a v-if="!isRecap" class="button is-cyan" @click="valider()">
          {{ $t("sauvegarder") }}
        </a>
        <a v-else class="button is-cyan" @click="envoiInvitation()">
          {{ $t("envoyerInvitation") }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { getObjectById } from "@/services/parse/getObject";
import asyncForEach from "@/services/general/asyncForEach.js";
import codeGenerator from "@/services/general/codeGenerator.js";
import moment from "moment";

export default {
  name: "recaptitulatif",
  props: ["sessionGroupValue", "group", "isRecap"],
  data() {
    return {
      usersAssociate: [],
      descriptionFormate: null,
      usersWithLicence: [],
      userLoad: false,
      valueCreationSessionGroup: {},
    };
  },
  methods: {
    async valider() {
      if (
        this.valueCreationSessionGroup.nb_licence_restant_apres_creation < 0
      ) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Vous n'avez pas suffisamment de licences disponibles.",
          type: "is-danger",
        });
        return;
      }
      const loadingComponent = this.$buefy.loading.open();
      try {
        ParseConfig();
        const endDate = new Date(
          this.sessionGroupValue.dateEnd.getFullYear(),
          this.sessionGroupValue.dateEnd.getMonth(),
          this.sessionGroupValue.dateEnd.getDate(),
          23,
          59,
          59
        );
        const ObjectSessionGroup = Parse.Object.extend("session_group");
        const ObjectUserGroup = Parse.Object.extend("user_group");
        let SessionGroup = null;
        let msg = "Création de la séance de groupe effectuée avec succès";
        if (!this.sessionGroupValue.id) {
          SessionGroup = new ObjectSessionGroup();
        } else {
          SessionGroup = await getObjectById(
            "session_group",
            this.sessionGroupValue.id
          );
          msg = "Modification de la séance de groupe effectuée avec succès";
        }
        const TempUserGroup = new ObjectUserGroup();
        TempUserGroup.id = this.group.objectId;
        SessionGroup.set("name", this.sessionGroupValue.name);
        SessionGroup.set("description", this.sessionGroupValue.description);
        SessionGroup.set("date_start", this.sessionGroupValue.dateStart);
        SessionGroup.set("date_end", endDate);
        SessionGroup.set("user_group", TempUserGroup);
        const RelationSessionGroupLinks = SessionGroup.relation("link");
        let QueryRelationSessionGroupLinks = RelationSessionGroupLinks.query();
        await QueryRelationSessionGroupLinks.find();
        await asyncForEach(this.sessionGroupValue.linksToAdd, async (link) => {
          //RelationSessionGroupLinks.add(link);
          const LinkQuery = new Parse.Query(Parse.Object.extend("link"));
          LinkQuery.equalTo("objectId", link.objectId);
          const ResponseLink = await LinkQuery.first();
          RelationSessionGroupLinks.add(ResponseLink);
          /*const links = await getLinksFileByLink(link);
          if (links) {
            links.forEach(e => {
              RelationSessionGroupLinks.add(e);
            });
          }*/
        });
        const RelationSessionGroupUsers = SessionGroup.relation("users");
        let QueryRelationSessionGroupUsers = RelationSessionGroupUsers.query();
        const ResponseRelationSessionGroupUsers =
          await QueryRelationSessionGroupUsers.find();
        await asyncForEach(ResponseRelationSessionGroupUsers, async (user) => {
          if (
            !this.usersAssociate.find((e) => {
              return e.objectId === user.id;
            })
          ) {
            user.set("type_pratique", "cabinet");
            user.set("cgu", true);
            user.set("date_debut_licence", null);
            user.set("fin_acces_plateforme", null);
            user.set("password", this.group.password_cabinet);
            Parse.masterKey = "myMasterKey";
            const RelationUserLinks = user.relation("link");
            let QueryRelationUserLinks = RelationUserLinks.query();
            const ResponseRelationUserLinks =
              await QueryRelationUserLinks.find();
            await asyncForEach(ResponseRelationUserLinks, async (link) => {
              RelationUserLinks.remove(link);
            });
            await user.save({}, { useMasterKey: true });
            RelationSessionGroupUsers.remove(user);
            Parse.Cloud.run("annulationSeanceGroupeUtilisateur", {
              destinataire: user.getUsername(),
              utilisateur:
                user.attributes.firstname + " " + user.attributes.lastname,
              therapeute:
                Parse.User.current().attributes.firstname +
                " " +
                Parse.User.current().attributes.lastname,
              emailPro: Parse.User.current().attributes.username,
            });
          }
        });
        await asyncForEach(this.usersAssociate, async (user) => {
          const QuerySessionGroup = new Parse.Query(ObjectSessionGroup);
          const ResponseSessionGroup = await QuerySessionGroup.find();
          const sessionGroupWithCurrentUser = [];
          await asyncForEach(ResponseSessionGroup, async (session) => {
            const RelationSessionUsers = session.relation("users");
            let QueryRelationSessionUsers = RelationSessionUsers.query();
            const ResponseRelationSessionUsers =
              await QueryRelationSessionUsers.find();
            ResponseRelationSessionUsers.find((e) => {
              return e.id === user.objectId;
            });
            const currentuser = ResponseRelationSessionUsers.find((e) => {
              return e.id === user.objectId;
            });
            if (currentuser) {
              sessionGroupWithCurrentUser.push(session);
            }
          });

          let dateInit = this.sessionGroupValue.dateStart;
          let dateFin = endDate;
          await sessionGroupWithCurrentUser.forEach((session) => {
            if (dateInit > session.attributes.date_start)
              dateInit = session.attributes.date_start;
            if (dateFin < session.attributes.date_end)
              dateFin = session.attributes.date_end;
          });
          const User = await getObjectById("User", user.objectId);
          if (
            moment(dateInit).format("DD/MM/YYYY") <=
            moment(new Date()).format("DD/MM/YYYY")
          ) {
            User.set("type_pratique", "licence");
            const password = codeGenerator("xxxxxxxx");
            User.set("password", password);
            User.set("date_debut_licence", dateInit);
            User.set("fin_acces_plateforme", dateFin);
            const RelationUserLink = User.relation("link");
            let QueryRelationUserLink = RelationUserLink.query();
            QueryRelationUserLink.limit(1000);
            const ResponseRelationUserLink = await QueryRelationUserLink.find();
            ResponseRelationUserLink.forEach((link) => {
              RelationUserLink.remove(link);
            });
            await asyncForEach(
              this.sessionGroupValue.linksToAdd,
              async (link) => {
                //RelationUserLink.add(link);
                const Link2Query = new Parse.Query(Parse.Object.extend("link"));
                Link2Query.equalTo("objectId", link.objectId);
                const Response2Link = await Link2Query.first();
                RelationUserLink.add(Response2Link);
                /*const links = await getLinksFileByLink(link);
                if (links) {
                  links.forEach(e => {
                    RelationUserLink.add(e);
                  });
                }*/
              }
            );
            if (!this.sessionGroupValue.id) {
              await Parse.Cloud.run("ouvertureSeanceGroupe", {
                destinataire: user.mail,
                utilisateur: user.prenom + " " + user.nom,
                password: password,
                therapeute:
                  Parse.User.current().attributes.firstname +
                  " " +
                  Parse.User.current().attributes.lastname,
                dateStart: moment(this.sessionGroupValue.dateStart).format(
                  "DD/MM/YYYY"
                ),
                dateEnd: moment(this.sessionGroupValue.dateEnd).format(
                  "DD/MM/YYYY"
                ),
              });
            } else {
              if (
                !ResponseRelationSessionGroupUsers.find((e) => {
                  return user.objectId === e.id;
                })
              ) {
                await Parse.Cloud.run("ouvertureSeanceGroupe", {
                  destinataire: user.mail,
                  utilisateur: user.prenom + " " + user.nom,
                  password: password,
                  therapeute:
                    Parse.User.current().attributes.firstname +
                    " " +
                    Parse.User.current().attributes.lastname,
                  dateStart: moment(this.sessionGroupValue.dateStart).format(
                    "DD/MM/YYYY"
                  ),
                  dateEnd: moment(this.sessionGroupValue.dateEnd).format(
                    "DD/MM/YYYY"
                  ),
                });
              }
            }
          } else {
            if (!this.sessionGroupValue.id) {
              await Parse.Cloud.run("pratiqueSousLicenceSeanceGroupe", {
                destinataire: user.mail,
                utilisateur: user.prenom + " " + user.nom,
                therapeute:
                  Parse.User.current().attributes.firstname +
                  " " +
                  Parse.User.current().attributes.lastname,
                dateStart: moment(this.sessionGroupValue.dateStart).format(
                  "DD/MM/YYYY"
                ),
                dateEnd: moment(this.sessionGroupValue.dateEnd).format(
                  "DD/MM/YYYY"
                ),
              });
            } else {
              if (
                !ResponseRelationSessionGroupUsers.find((e) => {
                  return user.objectId === e.id;
                })
              ) {
                await Parse.Cloud.run("pratiqueSousLicenceSeanceGroupe", {
                  destinataire: user.mail,
                  utilisateur: user.prenom + " " + user.nom,
                  therapeute:
                    Parse.User.current().attributes.firstname +
                    " " +
                    Parse.User.current().attributes.lastname,
                  dateStart: moment(this.sessionGroupValue.dateStart).format(
                    "DD/MM/YYYY"
                  ),
                  dateEnd: moment(this.sessionGroupValue.dateEnd).format(
                    "DD/MM/YYYY"
                  ),
                });
              }
            }
          }
          Parse.masterKey = "myMasterKey";
          User.save({}, { useMasterKey: true });
          RelationSessionGroupUsers.add(User);
        });
        SessionGroup.save();
        this.$buefy.toast.open({
          duration: 5000,
          message: msg,
          type: "is-success",
        });
        loadingComponent.close();
        this.$emit("back");
      } catch (e) {
        //console.log(e);
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de l'enregistrement.",
          type: "is-danger",
        });
      }
    },
    async envoiInvitation() {
      await asyncForEach(this.usersAssociate, async (user) => {
        try {
          await Parse.Cloud.run("pratiqueSousLicenceSeanceGroupeRappel", {
            destinataire: user.mail,
            utilisateur: user.prenom + " " + user.nom,
            therapeute:
              Parse.User.current().attributes.firstname +
              " " +
              Parse.User.current().attributes.lastname,
            dateStart: moment(this.sessionGroupValue.dateStart).format(
              "DD/MM/YYYY"
            ),
            dateEnd: moment(this.sessionGroupValue.dateEnd).format(
              "DD/MM/YYYY"
            ),
          });
        } catch (error){
        //console.log(error)
          this.$buefy.toast.open({
            duration: 3000,
            message: "Êchec d'envoi du mail.",
            type: "is-danger",
          });
          return;
        }
      });
      this.$buefy.toast.open({
        duration: 5000,
        message:
          "Un mail d'invitation a été envoyé aux utilisateurs de cette séance de groupe.",
        type: "is-success",
      });
      this.$emit("back");
    },
    reloadValueCreationSessionGroup() {
      this.userLoad = false;
      this.usersAssociate = [];
      if (this.sessionGroupValue.utilisateurs) {
        // eslint-disable-next-line
        this.usersWithLicence = this.sessionGroupValue.utilisateurs.filter(e => { return (e.typePratique === "licence" && ((e.date_debut_licence <= this.sessionGroupValue.dateStart && this.sessionGroupValue.dateStart <= e.fin_acces_plateforme) || (e.date_debut_licence <= this.sessionGroupValue.dateEnd && this.sessionGroupValue.dateEnd <= e.fin_acces_plateforme) || (this.sessionGroupValue.dateStart <= e.date_debut_licence && e.date_debut_licence <= this.sessionGroupValue.dateEnd) || (this.sessionGroupValue.dateStart <= e.fin_acces_plateforme && e.fin_acces_plateforme <= this.sessionGroupValue.dateEnd))); });
        this.usersAssociate = this.sessionGroupValue.utilisateurs.filter(
          (e) => {
            return e.selected === true;
          }
        );
        let usersAssociateWithLicence = [];
        usersAssociateWithLicence = this.usersWithLicence.filter((e) => {
          return e.selected === true;
        });
        this.valueCreationSessionGroup.nb_users_selectionnes =
          this.usersAssociate.length;
        this.valueCreationSessionGroup.nb_users_selectionnes_with_licence =
          usersAssociateWithLicence.length;
        this.valueCreationSessionGroup.nb_licence_achete = this.group.nb_users;
        this.valueCreationSessionGroup.nb_licence_utilise =
          this.usersWithLicence.length;
        this.valueCreationSessionGroup.nb_licence_dispo =
          this.valueCreationSessionGroup.nb_licence_achete -
          this.valueCreationSessionGroup.nb_licence_utilise;
        this.valueCreationSessionGroup.nb_licence_restant_apres_creation =
          this.valueCreationSessionGroup.nb_licence_dispo -
          this.valueCreationSessionGroup.nb_users_selectionnes +
          this.valueCreationSessionGroup.nb_users_selectionnes_with_licence;
        this.userLoad = true;
      }
    },
  },
  watch: {
    sessionGroupValue() {
      this.reloadValueCreationSessionGroup();
    },
  },
  mounted() {
    this.reloadValueCreationSessionGroup();
    if (this.sessionGroupValue.description) {
      // eslint-disable-next-line
      this.descriptionFormate = this.sessionGroupValue.description.replace(/(<([^>]+)>)/ig,"");
    }
  },
};
</script>

<style></style>
