<template>
  <div>
    <!-- <form :action="url" method="post" id="PaymentRequest">
      <p>
        <input
          type="hidden"
          name="version"
          id="version"
          :value="TPE.CMCIC_VERSION"
        />
        <input type="hidden" name="TPE" id="TPE" :value="TPE.CMCIC_TPE" />
        <input type="hidden" name="date" id="date" :value="DATA.date" />
        <input
          type="hidden"
          name="montant"
          id="montant"
          :value="DATA.amount + '' + TPE.CMCIC_CURRENCY"
        />
        <input
          type="hidden"
          name="reference"
          id="reference"
          :value="DATA.reference"
        />
        <input
          v-if="contexte_commandeBase64"
          type="hidden"
          name="contexte_commande"
          id="contexte_commande"
          :value="contexte_commandeBase64"
        />
        <input v-if="mac" type="hidden" name="MAC" id="MAC" :value="mac" />
        <input
          type="hidden"
          name="url_retour"
          id="url_retour"
          :value="TPE.CMCIC_URL_RETOUR"
        />
        <input
          type="hidden"
          name="url_retour_ok"
          id="url_retour_ok"
          :value="TPE.CMCIC_URLOK"
        />
        <input
          type="hidden"
          name="url_retour_err"
          id="url_retour_err"
          :value="TPE.CMCIC_URLKO"
        />
        <input type="hidden" name="lgue" id="lgue" :value="TPE.CMCIC_LNG" />
        <input
          type="hidden"
          name="societe"
          id="societe"
          :value="TPE.CMCIC_CODESOCIETE"
        />
        <input type="hidden" name="mail" id="mail" :value="DATA.email" />
        <input
          class="button is-primary buttonRadius"
          type="submit"
          name="bouton"
          id="bouton"
          value="Confirmer & Payer"
          @focus="$emit('confirmerAbonnement')"
        />
      </p>
    </form> -->
    <iframe
      id="paiementIframe"
      :src="url"
      style="width: 100% !important; height: 220px"
    />
  </div>
</template>

<script>
import { CONFIG_PAIEMENT } from "@/configuration/paiementConf.js";
import initialisationPaiement from "@/services/paiement/initialisationPaiement";
import Parse from "parse";
export default {
  name: "formulaire-paiement",
  props: ["transactionData2", "amount", "code_societe", "user", "page"],
  data() {
    return {
      transactionData: this.transactionData2,
      tpe: {
        CMCIC_TPE: CONFIG_PAIEMENT.CMCIC_TPE,
        CMCIC_CODESOCIETE: CONFIG_PAIEMENT.CMCIC_CODESOCIETE,
        CMCIC_CLE: CONFIG_PAIEMENT.CMCIC_CLE,
        CMCIC_VERSION: CONFIG_PAIEMENT.CMCIC_VERSION,
        CMCIC_BANK: CONFIG_PAIEMENT.CMCIC_BANK,
        CMCIC_LNG: CONFIG_PAIEMENT.CMCIC_LNG,
        CMCIC_CURRENCY: CONFIG_PAIEMENT.CMCIC_CURRENCY,
        CMCIC_SERVEUR: CONFIG_PAIEMENT.CMCIC_SERVEUR,
        CMCIC_URL: CONFIG_PAIEMENT.CMCIC_URL,
        test: CONFIG_PAIEMENT.TEST,
        CMCIC_URL_MONETICO: CONFIG_PAIEMENT.CMCIC_URL_MONETICO,
      },
      TPE: {},
      DATA: {},
      postOptions: {
        host: "",
        path: "",
        method: "POST",
      },
      mac: null,
      contexte_commande: {},
      contexte_commandeBase64: null,
      url: null,
    };
  },
  methods: {
    initPaiement() {
      this.tpe.CMCIC_CODESOCIETE = this.code_societe;
      this.transactionData.contexte_commandeBase64 =
        this.contexte_commandeBase64;
      const ResponseInitialisationPaiement = initialisationPaiement(
        this.transactionData,
        this.tpe
      );
      this.DATA = ResponseInitialisationPaiement.transactionData;
      this.mac = ResponseInitialisationPaiement.mac;
      this.TPE = ResponseInitialisationPaiement.transactionTpe;
      this.postOptions = ResponseInitialisationPaiement.postOptions;
      this.url =
        this.TPE.CMCIC_URL_MONETICO +
        "version=3.0&TPE=" +
        this.TPE.CMCIC_TPE +
        "&date=" +
        this.DATA.date +
        "&contexte_commande=" +
        this.contexte_commandeBase64 +
        "&montant=" +
        this.DATA.amount +
        this.TPE.CMCIC_CURRENCY +
        "&reference=" +
        this.DATA.reference +
        "&mail=" +
        this.DATA.email +
        "&lgue=" +
        this.TPE.CMCIC_LNG +
        "&societe=" +
        this.TPE.CMCIC_CODESOCIETE +
        "&url_retour=" +
        this.tpe.CMCIC_URLKO +
        "&url_retour_ok=" +
        this.tpe.CMCIC_URLOK +
        "&url_retour_err=" +
        this.tpe.CMCIC_URLKO +
        "&MAC=" +
        this.mac +
        "&mode_affichage=iframe";
    },
  },
  watch: {
    mac() {
      this.TPE.mac = this.mac;
    },
    amount() {
      this.initPaiement();
    },
    code_societe() {
      this.tpe.CMCIC_CODESOCIETE = this.code_societe;
      this.TPE.CMCIC_CODESOCIETE = this.code_societe;
      this.initPaiement();
    },
  },
  async mounted() {
    if (this.page === "abonnement") {
      this.tpe.CMCIC_URLOK =
        this.tpe.CMCIC_URL +
        "/validation-paiement?validationpaiementabonnement=true";
      this.tpe.CMCIC_URLKO =
        this.tpe.CMCIC_URL + "/validation-paiement?retourabonnement=true";
    } else if (this.page === "inscriptionplay") {
      this.tpe.CMCIC_URLOK =
        this.tpe.CMCIC_URL +
        "/validation-paiement?validationpaiementinscriptionplay=true";
      this.tpe.CMCIC_URLKO =
        this.tpe.CMCIC_URL + "/validation-paiement?retourinscriptionplay=true";
    } else if (this.page === "inscriptionpulse") {
      this.tpe.CMCIC_URLOK =
        this.tpe.CMCIC_URL +
        "/validation-paiement?validationpaiementinscriptionpulse=true";
      this.tpe.CMCIC_URLKO =
        this.tpe.CMCIC_URL + "/validation-paiement?retourinscriptionpulse=true";
    }
    this.contexte_commande = {};
    var userCourant = await Parse.User.current();
    const user = this.user ? this.user : userCourant;
    if (user.attributes) {
      this.contexte_commande = {
        billing: {
          addressLine1: user.attributes.adresse
            ? user.attributes.adresse
            : "Non défini",
          city: user.attributes.ville ? user.attributes.ville : "Non défini",
          postalCode: user.attributes.code_postal
            ? user.attributes.code_postal
            : "0",
          country: "FR",
        },
        shipping: {
          addressLine1: user.attributes.adresse
            ? user.attributes.adresse
            : "Non défini",
          city: user.attributes.ville ? user.attributes.ville : "Non défini",
          postalCode: user.attributes.code_postal
            ? user.attributes.code_postal
            : "0",
          country: "FR",
        },
      };
    }
    if (user.adresse) {
      this.contexte_commande = {
        billing: {
          addressLine1: user.adresse ? user.adresse : "Non défini",
          city: user.ville ? user.ville : "Non défini",
          postalCode: user.codePostal ? user.codePostal : "0",
          country: "FR",
        },
        shipping: {
          addressLine1: user.adresse ? user.adresse : "Non défini",
          city: user.ville ? user.ville : "Non défini",
          postalCode: user.codePostal ? user.codePostal : "0",
          country: "FR",
        },
      };
    }
    this.contexte_commandeBase64 = btoa(JSON.stringify(this.contexte_commande));
    this.initPaiement();
  },
};
</script>

<style lang="scss" scoped></style>
