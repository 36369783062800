<template>
    <div>
      <canvas ref="chartbpm" height="350px" width="1000px"></canvas>
    </div>
  </template>
  
  <script>
  import Chart from "chart.js";
  
  export default {
    props: ["valeurX", "tabRowFc","tabRowCC","defaultOpenedDetails"],
    name: "chart-fc",
    data() {
      return {
        chart: null,
      };
    },
    methods: {
      loadChart() {
        if (!this.tabRowFc.length || !this.valeurX.length) {
          return;
        }
  
        const mesDonnees = {
          labels: this.valeurX,
          datasets: [
            {
              label: "Tachogramme",
              data: this.tabRowFc,
              borderColor: "rgba(51, 102, 204, 1)",
              backgroundColor: "rgba(255, 255, 255, 0)",
              pointBackgroundColor: "rgba(51, 102, 204, 0)",
              pointBorderColor: "rgba(51, 102, 204, 0)",
              borderWidth: 1,
            },
            {
              label: "Cohérence cardiaque",
              data: this.tabRowCC,
              borderColor: "rgba(255, 99, 132, 1)", // Couleur de la deuxième ligne
              backgroundColor: "rgba(255, 99, 132, 0.2)", // Fond avec opacité
              pointBackgroundColor: "rgba(255, 99, 132, 0)",
              pointBorderColor: "rgba(255, 99, 132, 0)",
              borderWidth: 1,

            },
          ],
        };
  
        var chartbpm = this.$refs.chartbpm;
        var ctx = chartbpm.getContext("2d");
  
        this.chart = new Chart(ctx, {
          type: "line",
          data: mesDonnees,
          responsive: true,
          options: {
            legend: {
              display: true,
            },
            bezierCurve: false,
            animation: {
              onComplete: this.done,
            },
          },
        });
      },
      done() {
        var url = this.chart.toBase64Image();
        this.$emit("sendChartFc", url);
      },
    },
    mounted() {
      this.loadChart();
    },
    watch: {
      defaultOpenedDetails() {
        this.loadChart();
      },
    },
  };
  </script>
  