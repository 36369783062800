import { render, staticRenderFns } from "./relectureDetailsAni.vue?vue&type=template&id=2d92d5ea"
import script from "./relectureDetailsAni.vue?vue&type=script&lang=js"
export * from "./relectureDetailsAni.vue?vue&type=script&lang=js"
import style0 from "./relectureDetailsAni.vue?vue&type=style&index=0&id=2d92d5ea&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports