import { countUnseenMessages } from "@/services/chat/countUnseenMessages.js";
import Parse from "parse";

export async function getTotalUnseenMessages() {
    try {
        const userId = Parse.User.current().id;
        const unseenMessagesArray = await countUnseenMessages(userId);

        let totalUnseenMessages = 0;

        unseenMessagesArray.forEach(item => {
            totalUnseenMessages += item.numUnseenMessages;
        });

        console.log("Total des messages non lus :", totalUnseenMessages);
        return totalUnseenMessages;

    } catch (error) {
        console.error("Une erreur s'est produite lors de la récupération du total des messages non lus :", error);
        return 0; // Retourner 0 en cas d'erreur
    }
}
