var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"custom-notification"},[_c('div',{staticClass:"custom-notification-header"},[_c('span',[_vm._v("Notifications ("+_vm._s(_vm.unreadCount)+" non lues)")])]),_c('div',{staticClass:"custom-notification-content"},_vm._l((_vm.sortedNotifications),function(notif){return _c('div',{key:notif.id,staticClass:"custom-notification-item",class:{ unread: !notif.attributes.isReadUserPro && _vm.isTherapeute }},[(
            (!notif.attributes.isReadUserPro && _vm.isTherapeute) ||
            (!notif.attributes.isReadUser && !_vm.isTherapeute)
          )?_c('div',{staticClass:"new-indicator"}):(
            (notif.attributes.isReadUserPro && _vm.isTherapeute) ||
            (notif.attributes.isReadUser && !_vm.isTherapeute)
          )?_c('div',{staticClass:"read-indicator"},[_c('i',{staticClass:"fas fa-check"})]):_vm._e(),_c('div',{staticClass:"custom-icon-container"},[(notif.attributes.type === 'Visio-conférence')?_c('b-icon',{staticClass:"custom-icon fas fa-video"}):(notif.attributes.type === 'Questionnaire')?_c('b-icon',{staticClass:"custom-icon fas fa-clipboard"}):(notif.attributes.type === 'Questionnaire BFP')?_c('b-icon',{staticClass:"custom-icon fas fa-clipboard"}):_vm._e()],1),_c('div',{staticClass:"custom-notification-body"},[(notif.attributes.type === 'Visio-conférence')?_c('div',{staticClass:"custom-description"},[_vm._v(" "+_vm._s(notif.attributes.description)+" ")]):(notif.attributes.type === 'Questionnaire')?_c('div',{staticClass:"custom-description"},[_vm._v(" Score de questionnaire critique ")]):(notif.attributes.type === 'Questionnaire BFP')?_c('div',{staticClass:"custom-description"},[_vm._v(" Évaluation Initiale ")]):_vm._e()]),_c('span',{staticClass:"custom-notification-time"},[_vm._v(_vm._s(_vm.timeSince(notif.attributes.createdAt)))]),_c('button',{staticClass:"detail-button",on:{"click":function($event){return _vm.openPopup(notif)}}},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Détails ")])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }