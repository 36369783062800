<template>
  <div>
    <h2 class="table-title" ref="DivPlanAction">Plan d'action</h2>
    <table>
      <thead>
        <tr>
          <th @click="sortBy('name')">Questionnaire</th>
          <th @click="sortBy('dueDate')">Date</th>
          <th @click="sortBy('Note')">Note</th>
          <th @click="sortBy('status')">Statut</th>
          <th class="titleColonne">Détail</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="action in actions.slice().reverse()">
          <tr :key="action.id">
            <td>
              <div class="exercise-wrapper">
                <span
                  v-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-rouge'
                  "
                  class="indicator-dot"
                ></span>
                <span class="exercise-name">{{ action.name }}</span>
              </div>
            </td>

            <td>{{ action.dueDate }}</td>
            <td>
              <span v-if="action.name === 'BFP : Bilan Flash Psychologique'">{{
                action.status
              }}</span
              ><span v-else></span>
            </td>
            <td>
              <span
                :class="[
                  'status-badge',
                  getStatusClass(action.name, action.status, action.noteDetail),
                ]"
              >
                <span class="status-dot"></span>
              </span>
              <!-- <span>{{ }}</span> -->
              <span v-if="action.name === 'BFP : Bilan Flash Psychologique'">
                <span
                  v-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-vert'
                  "
                >
                  État d'équilibre</span
                >
                <span
                  v-else-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-orange'
                  "
                >
                  État de vulnérabilité</span
                >
                <span
                  v-else-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-rouge'
                  "
                >
                  État de crise</span
                >
              </span>
              <span v-else>
                <span
                  v-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-vert'
                  "
                >
                  Normal/Léger
                </span>
                <span
                  v-else-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-orange'
                  "
                >
                  Modéré</span
                >
                <span
                  v-else-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-rouge'
                  "
                >
                  Grave/Extrêmement grave</span
                >
              </span>
            </td>

            <td class="centered-button">
              <button
                class="button is-small button-custom"
                v-on:click="MoreInformations(action)"
              >
                <i class="icon fas fa-info-circle"></i>
                <p>détail</p>
              </button>
            </td>
          </tr>
          <tr
            v-if="detailsVisible === action.id"
            :key="action.id + '-details'"
            class="details-row"
          >
            <td colspan="5">
              <div
                class="StatutBFP"
                v-if="action.name === 'BFP : Bilan Flash Psychologique'"
              >
                <h3
                  v-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-vert'
                  "
                >
                  <i class="fas fa-circle status-icon-green"></i> État
                  d'équilibre
                </h3>
                <h3
                  v-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-orange'
                  "
                >
                  <i class="fas fa-circle status-icon-orange"></i> État de
                  vulnérabilité
                </h3>
                <h3
                  v-if="
                    getStatusClass(
                      action.name,
                      action.status,
                      action.noteDetail
                    ) === 'bilan-rouge'
                  "
                >
                  <i class="fas fa-circle status-icon-red"></i> État de crise
                </h3>
              </div>
              <div class="details-container">
                <div
                  class="question-item"
                  v-for="detail in action.noteDetail"
                  :key="detail.question"
                >
                  <div class="question">
                    <span>{{ detail.question }}</span>
                  </div>
                  <div class="answer">
                    <div class="score-dot"></div>
                    <span
                      v-if="
                        action.name ===
                        'Bilan Flash: Stress, Anxiété et Dépression'
                      "
                      >{{ "Score: " + detail.score }}</span
                    >
                    <span v-else>{{ detail.score }}/10</span>
                  </div>
                  <div
                    v-if="
                      action.name ===
                      'Bilan Flash: Stress, Anxiété et Dépression'
                    "
                    class="answer"
                  >
                    <span>Signification: </span>
                    <span style="margin-left: 10px">
                      {{ "   " + getEtat(detail.question, detail.score) }}
                    </span>
                    <span style="margin-left: 10px">
                      <i
                        v-if="
                          getEtat(detail.question, detail.score) === ' Normal'
                        "
                        class="fas fa-circle status-icon-green"
                      ></i>
                      <i
                        v-if="
                          getEtat(detail.question, detail.score) === ' Léger'
                        "
                        class="fas fa-circle status-icon-orange"
                      ></i>
                      <i
                        v-if="
                          getEtat(detail.question, detail.score) === ' Modéré'
                        "
                        class="fas fa-circle status-icon-orange"
                      ></i>
                      <i
                        v-if="
                          getEtat(detail.question, detail.score) === ' Grave'
                        "
                        class="fas fa-circle status-icon-red"
                      ></i>
                      <i
                        v-if="
                          getEtat(detail.question, detail.score) ===
                          ' Extrêmement grave'
                        "
                        class="fas fa-circle status-icon-red"
                      ></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="bfp-description-resultat" v-if="action.name === 'BFP : Bilan Flash Psychologique'">
                <div
                  v-if="
                    action.noteTotal &&
                    action.noteTotal >= 3 &&
                    action.noteTotal <= 10
                  "
                >
                Les résultats au test semblent indiquer que tout va bien. Votre patient ne présente ni stress, ni charge dépressive importante. Son niveau de forme est correct. 
                </div>
                <div
                  v-else-if="
                    action.noteTotal &&
                    action.noteTotal >= 11 &&
                    action.noteTotal <= 18
                  "
                >
                Les résultats au test semblent indiquer que le stress, la déprime ou la fatigue impacte(nt) votre patient. C’est le moment de prendre contact avec lui et de mettre en œuvre les techniques de reconditionnement psychologique et physique. 
                </div>
                <div
                  v-else-if="
                    action.noteTotal &&
                    action.noteTotal >= 19 &&
                    action.noteTotal <= 30
                  "
                >
                Les résultats au test semblent indiquer que le stress, la déprime ou la fatigue mette(nt) en danger votre patient. Nous vous conseillons de le contacter immédiatement afin de mettre en œuvre avec lui les techniques de reconditionnement d’urgence ou de soins nécessaires. 
                </div>
              </div>
              <div class="DivChart">
                <chartBFP
                  :nameBFP="action.name"
                  :firstValue="firstValue"
                  :secondValue="secondValue"
                  :thirdValue="thirdValue"
                  :valueChart="valueChart"
                ></chartBFP>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import chartBFP from "../chart/chartBFP.vue";
import moment from "moment";
import "moment/locale/fr"; // Importez le module de localisation français
export default {
  props: {
    filesDonesBFP: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      filterText: "",
      detailsVisible: null,
      actions: this.filesDonesBFP.map((fileDone) => ({
        id: fileDone.id,
        name: fileDone.attributes.file.attributes.name,
        dueDate: moment(fileDone.createdAt).locale("fr").format("LLL"),
        status: fileDone.attributes.note,
        statusClass: this.getStatusClass(
          fileDone.attributes.name,
          fileDone.attributes.note,
          JSON.parse(fileDone.attributes.note_detail)
        ),
        noteDetail: JSON.parse(fileDone.attributes.note_detail),
        noteTotal: fileDone.attributes.note,
      })),

      currentSort: "",
      currentSortDir: "asc",
      anxietyValue: 8,
      depressionValue: 2,
      stressValue: 5,
      valueChart: [],
      answers: [],
    };
  },
  created() {
    this.filesDonesBFP.forEach((fileDone) => {});
    console.log("FileDoneBfp", this.filesDonesBFP);
    try {
      // this.scrollToTarget();
      this.IdUserBfp = this.getCookie("IdUserBfp");
      if (this.IdUserBfp) {
        setTimeout(() => {
          this.scrollToTarget();
        }, 1500);
        //ouvrir le action le plus récent
        var action = this.actions.slice().reverse()[0];
        this.MoreInformations(action);
      }
    } catch (error) {
      console.log(error);
    }
  },
  watch: {
    anxietyValue() {
      this.updateRadarChart();
    },
    depressionValue() {
      this.updateRadarChart();
    },
    stressValue() {
      this.updateRadarChart();
    },
  },
  components: {
    chartBFP,
  },
  methods: {
    test(detail) {
      console.log(detail);
    },
    scrollToTarget() {
      // Récupérez la référence vers l'élément cible en utilisant "this.$refs"
      const targetElement = this.$refs.DivPlanAction;

      // Vérifiez si l'élément cible existe
      if (targetElement) {
        // Faites défiler jusqu'à l'élément cible en utilisant scrollIntoView()
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    },
    getCookie(name) {
      var value = "; " + document.cookie;
      var parts = value.split("; " + name + "=");
      if (parts.length == 2) return parts.pop().split(";").shift();
    },
    getEtat(question, score) {
      if (question === "Dépression") {
        if (score >= 0 && score <= 9) {
          return " Normal";
        } else if (score >= 10 && score <= 13) {
          return " Léger";
        } else if (score >= 14 && score <= 20) {
          return " Modéré";
        } else if (score >= 21 && score <= 27) {
          return " Grave";
        } else {
          return " Extrêmement grave";
        }
      } else if (question === "Anxiété") {
        if (score >= 0 && score <= 7) {
          return " Normal";
        } else if (score >= 8 && score <= 9) {
          return " Léger";
        } else if (score >= 10 && score <= 14) {
          return " Modéré";
        } else if (score >= 15 && score <= 19) {
          return " Grave";
        } else {
          return " Extrêmement grave";
        }
      } else if (question === "Stress") {
        if (score >= 0 && score <= 14) {
          return " Normal";
        } else if (score >= 15 && score <= 18) {
          return " Léger";
        } else if (score >= 19 && score <= 25) {
          return " Modéré";
        } else if (score >= 26 && score <= 33) {
          return " Grave";
        } else {
          return " Extrêmement grave";
        }
      }
    },
    getEtatCss(question, score) {
      if (question === "Dépression") {
        if (score >= 0 && score <= 9) {
          return "stress";
        } else if (score >= 10 && score <= 13) {
          return "leger";
        } else if (score >= 14 && score <= 20) {
          return "modere";
        } else if (score >= 21 && score <= 27) {
          return "grave";
        } else {
          return "extremement-grave";
        }
      } else if (question === "Anxiété") {
        if (score >= 0 && score <= 7) {
          return "stress";
        } else if (score >= 8 && score <= 9) {
          return "leger";
        } else if (score >= 10 && score <= 14) {
          return "modere";
        } else if (score >= 15 && score <= 19) {
          return "grave";
        } else {
          return "extremement-grave";
        }
      } else if (question === "Stress") {
        if (score >= 0 && score <= 14) {
          return "stress";
        } else if (score >= 15 && score <= 18) {
          return "leger";
        } else if (score >= 19 && score <= 25) {
          return "modere";
        } else if (score >= 26 && score <= 33) {
          return "grave";
        } else {
          return "extremement-grave";
        }
      }
    },
    getStatusClass(name, note, noteDetail) {
      console.log("Nom du bilan", name);
      var note_detail = noteDetail;

      if (name === "Bilan Flash: Stress, Anxiété et Dépression") {
        console.log("Note détail reçu dans le getStatusCalss", noteDetail);
        for (var i = 0; i < note_detail.length; i++) {
          var question = note_detail[i];
          console.log("Question", question);
          if (question.question === "Anxiété" && question.score >= 27) {
            return "bilan-rouge-2";
          } else if (question.question === "Anxiété" && question.score >= 21) {
            return "bilan-rouge";
          } else if (
            question.question === "Anxiété" &&
            question.score >= 14 &&
            question.score <= 20
          ) {
            return "bilan-orange";
          } else if (
            question.question === "Anxiété" &&
            question.score >= 0 &&
            question.score <= 13
          ) {
            return "bilan-vert";
          } else if (
            question.question === "Dépression" &&
            question.score >= 15
          ) {
            return "bilan-rouge";
          } else if (
            question.question === "Dépression" &&
            question.score >= 10 &&
            question.score <= 14
          ) {
            return "bilan-orange";
          } else if (
            question.question === "Dépression" &&
            question.score >= 0 &&
            question.score <= 9
          ) {
            return "bilan-vert";
          } else if (question.question === "Stress" && question.score >= 19) {
            return "bilan-rouge";
          } else if (
            question.question === "Stress" &&
            question.score >= 15 &&
            question.score <= 18
          ) {
            return "bilan-orange";
          } else if (
            question.question === "Stress" &&
            question.score >= 0 &&
            question.score <= 14
          ) {
            return "bilan-vert";
          }
        }
      } else {
        console.log("Note détail reçu dans le getStatusCalss", note_detail);
        for (var i = 0; i < note_detail.length; i++) {
          var question = note_detail[i];

          if (question.score >= 7) {
            return "bilan-rouge";
          }
        }

        if (note >= 3 && note <= 10) {
          return "bilan-vert";
        } else if (note >= 11 && note <= 18) {
          return "bilan-orange";
        } else if (note >= 19 && note <= 30) {
          return "bilan-rouge";
        } else if (note > 30) {
          return "bilan-rouge";
        }
        return "";
      }
    },

    testAction() {
      console.log(this.actions);
    },

    MoreInformations(result) {
      console.log(result);
      if (result.noteDetail && result.noteDetail.length > 0) {
        // Initialiser un tableau pour stocker les questions
        const questions = [];
        this.valueChart = [];
        this.answers = [];
        // Parcourir tous les éléments note detail et stocker les questions
        result.noteDetail.forEach((detail) => {
          if (detail.question) {
            questions.push(detail.question);
          }
          if (detail.score) {
            this.answers.push(detail.score);
          }
        });

        // Utiliser le tableau comme nécessaire
        console.log(questions);
        this.valueChart = questions;
        console.log(this.answers);
        this.firstValue = this.answers[0];
        this.secondValue = this.answers[1];
        this.thirdValue = this.answers[2];
        console.log(this.answers);
      }

      this.detailsVisible =
        this.detailsVisible === result.id ? null : result.id;
    },
    sortBy(sortKey) {
      if (this.currentSort === sortKey) {
        this.currentSortDir = this.currentSortDir === "asc" ? "desc" : "asc";
      } else {
        this.currentSort = sortKey;
        this.currentSortDir = "asc";
      }

      this.actions.sort((a, b) => {
        if (this.currentSortDir === "asc") {
          return a[this.currentSort] > b[this.currentSort] ? 1 : -1;
        } else {
          return a[this.currentSort] < b[this.currentSort] ? 1 : -1;
        }
      });
    },
  },
};
</script>

<style scoped>
table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin: 20px 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}
.exercise-wrapper {
  position: relative;
  display: inline-block;
}

.indicator-dot {
  position: absolute;
  top: 0;
  left: -8px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  margin-right: 5px;
}
th,
td {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: left;
  cursor: pointer;
}

th {
  background-color: #f9f9f9;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

.en-cours {
  color: #ff9800;
}

.termine {
  color: #4caf50;
}

.table-title {
  font-size: 24px;
  margin-bottom: 16px;
}

.details-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
  width: 100%;
}

.question-item,
.answer-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd; /* Utilisation d'une couleur plus douce pour les bordures */
  border-radius: 10px;
  background-color: #fff; /* Fond blanc pour une meilleure lisibilité */
  transition: transform 0.2s ease-in-out; /* Ajout d'une transition subtile */
}

/* Effet de survol pour attirer l'attention sur les éléments */
.question-item:hover,
.answer-item:hover {
  transform: scale(1.05);
}

.answer-item {
  position: relative;
}

/* .score-dot {
  width: 12px;
  height: 12px;
  background-color: #4caf50;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -20px;
} */

.question span,
.answer span {
  margin-top: 15px;
  font-size: 16px; /* Ajustement de la taille de la police pour une meilleure lisibilité */
}

/* Style spécifique pour la signification */
.answer-item .signification {
  font-weight: bold;
  color: #333;
}

/* Style pour la grille à la fin pour une apparence plus propre */
.answer-item:last-child {
  grid-column: span 3;
}

/* Ajout d'une ombre pour une meilleure distinction des éléments */
.question-item,
.answer-item,
.answer-item:last-child {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.score {
  display: flex;
  align-items: center;
}

.score-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  /* Ajoutez ici la couleur correspondant au score */
}

.question-item {
  display: flex;
  align-items: center;
}

.answer-item {
  display: flex;
  align-items: center;
}

.bilan-vert {
  background-color: green;
  color: white;
}

.question {
  flex: 1;
}

.score-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
  /* Ajoutez ici la couleur correspondant au score */
}

.answer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bilan-orange {
  background-color: orange;
  color: white;
}

.bilan-rouge {
  background-color: red;
  color: white;
}

.status-badge {
  display: inline-flex;
  align-items: center;
}

.status-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 5px;
}

.bilan-vert .status-dot {
  background-color: green;
}

.bilan-orange .status-dot {
  background-color: orange;
}

.bilan-rouge .status-dot {
  background-color: red;
}

.button-custom {
  background-color: #218977;
  border-color: transparent;
  color: #fff;
  display: flex;
  align-items: center;
  border-radius: 20px;
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s;
}

.centered-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-custom:hover {
  background-color: #4d978b;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.icon {
  margin-right: 0.25rem;
}

.titleColonne {
  text-align: center !important;
}

tr.details-row {
  background-color: #f9f9f9;
  font-size: 14px;
}

tr.details-row td {
  padding: 10px;
  text-align: center;
}

tr.details-row td:first-child {
  /* font-weight: bold; */
}

tr.details-row p {
  margin-bottom: 10px;
}

.detail-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.status-icon-green,
.status-icon-orange,
.status-icon-red {
  margin-right: 5px;
  font-size: 20px;
}

.status-icon-green {
  color: green;
}

.status-icon-orange {
  color: orange;
}

.status-icon-red {
  color: red;
}

.DivChart {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.answer .stress {
  color: #ff5733; /* Couleur pour l'état Stress */
}

.answer .leger {
  color: #ffc300; /* Couleur pour l'état Léger */
}

.answer .modere {
  color: #ff5733; /* Couleur pour l'état Modéré */
}

.answer .grave {
  color: #e42057; /* Couleur pour l'état Grave */
}

.answer .extremement-grave {
  color: red; /* Couleur pour l'état Extrêmement Grave */
}
.StatutBFP {
  margin-bottom: 20px;
}

.bfp-description-resultat
{
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  width: 80%;
  margin-right: auto;
  margin-left: auto;
}
</style>
