<template>
  <div>
    <div v-if="!isRecap" class="steps is-large">
      <div
        class="step-item"
        :class="{
          'is-active': etapes[0].active,
          'is-completed': etapes[0].completed,
        }"
      >
        <div class="step-marker">1</div>
        <div
          class="step-details step-detailsDesc"
          :class="{
            cacherDetails: !etapes[0].active,
          }"
        >
          <p>
            {{ $t("creationSeanceGroupeBloc1") }}
          </p>
        </div>
      </div>
      <div
        class="step-item"
        :class="{
          'is-active': etapes[1].active,
          'is-completed': etapes[1].completed,
        }"
      >
        <div class="step-marker">2</div>
        <div
          class="step-details step-detailsDesc"
          :class="{
            cacherDetails: !etapes[1].active,
          }"
        >
          <p>
            {{ $t("creationSeanceGroupeBloc2") }}
          </p>
        </div>
      </div>
      <div
        class="step-item"
        :class="{
          'is-active': etapes[2].active,
          'is-completed': etapes[2].completed,
        }"
      >
        <div class="step-marker">3</div>
        <div
          class="step-details step-detailsDesc"
          :class="{
            cacherDetails: !etapes[2].active,
          }"
        >
          <p>
            {{ $t("creationSeanceGroupeBloc3") }}
          </p>
        </div>
      </div>
      <div
        class="step-item"
        :class="{
          'is-active': etapes[3].active,
          'is-completed': etapes[3].completed,
        }"
      >
        <div class="step-marker">4</div>
        <div
          class="step-details step-detailsDesc"
          :class="{
            cacherDetails: !etapes[3].active,
          }"
        >
          <p>
            {{ $t("creationSeanceGroupeBloc4") }}
          </p>
        </div>
      </div>
    </div>
    <selection-informations
      :sessionGroupValue="sessionGroupValue"
      v-if="step === 1 && !isRecap"
      @next="goToStep"
    />
    <selection-exercices
      :sessionGroupValue="sessionGroupValue"
      v-if="step === 2 && !isRecap"
      @next="goToStep"
    />
    <selection-utilisateurs
      :sessionGroupValue="sessionGroupValue"
      :group="group"
      v-else-if="step === 3 && !isRecap"
      @next="goToStep"
    />
    <recaptitulatif
      v-if="step === 4 || isRecap"
      :sessionGroupValue="sessionGroupValue"
      :group="group"
      :isRecap="isRecap"
      @back="$emit('back')"
      @next="goToStep"
    />
  </div>
</template>

<script>
import Recaptitulatif from "./recaptitulatif.vue";
import SelectionExercices from "./selectionExercices.vue";
import SelectionInformations from "./selectionInformations.vue";
import SelectionUtilisateurs from "./selectionUtilisateurs.vue";
export default {
  name: "creation-seance",
  components: {
    recaptitulatif: Recaptitulatif,
    selectionExercices: SelectionExercices,
    selectionInformations: SelectionInformations,
    selectionUtilisateurs: SelectionUtilisateurs,
  },
  props: ["sessionGroup", "group", "isRecap"],
  data() {
    return {
      step: 1,
      etapes: [
        {
          etape: 1,
          active: true,
          completed: false,
        },
        {
          etape: 2,
          active: false,
          completed: false,
        },
        {
          etape: 3,
          active: false,
          completed: false,
        },
        {
          etape: 4,
          active: false,
          completed: false,
        },
      ],
      sessionGroupValue: {},
    };
  },
  methods: {
    goToStep(num) {
      if (num === 1) {
        this.etapes[0].completed = false;
        this.etapes[0].active = true;
        this.etapes[1].active = false;
      } else {
        this.etapes[num - 2].completed = true;
        this.etapes[num - 2].active = false;
        this.etapes[num - 1].active = true;
        this.etapes[num - 1].completed = false;
        if (this.etapes[num]) {
          this.etapes[num].active = false;
        }
      }
      this.step = num;
    },
  },
  mounted() {
    this.sessionGroupValue = this.sessionGroup;
  },
};
</script>
