var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.isRecap)?_c('div',{staticClass:"steps is-large"},[_c('div',{staticClass:"step-item",class:{
        'is-active': _vm.etapes[0].active,
        'is-completed': _vm.etapes[0].completed,
      }},[_c('div',{staticClass:"step-marker"},[_vm._v("1")]),_c('div',{staticClass:"step-details step-detailsDesc",class:{
          cacherDetails: !_vm.etapes[0].active,
        }},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("creationSeanceGroupeBloc1"))+" ")])])]),_c('div',{staticClass:"step-item",class:{
        'is-active': _vm.etapes[1].active,
        'is-completed': _vm.etapes[1].completed,
      }},[_c('div',{staticClass:"step-marker"},[_vm._v("2")]),_c('div',{staticClass:"step-details step-detailsDesc",class:{
          cacherDetails: !_vm.etapes[1].active,
        }},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("creationSeanceGroupeBloc2"))+" ")])])]),_c('div',{staticClass:"step-item",class:{
        'is-active': _vm.etapes[2].active,
        'is-completed': _vm.etapes[2].completed,
      }},[_c('div',{staticClass:"step-marker"},[_vm._v("3")]),_c('div',{staticClass:"step-details step-detailsDesc",class:{
          cacherDetails: !_vm.etapes[2].active,
        }},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("creationSeanceGroupeBloc3"))+" ")])])]),_c('div',{staticClass:"step-item",class:{
        'is-active': _vm.etapes[3].active,
        'is-completed': _vm.etapes[3].completed,
      }},[_c('div',{staticClass:"step-marker"},[_vm._v("4")]),_c('div',{staticClass:"step-details step-detailsDesc",class:{
          cacherDetails: !_vm.etapes[3].active,
        }},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("creationSeanceGroupeBloc4"))+" ")])])])]):_vm._e(),(_vm.step === 1 && !_vm.isRecap)?_c('selection-informations',{attrs:{"sessionGroupValue":_vm.sessionGroupValue},on:{"next":_vm.goToStep}}):_vm._e(),(_vm.step === 2 && !_vm.isRecap)?_c('selection-exercices',{attrs:{"sessionGroupValue":_vm.sessionGroupValue},on:{"next":_vm.goToStep}}):(_vm.step === 3 && !_vm.isRecap)?_c('selection-utilisateurs',{attrs:{"sessionGroupValue":_vm.sessionGroupValue,"group":_vm.group},on:{"next":_vm.goToStep}}):_vm._e(),(_vm.step === 4 || _vm.isRecap)?_c('recaptitulatif',{attrs:{"sessionGroupValue":_vm.sessionGroupValue,"group":_vm.group,"isRecap":_vm.isRecap},on:{"back":function($event){return _vm.$emit('back')},"next":_vm.goToStep}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }