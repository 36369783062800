<template>
  <div>
    <b-table
      v-if="seancesGroupe.length"
      :data="seancesGroupe"
      :bordered="true"
      :narrowed="true"
      :striped="true"
    >
      <template slot-scope="props" slot="header">
        <div>{{ props.column.label }}</div>
      </template>

      <template slot-scope="props">
        <b-table-column field="name" label="Nom">
          <a
            @click="
              $emit('editionSessionGroup', {
                sessionGroupSelected: props.row,
                isRecap: true,
              })
            "
          >
            {{ props.row.attributes.name }}
          </a>
        </b-table-column>
        <b-table-column
          field="startDate"
          label="Date de debut"
          style="width: 150px"
        >
          {{ props.row.attributes.date_start.toLocaleDateString() }}
        </b-table-column>
        <b-table-column
          field="endDate"
          label="Date de fin"
          style="width: 150px"
        >
          {{ props.row.attributes.date_end.toLocaleDateString() }}
        </b-table-column>
        <b-table-column
          field="nbLicencesUsers"
          label="Nbr de licences utilisateurs"
          style="width: 150px"
        >
          {{ props.row.users.length }}
        </b-table-column>
        <b-table-column
          field="edit"
          label="Modifier"
          class="has-text-centered"
          style="width: 20px"
        >
          <a
            @click="
              $emit('editionSessionGroup', { sessionGroupSelected: props.row })
            "
            v-if="props.row.attributes.date_end >= new Date()"
          >
            <b-icon pack="fas" icon="edit" size="is-small"></b-icon>
          </a>
          <div v-else>
            <b-icon
              pack="fas"
              icon="edit"
              size="is-small"
              type="is-grey"
            ></b-icon>
          </div>
        </b-table-column>
        <b-table-column
          field="delete"
          label="Supprimer"
          class="has-text-centered"
          style="width: 20px"
        >
          <a @click="openModalDeleteSessionGroup(props.row)">
            <b-icon
              pack="fas"
              icon="trash-alt"
              size="is-small"
              type="is-danger"
            >
            </b-icon>
          </a>
        </b-table-column>
      </template>
    </b-table>
    <b-modal
      :width="1200"
      :active.sync="isCardDeleteSessionGroup"
      @close="isCardDeleteSessionGroup = false"
    >
      <div class="card">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              {{ $t("suppressionSeanceGroupe") }}
            </h3>
            <p>{{ $t("confirmationSuppressionSeanceGroupe") }}</p>
            <a
              class="button is-light"
              @click="isCardDeleteSessionGroup = false"
            >
              {{ $t("annuler") }}
            </a>
            <a
              class="button is-cyan is-pulled-right"
              @click="deleteSessionGroup(sessionGroupDelete)"
            >
              {{ $t("confirmer") }}
            </a>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { getObjectById } from "@/services/parse/getObject";
import asyncForEach from "@/services/general/asyncForEach";

export default {
  name: "liste-seance-groupe",
  props: ["seancesGroupe"],
  data() {
    return {
      showRecap: false,
      isCardDeleteSessionGroup: false,
      sessionGroupDelete: {},
    };
  },
  methods: {
    openModalDeleteSessionGroup(props) {
      this.sessionGroupDelete = props;
      this.isCardDeleteSessionGroup = true;
    },
    async deleteSessionGroup(sessionGroup) {
      ParseConfig();
      const loadingComponent = this.$buefy.loading.open();
      try {
        const ResponseSessionGroup = await getObjectById(
          "session_group",
          sessionGroup.id
        );
        const RelationUsersSessionGroup =
          ResponseSessionGroup.relation("users");
        let QueryRelationUsersSessionGroup = RelationUsersSessionGroup.query();
        QueryRelationUsersSessionGroup.limit(1000);
        const ResponseRelationUsersSessionGroup =
          await QueryRelationUsersSessionGroup.find();
        asyncForEach(ResponseRelationUsersSessionGroup, async (user) => {
          await Parse.Cloud.run("annulationSeanceGroupe", {
            destinataire: user.getUsername(),
            utilisateur:
              user.attributes.firstname + " " + user.attributes.lastname,
            therapeute:
              Parse.User.current().attributes.firstname +
              " " +
              Parse.User.current().attributes.lastname,
            emailPro: Parse.User.current().attributes.username,
          });
          const RelationLinkUser = user.relation("link");
          let QueryRelationLinkUser = RelationLinkUser.query();
          QueryRelationLinkUser.limit(1000);
          const ResponseRelationLinkUser = await QueryRelationLinkUser.find();
          const ObjectSessionGroup = Parse.Object.extend("session_group");
          const QuerySessionGroup = new Parse.Query(ObjectSessionGroup);
          const ResponseSessionGroup = await QuerySessionGroup.find();
          const sessionGroupWithCurrentUser = [];
          await asyncForEach(ResponseSessionGroup, async (session) => {
            if (session.id !== sessionGroup.id) {
              const RelationSessionUsers = session.relation("users");
              let QueryRelationSessionUsers = RelationSessionUsers.query();
              const ResponseRelationSessionUsers =
                await QueryRelationSessionUsers.find();
              ResponseRelationSessionUsers.find((e) => {
                return e.id === user.id;
              });
              const currentuser = ResponseRelationSessionUsers.find((e) => {
                return e.id === user.id;
              });
              if (currentuser) {
                sessionGroupWithCurrentUser.push(session);
              }
            }
          });
          let dateInit = null;
          let dateFin = null;
          await sessionGroupWithCurrentUser.forEach((session) => {
            if (!dateInit) dateInit = session.attributes.date_start;
            else if (dateInit > session.attributes.date_start)
              dateInit = session.attributes.date_start;
            if (!dateFin) dateFin = session.attributes.date_end;
            else if (dateFin < session.attributes.date_end)
              dateFin = session.attributes.date_end;
          });
          user.set("type_pratique", "cabinet");
          user.set("cgu", true);
          user.set("date_debut_licence", dateInit);
          user.set("fin_acces_plateforme", dateFin);
          if (!dateInit || !dateFin) {
            ResponseRelationLinkUser.forEach((link) => {
              RelationLinkUser.remove(link);
            });
          }
          Parse.masterKey = "myMasterKey";
          await user.save({}, { useMasterKey: true });
        });
        ResponseSessionGroup.destroy();
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 5000,
          message: "Suppression de la séance de groupe effectuée avec succès.",
          type: "is-success",
        });
        this.$emit("reload");
      } catch (e) {
        //console.log(e);
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Echec de la suppression de la séance de groupe.",
          type: "is-danger",
        });
      }
    },
  },
};
</script>
