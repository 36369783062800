import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export async function getObjectById(table, id) {
  ParseConfig();
  const ObjectValue = Parse.Object.extend(table);
  const QueryValue = new Parse.Query(ObjectValue);
  QueryValue.limit(100000);
  QueryValue.equalTo("objectId", id);
  return await QueryValue.first();
}

export async function getObjects(table) {
  const ObjectValue = Parse.Object.extend(table);
  const QueryValue = new Parse.Query(ObjectValue);
  QueryValue.limit(100000);
  return await QueryValue.find();
}

export async function getObjectsByTypeAndValue(table, type, value) {
  ParseConfig();
  const ObjectValue = Parse.Object.extend(table);
  const QueryValue = new Parse.Query(ObjectValue);
  QueryValue.equalTo(type, value);
  QueryValue.limit(100000);
  return await QueryValue.find();
}

export async function getObjectByTypeAndValue(table, type, value) {
  ParseConfig();
  const ObjectValue = Parse.Object.extend(table);
  const QueryValue = new Parse.Query(ObjectValue);
  QueryValue.limit(100000);
  QueryValue.equalTo(type, value);
  return await QueryValue.first();
}
