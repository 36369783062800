<template>
  <div>
    <div>
      <div
        class=""
        v-for="folder in listeFolder.filter((e) => {
          return e.aAfficher;
        })"
        :key="folder.id"
      >
        <div>
          <p class="has-text-left has-text-weight-semibold">
            {{ folder.name.toUpperCase() }}
          </p>
          <br />
          <div
            v-for="folderProtocole in folder.foldersProtocole.filter((e) => {
              return e.aAfficher;
            })"
            :key="folderProtocole.id"
            class="is-margin-b-20"
          >
            <div>
              <p class="has-text-left brique-text-avancement">
                {{ folderProtocole.name }}
              </p>
              <div
                v-for="folderModule in folderProtocole.foldersModule.filter(
                  (e) => {
                    return e.aAfficher;
                  }
                )"
                :key="folderModule.id"
                class="is-margin-b-20"
              >
                <p class="has-text-left brique-text-avancement">
                  {{ folderModule.name }}
                </p>
                <div style="width: 1000px; height: 150px; display: inline-flex">
                  <div
                    style="height: 150px; display: grid; place-items: center"
                  >
                    <div
                      style="
                        background-color: #537b81;
                        width: 400px;
                        height: 30px;
                      "
                    >
                      <div
                        style="
                          background-color: #3ec2cf;
                          height: 30px;
                          text-align: right;
                        "
                        v-bind:style="{
                          width:
                            folderModule.nbNote === 0
                              ? 0
                              : (folderModule.noteMoyenne * 100) /
                                  folderModule.maxPoint +
                                '%',
                        }"
                      >
                        <label style="color: white; padding-right: 10px">
                          {{
                            folderModule.nbNote === 0
                              ? ""
                              : folderModule.noteMoyenne + " pts"
                          }}
                        </label>
                      </div>
                    </div>
                  </div>

                  <div
                    style="
                      padding-left: 10px;
                      padding-right: 10px;
                      height: 150px;
                      display: grid;
                      place-items: center;
                    "
                  >
                    <label>{{ folderModule.maxPoint }} pts</label>
                  </div>
                  <div
                    style="
                      padding-left: 20px;
                      height: 150px;
                      display: grid;
                      place-items: center;
                      border-left-color: black;
                      border-left-width: 1px;
                      border-left-style: solid;
                    "
                  >
                    <div style="display: inline-flex" v-if="folderUserSelected">
                      <div
                        style="
                          display: grid;
                          place-items: center;
                          padding-right: 20px;
                        "
                      >
                        <label>Résultats détaillés</label>
                      </div>
                      <chart-questionnaire-detail-v2
                        :data="[Math.round(folderModule.somme)]"
                        :questionnaire="folderModule"
                        :userSelected="folderUserSelected"
                      ></chart-questionnaire-detail-v2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chartQuestionnaireDetailV2 from "./chartQuestionnaireDetailv2.vue";
export default {
  props: ["listeUser", "listeFolder", "folderUserSelected"],
  name: "chart-questionnaire-v2",
  components: {
    chartQuestionnaireDetailV2: chartQuestionnaireDetailV2,
  },
  data() {
    return {
      name: "",
      userSelected: null,
    };
  },
  computed: {
    filteredDataArray() {
      return this.listeUser.filter((option) => {
        return (
          option.attributes.username
            .toLowerCase()
            .indexOf(this.name.toLowerCase()) >= 0
        );
      });
    },
  },
};
</script>
