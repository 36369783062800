import getDate from "./getDate";

export default function initialisationTransactionData(transactionData) {
  return {
    date: getDate(),
    amount: transactionData.amount,
    reference: transactionData.reference,
    texteLibre: transactionData.texteLibre,
    email: transactionData.email,
    NbrEch: transactionData.nbrEch,
    DateEcheance1: transactionData.dateEcheance1,
    MontantEcheance1: transactionData.montantEcheance1,
    DateEcheance2: transactionData.dateEcheance2,
    MontantEcheance2: transactionData.montantEcheance2,
    DateEcheance3: transactionData.dateEcheance3,
    MontantEcheance3: transactionData.montantEcheance3,
    DateEcheance4: transactionData.dateEcheance4,
    MontantEcheance4: transactionData.montantEcheance4,
    options: transactionData.options,
    contexte_commandeBase64: transactionData.contexte_commandeBase64,
    montant_deja_capture: transactionData.montant_deja_capture,
  };
}
