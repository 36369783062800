import store from "../../store/store";
const myHeadings = store.getters["user/headings"];

export default function isThisHeading(thisHeading) {
  var isActive = false;
  var heads = myHeadings.filter((el) => {
    return el.heading;
  });
  heads.forEach((el) => {
    if (el.heading.name == thisHeading) {
      isActive = el.isActive;
    }
  });
  return isActive;
}
