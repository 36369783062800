<template>
  <div>
    <div class="columns is-multiline">
      <div
        class="column is-12-mobile is-12-tablet is-6-desktop is-4-widescreen"
      >
        <a class="button is-cyan" @click="isCardCreationUtilisateur = true">
          {{ $t("ajoutUtilisateur") }}
        </a>
        <br />
        <p class="has-text-weight-semibold is-size-5">
          {{ $t("listeUtilisateurInvite") }}
        </p>
      </div>
    </div>
    <b-table
      v-if="
        sessionGroupValue &&
        sessionGroupValue.utilisateurs.length &&
        displayUser
      "
      :data="sessionGroupValue.utilisateurs"
      :bordered="true"
      :narrowed="true"
      :striped="true"
    >
      <template slot-scope="props" slot="header">
        <div>{{ props.column.label }}</div>
      </template>

      <template
        slot-scope="props"
        v-if="props.row.typePratique !== textLicence"
      >
        <b-table-column field="lastname" label="Nom">
          {{ props.row.nom }}
        </b-table-column>
        <b-table-column field="firstname" label="Prénom">
          {{ props.row.prenom }}
        </b-table-column>
        <b-table-column field="email" label="Mail">
          {{ props.row.mail }}
        </b-table-column>
        <b-table-column label="Dernière activité" class="has-text-centered">
          <div v-if="props.row.derniere_connexion">
            {{ new Date(props.row.derniere_connexion).toLocaleDateString() }}
          </div>
        </b-table-column>
        <b-table-column label="Durée d'accès" class="has-text-centered">
          <span v-if="props.row.dureeAcces">
            {{ props.row.dureeAcces }} mois
          </span>
        </b-table-column>
        <b-table-column label="Licence active">
          <div class="has-text-centered">
            <b-icon
              v-if="
                props.row.typePratique === textLicence && props.row.accesActif
              "
              pack="fas"
              icon="circle"
              type="is-success"
            >
            </b-icon>
            <b-icon
              v-else-if="
                props.row.typePratique === textLicence && !props.row.accesActif
              "
              pack="fas"
              icon="circle"
              type="is-warning"
            >
            </b-icon>
            <b-icon v-else pack="fas" icon="circle" type="is-danger"></b-icon>
          </div>
        </b-table-column>
        <b-table-column label="Accès" class="has-text-centered">
          <b-checkbox v-model="props.row.selected" type="is-cyan"></b-checkbox>
        </b-table-column>
      </template>
    </b-table>
    <div v-else>
      {{ $t("aucunUtilisateur") }}
    </div>
    <br /><br /><br /><br />
    <div
      class="navbar is-fixed-bottom"
      style="
        position: float;
        padding-top: 20px;
        border-top: 5px;
        margin-left: 225px;
      "
    >
      <a class="button is-grey is-margin-l-20" @click="$emit('next', 2)">
        {{ $t("precedent") }}
      </a>
      <div class="navbar-end is-margin-r-80">
        <a class="button is-cyan" @click="saveAndNext()">
          {{ $t("suivant") }}
        </a>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardCreationUtilisateur"
      @close="isCardCreationUtilisateur = false"
    >
      <creation-utilisateur-component
        :group="group"
        @createUser="createUser"
        @closeModal="isCardCreationUtilisateur = false"
      />
    </b-modal>
  </div>
</template>

<script>
import { TYPE_PRATIQUE } from "@/configuration/userGroupConf.js";
import CreationUtilisateur from "./creationUtilisateur.vue";
import { getUsersByGroupId } from "@/services/utilisateurs/getUsersByGroupId.js";

export default {
  name: "selection-utilisateurs",
  props: ["sessionGroupValue", "group"],
  components: {
    CreationUtilisateurComponent: CreationUtilisateur,
  },
  data() {
    return {
      isCardCreationUtilisateur: false,
      typePratique: TYPE_PRATIQUE.cabinet,
      textCabinet: TYPE_PRATIQUE.cabinet,
      textLicence: TYPE_PRATIQUE.licence,
      textAffiliation: TYPE_PRATIQUE.affiliation,
      displayUser: true,
    };
  },
  methods: {
    saveAndNext() {
      if (
        !this.sessionGroupValue.utilisateurs.filter((e) => {
          return e.selected === true;
        }).length
      ) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Vous devez sélectionner au moins un utilisateur.",
          type: "is-danger",
        });
        return;
      }
      this.$emit("next", 4);
    },
    async createUser() {
      this.displayUser = false;
      this.isCardCreationUtilisateur = false;
      const props = await getUsersByGroupId(this.group);
      this.sessionGroupValue.utilisateurs = props.usersInMyGroup;
      this.displayUser = true;
    },
  },
};
</script>
