<template lang="html">
  <div>
    <div class="card" style="background-color: white">
      <!-- <header class="card-header card-header-cyan">
        <p class="has-text-white is-size-4">
          {{ $t("offrePulse") }}
        </p>
      </header> -->
      <div class="columns is-multiline">
        <!-- <div
          class="card-image column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen"
          style="width: 100%"
        >
          <figure
            class="image is-2by1"
            style="display: flex; padding-top: 25% !important"
          >
            <img
              style="
                max-width: 400px;
                max-height: 600px;
                margin-left: auto;
                margin-right: auto;
              "
              src="../../../../assets/img/device_symbiocenter.png"
              alt="Image abonnement pulse"
            />
          </figure>
        </div> -->

        <div
          class="column"
          v-if="misAJour"
          style="align-items: center; display: flex; flex-direction: column"
        >
          <div
            class="subscription-options"
            v-if="group.type_abonnement !== 'pulse'"
          >
            <button
              :class="[
                'button-option',
                { annual: true, highlighted: !SubscriptionAnnual },
              ]"
              @click="setEngagement(true)"
            >
              <div class="button-text button-text-subscription">
                <span>
                  {{
                    SubscriptionAnnual
                      ? "Abonnement avec engagement (annuel)"
                      : "Passez à l'abonnement avec engagement (annuel)"
                  }}
                </span>
                <span class="price-tcc-div">
                  {{ (this.pulseAnnuel / 1).toFixed(2) }}€ / ttc
                </span>
              </div>
            </button>
            <button
              :class="[
                'button-option-2',
                { monthly: true, highlighted: SubscriptionAnnual },
              ]"
              @click="setEngagement(false)"
            >
              <div class="button-text button-text-subscription">
                <span>
                  {{
                    SubscriptionAnnual
                      ? "Passez à l'abonnement sans engagement (trimestriel)"
                      : "Abonnement sans engagement (trimestriel)"
                  }}
                </span>
                <span class="price-tcc-div">
                  {{ this.pulseMensuel.toFixed(2) }}€ / ttc
                </span>
              </div>
            </button>
          </div>

          <!-- <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen has-text-centered"
            v-if="group.type_abonnement !== 'pulse'"
          >
            <a
              style="padding: 4rem"
              class="button is-cyan is-large button-offre-abonnement"
              :class="{ isGrey: !abonnement.engagementAnnuel }"
              @click="setEngagement(true)"
            >
              <div class="columns is-multiline">
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-6">{{ $t("avecEngagementAnnuel") }}test</p>
                </div>
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-4">
                    {{ (this.pulseAnnuel / 1).toFixed(2) }}€ / ttc
                  </p>
                </div>
              </div>
            </a>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen has-text-centered"
            v-if="group.type_abonnement !== 'pulse'"
          >
            <a
              style="padding: 4rem"
              class="button is-cyan is-large button-offre-abonnement"
              :class="{ isGrey: abonnement.engagementAnnuel }"
              @click="setEngagement(false)"
            >
              <div class="columns is-multiline">
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-6">{{ $t("sansEngagement") }}</p>
                </div>
                <div class="column is-12 button-text-offre-abonnement">
                  <p class="is-size-4">
                    {{ this.pulseMensuel.toFixed(2) }}€ / ttc
                  </p>
                </div>
              </div>
            </a>
          </div> -->
          <div
            class="column is-12-mobile is-12-tablet is-7-desktop is-8-widescreen"
          >
            <div class="is-margin-l-20 is-size-6">
              <!-- <button @click="testUser(group)">testUser</button> -->
              <!-- <div v-if='!group.date_paiement'>
                Essai gratuits
              </div> -->
              <!-- {{ group.type_abonnement + " " + group.engagement_annuel + " " + modificationAbonnement }} -->
              <div
                v-if="
                  group.type_abonnement === 'pulse' &&
                  !modificationAbonnement &&
                  !group.engagement_annuel &&
                  !group.date_paiement
                "
                class="subscription-container"
              >
                <div class="evolution-abonnement">
                  <p>
                    {{ $t("evolutionAbonnement") }}
                  </p>
                </div>

                <div class="columns">
                  <div class="column subscription-info">
                    <p class="title">{{ $t("votreAbonnement") }}</p>
                    <p class="subtitle">Période d'essai</p>
                    <p class="price">
                      {{ "GRATUIT (" + daysRemaining + " jours restants)" }}
                    </p>
                  </div>

                  <!-- <div class="column subscription-info">
                    <p class="title">{{ $t("abonnementAvecEngagement") }}</p>
                    <p class="description">
                      {{ $t("prelevementAnnuelDe") }}
                      {{ this.pulseAnnuel.toFixed(2) }} € TTC.
                    </p>
                    <p class="description">
                      {{ $t("debiteEn") }} 1 {{ $t("fois") }}.
                    </p>
                  </div> -->
                </div>

                <a
                  class="button-edit-annuel"
                  @click="editInformationAbonnement()"
                >
                  <div class="button-content">
                    <span class="button-text">
                      {{ $t("passageAbonnementAnnuel") }}</span
                    >
                    <span class="button-price">
                      {{ (this.pulseAnnuel / 1).toFixed(2) }}€ / ttc
                    </span>
                  </div>
                </a>

                <a
                  class="button-edit-trimestriel"
                  @click="editInformationAbonnementTrimestriel()"
                >
                  <div class="button-content">
                    <span class="button-text">{{
                      $t("passageAbonnementTrimestriel")
                    }}</span>
                    <span class="button-price"
                      >{{ this.pulseMensuel.toFixed(2) }}€ / ttc</span
                    >
                  </div>
                </a>
              </div>

              <p
                v-if="
                  group.type_abonnement === 'pulse' && modificationAbonnement
                "
                class="abonnement-condition"
              >
                {{ $t("modificationAbonnementConditionGeneral") }}
              </p>
              <!-- <button @click="test(abonnement, group,modificationAbonnement)">test</button> -->
              <div
                class="field"
                v-if="
                  group.type_abonnement !== 'pulse' || modificationAbonnement
                "
              >
                <adresseLectureComponent
                  :propsInformationAdresse="abonnement.adressePostal"
                  :error="error"
                />
                <!-- <b-checkbox
                  v-model="adresseLivraisonDifferente"
                  @input="
                    checkValue();
                    contratAccepte = false;
                  "
                >
                  {{ $t("adresseLivraisonDifferente") }}
                </b-checkbox> -->
              </div>
              <span
                class="has-text-danger"
                v-if="
                  errorAdresseUtilisateurUndefined &&
                  abonnement.engagementAnnuel &&
                  (group.type_abonnement !== 'pulse' || modificationAbonnement)
                "
              >
                {{ $t("adresseFacturationNonRenseigne") }}
              </span>
              <!-- <button @click="test2(group, modificationAbonnement,adresseLivraisonDifferente,userHasBillingAdress)">test2</button> -->
              <div
                v-if="
                  ((group.type_abonnement !== 'pulse' ||
                    modificationAbonnement) &&
                    adresseLivraisonDifferente) ||
                  !userHasBillingAdress
                "
              >
                <p
                  v-if="
                    group.type_abonnement === 'pulse' && modificationAbonnement
                  "
                  class="subtitle subtitleFacturation"
                >
                  {{ $t("modifierInformationFacturation") }}
                </p>
                <adresseLivraisonComponent
                  v-if="abonnement.adresse && modificationAbonnement"
                  @updateAdresse="updateAdresse"
                  :propsInformationAdresse="abonnement.adresse"
                  :error="error"
                />
                <!-- {{ abonnement.adresse }}
                {{ modificationAbonnement }} -->
              </div>
              <div v-else-if="userHasBillingAdress && modificationAbonnement">
                <button @click="toggleAdresseLivraison" class="toggle-button">
                  <span>{{
                    displayAdresseLivraison
                      ? "Cacher le formulaire"
                      : "Modifier votre adresse de facturation"
                  }}</span>
                  <span
                    class="arrow-icon"
                    :class="{
                      'arrow-up': displayAdresseLivraison,
                      'arrow-down': !displayAdresseLivraison,
                    }"
                  ></span>
                </button>
                <div v-if="displayAdresseLivraison">
                  <p
                    v-if="
                      group.type_abonnement === 'pulse' &&
                      modificationAbonnement
                    "
                    class="subtitle subtitleFacturation"
                  >
                    {{ $t("modifierInformationFacturation") }}
                  </p>
                  <adresseLivraisonComponent
                    v-if="abonnement.adresse && modificationAbonnement"
                    @updateAdresse="updateAdresse"
                    :propsInformationAdresse="abonnement.adresse"
                    :error="error"
                  />
                </div>
              </div>
              <!-- <button @click="test">test</button> -->
              <formulaire-paiement-component
                v-if="
                  check &&
                  abonnement &&
                  abonnement.adresse &&
                  abonnement.adresse.codePostal
                "
                :transactionData2="transactionData"
                :amount="this.transactionData.amount"
                :code_societe="code_societe"
                :page="'abonnement'"
                @confirmerAbonnement="confirmerAbonnement"
              />
              <span
                class="has-text-danger"
                v-if="
                  check &&
                  !abonnement &&
                  !abonnement.adresse &&
                  !abonnement.adresse.codePostal
                "
              >
                Pour passer au paiement, veuillez remplir votre adresse.
              </span>
              <br />
              <b-field
                v-if="
                  group.type_abonnement !== 'pulse' || modificationAbonnement
                "
                :type="{ 'is-danger': !contratAccepte && errorContratAccepte }"
                :message="{
                  'Veuillez accepter le contrat afin de procéder au paiement.':
                    !contratAccepte && errorContratAccepte,
                }"
              >
                <!-- <b-checkbox
                  v-if="
                    group.type_abonnement !== 'pulse' || modificationAbonnement
                  "
                  v-model="contratAccepte"
                  @input="Comp()"
                >
                  {{ $t("cgu") }} (<a
                    href="https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgv") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CP_Pulse_SymbioSensor.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cp") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgu") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                    target="_blank"
                  >
                    {{ $t("cgu2_rgpd") }}</a
                  >)
                </b-checkbox> -->
                <b-checkbox
                  v-if="
                    group.type_abonnement !== 'pulse' || modificationAbonnement
                  "
                  v-model="contratAccepte"
                >
                  {{ $t("cgu") }} (<a
                    href="https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgv") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CP_Pulse_SymbioSensor.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cp") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
                    target="_blank"
                    >{{ $t("cgu2_cgu") }}</a
                  >,<a
                    href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
                    target="_blank"
                  >
                    {{ $t("cgu2_rgpd") }}</a
                  >)
                </b-checkbox>
              </b-field>
            </div>
          </div>
          <!-- <button @click="testgroup(group)"> test </button> -->
          <div
            v-if="group.date_paiement && group.type_abonnement === 'pulse'"
            class="column is-12-mobile is-12-tablet is-5-desktop is-4-widescreen"
          >
            <figure class="image is-2by1" style="display: flex">
              <img
                style="
                  max-width: 400px;
                  max-height: 600px;
                  margin-left: auto;
                  margin-right: auto;
                "
                src="../../../../assets/img/device_symbiocenter.png"
                alt="Image abonnement pulse"
              />
            </figure>
            <div class="box has-text-centered box-information box-offre">
              <p class="has-text-weight-normal is-size-3">
                {{ $t("offrePulse") }}
              </p>
              <p
                class="is-size-5 is-margin-b-10"
                v-if="abonnement.engagementAnnuel"
              ></p>
              <p class="is-size-5 is-margin-b-10" v-else>
                {{ this.pulseMensuel.toFixed(2) }} € ttc
              </p>
              <p
                class="is-size-7 is-margin-b-10"
                v-if="abonnement.engagementAnnuel"
              >
                {{ $t("prelevementAnnuelDe") }}
                {{ this.pulseAnnuel.toFixed(2) }} € ttc. <br />
                {{ $t("debiteEn") }} 1 {{ $t("fois") }}.
              </p>
              <p
                class="is-size-6 is-margin-b-10 has-text-success"
                v-if="abonnement.engagementAnnuel"
              ></p>
              <a
                v-if="
                  group.type_abonnement === 'pulse' && !modificationAbonnement
                "
                class="button is-danger buttonRadius"
                @click="
                  (isCardResiliationOpen = true),
                    (raisonResiliation = raisonResiliationDefault)
                "
              >
                {{ $t("resilier") }}
              </a>
              <!--a
                v-else-if="group.type_abonnement !== 'pulse' && !check"
                class="button is-primary buttonRadius"
                @click="confirmerAbonnement()"
              >
                Confirmer & Payer
              </a-->
              <div v-else>
                <!-- <formulaire-paiement-component
                  v-if="showTransactionData"
                  :transactionData2="transactionData"
                  :amount="this.transactionData.amount"
                  @confirmerAbonnement="confirmerAbonnement"
                /> -->
              </div>
            </div>
          </div>
          <div
            class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-margin-b-20"
          >
            <a
              class="button is-light is-margin-l-20 buttonToBack"
              @click="
                modificationAbonnement = false;
                $emit('retour');
              "
            >
              {{ $t("retour") }}
            </a>
            <a
              class="button is-pulled-right is-cyan"
              v-if="group.type_abonnement === 'pulse' && step2"
              @click="modifierAbonnement()"
              :disabled="
                !(
                  group.type_abonnement === 'pulse' &&
                  step2 &&
                  check &&
                  abonnement &&
                  abonnement.adresse &&
                  abonnement.adresse.codePostal
                )
              "
            >
              {{ $t("confirmer") }}
            </a>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      :width="1200"
      :active.sync="isCardResiliationOpen"
      @close="
        (isCardResiliationOpen = false),
          (raisonResiliation = raisonResiliationDefault)
      "
    >
      <div class="card card-resiliation" v-if="raisonResiliation">
        <div class="card-content">
          <div class="content">
            <h3 class="title has-text-black is-size-6">
              {{ $t("demandeResiliation") }}
            </h3>
            <p>
              {{ $t("motivationResiliation") }}
            </p>
            <b-checkbox
              v-model="raisonResiliation.problemes_techniques"
              native-value="Problèmes techniques"
            >
              {{ $t("resiliationRaison1") }}
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="raisonResiliation.inutilise"
              native-value="Inutilisé"
            >
              {{ $t("resiliationRaison2") }}
            </b-checkbox>
            <br />
            <b-checkbox v-model="raisonResiliation.cout" native-value="Coût">
              {{ $t("resiliationRaison3") }}
            </b-checkbox>
            <br />
            <b-checkbox
              v-model="raisonResiliation.complexe"
              native-value="Complexe"
            >
              {{ $t("resiliationRaison4") }}
            </b-checkbox>
            <br />
            <b-checkbox v-model="raisonResiliation.autre" native-value="Autres">
              {{ $t("resiliationRaison5") }}
            </b-checkbox>
            <textarea
              v-if="raisonResiliation.autre"
              v-model="raisonResiliation.texte"
              class="textarea"
              rows="2"
            ></textarea>
            <br />
            <br />
            <p>{{ $t("confirmationAnnualationAbonnement") }}</p>
            <div class="is-margin-b-50">
              <a
                class="button is-light is-pulled-left"
                @click="
                  (isCardResiliationOpen = false),
                    (raisonResiliation = raisonResiliationDefault)
                "
              >
                {{ $t("conserverOffre") }}
              </a>
              <a
                v-if="!validerResiliation"
                class="button is-cyan is-pulled-right"
                @click="resilier()"
              >
                {{ $t("confirmer") }}
              </a>
              <formulaire-resiliation-component
                v-else
                :resiliationData="resiliationData"
                @resilier="resilier()"
              />
            </div>
            <b-field
              :type="{
                'is-danger': errorValiderResiliation && !validerResiliation,
              }"
              :message="{
                'Veuillez accepter avant de confirmer la résiliation.':
                  !validerResiliation && errorValiderResiliation,
              }"
            >
              <b-checkbox class="is-margin-t-20" v-model="validerResiliation">
                {{ $t("informationResiliation") }}
              </b-checkbox>
            </b-field>
            <p class="has-text-justified">
              {{ $t("informationResiliation2") }}
            </p>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import { responseQuestionnaryResiliation } from "@/services/userGroup/responseQuestionnaryResiliation.js";
import FormulairePaiement from "../general/FormulairePaiement.vue";
import FormulaireResiliation from "../general/FormulaireResiliation.vue";
import codeGenerator from "@/services/general/codeGenerator.js";
import { editUserGroupAbonnement } from "@/services/userGroup/editUserGroupAbonnement.js";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";
import { checkAddressInformation } from "@/services/general/checkAddressInformation.js";
import AdresseLivraison from "@/components/general/AdresseLivraison.vue";
import AdresseLecture from "@/components/general/AdresseLecture.vue";
import getDateRenouvellement from "@/services/abonnement/getDateRenouvellement.js";
import { CONFIG_PAIEMENT } from "@/configuration/paiementConf.js";
import asyncForEach from "@/services/general/asyncForEach.js";
//import jsPDF from "jspdf";

const User = createNamespacedHelpers("user");

export default {
  name: "offre-pulse",
  components: {
    AdresseLivraisonComponent: AdresseLivraison,
    AdresseLectureComponent: AdresseLecture,
    FormulairePaiementComponent: FormulairePaiement,
    FormulaireResiliationComponent: FormulaireResiliation,
  },
  data() {
    return {
      userHasBillingAdress: false,
      errorAdresseUtilisateurUndefined: false,
      adresseLivraisonDifferente: false,
      code_societe: CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL,
      amount: null,
      showTransactionData: false,
      transactionData: null,
      abonnement: {},
      misAJour: true,
      error: false,
      contratAccepte: false,
      errorContratAccepte: false,
      erreurPaiement: false,
      isCardResiliationOpen: false,
      raisonResiliation: null,
      raisonResiliationDefault: {
        complexe: false,
        autre: false,
        inutilise: false,
        problemes_techniques: false,
        cout: false,
        texte: null,
      },
      validerResiliation: false,
      errorValiderResiliation: false,
      check: false,
      datePaiement: null,
      resiliationData: null,
      modificationAbonnement: false,
      pulseMensuel: 0,
      pulseAnnuel: 0,
      pulseEconomie: "",
      displayAdresseLivraison: false,
      SubscriptionAnnual: true,
      step2: false,
    };
  },
  computed: {
    ...User.mapGetters(["group", "user", "abonnementTemp"]),
    daysRemaining() {
      if (!this.group.subscription_end_date) {
        return "N/A"; // Retourne 'N/A' si la date de fin de l'abonnement n'est pas définie
      }

      const today = new Date();
      const endDate = new Date(this.group.subscription_end_date);
      const timeDiff = endDate - today;
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convertit le temps restant en jours

      return days > 0 ? days : 0; // Retourne 0 si les jours restants sont négatifs (abonnement expiré)
    },
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsEditAbonnementTemp: "editAbonnementTemp",
    }),
    async confirmerAbonnement() {
      /*if (
        this.abonnement.engagementAnnuel &&
        !this.user.adresse &&
        !this.adresseLivraisonDifferente
      ) {
        this.errorAdresseUtilisateurUndefined = true;
        return;
      }
      const loadingComponent = this.$buefy.loading.open();
      this.error = false;
      this.errorAdresseUtilisateurUndefined = false;
      if (
        this.abonnement.engagementAnnuel &&
        !checkAddressInformation(this.abonnement.adresse)
      ) {
        this.error = true;
        loadingComponent.close();
        return;
      }
      this.actionsEditAbonnementTemp({
        abonnement: this.abonnement,
        transactionData: this.transactionData
      });
      loadingComponent.close();*/
    },
    async editInformationAbonnement() {
      this.modificationAbonnement = true;
      this.abonnement.cartesPostales = true;
      this.setEngagement(true);
    },
    async editInformationAbonnementTrimestriel() {
      this.modificationAbonnement = true;
      this.abonnement.cartesPostales = false;
      this.setEngagement(false);
    },
    test()
    {
      console.log("test",this.transactionData,this.code_societe);
    },
    testgroup(group) {
      console.log("group", group);
    },
    test2(
      group,
      modificationAbonnement,
      adresseLivraisonDifferente,
      userHasBillingAdress
    ) {
      console.log("group", group);
      console.log("modificationAbonnement", modificationAbonnement);
      console.log("adresseLivraisonDifferente", adresseLivraisonDifferente);
      console.log("userHasBillingAdress", userHasBillingAdress);
    },
    async modifierAbonnement() {
      const loadingComponent = this.$buefy.loading.open();
      this.error = false;
      loadingComponent.close();
      await editUserGroupAbonnement(this.abonnement, this.group.objectId);
      this.actionsUpdateUserGroup();
      loadingComponent.close();
      this.$buefy.toast.open({
        duration: 3000,
        message: "Modification de l'abonnement effectué avec succès.",
        type: "is-success",
      });
      this.$emit("retour");
    },
    testUser(user) {
      console.log("user", user);
    },
    updateAdresse(e) {
      this.misAJour = false;
      this.abonnement.adresse = e;
      this.misAJour = true;
      this.contratAccepte = false;
      this.checkValue();
    },
    async resilier() {
      ParseConfig();
      const loadingComponent = this.$buefy.loading.open();
      this.errorValiderResiliation = false;
      if (!this.validerResiliation) {
        this.errorValiderResiliation = true;
        loadingComponent.close();
        return;
      }
      try {
        await responseQuestionnaryResiliation(
          this.raisonResiliation,
          this.group
        );
        this.actionsUpdateUserGroup();
        loadingComponent.close();
        this.isCardResiliationOpen = false;
        this.$buefy.toast.open({
          duration: 5000,
          message: "Résiliation de l'abonnement effectué avec succès.",
          type: "is-success",
        });
        const dateResiliation = getDateRenouvellement(this.group);
        console.log("this.user", this.user);
        console.log("this.group", this.group);
        Parse.Cloud.run("confirmationResiliationAbonnementPro", {
          destinataire: this.user.username,
          offre: this.group.type_abonnement,
          duree: this.group.engagement_annuel ? "annuel" : "trimestriel",
          date_fin_acces: moment(new Date(dateResiliation)).format(
            "DD/MM/YYYY"
          ),
          utilisateur: this.user.email,
        })
          .then((response) => {
            console.log("response", response);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } catch {
        loadingComponent.close();
        this.$buefy.toast.open({
          duration: 3000,
          message: "Erreur rencontrée.",
          type: "is-danger",
        });
      }
      this.$emit("retour");
    },
    setEngagement(value) {
      this.check = false;
      this.SubscriptionAnnual = value;
      this.step2 = true;
      // this.modificationAbonnement=true;
      this.abonnement.engagementAnnuel = value;
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL;
      this.editTransactionData(value);
      if (this.contratAccepte) {
        this.check = true;
      }
    },
    checkValue() {
      this.check = false;
      this.error = false;
      this.errorAdresseUtilisateurUndefined = false;
      if (!this.contratAccepte) {
        this.errorContratAccepte = true;
        return;
      }
      if (
        this.abonnement.engagementAnnuel &&
        !this.user.adresse &&
        !this.adresseLivraisonDifferente
      ) {
        this.errorAdresseUtilisateurUndefined = true;
        return;
      }
      if (
        this.abonnement.engagementAnnuel &&
        !checkAddressInformation(this.abonnement.adresse)
      ) {
        this.error = true;
        return;
      }
      this.check = true;
      this.editTransactionData(this.abonnement.engagementAnnuel);
      this.datePaiement = new Date();
    },
    async generationFacture() {
      var today = new Date();
      var dd = today.getDate();
      var mm = today.getMonth() + 1;

      var yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = dd + "/" + mm + "/" + yyyy;

      ParseConfig();
      var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
      QueryGroup.equalTo("objectId", this.group.objectId);
      var ResponseGroup = await QueryGroup.first();
      var QueryFacture = new Parse.Query(Parse.Object.extend("facture"));
      QueryFacture.equalTo("type_abonnement", "modele"); // Permet de récupérer l'image de fond de la facture
      //var ResponseFacture = await QueryFacture.first();
      var transactionData = this.transactionData;
      //var group = this.group;

      var QueryFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryFactures.equalTo("user", await Parse.User.current());
      var ResponseFactures = await QueryFactures.find();

      await asyncForEach(ResponseFactures, async (facture) => {
        if (!facture.attributes.valide) {
          await facture.destroy();
        }
      });

      var init_ref_facture =
        "SC" +
        yyyy.toString().charAt(2) +
        yyyy.toString().charAt(3) +
        "" +
        mm +
        "";
      var QueryAllFactures = new Parse.Query(Parse.Object.extend("facture"));
      QueryAllFactures.startsWith("reference_facture", init_ref_facture);
      var ResponseAllFactures = await QueryAllFactures.find();

      var initReFactureOk = false;
      var number = ResponseAllFactures.length + 1;
      var finalRef = null;
      while (!initReFactureOk) {
        if (number <= 9) {
          finalRef = init_ref_facture + "000" + number.toString();
        } else if (number <= 99) {
          finalRef = init_ref_facture + "00" + number.toString();
        } else if (number <= 999) {
          finalRef = init_ref_facture + "0" + number.toString();
        } else {
          finalRef = init_ref_facture + number.toString();
        }
        var QueryFactureByRefFacture = new Parse.Query(
          Parse.Object.extend("facture")
        );
        QueryFactureByRefFacture.equalTo("reference_facture", finalRef);
        var ResponseFactureByRefFacture = await QueryFactureByRefFacture.find();
        if (!ResponseFactureByRefFacture.length) {
          initReFactureOk = true;
        } else {
          number++;
        }
      }
      let duration = null;
      let dateRenouvellement = null;
      if (transactionData.engagementAnnuel) duration = "years";
      else duration = "trimesters";
      dateRenouvellement = new Date(moment().add(1, duration));
      while (dateRenouvellement < new Date()) {
        dateRenouvellement = new Date(
          moment(dateRenouvellement).add(1, duration)
        );
      }

      var ObjectFacture = Parse.Object.extend("facture");
      var Facture = new ObjectFacture();
      var monUser = await Parse.User.current();
      Facture.set("type_abonnement", "pulse");
      Facture.set("avec_engagement", transactionData.engagementAnnuel);
      Facture.set("user", monUser);
      Facture.set("montant", transactionData.amount);
      Facture.set("credit", 0);
      Facture.set("reference_paiement", transactionData.reference);
      Facture.set("reference_facture", finalRef);
      Facture.set("valide", false);
      Facture.set("group", ResponseGroup);
      await Facture.save();
    },
    toDataURL(url, currentUser, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(reader.result, currentUser);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    editTransactionData(value) {
      this.showTransactionData = false;
      if (this.transactionData) {
        this.transactionData.engagementAnnuel = value;
      }
      if (this.transactionData && this.transactionData.engagementAnnuel) {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-engagement";
        this.transactionData.amount = this.pulseAnnuel.toFixed(2);
        console.log("PRICE:", this.pulseAnnuel.toFixed(2));
      } else if (this.transactionData) {
        this.transactionData.reference =
          this.transactionData.referenceCode + "-sansengagement";
        this.transactionData.amount = this.pulseMensuel.toFixed(2);
        console.log("PRICE:", this.pulseMensuel.toFixed(2));
      }
      this.code_societe = value
        ? CONFIG_PAIEMENT.CODE_SOCIETE_ANNUEL
        : CONFIG_PAIEMENT.CODE_SOCIETE_MENSUEL;
      this.showTransactionData = true;
      // this.generationFacture();
    },
    toggleAdresseLivraison() {
      this.displayAdresseLivraison = !this.displayAdresseLivraison;
    },
    async chargeParametre() {
      var queryAppParam = new Parse.Query(Parse.Object.extend("app_param"));
      var appParam = await queryAppParam.first();
      this.pulseMensuel = appParam.attributes.pulseMensuel;
      this.pulseAnnuel = appParam.attributes.pulseAnnuel;
      this.pulseEconomie = appParam.attributes.pulseEconomie;
    },
  },
  async mounted() {
    await this.chargeParametre();
    this.raisonResiliation = this.raisonResiliationDefault;
    let engagementAnnuel = false;
    let adresse = {};
    let adressePostal = {};
    if (this.user.adresse) {
      this.userHasBillingAdress = true;
    }
    if (this.group) {
      if (!this.group.adresse_livraison) {
        adresse.nom = this.user.lastname;
        adresse.prenom = this.user.firstname;
        adresse.adresse = this.user.adresse;
        adresse.complementAdresse = this.user.complement_adresse;
        if (this.user.ville) {
          adresse.ville = this.user.ville;
        }
        if (this.user.code_postal) {
          adresse.codePostal = this.user.code_postal;
          adressePostal.codePostal = this.user.code_postal;
        }
        adresse.codeAcces = null;
        adressePostal.nom = this.user.lastname;
        adressePostal.prenom = this.user.firstname;
        adressePostal.adresse = this.user.adresse;
        adressePostal.complementAdresse = this.user.complement_adresse;
        adressePostal.ville = this.user.ville;
        adressePostal.codeAcces = null;
      } else {
        adresse.nom = this.group.adresse_livraison_nom
          ? this.group.adresse_livraison_nom
          : this.user.lastname;
        adresse.prenom = this.group.adresse_livraison_prenom
          ? this.group.adresse_livraison_prenom
          : this.user.firstname;
        adresse.adresse = this.group.adresse_livraison;
        adresse.complementAdresse = this.group.adresse_livraison_complement;
        adresse.ville = this.group.adresse_livraison_ville
          ? this.group.adresse_livraison_ville
          : this.user.ville;
        adresse.codePostal = this.group.adresse_livraison_code_postal
          ? this.group.adresse_livraison_code_postal
          : this.user.code_postal;
        adresse.codeAcces = this.group.adresse_livraison_code_acces_residence;

        adressePostal.nom = this.group.adresse_livraison_nom
          ? this.group.adresse_livraison_nom
          : this.user.lastname;
        adressePostal.prenom = this.group.adresse_livraison_prenom
          ? this.group.adresse_livraison_prenom
          : this.user.firstname;
        adressePostal.adresse = this.group.adresse_livraison;
        adressePostal.complementAdresse =
          this.group.adresse_livraison_complement;
        adressePostal.ville = this.group.adresse_livraison_ville
          ? this.group.adresse_livraison_ville
          : this.user.ville;
        adressePostal.codePostal = this.group.adresse_livraison_code_postal
          ? this.group.adresse_livraison_code_postal
          : this.user.code_postal;
        adressePostal.codeAcces =
          this.group.adresse_livraison_code_acces_residence;
      }

      if (
        !this.group.engagement_annuel &&
        this.group.engagement_annuel !== false
      ) {
        engagementAnnuel = true;
      } else {
        engagementAnnuel = this.group.engagement_annuel;
      }
    }

    this.abonnement = {
      typeAbonnement: "pulse",
      engagementAnnuel: engagementAnnuel,
      cartesPostales: false,
      adresse: adresse,
      adressePostal: adressePostal,
    };

    this.transactionData = {
      email: this.user.email,
      referenceCode:
        this.abonnement.typeAbonnement +
        "_" +
        this.group.objectId +
        "_" +
        codeGenerator("xxxx") +
        "-therapeute",
    };
    this.editTransactionData(engagementAnnuel);
    // this.editInformationAbonnement();
    if (
      Parse.User.current().attributes.group.attributes.type_abonnement ===
      "gratuit"
    ) {
      console.log("Edit information abonnement...");
      this.editInformationAbonnement();
    }

    console.log(Parse.User.current());
  },
  watch: {
    async contratAccepte() {
      if (this.contratAccepte) {
        this.confirmerAbonnement();
        this.check = true;

        const params = {
          groupId: this.group.objectId,
          adresseLivraisonDifferente: this.adresseLivraisonDifferente,
          abonnement: this.abonnement,
          user: this.user,
        };

        try {
          // Appeler la méthode cloud avec les paramètres
          this.generationFacture();
          const result = await Parse.Cloud.run(
            "updateUserGroupAddress",
            params
          );
          console.log(result); // Afficher le résultat
        } catch (error) {
          console.error(
            "Erreur lors de l'appel à updateUserGroupAddress :",
            error
          );
        }
        //     const ObjectGroup = Parse.Object.extend("user_group");
        //     const QueryGroup = new Parse.Query(ObjectGroup);
        //     QueryGroup.equalTo("objectId", this.group.objectId);
        //     const ResponseGroup = await QueryGroup.first();
        //     if (
        //       this.adresseLivraisonDifferente &&
        //       this.abonnement.engagementAnnuel
        //     ) {
        //       ResponseGroup.set(
        //         "adresse_livraison_nom",
        //         this.abonnement.adresse.nom
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison_prenom",
        //         this.abonnement.adresse.prenom
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison",
        //         this.abonnement.adresse.adresse
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison_complement",
        //         this.abonnement.adresse.complementAdresse
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison_code_postal",
        //         this.abonnement.adresse.codePostal
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison_ville",
        //         this.abonnement.adresse.ville
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison_code_acces_residence",
        //         this.abonnement.adresse.codeAcces
        //       );
        //     } else {
        //       ResponseGroup.set("adresse_livraison_nom", this.user.lastname);
        //       ResponseGroup.set("adresse_livraison_prenom", this.user.firstname);
        //       ResponseGroup.set("adresse_livraison", this.user.adresse);
        //       ResponseGroup.set(
        //         "adresse_livraison_complement",
        //         this.user.complementAdresse
        //       );
        //       ResponseGroup.set(
        //         "adresse_livraison_code_postal",
        //         this.user.codePostal
        //       );
        //       ResponseGroup.set("adresse_livraison_ville", this.user.ville);
        //     }
        //     ResponseGroup.set("essai_gratuit", false);
        //     ResponseGroup.set("subscription_end_date", new Date(2100, 12, 1));
        //     await ResponseGroup.save();
        //   }
        // }
      }
    },
    async isCardResiliationOpen() {
      this.resiliationData = {
        reference: this.group.reference_paiement,
        date_commande: moment(new Date(this.group.date_paiement)).format(
          "DD/MM/YYYY"
        ),
        montant_deja_capture: this.group.montant_deja_preleve_therapeute,
        amount: this.group.montant_initial_therapeute,
      };
    },
  },
};
</script>

<style scoped>
.subtitleFacturation {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
}

.toggle-button {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 8px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.arrow-icon {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #666;
  margin-left: 5px;
  transition: transform 0.3s ease;
}

.arrow-up {
  transform: rotate(-180deg);
}

.evolution-abonnement {
  background-color: #f4f9fc; /* Couleur de fond légère pour un aspect propre et moderne */
  border: 1px solid #d1e2f1; /* Bordure fine pour délimiter la zone */
  border-radius: 8px; /* Coins arrondis pour un design doux */
  padding: 20px; /* Espacement interne pour le confort visuel */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Ombre légère pour ajouter de la profondeur */
  max-width: 600px; /* Limite la largeur pour une meilleure lisibilité */
  margin: 20px auto; /* Centre le conteneur et ajoute de l'espace au-dessus et en dessous */
  font-family: "Roboto", sans-serif; /* Police moderne et lisible */
}

.evolution-abonnement p {
  margin: 0; /* Supprime les marges par défaut */
  font-size: 1.125rem; /* Taille de police légèrement augmentée pour la lisibilité */
  color: #333; /* Couleur de texte sombre pour un bon contraste */
  line-height: 1.6; /* Espacement entre les lignes pour une meilleure lisibilité */
}

.evolution-abonnement p::before {
  content: "🔔 "; /* Icône d'alerte pour attirer l'attention */
  color: #007bff; /* Couleur de l'icône pour la cohérence avec le thème */
  font-size: 1.5rem; /* Taille de l'icône pour une meilleure visibilité */
}

.subscription-container {
  text-align: center;
  padding: 30px;
  background-color: #f9f9f9; /* Couleur de fond douce */
  border-radius: 10px; /* Coins arrondis pour un effet moderne */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ombre douce pour de la profondeur */
  margin: 20px auto; /* Centrage et espacement autour du conteneur */
  max-width: 800px; /* Largeur maximale pour un meilleur rendu */
}

.evolution-abonnement {
  background-color: #e0f7fa; /* Couleur de fond douce et apaisante */
  border: 2px solid #00acc1; /* Bordure colorée pour mettre en valeur */
  border-radius: 8px; /* Coins arrondis */
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère */
  margin-bottom: 20px; /* Espacement en dessous */
  font-size: 1.2rem; /* Taille de police légèrement augmentée */
  color: #00796b; /* Couleur du texte pour correspondre au thème */
}

.subscription-info {
  background-color: #ffffff; /* Fond blanc pour une bonne lisibilité */
  border-radius: 8px; /* Coins arrondis */
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère */
  margin: 10px;
}

.title {
  font-size: 1.1rem; /* Taille de police augmentée pour le titre */
  font-weight: bold; /* Texte en gras pour plus d'impact */
  color: #333; /* Couleur sombre pour le texte */
}

.subtitle,
.description {
  font-size: 1rem; /* Taille de police standard */
  color: #555; /* Couleur légèrement plus claire pour le texte secondaire */
}

.price {
  font-size: 1.25rem; /* Taille de police augmentée pour le prix */
  font-weight: bold; /* Texte en gras pour le prix */
  color: #d32f2f; /* Couleur accentuée pour le prix */
  margin-top: 5px; /* Espacement au-dessus du prix */
}

.button.is-primary {
  background-color: #714dd2; /* Couleur de fond primaire */
  color: #ffffff; /* Couleur du texte */
  padding: 12px 20px; /* Espacement interne */
  border-radius: 8px; /* Coins arrondis pour le bouton */
  text-transform: uppercase; /* Texte en majuscules pour le bouton */
  font-weight: bold; /* Texte en gras */
  transition: background-color 0.3s ease; /* Effet de transition pour le survol */
}

.button.is-primary:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
}
/* Conteneur des options d'abonnement */
.subscription-options {
  display: flex;
  justify-content: center; /* Centre les boutons horizontalement */
  gap: 20px; /* Espacement entre les boutons */
  margin: 30px 0; /* Espacement autour du conteneur */
}

/* Styles communs pour les boutons */
.button-option {
  background-color: #007bff; /* Couleur primaire pour les boutons */
  color: #ffffff; /* Couleur du texte */
  border: none; /* Supprime les bordures par défaut */
  border-radius: 12px; /* Coins arrondis pour un look moderne */
  padding: 15px 30px; /* Espacement interne pour confort */
  font-size: 1rem; /* Taille de police pour une bonne lisibilité */
  font-weight: 600; /* Poids de police pour accentuer le texte */
  cursor: pointer; /* Curseur pour indiquer que le bouton est cliquable */
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease; /* Transitions pour les effets de survol */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1); /* Ombre douce pour une meilleure profondeur */
  text-align: center; /* Centre le texte à l'intérieur du bouton */
  line-height: 1.4; /* Hauteur de ligne pour améliorer la lisibilité */
}

/* Effets au survol et au clic pour les boutons */
.button-option:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
  transform: translateY(-4px); /* Élévation au survol */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.button-option:active {
  background-color: #004494; /* Couleur de fond lorsque le bouton est pressé */
  transform: translateY(1px); /* Légère dépression au clic */
}

/* Styles spécifiques pour les boutons */
.button-option-2.monthly {
  background-color: #ffffff; /* Couleur de fond blanche pour l'abonnement sans engagement */
  color: #138496; /* Couleur du texte */
  border: 2px solid #138496; /* Bordure colorée */
}

.button-option-2.monthly:hover {
  background-color: #138496; /* Couleur au survol pour l'abonnement sans engagement */
  color: #ffffff; /* Texte blanc au survol */
}

.button-option.annual {
  background-color: #28a745; /* Couleur pour l'abonnement avec engagement */
  box-shadow: 0 6px 12px rgba(0, 128, 0, 0.2); /* Ombre douce plus prononcée */
}

.button-option.annual:hover {
  background-color: #218838; /* Couleur au survol pour l'abonnement avec engagement */
  box-shadow: 0 8px 16px rgba(0, 128, 0, 0.3); /* Ombre plus prononcée au survol */
}

/* Met en avant le bouton d'abonnement annuel */
.button-option.highlighted {
  border: 2px solid #ffffff; /* Bordure blanche pour le bouton d'abonnement annuel */
  background-color: #2e7d32; /* Couleur de fond plus foncée pour le bouton d'abonnement annuel */
  color: #ffffff; /* Couleur du texte */
}

.button-option.highlighted:hover {
  background-color: #1b5e20; /* Couleur au survol pour mettre davantage en avant */
}

.button-option-2 {
  background-color: #007bff; /* Couleur de fond primaire pour les boutons */
  color: #ffffff; /* Couleur du texte */
  border: none; /* Supprime les bordures par défaut */
  border-radius: 12px; /* Réduit les coins arrondis pour un bouton plus compact */
  padding: 5px 12px; /* Réduit l'espacement interne pour diminuer la taille du bouton */
  font-size: 0.875rem; /* Réduit la taille de la police pour un bouton plus petit */
  cursor: pointer; /* Curseur pour indiquer que le bouton est cliquable */
  transition: background-color 0.3s ease, transform 0.2s ease,
    box-shadow 0.3s ease; /* Transitions pour les effets de survol */
}

.button-option-2:hover {
  background-color: #0056b3; /* Couleur de fond au survol */
  transform: translateY(-4px); /* Élévation au survol */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée au survol */
}

.button-option-2:active {
  background-color: #004494; /* Couleur de fond lorsque le bouton est pressé */
  transform: translateY(1px); /* Légère dépression au clic */
}

.button-text-subscription {
  display: flex;
  flex-direction: column;
}

.button-option.annual.highlighted {
  background-color: white;
  color: #28a745;
  border: 2px solid #28a745;
}

/* Styles pour le bouton mensuel */
.button-option-2.monthly.highlighted {
  background-color: white; /* Couleur de fond blanche pour l'abonnement sans engagement */
  color: #138496; /* Couleur du texte */
  border: 1px solid #138496; /* Bordure colorée */
}
/* Style pour le bouton avec la classe 'button-edit-trimestriel' */
.button-edit-trimestriel {
  display: inline-block; /* Pour que le bouton s'adapte au texte */
  padding: 4px 12px; /* Espacement interne réduit */
  font-size: 12px; /* Taille de la police réduite */
  color: #0056b3; /* Couleur du texte bleue pour la discrétion */
  background-color: transparent; /* Fond transparent pour le bouton */
  border: 1px solid #0056b3; /* Bordure bleue */
  border-radius: 3px; /* Coins légèrement arrondis pour un style épuré */
  text-decoration: none; /* Supprime la soulignure du lien */
  cursor: pointer; /* Change le curseur en pointeur pour indiquer que c'est cliquable */
  transition: background-color 0.3s, color 0.3s; /* Effet de transition pour le survol */
  margin-top: 10px;
}

.button-edit-trimestriel:hover,
.button-edit-trimestriel:focus {
  background-color: #007bff; /* Fond bleu lors du survol */
  color: white; /* Texte blanc lors du survol */
  outline: none; /* Retire le contour du focus */
}

.button-edit-trimestriel:active {
  background-color: #0056b3; /* Couleur de fond plus foncée lors du clic */
  border-color: #0056b3; /* Couleur de bordure plus foncée lors du clic */
}
.button-edit-annuel {
  display: inline-block; /* Pour que le bouton s'adapte au texte */
  padding: 6px 18px; /* Espacement interne augmenté de 1,5 fois */
  font-size: 18px; /* Taille de la police augmentée de 1,5 fois */
  background: #714dd2;
  color: white; /* Texte blanc pour une bonne visibilité */
  border: 2px solid #714dd2; /* Bordure verte augmentée de 1,5 fois */
  border-radius: 4px; /* Coins légèrement arrondis pour un style épuré */
  text-decoration: none; /* Supprime la soulignure du lien */
  cursor: pointer; /* Change le curseur en pointeur pour indiquer que c'est cliquable */
  transition: background-color 0.3s, color 0.3s; /* Effet de transition pour le survol */
  margin-top: 15px; /* Marge supérieure augmentée pour espacer davantage */
}

.button-edit-annuel:hover,
.button-edit-annuel:focus {
  background-color: #553c9b; /* Fond vert lors du survol */
  color: white; /* Texte blanc lors du survol */
  outline: none; /* Retire le contour du focus */
}

.button-edit-annuel:active {
  background-color: #714dd2; /* Couleur de fond plus foncée lors du clic */
  border-color: #714dd2; /* Couleur de bordure plus foncée lors du clic */
}

.button-content {
  display: flex;
  flex-direction: column; /* Empile les éléments verticalement */
  align-items: center; /* Centre les éléments horizontalement */
  text-align: center; /* Centre le texte */
}

.abonnement-condition {
  font-size: 16px; /* Taille de police optimisée */
  line-height: 1.5; /* Hauteur de ligne pour une meilleure lisibilité */
  color: #333; /* Couleur de texte neutre */
  margin: 10px 0; /* Marge verticale pour séparer les éléments */
  padding: 10px; /* Padding pour espacer le texte du bord */
  border-left: 4px solid #714dd2; /* Bordure colorée pour mettre en avant le texte */
  background-color: #f9f9f9; /* Couleur de fond légère pour une meilleure visibilité */
  border-radius: 4px; /* Coins arrondis pour un effet visuel agréable */
}

.buttonToBack {
  border: 1px solid #8b8b8b;
}

.buttonToBack:hover {
  background-color: #8b8b8b;
  color: white;
}
</style>
