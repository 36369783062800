// import moment from "moment";

export default function hasPayingSubscription(abonnement) {
  if (
    abonnement &&
    (abonnement.type === "play" || abonnement.type === "pulse")
    // &&
    // abonnement.endDate &&
    // moment().toDate() <= moment(abonnement.endDate).toDate()
  ) {
    return true;
  }
  return false;
}
