import ParseConfig from "@/api/parse.config";
import asyncForEach from "@/services/general/asyncForEach";
import { getFilesByModule } from "./getFilesByModule.js";
import { getFilesCompositeByModule } from "./getFilesCompositeByModule.js";

export async function getFoldersByParent(
  parent,
  ResponseLinkFromRelation,
  typeAbonnement,
  Tabfolders,
  TabLink,
  ResponseVerrouillageEtape,
  TabFile,
  TabCompositeFile
) {
  ParseConfig();
  const folders = [];

  //const ObjectFolder = Parse.Object.extend("folder");
  //const ObjectVerrouillageEtape = Parse.Object.extend("verrouillage_etape");

  let ResponseLinks = TabLink.filter(function (e) {
    if (e.attributes.parent_folder !== undefined) {
      return e.attributes.parent_folder.id === parent.id;
    } else {
      return false;
    }
  });

  await ResponseLinks.sort((a, b) => {
    if (a.attributes.order < b.attributes.order) return -1;
    return 1;
  });

  await asyncForEach(ResponseLinks, async (link) => {
    let tmpFolder = Tabfolders.filter(function (e) {
      return (
        link.attributes.folder !== undefined &&
        e.id === link.attributes.folder.id
      );
    });

    const folder = tmpFolder[0];

    const ResponseLinksNew = TabLink.filter(function (e) {
      if (e.attributes.parent_folder !== undefined) {
        return e.attributes.parent_folder.id === folder.id;
      } else {
        return false;
      }
    });

    const foldersNew = [];
    const foldersNewExpert = [];
    const foldersNewDecouverte = [];

    ResponseLinksNew.sort((a, b) => {
      if (a.attributes.order < b.attributes.order) return -1;
      return 1;
    });
    await asyncForEach(ResponseLinksNew, async (linkNew) => {
      let tmpFolder2 = Tabfolders.filter(function (e) {
        return (
          linkNew.attributes.folder !== undefined &&
          e.id === linkNew.attributes.folder.id
        );
      });
      const folderNew = tmpFolder2[0];

      const files = await getFilesByModule(
        folderNew,
        typeAbonnement,
        TabFile,
        TabLink
      );

      let compositeFile = {
        expert: [],
        decouverte: [],
      };
      let fileIsNotEval = true;
      if (folder.attributes.name === "Entraînement libre") {
        compositeFile = await getFilesCompositeByModule(
          folderNew,
          TabLink,
          TabCompositeFile
        );
      }

      const fileAccept = files.find((e) => {
        if (
          e.attributes.type.attributes !== undefined &&
          (e.attributes.type.attributes.name === "Évaluation" ||
            e.attributes.type.attributes.name === "Questionnaire")
        ) {
          fileIsNotEval = false;
          return true;
        }
        return true;
      });

      const folderTemp2 = {
        id: folderNew.id,
        color: folderNew.attributes.color,
        picto: folderNew.attributes.picto,
        name: folderNew.attributes.name,
        text: folderNew.attributes.text,
        resource: folderNew.attributes.resource,
        textFinProtocole: folderNew.attributes.textFinProtocole,
        resourceBg: folderNew.attributes.resourceBg,
        type_abonnement: folderNew.attributes.type_abonnement,
        link: linkNew,
        fileIsNotEval: fileIsNotEval,
        deverrouille: false,
        nbr_exo: folderNew.attributes.nbr_exo,
      };
      if (
        parent.attributes.name.includes("cardiaque") &&
        folder.attributes.name === "Entraînement libre"
      ) {
        foldersNew.push(folderTemp2);
      }
      if (
        fileAccept ||
        compositeFile.expert.length ||
        compositeFile.decouverte.length
      ) {
        if (
          parent.attributes.name.includes("cardiaque") &&
          folder.attributes.name !== "Entraînement libre" &&
          typeAbonnement !== "pulse"
        ) {
          if (foldersNew.length <= 0) {
            foldersNew.push(folderTemp2);
          }
        } else {
          foldersNew.push(folderTemp2);
        }
      }
      if (compositeFile && compositeFile.expert.length) {
        folderTemp2.linkExpert = compositeFile.expert;
        foldersNewExpert.push(folderTemp2);
      }
      if (compositeFile && compositeFile.decouverte.length) {
        folderTemp2.linkDecouverte = compositeFile.decouverte;
        foldersNewDecouverte.push(folderTemp2);
      }
    });

    let deverouille = false;
    if (
      ResponseLinkFromRelation.find((e) => {
        return e.id === link.id;
      })
    ) {
      deverouille = true;
    }

    /*const verrouillageEtape = ResponseVerrouillageEtape.find(e => {
      if (
        e.attributes.folder !== undefined &&
        folder &&
        e.attributes.folder.id === folder.id
      ) {
        return e;
      }
      return;
    });
    if (!verrouillageEtape) {
      const NewVerrouillageEtape = new ObjectVerrouillageEtape();
      const NewFolder = new ObjectFolder();
      NewFolder.id = folder.id;
      NewVerrouillageEtape.set("User", await Parse.User.current());
      NewVerrouillageEtape.set("verrouille", false);
      NewVerrouillageEtape.set("folder", NewFolder);
      NewVerrouillageEtape.save();
    }

    let verrouillage_etape = false;
    if (verrouillageEtape && verrouillageEtape.attributes.verrouille) {
      verrouillage_etape = verrouillageEtape.attributes.verrouille;
    }*/
    if (folder.attributes && folder.attributes.videosIntro) {
      var videoIntroToImplement = folder.attributes.videosIntro;
    }
    const folderTemp = {
      id: folder.id,
      color: folder.attributes.color,
      picto: folder.attributes.picto,
      name: folder.attributes.name,
      text: folder.attributes.text,
      resource: folder.attributes.resource,
      textFinProtocole: folder.attributes.textFinProtocole,
      resourceBg: folder.attributes.resourceBg,
      type_abonnement: folder.attributes.type_abonnement,
      link: link,
      deverrouille: deverouille,
      deverrouille_user: deverouille,
      verrouillage_etape: null,
      videoIntro: videoIntroToImplement,
      nbr_exo: folder.attributes.nbr_exo,
    };
    if (foldersNew) {
      folderTemp.modules = foldersNew;
      //console.log('Module: ', folderTemp.modules);
      for (var j = 0; j < folderTemp.modules.length; j = j + 1) {
        if (
          ResponseLinkFromRelation.find((e) => {
            return (
              folderTemp.modules[j].link !== undefined &&
              e.id === folderTemp.modules[j].link.id
            );
          })
        ) {
          folderTemp.modules[j].deverrouille = true;
        } else {
          folderTemp.modules[j].deverrouille = false;
        }
      }
    }
    if (foldersNewExpert) {
      folderTemp.modulesExpert = foldersNewExpert;
    }
    if (foldersNewDecouverte) {
      folderTemp.modulesDecouverte = foldersNewDecouverte;
    }
    if (folderTemp.name === "Entraînement libre" && folderTemp.deverrouille) {
      if (folders[1]) {
        if (folders[1].deverrouille) {
          folderTemp.buttonExpert = folders[1].deverrouille;
          folderTemp.buttonDecouverte = folders[1].deverrouille;
        } else {
          folderTemp.buttonExpert = folders[1].deverrouille;
          folderTemp.buttonDecouverte = folders[0].deverrouille;
        }
      } else {
        folderTemp.buttonExpert = folders[0].deverrouille;
        folderTemp.buttonDecouverte = folders[0].deverrouille;
      }
    }
    folders.push(folderTemp);
  });
  return folders;
}
