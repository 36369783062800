var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.folders)?_c('div',_vm._l((_vm.folders.filter(
      (e) => e.theme.ordre == _vm.numTheme && e.aAfficher
    )),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"column is-2 is-multiline folders-dashboard"},[_c('div',[_c('p',{staticClass:"has-text-centered is-size-5 has-text-weight-semibold",staticStyle:{"min-height":"60px"}},[_vm._v(" "+_vm._s(folderBrique.name.toUpperCase())+" ")]),_c('br'),_vm._l((folderBrique.foldersProtocole.filter(
          (e) => {
            return e.aAfficher;
          }
        )),function(folderProtocole){return _c('div',{key:folderProtocole.id,staticClass:"is-margin-b-20"},[(
            folderProtocole.name !== 'Entraînement libre' &&
            folderProtocole.name !== 'Découverte vidéo de la technique'
          )?_c('div',[_c('div',[_c('div',[_c('p',{staticClass:"brique-text-avancement"},[_vm._v(" "+_vm._s(folderProtocole.name)+" ")])]),(_vm.nbUtilisateur)?_c('chart-brique',{attrs:{"data":[
                Math.round(
                  folderProtocole.sommeCabinet / _vm.nbUtilisateur.cabinet
                ),
                Math.round(
                  folderProtocole.sommeLicence / _vm.nbUtilisateur.licence
                ),
                Math.round(
                  folderProtocole.sommeAffiliation / _vm.nbUtilisateur.affiliation
                ),
              ],"moyenne":Math.round(folderProtocole.somme / _vm.nbUtilisateur.total)}}):_c('chart-brique-user',{attrs:{"data":[Math.round(folderProtocole.somme)]}})],1),_c('div',[(
                _vm.nbUtilisateur && _vm.nbUtilisateur.total && folderProtocole.somme
              )?_c('p',{staticClass:"has-text-centered is-size-5 has-text-weight-semibold"},[_vm._v(" Moyenne : "+_vm._s(Math.round(folderProtocole.somme / _vm.nbUtilisateur.total))+" % ")]):(_vm.nbUtilisateur)?_c('p',{staticClass:"has-text-centered is-size-5 has-text-weight-semibold"},[_vm._v(" Moyenne : 0 % ")]):_vm._e(),_c('hr')]),(folderProtocole.type === 'Protocole long')?_c('div',{staticStyle:{"text-align":"center"}},[_c('p',[_vm._v(" Résultats de l'auto-évaluation sur "),_c('b',[_vm._v(_vm._s(folderProtocole.foldersModule[0].maxPoint)+" pts")])]),_c('chart-auto-evaluation',{attrs:{"max":folderProtocole.foldersModule[0].maxPoint,"avant":folderProtocole.foldersModule[0].noteMoyenne,"apres":folderProtocole.foldersModule[5].noteMoyenne}})],1):_vm._e()]):_vm._e()])}),(folderBrique.name.includes('cardiaque'))?_c('div',{staticClass:"has-text-centered is-margin-t-20"},[_c('a',{staticClass:"button is-cyan",on:{"click":function($event){_vm.isCardResultat = true}}},[_vm._v(" "+_vm._s(_vm.$t("resultat"))+" ")])]):_vm._e(),(folderBrique.name.includes('cardiaque'))?_c('div',{staticClass:"has-text-centered is-margin-t-20"},[(_vm.statistiques)?_c('a',{staticClass:"button is-cyan",on:{"click":function($event){_vm.isCardStatistiques = true}}},[_vm._v(" "+_vm._s(_vm.$t("statistiques"))+" ")]):_vm._e()]):_vm._e()],2)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }