<template>
  <div>
    <div>
      <div class="columns is-multiline">
        <div class="column is-3">
          <b-dropdown
            v-model="userSelected"
            aria-role="list"
            v-if="users"
            class="is-pulled-right"
          >
            <button class="button" type="button" slot="trigger">
              <template v-if="userSelected === 'tous'">
                <span>{{ $t("rechercherParUtilisateur") }}</span>
                <b-icon pack="fas" icon="caret-down"></b-icon>
              </template>
              <template v-else>
                <span>{{ userSelected.attributes.username }}</span>
                <b-icon pack="fas" icon="caret-down"></b-icon>
              </template>
            </button>
            <b-dropdown-item value="tous" aria-role="listitem">
              {{ $t("aucunFiltre") }}
            </b-dropdown-item>
            <b-dropdown-item
              v-for="utilisateur in users"
              :key="utilisateur.id"
              :value="utilisateur"
              aria-role="listitem"
            >
              {{ utilisateur.attributes.username }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="column is-3">
          <b-field>
            <b-datepicker
              v-model="dateDebut"
              icon-pack="fas"
              ref="datepicker"
              placeholder="Date de début"
              icon="calendar-alt"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <b-datepicker
              v-model="dateFin"
              icon-pack="fa"
              ref="datepicker"
              placeholder="Date de fin"
              icon="calendar-alt"
            >
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-3">
          <b-field>
            <b-radio-button
              v-model="selectionDuree"
              native-value="JOUR"
              type="is-primary"
            >
              <span>{{ $t("jour") }}</span>
            </b-radio-button>

            <b-radio-button
              v-model="selectionDuree"
              native-value="SEMAINE"
              type="is-primary"
            >
              <span>{{ $t("semaine") }}</span>
            </b-radio-button>

            <b-radio-button
              v-model="selectionDuree"
              native-value="MOIS"
              type="is-primary"
            >
              <span>{{ $t("moisMaj") }}</span>
            </b-radio-button>
          </b-field>
        </div>
      </div>
      <div class="column is-12">
        <div
          class="has-text-danger"
          v-if="
            userSelected !== 'tous' &&
            (userSelected.attributes.type_pratique === 'licence' ||
              userSelected.attributes.affiliation) &&
            !userSelected.attributes.acces_donnees_therapeute
          "
        >
          L'utilisateur n'a pas autorisé l'accès à ses données.
        </div>
      </div>
    </div>
    <canvas
      class="is-margin-b-50"
      ref="chart"
      height="200px"
      width="1000px"
    ></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";
import moment from "moment";
import Parse from "parse";
import ParseConfig from "@/api/parse.config.js";

export default {
  name: "connexion-utilisateurs",
  props: ["group", "users"],
  data() {
    return {
      userSelected: "tous",
      //users: [],
      selectionDuree: "JOUR",
      dateDebut: moment().subtract(35, "d").toDate(),
      dateFin: new Date(),
      options: {
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              id: "y-axis-1",
              type: "linear",
              display: true,
              position: "left",
              ticks: {
                beginAtZero: true,
                min: 0,
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    async getSessionDetail() {
      //this.users = [];

      const ObjectUserGroup = Parse.Object.extend("user_group");
      const QueryUserGroup = new Parse.Query(ObjectUserGroup);
      QueryUserGroup.equalTo("objectId", this.group.objectId);
      const ResponseUserGroup = await QueryUserGroup.first();

      const ObjectSessionDetail = Parse.Object.extend("session_detail");
      const QuerySessionDetail = new Parse.Query(ObjectSessionDetail);
      QuerySessionDetail.lessThanOrEqualTo("createdAt", this.dateFin);
      QuerySessionDetail.greaterThanOrEqualTo("createdAt", this.dateDebut);
      QuerySessionDetail.equalTo("group", ResponseUserGroup);
      QuerySessionDetail.limit(10000);
      const ResponseSessionDetail = await QuerySessionDetail.find();
      var Sessions = [];

      /* await asyncForEach(this.users, async user => {
        if (user.attributes.acces_donnees_therapeute) {
          const QuerySessionDetailAffilie = new Parse.Query(
            ObjectSessionDetail
          );
          QuerySessionDetailAffilie.lessThanOrEqualTo(
            "createdAt",
            this.dateFin
          );
          QuerySessionDetailAffilie.greaterThanOrEqualTo(
            "createdAt",
            this.dateDebut
          );
          QuerySessionDetailAffilie.equalTo("user", user);
          const ResponseSessionDetailAffilie = await QuerySessionDetailAffilie.find();
          await ResponseSessionDetailAffilie.forEach(e => {
            Sessions.push(e);
          });
        }
      });*/

      const QuerySessionDetailAffilie = new Parse.Query(ObjectSessionDetail);
      QuerySessionDetailAffilie.lessThanOrEqualTo("createdAt", this.dateFin);
      QuerySessionDetailAffilie.greaterThanOrEqualTo(
        "createdAt",
        this.dateDebut
      );
      QuerySessionDetailAffilie.containedIn("user", this.users);
      const ResponseSessionDetailAffilie =
        await QuerySessionDetailAffilie.find();
      await ResponseSessionDetailAffilie.forEach((e) => {
        if (e.attributes.user.attributes.acces_donnees_therapeute)
          Sessions.push(e);
      });

      await ResponseSessionDetail.forEach((e) => {
        // eslint-disable-next-line
        if (e.attributes.user.attributes.type_pratique === "licence" && !e.attributes.user.attributes.acces_donnees_therapeute) {
        } else {
          Sessions.push(e);
        }
      });
      if (this.selectionDuree === "JOUR") {
        this.traitementDataJour(Sessions);
      } else if (this.selectionDuree === "SEMAINE") {
        this.traitementDataSemaine(Sessions);
      } else if (this.selectionDuree === "MOIS") {
        this.traitementDataMois(Sessions);
      }
    },
    traitementDataJour(SessionsDetail) {
      const labels = [];
      const datas = { cabinet: [], licence: [], affiliation: [] };
      const datasById = [];
      let currentDate = moment(this.dateDebut);
      while (currentDate <= moment(this.dateFin)) {
        labels.push(currentDate.format("DD-MM-YYYY"));
        datas.cabinet.push(0);
        datas.licence.push(0);
        datas.affiliation.push(0);
        datasById.push({ cabinet: [], licence: [], affiliation: [] });
        currentDate.add(1, "days");
      }

      SessionsDetail.forEach((session) => {
        const index = labels.indexOf(
          moment(session.attributes.createdAt).format("DD-MM-YYYY")
        );
        if (index) {
          if (
            session.attributes.user.attributes.type_pratique === "cabinet" &&
            !session.attributes.user.attributes.affiliation
          ) {
            if (
              !datasById[index].cabinet.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[index].cabinet.push(session.attributes.user.id);
              datas.cabinet[index]++;
            }
          } else if (
            session.attributes.user.attributes.type_pratique === "licence" &&
            !session.attributes.user.attributes.affiliation
          ) {
            if (
              !datasById[index].licence.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[index].licence.push(session.attributes.user.id);
              datas.licence[index]++;
            }
          } else if (session.attributes.user.attributes.affiliation) {
            if (
              !datasById[index].licence.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[index].licence.push(session.attributes.user.id);
              datas.affiliation[index]++;
            }
          }
        }
      });

      const mesDonnees = {
        labels: labels,
        datasets: [
          {
            label: this.$t("utilisateursCabinet"),
            data: datas.cabinet,
            backgroundColor: "rgba(77, 160, 255, 0.2)",
            borderColor: "rgba(77, 160, 255, 1)",
          },
          {
            label: this.$t("utilisateursLicence"),
            data: datas.licence,
            backgroundColor: "rgba(184, 61, 153, 0.2)",
            borderColor: "rgba(184, 61, 153, 1)",
          },
          {
            label: this.$t("utilisateursAffilie"),
            data: datas.affiliation,
            backgroundColor: "rgba(210, 213, 5, 0.2)",
            borderColor: "rgba(210, 213, 5, 1)",
          },
        ],
      };

      var chart = this.$refs.chart;
      var ctx = chart.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: mesDonnees,
        options: this.options,
      });
    },
    traitementDataSemaine(SessionsDetail) {
      const labels = [];
      const labelsDays = [];
      const datas = { cabinet: [], licence: [], affiliation: [] };
      const datasById = [];
      let currentDate = moment(this.dateDebut);
      while (currentDate <= moment(this.dateFin)) {
        labelsDays.push(currentDate.format("DD-MM-YYYY"));
        const weekNumber = this.getWeekNumber(currentDate);
        const labelFormat = weekNumber + "-" + currentDate.format("YYYY");
        if (
          !labels.find((e) => {
            return e === labelFormat;
          })
        ) {
          labels.push(labelFormat);
          datas.cabinet.push(0);
          datas.licence.push(0);
          datas.affiliation.push(0);
        }
        datasById.push({ cabinet: [], licence: [], affiliation: [] });
        currentDate.add(1, "days");
      }

      SessionsDetail.forEach((session) => {
        const indexWeek = labels.indexOf(
          this.getWeekNumber(moment(session.attributes.createdAt)) +
            "-" +
            moment(session.attributes.createdAt).format("YYYY")
        );
        const indexDays = labelsDays.indexOf(
          moment(session.attributes.createdAt).format("DD-MM-YYYY")
        );
        if (indexDays && indexWeek) {
          if (
            session.attributes.user.attributes.type_pratique === "cabinet" &&
            !session.attributes.user.attributes.affiliation
          ) {
            if (
              !datasById[indexDays].cabinet.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[indexDays].cabinet.push(session.attributes.user.id);
              datas.cabinet[indexWeek]++;
            }
          } else if (
            session.attributes.user.attributes.type_pratique === "licence" &&
            !session.attributes.user.attributes.affiliation
          ) {
            if (
              !datasById[indexDays].licence.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[indexDays].licence.push(session.attributes.user.id);
              datas.licence[indexWeek]++;
            }
          } else if (session.attributes.user.attributes.affiliation) {
            if (
              !datasById[indexDays].licence.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[indexDays].licence.push(session.attributes.user.id);
              datas.affiliation[indexWeek]++;
            }
          }
        }
      });

      const mesDonnees = {
        labels: labels,
        datasets: [
          {
            label: this.$t("utilisateursCabinet"),
            data: datas.cabinet,
            backgroundColor: "rgba(152, 188, 206, 0.2)",
            borderColor: "rgba(152, 188, 206, 1)",
          },
          {
            label: this.$t("utilisateursLicence"),
            data: datas.licence,
            backgroundColor: "rgba(193, 66, 66, 0.2)",
            borderColor: "rgba(193, 66, 66, 1)",
          },
          {
            label: this.$t("utilisateursAffilie"),
            data: datas.affiliation,
            backgroundColor: "rgba(210, 213, 5, 0.2)",
            borderColor: "rgba(210, 213, 5, 1)",
          },
        ],
      };

      var chart = this.$refs.chart;
      var ctx = chart.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: mesDonnees,
        options: this.options,
      });
    },
    traitementDataMois(SessionsDetail) {
      const labels = [];
      const labelsDays = [];
      const datas = { cabinet: [], licence: [], affiliation: [] };
      const datasById = [];
      let currentDate = moment(this.dateDebut);
      while (currentDate <= moment(this.dateFin)) {
        labelsDays.push(currentDate.format("DD-MM-YYYY"));
        const labelFormat = currentDate.format("MM-YYYY");
        if (
          !labels.find((e) => {
            return e === labelFormat;
          })
        ) {
          labels.push(labelFormat);
          datas.cabinet.push(0);
          datas.licence.push(0);
          datas.affiliation.push(0);
        }
        datasById.push({ cabinet: [], licence: [], affiliation: [] });
        currentDate.add(1, "days");
      }

      SessionsDetail.forEach((session) => {
        const indexMonth = labels.indexOf(
          moment(session.attributes.createdAt).format("MM-YYYY")
        );
        const indexDays = labelsDays.indexOf(
          moment(session.attributes.createdAt).format("DD-MM-YYYY")
        );
        if (indexDays && indexMonth) {
          if (
            session.attributes.user.attributes.type_pratique === "cabinet" &&
            !session.attributes.user.attributes.affiliation
          ) {
            if (
              !datasById[indexDays].cabinet.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[indexDays].cabinet.push(session.attributes.user.id);
              datas.cabinet[indexMonth]++;
            }
          } else if (
            session.attributes.user.attributes.type_pratique === "licence" &&
            !session.attributes.user.attributes.affiliation
          ) {
            if (
              !datasById[indexDays].licence.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[indexDays].licence.push(session.attributes.user.id);
              datas.licence[indexMonth]++;
            }
          } else if (session.attributes.user.attributes.affiliation) {
            if (
              !datasById[indexDays].licence.find((e) => {
                return e === session.attributes.user.id;
              })
            ) {
              datasById[indexDays].licence.push(session.attributes.user.id);
              datas.affiliation[indexMonth]++;
            }
          }
        }
      });

      const mesDonnees = {
        labels: labels,
        datasets: [
          {
            label: "Utilisateurs au cabinet",
            data: datas.cabinet,
            backgroundColor: "rgba(152, 188, 206, 0.2)",
            borderColor: "rgba(152, 188, 206, 1)",
          },
          {
            label: "Utilisateurs sous licence",
            data: datas.licence,
            backgroundColor: "rgba(193, 66, 66, 0.2)",
            borderColor: "rgba(193, 66, 66, 1)",
          },
          {
            label: "Utilisateurs affiliés",
            data: datas.affiliation,
            backgroundColor: "rgba(210, 213, 5, 0.2)",
            borderColor: "rgba(210, 213, 5, 1)",
          },
        ],
      };

      var chart = this.$refs.chart;
      var ctx = chart.getContext("2d");
      new Chart(ctx, {
        type: "line",
        data: mesDonnees,
        options: this.options,
      });
    },
    getWeekNumber(uneDate) {
      var d = new Date(uneDate);
      var DoW = d.getDay();
      d.setDate(d.getDate() - ((DoW + 6) % 7) + 3); // Nearest Thu
      var ms = d.valueOf(); // GMT
      d.setMonth(0);
      d.setDate(4); // Thu in Week 1
      return Math.round((ms - d.valueOf()) / (7 * 864e5)) + 1;
    },
  },
  async mounted() {
    ParseConfig();
    await this.getSessionDetail();
  },
  watch: {
    async selectionDuree() {
      await this.getSessionDetail();
    },
    async dateDebut() {
      await this.getSessionDetail();
    },
    async dateFin() {
      await this.getSessionDetail();
    },
    async userSelected() {
      if (this.userSelected === "tous") {
        await this.getSessionDetail();
        return;
      }
      if (
        (this.userSelected.attributes.type_pratique === "licence" ||
          this.userSelected.attributes.affiliation) &&
        !this.userSelected.attributes.acces_donnees_therapeute
      ) {
        if (this.selectionDuree === "JOUR") {
          this.traitementDataJour([]);
        } else if (this.selectionDuree === "SEMAINE") {
          this.traitementDataSemaine([]);
        } else if (this.selectionDuree === "MOIS") {
          this.traitementDataMois([]);
        }
        return;
      }
      const ObjectSessionDetail = Parse.Object.extend("session_detail");
      const QuerySessionDetail = new Parse.Query(ObjectSessionDetail);
      QuerySessionDetail.lessThanOrEqualTo("createdAt", this.dateFin);
      QuerySessionDetail.greaterThanOrEqualTo("createdAt", this.dateDebut);
      QuerySessionDetail.equalTo("user", this.userSelected);
      const ResponseSessionDetail = await QuerySessionDetail.find();
      if (this.selectionDuree === "JOUR") {
        this.traitementDataJour(ResponseSessionDetail);
      } else if (this.selectionDuree === "SEMAINE") {
        this.traitementDataSemaine(ResponseSessionDetail);
      } else if (this.selectionDuree === "MOIS") {
        this.traitementDataMois(ResponseSessionDetail);
      }
    },
  },
};
</script>
