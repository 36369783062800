<template>
  <div>
    <div class="subscribe-banner">
      <div class="banner-content">
        <span class="banner-text"
          >Profitez d'un essai gratuit de 15 jours pour découvrir nos outils
          dédiés à votre pratique !</span
        >
        <button class="banner-button" @click="goToSuscribe">
          Je profite !
        </button>
      </div>
    </div>
    <div v-if="isLoading" class="loading-overlay-connexion">
      <div class="loading-content-connexion">
        <div class="loading-spinner-connexion"></div>
        <p class="loading-text-connexion">Connexion en cours...</p>
      </div>
    </div>

    <div class="main-connexion">
      <div class="connexion-content">
        <div class="column is-4 is-offset-4 is-offset-2-mobile is-8-mobile">
          <a
            class="card-content is-flex is-horizontal-center"
            href="https://symbiocenter.fr/"
            target="_blank"
          >
            <img :src="logo.src" alt="Logo Symbiofi" loading="lazy" />
          </a>
        </div>
        <div class="">
          <div class="has-text-centered"></div>
          <div
            class="column is-offset-4-desktop is-three-fifths-tablet is-offset-one-fifth-tablet is-4-desktop"
            v-if="!isMaintenance"
          >
            <!-- <button @click="generateToken">generateToken</button>
            <button @click="recupToken">recup token</button> -->

            <div class="banner-notification" v-if="!emailValidated">
              <p>Veuillez d'abord entrer votre email.</p>
            </div>
            <div
              class="banner-notification"
              v-if="emailValidated && !userHasAcceptedCGU"
            >
              <p>
                C'est votre première connexion, vous devez créer votre mot de
                passe.
              </p>
            </div>
            <div class="has-text-centered is-margin-10">
              <div class="">
                <form @submit.prevent="connexion">
                  <div class="field has-addons has-addons-right">
                    <div class="control is-expanded">
                      <input
                        class="input is-large"
                        type="email"
                        placeholder="Email"
                        v-model="email"
                        @input="verifyEmail"
                        autocomplete="email"
                      />
                    </div>
                    <!-- <div class="control">
                        <span class="icon is-large" v-if="emailLoading">
                          <i class="fas fa-spinner fa-pulse"></i>
                        </span>
                        <span
                          class="icon is-large has-text-success"
                          v-if="emailValidated && !emailLoading"
                        >
                          <i class="fas fa-check"></i>
                        </span>
                        <span
                          class="icon is-large has-text-danger"
                          v-if="errorMail && !emailLoading"
                        >
                          <i class="fas fa-times"></i>
                        </span>
                      </div> -->
                  </div>
                  <p
                    v-if="errorMail && !errorMailCabinet"
                    class="has-text-weight-light has-text-danger is-size-6"
                  >
                    Cet email n’est pas reconnu. Assurez-vous d’avoir entré un
                    email valide.
                  </p>
                  
                  <p
                    v-else-if="!errorMail && errorMailCabinet"
                    class="has-text-weight-light has-text-danger is-size-6">
                    Vous possédez un compte cabinet, veuillez vous renseigner auprès de votre professionnel.
                    </p>
                    <p v-if="errorLogIn" class="has-text-weight-light has-text-danger is-size-6">
                    Vos identifiants sont incorrects. Veuillez réessayer.
                  </p>
                  <p v-if="!userHasValidAccount" class="has-text-weight-light has-text-danger is-size-6">
                    Votre compte n'est pas encore validé, veuillez cliquer sur le lien reçu par mail.
                  </p>
                  <!-- {{errorMailCabinet + ' ' + errorMail + ' ' + errorLogIn}} -->
                  <!-- Transition animée pour l'affichage des champs de mot de passe -->
                  <transition name="fade">
                    <div>
                      <div v-if="emailValidated">
                        <!-- Si l'utilisateur n'a pas accepté les CGU, afficher deux champs pour changer le mot de passe -->
                        <div v-if="!userHasAcceptedCGU">
                          <b-field>
                            <b-input
                              type="password"
                              placeholder="Nouveau mot de passe"
                              icon-pack="fas"
                              v-model="newPassword"
                              password-reveal
                              size="is-large"
                            ></b-input>
                          </b-field>
                          <b-field>
                            <b-input
                              type="password"
                              placeholder="Confirmer le mot de passe"
                              icon-pack="fas"
                              v-model="confirmPassword"
                              password-reveal
                              size="is-large"
                            ></b-input>
                          </b-field>
                          <p
                            v-if="passwordsDoNotMatch"
                            class="has-text-weight-light has-text-danger is-size-6"
                          >
                            Les mots de passe ne correspondent pas.
                          </p>
                        </div>
                        <!-- Si les CGU sont acceptées, afficher le champ classique pour entrer le mot de passe -->
                        <div v-else>
                          <b-field>
                            <b-input
                              type="password"
                              placeholder="Mot de passe"
                              icon-pack="fas"
                              v-model="password"
                              password-reveal
                              size="is-large"
                            ></b-input>
                          </b-field>
                        </div>
                      </div>
                      <button
                        :disabled="isFormDisabled"
                        type="submit"
                        class="button is-primary is-fullwidth is-large buttonConnexion"
                      >
                        {{ $t("CONNEXION") }}
                      </button>
                    </div>
                  </transition>
                </form>
              </div>
              <p style="margin-top: 10px">
                <router-link :to="'/reinitialisation-mot-de-passe'">
                  {{ $t("motDePasseOublie") }}
                </router-link>
              </p>
            </div>
          </div>
          <!--Maintenance-->
          <div v-else>
            <div class="column is-6 is-offset-3 has-text-centered">
              <div class="box">
                <p>NOTRE SITE EST EN MAINTENANCE POUR LE MOMENT</p>
                <p>
                  mais nous mettons tout en oeuvre pour que vous le retrouviez
                  très vite.
                </p>
                <p class="title has-text-grey is-size-6 has-text-centered">
                  {{ $t("") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footerM">
        <div class="footer-connexion is-fullwidth">
          <a
            class="button is-blue is-margin-10"
            href="https://www.symbiocenter.com/assets/Mentions_legales.pdf"
            target="_blank"
            ><u>Mentions légales</u></a
          >
          <a
            class="button is-blue is-margin-10"
            href="https://www.symbiofi.com"
            target="_blank"
            ><u>Centre de formation</u></a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import Parse from "parse";
import ParseConfig from "@/api/parse.config";
import { getObjectById } from "@/services/parse/getObject.js";
import AuthService from "@/services/utilisateurs/authService.js"; // Assurez-vous du bon chemin d'import

import {resetIdPatientGlobal} from "@/services/utilisateurs/globalSettingsUser.js";

const User = createNamespacedHelpers("user");

export default {
  name: "login",
  data() {
    return {
      isMaintenance: false,
      email: "",
      password: "",
      newPassword: "", // Nouveau mot de passe pour CGU non acceptées
      confirmPassword: "", // Confirmation du nouveau mot de passe
      emailValidated: false,
      userHasAcceptedCGU: false, // Flag pour vérifier l'acceptation des CGU
      emailLoading: false,
      errorMail: false,
      passwordsDoNotMatch: false,
      logo: {
        src: require("../../../assets/img/logo_symbiofi.png"),
      },
      SessionToken: null,
      isLoading: false, // Initialement en mode chargement
      errorMailCabinet: false,
      errorLogIn: false,
      userHasValidAccount: true,
    };
  },
  computed: {
    isFormDisabled() {
      if (!this.emailValidated) return true;
      if (!this.userHasAcceptedCGU) {
        return !this.newPassword || this.newPassword !== this.confirmPassword;
      }
      return !this.password;
    },
  },
  created() {
    resetIdPatientGlobal();
    try {
      this.recupToken();
    } catch (error) {
      console.log("Erreur lors de la récupération du token", error);
    }
  },
  methods: {
    ...User.mapActions({
      actionsLogIn: "logIn",
    }),
    async generateToken() {
      AuthService.generateToken();
    },
    async recupToken() {
      try {
        var response = await AuthService.recupToken();
        console.log("Réponse:", response);
        this.SessionToken = response.tokenResponse;
        this.MailResponse = response.MailResponse;
        this.isLoading = true;
        await this.actionsLogIn({
          SessionToken: this.SessionToken,
          MailResponse: this.MailResponse,
        });
      } catch (error) {
        console.log("Erreur lors de la récupération du token", error);
      } finally {
        this.isLoading = false; // Masquer le loader lorsque le chargement est terminé
      }
    },
    async verifyEmail() {
  if (!this.email) return;

  this.emailLoading = true;
  ParseConfig();

  try {
    const User = Parse.Object.extend("User");
    const query = new Parse.Query(User);
    query.equalTo("email", this.email);

    const user = await query.first();
    if (!user) {
      this.emailValidated = false;
      this.errorMail = true;
      return;
    }

    // User found, process data
    this.emailValidated = true;
    this.userHasAcceptedCGU = user.get("cgu");
    this.idUser = user.id;

    // Check user group
    const groupQuery = new Parse.Query("user_group");
    groupQuery.equalTo("objectId", user.get("group").id); // Assume groupUserId is stored in the user object
    const group = await groupQuery.first();

    if (group) {
      this.userHasValidAccount = group.get("compte_valide");
    }

    // Check for cabinet type
    this.errorMailCabinet = user.get("type_pratique") === "cabinet";
    this.errorMail = false;

  } catch (error) {
    console.error("Error verifying email:", error);
    this.emailValidated = false;
    this.errorMail = true;
  } finally {
    this.emailLoading = false;
  }
},

    async connexion() {
      if (this.emailValidated) {
        try {
          this.isLoading = true;

          console.log("Connexion...");
          if (!this.userHasAcceptedCGU) {
            console.log("Nouveau mot de passe");
            if (this.newPassword !== this.confirmPassword) {
              this.passwordsDoNotMatch = true;
              return;
            }
            this.passwordsDoNotMatch = false;
            // Enregistrer le nouveau mot de passe et mettre à jour les CGU
            await Parse.Cloud.run("changePassword", {
              email: this.email,
              confirmPassword: this.confirmPassword,
            });
            console.log("Mot de passe mis à jour");
            await this.actionsLogIn({
              email: this.email,
              password: this.confirmPassword,
              SessionToken: this.SessionToken,
              MailResponse: this.MailResponse,
            });
            // Code pour mettre à jour le mot de passe et marquer les CGU comme acceptées...
          } else {
            await this.actionsLogIn({
              email: this.email,
              password: this.password,
              SessionToken: this.SessionToken,
              MailResponse: this.MailResponse,
            });
          }
        } catch (error) {
          // Gérer les erreurs de connexion
          console.log("Erreur de connexion", error);
          this.errorLogIn = true;
        } finally {
          console.log("Connexion terminée");
          if(Parse.User.current()){
            this.isLoading = false;
          }
          else{
            this.isLoading = false;
            this.errorLogIn = true;
          }
          
        }
      }
    },

    goToSuscribe() {
      this.$router.push("/inscription-credits");
    },
  },
};
</script>
<style scoped>
html {
  background-color: #3c3c3c;
  overflow-y: auto;
}

.boutonInscription {
  color: #fff;
}

.boutonInscription:hover {
  color: #fff;
}

.containers {
  min-height: 60vh;
  margin-bottom: -50px;
  padding-bottom: 50px;
}

.footerM {
  position: absolute;
  bottom: 0;
  width: 100%;
}

html,
body {
  height: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.app {
  flex: 1;
  height: 100%;
}
/* Style de la banderole */
.subscribe-banner {
  background-color: #239380;
  color: white;
  text-align: center;
  padding: 15px 0;
  font-size: 18px;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.banner-content {
  display: flex;
  flex-direction: row; /* Par défaut, mais bon de préciser */
  align-items: center;
  justify-content: center;
  flex-wrap: wrap; /* Permet aux éléments de passer à la ligne en dessous si nécessaire */
}

.banner-text {
  margin: 0 20px;
  font-weight: 600;
  text-align: center; /* Pour que le texte soit centré même quand il passe à la ligne */
}

.banner-button {
  background-color: white;
  color: #239380;
  padding: 10px 20px;
  cursor: pointer;
  border: 2px solid white;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  transition: all 0.3s ease;
}

.banner-button:hover {
  background-color: #f2f2f2;
  border-color: #f2f2f2;
}

/* Media queries pour la responsivité */
@media (max-width: 768px) {
  .banner-content {
    flex-direction: column; /* Les éléments s'empilent verticalement sur les petits écrans */
    align-items: center; /* Centre les éléments verticalement */
  }

  .banner-text {
    margin: 10px 0; /* Augmente l'espacement en haut et en bas pour la respiration visuelle */
  }

  .banner-button {
    margin: 10px 0; /* Augmente l'espacement en haut et en bas pour la respiration visuelle */
  }
}

.banner-notification {
  background-color: #d4ede9; /* Fond gris clair pour un aspect doux */
  color: #333; /* Texte en gris foncé pour une bonne lisibilité */
  padding: 10px 20px; /* Espacement interne confortable */
  border-left: 4px solid #239380; /* Bordure gauche pour un effet de banderole */
  border-radius: 4px; /* Coins légèrement arrondis pour un design plus doux */
  font-size: 1rem; /* Taille de texte agréable */
  text-align: center; /* Centrage du texte */
  margin-bottom: 20px; /* Espacement en bas pour séparer le message du formulaire */
  width: 100%; /* S’étend sur toute la largeur disponible */
  max-width: 800px; /* Limite la largeur maximale pour éviter que la banderole soit trop large */
  margin-left: auto;
  margin-right: auto; /* Centre la banderole horizontalement */
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Légère ombre pour ajouter de la profondeur */
}

.banner-notification p {
  margin: 0; /* Retire les marges par défaut pour un rendu compact */
  font-weight: 500; /* Légère mise en gras pour que le texte se démarque */
}

.buttonConnexion {
  margin-top: 10px;
}
/* Styles de l'overlay de chargement */
.loading-overlay-connexion {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(
    0,
    0,
    0,
    0.6
  ); /* Fond semi-transparent légèrement plus sombre */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Assure que l'overlay est au-dessus des autres éléments */
}

/* Contenu de l'overlay */
.loading-content-connexion {
  text-align: center;
  background: #fff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
  width: 300px; /* Largeur fixe pour une meilleure lisibilité */
}

/* Style du spinner de chargement */
.loading-spinner-connexion {
  border: 8px solid rgba(0, 0, 0, 0.1); /* Couleur de l'arrière-plan du spinner */
  border-left: 8px solid #239380; /* Couleur du spinner */
  border-radius: 50%;
  width: 60px; /* Taille légèrement augmentée */
  height: 60px; /* Taille légèrement augmentée */
  animation: spin 1s linear infinite;
  margin-bottom: 20px; /* Espacement plus large entre le spinner et le texte */
  position: relative;
  transform: translateZ(0); /* Optimisation des performances */
  margin-left: auto;
  margin-right: auto;
}

/* Animation du spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Style du texte de chargement */
.loading-text-connexion {
  font-size: 18px; /* Taille du texte augmentée pour une meilleure lisibilité */
  color: #333; /* Couleur du texte */
  font-weight: 500; /* Poids de la police pour plus de visibilité */
  line-height: 1.5; /* Hauteur de ligne améliorée pour une meilleure lisibilité */
}

/* Effet de fondu en entrée de l'overlay */
.loading-overlay-connexion {
  animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
