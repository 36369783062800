<template>
  <div class="is-main-content" style="padding: 5% 0 0 0 !important">
    <div class="is-fullheight accueil">
      <div class="container is-fluid">
        <h1 class="title is-size-4">{{ $t("gestionDeMonAbonnement") }}</h1>
        <div class="columns">
          <div
            class="column is-12-mobile is-8-tablet is-9-desktop is-9-widescreen"
          >
            <p class="has-text-justified is-size-6">
              {{ $t("texteInformationAbonnement1") }}
            </p>
            <br />
            <p class="title is-size-5">{{ $t("modifierMonOffre") }}</p>
            <div v-if="nbUsersInMyGroup > 1 && abonnement.type === 'gratuit'">
              <p class="has-text-centered has-text-danger">
                {{ $t("accesMenuDesactive") }}
                <br />
                {{ $t("abonnezVousPratique") }}
              </p>
              <br />
            </div>
            <div class="columns" v-if="!souscrireAbonnement">
              <div v-if="abonnement.type === 'play'">
                <div
                  class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                >
                  <div
                    class="card"
                    style="background-color: white"
                    :class="{
                      cardGrey: abonnement.type === 'play',
                      cardNoDisplay:
                        abonnement.type === 'pulse' &&
                        (group.essai_gratuit === undefined ||
                          !group.essai_gratuit),
                    }"
                  >
                    <header
                      class="card-header card-header-cyan"
                      :class="{
                        cardHeaderActif:
                          abonnement.type === 'play' ||
                          (abonnement.type === 'pulse' &&
                            (group.essai_gratuit === undefined ||
                              !group.essai_gratuit)),
                        cardHeaderDanger:
                          abonnement.type === 'play' && group.resilie,
                      }"
                    >
                      <p class="has-text-white is-size-4">
                        {{ $t("OffrePlayA") }}
                      </p>
                    </header>
                    <div class="card-image">
                      <figure class="image is-2by1">
                        <img
                          src="../../../assets/img/img_abonnement_play.png"
                          alt="Image abonnement play"
                        />
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="content has-text-centered">
                        <p>
                          {{ $t("avantagePlay1") }}
                        </p>
                        <p>
                          {{ $t("avantagePlay2") }}
                        </p>
                        <p>
                          {{ $t("avantagePlay3") }}
                        </p>
                        <p>
                          {{ $t("avantagePlay4") }}
                        </p>
                        <a
                          v-if="
                            abonnement.type === 'play' &&
                            (group.resilie === undefined ||
                              group.resilie === false) &&
                            (group.essai_gratuit === undefined ||
                              !group.essai_gratuit)
                          "
                          class="button is-medium buttonRadius buttonAbonnementModif"
                          @click="souscrireAbonnement = 'play'"
                        >
                          Modifier votre abonnement
                        </a>
                        <a
                          v-else
                          class="button is-primary is-medium buttonRadius"
                          :class="{
                            'is-grey':
                              abonnement.type === 'pulse' &&
                              (group.essai_gratuit === undefined ||
                                !group.essai_gratuit),
                          }"
                          @click="
                            if (
                              !(
                                abonnement.type === 'pulse' &&
                                (group.essai_gratuit === undefined ||
                                  !group.essai_gratuit)
                              )
                            )
                              souscrireAbonnement = 'play';
                          "
                        >
                          {{ $t("souscrire") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="abonnement.type === 'play'">
                <div
                  class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen"
                >
                  <div
                    class="card"
                    style="background-color: white"
                    :class="{
                      cardGrey:
                        abonnement.type === 'pulse' &&
                        (group.essai_gratuit === undefined ||
                          !group.essai_gratuit),
                    }"
                  >
                    <header
                      class="card-header card-header-cyan"
                      :class="{
                        cardHeaderActif:
                          abonnement.type === 'pulse' &&
                          (group.essai_gratuit === undefined ||
                            !group.essai_gratuit),
                        cardHeaderDanger:
                          abonnement.type === 'pulse' && group.resilie,
                      }"
                    >
                      <p class="has-text-white is-size-4">
                        {{ $t("OffrePulseA") }}
                        {{ (this.pulseAnnuel / 12).toFixed(2) }}€/mois ttc
                      </p>
                    </header>
                    <div class="card-image">
                      <figure class="image is-2by1" style="display: flex">
                        <img
                          style="
                            max-width: 600px;
                            max-height: 350px;
                            margin-left: auto;
                            margin-right: auto;
                          "
                          src="../../../assets/img/device_symbiocenter.png"
                          alt="Image abonnement pulse"
                        />
                      </figure>
                    </div>
                    <div class="card-content">
                      <div class="content has-text-centered">
                        <div class="abonnement-avantages">
                          <p class="avantage">{{ $t("avantagePulse1") }}</p>
                          <span class="plus-sign">+</span>
                          <p class="avantage">{{ $t("avantagePulse2") }}</p>
                        </div>
                        <a
                          v-if="
                            abonnement.type === 'pulse' &&
                            (group.resilie === undefined ||
                              group.resilie === false) &&
                            (group.essai_gratuit === undefined ||
                              !group.essai_gratuit)
                          "
                          class="button  is-medium buttonRadius buttonAbonnementModif"
                          @click="souscrireAbonnement = 'pulse'"
                        >
                        Modifier votre abonnement                        </a>
                        <p
                          v-else-if="
                            abonnement.type === 'pulse' && group.resilie
                          "
                          class="has-text-danger"
                        >
                          {{ $t("vousAvezResilie") }}
                        </p>
                        <a
                          v-else
                          class="button is-primary is-medium buttonRadius"
                          @click="souscrireAbonnement = 'pulse'"
                        >
                          {{ $t("souscrire") }}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen"
                v-if="
                  abonnement.type === 'pulse' &&
                  (group.essai_gratuit === undefined || !group.essai_gratuit)
                "
              >
                <div
                  class="card"
                  style="background-color: white"
                  :class="{
                    cardGrey:
                      abonnement.type === 'pulse' &&
                      (group.essai_gratuit === undefined ||
                        !group.essai_gratuit),
                  }"
                >
                  <header
                    class="card-header card-header-cyan"
                    :class="{
                      cardHeaderActif:
                        abonnement.type === 'pulse' &&
                        (group.essai_gratuit === undefined ||
                          !group.essai_gratuit),
                      cardHeaderDanger:
                        abonnement.type === 'pulse' && group.resilie,
                    }"
                  >
                    <p class="has-text-white is-size-4">
                      {{ $t("OffrePulseA") }}
                      {{ (this.pulseAnnuel / 12).toFixed(2) }}€/mois ttc
                    </p>
                  </header>
                  <div class="card-image">
                    <figure class="image is-2by1" style="display: flex">
                      <img
                        style="
                          max-width: 600px;
                          max-height: 400px;
                          margin-left: auto;
                          margin-right: auto;
                        "
                        src="../../../assets/img/device_symbiocenter.png"
                        alt="Image abonnement pulse"
                      />
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="content has-text-centered">
                      <!-- <button @click="testUser(group, abonnement)">Test</button> -->
                      <div
                        class="subscription-info"
                        v-if="!group.date_paiement"
                      >
                        <p class="days-remaining">
                          Il vous reste
                          <span class="highlight">{{ daysRemaining }}</span>
                          jours d'essai
                        </p>
                      </div>
                      <div class="abonnement-avantages">
                        <p class="avantage">{{ $t("avantagePulse1") }}</p>
                        <span class="plus-sign">+</span>
                        <p class="avantage">{{ $t("avantagePulse2") }}</p>
                      </div>
                      <a
                        v-if="
                          abonnement.type === 'pulse' &&
                          (group.resilie === undefined  ||
                            group.resilie === false) &&
                          (group.essai_gratuit === undefined   ||
                            !group.essai_gratuit) && group.date_paiement
                        "
                        class="button is-medium buttonRadius buttonAbonnementModif"
                        @click="souscrireAbonnement = 'pulse'"
                      >
                      Modifier votre abonnement
                      </a>
                      <p
                        v-else-if="abonnement.type === 'pulse' && group.resilie"
                        class="has-text-danger"
                      >
                        {{ $t("vousAvezResilie") }}
                      </p>
                      <a
                        v-else
                        class="button is-primary is-medium buttonRadius"
                        @click="souscrireAbonnement = 'pulse'"
                      >
                        {{ $t("souscrire") + " à l'abonnement"}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen"
                v-if="abonnement.type === 'pulse' && group.essai_gratuit"
              >
                <div
                  class="card"
                  style="background-color: white"
                  :class="{
                    cardGrey:
                      abonnement.type === 'pulse' &&
                      (group.essai_gratuit === undefined ||
                        !group.essai_gratuit),
                  }"
                >
                  <header
                    class="card-header card-header-cyan"
                    :class="{
                      cardHeaderActif:
                        abonnement.type === 'pulse' &&
                        (group.essai_gratuit === undefined ||
                          !group.essai_gratuit),
                      cardHeaderDanger:
                        abonnement.type === 'pulse' && group.resilie,
                    }"
                  >
                    <p class="has-text-white is-size-4">
                      {{ $t("OffrePulseA") }}
                      {{ (this.pulseAnnuel / 12).toFixed(2) }}€/mois ttc
                    </p>
                  </header>
                  <div class="card-image">
                    <figure class="image is-2by1" style="display: flex">
                      <img
                        style="
                          max-width: 600px;
                          max-height: 400px;
                          margin-left: auto;
                          margin-right: auto;
                        "
                        src="../../../assets/img/device_symbiocenter.png"
                        alt="Image abonnement pulse"
                      />
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="content has-text-centered">
                      <div class="abonnement-avantages">
                        <p class="avantage">{{ $t("avantagePulse1") }}</p>
                        <span class="plus-sign">+</span>
                        <p class="avantage">{{ $t("avantagePulse2") }}</p>
                      </div>
                      <a
                        v-if="
                          abonnement.type === 'pulse' &&
                          (group.resilie === undefined ||
                            group.resilie === false) &&
                          (group.essai_gratuit === undefined ||
                            !group.essai_gratuit)
                        "
                        class="button is-warning is-medium buttonRadius"
                        @click="souscrireAbonnement = 'pulse'"
                      >
                        {{ $t("modifier") }}
                        
                      </a>
                      <p
                        v-else-if="abonnement.type === 'pulse' && group.resilie"
                        class="has-text-danger"
                      >
                        {{ $t("vousAvezResilie") }}
                      </p>
                      <a
                        v-else
                        class="button is-primary is-medium buttonRadius"
                        @click="souscrireAbonnement = 'pulse'"
                      >
                        {{ $t("souscrire") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="column is-12-mobile is-12-tablet is-6-desktop is-6-widescreen"
                v-if="abonnement.type === 'gratuit'"
              >
                <div
                  class="card"
                  style="background-color: white"
                  :class="{
                    cardGrey:
                      abonnement.type === 'pulse' &&
                      (group.essai_gratuit === undefined ||
                        !group.essai_gratuit),
                  }"
                >
                  <header
                    class="card-header card-header-cyan"
                    :class="{
                      cardHeaderActif:
                        abonnement.type === 'pulse' &&
                        (group.essai_gratuit === undefined ||
                          !group.essai_gratuit),
                      cardHeaderDanger:
                        abonnement.type === 'pulse' && group.resilie,
                    }"
                  >
                    <p class="has-text-white is-size-4">
                      {{ $t("OffrePulseA") }}
                      {{ (this.pulseAnnuel / 12).toFixed(2) }}€/mois ttc
                    </p>
                  </header>
                  <div class="card-image">
                    <figure class="image is-2by1" style="display: flex">
                      <img
                        style="
                          max-width: 600px;
                          max-height: 400px;
                          margin-left: auto;
                          margin-right: auto;
                        "
                        src="../../../assets/img/device_symbiocenter.png"
                        alt="Image abonnement pulse"
                      />
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="content has-text-centered">
                      <div class="abonnement-avantages">
                        <p class="avantage">{{ $t("avantagePulse1") }}</p>
                        <span class="plus-sign">+</span>
                        <p class="avantage">{{ $t("avantagePulse2") }}</p>
                      </div>

                      <a
                        v-if="
                          abonnement.type === 'pulse' &&
                          (group.resilie === undefined ||
                            group.resilie === false) &&
                          (group.essai_gratuit === undefined ||
                            !group.essai_gratuit)
                        "
                        class="button is-warning is-medium buttonRadius"
                        @click="souscrireAbonnement = 'pulse'"
                      >
                        {{ $t("modifier") }}
                      </a>
                      <p
                        v-else-if="abonnement.type === 'pulse' && group.resilie"
                        class="has-text-danger"
                      >
                        {{ $t("vousAvezResilie") }}
                      </p>
                      <a
                        v-else
                        class="button is-primary is-medium buttonRadius"
                        @click="souscrireAbonnement = 'pulse'"
                      >
                        {{ $t("souscrire") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="souscrireAbonnement === 'play'">
              <offre-play-component @retour="souscrireAbonnement = null" />
            </div>

            <div v-if="souscrireAbonnement === 'pulse'">
              <offre-pulse-component @retour="souscrireAbonnement = null" />
            </div>
          </div>
          <!--( zone laterale droite 3 blocs )-->
          <div
            class="column is-12-mobile is-4-tablet is-3-desktop is-3-widescreen"
          >
            <div
              class="box has-text-centered box-information box-information-utilisateurs"
              :class="{ boxInformationDanger: group.resilie }"
            >
              <p class="has-text-weight-normal is-size-2 box-titre">
                {{ $t("offre") }}
                <span v-if="abonnement.type === 'play'">{{ $t("play") }}</span>
                <span v-else-if="abonnement.type === 'pulse'">
                  {{ $t("pulse") }}
                </span>
                <span v-else>{{ $t("gratuite") }}</span>
              </p>
              <p
                v-if="abonnement.type === 'play' || abonnement.type === 'pulse'"
              >
                <span v-if="!group.resilie">
                  <span
                    v-if="
                      group.essai_gratuit == undefined || !group.essai_gratuit
                    "
                  >
                    {{ $t("dateRenouvellement") }} :
                  </span>
                  <span v-else>{{ $t("finPeriodeEssai") }} :</span>
                </span>
                <span v-else>{{ $t("termineLe") }} :</span>
              </p>
              <p v-else>{{ $t("creditsDisponibles") }} :</p>
              <p
                class="button is-warning buttonRadius is-margin-b-10"
                v-if="
                  (abonnement.type === 'play' || abonnement.type === 'pulse') &&
                  dateRenouvellement &&
                  !group.resilie
                "
              >
                {{
                  dateRenouvellement === "période d'essai"
                    ? dateRenouvellement
                    : new Date(dateRenouvellement).toLocaleDateString()
                }}
              </p>
              <p
                class="button is-danger buttonRadius is-margin-b-10"
                v-else-if="
                  (abonnement.type === 'play' || abonnement.type === 'pulse') &&
                  dateRenouvellement &&
                  group.resilie
                "
              >
                {{
                  dateRenouvellement === "période d'essai"
                    ? dateRenouvellement
                    : new Date(dateRenouvellement).toLocaleDateString()
                }}
              </p>
            </div>
            <div
              class="box has-text-centered box-information"
              :class="{
                disabled: !payingSubscription,
                boxInformationUtilisateurs: payingSubscription,
              }"
            >
              <div class="is-margin-b-10">
                <div :class="{ disabledText: !payingSubscription }">
                  <p class="has-text-weight-semibold is-size-2 box-titre">
                    {{ nbUsersInMyGroupWithActiveAccess }} /
                    <span v-if="group.nb_users">{{ group.nb_users }}</span>
                    <span v-else> {{ group.credits }} </span>
                  </p>
                  <p v-if="group.nb_users">{{ $t("accesActif") }}</p>
                  <p v-else>{{ $t("creditsInfo") }}</p>
                  <a
                    v-if="nbUsersInMyGroupWithActiveAccess >= group.nb_users"
                    class="button is-primary buttonRadius is-margin-b-10"
                  >
                    {{ $t("acheter") }}&nbsp;&nbsp;
                    <b-icon pack="fas" icon="plus" size="is-small"></b-icon>
                  </a>
                </div>
              </div>
              <div v-if="!payingSubscription">
                <p class="has-text-white">
                  {{ $t("texteInformationAbonnement2") }}
                </p>
              </div>
            </div>

            <div
              class="box has-text-centered box-information"
              :class="{
                disabled: !payingSubscription || abonnement.type !== 'pulse',
                boxInformationUtilisateurs: abonnement.type === 'pulse',
              }"
            >
              <div class="is-margin-b-10">
                <div
                  :class="{
                    disabledText:
                      !payingSubscription || abonnement.type !== 'pulse',
                  }"
                >
                  <figure class="image is-2by1 is-margin-b-10">
                    <img
                      src="../../../assets/img/capteur_kyto_resize.png"
                      alt="Image capteur"
                    />
                  </figure>
                  <p class="is-size-6">{{ $t("vousDevezPosseder") }}</p>
                  <p class="is-size-4">{{ $t("capteur") }}</p>
                  <p class="is-size-6"></p>
                  <div class="columns is-multiline has-text-centered">
                    <div class="column">
                      <a
                        class="button is-primary buttonRadius"
                        target="_blank"
                        v-if="
                          !payingSubscription || abonnement.type !== 'pulse'
                        "
                      >
                        {{ $t("acheter") }}
                      </a>
                      <a
                        class="button is-primary buttonRadius is-margin-t-10"
                        href="https://www.symbiocenter.fr/capteurs-de-pouls-2/"
                        target="_blank"
                        v-else
                      >
                        {{ $t("acheter") }}
                      </a>
                    </div>
                    <div class="column">
                      <a
                        class="button is-primary buttonRadius"
                        target="_blank"
                        v-if="
                          !payingSubscription || abonnement.type !== 'pulse'
                        "
                      >
                        {{ $t("telecharger") }}
                      </a>
                      <a
                        class="button is-primary buttonRadius is-margin-t-10"
                        :href="lienDownload"
                        target="_blank"
                        v-else
                        style="font-size: 100%"
                        @click="getIOS"
                      >
                        {{ $t("Téléchargement du logiciel") }}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!payingSubscription || abonnement.type !== 'pulse'">
                <p class="has-text-white">
                  {{ $t("texteInformationAbonnement3") }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="is-size-5">Liste de mes factures</div>
          <b-table
            v-if="factures.length"
            :data="factures"
            :bordered="true"
            :narrowed="true"
            :striped="true"
            class="is-margin-b-50"
          >
            <template slot-scope="props" slot="header">
              <div>{{ props.column.label }}</div>
            </template>

            <template slot-scope="props">
              <b-table-column
                field="Reference Facture"
                label="Référence facture"
              >
                {{ props.row.reference_facture }}
              </b-table-column>
              <b-table-column field="Date de paiement" label="Date de paiement">
                {{ props.row.date_abonnement }}
              </b-table-column>
              <b-table-column field="Type abonnement" label="Type abonnement">
                {{ props.row.type_abonnement }}
              </b-table-column>
              <b-table-column field="Engagement" label="Engagement">
                {{ props.row.engagement }}
              </b-table-column>
              <b-table-column field="Engagement" label="Facture">
                <a class="has-text-black" @click="print(props.row)">
                  <b-icon pack="fa" icon="print"></b-icon>
                </a>
              </b-table-column>
            </template>
          </b-table>
          <div v-else>Aucune facture enregistrée</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import jsPDF from "jspdf";
import Parse from "parse";
import { createNamespacedHelpers } from "vuex";
import moment from "moment";
import ParseConfig from "@/api/parse.config.js";
import hasPayingSubscription from "@/services/userGroup/subscriptionType.js";
import OffrePlay from "./abonnement/OffrePlay.vue";
import OffrePulse from "./abonnement/OffrePulse.vue";
import router from "@/router";
import getDateRenouvellement from "@/services/abonnement/getDateRenouvellement.js";
import { sortInvArrayByParam } from "@/services/general/sortArrayByParam.js";
import asyncForEach from "@/services/general/asyncForEach.js";

const User = createNamespacedHelpers("user");

export default {
  name: "abonnement",
  components: {
    OffrePlayComponent: OffrePlay,
    OffrePulseComponent: OffrePulse,
  },
  data() {
    return {
      dateRenouvellement: null,
      nbUsersInMyGroupWithActiveAccess: 0,
      nbUsersInMyGroup: [],
      payingSubscription: false,
      souscrireAbonnement: null,
      os: null,
      factures: [],
      playMensuel: 0,
      playAnnuel: 0,
      playEconomie: "",
      pulseMensuel: 0,
      pulseAnnuel: 0,
      pulseEconomie: "",
      lienDownload: "",
      exeSymbiosensor: "",
      useronline: "",
    };
  },
  computed: {
    ...User.mapGetters(["group", "abonnement", "abonnementTemp", "user"]),
    daysRemaining() {
      if (!this.group.subscription_end_date) {
        return "N/A"; // Retourne 'N/A' si la date de fin de l'abonnement n'est pas définie
      }

      const today = new Date();
      const endDate = new Date(this.group.subscription_end_date);
      const timeDiff = endDate - today;
      const days = Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // Convertit le temps restant en jours

      return days > 0 ? days : 0; // Retourne 0 si les jours restants sont négatifs (abonnement expiré)
    },
  },
  methods: {
    ...User.mapActions({
      actionsUpdateUserGroup: "updateUserGroup",
      actionsLogOut: "logOut",
    }),
    async getIOS() {
      this.getOS();
      ParseConfig();
      var querytest = new Parse.Query("_User");
      querytest.equalTo("objectId", this.useronline);
      var resultattest = await querytest.first();
      if (this.os === "Windows") {
        resultattest.set("isWindows", true);
        Parse.masterKey = "myMasterKey";
        await resultattest.save({}, { useMasterKey: true });
      } else {
        resultattest.set("isApple", true);
        Parse.masterKey = "myMasterKey";
        await resultattest.save({}, { useMasterKey: true });
      }
    },
    async getMyUsers() {
      ParseConfig();
      const UserGroupObject = Parse.Object.extend("user_group");
      const UserGroupTemporary = new UserGroupObject();
      UserGroupTemporary.id = this.group.objectId;
      const UserObject = Parse.Object.extend("User");
      const UserQuery = new Parse.Query(UserObject);
      UserQuery.equalTo("group", UserGroupTemporary);
      const UsersInMyGroup = await UserQuery.find();
      UsersInMyGroup.forEach((user) => {
        if (
          moment().toDate() <
            moment(user.attributes.fin_acces_plateforme).toDate() &&
          user.attributes.fin_acces_plateforme
        ) {
          this.nbUsersInMyGroupWithActiveAccess++;
        }
      });
      this.nbUsersInMyGroup = UsersInMyGroup.length;
    },
    testUser(group, abonnement) {
      console.log(group);
      console.log(abonnement);
    },
    getDateRenouvellement() {
      this.dateRenouvellement = getDateRenouvellement(this.group);
    },
    async loadFactures() {
      ParseConfig();
      this.factures = [];
      try {
        var QueryGroup = new Parse.Query(Parse.Object.extend("user_group"));
        QueryGroup.equalTo("objectId", this.group.objectId);
        var ResponseGroup = await QueryGroup.first();
        var QueryFacture = new Parse.Query(Parse.Object.extend("facture"));
        QueryFacture.equalTo("group", ResponseGroup);
        var ResponseFacture = await QueryFacture.find();
        await asyncForEach(ResponseFacture, async (e) => {
          if (e.attributes.valide) {
            var facture = {
              reference_facture: e.attributes.reference_facture,
              type_abonnement: e.attributes.type_abonnement,
              date_abonnement: moment(e.createdAt).format("DD/MM/YYYY"),
              engagement: e.attributes.avec_engagement
                ? "Annuel"
                : "Trimestriel",
              credit: e.attributes.credit,
              date: e.createdAt,
              facture: e.attributes.facture,
            };
            this.factures.push(facture);
          }
        });
        this.factures = sortInvArrayByParam(this.factures, "date");
      } catch {
        return;
      }
    },
    print(facture) {
      if (facture.facture !== undefined) {
        this.download(facture.facture._url, "facture.pdf");
      } else {
        this.generationFacture(facture);
      }
    },
    download(url, filename) {
      fetch(url).then(function (t) {
        return t.blob().then((b) => {
          var a = document.createElement("a");
          a.href = URL.createObjectURL(b);
          a.setAttribute("download", filename);
          a.click();
        });
      });
    },
    async generationFacture(facture) {
      var queryFactureModele = new Parse.Query(Parse.Object.extend("facture"));
      queryFactureModele.equalTo("type_abonnement", "modele"); // Permet de récupérer l'image de fond de la facture
      var modeleFacture = await queryFactureModele.first();

      this.toDataURL(
        facture,
        modeleFacture.attributes.facture._url,
        await Parse.User.current(),
        async function (maFacture, dataUrl, currentUser) {
          var queryFacture = new Parse.Query(Parse.Object.extend("facture"));
          queryFacture.equalTo(
            "reference_facture",
            maFacture.reference_facture
          );
          var facture = await queryFacture.first();

          var doc = new jsPDF({ compress: true });
          var img = new Image();
          img.src = dataUrl;
          doc.addImage(img, "JPEG", 0, 0, 210, 297, undefined, "FAST");
          doc.setFontSize(9);
          var Reference = facture.attributes.avec_engagement
            ? "Annuel"
            : "Trimestriel";
          // Références (21 caractères max);
          doc.text(
            8,
            107,
            facture.attributes.type_abonnement + "_" + Reference
          );
          // Désignation (60 caractères max sur une ligne)
          doc.text(40, 107, "Offre " + facture.attributes.type_abonnement);
          // Quantité
          doc.text(147, 107, "1");
          // PU HT
          // eslint-disable-next-line
          doc.text(
            158,
            107,
            (
              Math.round((parseFloat(facture.attributes.montant) / 1.2) * 100) /
              100
            ).toString() + " EUR"
          );
          // Montant HT
          // eslint-disable-next-line
          doc.text(
            183,
            107,
            (
              Math.round((parseFloat(facture.attributes.montant) / 1.2) * 100) /
              100
            ).toString() + " EUR"
          );
          // Total HT
          // eslint-disable-next-line
          doc.text(
            183,
            149,
            (
              Math.round((parseFloat(facture.attributes.montant) / 1.2) * 100) /
              100
            ).toString() + " EUR"
          );
          // TVA 20%
          // eslint-disable-next-line
          doc.text(
            183,
            154,
            (
              Math.round(
                (parseFloat(facture.attributes.montant) -
                  parseFloat(facture.attributes.montant) / 1.2) *
                  100
              ) / 100
            ).toString() + " EUR"
          );
          // Total TTC
          // eslint-disable-next-line
          doc.text(
            183,
            159,
            parseFloat(facture.attributes.montant).toString() + " EUR"
          );
          // Montant réglé
          // eslint-disable-next-line
          doc.text(
            183,
            166,
            parseFloat(facture.attributes.montant).toString() + " EUR"
          );
          // Net à payer
          // eslint-disable-next-line
          doc.text(
            183,
            171,
            parseFloat(facture.attributes.montant).toString() + " EUR"
          );
          // Référence Facture
          doc.text(178, 28, facture.attributes.reference_facture);
          // Date
          doc.text(178, 33, moment(facture.createdAt).format("DD/MM/YYYY"));
          // Mode réglement
          doc.text(178, 38, "Carte bancaire");
          // N° Affiliation
          await facture.attributes.group.fetch();
          doc.text(
            178,
            43,
            facture.attributes.group.attributes.code_affiliation
          );

          // Référence Commande
          doc.text(107, 195.5, facture.attributes.reference_paiement);

          // Profession
          // eslint-disable-next-line

          if (currentUser.attributes.profession)
            doc.text(5, 65, currentUser.attributes.profession);
          // Nom / Prénom
          // eslint-disable-next-line
          if (
            currentUser.attributes.lastname &&
            currentUser.attributes.firstname
          )
            doc.text(
              5,
              70,
              currentUser.attributes.lastname +
                " " +
                currentUser.attributes.firstname
            );
          // Adresse de facturation
          // eslint-disable-next-line
          if (currentUser.attributes.adresse)
            doc.text(5, 75, currentUser.attributes.adresse);
          // Complement Adresse
          if (currentUser.attributes.complement_adresse) {
            // eslint-disable-next-line
            doc.text(5, 80, currentUser.attributes.complement_adresse);
            // eslint-disable-next-line
            doc.text(
              5,
              85,
              currentUser.attributes.code_postal +
                " - " +
                currentUser.attributes.ville
            );
          } else {
            // Code Postal - Ville
            // eslint-disable-next-line
            doc.text(
              5,
              80,
              currentUser.attributes.code_postal +
                " - " +
                currentUser.attributes.ville
            );
          }

          doc.setFontSize(7);
          doc.text(45, 111, "Cet abonnement inclut :");
          // eslint-disable-next-line
          doc.text(
            50,
            114,
            "- Accès aux protocoles experts avec questionnaires d’évaluations"
          );
          // eslint-disable-next-line
          doc.text(
            50,
            117,
            "- 60 mois d'accés à ventiler auprès de vos utilisateurs sous la forme de crédits"
          );
          // eslint-disable-next-line

          // Phrase Renouvellement 1
          // eslint-disable-next-line
          doc.text(
            45,
            126,
            "L'abonnement sera renouvelé automatiquement chaque " +
              (facture.attributes.avec_engagement ? "année." : "trimestre.")
          );
          // Phrase Renouvellement 2
          // eslint-disable-next-line
          doc.text(
            45,
            129,
            "(annulation du renouvellement possible jusqu’à un jour ouvré avant l'échéance)."
          );
          // Phrase Renouvellement 3
          // eslint-disable-next-line

          var dateRenouvellement = null;
          if (facture.attributes.group.attributes.date_paiement !== undefined) {
            if (facture.attributes.avec_engagement) {
              var dateRenouvellement = moment(
                facture.attributes.group.attributes.date_paiement
              )
                .add(1, "years")
                .format("DD/MM/YYYY");
            } else {
              var dateRenouvellement = moment(
                facture.attributes.group.attributes.date_paiement
              )
                .add(3, "months")
                .format("DD/MM/YYYY");
            }
          } else {
            if (facture.attributes.avec_engagement) {
              var dateRenouvellement = moment(
                facture.attributes.group.attributes.date_paiement_perso
              )
                .add(1, "years")
                .format("DD/MM/YYYY");
            } else {
              var dateRenouvellement = moment(
                facture.attributes.group.attributes.date_paiement_perso
              )
                .add(3, "months")
                .format("DD/MM/YYYY");
            }
          }

          doc.text(45, 132, "Prochain renouvellement le " + dateRenouvellement);
          var file = doc.output("datauristring");

          //return;
          try {
            var parseFile = new Parse.File("facture.pdf", {
              base64: file.split("base64,")[1],
            });
            var fileSave = await parseFile.save();

            facture.set("facture", fileSave);

            await facture.save();

            fetch(facture.attributes.facture._url).then(function (t) {
              return t.blob().then((b) => {
                var a = document.createElement("a");
                a.href = URL.createObjectURL(b);
                a.setAttribute("download", "facture.pdf");
                a.click();
              });
            });
          } catch {
            return;
          }
        }
      );
    },
    toDataURL(facture, url, currentUser, callback) {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          callback(facture, reader.result, currentUser);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.send();
    },
    async chargeParametre() {
      var queryAppParam = new Parse.Query(Parse.Object.extend("app_param"));
      var appParam = await queryAppParam.first();
      this.playMensuel = appParam.attributes.playMensuel;
      this.playAnnuel = appParam.attributes.playAnnuel;
      this.pulseMensuel = appParam.attributes.pulseMensuel;
      this.pulseAnnuel = appParam.attributes.pulseAnnuel;
      this.playEconomie = appParam.attributes.playEconomie;
      this.pulseEconomie = appParam.attributes.pulseEconomie;
    },
    getOS() {
      var platform = window.navigator.platform,
        macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
        windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
        iosPlatforms = ["iPhone", "iPad", "iPod"];
      if (macosPlatforms.indexOf(platform) !== -1) {
        this.os = "Macosx";
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        this.os = "Ios";
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        this.os = "Windows";
      }

      return this.os;
    },
  },
  async mounted() {
    ParseConfig();
    this.useronline = await Parse.User.current().id;

    var querytest = new Parse.Query("_User");
    querytest.equalTo("objectId", this.useronline);
    var resultattest = await querytest.find();
    this.exeSymbiosensor = resultattest[0].attributes.exeSymbiosensor;

    try {
      // this.getOS()
      var query = new Parse.Query("app_desktop");
      var resultat = await query.find();
      this.getOS();
      if (this.exeSymbiosensor == false || this.exeSymbiosensor == undefined) {
        if (this.os == "Macosx") {
          this.lienDownload = resultat[1].attributes.chemin;
        } else {
          this.lienDownload = resultat[0].attributes.chemin;
        }
      } else {
        this.lienDownload =
          "https://www.symbiocenter.com/assets/symbiosensor/Symbiofi-Installeur-3.2.exe";
      }
    } catch (error) {
      console.error(error);
      //await this.actionsLogOut();
      //router.go("/connexion");
      return;
    }
    await this.chargeParametre();
    await this.loadFactures();
    // await this.getMyUsers();
    this.payingSubscription = hasPayingSubscription(this.abonnement);
    if (this.group.essai_gratuit !== undefined && this.group.essai_gratuit)
      this.payingSubscription = true;
    if (this.$route.query.validationpaiement) {
      await this.actionsUpdateUserGroup();
      if (moment() <= moment(this.group.date_paiement).add(1, "hours")) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "Paiement effectué avec succès.",
          type: "is-success",
        });
      }
      if (this.factures.length >= 2) {
        if (
          this.abonnement.type === "pulse" &&
          this.factures[1].type_abonnement === "play"
        ) {
          var user = await Parse.User.current();
          Parse.Cloud.run("passagePlayVersPulse", {
            utilisateur: user.attributes.username,
          });
        }
      }
      router.push("/abonnement");
    } else if (this.$route.query.retour) {
      router.push("/abonnement");
      this.$buefy.toast.open({
        duration: 3000,
        message: "Êchec du paiement.",
        type: "is-danger",
      });
    }
    this.getDateRenouvellement();
  },
  watch: {
    abonnement() {
      this.payingSubscription = hasPayingSubscription(this.abonnement);
      if (this.group.essai_gratuit !== undefined && this.group.essai_gratuit)
        this.payingSubscription = true;
    },
  },
};
</script>

<style scoped>
.abonnement-avantages {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem; /* Taille de police de base, ajustez si nécessaire */
  color: #333; /* Couleur du texte, ajustez pour correspondre à votre thème */
  margin: 20px 0; /* Marges autour du conteneur */
  padding: 10px; /* Espacement interne */
  background-color: #f9f9f9; /* Couleur de fond, ajustez pour correspondre à votre thème */
  border-radius: 8px; /* Coins arrondis pour un look moderne */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Ombre légère pour l'effet de profondeur */
}

.avantage {
  margin: 0;
  padding: 0 10px; /* Espacement horizontal autour des avantages */
}

.plus-sign {
  font-size: 1.5rem; /* Taille de la police pour le signe plus */
  color: #007bff; /* Couleur du signe plus */
  font-weight: bold; /* Gras pour le signe plus */
  margin: 0 15px; /* Espacement horizontal autour du signe plus */
}

.abonnement-avantages p {
  margin: 0;
  padding: 0;
}

.abonnement-avantages p:first-of-type {
  font-weight: bold; /* Texte en gras pour le premier avantage */
}

.abonnement-avantages p:last-of-type {
  font-style: italic; /* Texte en italique pour le second avantage */
}

.subscription-info {
  background-color: #eaf4fc; /* Couleur de fond douce pour attirer l'attention sans être trop agressif */
  border-left: 4px solid #007bff; /* Bordure colorée à gauche pour un effet de mise en avant */
  padding: 15px 20px; /* Espacement interne pour un meilleur rendu visuel */
  border-radius: 8px; /* Coins arrondis pour un design moderne */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Ombre légère pour ajouter de la profondeur */
  font-family: Arial, sans-serif; /* Police lisible et moderne */
  margin: 20px 0; /* Espacement autour du conteneur */
}

.days-remaining {
  font-size: 1.125rem; /* Taille de la police légèrement augmentée pour la lisibilité */
  color: #333; /* Couleur du texte principale pour une bonne lisibilité */
  margin: 0; /* Supprime les marges par défaut */
}

.highlight {
  font-weight: bold; /* Texte en gras pour mettre en évidence le nombre de jours restants */
  color: #d9534f; /* Couleur accentuée pour attirer l'attention sur le nombre de jours restants */
  font-size: 1.25rem; /* Taille de la police augmentée pour le nombre de jours restants */
}

.card-content
{
  padding:1rem !important;
}

.buttonAbonnementModif
{
  background-color: #7957d5 !important;
  color: white !important;
}

.buttonAbonnementModif:hover
{
  background-color: #5d3eb3 !important;
  
  

}


</style>
