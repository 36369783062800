<template>
  <div class="container-user">
    <!-- <div class="buttons-div">
        <div class="buttons buttonsRight">
          <b-button type="is-danger" @click="annuler">Annuler</b-button>
        </div>
        <div class="buttons buttonsLeft">
          <b-button
            v-if="isLoadingCreateUser"
            type="is-success"
            class="button-with-loader"
          >
            <div class="loader"></div>
          </b-button>
          <b-button v-else-if="step === 2" type="is-success" @click="valider"
            >Validez le compte</b-button
          >
          <b-button v-else type="is-success" @click="nextStep"
            >Suivant</b-button
          >
        </div>
      </div>  -->
    <div class="user-form-container">
      <button class="close-btn" @click="closeForm">
        <i class="fas fa-times"></i>
      </button>
      <div class="progress-bar" :class="{ active: step === 2 }">
        <div class="step active">Étape 1</div>
        <div :class="{ step: true, active: step === 2, passed: step < 2 }">
          Étape 2
        </div>
      </div>
      <h2 v-if="step === 1" class="title titleAjout">
        Créez un patient/client
      </h2>

      <div class="field-group" v-if="step === 1">
        <div class="field-left">
          <b-field label="Nom" class="field">
            <b-input
              class="InputName"
              v-model="userData.lastname"
              placeholder="Entrez le nom"
            ></b-input>
          </b-field>
        </div>
        <div class="field-right">
          <b-field label="Prénom" class="field">
            <b-input
              class="InputName"
              v-model="userData.firstname"
              placeholder="Entrez le prénom"
            ></b-input>
          </b-field>
        </div>
      </div>
      <div class="field-group" v-if="step === 1">
        <div class="field-left">
          <span class="titlePratique">Choissisez le type de pratique :</span
          ><br />
          <span class="infoPratique"
            ><i class="fas fa-info-circle"></i> En séance = le patient/client
            pratique avec vous en séance au cabinet ; À distance = le
            patient/client peut pratiquer de n'importe où</span
          ><br />
          <div class="divRadioPratique">
            <!-- <b-switch
              v-model="selectedState"
              true-value="cabinet"
              false-value="licence"
            >
              <p>
                <span :class="{ 'active-state': selectedState === 'licence' }"
                  >À distance</span
                ><span> / </span>
                <span :class="{ 'active-state': selectedState === 'cabinet' }">
                  En séance</span
                >
              </p>
            </b-switch> -->
            <div class="divRadio">
              <input
                style="margin-left: 0 !important"
                type="radio"
                id="seance"
                name="pratique"
                value="cabinet"
                v-model="selectedState"
              />
              <label style="margin-left: 1rem !important" for="cabinet"
                >En séance</label
              >
            </div>
            <div class="divRadio">
              <input
                style="margin-left: 0 !important"
                type="radio"
                id="distance"
                name="pratique"
                value="licence"
                v-model="selectedState"
              />
              <label style="margin-left: 1rem !important" for="licence"
                >À distance</label
              >
            </div>
          </div>
        </div>
      </div>
      <div class="field-group" v-if="selectedState === 'licence' && step === 1">
        <div class="field-left">
          <b-field label="Email" class="field">
            <b-input
              class="InputName"
              v-model="userData.mail"
              placeholder="Entrez le mail"
              @input="toLowerCase('mail')"
            ></b-input>
          </b-field>
        </div>
        <div class="field-right">
          <b-field label="Email" class="field">
            <b-input
              class="InputName"
              v-model="userData.mailConfirmation"
              placeholder="Confirmer le mail"
              @input="toLowerCase('mailConfirmation')"
            ></b-input>
          </b-field>
        </div>
      </div>

      <div class="field-group" v-if="selectedState === 'licence' && step === 1">
        <div class="field-left">
          <b-field
            label="Durée d'accès distanciel à la plateforme"
            class="field"
          >
            <b-select v-model="selectedDuration">
              <option value="1">1 mois</option>
              <option value="2">2 mois</option>
              <option value="3">3 mois</option>
              <option value="4">4 mois</option>
            </b-select>
          </b-field>
        </div>
      </div>
      <!-- <b-field class="field">
            <b-checkbox  :checked="true" v-model="checkbox">Checkbox</b-checkbox>
          </b-field> -->
      <b-field class="fieldBfp">
        <div class="field-right">
          <div class="field-container">
            <b-icon class="custom-icon fas fa-clipboard"></b-icon>
            <div class="field-content">
              <div class="field-label-container">
                <label class="label">Bilan Flash Psychologique</label>
              </div>
              <b-field class="field" style="margin-top: 1rem">
                <b-switch v-model="userData.flashQuestionnaire">
                  <p>
                    <span v-if="userData.flashQuestionnaire">Activé</span>
                    <span v-if="!userData.flashQuestionnaire">Désactivé</span>
                  </p>
                </b-switch>
              </b-field>
              <div>
                <p class="btn-prev-bfp" @click="showPopup = true">
                  Prévisualiser le Questionnaire
                </p>
              </div>
            </div>
            <hr class="modern-separator" />

            <div class="field-content">
              <div class="field-label-container">
                <label class="label"
                  >Bilan Flash: Stress, Anxiété et Dépression</label
                >
              </div>
              <b-field class="field" style="margin-top: 1rem">
                <b-switch v-model="userData.flashQuestionnaire2">
                  <p>
                    <span v-if="userData.flashQuestionnaire2">Activé</span>
                    <span v-if="!userData.flashQuestionnaire2">Désactivé</span>
                  </p>
                </b-switch>
              </b-field>
              <div>
                <p class="btn-prev-bfp" @click="showPopup_Bfp2 = true">
                  Prévisualiser le Questionnaire
                </p>
              </div>
            </div>
          </div>
        </div>
      </b-field>
      <b-field class="field" v-if="selectedState === 'cabinet' && step === 1">
        <b-checkbox v-model="userData.confirme" class="checkbox">
          Je confirme avoir eu l'accord express de mon utilisateur pour une
          utilisation "en séance" dans les conditions des politiques de
          confidentialités du SymbioCenter.(<a
            href="https://www.symbiocenter.com/assets/CGV_SymbioCenter.pdf"
            target="_blank"
            >{{ $t("cgu2_cgv") }}</a
          >,<a
            href="https://www.symbiocenter.com/assets/CGU_P_SymbioCenter.pdf"
            target="_blank"
            >{{ $t("cgu2_cgu") }}</a
          >,<a
            href="https://www.symbiocenter.com/assets/RGPD_SymbioCenter.pdf"
            target="_blank"
          >
            {{ $t("cgu2_rgpd") }}</a
          ><a
            href="https://www.symbiocenter.com/assets/CP_Pulse_SymbioSensor.pdf"
            target="_blank"
            >, {{ $t("cgu2_cp") }}</a
          >)
        </b-checkbox>
      </b-field>

      <div v-if="step === 1">
        <!-- <div class="buttons buttonsRight">
          <b-button type="is-danger" @click="annuler">Annuler</b-button>
        </div>
        <div class="buttons buttonsLeft">
          <b-button
            v-if="isLoadingCreateUser"
            type="is-success"
            class="button-with-loader"
          >
            <div class="loader"></div>
          </b-button>
          <b-button v-else-if="step === 2" type="is-success" @click="valider"
            >Validez le compte</b-button
          >
          <b-button v-else type="is-success" @click="nextStep"
            >Suivant</b-button
          >
        </div> -->
        <div
          class="navbar is-fixed-bottom"
          style="
            position: float;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 5px;
            margin-left: 0px;
            background-color: #6753be;
          "
        >
          <div class="navbar-end">
            <a
              class="button-mon-profil button button-is-red is-margin-r-20"
              @click="annuler"
              type="is-danger"
            >
              Annuler
            </a>
            <a
              class="button-mon-profil button button-is-green is-margin-r-20"
              @click="nextStep"
              type="is-success"
            >
              Passer à l'étape 2
            </a>
          </div>
        </div>
      </div>
      <div v-if="step === 2" style="text-align: center">
        <h4 class="title titleAjout">
          Personnalisez librement, dans les 3 onglets, tous les outils que vous
          souhaitez adresser au patient/client, puis validez la création du
          compte
        </h4>
        <personnalisation-acces
          ref="personnalisationAcces"
          :forCreateUser="true"
          v-if="step === 2"
          @update-addedFolderIds="handleRemoveFolderIds"
        ></personnalisation-acces>
        <!-- <div class="fixed-bottom-button">
          <div class="buttons-div">
            <div class="buttons buttonsRight">
              <b-button type="is-danger" @click="annuler">Annuler</b-button>
            </div>
            <div class="buttons buttonsLeft">
              <b-button
                v-if="isLoadingCreateUser"
                type="is-success"
                class="button-with-loader"
              >
                <div class="loader"></div>
              </b-button>
              <b-button
                v-else-if="step === 2"
                type="is-success"
                @click="valider"
                >Validez le compte</b-button
              >
              <b-button v-else type="is-success" @click="nextStep"
                >Suivant</b-button
              >
            </div>
          </div>
        </div> -->
        <div
          class="navbar is-fixed-bottom"
          style="
            position: float;
            padding-top: 10px;
            padding-bottom: 10px;
            border-top: 5px;
            margin-left: 0px;
            background-color: #6753be;
          "
        >
          <div class="navbar-end">
            <a
              class="button-mon-profil button button-is-red is-margin-r-20"
              @click="annuler"
              type="is-danger"
            >
              Annuler
            </a>
            <a
              class="button-mon-profil button button-is-green is-margin-r-20"
              @click="valider"
              type="is-success"
            >
              Validez le compte
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="popup" v-if="showPopup" @click="handleClose">
      <div class="popup-container">
        <div class="popup-content" @click.stop>
          <PrevisualisationQuestionnaire
            @close="handleClose"
            :bfp="'Bilan Flash Psychologique'"
          />
        </div>
      </div>
    </div>
    <div class="popup" v-else-if="showPopup_Bfp2" @click="handleClose">
      <div class="popup-container">
        <div class="popup-content" @click.stop>
          <PrevisualisationQuestionnaire2
            @close="handleClose"
            :bfp="'Bilan Flash: Stress, Anxiété et Dépression'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import UserService from "../../services/utilisateurs/NewCreateUser.js";

import Parse from "parse";
import ParseConfig from "@/api/parse.config";

import PersonnalisationAcces from "../utilisateur/PersonnalisationAcces";
import PrevisualisationQuestionnaire from "../exercice/PrevisualisationQuestionnaire";
import PrevisualisationQuestionnaire2 from "../exercice/PrevisualisationQuestionnaire2";
export default {
  data() {
    return {
      userData: {
        lastname: "",
        firstname: "",
        confirme: false,
        mail: "",
        mailConfirmation: "",
        flashQuestionnaire: false,
        flashQuestionnaire2: false,
        BeginQuestionnaireId: "",
      },
      relatedFoldersArray: [],
      step: 1,
      isLoadingCreateUser: false,
      selectedState: "cabinet",
      typePratique: "cabinet",
      selectedDuration: 1,
      test: false,
      removedFolderIds: [],
      checkbox: false,
      addedFolderIds: [],
      showPopup: false,
      isStagiaire: false,
      showPopup_Bfp2: false,
    };
  },
  created() {
    this.selectedState = "cabinet";
    //console.log("NewCreationUtilisateur.vue created");
  },
  components: {
    PersonnalisationAcces,
    PrevisualisationQuestionnaire,
    PrevisualisationQuestionnaire2,
  },
  watch: {
    // 'userData.flashQuestionnaire': function(newVal, oldVal) {
    //   if (newVal) {
    //     this.userData.flashQuestionnaire2 = false;
    //   }
    // },
    // 'userData.flashQuestionnaire2': function(newVal, oldVal) {
    //   if (newVal) {
    //     this.userData.flashQuestionnaire = false;
    //   }
    // }
  },
  methods: {
    closeForm() {
      this.$emit("closeForm");
    },
    saveInComponent() {
      this.$refs.personnalisationAcces.saveChanges();
    },
    toLowerCase(field) {
      this.userData[field] = this.userData[field].toLowerCase();
    },
    nextStep() {
      ParseConfig();
      console.log("USERDATA", this.userData);

      // Vérifier que les emails sont identiques
      if (this.userData.mail !== this.userData.mailConfirmation) {
        Swal.fire({
          icon: "error",
          title: "Les emails ne correspondent pas. Veuillez les vérifier.",
        });
        return;
      }

      var userQuery = new Parse.Query(Parse.User);
      userQuery.equalTo("email", this.userData.mail);
      userQuery
        .first()
        .then((user) => {
          if (user && this.selectedState === "licence") {
            Swal.fire({
              icon: "error",
              title: "Un utilisateur avec cet email existe déjà.",
            });
            return;
          }

          // Déplacer la logique ici pour attendre la réponse de la promesse
          if (
            (this.selectedState === "licence" &&
              this.userData.lastname &&
              this.userData.firstname &&
              this.userData.mail &&
              this.userData.mailConfirmation) ||
            (this.selectedState === "cabinet" &&
              this.userData.confirme &&
              this.userData.lastname &&
              this.userData.firstname)
          ) {
            this.step++;
          } else if (!this.userData.confirme) {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 9000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "error",
              title: "Vous devez confirmer les conditions de confidentialité.",
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 9000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "error",
              title: "Vous devez remplir tous les champs.",
            });
          }
        })
        .catch((error) => {
          // Gérer les erreurs potentielles de la requête Parse
          console.error(
            "Erreur lors de la vérification de l'utilisateur :",
            error
          );
          Swal.fire({
            icon: "error",
            title:
              "Une erreur est survenue lors de la vérification de l'utilisateur.",
          });
        });
    },
    handleRemoveFolderIds(addedFolderIds) {
      //console.log('Id removed:', addedFolderIds);
      this.addedFolderIds = addedFolderIds;
    },
    handleClose() {
      this.showPopup = false;
      this.showPopup_Bfp2 = false;
    },
    async valider() {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 9000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });
      if (this.email !== this.emailConfirmation) {
        Toast.fire({
          icon: "error",
          title: "Les emails ne correspondent pas.'",
        });
        return;
      }
      // Initialiser un tableau vide pour stocker les identifiants des questionnaires
      this.userData.BeginQuestionnaireIds = [];

      // Vérifier si le premier questionnaire doit être lancé
      if (this.userData.flashQuestionnaire) {
        // Ajouter l'identifiant du premier questionnaire au tableau
        this.userData.BeginQuestionnaireIds.push("8YKAqC9cB0");
      }

      // Vérifier si le deuxième questionnaire doit être lancé
      if (this.userData.flashQuestionnaire2) {
        // Ajouter l'identifiant du deuxième questionnaire au tableau
        this.userData.BeginQuestionnaireIds.push("Ce7Us628aH");
      }

      if (this.selectedState === "licence") {
        this.userData.confirme = true;
      }
      // Code pour valider et ajouter un utilisateur
      if (this.userData.confirme) {
        this.isLoadingCreateUser = true;
        console.log('CreateUserService',this.userData, this.selectedState, this.addedFolderIds, this.selectedDuration);
        const user = await UserService.createUser(
          this.userData,
          this.selectedState,
          this.addedFolderIds,
          this.selectedDuration
        );
        //console.log('User by NewCreateUser:', user);
        if (user) {
          this.userData.id = user.id;
          this.$emit("addUser", this.userData);
          this.userData = {
            lastname: "",
            firstname: "",
            confirme: false,
          };
          // Fermer la modal
          this.$emit("refreshListUser");
          this.$emit("close");
          this.isLoadingCreateUser = false;
          Toast.fire({
            icon: "success",
            title: "L’utilisateur a été créé avec succès'",
          });
        } else {
          Toast.fire({
            icon: "error",
            title: "Une erreur est survenu.'",
          });
        }

        this.isLoadingCreateUser = false;
      } else {
        //console.log("Vous devez confirmer les conditions de confidentialité.");
        Toast.fire({
          icon: "error",
          title: "Vous devez confirmer les conditions de confidentialité.'",
        });
      }
    },
    annuler() {
      this.userData = {
        nom: "",
        prenom: "",
        confirme: false,
      };
      // Fermer la modal
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
$primary: #8a4d76;
$success: #4caf50;
$danger: #ff5252;
.titleAjout {
  margin-top: 3rem;
}
.btn-prev-bfp {
  font-style: italic;
  font-size: 0.7rem;
  color: #239380;
  margin-top: 10px;
  margin-left: 20px;
  border: 1px solid #239380;
  padding: 4px;
  border-radius: 20px;
  cursor: pointer;
  width: 50%;
  text-align: center;
}

.user-form-container {
  position: relative;
  padding: 2rem;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  .title {
    font-size: 1.8rem;
    font-weight: 600;
    // margin-bottom: 1.5rem;
    color: darken($primary, 15%);
  }

  .field {
    // margin-bottom: 1.5rem;
    label {
      font-weight: 500;
      color: darken($primary, 10%);
    }
    input {
      border-radius: 4px;
      border: 1px solid darken($primary, 5%);
      &:focus {
        border-color: $primary;
      }
    }
  }

  .checkbox {
    color: darken($primary, 10%);
    font-size: 0.9rem;
    input[type="checkbox"]:checked + .check {
      background-color: $success;
    }
  }

  .buttons {
    .button {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      border-radius: 4px;
      &.is-success {
        background-color: $success;
        color: white;
        &:hover {
          background-color: darken($success, 5%);
        }
      }
      &.is-danger {
        background-color: $danger;
        color: white;
        &:hover {
          background-color: darken($danger, 5%);
        }
      }
    }
  }
  .buttonsLeft {
    margin-left: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
  .buttonsRight {
    margin-right: auto;
    margin-bottom: auto;
    margin-top: auto;
  }
}
.buttons-div {
  display: flex;
}
.titlePratique {
  font-weight: bold;
}
.button-with-loader {
  position: relative;
  overflow: hidden;
  width: 7rem;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 20px; /* Ajustez la largeur du loader comme souhaité */
  height: 20px; /* Ajustez la hauteur du loader comme souhaité */
  border: 4px solid transparent;
  border-top: 4px solid #ffffff; /* Couleur de la partie blanche */
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.field-container {
  display: flex;
  flex-direction: row;
}

.field-content {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.active-state {
  color: #239380;
  font-weight: 600;
  //souligner
  text-decoration: underline;
}

.container-user {
  margin: 10px;
}

.fieldTypePratique {
  position: absolute;
  top: 20px;
  right: 40px;
}

.field-group {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px; /* Ajustez la marge comme vous le souhaitez */
}

.field-left {
  flex: 1; /* Ajustez la largeur à votre convenance */
  margin-right: 10px; /* Espace entre le champ "Nom" et le champ "Prénom" */
}

.field-right {
  flex: 1; /* Ajustez la largeur à votre convenance */
}

.InputName {
  width: 80%;
}

.fieldSelect {
  text-align: center;
}

.SelectDiv {
  text-align: center;
}
.field-label-container {
  display: flex;
  align-items: center;
}

.label {
  margin-right: 10px;
}
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 2000;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: none !important;
}
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgb(104 101 101 / 70%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  position: relative;
  background-color: #f9f9f9;
  padding: 40px;
  width: 80%;
  max-width: 800px;
  border-radius: 20px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;

  @media (min-width: 768px) {
    width: 50%;
  }
}

// Variables de style
$primary-color: #239380; // Couleur principale
$secondary-color: #ccc; // Couleur de l'étape passée
$bar-height: 10px; // Hauteur de la barre de progression
$progress-width: 50%;
// Style de base pour la barre de progression
.progress-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    to right,
    $primary-color 0%,
    $primary-color 50%,
    $secondary-color 50%,
    $secondary-color 100%
  );
  border-radius: 5px;
  height: $bar-height;
  width: 30%;
  margin: 0 auto;
  .progress {
    background: $primary-color;
    border-radius: 5px 0 0 5px;
    height: 100%;
    width: $progress-width;
  }
}
.progress-bar.active {
  background: linear-gradient(
    to right,
    $primary-color 0%,
    $primary-color 100%,
    /* Background à 100% */ $secondary-color 100% /* Background à 100% */
  );
}
// Style pour l'étape active (étape 1)
.step.active {
  background: $primary-color;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}
.infoPratique {
  font-size: 1rem;
  font-style: italic;
  color: #239380;
  margin-bottom: 1rem;
}
// Style pour l'étape passée (étape 2)
.step.passed {
  background: $secondary-color;
  color: #888;
  padding: 10px;
  border-radius: 5px;
}
.divRadioPratique {
  margin-top: 1rem;
  display: flex;
}
.divRadio {
  width: 10%;
}

.modern-separator {
  margin: 1rem 0;
  border: none;
  border-top: 1px solid #ccc; /* Couleur de la ligne */
}

.fieldBfp {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.close-btn {
  position: absolute;
  top: 10px; /* Ajustez selon vos besoins */
  right: 40px; /* Ajustez selon vos besoins */
  border: none;
  background: none;
  cursor: pointer;
  font-size: 24px; /* Ajustez la taille de l'icône */
}

.fixed-bottom-button {
  position: fixed;
  bottom: 0;
  width: 80%;
  text-align: center; /* Optionnel : pour centrer le bouton */
  background-color: white; /* Optionnel : pour une meilleure visibilité */
  padding: 1rem; /* Optionnel : ajuster le padding si nécessaire */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Optionnel : pour un peu de style */
}

.buttonSaveChanges {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-color: #239380;
  color: white;
}

.navbar-end {
  margin-right: auto;
  margin-left: auto;
}

.button-is-red {
  background-color: #ff5252;
  color: white;
}
.button-is-green {
  background-color: #4caf50;
  color: white;
}
</style>
