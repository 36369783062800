// import { getLinksFileByLink } from "@/services/folders/getLinksFileByLink";
import Parse from "parse";

export async function pushLinkToUser(ResponseUser, linksToAdd) {
  const RelationLinkFromUser = ResponseUser.relation("link");
  let QueryLinkFromRelation = RelationLinkFromUser.query();
  QueryLinkFromRelation.limit(1000);
  const ResponseLinkFromRelation = await QueryLinkFromRelation.find();
  ResponseLinkFromRelation.forEach((link) => {
    RelationLinkFromUser.remove(link);
  });

  var listeLinkId = [];
  for (var i = 0; i < linksToAdd.length; i = i + 1) {
    listeLinkId.push(linksToAdd[i].id || linksToAdd[i].objectId);
  }

  var queryLink = new Parse.Query("link");
  queryLink.containedIn("objectId", listeLinkId);
  queryLink.limit(100000);
  var listeLinkAAjouter = await queryLink.find();

  for (i = 0; i < listeLinkAAjouter.length; i = i + 1) {
    RelationLinkFromUser.add(listeLinkAAjouter[i]);
  }
}
