<template>
  <div>
    <canvas ref="chartBrique" height="225px" width="360px"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  props: ["data", "moyenne"],
  name: "chart-brique",
  methods: {
    loadChart() {
      const mesDonnees = {
        labels: ["Cabinet", "Licence", "Abonné"],
        datasets: [
          {
            label: "Moyenne",
            data: [this.moyenne, this.moyenne, this.moyenne],
            type: "line",
            fill: false,
            borderColor: "rgb(0, 0, 0)",
            order: 2,
          },
          {
            label: "Avancement",
            data: this.data,
            backgroundColor: [
              "rgb(77, 160, 255)",
              "rgb(184, 61, 153)",
              "rgb(210, 213, 5)",
            ],
            order: 1,
          },
        ],
      };
      var chartBrique = this.$refs.chartBrique;
      var ctx = chartBrique.getContext("2d");
      new Chart(ctx, {
        type: "bar",
        data: mesDonnees,
        options: {
          legend: {
            display: false,
          },
          scales: {
            yAxes: [
              {
                id: "y-axis-1",
                type: "linear",
                display: true,
                position: "left",
                ticks: {
                  beginAtZero: true,
                  min: 0,
                  max: 100,
                },
              },
            ],
          },
          cutoutPercentage: 75,
        },
      });
    },
  },
  mounted() {
    this.loadChart();
  },
};
</script>
