var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.misAJour)?_c('div',{ref:"element",staticClass:"box is-margin-b-10 bg-color"},[_c('b-tabs',{attrs:{"type":"is-boxed","expanded":"","size":"is-medium"}},[_vm._l((_vm.themes),function(theme){return [(theme.attributes.name != 'Mes échelles d\'évaluation')?_c('b-tab-item',{key:theme.id,attrs:{"label":theme.attributes.name}},[(theme.attributes.ordre === 1)?_c('div',_vm._l((_vm.getFoldersByTheme(theme.id)),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"is-margin-t-10"},[(
                folderBrique &&
                (folderBrique.name.includes('cardiaque') ||
                  folderBrique.name.includes('hypnotique') ||
                  folderBrique.name.includes('compassion'))
              )?_c('div',{staticClass:"box is-margin-b-20",class:{
                boxFolder:
                  folderBrique.deverrouille &&
                  folderBrique.protocoles[1].deverrouille,
              }},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"},[_c('b-tooltip',{attrs:{"label":folderBrique.deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? ''
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-right"}},[_c('div',{staticClass:"img-brique-folder"},[_c('figure',{staticClass:"image is-128x128 brique is-margin-t-50 is-margin-l-0"},[(
                            !_vm.isPageUser &&
                            !_vm.isPageSessionGroup &&
                            _vm.group.type_abonnement == 'gratuit'
                          )?_c('router-link',{attrs:{"to":"/abonnement"}},[_c('div',{},[_c('div',{staticClass:"column is-12 is-size-7 is-centered text-abonnezvous-expert",staticStyle:{"border-radius":"15px 15px 0px 0px","background-color":"#4a439e","color":"white","z-index":"1","display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("abonnezVousPour"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("debloquerProtocole"))+" ")])])]):_vm._e(),(
                            folderBrique.resourceBg &&
                            folderBrique.resourceBg._url
                          )?_c('img',{staticClass:"brique-image",staticStyle:{"border-radius":"0px 0px 15px 15px","width":"100%","max-width":"400px"},attrs:{"src":folderBrique.resourceBg._url}}):(
                            folderBrique.resourceBg &&
                            folderBrique.resourceBg.url
                          )?_c('img',{staticClass:"brique-image",staticStyle:{"border-radius":"0px 0px 15px 15px","width":"100%","max-width":"400px"},attrs:{"src":folderBrique.resourceBg.url}}):_vm._e(),_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"text-brique"},[_vm._v(" "+_vm._s(folderBrique.protocoles[1].name)+" ")])]),_c('a',{staticClass:"linkPlusInfo2",staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.openInformation(
                              folderBrique.protocoles[1].text,
                              folderBrique.protocoles[1].videoIntro
                            )}}},[_vm._v(" + d'infos")])],1)])])],1),(folderBrique.protocoles[1])?_c('a',{staticClass:"tooltip column is-12-mobile is-5-tablet is-5-desktop is-4-widescreen",class:{
                    verrouille:
                      !folderBrique.protocoles[1].deverrouille &&
                      (folderBrique.protocoles[1].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      !folderBrique.protocoles[1].deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  },on:{"click":function($event){return _vm.unlock(folderBrique, 1)}}},[_c('span',{staticClass:"tooltiptext"},[_vm._v("Activer/Désactiver le protocole")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('span',{staticClass:"has-text-black is-size-12 text-brique"},[_vm._v(" "+_vm._s(folderBrique.protocoles[1].name)+" ")])]),_c('div',{staticClass:"BSwitchBrique"},[_c('b-switch',{attrs:{"size":"is-small","type":"is-cyan"},on:{"click":function($event){return _vm.unlock(folderBrique, 1)}},model:{value:(folderBrique.protocoles[1].deverrouille),callback:function ($$v) {_vm.$set(folderBrique.protocoles[1], "deverrouille", $$v)},expression:"folderBrique.protocoles[1].deverrouille"}},[(folderBrique.protocoles[1].deverrouille)?_c('span',[_vm._v("Activé")]):_c('span',[_vm._v("Désactivé")])])],1)]),_c('div',[_vm._l((folderBrique.protocoles[1]
                        .modules),function(moduleP,index){return _c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{}},[_c('div',{staticClass:"columns is-vcentered"},[(!moduleP.name.includes('Pratique libre'))?_c('div',{staticClass:"column is-12-mobile is-2-tablet is-3-desktop is-2-widescreen p-5",class:{
                            'is-3': _vm.isPageSessionGroup,
                          }},[_c('figure',[_c('div',{staticClass:"cercle",class:{
                                cerclegrey:
                                  !folderBrique.protocoles[1].deverrouille,
                              }},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(index + 1)+" ")])])])]):_vm._e(),(!moduleP.name.includes('Pratique libre'))?_c('div',{staticClass:"column isPageUser has-text-black is-12-mobile is-10-tablet is-8-desktop is-10-widescreen"},[_c('div',[_c('span',{staticClass:"has-text-black",staticStyle:{"font-size":"12px","margin-left":"20px"}},[_vm._v(" "+_vm._s(moduleP.name)+" ")])])]):_vm._e()])])}),_c('div',{staticClass:"is-margin-t-10"},[(folderBrique.protocoles[1].modules.length === 1)?_c('div',{staticClass:"columns is-vcentered folders-cc-disabled"},[_c('div',{staticClass:"column is-12-mobile is-4-tablet is-3-desktop is-3-widescreen p-5",class:{
                            'is-3': _vm.isPageSessionGroup,
                          }},[_c('figure',{staticStyle:{"margin":"0"}},[_c('div',{staticClass:"cerclegrey"},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(2)+" ")])])])]),_c('div',{staticClass:"column has-text-black",class:{ 'is-margin-l-40': _vm.isPageUser }},[_vm._v(" "+_vm._s(_vm.$t("installerLaCC"))+" ")])]):_vm._e()]),_c('div',{staticClass:"is-margin-t-12"},[(folderBrique.protocoles[1].modules.length === 1)?_c('div',{staticClass:"columns is-vcentered folders-cc-disabled"},[_c('div',{staticClass:"column is-2",class:{
                            'is-2': _vm.isPageUser,
                            'is-3': _vm.isPageSessionGroup,
                          }},[_c('figure',{staticStyle:{"margin":"0"}},[_c('div',{staticClass:"cerclegrey"},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(3)+" ")])])])]),_c('div',{staticClass:"column has-text-black",class:{ 'is-margin-l-40': _vm.isPageUser }},[_vm._v(" "+_vm._s(_vm.$t("maitriserLaCC"))+" ")])]):_vm._e()]),_c('div',{staticClass:"is-margin-t-12"},[(folderBrique.protocoles[1].modules.length === 1)?_c('div',{staticClass:"columns is-vcentered folders-cc-disabled"},[_c('div',{staticClass:"column is-12-mobile is-2-tablet is-2-desktop is-2-widescreen p-5",class:{
                            'is-3': _vm.isPageSessionGroup,
                          }},[_c('figure',{staticStyle:{"margin":"0"}},[_c('div',{staticClass:"cerclegrey"},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(4)+" ")])])])]),_c('div',{staticClass:"column has-text-black",class:{ 'is-margin-l-40': _vm.isPageUser }},[_vm._v(" "+_vm._s(_vm.$t("ancrerLaCC"))+" ")])]):_vm._e()])],2),(
                      folderBrique.protocoles[2] &&
                      folderBrique.protocoles[2].modules
                    )?_c('div',{staticClass:"is-pulled-right is-size-7"}):_vm._e()]):_vm._e()])]):_vm._e()])}),0):_vm._e(),(theme.attributes.ordre === 1)?_c('div',{staticClass:"bg-color"},_vm._l((_vm.getFoldersByTheme(theme.id)),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"bg-color"},[(
                folderBrique &&
                !folderBrique.name.includes('cardiaque') &&
                !folderBrique.name.includes('hypnotique') &&
                !folderBrique.name.includes('compassion') &&
                !folderBrique.name.includes('Tests') &&
                !folderBrique.name.includes('Soft') &&
                !folderBrique.name.includes('Vie')
              )?_c('div',{staticClass:"box is-margin-b-10",class:{
                boxFolder:
                  (folderBrique.deverrouille &&
                    folderBrique.protocoles[1].deverrouille) ||
                  (folderBrique.deverrouille &&
                    folderBrique.protocoles[2].deverrouille),
              }},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"},[_c('b-tooltip',{attrs:{"label":folderBrique.deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? ''
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-right"}},[_c('div',{staticClass:"img-brique-folder"},[_c('figure',{staticClass:"image is-128x128 brique is-margin-t-50 is-margin-l-0"},[(
                            !_vm.isPageUser &&
                            !_vm.isPageSessionGroup &&
                            _vm.group.type_abonnement == 'gratuit'
                          )?_c('router-link',{attrs:{"to":"/abonnement"}},[_c('div',{},[_c('div',{staticClass:"column is-12 is-size-7 is-centered text-abonnezvous-expert",staticStyle:{"border-radius":"15px 15px 0px 0px","background-color":"#4a439e","color":"white","z-index":"1","display":"block"}},[_vm._v(" "+_vm._s(_vm.$t("abonnezVousPour"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("debloquerProtocole"))+" ")])])]):_vm._e(),(
                            folderBrique.resourceBg &&
                            folderBrique.resourceBg._url
                          )?_c('img',{staticClass:"brique-image",staticStyle:{"border-radius":"0px 0px 15px 15px","width":"100%","max-width":"400px"},attrs:{"src":folderBrique.resourceBg._url}}):(
                            folderBrique.resourceBg &&
                            folderBrique.resourceBg.url
                          )?_c('img',{staticClass:"brique-image",staticStyle:{"border-radius":"0px 0px 15px 15px","width":"100%","max-width":"400px"},attrs:{"src":folderBrique.resourceBg.url}}):_vm._e(),_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"text-brique"},[_vm._v(" "+_vm._s(folderBrique.protocoles[1].name)+" ")])]),_c('a',{staticClass:"linkPlusInfo2",staticStyle:{"display":"flex"},on:{"click":function($event){return _vm.openInformation(
                              folderBrique.protocoles[1].text,
                              folderBrique.protocoles[1].videoIntro
                            )}}},[_vm._v(" + d'infos")])],1)])])],1),(folderBrique.protocoles[1])?_c('a',{staticClass:"tooltip column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen column-border",class:{
                    verrouille:
                      !folderBrique.protocoles[1].deverrouille &&
                      (folderBrique.protocoles[1].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      !folderBrique.protocoles[1].deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  },on:{"click":function($event){return _vm.unlock(folderBrique, 1)}}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('span',{staticClass:"tooltiptext"},[_vm._v("Activer/Désactiver le protocole")]),_c('div',[_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" Programme Court ")])]),_c('div',{staticClass:"BSwitchBrique"},[_c('b-switch',{attrs:{"size":"is-small","type":"is-cyan"},on:{"click":function($event){return _vm.unlock(folderBrique, 1)}},model:{value:(folderBrique.protocoles[1].deverrouille),callback:function ($$v) {_vm.$set(folderBrique.protocoles[1], "deverrouille", $$v)},expression:"folderBrique.protocoles[1].deverrouille"}},[(folderBrique.protocoles[1].deverrouille)?_c('span',[_vm._v("Activé")]):_c('span',[_vm._v("Désactivé")])])],1)]),_c('div',_vm._l((folderBrique.protocoles[1]
                        .modules),function(moduleP,index){return _c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{}},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-12-mobile is-2-tablet is-2-desktop is-3-widescreen p-5",class:{
                            'is-4': _vm.isPageSessionGroup,
                          }},[_c('figure',[_c('div',{staticClass:"cercle",class:{
                                cerclegrey:
                                  !folderBrique.protocoles[1].deverrouille,
                              }},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(index + 1)+" ")])])])]),_c('div',[_c('span',{staticClass:"column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleP.name)+" ")])])])])}),0),(
                      folderBrique.protocoles[2] &&
                      folderBrique.protocoles[2].modules
                    )?_c('div',{staticClass:"is-pulled-right is-size-7"}):_vm._e()]):_vm._e(),(folderBrique.protocoles[2])?_c('a',{staticClass:"tooltip column is-12-mobile is-5-tablet is-5-desktop is-4-widescreen column-border",class:{
                    verrouille:
                      !folderBrique.protocoles[2].deverrouille &&
                      (folderBrique.protocoles[2].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                  },on:{"click":function($event){return _vm.unlock(folderBrique, 2)}}},[_c('span',{staticClass:"tooltiptext"},[_vm._v("Activer/Désactiver le protocole")]),_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',[_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" Programme Long ")])]),_c('div',{staticClass:"BSwitchBrique"},[_c('b-switch',{attrs:{"size":"is-small","type":"is-cyan"},on:{"click":function($event){return _vm.unlock(folderBrique, 2)}},model:{value:(folderBrique.protocoles[2].deverrouille),callback:function ($$v) {_vm.$set(folderBrique.protocoles[2], "deverrouille", $$v)},expression:"folderBrique.protocoles[2].deverrouille"}},[(folderBrique.protocoles[2].deverrouille)?_c('span',[_vm._v("Activé")]):_c('span',[_vm._v("Désactivé")])])],1)]),_c('div',_vm._l((folderBrique.protocoles[2]
                        .modules),function(moduleP,index){return _c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{ isPageUser: _vm.isPageUser }},[(moduleP.fileIsNotEval)?_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-12-mobile is-2-tablet is-2-desktop is-3-widescreen p-5",class:{
                            'is-6': _vm.isPageUser,
                            'is-4': _vm.isPageSessionGroup,
                          }},[_c('figure',[_c('div',{staticClass:"cercle",class:{
                                cerclegrey:
                                  !folderBrique.protocoles[2].deverrouille,
                              }},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(index)+" ")])])])]),_c('div',[_c('span',{staticClass:"column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleP.name)+" ")])])]):_vm._e()])}),0),(
                      folderBrique.protocoles[2] &&
                      folderBrique.protocoles[2].modules
                    )?_c('div',{staticClass:"is-pulled-right is-size-7"}):_vm._e()]):_vm._e()])]):(
                !folderBrique.name.includes('cardiaque') &&
                !folderBrique.name.includes('hypnotique') &&
                !folderBrique.name.includes('compassion') &&
                !folderBrique.name.includes('Tests') &&
                !folderBrique.name.includes('Soft') &&
                !folderBrique.name.includes('Vie')
              )?_c('div',[(!_vm.isPageUser && !_vm.isPageSessionGroup)?_c('div',{staticClass:"box is-margin-b-20 degrade"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border"},[(folderBrique.resource)?_c('figure',{staticClass:"image is-128x128 brique"},[(folderBrique.resource.url)?_c('img',{staticClass:"is-rounded brique-image",attrs:{"src":folderBrique.resource.url}}):(folderBrique.resource._url)?_c('img',{staticClass:"is-rounded brique-image",attrs:{"src":folderBrique.resource._url}}):_vm._e(),_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"is-size-7"},[_vm._v(_vm._s(folderBrique.name))])])]):_vm._e()]),(folderBrique.protocoles[1])?_c('div',{staticClass:"column is-3-mobile is-3-tablet is-3-desktop is-3-widescreen column-border"},[_c('span',{staticClass:"has-text-black"},[_vm._v(" "+_vm._s(folderBrique.protocoles[1].name)+" ")]),_c('div',{staticClass:"columns is-multiline"},_vm._l((folderBrique.protocoles[1]
                          .modules),function(moduleP,index){return _c('div',{key:moduleP.id,staticClass:"column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen",class:{ isPageUser: _vm.isPageUser }},[_c('div',{staticClass:"cercle"},[_c('strong',{class:{ cercletext: !_vm.isPageUser }},[_vm._v(" "+_vm._s(index + 1)+" ")])])])}),0)]):_vm._e(),(folderBrique.protocoles[2])?_c('div',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen"},[_c('span',{staticClass:"has-text-black",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(folderBrique.protocoles[2].name)+" ")]),_c('div',{staticClass:"columns is-multiline"},_vm._l((folderBrique.protocoles[2].modules),function(moduleP){return _c('div',{key:moduleP.id,staticClass:"column is-12-mobile is-6-tablet is-4-desktop is-3-widescreen",class:{ isPageUser: _vm.isPageUser }},[(moduleP.resource)?_c('figure',{staticClass:"image is-48x48"},[(moduleP.resource.url)?_c('img',{attrs:{"src":moduleP.resource.url}}):(moduleP.resource._url)?_c('img',{attrs:{"src":moduleP.resource._url}}):_vm._e()]):_vm._e()])}),0)]):_vm._e(),_c('div',{staticClass:"column is-3-mobile is-3-tablet is-3-desktop is-3-widescreen has-text-centered"},[_c('a',{staticClass:"button button-rounded is-primary",on:{"click":function($event){return _vm.goAbonnement()}}},[_vm._v(" "+_vm._s(_vm.$t("souscrireA"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("laBrique"))+" ")])])])]):_vm._e()]):_vm._e()])}),0):_vm._e(),(theme.attributes.ordre === 3)?_c('div',_vm._l((_vm.getFoldersByTheme(theme.id)),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"is-margin-t-10"},[(
                folderBrique &&
                (folderBrique.name.includes('cardiaque') ||
                  folderBrique.name.includes('hypnotique') ||
                  folderBrique.name.includes('compassion'))
              )?_c('div',{staticClass:"box is-margin-b-20",class:{
                boxFolder:
                  (folderBrique.deverrouille &&
                    folderBrique.protocoles[2] &&
                    folderBrique.protocoles[2].deverrouille) ||
                  (folderBrique.deverrouille && !folderBrique.protocoles[2]),
              }},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen column-border img-brique-folderR",staticStyle:{"border-radius":"15px","margin":"10% 2% 10% 2%"},style:({ 'background-color': folderBrique.color })},[(
                      !_vm.isPageUser &&
                      !_vm.isPageSessionGroup &&
                      _vm.group.type_abonnement == 'gratuit'
                    )?_c('router-link',{attrs:{"to":"/abonnement"}},[_c('div',{staticClass:"columns is-multiligne"},[_c('div',{staticClass:"column is-12 is-size-7 text-abonnezvous-expert",staticStyle:{"border-radius":"15px 15px 0px 0px","background-color":"#4a439e","color":"white","margin-bottom":"15px"}},[_vm._v(" "+_vm._s(_vm.$t("abonnezVousPour"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("debloquerRessources"))+" ")])])]):_vm._e(),_c('div',{attrs:{"label":folderBrique.deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? ''
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-right"}},[_c('div',{staticClass:"brique-textR",staticStyle:{"color":"white"}},[_c('p',{staticClass:"is-size-12"},[_vm._v(_vm._s(folderBrique.name))])])]),(folderBrique.protocoles[2])?_c('div',{staticClass:"BSwitchBrique"},[_c('b-switch',{attrs:{"size":"is-medium","type":"is-grey"},on:{"click":function($event){return _vm.unlock(folderBrique, 2)}},model:{value:(folderBrique.protocoles[2].deverrouille),callback:function ($$v) {_vm.$set(folderBrique.protocoles[2], "deverrouille", $$v)},expression:"folderBrique.protocoles[2].deverrouille"}},[(folderBrique.protocoles[2].deverrouille)?_c('span',{staticStyle:{"color":"white"}},[_vm._v("Activé")]):_c('span',{staticStyle:{"color":"white"}},[_vm._v("Désactivé")])])],1):_vm._e()],1),(folderBrique.protocoles[2])?_c('a',{staticClass:"tooltip column is-12-mobile is-6-tablet is-5-desktop is-5-widescreen",class:{
                    verrouille:
                      !folderBrique.protocoles[2].deverrouille &&
                      (folderBrique.protocoles[2].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      (!folderBrique.protocoles[2].deverrouille_user &&
                        (_vm.isPageUser || _vm.isPageSessionGroup)) ||
                      (_vm.group.type_abonnement !== 'pulse' &&
                        (folderBrique.name.includes('cardiaque') ||
                          folderBrique.name.includes('hypnotique') ||
                          folderBrique.name.includes('compassion'))),
                  },on:{"click":function($event){return _vm.unlock(folderBrique, 2)}}},[_c('span',{staticClass:"tooltiptext"},[_vm._v("Activer/Désactiver le protocole")]),(folderBrique.protocoles[2])?_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('div',{attrs:{"label":folderBrique.protocoles[2].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)
                          ? 'Cliquer pour activer/désactiver ce protocole'
                          : 'Exercices non accessibles',"position":"is-top"}},[(
                          folderBrique.protocoles[2] &&
                          folderBrique.protocoles[2].name
                        )?_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" "+_vm._s(folderBrique.protocoles[2].name)+" ")]):_vm._e()])]):_vm._e(),(folderBrique.protocoles[2])?_c('div',_vm._l((folderBrique.protocoles[2]
                        .modules),function(moduleP,index){return (folderBrique.protocoles[2].modules)?_c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{}},[_c('div',{key:index,staticClass:"columns is-vcentered"},[(!moduleP.name.includes('Pratique libre'))?_c('div',{staticClass:"column is-12-mobile is-2-tablet is-2-desktop is-2-widescreen p-5",class:{
                            'is-3': _vm.isPageSessionGroup,
                          }},[_c('figure',[(folderBrique.protocoles[2])?_c('div',{staticClass:"cercleRessources",class:{
                                cerclegreyRessources:
                                  !folderBrique.protocoles[2].deverrouille,
                              }},[_c('strong',{class:{ cercletext: !_vm.isPageUser }})]):_vm._e()])]):_vm._e(),_c('div',[_c('span',{staticClass:"column isPageUser has-text-black is-12-mobile is-12-tablet is-12-desktop is-12-widescreen",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleP.name)+" ")])]),(!moduleP.name.includes('Pratique libre'))?_c('div',{staticClass:"column has-text-black"}):_vm._e()])]):_vm._e()}),0):_vm._e()]):_vm._e()])]):_vm._e()])}),0):_vm._e(),(theme.attributes.ordre === 3)?_c('div',_vm._l((_vm.getFoldersByTheme(theme.id)),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"is-margin-t-10"},[(
                folderBrique &&
                !folderBrique.name.includes('cardiaque') &&
                !folderBrique.name.includes('hypnotique') &&
                !folderBrique.name.includes('compassion') &&
                !folderBrique.name.includes('Tests') &&
                !folderBrique.name.includes('Soft') &&
                !folderBrique.name.includes('Vie')
              )?_c('div',{staticClass:"box is-margin-b-10",class:{ boxFolder: folderBrique.deverrouille  &&
                    folderBrique.protocoles[3] &&
                    folderBrique.protocoles[3].deverrouille}},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-3-widescreen column-border img-brique-folderR",staticStyle:{"border-radius":"15px","margin":"10% 2% 10% 2%"},style:({ 'background-color': folderBrique.color })},[_c('b-tooltip',{attrs:{"label":folderBrique.deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? ''
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-right"}},[_c('div',{staticClass:"brique-textR",staticStyle:{"color":"white"}},[_c('p',{staticClass:"is-size-12"},[_vm._v(_vm._s(folderBrique.name))])])]),(folderBrique.protocoles[3])?_c('div',{staticClass:"BSwitchBrique"},[_c('b-switch',{attrs:{"size":"is-medium","type":"is-grey"},on:{"click":function($event){return _vm.unlock(folderBrique, 3)}},model:{value:(folderBrique.protocoles[3].deverrouille),callback:function ($$v) {_vm.$set(folderBrique.protocoles[3], "deverrouille", $$v)},expression:"folderBrique.protocoles[3].deverrouille"}},[(folderBrique.protocoles[3].deverrouille)?_c('span',{staticStyle:{"color":"white"}},[_vm._v("Activé")]):_c('span',{staticStyle:{"color":"white"}},[_vm._v("Désactivé")])])],1):_vm._e()],1),(folderBrique.protocoles[3])?_c('a',{staticClass:"column is-12-mobile is-4-tablet is4-desktop is-4-widescreen",class:{
                    verrouille:
                      !folderBrique.protocoles[3].deverrouille &&
                      (folderBrique.protocoles[3].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      !folderBrique.protocoles[3].deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  },on:{"click":function($event){return _vm.unlock(folderBrique, 3)}}},[_c('b-tooltip',{attrs:{"label":folderBrique.protocoles[3].deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? 'Cliquer pour activer/désactiver ce protocole'
                        : 'Exercices non accessibles',"position":"is-top"}},[_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" "+_vm._s(folderBrique.protocoles[3].name)+" (court) ")])]),_c('div',_vm._l((folderBrique.protocoles[3]
                        .modulesDecouverte),function(exoP){return _c('div',{key:exoP.id,staticClass:"is-margin-t-10",class:{}},_vm._l((exoP.linkDecouverte),function(moduleP){return _c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{}},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-12-mobile is-2-tablet is-3-desktop is-2-widescreen p-5",class:{
                              'is-3': _vm.isPageSessionGroup,
                            }},[_c('figure',[(folderBrique.protocoles[3])?_c('div',{staticClass:"cercleRessources",class:{
                                  cerclegreyRessources:
                                    !folderBrique.protocoles[3].deverrouille,
                                }},[_c('strong',{class:{ cercletext: !_vm.isPageUser }})]):_vm._e()])]),_c('div',{staticClass:"isPageUser has-text-black is-12-mobile is-10-tablet is-8-desktop is-10-widescreen"},[_c('b-tooltip',{attrs:{"label":folderBrique.protocoles[3]
                                  .deverrouille_user ||
                                (!_vm.isPageUser && !_vm.isPageSessionGroup)
                                  ? 'Cliquer pour activer/désactiver ce protocole'
                                  : 'Exercices non accessibles',"position":"is-top"}},[_c('span',{staticClass:"has-text-black",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleP.composite_file.short_file.sub_name)+" ")])])],1)])])}),0)}),0)],1):_vm._e(),(folderBrique.protocoles[3])?_c('a',{staticClass:"column is-12-mobile is-4-tablet is-4-desktop is-5-widescreen",class:{
                    verrouille:
                      !folderBrique.protocoles[3].deverrouille &&
                      (folderBrique.protocoles[3].deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      !folderBrique.protocoles[3].deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  },on:{"click":function($event){return _vm.unlock(folderBrique, 3)}}},[_c('b-tooltip',{attrs:{"label":folderBrique.protocoles[3].deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? 'Cliquer pour activer/désactiver ce protocole'
                        : 'Exercices non accessibles',"position":"is-top"}},[_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" "+_vm._s(folderBrique.protocoles[3].name)+" (long) ")])]),_c('div',_vm._l((folderBrique.protocoles[3]
                        .modulesDecouverte),function(exoP){return _c('div',{key:exoP.id,staticClass:"is-margin-t-10",class:{}},_vm._l((exoP.linkDecouverte),function(moduleE){return _c('div',{key:moduleE.id,staticClass:"is-margin-t-10",class:{}},[(moduleE.composite_file.long_file)?_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-12-mobile is-2-tablet is-3-desktop is-2-widescreen p-5",class:{
                              'is-3': _vm.isPageSessionGroup,
                            }},[_c('figure',[(folderBrique.protocoles[3])?_c('div',{staticClass:"cercleRessources",class:{
                                  cerclegreyRessources:
                                    !folderBrique.protocoles[3].deverrouille,
                                }},[_c('strong',{class:{ cercletext: !_vm.isPageUser }})]):_vm._e()])]),_c('div',{staticClass:"isPageUser has-text-black is-12-mobile is-10-tablet is-8-desktop is-10-widescreen"},[_c('b-tooltip',{attrs:{"label":folderBrique.protocoles[3]
                                  .deverrouille_user ||
                                (!_vm.isPageUser && !_vm.isPageSessionGroup)
                                  ? 'Cliquer pour activer/désactiver ce protocole'
                                  : 'Exercices non accessibles',"position":"is-top"}},[_c('span',{staticClass:"has-text-black",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleE.composite_file.long_file.sub_name)+" ")])])],1)]):_vm._e()])}),0)}),0)],1):_vm._e()])]):_vm._e()])}),0):_vm._e(),(theme.attributes.ordre === 7)?_c('div',_vm._l((_vm.getFoldersByTheme(theme.id)),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"is-margin-t-10"},[_c('div',{staticClass:"box is-margin-b-20",class:{ boxFolder: folderBrique.deverrouille }},[_c('div',{staticClass:"columns"},[_c('a',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border",class:{
                    verrouille:
                      !folderBrique.deverrouille &&
                      (folderBrique.deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      !folderBrique.deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  },on:{"click":function($event){return _vm.unlock(folderBrique)}}},[_c('b-tooltip',{attrs:{"label":folderBrique.deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? 'Cliquer pour activer/désactiver cette thématique'
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-right"}},[_c('div',{staticClass:"img-brique-folder"},[(
                          !_vm.isPageUser &&
                          !_vm.isPageSessionGroup &&
                          _vm.group.type_abonnement == 'gratuit'
                        )?_c('router-link',{attrs:{"to":"/abonnement"}},[_c('div',{staticClass:"columns is-multiligne"},[_c('div',{staticClass:"column is-12 is-size-7 text-abonnezvous-expert",staticStyle:{"border-radius":"15px 15px 0px 0px","background-color":"#4a439e","color":"white"}},[_vm._v(" "+_vm._s(_vm.$t("abonnezVousPour"))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t("debloquerQCM"))+" ")])])]):_vm._e(),_c('figure',{staticClass:"image container is-128x128 brique is-margin-l-0",class:{
                          'is-margin-t-0':
                            folderBrique.protocoles[0] !== undefined &&
                            folderBrique.protocoles[0].modules.length == 4,
                          'is-margin-t-90':
                            folderBrique.protocoles[0] !== undefined &&
                            folderBrique.protocoles[0].modules.length >= 5,
                        }},[(
                            folderBrique.resource &&
                            folderBrique.resource._url
                          )?_c('img',{staticClass:"is-rounded brique-image",attrs:{"src":folderBrique.resource._url}}):(
                            folderBrique.resource && folderBrique.resource.url
                          )?_c('img',{staticClass:"is-rounded brique-image",attrs:{"src":folderBrique.resource.url}}):_vm._e(),_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"is-size-12"},[_vm._v(_vm._s(folderBrique.name))])])])],1)])],1),(folderBrique.protocoles[0])?_c('a',{staticClass:"column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen column-border",class:{
                    verrouille:
                      !folderBrique.deverrouille ||
                      (!folderBrique.protocoles[0].deverrouille &&
                        (folderBrique.protocoles[0].deverrouille_user ||
                          (!_vm.isPageUser && !_vm.isPageSessionGroup))),
                    disabled:
                      !folderBrique.protocoles[0].deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  }},[_c('b-tooltip',{attrs:{"label":folderBrique.protocoles[0].deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? 'Cliquer sur le questionnaire pour activer/désactiver'
                        : 'Exercices non accessibles',"position":"is-top"}},[_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" "+_vm._s(folderBrique.protocoles[0].name)+" ")])]),_c('div',_vm._l((folderBrique.protocoles[0]
                        .modules),function(moduleP,index){return _c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{ isPageUser: _vm.isPageUser }},[_c('div',{staticClass:"columns is-vcentered",class:{
                          disabled:
                            _vm.getAccesWithAbonnement(_vm.group.type_abonnement) <
                            _vm.getAccesWithAbonnement(
                              moduleP.link.type_abonnement
                            ),
                        }},[_c('div',{staticClass:"column is-3",class:{
                            'is-6': _vm.isPageUser,
                            'is-4': _vm.isPageSessionGroup,
                          }},[_c('div',{staticClass:"round"},[_c('input',{attrs:{"type":"checkbox","id":'moduleP-checkbox-' + index},domProps:{"checked":moduleP.deverrouille &&
                                folderBrique.deverrouille}}),_c('label',{attrs:{"for":"moduleP-checkbox"},on:{"click":function($event){return _vm.unlock(folderBrique, 0, moduleP)}}})])]),_c('div',{staticClass:"column has-text-black",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleP.name)+" ")])])])}),0),(
                      folderBrique.protocoles[0] &&
                      folderBrique.protocoles[0].modules
                    )?_c('div',{staticClass:"is-pulled-right is-size-7"}):_vm._e()],1):_vm._e(),(folderBrique.protocoles[1])?_c('a',{staticClass:"column is-12-mobile is-4-tablet is-4-desktop is-4-widescreen",class:{
                    verrouille:
                      !folderBrique.deverrouille ||
                      (!folderBrique.protocoles[1].deverrouille &&
                        (folderBrique.protocoles[1].deverrouille_user ||
                          (!_vm.isPageUser && !_vm.isPageSessionGroup))),
                  }},[_c('b-tooltip',{attrs:{"label":folderBrique.protocoles[1].deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? 'Cliquer sur le questionnaire pour activer/désactiver'
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-top"}},[_c('span',{staticClass:"has-text-black is-size-12"},[_vm._v(" "+_vm._s(folderBrique.protocoles[1].name)+" ")])]),_c('div',_vm._l((folderBrique.protocoles[1]
                        .modules),function(moduleP,index){return _c('div',{key:moduleP.id,staticClass:"is-margin-t-10",class:{ isPageUser: _vm.isPageUser }},[(moduleP.fileIsNotEval)?_c('div',{staticClass:"columns is-vcentered",class:{
                          disabled:
                            _vm.getAccesWithAbonnement(_vm.group.type_abonnement) <
                            _vm.getAccesWithAbonnement(
                              moduleP.link.type_abonnement
                            ),
                        }},[_c('div',{staticClass:"column is-3",class:{
                            'is-6': _vm.isPageUser,
                            'is-4': _vm.isPageSessionGroup,
                          }},[_c('div',{staticClass:"round"},[_c('input',{attrs:{"type":"checkbox","id":'moduleP-checkbox-' + index},domProps:{"checked":moduleP.deverrouille &&
                                folderBrique.deverrouille}}),_c('label',{attrs:{"for":"moduleP-checkbox"},on:{"click":function($event){return _vm.unlock(folderBrique, 1, moduleP)}}})])]),_c('div',{staticClass:"column has-text-black",staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(moduleP.name + " " + moduleP.deverrouille)+" ")])]):_vm._e()])}),0),(
                      folderBrique.protocoles[1] &&
                      folderBrique.protocoles[1].modules
                    )?_c('div',{staticClass:"is-pulled-right is-size-7"}):_vm._e()],1):_vm._e()])])])}),0):_vm._e(),(
            theme.attributes.ordre === 9 ||
            theme.attributes.ordre === 6 ||
            theme.attributes.ordre === 8
          )?_c('div',_vm._l((_vm.getFoldersByTheme(theme.id)),function(folderBrique){return _c('div',{key:folderBrique.id,staticClass:"is-margin-t-10"},[_c('div',{staticClass:"box is-margin-b-20",class:{ boxFolder: folderBrique.deverrouille }},[_c('div',{staticClass:"columns"},[_c('a',{staticClass:"column is-12-mobile is-3-tablet is-3-desktop is-4-widescreen column-border",class:{
                    verrouille:
                      !folderBrique.deverrouille &&
                      (folderBrique.deverrouille_user ||
                        (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                    disabled:
                      !folderBrique.deverrouille_user &&
                      (_vm.isPageUser || _vm.isPageSessionGroup),
                  },on:{"click":function($event){return _vm.unlock(folderBrique)}}},[_c('b-tooltip',{attrs:{"label":folderBrique.deverrouille_user ||
                      (!_vm.isPageUser && !_vm.isPageSessionGroup)
                        ? 'Cliquer pour activer/désactiver cette thématique'
                        : 'Exercices non accessibles, voir la page "Mes activités" pour la gestion des accès aux exercices',"position":"is-right"}},[_c('div',{staticClass:"img-brique-folder"},[_c('figure',{staticClass:"image container is-128x128 brique is-margin-l-0",class:{
                          'is-margin-t-50':
                            folderBrique.protocoles.length >= 5,
                          'is-margin-t-90':
                            folderBrique.protocoles.length >= 8,
                        }},[(
                            folderBrique.resource &&
                            folderBrique.resource._url
                          )?_c('img',{staticClass:"is-rounded brique-image",attrs:{"src":folderBrique.resource._url}}):(
                            folderBrique.resource && folderBrique.resource.url
                          )?_c('img',{staticClass:"is-rounded brique-image",attrs:{"src":folderBrique.resource.url}}):_vm._e(),_c('div',{staticClass:"brique-text"},[_c('p',{staticClass:"is-size-7"},[_vm._v(_vm._s(folderBrique.name))])])])])])],1),_c('a',{staticClass:"column is-12-mobile is-9-tablet is-9-desktop is-9-widescreen"},_vm._l((folderBrique.protocoles),function(protocole,index){return _c('a',{key:index,on:{"click":function($event){return _vm.unlock(folderBrique, index)}}},[_c('div',{staticClass:"columns is-vcentered",class:{
                        verrouille:
                          !protocole.deverrouille &&
                          (protocole.deverrouille_user ||
                            (!_vm.isPageUser && !_vm.isPageSessionGroup)),
                        disabled:
                          !protocole.deverrouille_user &&
                          (_vm.isPageUser || _vm.isPageSessionGroup),
                      }},[_c('div',{staticClass:"column is-1",class:{
                          'is-4': _vm.isPageUser,
                          'is-3': _vm.isPageSessionGroup,
                        }},[_c('figure',[_c('div',{staticClass:"cercle-small",class:{
                              'cercle-small-grey': !protocole.deverrouille,
                            }})])]),_c('div',{staticClass:"column has-text-black"},[_vm._v(" "+_vm._s(protocole.name)+" ")])]),(folderBrique.protocoles.length - 1 !== index)?_c('hr',{staticClass:"hr-folder"}):_vm._e()])}),0)])])])}),0):_vm._e()]):_vm._e()]})],2),_c('b-modal',{attrs:{"width":1200,"active":_vm.isCardInformationOpen},on:{"update:active":function($event){_vm.isCardInformationOpen=$event},"close":function($event){_vm.isCardInformationOpen = false}}},[_c('information-activite-component',{attrs:{"descriptionSelected":_vm.descriptionSelected,"videoSelected":_vm.videoSelected},on:{"closeModal":function($event){_vm.isCardInformationOpen = false}}})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }