<template>
  <div class="popup">
    <div class="popup-content" v-if="step === 1">
      <div class="close-button" @click="closePopup">&#10006;</div>
      <!-- <button @click="test()">test</button> -->
      <div v-if="isTherapeute">
        <h2 class="titlePopUp">Communication</h2>
        <p>Choisissez le mode de contact pour interagir avec vos patients :</p>
      </div>
      <div v-else>
        <h2 class="titlePopUp">Félicitations !</h2>
        <p>Vous avez bien progressé sur la plateforme.</p>
        <p>Si vous en ressentez le besoin, vous pouvez contacter dès à présent votre professionel référent.</p>
      </div>
      <!-- <button @click="mailCadencement()">CADENCEMENT</button> -->
      <div class="contact-options">
        <contactBlock
          key="chat"
          method="chat"
          :selected="selectedMethod === 'chat'"
          @select="selectContactMethod"
          :numberNotif="NumberUnseenMessages"
          @therapistAvailabilityNotFilled="handleTherapistAvailabilityNotFilled"
        />
        <contactBlock
          key="video"
          method="video"
          :selected="selectedMethod === 'video'"
          @select="selectContactMethod"
          @therapistAvailabilityNotFilled="handleTherapistAvailabilityNotFilled"
        />
        <contactBlock
          key="ai"
          method="ai"
          :selected="selectedMethod === 'ai'"
          @select="selectContactMethod"
          @therapistAvailabilityNotFilled="handleTherapistAvailabilityNotFilled"
        />
      </div>
      <button @click="closePopup">Fermer</button>
    </div>
    <div class="popup-content" v-else-if="step === 2">
      <ListUserChat
        @close="closePopup"
        @selectUser="selectUser"
        @goBack="goBack"
        v-if="selectedMethod === 'chat'"
      />
      <SendMailVisio
        @close="closePopup"
        @goBack="goBack"
        v-else-if="selectedMethod === 'video'"
      />
    </div>
    <div class="popup-content" v-else-if="step === 3">
      <ChatPopUp
        v-if="selectedMethod === 'chat'"
        @close="closePopup"
        :selectedUser="selectedUser"
      />
      <VideoPopUp
        v-if="selectedMethod === 'video'"
        @close="closePopup"
        @goBack="goBack"
      />
      <AiPopUp v-if="selectedMethod === 'ai'" @close="closePopup" />
    </div>
  </div>
</template>

<script>
import contactBlock from "./ContactBlock.vue";

import ChatPopUp from "./chatPopUp.vue";
import ListUserChat from "./ListUserChat.vue";

import VideoPopUp from "./videoPopUp.vue";
import AiPopUp from "./aiPopUp.vue";

import SendMailVisio from "./SendMailVisio.vue";

import Parse from "parse";
export default {
  data() {
    return {
      selectedMethod: null,
      step: 1,
      selectedUser: null,
      therapistAvailability: false,
    };
  },
  computed: {
    isTherapeute() {
      return Parse.User.current().attributes.therapeute;
    },
  },
  props: {
    NumberUnseenMessages: {
      type: Number,
      default: 0,
    },
    idUserMsg: String,
  },
  async created() {
    // this.NumberUnseenMessages = await getTotalUnseenMessages(Parse.User.current().id);
    // console.log('Nombre total de messages non lus :', this.NumberUnseenMessages)
    var userQuery = new Parse.Query(Parse.User);
    userQuery.equalTo("objectId", this.idUserMsg);
    var user = await userQuery.first();
    console.log("ContactPopUp", user, this.idUserMsg);
    if (this.idUserMsg) {
      this.selectedMethod = "chat";
      this.selectedUser = user;
      this.step = 3;
    }
    // reset le paramètre idUserMsg
    // this.idUserMsg = null;
  },
  methods: {
    selectUser(user) {
      this.selectedUser = user;
      this.step = 3;
    },
    async mailCadencement() {
      try {
        console.log("étape 1");
        const days = [
          "dimanche",
          "lundi",
          "mardi",
          "mercredi",
          "jeudi",
          "vendredi",
          "samedi",
        ];
        const dayName = days[new Date().getDay()];

        const queryUser = new Parse.Query(Parse.User);
        //email est égal à 661f3f16e1@emailforyou.info
        queryUser.equalTo("email", "user_025@symbiofi.com");
        queryUser.limit(10000);
        const responseUsers = await queryUser.find();
        console.log("responseUsers", responseUsers);

        // Filtrez les utilisateurs qui ont le jour actuel dans leur liste `newsletter_jour`
        const usersWithTodayNewsletter = responseUsers.filter(
          (user) =>
            user.get("newsletter_jour") &&
            user.get("newsletter_jour").includes(dayName)
        );

        // Affichez un message pour chaque utilisateur correspondant
        usersWithTodayNewsletter.forEach(async (user) => {
          // Parse.Cloud.run("InformationCadencementExerciceV3", { destinataire: user.get("username") });
          try {
            console.log(
              `Sending newsletter to ${user.get("username")} for ${dayName}`
            );

            const FileDoneQuery = new Parse.Query("file_done");
            FileDoneQuery.equalTo("user", user);
            //le plus récent
            FileDoneQuery.descending("createdAt");
            //inclure le file, le module et le protocole
            FileDoneQuery.include("file");
            FileDoneQuery.include("module");
            FileDoneQuery.include("protocole");
            const fileDone = await FileDoneQuery.first();
            // console.log("FILE DONE : ",fileDone);
            // console.log('Nom du module :', fileDone.get("module").get("name") , 'Nom du protocole :', fileDone.get("protocole").get("name") , 'Nom du fichier :', fileDone.get("file").get("name"));
            if (fileDone) {
              const LinkQuery = new Parse.Query("link");
              LinkQuery.equalTo("file", fileDone.get("file"));
              const link = await LinkQuery.first();
              console.log("Link :", link);
              if (link) {
                const parentFolder = link.get("parent_folder");
                const order = link.get("order");

                if (parentFolder && order !== undefined) {
                  const LinkQuery = new Parse.Query("link");

                  // Configurer LinkQuery pour correspondre au même parent_folder
                  LinkQuery.equalTo("parent_folder", parentFolder);

                  // Configurer LinkQuery pour trouver le prochain order (order + 1)
                  LinkQuery.equalTo("order", order + 1);
                  
                  // inclure le parent_folder
                  LinkQuery.include("parent_folder");

                  // inclure le file
                  LinkQuery.include("file");

                  // Essayez de récupérer le link correspondant
                  const link = await LinkQuery.first();

                  if (link) {
                    const LinkQuery2 = new Parse.Query("link");
                    LinkQuery2.equalTo("folder", link.attributes.parent_folder);
                    LinkQuery2.include("parent_folder");
                    const link2 = await LinkQuery2.first();
                    //excluse les link ayant comme parent_folder name : Entraînement libre
                    if (link2.attributes.parent_folder.attributes.name !== "Entraînement libre") {
                      Parse.Cloud.run("InformationCadencementExercice", { destinataire: user.get("username") , mail: user.get("email") , nomModule: fileDone.get("module").get("name") , nomProtocole: fileDone.get("protocole").get("name") , nomExercice: fileDone.get("file").get("name") });
                    } else {
                      console.log(
                        "Aucun link correspondant trouvé pour le parent_folder et order suivant",
                        parentFolder,
                        order + 1
                      );
                    }
                    console.log("Link trouvé correspondant au critère :", link.attributes.parent_folder.attributes.name , link.attributes.file.attributes.name, link2.attributes.parent_folder.attributes.name);
                    Parse.Cloud.run("InformationCadencementExercice", { destinataire: user.get("username") , mail: user.get("email") , nomModule: fileDone.get("module").get("name") , nomProtocole: fileDone.get("protocole").get("name") , nomExercice: fileDone.get("file").get("name") });
                  } else {
                     console.log(
                       "Aucun link correspondant trouvé pour le parent_folder et order suivant",
                       parentFolder,
                       order + 1
                     );
                  }
                }
              } else {
                console.log(
                  "Aucun fichier terminé trouvé pour l'utilisateur",
                  user.get("username")
                );
              }
            } else {
              console.log(
                "Aucun fichier terminé trouvé pour l'utilisateur",
                user.get("username")
               );
            }
          } catch (innerError) {
             console.error(
               `Erreur lors de l'envoi de la newsletter à ${user.get(
                 "username"
              )}:`,
               innerError
             );
          }
        });
      } catch (error) {
        console.error(error);
      }
    },
    handleTherapistAvailabilityNotFilled(value) {
      console.log("Therapist availability not filled:", value);
      if (value) {
        this.therapistAvailability = true;
      }
    },
    goBack() {
      this.step = 1;
      this.selectedUser = null;
      this.selectedMethod = null;
    },
    selectContactMethod(method) {
      this.selectedMethod = method;
      console.log("Méthode sélectionnée :", method);
      if (this.isTherapeute && method === "chat") {
        this.step = 2;
      } else if (this.therapistAvailability && method === "video") {
        this.step = 2;
      } else {
        this.step = 3;
      }
    },
    test() {
      console.log("test");
      var offer = "Essai gratuit";
      var duration = "1 mois";
      var date = new Date();
      Parse.Cloud.run("confirmationResiliationAbonnementPro", {
        utilisateur: "Louis_HQZ",
        destinataire: "l.hanquiez22@gmail.com",
        offre: offer,
        duree: duration,
        date: date,
      });
    },
    closePopup() {
      this.$emit("close");
    },
  },
  components: {
    contactBlock,
    ChatPopUp,
    VideoPopUp,
    AiPopUp,
    ListUserChat,
    SendMailVisio,
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px); /* Effet de flou sur l'arrière-plan */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #fff;
  padding: 25px;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  width: auto;
  max-width: 600px; /* Ajustement de la largeur maximale */
  text-align: center;
  transition: all 0.3s ease;
  max-width: 70%;
  position: relative;
}

button {
  background: #489181;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.2s ease;
}

button:hover {
  background: #36715f; /* Couleur plus foncée au survol */
}

.titlePopUp {
  color: #489181;
  font-size: 1.2rem;
  font-weight: bold;
}

.contact-options {
  display: flex;
  justify-content: center;
  gap: 10px;
  flex-wrap: wrap; /* Permet aux blocs de s'enrouler sur les petits écrans */
  margin-top: 20px;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  right: 25px;
  cursor: pointer;
  font-size: 24px;
}
</style>
