// index.js
import en from "./en.json";
import fr from "./fr.json";

export const defaultLocale = "fr";

export const languages = {
  en: en,
  fr: fr,
};
