<template>
  <div>
    <canvas ref="scoreChart" :style="{ maxWidth: '1100px' }"  width="600px" height="150px"></canvas>
  </div>
</template>

<script>
import Chart from "chart.js";

export default {
  name: "ScoreChart",
  data() {
    return {
      chartInstance: null,
    };
  },
  props: {
    scores: {
      type: Array,
      required: true,
      validator: (value) => {
        return Array.isArray(value) && value.length > 0;
      },
    },
    dates: {
      type: Array,
      required: true,
      validator: (value) => {
        return Array.isArray(value) && value.length > 0;
      },
    },
  },
  mounted() {
    this.createChart();
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
  methods: {
    createChart() {
      const filteredData = this.scores
        .map((score, index) => ({ score, date: this.dates[index] }))
        .filter(({ score }) => score !== undefined && score !== 0);

      const filteredScores = filteredData.map((data) => data.score);
      const filteredDates = filteredData.map((data) => data.date);

      const ctx = this.$refs.scoreChart.getContext("2d");
      this.chartInstance = new Chart(ctx, {
        type: "bar",
        data: {
          labels: filteredDates,
          datasets: [
            {
              label: "Scores",
              data: filteredScores,
              backgroundColor: filteredScores.map((score) => {
                if (score >= 85) return "#95FE71";
                else if (score >= 60) return "#FFCE56";
                else return "#FF0000";
              }),
            },
          ],
        },
        options: {
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  max: 100,
                },
              },
            ],
          },
          legend: {
            display: true,
            labels: {
              fontColor: "rgb(255, 99, 132)",
            },
          },
          // onClick: (event, chartElements) => {
          //   if (chartElements.length > 0) {
          //     const elementIndex = chartElements[0]._index;
          //     const date = filteredDates[elementIndex];
          //     const score = filteredScores[elementIndex];
          //     alert(`Date: ${date}, Score: ${score}`); // Vous pouvez remplacer cette ligne pour faire autre chose avec les données
          //     this.$emit("click", { date, score });
          //   }
          // },
        },
      });
    },
  },
};
</script>

<style scoped>
/* Assurez-vous que le conteneur a une largeur et une hauteur définies */
/* div {
  width: 100%;
  height: 400px;
} */
</style>
