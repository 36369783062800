<template>
  <div class="user-list-container">
    <div class="navigation">
      <i class="fas fa-arrow-left back-arrow" @click="goBack"></i>
      <div class="close-button" @click="closePopup">&#10006;</div>
    </div>
    <h2 class="titlePopUp">Liste de vos utilisateurs :</h2>
    <p class="textPopUp">
      Sélectionnez un utilisateur pour commencer la conversation :
    </p>
    <div v-if="isLoading" class="loader-user-list"></div>
    <ul class="user-list" v-else>
      <li
        v-for="user in paginatedUsers"
        :key="user.id"
        @click="selectUser(user)"
        :class="{
          selected: selectedUser === user,
          online: onlineUser.includes(user.id),
          offline: !onlineUser.includes(user.id),
        }"
      >
        <span
          ><i class="fas fa-user userIcon"></i
          >{{
            "  " + user.attributes.lastname + " " + user.attributes.firstname
          }}
          <i
            class="fas fa-circle online-icon"
            v-if="onlineUser.includes(user.id)"
          ></i>
          <span
            v-if="user.unseenMessagesCount > 0"
            class="unseen-messages-badge"
            >{{ user.unseenMessagesCount }}</span
          >
        </span>
      </li>
    </ul>
    <div class="pagination-controls">
      <button @click="prevPage" :disabled="currentPage <= 1">Précédent</button>
      <button @click="nextPage" :disabled="currentPage >= totalPages">
        Suivant
      </button>
    </div>
    <!-- <button @click="callSocketOnline()">{{ onlineUser }}</button> -->
  </div>
</template>

<script>
import { getUserFromTherapeute } from "@/services/chat/getUserFromTherapeute.js";
import Parse from "parse";
import WebSocketService from "@/services/socket/WebSocketService.js";
import { countUnseenMessages } from "@/services/chat/countUnseenMessages.js";

export default {
  data() {
    return {
      currentPage: 1,
      usersPerPage: 5,
      UserList: "",
      isLoading: true,
      selectedUser: null,
      ws: null,
      onlineUser: "",
    };
  },
  computed: {
    paginatedUsers() {
      const start = (this.currentPage - 1) * this.usersPerPage;
      const end = start + this.usersPerPage;
      return this.UserList.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.UserList.length / this.usersPerPage);
    },
  },
  async created() {
    try {
      console.log("LIST USER CHAT");
      this.UserList = await this.fetchUserListWithUnseenMessagesCount();
      console.log(this.UserList);
      // this.callSocketOnline();
    } catch (error) {
      console.log(error);
    } finally {
      this.isLoading = false;
      console.log("UserList NOTIFICIATION///", this.UserList);
    }
  },
  methods: {
    goBack() {
      this.$emit("goBack");
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    async fetchUserListWithUnseenMessagesCount() {
      const userList = await getUserFromTherapeute(
        Parse.User.current().attributes.group,
        true
      );

      const userId = Parse.User.current().id;
      const unseenMessagesArray = await countUnseenMessages(userId);

      // Ajouter le nombre de messages non lus à chaque utilisateur
      userList.forEach((user) => {
        const matchingUnseenMessages = unseenMessagesArray.find(
          (item) => item.senderId === user.id
        );
        user.unseenMessagesCount = matchingUnseenMessages
          ? matchingUnseenMessages.numUnseenMessages
          : 0;
      });

      return userList;
    },
    async callSocketOnline() {
      let webSocketService;

      try {
        webSocketService = new WebSocketService();

        // Pour se connecter au WebSocket
        webSocketService.connectToWebSocket();
        // Pour envoyer l'ID de l'utilisateur
        const userId = Parse.User.current().id;
        webSocketService.sendUserId(userId);

        // Attendre la mise à jour de l'onlineUser avant de le récupérer
        const onlineUser = await webSocketService.awaitOnlineUserUpdate();
        this.onlineUser = onlineUser.onlineUsers;
        console.log("Liste des utilisateurs en ligne:", this.onlineUser);
      } catch (error) {
        console.error("Erreur lors de la connexion au WebSocket :", error);
      } finally {
        if (!webSocketService) {
          console.log("Le WebSocketService n'a pas été initialisé.");
        }
      }
    },
    closePopup() {
      this.$emit("close");
    },
    selectUser(user) {
      // vérifier l'utilisateur sélectionné si il est en ligne
      if (this.onlineUser.includes(user.id)) {
        //lui rajouter l'attribut isConnected
        user.isConnected = true;
      }
      this.selectedUser = user;
      this.$emit("selectUser", user);
    },
  },
};
</script>

<style>
.user-list-container {
  display: flex;
  flex-direction: column;
  max-width: 600px; /* Limite la largeur pour une meilleure lisibilité */
  margin: auto; /* Centrer dans la page */
}

.loader-user-list {
  -webkit-animation: spinAround 500ms infinite linear;
  animation: spinAround 1000ms infinite linear;
  border: 5px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 4em;
  position: relative;
  width: 4em;
  margin-left: auto;
  margin-right: auto;
  margin-top: 2rem;
}

.user-list {
  list-style: none;
  padding: 0;
  margin: 0;
}
.online {
}
.unseen-messages-badge {
  background-color: red;
  color: white;
  border-radius: 50%;
  /* padding: 5px; */
  margin-left: 0.5rem;
  display: inline-block;
  line-height: 1.2;
  width: 20px;
  height: 20px;
  font-size: 0.8em !important;
}
.offline {
  background-color: #dddddd !important; /* Remplacez par la couleur grise souhaitée */
}

.user-list li {
  display: flex;
  align-items: center; /* Aligner les icônes/avatars et le texte */
  padding: 12px 20px;
  border: 1px solid #ddd;
  margin-bottom: 8px;
  border-radius: 8px; /* Bordures arrondies */
  transition: all 0.3s ease; /* Transition douce pour l'interaction */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Ombre subtile */
  cursor: pointer;
  background-color: white;
}
.close-button {
  position: absolute;
  right: 20px;
  cursor: pointer;
  font-size: 24px;
}

.user-list li:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px); /* Légère élévation lors du survol */
  border-color: #489181;
}

.user-list li.selected {
  background-color: #489181;
  color: white;
  border-color: #489181;
}

.user-list li img {
  width: 40px; /* Taille de l'avatar */
  height: 40px;
  border-radius: 50%; /* Rend l'avatar circulaire */
  margin-right: 15px;
}

.user-list li span {
  font-size: 1.1em; /* Taille de la police légèrement augmentée */
  font-weight: 500; /* Police semi-grasse */
}
.titlePopUp {
  color: #489181;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.userIcon {
  color: #489181;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
}
.textPopUp {
  margin-bottom: 10px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez la taille au besoin */
  margin-right: 20px; /* Espacement avant le titre ou la bordure de la pop-up */
}

.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez selon vos besoins */
  color: #489181; /* Couleur de l'icône */
  margin-right: 20px; /* Ajustez l'espacement si nécessaire */
}

.online-icon {
  color: green; /* Couleur verte pour la pastille */
  margin-left: 5px; /* Marge à gauche pour espacer la pastille du nom de l'utilisateur */
}

.offline-message {
  font-style: italic; /* Style d'écriture en italique pour le message */
  color: #999; /* Couleur grise pour le message */
  margin-left: 10px; /* Marge à gauche pour espacer le message du nom de l'utilisateur */
}

.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px; /* Espace entre les boutons */
  margin-top: 20px; /* Espacement au-dessus de la pagination */
}

.pagination-controls button {
  padding: 8px 16px; /* Espacement intérieur pour agrandir le bouton */
  border: none; /* Suppression de la bordure par défaut */
  background-color: #489181; /* Couleur de fond bleue */
  color: white; /* Texte blanc pour le contraste */
  border-radius: 5px; /* Bordures arrondies pour un look moderne */
  cursor: pointer; /* Change le curseur pour indiquer la cliquabilité */
  font-weight: bold; /* Rend le texte un peu plus épais */
  transition: background-color 0.3s, transform 0.2s; /* Transition pour l'effet au survol */
}

.pagination-controls button:disabled {
  background-color: #cccccc; /* Couleur de fond pour les boutons désactivés */
  cursor: not-allowed; /* Change le curseur pour indiquer qu'il n'est pas cliquable */
  transform: none; /* Pas de transformation pour les boutons désactivés */
}

.pagination-controls button:not(:disabled):hover {
  background-color: #397467; /* Assombrir le bouton au survol */
  transform: translateY(-2px); /* Effet de léger soulèvement au survol */
}

.pagination-controls button:not(:disabled):active {
  background-color: #397467; /* Couleur plus sombre lors du clic */
  transform: translateY(1px); /* Effet d'enfoncement lors du clic */
}

</style>
