<template>
  <div class="chat-content">
    <div class="message-container" v-if="isLoading">Chargement en cours...</div>
    <div v-else class="message-container">
      <!-- <i class="fas fa-arrow-left back-arrow" @click="goBack"></i> -->
      <div class="close-button" @click="closePopup">&#10006;</div>
      <div class="user-name">
        <template v-if="isLoading">Chargement en cours...</template>
        <template v-else-if="!isTherapeute">{{ TherapeuteInfo.name }}</template>
        <template v-else>{{
          selectedUser.attributes.lastname +
          " " +
          selectedUser.attributes.firstname
        }}</template>
      </div>
      <!--green sticker online-->
      <div
        class="online"
        v-if="
          TherapeuteInfo.isConnectedRecently && !selectedUser ||
          (selectedUser && selectedUser.isConnected)
        "
      ></div>
      <div class="messages" id="messageContainer">
        <span
          class="offline-message"
          v-if="selectedUser && !selectedUser.isConnected"
        >
          (Cet utilisateur n'est pas en ligne pour le moment, mais votre message
          lui sera bien envoyé et il recevra également un e-mail le notifiant de
          votre message.)
        </span>

        <div
          v-for="(message, index) in MessagesList"
          :key="index"
          :class="{
            'user-message': message.isUserMessage,
            'other-message': !message.isUserMessage,
          }"
        >
          {{ message.text }}
          <span
            v-if="index === MessagesList.length - 1 && message.seen"
            class="seen-status"
          >
            <i class="fas fa-eye"></i> Vu
          </span>
        </div>
        <div id="lastMsg"></div>
      </div>
      <div class="input-bar">
        <input
          v-model="newMessage"
          @keyup.enter="sendMessageMethod"
          placeholder="Tapez votre message..."
        />
        <button @click="sendMessageMethod">Envoyer</button>
      </div>
    </div>
  </div>
</template>

<script>
import { getTherapeuteFromUser } from "@/services/utilisateurs/getTherapeuteFromUser.js";
import { getMessages } from "@/services/chat/getMessages.js";
import { getConversation } from "@/services/chat/getConversation.js";
import { sendMessage } from "@/services/chat/sendMessage.js"; // Importez la méthode sendMessage
import WebSocketService from "@/services/socket/WebSocketService.js";

import Parse from "parse";
export default {
  data() {
    return {
      newMessage: "",
      TherapeuteInfo: "",
      MessagesList: "",
      isLoading: true,
      ConversationInfo: "",
      socket: null,
      onlineUser: "",
    };
  },
  computed: {
    isTherapeute() {
      return Parse.User.current().attributes.therapeute;
    },
  },
  methods: {
    sendNotifMail(username,name,idUser) {
      console.log('Envoi du mail de notification à', username, 'avec le nom', name, 'et id', idUser);

      Parse.Cloud.run("notificationMessage", {
        mail: username,
        name: name,
        idUser:idUser,
      });
    },
    test(isConnected) {
      console.log("test", isConnected);
    },
    async sendMessageMethod() {
      if (this.newMessage) {
        sendMessage(this.newMessage, this.ConversationInfo.id);
        this.MessagesList.push({
          text: this.newMessage,
          isUserMessage: true,
          userId: Parse.User.current().id,
        });

        this.newMessage = "";
        console.log("selectedUserIsConnected", this.selectedUser, this.TherapeuteInfo); 
        if(Parse.User.current().attributes.therapeute && this.selectedUser && !this.selectedUser.isConnected)
        {
          var nameUser = this.selectedUser.attributes.lastname  + '  ' + this.selectedUser.attributes.firstname;
          this.sendNotifMail(this.selectedUser.attributes.username, nameUser , this.selectedUser.id);
        }
        else if(this.TherapeuteInfo && !this.TherapeuteInfo.isConnectedRecently)
        {
          this.sendNotifMail(this.TherapeuteInfo.mail, this.TherapeuteInfo.name, Parse.User.current().id);
        }
      }
    },
    closePopup() {
      this.$emit("close");
    },
    scrollToSection() {
      console.log("scrollToSection");
      setTimeout(() => {
        const section = document.querySelector("#lastMsg");
        section.scrollIntoView({ behavior: "smooth" });
      }, 100);
    },
    boucleGetMessage() {
      var user = this.selectedUser ? this.selectedUser : Parse.User.current();
      setInterval(async () => {
        this.MessagesList = await getMessages(this.TherapeuteInfo.id, user);
      }, 5000);
    },
    async callSocketOnline() {
      let webSocketService;

      try {
        webSocketService = new WebSocketService();

        // Pour se connecter au WebSocket
        webSocketService.connectToWebSocket();
        // Pour envoyer l'ID de l'utilisateur
        const userId = Parse.User.current().id;
        webSocketService.sendUserId(userId);

        // Attendre la mise à jour de l'onlineUser avant de le récupérer
        const onlineUser = await webSocketService.awaitOnlineUserUpdate();
        this.onlineUser = onlineUser.onlineUsers;
        console.log("Liste des utilisateurs en ligne:", this.onlineUser);
      } catch (error) {
        console.error("Erreur lors de la connexion au WebSocket :", error);
      } finally {
        if (!webSocketService) {
          console.log("Le WebSocketService n'a pas été initialisé.");
        }
      }
    },
  },
  props: {
    selectedUser: {
      type: Object,
      default: null,
    },
  },
  async created() {
    try {
      this.TherapeuteInfo = await getTherapeuteFromUser(Parse.User.current());
      console.log(Parse.User.current());
      console.log(this.TherapeuteInfo);
      if (this.selectedUser) {
        this.ConversationInfo = await getConversation(
          this.TherapeuteInfo.id,
          this.selectedUser
        );
        this.MessagesList = await getMessages(
          this.TherapeuteInfo.id,
          this.selectedUser
        );
      } else {
        this.ConversationInfo = await getConversation(
          this.TherapeuteInfo.id,
          Parse.User.current()
        );
        this.MessagesList = await getMessages(
          this.TherapeuteInfo.id,
          Parse.User.current()
        );
        // this.callSocketOnline();
        console.log("MessagesList", this.MessagesList);
      }
      console.log(this.MessagesList);
      console.log(this.ConversationInfo);
      console.log("selectedUser in chatPopUp", this.selectedUser);
    } catch (error) {
      console.error(error);
      // Gérez les erreurs de chargement ici
    } finally {
      this.isLoading = false; // Définissez isLoading sur false une fois que les données sont chargées
      if (!this.selectedUser) {
        console.log("Vérification de l'utilisateur en ligne:", this.onlineUser);
        if (this.onlineUser && this.TherapeuteInfo.includes(this.onlineUser)) {
          this.ThreapeuteInfo.isConnectedRecently = true;
        }
      }
      this.boucleGetMessage();
      this.scrollToSection();
    }
  },
};
</script>
<style scoped>
.user-name {
  text-align: center;
  background-color: #f7f7f7;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 15px;
}

.message-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}
.close-button {
  position: absolute;
  right: 5px;
  cursor: pointer;
  font-size: 24px;
}
.messages {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  /* max-height: 400px; */
  margin-bottom: 70px;
}
.online {
  width: 10px;
  height: 10px;
  background-color: green;
  border-radius: 50%;
  position: absolute;
  top: 15px;
  right: 40px;
}
.user-message {
  background-color: #489181;
  color: white;
  margin: 5px 0;
  padding: 10px;
  border-radius: 20px;
  max-width: 70%;
  align-self: flex-end;
  word-wrap: break-word;
}

.other-message {
  background-color: #f2f2f2; /* Couleur de fond pour les messages de l'autre personne */
  color: #333; /* Couleur du texte pour les messages de l'autre personne */
  margin: 5px 0;
  padding: 10px;
  border-radius: 20px;
  max-width: 70%;
  align-self: flex-start;
  word-wrap: break-word;
}

.input-bar {
  display: flex;
  background-color: #f7f7f7;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin-top: 10px;
  position: absolute;
  bottom: 0;
  width: 100%;
}

input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 20px;
  margin-right: 10px;
  font-size: 16px;
}

button {
  background-color: #489181;
  color: white;
  border: none;
  border-radius: 20px;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #366c5a;
}
.chat-content {
  max-height: 600px;
  height: 600px;
  display: flex;
  width: 350px;
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez la taille au besoin */
  margin-right: 20px; /* Espacement avant le titre ou la bordure de la pop-up */
}

.back-arrow {
  cursor: pointer;
  font-size: 24px; /* Ajustez selon vos besoins */
  color: #489181; /* Couleur de l'icône */
  margin-right: 20px; /* Ajustez l'espacement si nécessaire */
}

.offline-message {
  color: #a3a3a3; /* Couleur du texte en rouge */
  font-style: italic; /* Texte en italique */
  font-size: 12px; /* Taille de police plus petite */
}

/* Styliser la barre de défilement */
.messages::-webkit-scrollbar {
  width: 8px; /* Largeur de la barre de défilement */
  height: 8px; /* Hauteur de la barre de défilement */
}

/* Styliser le fond de la barre de défilement */
.messages::-webkit-scrollbar-track {
  background: #f0f0f0; /* Couleur de fond légèrement différente pour la piste */
  border-radius: 10px; /* Bords arrondis pour la piste */
}

/* Styliser le curseur de la barre de défilement */
.messages::-webkit-scrollbar-thumb {
  background: #489181; /* Couleur principale du thème pour le curseur */
  border-radius: 10px; /* Bords arrondis pour le curseur */
}

/* Ajouter un effet au survol du curseur de la barre de défilement */
.messages::-webkit-scrollbar-thumb:hover {
  background: #366c5a; /* Une teinte plus foncée pour le survol */
}

.seen-indicator {
  display: inline-block;
  background-color: #4caf50; /* Couleur verte */
  color: white;
  text-align: center;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 0.75rem;
  margin-left: 8px;
}
.seen-status {
  color: #aaa; /* Gris pour un aspect discret */
  font-size: 0.75rem; /* Petite taille pour la discrétion */
  margin-left: 8px; /* Espacement pour séparer du texte du message */
}

/* Spécifique pour l'icône FontAwesome */
.fas.fa-eye {
  font-size: 0.75rem; /* Ajustez la taille de l'icône au besoin */
}
</style>
