import Parse from "parse";

export default async function getMyHeadings() {
  var userCourant = new Parse.Object.extend("_User");
  userCourant = await Parse.User.current();

  const userHeadingsObject = Parse.Object.extend("user_headings");
  const userHeadingsQuery = new Parse.Query(userHeadingsObject);
  userHeadingsQuery.equalTo("user", userCourant);
  userHeadingsQuery.include("heading");
  const userHeadingsFind = await userHeadingsQuery.find();

  const tab = [];
  userHeadingsFind.forEach((userHeading) => {
    tab.push(userHeading);
  });
  return tab;
}
