<template lang="html">
  <div class="form-container">
    <b-field class="field-paiement" grouped>
      <b-field
        class="field-paiement"
        custom-class="is-small"
        expanded
        :type="{ 'is-danger': !nom && error }"
        :message="{
          'Veuillez renseigner un nom.': !nom && error,
        }"
      >
        <b-input
          custom-class="is-small"
          placeholder="Nom"
          v-model="nom"
        ></b-input>
      </b-field>
      <b-field
        class="field-paiement"
        custom-class="is-small"
        expanded
        :type="{ 'is-danger': !prenom && error }"
        :message="{
          'Veuillez renseigner un prénom': !prenom && error,
        }"
      >
        <b-input
          custom-class="is-small"
          placeholder="Prénom"
          v-model="prenom"
        ></b-input>
      </b-field>
    </b-field>

    <b-field
      class="field-paiement"
      custom-class="is-small"
      :type="{ 'is-danger': !adresse && error }"
      :message="{
        'Veuillez renseigner une adresse.': !adresse && error,
      }"
    >
      <b-input
        custom-class="is-small"
        placeholder="Adresse de livraison"
        v-model="adresse"
      ></b-input>
    </b-field>

    <b-field class="field-paiement" custom-class="is-small">
      <b-input
        custom-class="is-small"
        placeholder="Complément d'adresse"
        v-model="complementAdresse"
      ></b-input>
    </b-field>

    <b-field class="field-paiement" grouped>
      <b-field
        class="field-paiement"
        custom-class="is-small"
        expanded
        :type="{ 'is-danger': !ville && error }"
        :message="{
          'Veuillez renseigner une ville.': !ville && error,
        }"
      >
        <b-input
          custom-class="is-small"
          placeholder="Ville"
          v-model="ville"
        ></b-input>
      </b-field>
      <b-field
        class="field-paiement"
        custom-class="is-small"
        expanded
        :type="{ 'is-danger': !codePostal && error }"
        :message="{
          'Veuillez renseigner un code postal.': !codePostal && error,
        }"
      >
        <b-input
          custom-class="is-small"
          placeholder="Code postal"
          v-model="codePostal"
        ></b-input>
      </b-field>
    </b-field>

    <b-field class="field-paiement" custom-class="is-small">
      <b-input
        custom-class="is-small"
        placeholder="Code d'accès à la résidence"
        v-model="codeAcces"
      ></b-input>
    </b-field>
  </div>
</template>

<script>
export default {
  name: "adresse-livraison",
  props: ["propsInformationAdresse", "error"],
  data() {
    return {
      informationAdresse: {},
      adresse: null,
      complementAdresse: null,
      nom: null,
      prenom: null,
      ville: null,
      codePostal: null,
      codeAcces: null,
    };
  },
  methods: {
    updateAdresse() {
      this.$emit("updateAdresse", this.informationAdresse);
    },
  },
  mounted() {
    this.adresse = this.propsInformationAdresse.adresse;
    this.complementAdresse = this.propsInformationAdresse.complementAdresse;
    this.nom = this.propsInformationAdresse.nom;
    this.prenom = this.propsInformationAdresse.prenom;
    this.ville = this.propsInformationAdresse.ville;
    this.codePostal = this.propsInformationAdresse.codePostal;
    this.codeAcces = this.propsInformationAdresse.codeAcces;
  },
  watch: {
    adresse() {
      this.informationAdresse.adresse = this.adresse;
      this.updateAdresse();
    },
    complementAdresse() {
      this.informationAdresse.complementAdresse = this.complementAdresse;
      this.updateAdresse();
    },
    nom() {
      this.informationAdresse.nom = this.nom;
      this.updateAdresse();
    },
    prenom() {
      this.informationAdresse.prenom = this.prenom;
      this.updateAdresse();
    },
    ville() {
      this.informationAdresse.ville = this.ville;
      this.updateAdresse();
    },
    codePostal() {
      this.informationAdresse.codePostal = this.codePostal;
      this.updateAdresse();
    },
    codeAcces() {
      this.informationAdresse.codeAcces = this.codeAcces;
      this.updateAdresse();
    },
  },
};
</script>

<style scoped>
.form-container {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: auto;
}

.field-paiement {
  margin-bottom: 15px;
}

.b-input input {
  border-radius: 4px;
  border: 1px solid #ddd;
  padding: 10px;
  font-size: 14px;
  transition: border-color 0.3s ease;
}

.b-input input:focus {
  border-color: #3273dc;
  box-shadow: 0 0 5px rgba(50, 115, 220, 0.3);
}

.b-field .message {
  font-size: 12px;
  color: #ff3860;
}

.b-field.is-danger .message {
  color: #ff3860;
}
</style>